<script>
import { nextTick, ref } from "@vue/composition-api";
import ParametersTemplate from "src/views/docs/templates/components/Parameters";
import startCase from 'lodash/startCase';

export default {
  components: {
    ParametersTemplate,
  },
  props: {
    model: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    const contentHeight = ref("200vh");
    const sourcecodeLang = ref("python");
    const selectSourcecodeLang = (lang) => {
      sourcecodeLang.value = lang;
    };
    const formatJSON = (code) => {
      var beautify = require("json-beautify");
      console.log("formatJSON >>> ", code);
      return beautify(code, null, 2, 40);
    };
    nextTick(() => {
      contentHeight.value =
        document.getElementById("content").clientHeight -
        document.getElementById(`${props.model.anchor}`).clientHeight +
        200 +
        "px";
    });
    return {
      contentHeight,
      sourcecodeLang,
      selectSourcecodeLang,
      formatJSON,
      window,
      startCase,
    };
  },
};
</script>
<template>
  <div class="relative">
    <div class="bg-white sticky top-0 z-0 left-3/5 w-2/5">
      <div
        :id="model.anchor"
        class="flex pl-2 pr-6"
      >
        <div class="flex flex-col space-y-6 w-full py-6">
          <div
            v-if="model.endpoint"
            class=""
          >
            <div class="dracula flex flex-col space-y-2">
              <span class="text-lg font-semibold">Endpoint</span>
              <highlightjs
                class="rounded-xl"
                autodetect
                :code="model.endpoint"
              />
            </div>
          </div>
          <div
            v-if="model.sourcecode && model.sourcecode.length > 0"
            class="dracula rounded-xl relative overflow-hidden text-xs"
          >
            <template v-for="(it, i) in model.sourcecode">
              <highlightjs
                v-show="it.lang == sourcecodeLang"
                :key="`source-code-${i}`"
                class="sourcecode mt-8"
                :style="{ 'max-height': '300px' }"
                autodetect
                :code="it.code"
              />
            </template>
            <div
              class="
                absolute
                right-0
                top-0
                left-0
                bg-code-dark
                border-b border-white
              "
            >
              <div class="flex justify-end space-x-4 px-6 py-2">
                <button
                  v-for="(it, i) in model.sourcecode"
                  :key="`source-code-language-select-${i}`"
                  class="
                    text-xs
                    border border-white
                    hover:bg-white
                    px-2
                    py-1
                    rounded
                    font-medium
                    hover:text-parqay-primary
                    focus:outline-none
                  "
                  :class="
                    it.lang == sourcecodeLang
                      ? 'bg-white text-parqay-primary'
                      : 'text-yellow-300'
                  "
                  @click="selectSourcecodeLang(it.lang)"
                >
                  {{ startCase(it.lang) }}
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="model.response"
            class="
              google-light
              rounded-xl
              border
              relative
              overflow-hidden
              bg-code-light
            "
          >
            <highlightjs
              class="sourcecode mt-8"
              autodetect
              :code="formatJSON(model.response)"
            />
            <div class="absolute right-0 top-0 left-0 bg-gray-300">
              <div class="flex justify-start px-6 py-2">
                <span class="text-base">Response Object</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class=""
      :style="{ height: contentHeight }"
    >
      <div
        class="
          absolute
          left-0
          top-0
          bottom-0
          w-3/5
          text-3xl
          bg-white
          pl-8
          pr-4
          py-4
        "
      >
        <div
          id="content"
          class="flex flex-col"
        >
          <span
            class="text-xl font-semibold text-black pt-6"
            @click="$emit('scroll-to-section', model.anchor)"
          >
            <svg-icon
              icon-class="link-doc"
              class="
                w-8
                h-8
                text-parqay-primary
                hover:text-blue-800
                cursor-pointer
              "
            />
            {{ model.name }}</span>
          <p class="text-lg font-medium text-gray-500 leading-6 mt-4">
            {{ model.description }}
          </p>
          <div class="py-6">
            <p class="text-base font-light">
              {{ model.main }}
            </p>
          </div>
          <div v-if="model.parameters">
            <parameters-template :model="model.parameters" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>