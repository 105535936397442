<script>
import { reactive, onMounted } from "@vue/composition-api";
import { saveUserData, authCallback } from "src/plugins/prototype";
// APIs
import { API } from "src/views/auth/api";

export default {
  name: "SignupStepFinal",
  setup(props, ctx) {
    const controls = reactive({
      status: "loading",
      error: null
    })
    const signUp = async () => {
      controls.status = "loading";
      controls.error = null;
      const payload = ctx.root.$store.state.signUpForm;
      try {
        const { data } = await API.signUp({
          email: payload.email,
          password: payload.password,
          occupation: payload.occupation,
          phone_number: payload.phone_number,
          first_name: payload.first_name,
          last_name: payload.last_name,
          plan: payload.plan,
          subscribe: payload.subscribe,
          agreement_sign_at: payload.agreement_sign_at,
        });
        console.log("signup api result: ", data);
        saveUserData(data);
        authCallback();
        controls.status = "success";
        ctx.root.$store.commit("resetSignUpForm");
      } catch (error) {
        controls.status = "error";
        controls.error = error.message;
        ctx.root.$openNotification("Sign Up Error", error.message, 10, "error");
        console.log("signup error: ", error);
      }
    };
    onMounted(() => {
      signUp();
    });
    return {
      controls
    };
  },
};
</script>
<template>
  <div class="w-full overflow-hidden pt-6" style="min-height: 460px">
    <div v-if="controls.status === 'success'" class="flex flex-col overflow-auto space-y-4">
      <div class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer" style="min-height: 321px">
        <div class="flex flex-col px-6 pt-6">
          <span class="text-xl text-center font-bold" style="color: #f75676">Congratulations</span>
          <div class="flex items-center justify-center mx-auto rounded-full w-24 h-24 mt-12 border-2 border-parqay-primary">
            <span class="" style="font-size: 60px">🎉</span>
          </div>
          <span class="text-base text-center font-medium text-black mt-6">Everything is set. Thanks for Joining Parqay
            Host Community!</span>
        </div>
      </div>

      <div class="flex space-x-4">
        <router-link tag="button" to="/dashboard"
          class="text-base bg-blue-500 hover:bg-blue-700 rounded-lg text-white px-4 py-2 w-full">
          Go to Dashboard
        </router-link>
      </div>
    </div>
    <div v-if="controls.status === 'error'" class="flex flex-col overflow-auto space-y-4">
      <div class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer" style="min-height: 321px">
        <div class="flex flex-col items-center px-6 pt-6">
          <span class="text-xl text-orange-400 text-center font-bold">Something went wrong...</span>
          <span style="font-size: 60px">
            <a-icon type="info-circle" theme="twoTone" />
          </span>
          <span class="text-base text-center font-medium text-black mt-6">{{ controls.error }}</span>
        </div>
      </div>

      <div class="flex space-x-4">
        <button class="text-base bg-blue-500 hover:bg-blue-700 rounded-lg text-white px-4 py-2 w-full"
          @click="$emit('prev')">
          Go back and edit
        </button>
      </div>
    </div>
    <div v-if="controls.status === 'loading'" class="flex flex-col overflow-auto space-y-4">
      <div
        class="flex items-center justify-center rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer"
        style="min-height: 321px">
        <div class="flex flex-col items-center justify-center">
          <svg-icon icon-class="circular-loader" class="w-24 h-24 animate-spin text-blue-500" />
        </div>
      </div>
    </div>
  </div>
</template>