<script>
import Availability from "../components/components/AvailabilityView.vue";
import CostAndMileageLimits from "src/views/listings/new/car-rentals/components/CostAndMileageLimits";
import { getDefaultModelForMode as getDefaultAvailabilityModelForMode } from "src/views/listings/detail/availability/util";
import { getDefaultModel as getDefaultPriceMileageModel } from "src/views/listings/new/car-rentals/components/util";
import { reactive, ref } from "@vue/composition-api";
import lowerCase from 'lodash/lowerCase';

export default {
  name: "VehicleSchedulePriceMileage",
  components: {
    Availability,
    CostAndMileageLimits
  },
  setup(props, ctx) {
    const openNotification = ctx.root.$openNotification;
    const availabilityModel = reactive(
      getDefaultAvailabilityModelForMode("simple")
    );
    const priceMileageModel = reactive(getDefaultPriceMileageModel());
    const pricePackage = ref(2);
    const receivePackage = ref(false);
    const next = () => {
      transferFormPayload();
      ctx.emit("next");
    };
    const transferFormPayload = () => {
      ctx.emit("payload-update", "schedule-price-mileage", assemblePayloads());
    };

    const assembleSchedules = () => {
      const schedulePayload = {
        mode: availabilityModel.mode,
        simple: {},
        manual: []
      };
      switch (availabilityModel.mode) {
        case "simple":
          schedulePayload.simple = availabilityModel.simple;
          // Change assemble rules, IF NEEDED.
          break;
        case "manual":
          availabilityModel.manual.forEach((v, i) => {
            if (v.day && v.from && v.to) {
              schedulePayload.manual.push({
                day: lowerCase(v.day),
                from: v.from,
                to: v.to,
                active: v.active
              });
            }
          });
          break;
        default:
          break;
      }
      return schedulePayload;
    };

    const assemblePriceMileage = priceMileages => {
      let payload = [];
      for (const pm of priceMileages) {
        switch (pm.type) {
          case "monthly":
            payload.push({
              price: pm.price,
              every: { amount: 1, unit: "month" },
              enabled: pm.enabled,
              mileage: pm.mileage
            });
            break;
          case "daily":
            payload.push({
              price: pm.price,
              every: { amount: 1, unit: "day" },
              enabled: pm.enabled,
              mileage: pm.mileage
            });
            break;
          case "hourly":
            payload.push({
              price: pm.price,
              every: { amount: 1, unit: "hour" },
              enabled: pm.enabled,
              mileage: pm.mileage
            });
            break;
          default:
            break;
        }
      }
      return payload;
    };

    const assemblePayloads = () => {
      // Backend is using snake-case as its framework is Python
      return {
        price_mileage: assemblePriceMileage(priceMileageModel),
        schedule: assembleSchedules(),
        receive_package: receivePackage.value,
        package_price: pricePackage.value
      };
    };
    return {
      availabilityModel,
      priceMileageModel,
      pricePackage,
      receivePackage,
      next
    };
  }
};
</script>
<template>
  <div class="flex flex-col px-24 py-6">
    <div class="flex items-center justify-between border-b px-4">
      <h2 class="my-3 text-center text-lg text-parqay-primary font-medium">
        {{ $t("carListing.schedule") }}
      </h2>
      <button class="px-4 py-2 bg-parqay-primary text-white rounded" @click="next">
        {{ $t("carListing.continue") }}
      </button>
    </div>
    <div class="flex flex-col justify-center pb-4 border-b">
      <div class="grid grid-cols-8">
        <availability class="col-span-4 border-r" :model="availabilityModel" />
        <div class="col-span-4 px-8 py-12">
          <div class="flex flex-col space-y-4">
            <h2 class="text-center font-medium">
              Cost and Mileage Limits
            </h2>
            <cost-and-mileage-limits :model="priceMileageModel">
              <!-- <div class="flex flex-col space-y-2 my-4">
                <h2 class="text-center font-medium">
                  {{ $t("carListing.package") }}
                </h2>
                <h3 class="text-center text-gray-600 text-sm">
                  {{ $t("carListing.packageDetails") }}
                </h3>
                <div class="flex justify-center items-center space-x-4">
                  <span class="font-medium"> Receive package? </span>
                  <a-switch
                    v-model="receivePackage"
                    checked-children="Yes"
                    un-checked-children="No"
                  />
                  <span class="font-medium"> Price per package </span>
                  <a-input-number
                    :default-value="pricePackage"
                    :formatter="
                      (value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    "
                    :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
                  />
                </div>
              </div> -->
            </cost-and-mileage-limits>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end mb-5 py-2 px-4">
      <button class="px-4 py-2 bg-parqay-primary text-white rounded" @click="next">
        {{ $t("carListing.continue") }}
      </button>
    </div>
  </div>
</template>
