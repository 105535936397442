<script>
import { reactive, ref, watch, nextTick, onMounted, onBeforeUnmount } from "@vue/composition-api";
import EvOptionSlide from "./EvOptionSlide.vue";
import SendInvites from "src/views/documents/components/SendInvites.vue";
import { API } from "src/views/listings/api";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

export default {
  components: {
    EvOptionSlide,
    "send-invites-view": SendInvites,
  },
  props: {
    form: {
      type: Object,
      require: true
    }
  },
  setup(props, { root, emit }) {

    let updateDict = {
      amenities: [], // Amenities need to preserve order
      what_can_you_fit: {}
    }

    const controls = reactive({
      loading: {
        save: false
      },
      visible: {
        newTagInput: false,
        sendInvites: false
      },
      editMode: {
        amenities: false
      },
      newTagInputValue: null
    })

    const settings = ref(cloneDeep(props.form.settings));

    const settingsCopy = ref(cloneDeep(props.form.settings));

    const shouldShowSaveButton = ref(false);

    watch(() => props.form.settings, val => {
      settingsCopy.value = cloneDeep(val);
      settings.value = cloneDeep(val);
    },
      {
        deep: true
      })

    watch(
      () => settings.value,
      (updatedValue, oldValue) => {
        console.log("change detected...", oldValue);
        if (!isEqual(updatedValue, settingsCopy.value)) {
          shouldShowSaveButton.value = true;
        } else {
          shouldShowSaveButton.value = false;
        }
        if (!isEqual(updatedValue.amenities, settingsCopy.value.amenities)) {
          updateDict.amenities = updatedValue.amenities;
        } else {
          updateDict.amenities = []
        }
      },
      {
        deep: true,
      }
    );

    watch(() => settings.value.things_your_guests_should_know, (newVal, oldVal) => {
      console.log("things_your_guests_should_know changed -----> ", newVal, oldVal);
      if (newVal !== settingsCopy.value.things_your_guests_should_know) {
        updateDict.things_your_guests_should_know = newVal;
      } else {
        delete updateDict.things_your_guests_should_know;
      }
    })

    watch(() => settings.value.how_do_guests_enter_instruction, (newVal, oldVal) => {
      console.log("how_do_guests_enter_instruction changed -----> ", newVal, oldVal);
      if (newVal !== settingsCopy.value.how_do_guests_enter_instruction) {
        updateDict.how_do_guests_enter_instruction = newVal;
      } else {
        delete updateDict.how_do_guests_enter_instruction;
      }
    })

    watch(() => settings.value.about_host, (newVal, oldVal) => {
      console.log("about_host changed -----> ", newVal, oldVal);
      if (newVal !== settingsCopy.value.about_host) {
        updateDict.about_host = newVal;
      } else {
        delete updateDict.about_host;
      }
    })

    watch(() => settings.value.nearby_landmarks, (newVal, oldVal) => {
      console.log("nearby_landmarks changed -----> ", newVal, oldVal);
      if (newVal !== settingsCopy.value.nearby_landmarks) {
        updateDict.nearby_landmarks = newVal;
      } else {
        delete updateDict.nearby_landmarks;
      }
    })

    const setEvOptions = (opt) => {
      settings.value.what_can_you_fit.find(v => v.EV_options).EV_options = opt;
      if (opt !== settingsCopy.value.what_can_you_fit.find(v => v.EV_options).EV_options) {
        updateDict.EV_options = opt;
      } else {
        delete updateDict.EV_options;
      }
    }

    const getEvOptions = () => {
      let evOptions = { networks: [], plug_types: [] };
      if (settings.what_can_you_fit) {
        evOptions = settings.value.what_can_you_fit.find(v => v.EV_options).EV_options;
      }
      return evOptions
    }

    const getEvOptionsCount = () => {
      const evOptions = getEvOptions();
      return evOptions.plug_types.length + evOptions.networks.length;
    }

    const onCheckboxChange = (onOff, payload, section) => {
      const target = settingsCopy.value[section].find(v => v.id === payload.id);
      if (target) {
        if (target.checked !== onOff) {
          root.$deepSet(updateDict, `${section}.${payload.id}`, onOff);
        } else {
          delete updateDict[section][payload.id];
        }
      }
      console.log("onCheckboxChange -----> ", updateDict);
    }

    const saveChanges = async () => {
      controls.loading = true;
      try {
        const { data } = await API.updateSettings(root.$route.params.id, updateDict);
        settings.value = cloneDeep(data.settings);
        settingsCopy.value = cloneDeep(data.settings);
        emit("update-form", data);
      } catch (error) {
        console.log(error)
      } finally {
        setTimeout(() => {
          controls.loading = false;
        }, 1500);
      }
    }

    const resetChanges = () => {
      settings.value = cloneDeep(settingsCopy.value);
      updateDict = {
        amenities: {},
        what_can_you_fit: {}
      }
    }

    const handleTagClose = (removedTag, section) => {
      const ts = settings.value[section].filter(tag => tag !== removedTag);
      console.log(ts);
      settings.value[section] = ts;
    }

    const showTagInput = ($refs) => {
      controls.visible.newTagInput = true;
      nextTick(() => {
        $refs.input.focus();
      })
    }

    const handleTagInputChange = (e) => {
      controls.newTagInputValue = e.target.value;
    }

    const handleTagInputConfirm = () => {
      if (controls.newTagInputValue && settings.value.amenities.indexOf(controls.newTagInputValue) === -1) {
        settings.value.amenities = [...settings.value.amenities, {
          id: root.$shortUID(),
          name: controls.newTagInputValue,
          checked: true
        }];
      }
      controls.visible.newTagInput = false;
      controls.newTagInputValue = null;
    }

    onMounted(() => {

    })

    onBeforeUnmount(() => {

    })

    return {
      controls,
      settings,
      shouldShowSaveButton,
      getEvOptions,
      setEvOptions,
      getEvOptionsCount,
      onCheckboxChange,
      saveChanges,
      resetChanges,
      handleTagClose,
      showTagInput,
      handleTagInputChange,
      handleTagInputConfirm,
      console
    };
  },
};
</script>
<template>
  <div class="py-6">
    <div class="rounded bg-white shadow-sm border mb-24">
      <h4 class="border-b text-parqay-primary text-lg font-medium my-auto p-3">
        {{ $deepGet(form, "summary.title") }}
      </h4>
      <div class="px-8 pt-4 pb-12 flex flex-wrap border-b">
        <div class="w-1/2 py-2">
          <label-item label="Amenities">
            <template #actions>
              <div v-if="controls.editMode.amenities" class="px-4">
                <a-button type="primary" size="small" @click="() => controls.editMode.amenities = false">Done</a-button>
              </div>
            </template>
            <div class="flex flex-wrap">
              <template v-if="!controls.editMode.amenities">
                <div v-for="amenity in settings.amenities" :key="amenity.id" class="w-1/2 py-1">
                  <a-checkbox v-model="amenity.checked"
                    @change="e => onCheckboxChange(e.target.checked, amenity, 'amenities')">
                    {{ amenity.name }}
                  </a-checkbox>
                </div>
                <div class="w-1/2 py-1">
                  <a-button v-if="!controls.editMode.amenities" icon="edit" size="small" class="edit-amenities-btn"
                    @click="() => controls.editMode.amenities = true">
                    Edit Amenities
                  </a-button>
                </div>
              </template>
              <template v-else>
                <div v-for="(tag, i) in settings.amenities" :key="`tag-${tag.name}`" class="w-1/2 py-1">
                  <a-tag closable @close="() => handleTagClose(tag, 'amenities')">
                    {{ tag.name }}
                  </a-tag>
                </div>
                <div class="w-1/2 py-1">
                  <a-input v-if="controls.visible.newTagInput" ref="input" type="text" size="small"
                    :style="{ width: '78px' }" :value="controls.newTagInputValue" @change="handleTagInputChange"
                    @blur="handleTagInputConfirm" @keyup.enter="handleTagInputConfirm" />
                  <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showTagInput($refs)">
                    <a-icon type="plus" /> New Amenity
                  </a-tag>
                </div>
              </template>
            </div>
          </label-item>
        </div>
        <div v-if="settings.what_can_you_fit" class="w-1/2 py-2">
          <label-item label="What can you fit?">
            <div class="grid grid-cols-2 gap-2">
              <div v-for="(it, i) in settings.what_can_you_fit" :key="`what-can-you-fit-${it.id}`"
                class="flex items-center">
                <template v-if="i == 0">
                  <a-checkbox v-model="it.checked"
                    @change="e => onCheckboxChange(e.target.checked, it, 'what_can_you_fit')">
                    {{ it.name }}
                  </a-checkbox>
                  <a-button type="link" @click.stop="$refs['ev-option-slide'].show()">Options</a-button>
                </template>
                <template v-else>
                  <a-checkbox v-model="it.checked"
                    @change="e => onCheckboxChange(e.target.checked, it, 'what_can_you_fit')">
                    {{ it.name }}
                  </a-checkbox>
                </template>
              </div>
            </div>
          </label-item>
        </div>
        <div v-if="settings.kitchen" class="w-1/2 py-2">
          <label-item label="Kitchen Amenities">
            <div class="grid grid-cols-2 gap-2">
              <div v-for="(it, i) in settings.kitchen" :key="`kitchen-${it.id}`">
                <a-checkbox v-model="it.checked" @change="e => onCheckboxChange(e.target.checked, it, 'kitchen')">
                  {{ it.name }}
                </a-checkbox>
              </div>
            </div>
          </label-item>
        </div>
        <div v-if="settings.bathroom" class="w-1/2 py-2">
          <label-item label="Bathroom Amenities">
            <div class="grid grid-cols-2 gap-2">
              <div v-for="(it, i) in settings.bathroom" :key="`bathroom-${it.id}`">
                <a-checkbox v-model="it.checked" @change="e => onCheckboxChange(e.target.checked, it, 'bathroom')">
                  {{ it.name }}
                </a-checkbox>
              </div>
            </div>
          </label-item>
        </div>
        <div v-if="settings.safety_items" class="w-1/2 py-2">
          <label-item label="Safety Items">
            <div class="grid grid-cols-2 gap-2">
              <div v-for="(it, i) in settings.safety_items" :key="`safety-items-${it.id}`">
                <a-checkbox v-model="it.checked" @change="e => onCheckboxChange(e.target.checked, it, 'safety_items')">
                  {{ it.name }}
                </a-checkbox>
              </div>
            </div>
          </label-item>
        </div>
        <div v-if="settings.vehicle_size" class="w-1/2 py-2">
          <label-item label="Vehicle Size">
            <div class="grid grid-cols-2 gap-2">
              <div v-for="(it, i) in settings.vehicle_size" :key="`vehicle-size-${it.id}`">
                <a-checkbox v-model="it.checked" @change="e => onCheckboxChange(e.target.checked, it, 'vehicle_size')">
                  {{ it.name }}
                </a-checkbox>
              </div>
            </div>
          </label-item>
        </div>
        <div v-if="settings.power" class="w-1/2 py-2">
          <label-item label="Vehicle Power">
            <div class="grid grid-cols-2 gap-2">
              <div v-for="(it, i) in settings.power" :key="`vehicle-power-${it.id}`">
                <a-checkbox v-model="it.checked" @change="e => onCheckboxChange(e.target.checked, it, 'power')">
                  {{ it.name }}
                </a-checkbox>
              </div>
            </div>
          </label-item>
        </div>
        <div class="w-1/2 py-2">
          <label-item label="Documents to Collect">
            <template #actions>
              <div class="flex items-center px-4">
                <a-button type="link" icon="mail" @click.stop="() => controls.visible.sendInvites = true">
                  Send Invites
                </a-button>
                <a-modal v-model="controls.visible.sendInvites" title="Document Invitation" destroy-on-close
                  :footer="null">
                  <send-invites-view :templates="settings.documents_to_collect.filter(v => {
                    if (v.type === 'sign' && !v.is_reviewed) {
                      return false
                    }
                    if (v.type === 'identity') {
                      return false
                    }
                    return true
                  })" @cancel="() => controls.visible.sendInvites = false"
                    @dismiss="() => controls.visible.sendInvites = false" />
                </a-modal>
                <a-button type="link" icon="setting" @click.stop="$router.push('/dashboard/documents/templates')">Manage
                  Documents</a-button>
              </div>
            </template>
            <div class="grid grid-cols-2 gap-2 pt-2">
              <div v-for="doc in settings.documents_to_collect" :key="`doc-${doc.id}`">
                <div v-if="doc.checked" class="flex items-center space-x-4">
                  <a-icon type="check-circle" theme="twoTone" />
                  <span v-if="doc.type !== 'identity'"
                    class="text-blue-500 hover:text-parqay-primary hover:font-semibold cursor-pointer"
                    @click.stop="$bus.$emit('open-document-template-modal', doc)">{{
                      doc.name }}
                    <a-icon type="edit" />
                  </span>
                  <span v-else>{{
                    doc.name }}
                  </span>
                </div>
                <div v-else class="flex items-center space-x-4">
                  <a-icon type="close-circle" theme="twoTone" />
                  <span class="hover:text-parqay-primary hover:font-semibold cursor-pointer"
                    @click.stop="$bus.$emit('open-document-template-modal', doc)">{{
                      doc.name }}</span>
                </div>
              </div>
            </div>
          </label-item>
        </div>
      </div>

      <div class="px-6 py-4 flex space-x-8">
        <label-item label="About Yourself"
          desc="Inform your tenants about yourself as a host. This setting populates as &quot;About Host&quot; section in listing search result."
          class="w-1/2">
          <div class="flex items-center space-x-2 cursor-pointer" slot="actions"
            @click.stop="$router.push('/dashboard/account-settings#listing-settings')">
            <template v-if="settings.about_host_is_active">
              <div class="w-2 h-2 rounded-full bg-green-400" />
              <span class="text-xs font-semibold text-green-400 hover:text-green-500">Use Global Override</span>
            </template>
            <template v-else>
              <div class="w-2 h-2 rounded-full bg-pink-500" />
              <span class="text-xs font-semibold text-pink-500 hover:text-pink-400">Global Override Disabled</span>
            </template>
            <a-button type="link" icon="edit" />
          </div>
          <div v-if="settings.about_host_is_active" class="border border-gray-300 rounded px-4 py-2 text-sm font-medium">
            <span class="leading-6">
              {{ settings.about_host }}
            </span>
            <a-button type="link" icon="edit" size="small"
              @click.stop="$router.push('/dashboard/account-settings#listing-settings')">Edit</a-button>
          </div>
          <a-textarea v-else v-model="settings.about_host" placeholder="Tell tenants more about yourself as a host."
            :auto-size="{ minRows: 4, maxRows: 4 }" />
        </label-item>
        <label-item label="Things your guests should know"
          desc="Enter any essential information for tenants. This setting shows in &quot;Things your guests should know&quot; section in listing search result."
          class="w-1/2">
          <div class="flex items-center space-x-2 cursor-pointer" slot="actions"
            @click.stop="$router.push('/dashboard/account-settings#listing-settings')">
            <template v-if="settings.things_your_guests_should_know_is_active">
              <div class="w-2 h-2 rounded-full bg-green-400" />
              <span class="text-xs font-semibold text-green-400 hover:text-green-500">Use Global Override</span>
            </template>
            <template v-else>
              <div class="w-2 h-2 rounded-full bg-pink-500" />
              <span class="text-xs font-semibold text-pink-500 hover:text-pink-400">Global Override Disabled</span>
            </template>
            <a-button type="link" icon="edit" />
          </div>
          <div v-if="settings.things_your_guests_should_know_is_active"
            class="border border-gray-300 rounded px-4 py-2 text-sm font-medium">
            <span class="leading-6">
              {{ settings.things_your_guests_should_know }}
            </span>
            <a-button type="link" icon="edit" size="small"
              @click.stop="$router.push('/dashboard/account-settings#listing-settings')">Edit</a-button>
          </div>
          <a-textarea v-else v-model="settings.things_your_guests_should_know"
            placeholder="Enter any info your tenants need to pay special attention."
            :auto-size="{ minRows: 4, maxRows: 4 }" />
        </label-item>
      </div>

      <div class="px-6 py-4 flex space-x-8">
        <label-item label="How your guest get to this place"
          desc="This will show up in &quot;Getting There&quot; section in listing search. Enter any specific guidance to assistant guests enter this place."
          class="w-1/2">
          <a-textarea v-model="settings.how_do_guests_enter_instruction"
            placeholder="Enter any specific guidance to assistant guests enter this place."
            :auto-size="{ minRows: 4, maxRows: 4 }" :disabled="settings.how_do_guests_enter_instruction === null" />
        </label-item>
        <label-item label="How your guest get to this place"
          desc="This will show up in &quot;Nearby Landmarks&quot; section in listing search. Provide descriptions for nearby landmarks."
          class="w-1/2">
          <a-textarea v-model="settings.nearby_landmarks" placeholder="Provide descriptions for nearby landmarks."
            :auto-size="{ minRows: 4, maxRows: 4 }" :disabled="settings.nearby_landmarks === null" />
        </label-item>
      </div>
    </div>
    <EvOptionSlide ref="ev-option-slide" :options="getEvOptions()" @ev-option-update="setEvOptions" />
    <!-- footer -->
    <div v-if="shouldShowSaveButton" class="fixed bottom-0 right-0 bg-parqay-primary border-t text-right py-2 px-10"
      :style="{ left: `${$menuWidth()}px` }">
      <div class="flex items-center space-x-4 w-full justify-end pr-12">
        <a class="text-white hover:text-yellow-300 text-sm font-medium" @click="resetChanges()">
          Reset changes
        </a>
        <button class="
                                                          flex
                                                          items-center
                                                          space-x-2
                                                          rounded
                                                          bg-white
                                                          border
                                                          hover:bg-blue-500
                                                          text-parqay-primary
                                                          hover:text-white hover:border-white
                                                          px-6
                                                          py-2
                                                          text-base
                                                          font-medium
                                                        " @click="saveChanges()">
          <span>Save Changes</span>
          <a-icon v-if="controls.loading.save" type="loading" />
        </button>
      </div>
    </div>
    <document-template-modal />
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .anticon {
    vertical-align: unset;
  }

  .label-item {
    .label {
      @apply text-black font-semibold;
    }
  }
}

.edit-amenities-btn {
  @apply flex items-center;
}
</style>