<script>
import {
  ref,
  onMounted,
  reactive,
  nextTick,
} from "@vue/composition-api";
import hljs from "highlight.js/lib/core";
import DocsHeader from "src/views/docs/Header";
import CategoryBar from "src/views/docs/Category";
import LandingSection from "src/views/docs/Landing";
import EndpointTemplate from "src/views/docs/templates/endpoint/View";
import { docsContent } from "src/views/docs/data/index";
import Scrollbar from "smooth-scrollbar";
import isEmpty from "lodash/isEmpty";

export default {
  name: "docs",
  components: {
    DocsHeader,
    CategoryBar,
    LandingSection,
    EndpointTemplate,
  },
  props: {},
  setup(props, ctx) {
    const router = ctx.root.$router;
    const route = ctx.root.$route;
    const hash = route.params.sectionName;
    const sectionAnchor = ref("");
    const transitionName = ref("fade");
    const menuItems = reactive(docsContent);
    const scrollToSection = (anchor) => {
      document.getElementById(anchor).scrollIntoView({ behavior: "smooth" });
      var baseRoute;
      if (route.params.sectionName) {
        baseRoute = route.path.split("/").slice(0, -1).join("/");
      } else {
        baseRoute = route.path;
      }
      history.pushState({}, null, baseRoute + "/" + anchor);
      sectionAnchor.value = anchor;

      const meta = ctx.root.$metaInfoGenerator()

      nextTick(() => {
        document.title = meta.title;
      });
    };
    const optimizeCodeHighlightScrollBar = () => {
      document.querySelectorAll("pre code.hljs").forEach((el, i) => {
        Scrollbar.init(el);
      });
    };
    onMounted(() => {
      let timer;
      window.addEventListener(
        "scroll",
        () => {
          clearTimeout(timer);

          timer = setTimeout(() => {
            console.log("scroll end");
            // window.scrollTo({ top: 0, behavior: 'smooth' })
          }, 300);
        },
        { passive: true }
      );
    });
    nextTick(() => {
      hljs.highlightAll();
      optimizeCodeHighlightScrollBar();
      if (!isEmpty(hash)) {
        scrollToSection(hash);
      }
      // setTimeout(() => {
      //   const hashName = startCase(sectionAnchor.value);
      //   const meta = ctx.root.$metaInfoGenerator(window.location.pathname, hashName); 
      //   document.title = meta.title;
      // }, 300);
    });
    return {
      transitionName,
      menuItems,
      scrollToSection,
      window,
    };
  },
};
</script>
<template>
  <div class="flex flex-col h-screen">
    <docs-header @scroll-to-section="(section) => scrollToSection(section)" />
    <div class="flex bg-white w-screen overflow-hidden" :style="{ 'margin-top': '4rem' }">
      <category-bar :menu-items="menuItems" @scroll-to-section="(section) => scrollToSection(section)" />
      <div :style="{ height: 'calc(100%-4rem)' }">
        <smooth-scroll>
          <transition :name="transitionName">
            <div class="flex flex-col space-y-12 divide-y">
              <landing-section @scroll-to-section="(section) => scrollToSection(section)" />

              <div v-for="(it, i) in menuItems.main" :id="it.anchor" :key="`page-main-${i}`"
                class="dracula flex flex-col h-screen px-6 py-6">
                <span class="text-xl font-bold text-black leading-12">{{
                it.name
                }}</span>
                <span class="text-base text-gray-500 leading-12">Example Doc</span>
                <highlightjs v-if="it.sourcecode" class="rounded-xl" autodetect :code="it.sourcecode" />
              </div>
              <template v-for="(it, i) in menuItems.secondary">
                <div :id="it.anchor" :key="`page-secondary-${i}`" class="flex flex-col h-screen px-6 py-6">
                  <span class="text-xl font-bold text-black leading-12">{{
                  it.name
                  }}</span>
                  <span class="text-base text-gray-500 leading-12">Example Doc</span>
                </div>
                <template v-for="(sub, j) in it.sub">
                  <template v-if="sub.template == 'endpoint'">
                    <endpoint-template :key="`page-sub-${i}-${j}`" :model="sub"
                      @scroll-to-section="(section) => scrollToSection(section)" />
                  </template>
                  <template v-else>
                    <div :id="sub.anchor" :key="`page-sub-${i}-${j}`" class="flex flex-col h-screen px-6 py-6">
                      <span class="text-xl font-bold text-black leading-12">{{
                      sub.name
                      }}</span>
                      <span class="text-base text-gray-500 leading-12">Example Doc</span>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </transition>
        </smooth-scroll>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
/*!
  Theme: Dracula
  Author: Mike Barkmin (http://github.com/mikebarkmin) based on Dracula Theme (http://github.com/dracula)
  License: ~ MIT (or more permissive) [via base16-schemes-source]
  Maintainer: @highlightjs/core-team
  Version: 2021.05.0
*/
::v-deep .dracula {
  pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1em;
  }

  code.hljs {
    padding: 3px 5px;
  }

  .hljs {
    color: #e9e9f4;
    background: #282936;
  }

  .hljs ::selection {
    color: #4d4f68;
  }

  .hljs-comment {
    color: #626483;
  }

  .hljs-tag {
    color: #62d6e8;
  }

  .hljs-operator,
  .hljs-punctuation,
  .hljs-subst {
    color: #e9e9f4;
  }

  .hljs-operator {
    opacity: 0.7;
  }

  .hljs-bullet,
  .hljs-deletion,
  .hljs-name,
  .hljs-selector-tag,
  .hljs-template-variable,
  .hljs-variable {
    color: #ea51b2;
  }

  .hljs-attr,
  .hljs-link,
  .hljs-literal,
  .hljs-number,
  .hljs-symbol,
  .hljs-variable.constant_ {
    color: #b45bcf;
  }

  .hljs-class .hljs-title,
  .hljs-title,
  .hljs-title.class_ {
    color: #00f769;
  }

  .hljs-strong {
    font-weight: 700;
    color: #00f769;
  }

  .hljs-addition,
  .hljs-code,
  .hljs-string,
  .hljs-title.class_.inherited__ {
    color: #ebff87;
  }

  .hljs-built_in,
  .hljs-doctag,
  .hljs-keyword.hljs-atrule,
  .hljs-quote,
  .hljs-regexp {
    color: #a1efe4;
  }

  .hljs-attribute,
  .hljs-function .hljs-title,
  .hljs-section,
  .hljs-title.function_,
  .ruby .hljs-property {
    color: #62d6e8;
  }

  .diff .hljs-meta,
  .hljs-keyword,
  .hljs-template-tag,
  .hljs-type {
    color: #b45bcf;
  }

  .hljs-emphasis {
    color: #b45bcf;
    font-style: italic;
  }

  .hljs-meta,
  .hljs-meta .hljs-keyword,
  .hljs-meta .hljs-string {
    color: #00f769;
  }

  .hljs-meta .hljs-keyword,
  .hljs-meta-keyword {
    font-weight: 700;
  }
}
</style>

<style lang="scss" scoped>
/*!
  Theme: Google Light
  Author: Seth Wright (http://sethawright.com)
  License: ~ MIT (or more permissive) [via base16-schemes-source]
  Maintainer: @highlightjs/core-team
  Version: 2021.05.0
*/
::v-deep .google-light {
  pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1em;
  }

  code.hljs {
    padding: 3px 5px;
  }

  .hljs {
    color: #373b41;
    // background: #fff;
    @apply bg-gray-50;
  }

  .hljs ::selection {
    color: #c5c8c6;
  }

  .hljs-comment {
    color: #b4b7b4;
  }

  .hljs-tag {
    color: #969896;
  }

  .hljs-operator,
  .hljs-punctuation,
  .hljs-subst {
    color: #373b41;
  }

  .hljs-operator {
    opacity: 0.7;
  }

  .hljs-bullet,
  .hljs-deletion,
  .hljs-name,
  .hljs-selector-tag,
  .hljs-template-variable,
  .hljs-variable {
    color: #cc342b;
  }

  .hljs-attr,
  .hljs-link,
  .hljs-literal,
  .hljs-number,
  .hljs-symbol,
  .hljs-variable.constant_ {
    color: #f96a38;
  }

  .hljs-class .hljs-title,
  .hljs-title,
  .hljs-title.class_ {
    color: #fba922;
  }

  .hljs-strong {
    font-weight: 700;
    color: #fba922;
  }

  .hljs-addition,
  .hljs-code,
  .hljs-string,
  .hljs-title.class_.inherited__ {
    color: #198844;
  }

  .hljs-attribute,
  .hljs-built_in,
  .hljs-doctag,
  .hljs-function .hljs-title,
  .hljs-keyword.hljs-atrule,
  .hljs-quote,
  .hljs-regexp,
  .hljs-section,
  .hljs-title.function_,
  .ruby .hljs-property {
    color: #3971ed;
  }

  .diff .hljs-meta,
  .hljs-keyword,
  .hljs-template-tag,
  .hljs-type {
    color: #a36ac7;
  }

  .hljs-emphasis {
    color: #a36ac7;
    font-style: italic;
  }

  .hljs-meta,
  .hljs-meta .hljs-keyword,
  .hljs-meta .hljs-string {
    color: #3971ed;
  }

  .hljs-meta .hljs-keyword,
  .hljs-meta-keyword {
    font-weight: 700;
  }
}
</style>