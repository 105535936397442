<script>
import { reactive, ref } from "@vue/composition-api";
import PrimaryContact from "./PrimaryContact.vue";
import Activity from "./Activity.vue";
import AddressEditor from "./AddressEditor.vue";
import ImagesUploader from "src/views/components/ImagesUploader.vue";
import Cars from "./Cars";
import Spaces from "./Spaces";
import { API } from "src/views/listings/api";

export default {
  name: "Summary",
  components: {
    PrimaryContact,
    Activity,
    AddressEditor,
    ImagesUploader,
    Cars,
    Spaces
  },
  props: {
    listingType: {
      type: String,
      default: null
    },
    componentData: {
      type: Object,
      default: null
    },
    form: {
      type: Object,
      require: true
    }
  },
  setup(props, ctx) {

    const controls = reactive({
      visible: {
        titleEditor: false
      },
      selectedEventInsightsMode: "views",
      isCopied: false
    })

    const imageUpdateAPI = API.addImageUrlToListing;

    const copyMarketingURL = (url) => {
      controls.isCopied = true;
      ctx.root.$copyToClipboard(addHttpsPrefixIfNeeded(url));
      setTimeout(() => {
        controls.isCopied = false;
      }, 800);
    }

    const addHttpsPrefixIfNeeded = (url) => {
      if (!url.startsWith("https://")) {
        url = `https://${url}`
      }
      return url;
    }

    const getTypeName = () => {
      let typeName = "";
      switch (props.form.type) {
        case "parking":
          typeName = "Parking Space"
          break;
        case "stay":
          typeName = "Stay"
          break;
        case "coworking":
          typeName = "Coworking Office"
          break;
        case "car-rental":
          typeName = "Rental Vehicle"
          break;
        default:
          break;
      }
      return typeName;
    }

    const updateListingTitle = async () => {
      if (!props.componentData.title) {
        ctx.root.$openNotification(
          "Update Listing",
          "Listing title cannot be empty."
        )
        return
      }
      try {
        await API.updateTitle(props.form.id, props.componentData.title);
        controls.visible.titleEditor = false;
        ctx.emit("refresh");
      } catch (error) {
        ctx.root.$openNotification(
          "Update Listing Error",
          error.message
        )
      }
    }

    return {
      controls,
      imageUpdateAPI,
      copyMarketingURL,
      addHttpsPrefixIfNeeded,
      getTypeName,
      updateListingTitle
    };
  }
};
</script>
<template>
  <div class="py-2">
    <div v-if="listingType != 'car-rental'" class="grid grid-cols-2 gap-4">
      <div class="rounded-lg shadow-sm border bg-white">
        <div class="flex items-center font-semibold text-base text-black border-b p-4 space-x-2">
          <a-tag color="geekblue">{{ getTypeName() }}</a-tag>
          <span>{{ componentData.title }}</span>
          <a-icon slot="suffix" type="edit" style="color: gray;" @click="() => controls.visible.titleEditor = true" />
        </div>
        <a-modal :visible="controls.visible.titleEditor" title="Update Title" ok-text="Save" @ok="updateListingTitle()"
          @cancel="() => controls.visible.titleEditor = false">
          <a-input v-model="componentData.title" allow-clear size="large" />
        </a-modal>
        <div class="border-b p-4">
          <div class="flex items-center justify-between space-x-2 px-3 py-1.5">
            <span class="text-sm font-medium">Marketing URL</span>
            <a-tooltip placement="top">
              <template slot="title">
                <span>
                  {{ controls.isCopied ? "Copied!" : "Click to Copy" }}
                </span>
              </template>
              <div class="flex items-center space-x-2">
                <span v-if="form.marketing_url"
                  class="text-xs font-semibold px-2 py-1 text-parqay-primary hover:text-black bg-blue-100 hover:bg-white border border-blue-100 hover:border-black rounded cursor-pointer truncate"
                  :style="{ maxWidth: '260px' }" @click="copyMarketingURL(form.marketing_url)">{{
                    addHttpsPrefixIfNeeded(form.marketing_url)
                  }}
                </span>
                <a-icon type="copy" theme="twoTone" />
              </div>
            </a-tooltip>
          </div>
        </div>
        <div class="border-b p-4">
          <span class="text-sm font-medium px-2">
            Address
          </span>
          <address-editor :location-model="componentData.location" />
        </div>
        <div class="border-b p-4">
          <spaces :model="componentData" :form="form" @change-tab="tab => $emit('change-tab', tab)"
            @refresh="$emit('refresh')" />
        </div>
        <div class="p-4">
          <primary-contact :form="componentData.primary_contact" @refresh="$emit('refresh')" />
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <div class="flex flex-col rounded-lg shadow-sm border bg-white">
          <span class="font-semibold text-base border-b p-4">
            Summary
          </span>

          <activity :model="componentData.activities" :location-model="componentData.location"
            :listing-type="listingType" />
        </div>

        <div class="rounded-lg shadow-sm border bg-white overflow-hidden">
          <div class="flex items-center justify-between border-b p-4">
            <span class="font-semibold text-base">
              Event Traffic
            </span>
            <div v-if="$deepGet(form, 'eventInsights')" class="flex items-center space-x-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span class="text-sm font-medium">
                {{ $deepGet(form, 'eventInsights.total_views_count') }} views from {{ $deepGet(form,
                  'eventInsights.event_pages_count') }} event pages
              </span>
            </div>
          </div>

          <div class="flex flex-col px-4 py-2 bg-gray-50">
            <div class="flex items-center justify-between space-x-2">
              <div class="rounded-lg border flex items-center justify-center space-x-2 bg-white px-4 py-4">
                <div class="w-2 h-2 rounded-full bg-parqay-primary"></div>
                <span class="text-base font-bold">
                  Nearby Events: <a class="text-parqay-primary">{{ $deepGet(form, 'eventInsights.nearby_events_count',
                    '--') }}</a>
                </span>
              </div>
              <div class="rounded-lg border flex flex-grow items-center justify-center space-x-2 bg-white px-4 py-4">
                <div class="w-2 h-2 rounded-full bg-orange-400"></div>
                <span class="text-base font-bold">
                  Nearest City: <a class="text-orange-400 text-base">{{ $deepGet(form, 'eventInsights.nearest_city', '--')
                  }}</a>
                </span>
              </div>
            </div>
            <a-divider style="font-size: 1rem;">
              <div class="flex items-center space-x-3">
                <span>Events popularity</span>
                <a-tooltip
                  title="Event popularity indicates potential buyer traffic for your listing on specific occasions, helping to identify events that may attract more buyers to your listing.">
                  <a-icon type="info-circle" theme="twoTone" />
                </a-tooltip>
              </div>
            </a-divider>
            <div class="flex items-center justify-between pb-4">
              <span v-if="controls.selectedEventInsightsMode === 'views'" class="text-sm italic">People are discovering
                this listing through events:</span>
              <span v-if="controls.selectedEventInsightsMode === 'sales'" class="text-sm italic">Tenants booked this
                listing through events:</span>
              <a-radio-group v-model="controls.selectedEventInsightsMode" button-style="solid">
                <a-radio-button value="views">
                  Views
                </a-radio-button>
                <a-radio-button value="sales">
                  Sales
                </a-radio-button>
              </a-radio-group>
            </div>
            <div
              v-if="$deepGet(form, `eventInsights.popular_listings.${controls.selectedEventInsightsMode}`, []).length > 0"
              class="flex flex-col space-y-2">
              <div v-for="l in $deepGet(form, `eventInsights.popular_listings.${controls.selectedEventInsightsMode}`, [])"
                :key="l.id" class="flex items-start justify-between space-x-4">
                <div class="flex items-center space-x-2" style="max-width: 66.67%;">
                  <span class="text-xs ant-tag-geekblue border rounded-md px-3 py-1">
                    {{ l.name }}
                  </span>
                  <span v-if="l.slug === 'nearby'"
                    class="text-xs bg-green-400 hover:bg-green-500 text-white rounded px-1.5 py-1 cursor-pointer"
                    style="font-size: 0.75rem; line-height: 0.75rem;">nearby</span>
                  <span v-if="l.type === 'event'"
                    class="text-xs bg-pink-500 hover:bg-pink-400 text-white rounded px-1.5 py-1 cursor-pointer"
                    style="font-size: 0.75rem; line-height: 0.75rem;">event</span>
                  <span v-if="l.type === 'city'"
                    class="text-xs bg-orange-400 hover:bg-orange-500 text-white rounded px-1.5 py-1 cursor-pointer"
                    style="font-size: 0.75rem; line-height: 0.75rem;">city</span>
                  <span v-if="l.type === 'host'"
                    class="text-xs border border-black hover:border-none hover:bg-gray-500 text-black hover:text-white rounded px-1 py-0.5 cursor-pointer"
                    style="font-size: 0.75rem; line-height: 0.85rem;">white-label</span>
                </div>
                <div class="flex items-center space-x-1">
                  <template v-if="controls.selectedEventInsightsMode === 'views'">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <span class="text-xs font-medium">
                      {{ l.count }} views
                    </span>
                  </template>
                  <template v-if="controls.selectedEventInsightsMode === 'sales'">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="text-xs font-medium">{{ l.count }} booked</span>
                  </template>
                </div>
              </div>
            </div>
            <div v-else class="flex items-center justify-center px-4 pt-1 pb-6">
              <span
                class="rounded-lg border border-gray-300 text-sm font-medium text-gray-500 text-center px-6 py-4 w-full">No
                data available at this time. Please check back later.</span>
            </div>
          </div>
        </div>

        <div class="flex flex-col rounded-lg shadow-sm border bg-white">
          <span class="font-semibold text-base border-b p-4">
            Images
          </span>
          <div class="p-2">
            <images-uploader :model="componentData.images.map(e => (e['url'] ? e.url : e))" instant-upload
              :upload-requester="imageUpdateAPI" :requester-payload="{ listingId: $route.params.id }" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="grid grid-cols-2 gap-4">
      <cars class="col-span-2" :form="form" />
      <div class="rounded-lg shadow-sm border bg-white ml-6">
        <span class="font-semibold text-base text-black border-b p-4">
          {{ componentData.title }}
        </span>
        <div class="border-b p-4">
          <span class="font-medium px-2">
            Address
          </span>
          <address-editor :location-model="componentData.location" />
        </div>
        <div class="border-b p-4">
          <span class="font-medium px-2">
            Max mileage
          </span>
          <div class="flex items-center space-x-2">
            <a-select default-value="1000 Miles" class="w-32">
              <a-select-option value="1000 Miles">
                1000 Miles
              </a-select-option>
            </a-select>
            <span class="mx-4">per</span>
            <a-select default-value="1 day" class="w-32">
              <a-select-option value="1 day">
                1 day
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="p-4">
          <primary-contact :form="componentData.primary_contact" @refresh="$emit('refresh')" />
        </div>
      </div>
      <div class="flex flex-col space-y-4 mr-6">
        <div class="flex flex-col rounded-lg shadow-sm border bg-white">
          <span class="font-semibold text-base border-b p-4">
            Summary
          </span>

          <activity :model="componentData.activities" :location-model="componentData.location"
            :listing-type="listingType" />
        </div>

        <div class="rounded-lg shadow-sm border bg-white">
          <span class="font-semibold text-base border-b p-4">
            Images
          </span>
          <div class="p-2">
            <images-uploader :model="componentData.images.map(e => (e['url'] ? e.url : e))" instant-upload
              :upload-requester="imageUpdateAPI" :requester-payload="{ listingId: $route.params.id }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
