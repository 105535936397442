<script>
import { reactive, nextTick } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";
import MobileHeader from "src/views/landing-pages/mobile/Header.vue";

export default {
  name: "landing-header",
  components: {
    MobileHeader
  },
  directives: {
    ClickOutside,
  },
  setup(props, ctx) {
    const controls = reactive({
      visible: {
        mobileMenu: false,
        accountMenu: false
      },
      userData: ctx.root.$loadUserData()
    });

    const scrollToSection = (anchor) => {
      document
        .getElementById(anchor)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const navToLogo = () => {
      controls.visible.mobileMenu = false;
      if (ctx.root.$route.path !== "/") {
        ctx.root.$router.push("/");
      }
      setTimeout(() => {
        scrollToSection("logo");
      }, 500);
    };

    const navToFeatures = () => {
      controls.visible.mobileMenu = false;
      if (ctx.root.$route.path !== "/") {
        ctx.root.$router.push("/");
      }
      setTimeout(() => {
        scrollToSection("features");
      }, 500);
    };

    nextTick(() => {
      if (ctx.root.$route.hash) {
        console.log("hash: ", ctx.root.$route.hash);
        scrollToSection(ctx.root.$route.hash.replace("#", ""));
      }
    });

    return {
      controls,
      scrollToSection,
      navToLogo,
      navToFeatures,
      window,
    };
  },
};
</script>
<template>
  <header>
    <div id="logo" class="relative bg-white">

      <!-- 
        Desktop header 
      -->

      <div v-if="$isDesktopView()" class="
          flex
          justify-between
          items-center
          max-w-7xl
          mx-auto
          px-4
          pt-3
          pb-2
          sm:px-6
          md:justify-start
          md:space-x-10
          lg:px-8
        ">
        <div class="flex items-center justify-start">
          <router-link tag="a" to="/">
            <svg-icon icon-class="PARQAY-logo" class="h-14 w-auto pr-6" />
          </router-link>
        </div>
        <nav class="hidden md:flex space-x-8 z-1">
          <div class="relative">
            <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
            <button type="button" class="
                text-gray-500
                group
                bg-white
                rounded-md
                inline-flex
                items-center
                text-base
                font-medium
                hover:text-gray-900
                focus:outline-none
              " aria-expanded="false" @click="navToFeatures()">
              <span>Features</span>
            </button>
          </div>

          <a class="text-base font-medium text-gray-500 hover:text-gray-900" @click="() => {
            if ($route.path === '/pricing') {
              $scrollToHashSection('#pricing');
            } else {
              $router.push('/pricing');
            }
          }
            ">
            Pricing
          </a>

          <a class="text-base font-medium text-gray-500 hover:text-gray-900"
            @click="() => window.open('https://parqay.com', '_blank')">
            Marketplace
          </a>

          <a class="text-base font-medium text-gray-500 hover:text-gray-900" @click="() => {
            if ($route.hash === '#demo') {
              $scrollToHashSection($route.hash);
            } else {
              $router.push({ path: 'pricing', hash: '#demo' });
            }
          }
            ">
            Demo
          </a>
          <router-link v-if="false" to="/white-label" class="text-base font-medium text-gray-500 hover:text-gray-900">
            White-Label
          </router-link>
          <router-link to="/docs" class="text-base font-medium text-gray-500 hover:text-gray-900">
            API Doc
          </router-link>
        </nav>
        <div v-if="controls.userData" class="relative flex flex-grow justify-end"
          v-click-outside="() => controls.visible.accountMenu = false">
          <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
          <button type="button"
            class="text-blue-500 group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-blue-700 focus:outline-none"
            aria-expanded="false" @click="() => controls.visible.accountMenu = !controls.visible.accountMenu">
            <span>Hello, {{ $deepGet(controls.userData, 'first_name') }}</span>
            <!--
              Heroicon name: mini/chevron-down

              Item active: "text-gray-600", Item inactive: "text-gray-400"
            -->
            <svg class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clip-rule="evenodd" />
            </svg>
          </button>

          <div v-show="controls.visible.accountMenu"
            class="absolute left-1/2 z-10 mt-8 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
            <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                <a class="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                  @click="() => { controls.visible.accountMenu = false; $router.push('/dashboard'); }">
                  <div class="flex items-center justify-between space-x-2">
                    <span class="text-base font-medium text-gray-900">Dashboard</span>
                    <div v-if="$isAdmin()" class="flex items-center">
                      <div class="w-1.5 h-1.5 bg-pink-500 rounded-full -mr-2 mb-0.5" />
                      <span class="text-black text-sm font-bold border-black pl-4 pr-2 py-1">Admin</span>
                    </div>
                    <a-tag v-else color="orange">Host</a-tag>
                  </div>
                  <span class="mt-1 text-sm text-gray-500">Supercharged sharing economy property management
                    dashboard</span>
                </a>

                <a v-if="!$isAdmin()"
                  class="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                  @click="() => { controls.visible.accountMenu = false; $router.push({ 'name': 'tenant-home' }); }">
                  <div class="flex items-center justify-between space-x-2">
                    <span class="text-base font-medium text-gray-900">Access</span>
                    <a-tag color="cyan">Tenant</a-tag>
                  </div>
                  <span class="mt-1 text-sm text-gray-500">
                    Access portal for property tenant to manage reservations,
                    documents, etc
                  </span>
                </a>

                <a class="-m-3 block rounded-md transition duration-150 ease-in-out hover:bg-gray-50"
                  @click="() => { controls.visible.accountMenu = false; $logout(); controls.userData = null; }">
                  <p align="center" class="m-0 p-3">
                    <span class="text-center text-base font-medium text-pink-500">Log Out</span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="relative hidden md:flex items-center justify-end md:flex-1 lg:w-0"
          v-click-outside="() => controls.visible.accountMenu = false">
          <button class="
          z-20
              ml-8
              whitespace-nowrap
              inline-flex
              items-center
              justify-center
              px-4
              py-2
              border border-transparent
              rounded-md
              shadow-sm
              text-base
              font-medium
              text-white
              bg-gradient-to-r
              from-purple-600
              to-parqay-primary
              hover:from-purple-700
              hover:to-indigo-700
            " @mouseover="() => controls.visible.accountMenu = true" @click="$router.push('/login')">
            Sign in
            <a-icon :type="controls.visible.accountMenu ? 'arrow-right' : 'right'" class="ml-2" />
          </button>
          <div v-show="controls.visible.accountMenu" class="absolute top-4 right-0 z-10 px-2 sm:px-0"
            @mouseover="() => controls.visible.accountMenu = true"
            @mouseleave="() => controls.visible.accountMenu = false">
            <div class="relative overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div class="flex flex-col bg-white pt-8 pb-5 divide-y" :style="{ minWidth: '280px' }">
                <a class="rounded-md px-5 pt-3 pb-2 transition duration-150 ease-in-out hover:bg-gray-50"
                  @click="() => { controls.visible.accountMenu = false; $router.push('/login'); }">
                  <div class="flex flex-col space-y-2">
                    <div class="flex items-center justify-between space-x-2">
                      <span class="text-sm font-medium text-blue-600">Dashboard Login</span>
                      <a-tag color="orange">Host</a-tag>
                    </div>
                    <span class="text-sm text-gray-500">Supercharged sharing economy property management
                      dashboard</span>
                  </div>
                </a>

                <a class="rounded-md px-5 pt-3 pb-2 transition duration-150 ease-in-out hover:bg-gray-50"
                  @click="() => { controls.visible.accountMenu = false; $router.push('/login/tenant'); }">
                  <div class="flex flex-col space-y-2">
                    <div class="flex items-center justify-between space-x-2">
                      <span class="text-sm font-medium text-blue-600">Access Login</span>
                      <a-tag color="cyan">Tenant</a-tag>
                    </div>
                    <span class="text-sm text-gray-500">
                      Access portal for property tenant to manage reservations,
                      documents, etc
                    </span>
                  </div>
                </a>

                <a class="rounded-md px-5 pt-3 pb-2 transition duration-150 ease-in-out hover:bg-gray-50"
                  @click="() => { controls.visible.accountMenu = false; $router.push('/org/login'); }">
                  <div class="flex flex-col space-y-2">
                    <div class="flex items-center justify-between space-x-2">
                      <span class="text-sm font-medium text-blue-600">Organization Login</span>
                      <a-tag color="geekblue">White-label</a-tag>
                    </div>
                    <span class="text-sm text-gray-500">
                      Access portal for organization who activated white-label features
                    </span>
                  </div>
                </a>

                <a class="rounded-md px-5 pt-3 pb-2 transition duration-150 ease-in-out hover:bg-gray-50"
                  @click="() => { controls.visible.accountMenu = false; $router.push('/towing'); }">
                  <div class="flex flex-col space-y-2">
                    <div class="flex items-center justify-between space-x-2">
                      <span class="text-sm font-medium text-blue-600">Towing Login</span>
                      <a-tag color="white" class="towing-tag">Towing ORG</a-tag>
                    </div>
                    <span class="text-sm text-gray-500">
                      Towing company access to earn income from our hosts
                    </span>
                  </div>
                </a>

              </div>
              <a class="absolute z-20 left-5 top-3 text-xs font-medium text-gray-600">Choose an option</a>
            </div>
          </div>
        </div>
      </div>

      <!--
        Mobile header
      -->

      <mobile-header v-else :controls="controls" :funcs="{
        navToLogo,
        navToFeatures
      }" />
    </div>
  </header>
</template>
<style scoped>
.towing-tag {
  @apply border border-black text-black;
}
</style>