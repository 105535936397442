<script>
import Dropdown from "src/views/dropdowns/View";
import { nextTick, onMounted, reactive, ref } from "@vue/composition-api";
import { API } from "src/views/listings/api";

export default {
  components: {
    Dropdown,
  },
  props: {
    typeFilter: {
      type: String,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    autoSelectFirstOnBlur: {
      type: Boolean,
      default: true,
    },
    showSelectAll: {
      type: Boolean,
      default: true,
    },
    preSelectListingId: {
      type: String,
      default: null,
    },
  },
  setup(props, ctx) {
    const loading = ref(false);
    const listings = ref([]);
    const dataSource = ref([]);
    const dataSourceSearchResults = ref([]);
    const keyword = ref(null);
    const selectResult = (value) => {
      console.log("onSelect", value);
      if (value === "all") {
        resetSelect();
        return;
      }
      const selectedString = dataSource.value.filter((m) => m.value === value)[0].value;
      const selected = JSON.parse(selectedString);
      console.log("selected", selected);
      keyword.value = selected.name;
      loading.value = false;
      ctx.emit("selected", selected);
    };
    const resetSelect = () => {
      ctx.emit("reset");
    };
    onMounted(() => {
      loading.value = true;
      API.list().then((res) => {
        console.log("listing list result:", res);
        if (res.data) {
          res.data.forEach((v, i) => {
            var shouldAdd = true;
            if (props.typeFilter) {
              if (v.type !== props.typeFilter) {
                shouldAdd = false;
              }
            }
            if (shouldAdd) {
              listings.value.push(v);
              dataSource.value.push({
                value: JSON.stringify(v),
                text: v.name,
              });
            }
          });
          dataSourceSearchResults.value = dataSource.value;
        }
        loading.value = false;
        nextTick(() => {
          setTimeout(() => {
            if (props.preSelectListingId) {
              const preSelectListing = listings.value.find((v) => v.id === props.preSelectListingId);
              if (preSelectListing) {
                keyword.value = preSelectListing.name;
              }
            }
          }, 500);
        });
      });
    });
    return {
      loading,
      keyword,
      listings,
      dataSource,
      dataSourceSearchResults,
      selectResult,
      resetSelect,
      JSON,
      console,
    };
  },
};
</script>
<template>
  <div class="flex space-x-2 items-center">
    <a-tag
      v-if="typeFilter"
      color="geekblue"
    >
      {{ typeFilter }}
    </a-tag>
    <dropdown
      input-mode
      :input-mode-props="{
        model: keyword,
        placeholder: 'Search Listings',
        customDataSource: true,
        dataSource: dataSourceSearchResults,
        loading: loading,
        disabled: disable,
        onSearch(searchText) {
          loading = true;
          console.log('onSearch', searchText);
          dataSourceSearchResults = !searchText
            ? dataSource.slice(0, 20)
            : dataSource
              .filter((mString) => JSON.parse(mString.value).name.toLowerCase().includes(searchText.toLowerCase()))
              .slice(0, 20);
        },
        onSelect: selectResult,
        onChange(value) {
          console.log('onChange', value);
          keyword = value;
          loading = true;
          if (dataSourceSearchResults.length == 0) {
            loading = false;
          }
        },
      }"
      :width="72"
      @update-input-mode-model="
        (m) => {
          console.log('update-input-mode-model', m);
          if (autoSelectFirstOnBlur) {
            selectResult(m.value);
          }
        }
      "
    >
      <template #custom-search-input-menu>
        <a-select-option
          v-for="(ds, i) in dataSourceSearchResults"
          :key="`listing-search-input-select-${i}`"
          :value="ds.value"
        >
          <div class="flex space-x-2">
            <div>
              <a-tag :color="JSON.parse(ds.value).is_active ? 'green' : 'red'">
                {{ JSON.parse(ds.value).is_active ? "Active" : "Inactive" }}
              </a-tag>
            </div>
            <div class="flex flex-col space-y-1">
              <span class="text-parqay-primary font-medium">{{ ds.text }}</span>
              <span class="text-xs font-light whitespace-normal">
                {{ JSON.parse(ds.value).location.address }}
              </span>
            </div>
          </div>
        </a-select-option>
      </template>
      <template #custom-search-input-search-footer>
        <a-select-option
          v-if="showSelectAll"
          key="all"
          class="show-all"
        >
          <div class="flex space-x-2">
            <div class="flex flex-col space-y-1">
              <span class="text-parqay-primary font-medium">Any Listing</span>
              <span class="text-xs font-light whitespace-normal"> Show reservations for any listing </span>
            </div>
          </div>
        </a-select-option>
        <a-select-option
          key="intro"
          disabled
          class="show-all"
        >
          <a> Search Listing by Name </a>
        </a-select-option>
      </template>
    </dropdown>
  </div>
</template>