<script>
import { ref, onMounted } from "@vue/composition-api";
import PropertiesNearby from "./components/PropertiesNearby.vue";

export default {
  name: "marketplace",
  components: {
    PropertiesNearby,
  },
  setup(props, ctx) {
    const heroEffect = ref(null);
    const selectedReserveType = ref("parking");
    const selectedFreqType = ref("hourly");

    const chooseReserveType = (t) => {
      console.log("chooseReserveType: " + t);
      selectedReserveType.value = t;
    };

    const chooseFreqType = (t) => {
      console.log("chooseFreqType: " + t);
      selectedFreqType.value = t;
    };

    // Lifecycle Hooks
    onMounted(() => {});

    return {
      heroEffect,
      selectedReserveType,
      selectedFreqType,
      chooseReserveType,
      chooseFreqType,
    };
  },
};
</script>
<template>
  <main>
    <!-- Hero section -->
    <div class="relative bg-white">
      <div class="absolute inset-x-0 bottom-0 h-1/2" />
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div
          class="relative border shadow-sm sm:rounded-2xl sm:overflow-hidden"
          style="height: 612px"
        >
          <div class="absolute left-0 right-0 bottom-0 z-10">
            <div class="flex justify-center w-full bg-white pt-1 pb-2">
              <div
                class="inline-flex items-center text-white bg-white rounded-full py-2 sm:text-base lg:text-sm xl:text-base"
                @click="$router.push('/')"
              >
                <span
                  class="mx-3 text-parqay-primary text-sm font-bold cursor-pointer uppercase"
                >Or, you can list a property of</span>
                <span
                  class="cursor-pointer transition duration-200 ease-in-out transform hover:-translate-y-2 hover:scale-110 px-3 py-0.5 text-xs font-semibold leading-5 uppercase tracking-wide text-parqay-primary border border-parqay-primary rounded-full"
                >Parking Space</span>
                <span
                  class="cursor-pointer transition duration-200 ease-in-out transform hover:-translate-y-2 hover:scale-110 mx-2 px-3 py-0.5 text-xs font-semibold leading-5 uppercase tracking-wide text-parqay-primary border border-parqay-primary rounded-full"
                >Stay</span>
                <span
                  class="cursor-pointer transition duration-200 ease-in-out transform hover:-translate-y-2 hover:scale-110 mx-2 px-3 py-0.5 text-xs font-semibold leading-5 uppercase tracking-wide text-parqay-primary border border-parqay-primary rounded-full"
                >Office</span>
                <span
                  class="cursor-pointer transition duration-200 ease-in-out transform hover:-translate-y-2 hover:scale-110 mx-2 px-3 py-0.5 text-xs font-semibold leading-5 uppercase tracking-wide text-parqay-primary border border-parqay-primary rounded-full"
                >Car Rental</span>
                <!-- Heroicon name: solid/chevron-right -->
                <svg
                  class="ml-2 w-5 h-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
          <img
            class="absolute inset-0 h-full w-full object-cover rounded-2xl"
            src="/static/img/dashboard/landing-page-parking-hero.jpg"
            alt="Parking Hero Image"
          >
          <div
            ref="heroEffect"
            class="absolute inset-0 overflow-hidden"
          >
            <div class="flex space-x-4 h-full">
              <div class="w-1/2 h-full">
                <div
                  class="flex flex-col h-full items-center justify-center px-6"
                >
                  <h1
                    class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
                  >
                    <span class="block text-white">Reserve parking,</span>
                    <span
                      class="block text-white"
                    >a workplace, a stay or vehicle nearby.</span>
                  </h1>
                  <p
                    class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl"
                  >
                    Rent parking, a work space, a place to stay or a car to
                    drive when you need it. Earn money when you don't.
                  </p>
                </div>
              </div>
              <div class="w-1/2 h-full">
                <div class="w-full h-full px-12 pt-8 pb-16">
                  <div
                    class="w-full h-full bg-white rounded-lg shadow-sm border"
                  >
                    <div class="flex flex-col px-8 pt-8 space-y-3">
                      <span class="text-xl font-bold">Let's get started!</span>
                      <div class="pt-1 space-y-2">
                        <span
                          class="text-base font-light"
                        >What would you like to reserve?</span>
                        <div
                          class="border border-parqay-primary rounded-full overflow-hidden grid grid-cols-4 h-10"
                        >
                          <div
                            class="border-r border-parqay-primary flex items-center justify-center cursor-pointer"
                            :class="{
                              'bg-parqay-primary text-white':
                                selectedReserveType == 'parking',
                            }"
                            @click="chooseReserveType('parking')"
                          >
                            <span class="font-medium">Parking</span>
                          </div>
                          <div
                            class="border-r border-parqay-primary flex items-center justify-center cursor-pointer"
                            :class="{
                              'bg-parqay-primary text-white':
                                selectedReserveType == 'stay',
                            }"
                            @click="chooseReserveType('stay')"
                          >
                            <span class="font-medium">Stay</span>
                          </div>
                          <div
                            class="border-r border-parqay-primary flex items-center justify-center cursor-pointer"
                            :class="{
                              'bg-parqay-primary text-white':
                                selectedReserveType == 'coworking',
                            }"
                            @click="chooseReserveType('coworking')"
                          >
                            <span class="font-medium">Workspace</span>
                          </div>
                          <div
                            class="flex items-center justify-center cursor-pointer"
                            :class="{
                              'bg-parqay-primary text-white':
                                selectedReserveType == 'vehicle',
                            }"
                            @click="chooseReserveType('vehicle')"
                          >
                            <span class="font-medium">Vehicle</span>
                          </div>
                        </div>
                      </div>
                      <div class="pt-1 space-y-2">
                        <span
                          class="text-base font-light"
                        >How long would you like to reserve?</span>
                        <div
                          class="border border-parqay-primary rounded-full overflow-hidden grid grid-cols-3 h-10"
                        >
                          <div
                            class="border-r border-parqay-primary flex items-center justify-center cursor-pointer"
                            :class="{
                              'bg-parqay-primary text-white':
                                selectedFreqType == 'hourly',
                            }"
                            @click="chooseFreqType('hourly')"
                          >
                            <span class="font-medium">Hourly</span>
                          </div>
                          <div
                            class="border-r border-parqay-primary flex items-center justify-center cursor-pointer"
                            :class="{
                              'bg-parqay-primary text-white':
                                selectedFreqType == 'daily',
                            }"
                            @click="chooseFreqType('daily')"
                          >
                            <span class="font-medium">Daily</span>
                          </div>
                          <div
                            class="flex items-center justify-center cursor-pointer"
                            :class="{
                              'bg-parqay-primary text-white':
                                selectedFreqType == 'monthly',
                            }"
                            @click="chooseFreqType('monthly')"
                          >
                            <span class="font-medium">Monthly</span>
                          </div>
                        </div>
                      </div>
                      <div class="pt-1 space-y-2">
                        <span
                          class="text-base font-light"
                        >Where do you need this?</span>
                        <div class="relative rounded-full shadow-sm">
                          <div
                            class="absolute inset-y-0 left-0 flex items-center px-4"
                          >
                            <svg-icon
                              class="text-parqay-primary w-4 h-4"
                              icon-class="cursor"
                            />
                          </div>
                          <div
                            class="absolute inset-y-0 right-2 flex items-center"
                          >
                            <span
                              class="text-parqay-primary px-2 py-1 font-medium text-xs whitespace-nowrap rounded-full border bg-white hover:bg-parqay-primary hover:text-white cursor-pointer"
                            >Current Location</span>
                          </div>
                          <input
                            id="search-content"
                            type="text"
                            autocomplete="off"
                            name="search-content"
                            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-12 sm:text-sm border-gray-300 rounded-full"
                            placeholder="Search for an address"
                          >
                        </div>
                      </div>
                      <div class="pt-1 space-y-2">
                        <span
                          class="text-base font-light"
                        >When do you need it?</span>
                        <a-range-picker
                          v-if="selectedFreqType == 'hourly'"
                          :size="'large'"
                          :separator="'to'"
                          :show-time="{ format: 'HH:mm' }"
                          format="YYYY-MM-DD HH:mm"
                          :placeholder="['Start Date', 'End Date']"
                        />
                        <a-range-picker
                          v-if="selectedFreqType == 'daily'"
                          :size="'large'"
                          :separator="'to'"
                          :placeholder="['Start Day', 'End Day']"
                        />
                        <a-range-picker
                          v-if="selectedFreqType == 'monthly'"
                          :size="'large'"
                          :separator="'to'"
                          :placeholder="['Start month', 'End month']"
                          format="YYYY-MM"
                          :mode="['month', 'month']"
                        />
                      </div>
                      <div class="pt-1">
                        <button
                          class="rounded-md w-full py-2 text-base font-medium bg-parqay-primary hover:bg-blue-500 text-white"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div ref="heroEffect" class="absolute inset-0">
            <img
              class="h-full w-full object-cover"
              src="/static/img/dashboard/landing-page-parking-hero.jpg"
              alt="Parking Hero Image"
            />
          </div> -->
        </div>
      </div>
    </div>

    <div class="relative bg-white">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 py-8">
        <!-- Properties Nearby -->
        <properties-nearby />
      </div>
    </div>

    <!-- Alternating Feature Sections -->
    <div class="relative pt-16 pb-32 overflow-hidden">
      <div
        aria-hidden="true"
        class="absolute inset-x-0 top-0 h-48"
      />
      <div class="relative">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0"
          >
            <div>
              <div>
                <span
                  class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-parqay-primary"
                >
                  <!-- Heroicon name: outline/inbox -->
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2
                  class="text-3xl font-extrabold tracking-tight text-gray-900"
                >
                  Stay on top of customer support
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis
                  bibendum malesuada faucibus lacinia porttitor. Pulvinar
                  laoreet sagittis viverra duis. In venenatis sem arcu pretium
                  pharetra at. Lectus viverra dui tellus ornare pharetra.
                </p>
                <div class="mt-6">
                  <a
                    href="#"
                    class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-parqay-primary hover:from-purple-700 hover:to-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p class="text-base text-gray-500">
                    &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed
                    diam. Sit orci risus aenean curabitur donec aliquet. Mi
                    venenatis in euismod ut.&rdquo;
                  </p>
                </div>
                <footer class="mt-3">
                  <div class="flex items-center space-x-3">
                    <div class="flex-shrink-0">
                      <img
                        class="h-6 w-6 rounded-full"
                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      >
                    </div>
                    <div class="text-base font-medium text-gray-700">
                      Marcia Hill, Digital Marketing Manager
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div
              class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                alt="Inbox user interface"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mt-24">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2"
          >
            <div>
              <div>
                <span
                  class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-parqay-primary"
                >
                  <!-- Heroicon name: outline/sparkles -->
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2
                  class="text-3xl font-extrabold tracking-tight text-gray-900"
                >
                  Better understand your customers
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis
                  bibendum malesuada faucibus lacinia porttitor. Pulvinar
                  laoreet sagittis viverra duis. In venenatis sem arcu pretium
                  pharetra at. Lectus viverra dui tellus ornare pharetra.
                </p>
                <div class="mt-6">
                  <a
                    href="#"
                    class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-parqay-primary hover:from-purple-700 hover:to-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div
              class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
                alt="Customer profile user interface"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Gradient Feature Section -->

    <!-- Stats section -->
    <div class="relative bg-gray-900">
      <div class="h-80 absolute bottom-0 xl:inset-0 xl:h-full xl:w-full">
        <div class="h-full w-full xl:grid xl:grid-cols-2">
          <div class="h-full xl:relative xl:col-start-2">
            <img
              class="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
              alt="People working on laptops"
            >
            <div
              aria-hidden="true"
              class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div
        class="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8"
      >
        <div
          class="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24"
        >
          <h2 class="text-sm font-semibold tracking-wide uppercase">
            <span
              class="bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-transparent"
            >Valuable Metrics</span>
          </h2>
          <p class="mt-3 text-3xl font-extrabold text-white">
            Get actionable data that will help grow your business
          </p>
          <p class="mt-5 text-lg text-gray-300">
            Rhoncus sagittis risus arcu erat lectus bibendum. Ut in adipiscing
            quis in viverra tristique sem. Ornare feugiat viverra eleifend fusce
            orci in quis amet. Sit in et vitae tortor, massa. Dapibus laoreet
            amet lacus nibh integer quis. Eu vulputate diam sit tellus quis at.
          </p>
          <div class="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            <p>
              <span class="block text-2xl font-bold text-white">8K+</span>
              <span
                class="mt-1 block text-base text-gray-300"
              ><span class="font-medium text-white">Companies</span> use
                laoreet amet lacus nibh integer quis.</span>
            </p>

            <p>
              <span class="block text-2xl font-bold text-white">25K+</span>
              <span
                class="mt-1 block text-base text-gray-300"
              ><span
                class="font-medium text-white"
              >Countries around the globe</span>
                lacus nibh integer quis.</span>
            </p>

            <p>
              <span class="block text-2xl font-bold text-white">98%</span>
              <span
                class="mt-1 block text-base text-gray-300"
              ><span
                class="font-medium text-white"
              >Customer satisfaction</span>
                laoreet amet lacus nibh integer quis.</span>
            </p>

            <p>
              <span class="block text-2xl font-bold text-white">12M+</span>
              <span
                class="mt-1 block text-base text-gray-300"
              ><span class="font-medium text-white">Issues resolved</span>
                lacus nibh integer quis.</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- CTA Section -->
    <div class="bg-white">
      <div
        class="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between"
      >
        <h2
          class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          <span class="block">Ready to get started?</span>
          <span
            class="block bg-gradient-to-r from-purple-600 to-parqay-primary bg-clip-text text-transparent"
          >Get in touch or create an account.</span>
        </h2>
        <div class="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
          <a
            href="#"
            class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-parqay-primary hover:from-purple-700 hover:to-indigo-700"
          >
            Learn more
          </a>
          <a
            href="#"
            class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-800 bg-blue-50 hover:bg-blue-100"
          >
            Get started
          </a>
        </div>
      </div>
    </div>
  </main>
</template>