<script>
import { reactive, onMounted } from "@vue/composition-api";
import { API } from "src/views/documents/api";

export default {
    setup(props, ctx) {
        const controls = reactive({
            loading: false,
            verifyResults: [],
            currentPage: 0,
        });
        const getAdminIdentityVerificationResults = async () => {
            let resp, result;
            controls.verifyResults = [];
            controls.loading = true;
            try {
                resp = await API.getAdminIdentityVerificationResults();
                console.log("getAdminIdentityVerificationResults: ", resp.data);
            } catch (error) {
                console.log("getAdminIdentityVerificationResults, error: ", error);
            } finally {
                controls.loading = false;
            }
            controls.verifyResults = resp.data;
            return result;
        };
        const countResultImages = (r) => {
            let count = 0;
            if (r.report.document_front) {
                count += 1;
            }
            if (r.report.photo_id_front) {
                count += 1;
            }
            if (r.report.user_selfie) {
                count += 1;
            }
            return count;
        }
        onMounted(() => {
            getAdminIdentityVerificationResults()
        })
        return {
            controls,
            getAdminIdentityVerificationResults,
            countResultImages
        }
    }
}

</script>
<template>
    <div class="bg-white rounded-lg border shadow-sm relative">

        <div class="flex flex-col space-y-5 justify-center items-center py-4 border-b">
            <span class="text-base font-semibold text-black">Collected Verified Results</span>
        </div>

        <div class="absolute right-3 top-3">
            <a-button v-if="controls.loading" type="link" :loading="controls.loading"></a-button>
            <a-button v-else type="link" icon="reload" @click="getAdminIdentityVerificationResults()"></a-button>
        </div>

        <template v-if="controls.verifyResults.length > 0">
            <div class="grid grid-cols-2 gap-3 bg-gray-100 p-3">
                <div v-for="(r, i) in controls.verifyResults" :key="`verification-result-${i}`"
                    class="bg-white hover:bg-blue-100 rounded border cursor-pointer">
                    <div class="flex items-center space-x-2 px-5 py-2">
                        <avatar :user-id="r.user" show-name />
                        <a-tag v-if="r.report.document_number" color="geekblue">Doc # {{
                            r.report.document_number.substring(0, 5)
                        }}</a-tag>
                        <a-tag v-if="r.selfie_check" color="orange">Selfie</a-tag>
                        <a-tag color="green">{{ countResultImages(r) }} Images</a-tag>
                        <a-tag color="cyan">{{ r.is_host ? "Host" : "Tenant" }}</a-tag>
                        <a-button type="primary" class="text-xs font-medium" size="small"
                            @click="$openDocSlideCard(r.is_host ? 'host' : 'tenant', r)">View</a-button>
                    </div>
                </div>
            </div>
        </template>

        <div v-else class="flex flex-col space-y-3 items-center justify-center pb-12" :style="{ minHeight: '55vh' }">
            <a-empty description="No verified hosts/tenants yet" />
            <span class="text-gray-400 text-center text-sm">Note only verified results will show here.</span>
        </div>

    </div>
</template>