<script>
import { reactive, ref, onMounted, onBeforeUnmount } from "@vue/composition-api";
import StatsItem from "./components/StatsItem.vue";
import Calendar from "src/views/reservations/components/Calendar";
import HeatMapCalendar from "src/views/dashboard/components/HeatMapCalendar";
import ReservationTable from "src/views/reservations/components/Table";
import PropertiesSummary from "src/views/dashboard/components/PropertiesSummary";

import { API } from "src/views/dashboard/api";
import { API as ReservationAPI } from "src/views/reservations/api";

import RequestInviteModal from "./components/RequestInviteModal.vue";
import BalanceSummary from "../payments/components/BalanceSummary.vue";

export default {
  name: "Dashboard",
  components: {
    StatsItem,
    Calendar,
    HeatMapCalendar,
    ReservationTable,
    PropertiesSummary,
    RequestInviteModal,
    BalanceSummary
  },
  setup(props, ctx) {
    const controls = reactive({
      graphStats: {
        loading: false
      },
      calendar: {
        embedMode: true
      },
      request: {
        loading: false,
        modal: {
          visible: false,
        },
      },
      upcoming: {
        loading: false
      },
    });

    const pagination = reactive({
      request: {
        currentPage: 1,
        perPage: 10,
        total: 1
      },
      upcoming: {
        currentPage: 1,
        perPage: 10,
        total: 1
      }
    })

    const stats = ref([]);

    const requestTable = reactive({
      headers: [
        {
          title: "Name",
          align: "center",
          dataIndex: "tenant_name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Price",
          align: "center",
          dataIndex: "host_income",
          scopedSlots: { customRender: "host_income" },
        },
        {
          title: "Listing",
          align: "center",
          dataIndex: "listing.name",
          scopedSlots: { customRender: "location" },
        },
        {
          title: "Actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      items: [],
    });

    const upcomingTable = reactive({
      headers: [],
      items: [],
    });

    const getGraphStats = async () => {
      controls.graphStats.loading = true;
      stats.value = [];
      try {
        const { data } = await API.getGraphStats();
        stats.value = data;
        controls.graphStats.loading = false;
      } catch (error) {
        controls.graphStats.loading = false;
      }
    }

    const getReservationRequests = async () => {
      controls.request.loading = true;
      try {
        const { data, pagination: pag } = await ReservationAPI.list({
          tab: "requesting",
          page: pagination.request.currentPage
        });

        requestTable.items = data;

        pagination.request.currentPage = pag.page;
        pagination.request.perPage = pag.per_page;
        pagination.request.total = pag.total;

        controls.request.loading = false;
      } catch (error) {
        controls.request.loading = false;
      }
    };

    const getUpcomingReservations = async () => {
      controls.upcoming.loading = true;
      try {
        const { data, pagination: pag } = await ReservationAPI.list({
          tab: "upcoming",
          page: pagination.upcoming.currentPage
        });
        upcomingTable.items = data;

        pagination.upcoming.currentPage = pag.page;
        pagination.upcoming.perPage = pag.per_page;
        pagination.upcoming.total = pag.total;

        controls.upcoming.loading = false;
      } catch (error) {
        controls.upcoming.loading = false;
      }
    };

    const getReservations = () => {
      getReservationRequests();
      getUpcomingReservations();
    };

    const handlePageChange = (page, type) => {
      pagination[type].currentPage = page;
    }

    onMounted(() => {
      ctx.root.$bus.$on("reservation-request-status-change", getReservationRequests);
      getGraphStats();
      getReservations();
    });

    onBeforeUnmount(() => {
      ctx.root.$bus.$off("reservation-request-status-change", getReservationRequests);
    })

    return {
      controls,
      pagination,
      stats,
      requestTable,
      upcomingTable,
      getGraphStats,
      getReservationRequests,
      getUpcomingReservations,
      handlePageChange,
      console,
    };
  },
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="h-full flex flex-col relative w-full overflow-auto pb-10 pt-10 px-10">
      <properties-summary />
      <div class="pb-6">
        <a-divider>
          <div class="flex items-center space-x-2">
            <span class="">Stats and Figures</span>
            <a-icon type="reload" :spin="controls.graphStats.loading" style="font-size: 0.95rem;"
              @click.stop="getGraphStats()" />
          </div>
        </a-divider>
        <div class="grid lg:grid-cols-3 lg:gap-8 xl:grid-cols-3 xl:gap-8 pt-2">
          <balance-summary class="col-span-2" />
          <heat-map-calendar />
          <stats-item v-for="it in stats" :key="it.name" :title="it.name" :to="it.to" :from="it.from" :unit-type="it.type"
            :line-color="it.line_color" :area-color="it.area_color" :chart-data="it.data.map(v => v + 10)" />
        </div>
      </div>

      <div id="requests" class="mt-6">
        <div class="flex items-center space-x-4 mb-3">
          <div class="flex items-center space-x-2">
            <span class="font-semibold text-lg">
              Requests
            </span>
            <a-icon type="reload" :spin="controls.request.loading" style="font-size: 0.95rem;"
              @click.stop="getReservationRequests()" />
          </div>
          <button v-if="requestTable.items.length > 0"
            class="rounded px-4 py-1.5 bg-green-400 hover:bg-green-500 text-sm text-white focus:outline-none"
            @click="() => (controls.request.modal.visible = true)">+ New Request</button>
        </div>
        <div class="
                grid grid-cols-2
                shadow-sm
                border
                overflow-hidden
                rounded-lg
                bg-white
              ">
          <reservation-table v-show="controls.calendar.embedMode" no-data-description="No More Request" expand-mode
            :controls="controls.request" :headers="requestTable.headers" :data="requestTable.items"
            :pagination="pagination.request" @refresh="getReservationRequests()" @page-change="page => {
              handlePageChange(page, 'request');
              getReservationRequests();
            }">
            <template v-if="!controls.request.loading" #no-data-buttons>
              <a-button type="primary" @click="() => (controls.request.modal.visible = true)">+ Invite</a-button>
            </template>
          </reservation-table>
          <calendar :model="requestTable.items" :embed-mode="controls.calendar.embedMode" disable-tooltip disable-controls
            :class="controls.calendar.embedMode ? 'border-l col-span-1' : 'col-span-2'"
            @toggle-size="() => controls.calendar.embedMode = !controls.calendar.embedMode" />
        </div>
      </div>
      <div class="mt-6 pb-6">
        <div class="flex items-center space-x-2 mb-3">
          <span class="font-semibold text-lg">
            Upcoming Reservations
          </span>
          <a-icon type="reload" :spin="controls.upcoming.loading" style="font-size: 0.95rem;"
            @click.stop="getUpcomingReservations()" />
        </div>
        <div class="
                shadow-sm
                border
                overflow-hidden
                rounded-lg
                bg-white
                pb-10
              ">
          <reservation-table :controls="controls.upcoming" :headers="upcomingTable.headers" :data="upcomingTable.items"
            :pagination="pagination.upcoming" @page-change="page => {
              handlePageChange(page, 'upcoming');
              getUpcomingReservations();
            }">
            <template v-if="!controls.upcoming.loading" #no-data-buttons>
              <a-button type="primary" @click="$router.push('/dashboard/reservations#calendar')">+ Create Reservation
              </a-button>
            </template>
          </reservation-table>
        </div>
      </div>
    </div>
    <request-invite-modal :visible="controls.request.modal.visible"
      @dismiss="() => (controls.request.modal.visible = false)" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  h2 {
    @apply mb-3;
  }

  td {
    @apply py-2;
  }

  .ant-table-placeholder {
    border-bottom-width: 0px;
  }
}
</style>
