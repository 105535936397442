<script>
import { ref, onMounted } from "@vue/composition-api";
export default {
  props: {
    // Grid columns number
    cols: {
      type: Number,
      default: 2,
    },
    // Each item's height
    itemHeight: {
      type: Number,
      // Tailwind CSS: h-12
      default: 12,
    },
    // Grid items gap - vertically
    gapY: {
      type: Number,
      default: 4,
    },
    // Grid items gap - horizontally
    gapX: {
      type: Number,
      default: 0,
    },
    // Bullet icon color
    iconColor: {
      type: String,
      default: "blue-600",
    },
    // Bullet title
    model: {
      type: Array,
      required: true,
    },
  },
  setup() { },
};
</script>
<template>
  <div :class="`grid grid-cols-${cols} gap-y-${gapY} gap-x-${gapX}`">
    <div v-for="(c, i) in model" :key="`icon-bullet-list-item-${i}`" :class="`h-${itemHeight}`"
      @click="$emit('update-arm', c.arm)">
      <div class="flex items-center space-x-2 cursor-pointer hover:bg-blue-100 px-2 py-1 rounded-lg">
        <div class="object-center">
          <svg-icon :icon-class="c.icon" class="text-lg rounded-full h-8 w-8 text-center text-white"
            :class="`bg-${iconColor}`" />
        </div>
        <span class="text-base text-gray-500 text-center">{{
        c.title
        }}</span>
        <div class="flex flex-grow justify-end">
          <slot :index="i" />
        </div>
      </div>
    </div>
  </div>
</template>