var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"Income Details","visible":_vm.controls.showIncomeDetails,"body-style":{ background: 'rgba(244, 245, 247, 1)' }},on:{"ok":function () {
      _vm.$emit('dismiss');
      _vm.dismiss();
    },"cancel":function () {
      _vm.$emit('dismiss');
      _vm.dismiss();
    }},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a-button',{on:{"click":_vm.openIntercom}},[_vm._v("\n      Ask a question\n    ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function () {
          _vm.dismiss();
        }}},[_vm._v("\n      OK\n    ")])]},proxy:true}])},[_c('div',{staticClass:"flex flex-col space-y-8 pt-4 pb-8"},[_c('div',{staticClass:"flex flex-col divide-y border rounded-xl bg-white"},[_c('div',{staticClass:"flex w-full justify-between items-center py-4 px-4"},[_c('span',{staticClass:"text-lg text-black font-medium text-left"},[_vm._v("Price")]),_c('span',{staticClass:"text-lg text-gray-600 font-medium text-right"},[_vm._v(_vm._s(_vm.$getPrice(_vm.pricing.price)))])]),_c('div',{staticClass:"flex w-full justify-between items-center py-4 px-4"},[_c('span',{staticClass:"text-lg text-black font-medium text-left"},[_vm._v("Fee")]),_c('span',{staticClass:"text-lg text-pink-500 font-medium text-right"},[_vm._v("-"+_vm._s(_vm.$getPrice(_vm.pricing.fee)))])]),_c('div',{staticClass:"flex justify-end text-xl text-right space-x-4 items-center py-6 px-4"},[_c('span',{staticClass:"text-black font-semibold"},[_vm._v("Income")]),_c('span',{staticClass:"text-green-400 font-bold"},[_vm._v(_vm._s(_vm.$getPrice(_vm.pricing.income)))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }