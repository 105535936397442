import request from "src/services/api/request";

export const API = {
  list(filter = {}) {
    if (filter.type && filter.type === "all") {
      delete filter["type"];
    }
    return request.get("documents/tenant", {
      filter: {
        ...filter
      }
    });
  },
  getIdentityVerificationResult(
    { is_host = false, on_behalf_of = null } = { is_host: false, on_behalf_of: null }
  ) {
    let payload = {};
    if (on_behalf_of) {
      payload["on_behalf_of"] = on_behalf_of;
    }
    if (is_host) {
      payload["is_host"] = is_host;
    }
    return request.get(`documents/identity/verification-results`, payload);
  }
};
