<script>
import { reactive, ref } from "@vue/composition-api";
import PQSearchBar from "src/views/components/PQSearchBar.vue";
import Map from "src/views/map/View.vue";

import { API } from "src/views/listings/api";

export default {
  name: "AddressEditor",
  components: {
    "pq-search-bar": PQSearchBar,
    "pq-map": Map,
  },
  props: {
    locationModel: {
      type: Object,
      default: null,
    },
  },
  setup(props, ctx) {
    const loading = ref(false);
    const visible = ref(false);
    const location = reactive({
      lat: props.locationModel.lat,
      lon: props.locationModel.lon,
      address: props.locationModel.address,
      name: props.locationModel.address
    });
    const close = () => {
      visible.value = false;
    };
    const updateKeywords = (kw, loading) => {
      setTimeout(() => {
        loading.value = false;
      }, 300);
    };
    const updateMapCenter = (l) => {
      console.log("updateMapCenter -----> ", l);
      location.lat = l.lat;
      location.lon = l.lon;
      location.name = l.address;
      location.address = l.address;
    };
    const getCurrentLocation = () => {
      const options = {
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0, //defaults to 0
      };
      ctx.root.$getLocation(options).then((l) => {
        console.log(l);
        location.lat = l.lat;
        location.lon = l.lng;
        ctx.root.$reverseLocationSearch(location).then((res) => {
          console.log("res: " + res[0].formattedAddress);
          location.address = res[0].formattedAddress;
          location.name = location.address;
          ctx.refs["search-bar"].updateSearchBar(location.address);
        });
      });
    };
    const saveChanges = async () => {
      loading.value = true;
      try {
        await API.updateAddress(ctx.root.$route.params.id, location);
      } catch (error) {
        ctx.root.$openNotification("Edit Address", error.message);
        return
      } finally {
        setTimeout(() => {
          loading.value = false;
        }, 1500);
      }
      close();
    }
    return {
      loading,
      visible,
      location,
      saveChanges,
      close,
      updateKeywords,
      updateMapCenter,
      getCurrentLocation,
    };
  },
};
</script>
<template>
  <div class="flex justify-between px-2 items-center">
    <span class="text-parqay-primary font-medium">
      {{ location.address }}
    </span>
    <a-button type="primary" @click="visible = true">
      Edit
    </a-button>

    <a-modal v-model="visible" title="Edit Location" :footer="null">
      <div>
        <div class="relative">
          <pq-search-bar ref="search-bar" :initial-value="location.address" class="h-18" text-size="md"
            placeholder="Search address or location" :debounce-delay="200" data-source-mode
            @keywords-update="updateKeywords" @selected-data-source="updateMapCenter">
            <div class="rounded-full bg-blue-600 hover:bg-blue-800 mr-1 cursor-pointer transform scale-150"
              @click="getCurrentLocation">
              <div class="flex items-center justify-center w-5 h-5">
                <svg-icon class="text-white w-3 h-3" icon-class="cursor" />
              </div>
            </div>
          </pq-search-bar>
        </div>
        <div class="my-3 h-64 relative">
          <pq-map ref="PQMap" :center="location" simple-focus />
        </div>
        <a-button type="primary" :loading="loading" block size="large" @click="saveChanges()">
          Save
        </a-button>
      </div>
    </a-modal>
  </div>
</template>
<style scoped>
.hidden-input {
  z-index: -1;
}
</style>