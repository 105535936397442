<script>
import LineChart from "src/views/dashboard/components/LineChart";

export default {
  name: "StatsItem",
  components: {
    LineChart,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    from: {
      type: Number,
      default: 0,
    },
    to: {
      type: Number,
      default: 0,
    },
    unitType: {
      type: String,
      default: "",
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    lineColor: {
      type: String,
      default: "#5E72E4",
    },
    areaColor: {
      type: String,
      default: "rgba(94, 114, 228, 0.18)",
    },
  },
  setup(props, ctx) {
    const calculatePercStatus = (from, to) => {
      let perc = Math.round(((to - from) / (from == 0 ? 1 : from)) * 100);
      let sign;
      if (perc > 0) sign = "+";
      if (perc < 0) sign = "-";
      perc = Math.abs(perc);
      return {
        perc,
        sign,
      };
    };
    return {
      calculatePercStatus,
    };
  },
};
</script>
<template>
  <div class="info-panel">
    <div
      class="
        rounded-lg
        bg-white
        shadow-sm
        hover:shadow-lg
        border
        hover:border-0
        overflow-hidden
        h-52
        cursor-pointer
        relative
        text-center
      "
    >
      <div
        class="relative bg-transparent"
        @click="$router.push('/dashboard/reservations')"
      >
        <line-chart
          css-classes="h-52"
          :line-color="lineColor"
          :area-color="areaColor"
          :chart-data="chartData"
          class="pointer-events-none"
        />
      </div>
      <div class="absolute left-0 top-0 w-full h-full px-3 z-0">
        <p class="text-gray-600 mt-4 font-medium mb-1 text-base">
          {{ title }}
        </p>
        <h3
          v-if="unitType === 'currency'"
          class="text-2xl text-gray-900 font-medium"
        >
          {{ $formatCurrency(to) }}
        </h3>
        <h3
          v-if="['rating', 'default'].includes(unitType)"
          class="text-2xl text-gray-900 font-medium"
        >
          {{ to }}
        </h3>
        <p
          class="text-md font-bold"
          :class="{
            'text-green-400': calculatePercStatus(from, to).sign === '+',
            'text-red-500': calculatePercStatus(from, to).sign === '-',
          }"
        >
          <span v-if="calculatePercStatus(from, to).sign" class="mr-2">
            <svg-icon
              :class="{
                'transform rotate-180': calculatePercStatus(from, to).sign === '+',
              }"
              icon-class="down-arrow"
              class="w-4 h-4"
            />
          </span>

          <span v-if="calculatePercStatus(from, to).sign"
            >{{ calculatePercStatus(from, to).perc }}%</span
          >
        </p>
      </div>
    </div>
    <div class="text-center mt-2">
      <span class="font-medium text-base mr-2">Prior:</span>
      <p v-if="unitType === 'currency'" class="text-base inline">
        {{ $formatCurrency(from) }}
      </p>
      <p v-if="['rating', 'default'].includes(unitType)" class="text-base inline">
        {{ from }}
      </p>
    </div>
  </div>
</template>
