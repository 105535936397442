var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute inset-0"},[_c('div',{staticClass:"flex justify-between items-center px-10 py-4"},[_c('div',{staticClass:"flex space-x-2 items-center font-medium text-xl cursor-pointer"},[_c('a',{staticClass:"text-parqay-primary",on:{"click":function($event){return _vm.$router.push('/dashboard/listings')}}},[_vm._v("\n        Listings\n      ")]),_c('span',{staticClass:"mx-2"},[_vm._v("/")]),(_vm.form.id)?_c('span',{staticClass:"truncate",staticStyle:{"max-width":"35vw"}},[_vm._v(_vm._s(_vm.$deepGet(_vm.form, "summary.title") ||
        _vm.startCase(_vm.activeTab)))]):_vm._e(),_c('a-icon',{staticStyle:{"font-size":"1rem"},attrs:{"type":"reload","spin":_vm.controls.loading},on:{"click":function($event){$event.stopPropagation();return _vm.fetchData(_vm.$route.params.id)}}})],1),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"font-medium text-base"},[_vm._v("Property")]),_c('listing-search-dropdown',{attrs:{"auto-select-first-on-blur":false,"show-select-all":false,"pre-select-listing-id":_vm.form.id},on:{"selected":function (selected) {
        _vm.$bus.$emit('toggle-loading-indicator', true, 'Switching Property');
        _vm.$router.push({
          name: 'ListingsDetail',
          params: { id: selected.id },
          hash: '#overview',
        });
        _vm.fetchData(selected.id);
      }}})],1)]),_c('div',{staticClass:"flex justify-center py-4 px-6"},[_c('tab-header',{staticClass:"w-full",attrs:{"tabs":_vm.tabs,"padding":50,"show-toggles":_vm.tabs.length > 6},on:{"change":_vm.changeTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})],1),(_vm.form.id)?_c('div',{staticClass:"flex justify-center py-4 px-6"},[_c('keep-alive',[_c(_vm.activeTab,{tag:"component",attrs:{"listing-type":_vm.form.type,"component-data":_vm.componentData,"model":_vm.componentData,"form":_vm.form},on:{"change-tab":function (tab) { return _vm.activeTab = tab; },"refresh":function($event){return _vm.fetchData(_vm.$route.params.id)},"update-form":function (f) { return _vm.form = f; }}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }