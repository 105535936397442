import request from "src/services/api/request";

export const API = {
  getGraphStats() {
    return request.get("reservations/stats/graphs");
  },
  getSummaryStats() {
    return request.get("listings/stats/summary");
  }
};
