<script>
import MobileDocuments from "./mobile/View.vue";

import { ref, reactive, onMounted } from "@vue/composition-api";
import TabHeader from "src/views/components/TabHeader";
import DocumentTable from "src/views/documents/components/Table.vue";

import { startVerifyIdentity } from "src/views/documents/components/Templates.vue";

import { API } from "src/views/documents/api";

export default {
  name: "tenant-documents",
  components: {
    TabHeader,
    DocumentTable,
    MobileDocuments
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const router = ctx.root.$router;

    const controls = reactive({
      loading: false,
      verifyResult: {},
    });

    const docTemplates = ref(null);

    const tabs = ref([
      { name: "all", label: "All" },
      { name: "signature", label: "Sign" },
      { name: "upload", label: "Upload" },
      { name: "pending", label: "Pending" },
      { name: "complete", label: "Completed" }
    ]);
    const types = ref(["All"]);

    const activeTab = ref("documents");
    const activeType = ref("All");

    const setupHashRoute = hashRoute => {
      router.push({ name: route.name, hash: `#${hashRoute}`, query: route.query });
    };

    const changeTab = name => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    const updateTypes = ts => {
      types.value = ["All", ...ts];
    };

    const filter = v => {
      let result = true;
      let typeResult = true;
      if (activeTab.value === "signature") {
        result = v.template.doc_sign_required;
      }
      if (activeTab.value === "upload") {
        result = !v.template.doc_sign_required;
      }

      if (activeTab.value === "pending") {
        result = v.host_approval.status === null;
      }
      if (activeTab.value === "issues") {
        result = !["complete", "verifying"].includes(v.status);
      }
      if (activeType.value !== "All") {
        typeResult = v.type === activeType.value;
      }
      return result & typeResult;
    };

    const getTenantIdentityVerificationResult = async () => {
      try {
        const { data } = await API.getIdentityVerificationResult();
        controls.verifyResult = data;
      } catch (error) {
        console.log("getHostIdentityVerificationResult, error: ", error);
      }
    };

    const verifyTenantIdentity = async () => {
      controls.loading = true;
      ctx.root.$bus.$emit("toggle-loading-indicator", true, "Loading");
      await startVerifyIdentity();
      controls.loading = false;
      ctx.root.$bus.$emit("toggle-loading-indicator", false);
    }

    const getDocumentById = async () => {
      controls.loading = true;
      ctx.root.$bus.$emit("toggle-loading-indicator", true, "Loading Document");
      try {
        const { data } = await API.getDocumentById({ id: route.query.doc_id });
        controls.loading = false;
        ctx.root.$bus.$emit("toggle-loading-indicator", false);
        return data;
      } catch (error) {
        console.log("getDocumentById -> error: ", error);
        ctx.root.$openNotification("Document", error.message);
        controls.loading = false;
        ctx.root.$bus.$emit("toggle-loading-indicator", false);
      }
    }

    onMounted(async () => {
      getTenantIdentityVerificationResult();
      if (route.params.actionName === "identity-verification") {
        console.log("docAction -> identity-verification");
        verifyTenantIdentity();
        return;
      }
      if (["upload", "sign"].includes(route.params.actionName) && route.query.doc_id) {
        const doc = await getDocumentById();
        if (!doc) {
          return
        }
        if (doc.type !== route.params.actionName) {
          console.log("Doc type does not match")
          return
        }
        ctx.root.$openDocSlideCard(
          "tenant",
          doc
        );
        return
      }
    });

    return {
      controls,
      docTemplates,
      tabs,
      types,
      activeTab,
      activeType,
      changeTab,
      updateTypes,
      filter,
      verifyTenantIdentity
    };
  }
};
</script>

<template>
  <div class="absolute inset-0">
    <div v-show="$isDesktopView()" class="flex flex-col py-10 space-y-4 relative">
      <div class="border-b flex justify-between items-center px-10 py-2">
        <h2 class="font-bold text-xl">
          Documents
        </h2>
      </div>
      <div class="flex flex-col px-10 space-y-4">
        <div v-if="$isDesktopView()" class="flex flex-col space-y-2 items-center">
          <tab-header v-model="activeTab" :tabs="tabs" class="w-full" @change="changeTab">
            <template #right-actions>
              <a-tag color="geekblue" class="identity-verification-tag">
                <span class="text-sm font-medium">Your identity status:</span>
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>
                      Identity verification process takes about 2-5 minutes: we'll
                      collect and verify your identity documents through this web
                      browser, a "Verified" badge will be assigned once the process
                      went through. We require all tenants to verify identity before making any reservation.
                    </span>
                  </template>
                  <svg-icon icon-class="question-solid" class="w-5 h-5" />
                </a-tooltip>
                <a-tag v-if="controls.verifyResult.is_verified" color="green">Verified</a-tag>
                <span>|</span>
                <a-button v-if="controls.verifyResult.is_verified" type="primary" class="text-xs font-medium"
                  size="small" @click="$openDocSlideCard(
      'tenant',
      controls.verifyResult
    )">View</a-button>
                <a-button v-else type="primary" class="text-xs font-medium" size="small" :loading="controls.loading"
                  @click="verifyTenantIdentity()">Start Verification</a-button>
              </a-tag>
            </template>
          </tab-header>
        </div>
        <document-table role="tenant" :filter="filter" @update-types="types => updateTypes(types)" />
      </div>
    </div>
    <mobile-documents v-if="!$isDesktopView()" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .identity-verification-tag {
    @apply flex items-center space-x-2 px-3 py-1.5;
  }
}
</style>