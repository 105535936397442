<script>
import { ref, reactive } from "@vue/composition-api";
import { API } from "src/views/components/api/account";

export default {
    setup(props, ctx) {
        const loading = ref(false);
        const form = reactive({
            current_password: null,
            new_password: null,
            confirm_password: null
        })
        const shouldDisableUpdateButton = () => {
            return loading.value || !form.current_password || !form.new_password || !form.confirm_password || (form.new_password !== form.confirm_password)
        }
        const updatePassword = async () => {
            loading.value = true;
            try {
                const { message } = await API.updatePassword(
                    form.current_password,
                    form.new_password
                );
                ctx.root.$openNotification(
                    "Update Password",
                    message,
                    10,
                    "success"
                );
                ctx.emit("dismiss");
            } catch (error) {
                ctx.root.$openNotification(
                    "Update Password",
                    error.message,
                    10,
                    "info"
                );
                loading.value = false;
            } finally {
                setTimeout(() => {
                    loading.value = false;
                }, 1500);
            }
        }
        return {
            loading,
            form,
            shouldDisableUpdateButton,
            updatePassword
        }
    }
}
</script>
<template>
    <div class="change-password-modal py-2">
        <a-divider>Change Password</a-divider>
        <div class="mt-4 space-y-4">
            <label-item label="Old Password">
                <a-input-password v-model="form.current_password" placeholder="Enter old password" allow-clear size="large">
                    <svg-icon slot="prefix" icon-class="lock-filled" class="h-4 w-4 text-blue-400" />
                </a-input-password>
            </label-item>
            <label-item label="New Password">
                <a-input-password v-model="form.new_password" placeholder="Enter new password" allow-clear size="large">
                    <svg-icon slot="prefix" icon-class="lock" class="h-4 w-4 text-blue-400" />
                </a-input-password>
            </label-item>
            <label-item label="Confirm New Password">
                <a-input-password v-model="form.confirm_password" placeholder="Retype same new password" allow-clear
                    size="large">
                    <svg-icon slot="prefix" icon-class="lock" class="h-4 w-4 text-blue-400" />
                </a-input-password>
            </label-item>
        </div>
        <div class="flex justify-center mt-8">
            <button class="bg-parqay-primary hover:bg-blue-500 disabled:bg-blue-400 disabled:cursor-not-allowed text-white py-2 px-4 rounded w-full"
                :disabled="shouldDisableUpdateButton()" @click="updatePassword()">
                <svg-icon v-if="loading" icon-class="circular-loader"
                    class="animate-spin h-4 w-4 ml-2 my-auto text-white" />
                <span v-else class="my-auto">Update Password</span>
            </button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.change-password-modal::v-deep {
    .ant-input {
        padding-left: 40px;
    }
}
</style>