<script>
import { ref } from "@vue/composition-api";

export default {
  name: "RequestInviteModal",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const form = ref([
      {
        title: "Invite Tenant / Lead",
        icon: "invite-tenant",
        alt: true,
        useImage: true,
        route: "/dashboard/tenant/invite"
      },
      // {
      //   title: "Create New Lead Message",
      //   icon: "lead-magnet",
      //   alt: true,
      //   useImage: true,
      //   route: "/dashboard/messaging?lead=true#chat"
      // },
      {
        title: "Create Reservation and Invite",
        icon: "calendar-circle",
        alt: true,
        useImage: false,
        route: "/dashboard/reservations/new"
      }
    ]);
    return {
      form
    };
  }
};
</script>

<template>
  <a-modal :visible="visible" title="Create Request by" width="30vw" :footer="null" :mask-style="{
    opacity: 0.25
  }" @cancel="() => {
  $emit('dismiss');
}
  ">
    <div class="flex flex-col space-y-4 px-4 py-2">
      <div v-for="(v, i) in form" :key="`reservation-type-${i}`" class="
          flex
          items-center
          space-x-4
          hover:ring-2 hover:ring-parqay-primary
          cursor-pointer
          border
          shadow-xs
          hover:shadow-lg
          rounded-lg
          px-4
          py-2
        " @click="$router.push(v.route)">
        <img v-if="v.useImage" :src="`/static/img/${v.icon}.png`"
          class="rounded-full ring-2 ring-parqay-primary w-11 h-11 object-cover">
        <template v-else>
          <div v-if="!v.alt" class="border-parqay-primary rounded-lg w-12 h-12 flex" style="border-width: 3px">
            <svg-icon :icon-class="v.icon" class="h-auto w-auto m-2 text-black" />
          </div>
          <svg-icon v-else :icon-class="v.icon" class="h-12 w-12 rounded my-auto" />
        </template>
        <span class="text-sm font-medium">
          {{ v.title }}
        </span>
      </div>
    </div>
  </a-modal>
</template>
