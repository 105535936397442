<script>
import { ref } from "@vue/composition-api";
export default {
  setup() {
    const form = ref([
      {
        icon: "card-brand-visa",
        class: "object-cover",
      },
      {
        icon: "card-brand-amex",
        class: "object-cover",
      },
      {
        icon: "card-brand-discover",
        class: "object-cover",
      },
      {
        icon: "card-brand-mastercard",
        class: "object-cover",
      },
    ]);
    return {
      form,
    };
  },
};
</script>
<template>
  <div class="flex items-center space-x-2">
    <svg-icon
      v-for="(item, i) in form"
      :key="`card-logo-${i}`"
      :icon-class="item.icon"
      class="h-8 w-12"
      :class="item.class"
    />
  </div>
</template>
