<script>
import { reactive, ref, watch, computed } from "@vue/composition-api";
import Simple from "src/views/listings/detail/availability/Simple.vue";
import Manual from "src/views/listings/detail/availability/Manual.vue";
import { API } from "src/views/listings/api";
import { getDefaultModelForMode } from "src/views/listings/detail/availability/util.js";
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

export default {
  components: {
    Simple,
    Manual,
  },
  props: {
    form: {
      type: Object,
      require: true
    }
  },
  setup(props, ctx) {

    const controls = reactive({
      loading: {
        save: false,
        update: false,
        delete: false
      },
      mode: props.form.availability.mode,
      modes: ["simple", "manual"],
      minDate: dayjs().startOf('day').toDate()
    });

    const presale = reactive({
      isActive: false,
      availableRange: {
        start: dayjs().startOf('day').toDate(),
        end: dayjs().add(1, 'month').startOf('day').toDate()
      }
    })

    const pointerState = ref('start');

    const pointerDate = ref(dayjs().startOf('day').toDate());

    const isCalendarEnabled = ref(false);

    const presale_schedule = computed(() => {
      return props.form.presale_schedule;
    })

    watch(() => presale_schedule.value, val => {
      loadPresaleSchedule(val);
    })

    const loadPresaleSchedule = (schedule) => {
      if (schedule) {
        presale.isActive = true;
        presale.availableRange = {
          start: schedule.from ? dayjs(schedule.from).toDate() : null,
          end: schedule.to ? dayjs(schedule.to).toDate() : null
        }
      } else {
        presale.isActive = false;
        presale.availableRange = {
          start: dayjs().startOf('day').toDate(),
          end: dayjs().add(1, 'month').startOf('day').toDate()
        }
      }
    }

    loadPresaleSchedule(presale_schedule.value);

    const toggleModeConfirm = () => {
      const defaultModel = getDefaultModelForMode(controls.mode);
      console.log("getDefaultModelForMode", defaultModel);
      props.form.availability.mode = defaultModel.mode;
      props.form.availability.simple = defaultModel.simple;
      props.form.availability.manual = defaultModel.manual;
    };

    const updateAvailability = async (mode, availability) => {
      let payload = {
        mode
      }
      if (mode === "simple") {
        payload = {
          simple: props.form.availability.simple,
          manual: null,
          ...payload
        }
      }
      if (mode === "manual") {
        payload = {
          simple: null,
          manual: props.form.availability.manual,
          ...payload
        }
      }
      try {
        controls.loading.update = true;
        await API.updateAvailability(props.form.id, payload);
        controls.loading.update = false;
        ctx.root.$openNotification("Update Availability", "Update has been saved successfully.", 8, "success");
      } catch (error) {
        controls.loading.update = false;
        ctx.root.$openNotification("Update Availability", error.message, 10, "error");
      }
    }

    const formatRangeDate = (date) => {
      return dayjs(date).format("YYYY-MM-DDTHH:mm");
    };

    const updatePresaleSchedule = async () => {
      try {
        controls.loading.save = true;
        isCalendarEnabled.value = false;
        const payload = {
          is_active: presale.isActive,
          available_range: {
            from: presale.availableRange.start ? formatRangeDate(presale.availableRange.start) : null,
            to: presale.availableRange.end ? formatRangeDate(presale.availableRange.end) : null
          }
        };
        await API.updatePresaleSchedule(props.form.id, payload);
        ctx.root.$openNotification(
          "Presale Schedule",
          `Update is saved. Now you will be able to sell \
          this listing only \
          from ${presale.availableRange.start ? ctx.root.$formatDate(presale.availableRange.start, 'M-D-YYYY, h:mm a') : 'Now'} \
          to ${presale.availableRange.end ? ctx.root.$formatDate(presale.availableRange.end, 'M-D-YYYY, h:mm a') : 'Forever'}`,
          10,
          "success");
        controls.loading.save = false;
        ctx.emit("refresh");
      } catch (error) {
        ctx.root.$openNotification("Update Presale Schedule", error.message, 10, "error");
        controls.loading.save = false;
      }
    }

    const disablePresaleSchedule = async () => {
      try {
        controls.loading.delete = true;
        const payload = {
          is_active: false
        };
        await API.updatePresaleSchedule(props.form.id, payload);
        ctx.root.$openNotification(
          "Presale Schedule",
          "Presale has been turned off. Now the listing availability depends on the operating hours.",
          10,
          "success");
        controls.loading.delete = false;
        presale.isActive = false;
        ctx.emit("refresh");
      } catch (error) {
        ctx.root.$openNotification("Update Presale Schedule", error.message, 10, "error");
        controls.loading.delete = false;
      }
    }

    // Set pointer state to either 'start' or 'end'
    const setPointer = (pointer) => {
      isCalendarEnabled.value = true;
      pointerState.value = pointer;
    };

    // Update the presale dates based on the pointer state
    const updatePresaleDate = (date) => {
      console.log("updatePresaleDate --> ", date);
      if (pointerState.value === 'start') {
        presale.availableRange.start = date;
      } else if (pointerState.value === 'end') {
        presale.availableRange.end = date;
      }
    };

    return {
      controls,
      presale,
      presale_schedule,
      pointerState,
      pointerDate,
      isCalendarEnabled,
      setPointer,
      updatePresaleDate,
      toggleModeConfirm,
      updateAvailability,
      updatePresaleSchedule,
      disablePresaleSchedule,
      startCase
    };
  },
};
</script>
<template>
  <div class="grid grid-cols-4 gap-4 h-screen">
    <div class="col-span-2 flex flex-col items-center justify-start space-y-4">
      <div class="flex items-center space-x-2">
        <span class="text-center text-base font-semibold">Operating Hours</span>
        <a-tooltip>
          <template #title>
            <span>Adjust the operating hours to automate the open/close hours, allowing our system to find the best tenant
              matches that fit your schedule.</span>
          </template>
          <a-icon type="info-circle" theme="twoTone" class="cursor-pointer" />
        </a-tooltip>
      </div>
      <div v-if="controls.mode != form.availability.mode" class="rounded-lg shadow-md bg-yellow-50 p-4 mx-auto mb-8"
        style="width: 550px">
        <div class="flex flex-col space-y-2">
          <div class="flex justify-center w-full">
            <svg-icon icon-class="warning" class="h-5 w-5 text-yellow-400" />
            <h3 class="text-sm font-medium text-yellow-800 ml-3">
              Attention needed
            </h3>
          </div>
          <div class="flex flex-col items-center justify-center">
            <div class="mt-2 text-sm text-yellow-700">
              <p>
                <b>{{ startCase(controls.mode) }}</b> availability mode is not enabled, do you want to enable it?
              </p>
            </div>
            <div class="flex justify-between px-6 mt-6 pb-2 w-full">
              <button
                class="w-2/5 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-400 hover:bg-green-500 focus:outline-none"
                @click="toggleModeConfirm">
                <div class="flex w-full justify-center space-x-2">
                  <span class="my-auto">Enable</span>
                  <svg-icon icon-class="check" class="h-5 w-5 my-auto text-white" />
                </div>
              </button>
              <button
                class="w-2/5 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-parqay-primary hover:bg-blue-500 focus:outline-none"
                @click="() => (controls.mode = form.availability.mode)">
                <div class="flex w-full justify-center space-x-2">
                  <span class="my-auto">Reset</span>
                  <svg-icon icon-class="refresh" class="h-5 w-5 my-auto text-white" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="mt-2 flex justify-center">
        <simple v-if="form.availability.mode === 'simple' && controls.mode == form.availability.mode"
          :model="form.availability.simple">
          <a-radio-group v-model="controls.mode">
            <a-radio-button v-for="mode in controls.modes" :key="mode" :value="mode">
              {{ startCase(mode) }}
            </a-radio-button>
          </a-radio-group>
          <template #footer>
            <div class="p-4">
              <a-button type="primary" :loading="controls.loading.update" block size="large"
                @click="updateAvailability('simple')">
                Update
              </a-button>
            </div>
          </template>
        </simple>
        <manual v-if="form.availability.mode === 'manual' && controls.mode == form.availability.mode"
          :model="form.availability.manual">
          <a-radio-group v-model="controls.mode">
            <a-radio-button v-for="mode in controls.modes" :key="mode" :value="mode">
              {{ startCase(mode) }}
            </a-radio-button>
          </a-radio-group>
          <template #footer>
            <a-button type="primary" :loading="controls.loading.update" block size="large"
              @click="updateAvailability('manual')">
              Update
            </a-button>
          </template>
        </manual>
      </div>
    </div>
    <div class="col-span-2 flex flex-col items-center justify-start space-y-4">
      <div class="flex items-center space-x-2">
        <span class="text-center text-base font-semibold">Presale Schedule</span>
        <a-tooltip>
          <template #title>
            <span>Use the date picker below to choose a desired future date range,
              enabling tenants to secure their bookings in advance only within the range.</span>
          </template>
          <a-icon type="info-circle" theme="twoTone" class="cursor-pointer" />
        </a-tooltip>
      </div>
      <div class="presale flex flex-col items-center py-4 space-y-4 bg-white border rounded-lg">
        <div class="flex w-full items-center justify-between px-4 pb-4 border-b border-gray-300">
          <div class="flex items-center space-x-2">
            <div v-if="presale.isActive" class="w-2 h-2 rounded-full bg-green-400" />
            <div v-else class="w-2 h-2 rounded-full bg-pink-500" />
            <span v-if="presale.isActive" class="text-green-400 text-xs font-semibold">Active</span>
            <span v-else class="text-pink-500 text-xs font-semibold">Inactive</span>
            <a-switch v-model="presale.isActive" size="small" @change="checked => {
              if (presale_schedule && !checked) {
                disablePresaleSchedule();
              }
            }" />
          </div>
        </div>
        <template v-if="presale.isActive">
          <div class="flex items-center">
            <a-tag available>
              <span>Available From</span>
              <a @click="setPointer('start')">{{ presale.availableRange.start ? $formatDate(presale.availableRange.start,
                'M-D-YYYY, h:mm a') : "Now" }}</a>
              <span>To</span>
              <a @click="setPointer('end')">{{ presale.availableRange.end ? $formatDate(presale.availableRange.end,
                'M-D-YYYY, h:mm a') : "Forever" }}</a>
            </a-tag>
            <a-button v-if="isCalendarEnabled" type="link" icon="close" @click="() => {
              isCalendarEnabled = false;
            }" />
            <a-button v-else type="link" icon="edit" @click="setPointer('start')" />
          </div>
          <template v-if="isCalendarEnabled">
            <div class="w-full flex items-center justify-center">
              <span class="text-base">Set a <a class="text-parqay-primary font-semibold">{{ pointerState }}</a>
                date</span>
              <span class="text-base">, Or <a class="text-orange-400 font-semibold" @click="() => {
                presale.availableRange[pointerState] = null;
                isCalendarEnabled = false;
              }">leave it unbounded</a>
              </span>
            </div>
            <vc-date-picker v-model="pointerDate" mode="dateTime" :columns="1" :min-date="controls.minDate"
              @input="updatePresaleDate" />
          </template>
          <div class="w-full flex items-center justify-between px-4 space-x-4">
            <a-button type="danger" :loading="controls.loading.delete" icon="close" block size="large"
              :disabled="!presale_schedule" @click.stop="disablePresaleSchedule()">Delete</a-button>
            <a-button type="primary" :loading="controls.loading.save" icon="save" block size="large"
              @click.stop="updatePresaleSchedule()">Save</a-button>
          </div>
        </template>
        <div v-else class="flex flex-col items-center justify-center space-y-4 py-2">
          <span class="text-base text-gray-400 font-medium">No active schedule.</span>
          <a-button icon="plus" @click.stop="() => presale.isActive = true">Setup schedule</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.presale::v-deep {
  select {
    background-image: unset;
  }

  .ant-btn {
    @apply flex items-center justify-center;
  }

  .ant-tag[available] {
    span {
      @apply text-sm text-black font-semibold;
    }

    a {
      @apply text-parqay-primary text-sm font-bold;
    }

    @apply flex items-center space-x-1.5 px-4 py-2 border border-gray-300 bg-white hover:bg-gray-50 text-gray-600 font-medium;
  }

  .vc-time-picker {
    @apply inline-flex w-full px-2;
  }

  .vc-date-time {
    @apply pr-4 py-4;
  }

  @apply w-full;
}
</style>