<script>
import VueQr from 'vue-qr'

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    components: {
        VueQr
    },
    setup(props, ctx) {

        const getNativeListingURL = (listingId) => {
            return process.env.VUE_APP_MARKETPLACE_FRONTEND_BASE_URL + "/listings/" + listingId;
        }

        const constructEventURLs = () => {

            const baseURL = getNativeListingURL(props.form.id);

            const intervalModeParam = 'daily'; // Fixed value as per instructions

            return props.form.rates.rules.flatMap(rule => {
                // Construct the title using the first two elements of the title array
                const eventTitle = `${rule.title[0]}: ${rule.title[1]}`;
                const encodedTitle = encodeURIComponent(eventTitle);

                // Map through intervals and construct query strings
                return rule.intervals.map(interval => {
                    const startParam = encodeURIComponent(interval.start);
                    const endParam = encodeURIComponent(interval.end);

                    return `${baseURL}?title=${encodedTitle}&start=${startParam}&end=${endParam}&interval_mode=${intervalModeParam}`;
                });
            });
        };

        const autocompleteLink = link => {
            if (link && !link.startsWith('http://') && !link.startsWith('https://')) {
                return `https://${link}`;
            }
            return link;
        };
        return {
            autocompleteLink,
            getNativeListingURL,
            constructEventURLs
        }
    }
}
</script>
<template>
    <div class="w-full px-4 grid grid-cols-5">
        <div class="col-span-3 flex flex-col items-center justify-start space-y-4" style="max-width: 520px;">
            <span class="text-center font-medium">QR Code to jump directly into marketplace URL</span>
            <vue-qr v-if="form.events" color-dark="#1c55e4" background-color="#c5d4fa"
                logo-src="/static/img/dashboard/parqay-logo.png" :text="constructEventURLs()[0]" :dot-scale="0.8"
                :logo-scale="0.2" :size="1024" style="width: 500px; height: 500px;" />
            <vue-qr v-else color-dark="#1c55e4" background-color="#c5d4fa" logo-src="/static/img/dashboard/parqay-logo.png"
                :text="autocompleteLink(form.marketing_url) || getNativeListingURL(form.id)" :dot-scale="0.8"
                :logo-scale="0.2" :size="1024" style="width: 500px; height: 500px;" />
        </div>
        <div class="col-span-2 flex flex-col items-center justify-start pt-2 pb-8 space-y-4 overflow-y-auto"
            style="max-height: 65vh;">
            <span class="text-xl font-semibold">How to download / print?</span>
            <span>Move the mouse onto the QR code, right click to call context menu:</span>
            <div class="px-6 py-2">
                <p class="py-1 text-sm"><a-tag color="geekblue">To Download</a-tag>Click "<b>Save Image As...</b>"</p>
                <p class="py-1 text-sm"><a-tag color="geekblue">To Print</a-tag>Open downloaded image in step 1, then
                    click print in previewer</p>
            </div>
            <template v-if="form.events">
                <template v-for="(eventURL, i) in constructEventURLs()">
                    <span :key="`event-qr-code-${i}-title`" class="text-center font-medium">Event #{{ i + 1 }} Direct QR
                        Code</span>
                    <vue-qr :key="`event-qr-code-${i}`" color-dark="#1c55e4" background-color="#c5d4fa"
                        logo-src="/static/img/dashboard/parqay-logo.png" :text="eventURL" :dot-scale="0.8" :logo-scale="0.2"
                        :size="500" class="cursor-pointer hover:ring-8 ring-parqay-primary"
                        style="width: 250px; height: 250px;" />
                </template>
            </template>
        </div>
    </div>
</template>