<script>
import { ref, computed, onMounted, reactive, watch } from "@vue/composition-api";

import { API } from "src/views/listings/api";
import TabHeader from "src/views/components/TabHeader";
import Availability from "./availability/View";
import Overview from "./summary/View";
import Rates from "./rates/View";
import Admin from "./admin/View";
import Settings from "./settings/View";
import Calendar from './BookingCalendar.vue';
import Qrcode from "./qr-code/View.vue";
import Team from "src/views/team/View.vue";
import ListingSearchDropdown from "src/views/components/ListingSearchDropdown";
import startCase from "lodash/startCase";

export default {
  name: "Listings",
  components: {
    TabHeader,
    Availability,
    Overview, //Note "summary" is reserved
    Rates,
    Admin,
    Settings,
    Calendar,
    Qrcode,
    Team,
    ListingSearchDropdown,
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const router = ctx.root.$router;
    const deepGet = ctx.root.$deepGet;

    const tabs = ref([
      { name: "overview", label: "Overview" },
      { name: "rates", label: "Pricing" },
      { name: "settings", label: "Settings" },
      { name: "calendar", label: "Booked Slots" },
      { name: "qrcode", label: "QR Code" },
      { name: "team", label: "Attendants" }
    ]);

    const activeTab = ref(tabs.value[0].name);

    const controls = reactive({
      loading: false,
      key: 0
    });

    const form = ref({});

    const componentData = computed(() => {
      if (activeTab.value == "overview") {
        return deepGet(form.value, "summary");
      }
      // Note: prop - componentData changed to model
      if (activeTab.value == "availability") {
        var availabilityData = deepGet(form.value, "availability", {});
        availabilityData.title = deepGet(form.value, "summary.title");
        return availabilityData;
      }
      if (activeTab.value == "rates") {
        var ratesData = deepGet(form.value, "rates", {});
        ratesData.title = deepGet(form.value, "summary.title");
        return ratesData;
      }
      if (activeTab.value == "settings") {
        var settingsData = deepGet(form.value, "settings", {});
        settingsData.title = deepGet(form.value, "summary.title");
        return settingsData;
      }
      // if (activeTab.value == "admin") {
      //   // Temp disabled
      //   var adminData = deepGet(form.value, "admin");
      //   adminData.title = deepGet(form.value, "summary.title");
      //   return adminData;
      // }
    });

    const setupHashRoute = (hashRoute) => {
      router.push({ name: route.name, hash: `#${hashRoute}` });
    };
    const changeTab = (name) => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    const getEventInsights = async () => {
      try {
        const { data } = await API.getEventInsights(ctx.root.$route.params.id);
        ctx.root.$set(form.value, "eventInsights", data);
      } catch (error) {
        console.log("getEventInsights -> error: ", error);
      }
    }

    const addPricingTiersIfNeeded = (data) => {
      // Add tiers param in listing to represent tiered pricing
      if (!data.events && !data.tiers) {
        let tiers = [];
        data.rates.rules.forEach(rule => {
          tiers.push({
            id: rule.id,
            adjustments: []
          })
        });
        data.tiers = tiers;
      }
      return data;
    }

    const fetchData = async (listingId) => {
      try {
        controls.loading = true;
        ctx.root.$bus.$emit("toggle-loading-indicator", true, "Refreshing...");
        const { data } = await API.details(listingId);

        form.value = addPricingTiersIfNeeded(data);
        // Find the index of the "Availability" tab
        const availabilityTabIndex = tabs.value.findIndex(tab => tab.name === "availability");

        // Check if 'events' property exists or not
        if (form.value.events) {
          // If 'events' exists and 'availability' tab is present, remove it
          if (availabilityTabIndex !== -1) {
            tabs.value.splice(availabilityTabIndex, 1);
          }
        } else {
          // If 'events' does not exist and 'availability' tab is not present, add it
          if (availabilityTabIndex === -1) {
            tabs.value.splice(1, 0, { name: "availability", label: "Availability" });
          }
        }
        getEventInsights();
        ctx.root.$bus.$emit("toggle-loading-indicator", false);
        controls.loading = false;
      } catch (error) {
        ctx.root.$bus.$emit("toggle-loading-indicator", false);
        controls.loading = false;
      }
    };

    onMounted(() => {
      fetchData(route.params.id);
    });

    return {
      tabs,
      route,
      controls,
      form,
      activeTab,
      componentData,
      changeTab,
      fetchData,
      startCase,
    };
  },
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="flex justify-between items-center px-10 py-4">
      <div class="flex space-x-2 items-center font-medium text-xl cursor-pointer">
        <a class="text-parqay-primary" @click="$router.push('/dashboard/listings')">
          Listings
        </a>
        <span class="mx-2">/</span>
        <span v-if="form.id" class="truncate" style="max-width: 35vw;">{{ $deepGet(form, "summary.title") ||
          startCase(activeTab) }}</span>
        <a-icon type="reload" :spin="controls.loading" style="font-size: 1rem;"
          @click.stop="fetchData($route.params.id)" />
      </div>
      <div class="flex space-x-2 items-center">
        <span class="font-medium text-base">Property</span>
        <listing-search-dropdown :auto-select-first-on-blur="false" :show-select-all="false"
          :pre-select-listing-id="form.id" @selected="(selected) => {
          $bus.$emit('toggle-loading-indicator', true, 'Switching Property');
          $router.push({
            name: 'ListingsDetail',
            params: { id: selected.id },
            hash: '#overview',
          });
          fetchData(selected.id);
        }
          " />
      </div>
    </div>
    <div class="flex justify-center py-4 px-6">
      <tab-header v-model="activeTab" :tabs="tabs" :padding="50" :show-toggles="tabs.length > 6" class="w-full"
        @change="changeTab" />
    </div>
    <div v-if="form.id" class="flex justify-center py-4 px-6">
      <keep-alive>
        <component :is="activeTab" :listing-type="form.type" :component-data="componentData" :model="componentData"
          :form="form" @change-tab="tab => activeTab = tab" @refresh="fetchData($route.params.id)"
          @update-form="f => form = f" />
      </keep-alive>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {}
</style>