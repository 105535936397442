<script>
import { ref, watch, onMounted, onBeforeUnmount } from "@vue/composition-api";
import StatusTag from "src/views/reservations/components/StatusTag.vue";
import TypeTag from "src/views/reservations/components/TypeTag.vue";
import ConfirmationNumberTag from "src/views/reservations/components/ConfirmationNumberTag.vue";
import ApproveDenyTag from "src/views/reservations/components/ApproveDenyTag.vue";
import ListingAvatar from "src/views/components/ListingImageAvatar.vue";

import JSZip from "jszip";
import { startSignatureRequest } from "src/views/documents/components/Templates.vue";
import { getDropboxURL, getS3DownloadUrl } from "src/views/components/util";

export default {
  components: {
    StatusTag,
    TypeTag,
    ConfirmationNumberTag,
    ApproveDenyTag,
    ListingAvatar
  },
  props: {
    expandMode: {
      type: Boolean,
      default: false,
    },
    noDataDescription: {
      type: String,
      default: "No Reservation",
    },
    controls: {
      type: Object,
      default: () => ({
        loading: false,
      }),
    },
    pagination: {
      type: Object,
      default: () => ({
        currentPage: 1,
        perPage: 10,
        total: 0
      })
    },
    headers: {
      type: Array,
      default: () => [],
    },
    data: Array
  },
  setup(props, ctx) {
    const deepGet = ctx.root.$deepGet;
    const downloadAnchor = ref(null);
    const headers = ref(
      props.headers.length > 0
        ? props.headers
        : [
          {
            title: "Tenant",
            align: "center",
            dataIndex: "tenant_name",
            scopedSlots: { customRender: "name" },
          },
          {
            title: "Income",
            align: "center",
            dataIndex: "host_income",
            scopedSlots: { customRender: "host_income" },
          },
          {
            title: "Booked Intervals",
            align: "center",
            dataIndex: "durations",
            scopedSlots: { customRender: "durations" },
          },
          {
            title: "Status",
            align: "center",
            dataIndex: "status",
            scopedSlots: { customRender: "status" },
          },
          {
            title: "Listing",
            align: "center",
            dataIndex: "title",
            scopedSlots: { customRender: "reservation-name" },
          },
          {
            title: "Type",
            align: "center",
            dataIndex: "listing.type",
            scopedSlots: { customRender: "reservation-type" },
          },
          {
            title: "Confirmation#",
            align: "center",
            dataIndex: "confirmation_id",
            scopedSlots: { customRender: "confirmation" },
          },
          {
            title: "Source",
            align: "center",
            dataIndex: "source",
            scopedSlots: { customRender: "source" },
          },
          {
            title: "Contact",
            align: "center",
            dataIndex: "contact_id",
            scopedSlots: { customRender: "contact" },
            fixed: "right",
          },
          {
            title: "Doc",
            align: "center",
            dataIndex: "docs",
            scopedSlots: { customRender: "doc" },
            fixed: "right",
          }
        ]
    );
    const table = ref({
      headers: [],
      items: [],
    });
    watch(
      () => props.data,
      (val) => {
        const reservations = val ? val : [];
        table.value = {
          headers: headers.value,
          items: reservations,
        };
        fetchReservationMessagesUnreadCount();
      }
    );
    const fetchReservationMessagesUnreadCount = () => {
      ctx.root.$socket.emit("get-messages-unread-count", {
        user_uid: ctx.root.$getUserUID()
      });
    }
    const downloadDocs = async (record) => {
      ctx.root.$set(record, "$loading.download", true);
      const zip = new JSZip();
      // Dropbox paths
      for (const filePath of [
        ...deepGet(record, "files", [])
          .filter((v) => v.dbx_path)
          .map((v) => v.dbx_path),
        ...deepGet(record, "signatures", [])
          .filter((v) => v.dbx_path)
          .map((v) => v.dbx_path),
      ].filter((v) => v)) {
        let resp;
        try {
          resp = await getDropboxURL(filePath);
          console.log("resp => ", resp);
          zip.file(resp.name, resp.fileBlob, { base64: true });
        } catch (error) {
          console.log("filesDownload -> Dropbox >> error: ", error);
          continue;
        }
      }
      // S3 paths
      for (const f of [
        ...deepGet(record, "files", []).filter((v) => v.s3_path),
        ...deepGet(record, "signatures", []).filter((v) => v.s3_path),
      ].filter((v) => v)) {
        let filename = f.s3_path.split("/").pop();
        let url;
        try {
          url = await getS3DownloadUrl({ path: f.s3_path });
          console.log("url => ", url);
          let blob = await fetch(url).then((r) => r.blob());
          zip.file(filename, blob, { base64: true });
        } catch (error) {
          console.log("filesDownload -> S3 >> error: ", error);
          continue;
        }
      }
      ctx.root.$set(record, "$loading.download", false);
      const content = await zip.generateAsync({ type: "blob" });
      if (downloadAnchor.value) {
        const dataURL = URL.createObjectURL(content);
        downloadAnchor.value.setAttribute("href", dataURL);
        downloadAnchor.value.setAttribute(
          "download",
          `${record.template.name}-documents-${record.tenant_name}.zip`
        );
        downloadAnchor.value.click();
      }
      ctx.root.$bus.$emit("document-download-stop");
    };
    const signDocument = async (doc) => {
      // ctx.root.$bus.$emit("toggle-loading-indicator", true, "Preparing Document");
      ctx.root.$set(doc, "$loading.sign", true);
      await startSignatureRequest(doc, "host");
      // ctx.root.$bus.$emit("toggle-loading-indicator", false);
      ctx.root.$set(doc, "$loading.sign", false);
      ctx.root.$bus.$emit("close-documents-drawer");
    };
    onMounted(() => {
      ctx.root.$bus.$on("document-download-start", downloadDocs);
      ctx.root.$bus.$on("start-new-document-signature-request", signDocument);
    })
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("document-download-start", downloadDocs);
      ctx.root.$bus.$off("start-new-document-signature-request", signDocument);
    });
    return {
      table,
      console,
    };
  },
};
</script>
<template>
  <div>
    <a-config-provider>
      <template #renderEmpty>
        <div class="flex flex-col space-y-4 items-center justify-center py-32">
          <a-empty v-if="!controls.loading" :description="noDataDescription" />
          <slot name="no-data-buttons" />
        </div>
      </template>
      <a-table v-if="expandMode" :scroll="{ x: true, y: false }" :loading="controls.loading" :columns="table.headers"
        :data-source="table.items" row-key="id" :custom-row="(record, index) => {
          return {
            on: {
              click: (event) => {
                $bus.$emit('open-reservation-drawer', record);
              },
            },
          };
        }
          " :pagination="false">
        <template slot="name" slot-scope="name, record">
          <div class="flex items-center justify-center space-x-1">
            <avatar :user-id="record.customer" :admin-on-behalf-of="record.user" />
          </div>
        </template>

        <template slot="reservation-name" slot-scope="title, record">
          <div class="flex items-center space-x-3">
            <listing-avatar width="50" height="50" rounded="sm" :listing-id="record.listing.id" />
            <div class="flex flex-col items-center justify-center space-y-2">
              <a-tag>{{ title ? title : $deepGet(record, "listing.name") }}</a-tag>
              <span v-if="$deepGet(record, 'event')" class="text-xs ant-tag-geekblue border rounded-md px-3 py-1"
                style="max-width: 160px;">
                {{
                  record.event
                }}
              </span>
            </div>
          </div>
        </template>

        <template slot="reservation-type" slot-scope="type, record">
          <div class="flex flex-col space-y-2">
            <type-tag :type="type" />
            <a-tag v-if="$deepGet(record, 'event')" color="geekblue">Event</a-tag>
          </div>
        </template>

        <template slot="host_income" slot-scope="host_income">
          <span class="text-green-400 text-xs font-medium">{{ $formatCurrency(host_income) }}</span>
        </template>

        <template slot="location" slot-scope="location, record">
          <div class="flex items-center space-x-4 justify-between">
            <div class="flex flex-col space-y-2">
              <type-tag :type="record.listing.type" style="font-size: 10px;" />
              <a-tag v-if="$deepGet(record, 'event')" color="geekblue" style="font-size: 10px;">Event</a-tag>
            </div>
            <a class="flex-grow text-parqay-primary font-medium text-xs text-left" @click.stop="
              $router.push({
                name: 'ListingsDetail',
                params: {
                  id: record.listing.id,
                },
              })
              ">
              {{ location }}
            </a>
          </div>
        </template>

        <template slot="expandedRowRender" slot-scope="record">
          <div class="flex flex-col space-y-2">
            <div v-if="$deepGet(record, 'request_message')" class="flex items-center space-x-1">
              <span class="text-black font-medium">Request message:</span>
              <span class="text-xs bg-white border rounded-md px-3 py-1">
                "
                {{
                  record.request_message
                }}
                "
              </span>
            </div>
            <div v-for="(duration, i) in record.durations" :key="`reservation-duration-${i}`"
              class="flex space-x-2 items-center">
              <div class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                {{ $parseBackendDate(duration.from, "MMM DD, h:mm a") }}
              </div>
              <svg-icon icon-class="arrow-circle-right" class="text-parqay-primary w-6 h-6" />
              <div class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                {{ $parseBackendDate(duration.to, "MMM DD, h:mm a") }}
              </div>
            </div>
            <div v-if="$deepGet(record, 'event')">
              <span class="text-xs ant-tag-geekblue border rounded-md px-3 py-1">
                {{
                  record.event
                }}
              </span>
            </div>
          </div>
        </template>

        <template slot="actions" slot-scope="actions, record">
          <approve-deny-tag :form="record" @refresh="$bus.$emit('reservation-request-status-change')" />
        </template>
      </a-table>
      <a-table v-else :scroll="{ x: true, y: false }" :loading="controls.loading" :columns="table.headers"
        :data-source="table.items" row-key="id" :custom-row="(record, index) => {
          return {
            on: {
              click: (event) => {
                $bus.$emit('open-reservation-drawer', record);
              }
            },
          };
        }
          " :pagination="false">
        <template slot="name" slot-scope="name, record">
          <div class="flex items-center justify-between py-2 space-x-4">
            <!-- <img
                  class="h-10 w-10 rounded-full object-cover ring-2 ring-indigo-500"
                  :src="record.avator"
                /> -->
            <avatar :user-id="record.customer" :admin-on-behalf-of="record.user" />
            <a class="font-medium text-xs text-gray-900 flex-grow text-center" @click.stop="
              $router.push({
                name: 'Tenants',
                query: {
                  focus: $isAdmin() ? `${record.customer},${record.user}` : record.customer
                }
              })
              ">
              {{ name }}
            </a>
          </div>
        </template>

        <template slot="reservation-name" slot-scope="title, record">
          <div class="flex items-center space-x-3">
            <listing-avatar width="50" height="50" rounded="sm" :listing-id="record.listing.id" />
            <div class="flex flex-col items-center justify-center space-y-2">
              <a-tag>{{ title ? title : $deepGet(record, "listing.name") }}</a-tag>
              <span v-if="$deepGet(record, 'event')" class="text-xs ant-tag-geekblue border rounded-md px-3 py-1"
                style="max-width: 160px;">
                {{
                  record.event
                }}
              </span>
            </div>
          </div>
        </template>

        <template slot="durations" slot-scope="durations">
          <div class="flex flex-col space-y-2">
            <div v-for="(duration, i) in durations" :key="`reservation-duration-${i}`"
              class="flex space-x-2 items-center">
              <div class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                {{ $parseBackendDate(duration.from, "MMM DD, h:mm a") }}
              </div>
              <svg-icon icon-class="arrow-circle-right" class="text-parqay-primary w-6 h-6" />
              <div class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                {{ $parseBackendDate(duration.to, "MMM DD, h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template slot="reservation-type" slot-scope="type, record">
          <div class="flex flex-col space-y-2">
            <type-tag :type="type" />
            <a-tag v-if="$deepGet(record, 'event')" color="geekblue">Event</a-tag>
          </div>
        </template>

        <template slot="status" slot-scope="status, record">
          <div class="flex flex-col space-y-1 items-center">
            <status-tag :status="status">
              <template v-if="record.request_message || record.cancel_reason || record.deny_message" #action>
                <div class="py-1">
                  <a-button type="primary" size="small"
                    style="font-size:10px; padding: 4px; height: 18px; line-height: 9px;"
                    @click.stop="() => $set(record, '$showRequestMessage', true)">message</a-button>
                </div>
                <a-modal :visible="record.$showRequestMessage" title="Request Message" :footer="null"
                  @cancel="() => record.$showRequestMessage = false">
                  <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto">
                    <div class="bg-gray-100 px-4 py-3 flex items-center space-x-2">
                      <avatar :user-id="record.customer" show-name :admin-on-behalf-of="record.user" />
                    </div>
                    <div class="p-4 relative">
                      <div class="bg-gray-100 rounded-lg py-2 px-3 mb-1 inline-block">
                        <span class="text-gray-900 leading-tight">{{ record.request_message }}</span>
                      </div>
                      <div
                        class="absolute right-0 bottom-0 border-t-8 border-gray-100 border-solid transform rotate-45 -mr-4">
                      </div>
                    </div>
                  </div>
                  <template v-if="record.cancel_reason">
                    <a-divider style="color: orange;">Cancel Reason</a-divider>
                    <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto">
                      <div class="bg-gray-100 px-4 py-3 flex items-center space-x-2">
                        <avatar :user-id="record.user" show-name relative-role="host" />
                      </div>
                      <div class="p-4 relative">
                        <div class="bg-gray-100 rounded-lg py-2 px-3 mb-1 inline-block">
                          <span class="text-gray-900 leading-tight">{{ record.cancel_reason }}</span>
                        </div>
                        <div
                          class="absolute right-0 bottom-0 border-t-8 border-gray-100 border-solid transform rotate-45 -mr-4">
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="record.deny_message">
                    <a-divider style="color: red;">Denied Reason</a-divider>
                    <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto">
                      <div class="bg-gray-100 px-4 py-3 flex items-center space-x-2">
                        <avatar :user-id="record.user" show-name relative-role="host" />
                      </div>
                      <div class="p-4 relative">
                        <div class="bg-gray-100 rounded-lg py-2 px-3 mb-1 inline-block">
                          <span class="text-gray-900 leading-tight">{{ record.deny_message }}</span>
                        </div>
                        <div
                          class="absolute right-0 bottom-0 border-t-8 border-gray-100 border-solid transform rotate-45 -mr-4">
                        </div>
                      </div>
                    </div>
                  </template>
                </a-modal>
              </template>
            </status-tag>
            <approve-deny-tag v-if="status === 'request'" :form="record" @refresh="$emit('refresh')" />
          </div>
        </template>

        <template slot="confirmation" slot-scope="confirmation_id, record">
          <confirmation-number-tag :confirmation-id="confirmation_id" />
        </template>

        <template slot="host_income" slot-scope="host_income">
          <span class="text-green-400 text-xs font-medium">+{{ $formatCurrency(host_income) }}</span>
        </template>

        <template slot="location" slot-scope="location, record">
          <router-link tag="a" :to="{
            name: 'ListingsDetail',
            params: {
              id: record.listing.id,
            },
          }" class="text-parqay-primary font-medium text-xs">
            {{ location }}
          </router-link>
        </template>

        <template slot="source" slot-scope="source, record">
          <div class="flex flex-col items-center justify-center space-y-2">
            <a-tag v-if="source" color="geekblue">
              {{ source }}
            </a-tag>
            <a-tag v-if="record.platform === 'web'" color="cyan">
              Web App
            </a-tag>
            <a-tag v-else-if="record.platform === 'ios'" color="cyan">
              iOS
            </a-tag>
            <a-tag v-else>
              platform: N/A
            </a-tag>
          </div>
        </template>

        <template slot="contact" slot-scope="contact_id, record">
          <div class="flex items-center space-x-2 justify-center">
            <div class="flex items-center justify-center"
              @click.stop="$bus.$emit('toggle-email-editor', { tenant_id: record.customer })">
              <svg-icon icon-class="envelope" class="h-6 w-6 text-gray-600 cursor-pointer hover:text-parqay-primary" />
            </div>
            <a-badge :count="$store.getters.getUnreadMessagesCountForReservation(record)"
              @click.stop="$openContactSlideCard(record)">
              <svg-icon icon-class="chat" class="h-6 w-6 text-gray-600 cursor-pointer hover:text-parqay-primary" />
            </a-badge>
          </div>
        </template>

        <template slot="doc" slot-scope="docs, record">
          <div class="pointer-events-auto" @click.stop="$openDocSlideCard('host', null, record.id)">
            <svg-icon icon-class="document" class="h-6 w-6 text-gray-600 cursor-pointer hover:text-parqay-primary" />
          </div>
        </template>
      </a-table>
    </a-config-provider>
    <div class="flex items-center justify-center py-4">
      <a-pagination :current="pagination.currentPage" :total="pagination.total" :page-size="pagination.perPage"
        @change="(page) => $emit('page-change', page)" />
    </div>
  </div>
</template>
