<script>
import { onMounted, reactive } from "@vue/composition-api";
import LineChart from "./EarningsHistoryLineChart.vue";
import { API } from "../api";
export default {
    components: {
        LineChart
    },
    setup(props, ctx) {
        const controls = reactive({
            loading: false,
            activeTab: "total",
            pending: 0,
            available: 0,
            paid: 0
        })
        const fetchData = async () => {
            controls.loading = true;
            const { data } = await API.balanceSummary();
            const { pending, available, paid } = data;
            controls.pending = pending;
            controls.available = available;
            controls.paid = paid;
            controls.loading = false;
        }
        onMounted(() => {
            fetchData()
        })
        return {
            controls
        }
    }
}
</script>
<template>
    <div class="flex flex-col bg-white border rounded-lg px-6 py-6 space-y-6"
        :class="{'cursor-pointer hover:shadow-lg': $route.path === '/dashboard'}" @click="() => {
            if ($route.path === '/dashboard') {
                $router.push('/dashboard/payments')
            }
        }">
        <div class="flex justify-between">
            <div class="flex flex-col space-y-2">
                <div class="flex space-x-6 items-center">
                    <span class="font-semibold text-base cursor-pointer"
                        :class="controls.activeTab === 'total' ? 'text-black hover:text-gray-700' : 'text-gray-400 hover:text-black'"
                        @click.stop="() => controls.activeTab = 'total'">Total earnings</span>
                    <span class="font-semibold text-base cursor-pointer"
                        :class="controls.activeTab === 'history' ? 'text-black hover:text-gray-700' : 'text-gray-400 hover:text-black'"
                        @click.stop="() => controls.activeTab = 'history'">Earnings history</span>
                </div>
                <span v-if="controls.activeTab === 'total'" class="font-bold text-blue-600 text-3xl">
                    {{ controls.loading ? "-" : $formatCurrency(controls.available) }}
                </span>
            </div>
            <div v-if="true" class="flex items-center space-x-2">
                <a-button @click.stop="$openIntercom()">Contact us</a-button>
                <a-button type="primary" @click.stop="() => $router.push('/dashboard/account-settings#check-earnings')">
                    Setup Payout Account
                </a-button>
            </div>
            <div v-else class="flex items-center space-x-2">
                <!-- Default Payment Method -->
            </div>
        </div>
        <a-steps v-if="controls.activeTab === 'total'" progress-dot>
            <a-step title="Pending" status="finish">
                <template #description>
                    <span class="block text-orange-400 font-medium">{{ $formatCurrency(controls.pending) }}</span>
                    <span class="block">Processing amount</span>
                </template>
            </a-step>
            <a-step title="Ready" status="finish">
                <template #description>
                    <span class="block text-blue-600 font-medium">{{ $formatCurrency(controls.available) }}</span>
                    <span class="block">Ready for payout</span>
                </template>
            </a-step>
            <a-step title="Paid" status="finish">
                <template #description>
                    <span class="block text-green-400 font-medium">{{ $formatCurrency(controls.paid) }}</span>
                    <span class="block">Sent to payout</span>
                </template>
            </a-step>
        </a-steps>
        <div v-if="controls.activeTab === 'history'" class="relative pt-6">
            <line-chart line-color="#5E72E4" area-color="rgba(94, 114, 228, 0.18)"
            :chart-data="[2000, 3000, 1500, 4500, 8000, 3500, 10500]" :style="{height: '200px'}" />
            <div class="absolute inset-0" :style="{top: '-20px'}">
                <div class="flex items-start justify-center h-full pt-3">
                    <span class="text-sm font-medium text-blue-600">Your earnings history graph will be generated once we've processed enough transactions data</span>
                </div>
            </div>
        </div>
    </div>
</template>