var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"booking-calendar pb-4 px-6"},[_vm._l((Object.keys(_vm.form.summary.spaces.groups)),function(groupId,i){return [_c('a-divider',{key:("divider-" + i)},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v("\n                    "+_vm._s(_vm.$deepGet(_vm.form, ("summary.spaces.groups." + groupId + ".name"), ("Space Group #" + i)))+"\n                ")]),_c('a-icon',{staticStyle:{"color":"gray"},attrs:{"type":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('change-tab', 'overview')}}})],1)]),_c('div',{key:("group-" + i),staticClass:"flex flex-wrap gap-4 items-center justify-center pb-4"},_vm._l((_vm.form.summary.spaces.items.filter(function (v) { return v.group_id === groupId; })),function(item,j){return _c('div',{key:("calendar-" + (item.id)),staticClass:"flex flex-col items-center justify-center space-y-2"},[_c('span',{staticClass:"text-base text-parqay-primary font-medium pb-2"},[_vm._v("Space #"+_vm._s(j + 1))]),_c('vc-calendar',{attrs:{"from-page":_vm.getFocusPage(item),"color":'blue',"attributes":_vm.getAttrs(item)},on:{"dayclick":function (day) { return _vm.console.log('dayclick -> ', day); }},scopedSlots:_vm._u([{key:"day-popover",fn:function(ref){
var dayTitle = ref.dayTitle;
var attributes = ref.attributes;
return [_c('ul',{staticClass:"flex flex-col"},_vm._l((attributes),function(ref){
var key = ref.key;
var customData = ref.customData;
return _c('li',{key:key,staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center justify-between py-1 space-x-1"},[_c('a-tag',{attrs:{"color":"orange"}},[_vm._v("From")]),_c('span',{staticClass:"text-black text-xs font-semibold"},[_vm._v(_vm._s(_vm.$formatDate(customData.start,
                                        'MM-DD-YYYY, h:mmA')))])],1),_c('div',{staticClass:"flex items-center justify-between py-1 space-x-1"},[_c('a-tag',{attrs:{"color":"orange"}},[_vm._v("To")]),_c('span',{staticClass:"text-black text-xs font-semibold"},[_vm._v(_vm._s(_vm.$formatDate(customData.end,
                                        'MM-DD-YYYY, h:mmA')))])],1),_c('div',{staticClass:"flex items-center justify-center space-x-2 text-xs text-gray-700 hover:bg-blue-100 px-2 py-2 cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$bus.$emit('open-reservation-drawer', null, customData.reservation_id)}}},[_c('div',{staticClass:"flex-shrink-0 w-2 h-2 rounded-full bg-green-400"}),_c('span',[_vm._v("Reservation "),_c('a',{staticClass:"text-parqay-primary font-semibold"},[_vm._v("#"+_vm._s(customData.reservation_id.substring(0, 6)))])])])])}),0)]}}],null,true)})],1)}),0)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }