<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import Parallax from "parallax-js";
export default {
  name: "ParallexHouseIncomeDemoMobile",
  props: {},
  setup(props, ctx) {
    const heroEffect = ref(null);
    var parallaxInstance;
    const armsAnimations = ref({
      parking: false,
      house: false,
      office: false,
      car: false,
    });
    const toggleArmsAnimations = (t, v) => {
      console.log("toggleArmsAnimations: " + v);
      armsAnimations.value[t] = v;
      ctx.emit("arm-focus", t);
    };
    onMounted(() => {
      // parallaxInstance = new Parallax(heroEffect.value, {
      //   relativeInput: true,
      // });
    });
    return {
      heroEffect,
      armsAnimations,
      toggleArmsAnimations,
    };
  },
};
</script>
<template>
  <div
    ref="heroEffect"
    data-relative-input="true"
    class="h-full w-full"
    data-limit-x="30"
    data-limit-y="30"
  >
    <div
      class="layer absolute"
      style="left: -30px; top: -30px; height: 115%; width: 110%"
      data-depth="0.4"
    >
      <img
        class="absolute inset-0 h-full w-full object-contain"
        src="/static/img/dashboard/db-login-background.png"
        alt=""
      />
    </div>
    <div
      class="layer absolute"
      data-depth="0.5"
      :style="{
        left: '50px',
        bottom: '60px',
        width: '3rem',
        height: '3rem',
      }"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        @mouseenter="toggleArmsAnimations('parking', true)"
        @mouseleave="toggleArmsAnimations('parking', false)"
      >
        <svg-icon
          icon-class="demo-float-arm-dot"
          class="w-12 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          :class="{
            'animate-pulse': !armsAnimations.parking,
            'animate-none': armsAnimations.parking,
          }"
        />
      </div>
    </div>

    <div
      class="layer absolute"
      data-depth="0.5"
      :style="{
        left: '50px',
        top: '40px',
        width: '3rem',
        height: '3rem',
      }"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        @mouseenter="toggleArmsAnimations('house', true)"
        @mouseleave="toggleArmsAnimations('house', false)"
      >
        <svg-icon
          icon-class="demo-float-arm-dot"
          class="w-12 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          :class="{
            'animate-pulse': !armsAnimations.house,
            'animate-none': armsAnimations.house,
          }"
        />
      </div>
    </div>

    <div
      class="layer absolute"
      data-depth="0.5"
      :style="{
        right: '60px',
        top: '40px',
        width: '3rem',
        height: '3rem',
      }"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        @mouseenter="toggleArmsAnimations('office', true)"
        @mouseleave="toggleArmsAnimations('office', false)"
      >
        <svg-icon
          icon-class="demo-float-arm-dot"
          class="w-12 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          :class="{
            'animate-pulse': !armsAnimations.office,
            'animate-none': armsAnimations.office,
          }"
        />
      </div>
    </div>

    <div
      class="layer absolute"
      :style="{
        right: '35px',
        bottom: '30px',
        height: '5rem',
        width: '5rem',
      }"
      data-depth="0.36"
    >
      <div class="absolute cursor-pointer pointer-events-auto">
        <img
          src="/static/img/dashboard/car-float-arm.png"
          class="w-20 h-16 object-contain"
        />
      </div>
    </div>

    <div
      class="layer absolute"
      data-depth="0.5"
      :style="{
        right: '35px',
        bottom: '30px',
        height: '5rem',
        width: '5rem',
      }"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        @mouseenter="toggleArmsAnimations('car', true)"
        @mouseleave="toggleArmsAnimations('car', false)"
      >
        <svg-icon
          icon-class="demo-float-arm-dot"
          class="w-12 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          :class="{
            'animate-pulse': !armsAnimations.car,
            'animate-none': armsAnimations.car,
          }"
        />
      </div>
    </div>
  </div>
</template>
