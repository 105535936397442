<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "@vue/composition-api";
import TabHeader from "src/views/components/TabHeader.vue";
import AdminVerificationResults from "src/views/documents/components/AdminVerificationResults.vue";
import DocumentTable from "src/views/documents/components/Table.vue";
import { startVerifyIdentity } from "./components/Templates.vue";
import startCase from "lodash/startCase";

import { API } from "src/views/documents/api";

export default {
  components: {
    TabHeader,
    DocumentTable,
    AdminVerificationResults
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const router = ctx.root.$router;

    const controls = reactive({
      loading: {
        fetchStatus: false,
        verifyResult: false
      },
      typeFilter: {},
      statusFilter: {},
      verifyResult: {}
    });

    const tabs = ref(
      ctx.root.$isAdmin() ? [
        { name: "all", label: "All Documents", action: () => ctx.root.$bus.$emit("documents-refresh") },
        { name: "admin", label: "Verification Results" }
      ] : [
        { name: "host", label: "For Me", filter: { type: "sign" }, action: () => ctx.root.$bus.$emit("documents-refresh") },
        { name: "tenant", label: "By Tenants", action: () => ctx.root.$bus.$emit("documents-refresh") },
        { name: "all", label: "All", action: () => ctx.root.$bus.$emit("documents-refresh") }
      ]);

    const activeTab = ref(ctx.root.$isAdmin() ? "all" : "host");

    const verifyHostIdentity = async () => {
      controls.loading.verifyResult = true;
      await startVerifyIdentity({ is_host: true });
    };

    const getHostIdentityVerificationResult = async () => {
      let resp, result;
      if (controls.verifyResult === {}) {
        return controls.verifyResult.is_verified;
      }
      controls.loading.fetchStatus = true;
      try {
        resp = await API.getIdentityVerificationResult({ is_host: true });
        console.log("getHostIdentityVerificationResult: ", resp.data);
      } catch (error) {
        console.log("getHostIdentityVerificationResult, error: ", error);
      }
      controls.loading.fetchStatus = false;
      result = resp.data.is_verified;
      ctx.root.$set(controls, "verifyResult", resp.data);
      return result;
    };

    const setupHashRoute = (hashRoute) => {
      router.push({ name: route.name, hash: `#${hashRoute}`, query: route.query });
    };

    const changeTab = (name) => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    const handleVerificationResult = (result) => {
      controls.verifyResult.is_verified = result;
      controls.loading.verifyResult = false;
    }

    const getDocumentFilters = () => {
      let filters = {
        ...controls.typeFilter,
        ...controls.statusFilter
      };
      const tab = tabs.value.find(tab => tab.name === activeTab.value);
      if (tab && tab.filter) {
        filters = {
          ...filters,
          ...tab.filter
        }
      }
      return filters;
    }

    onMounted(async () => {
      ctx.root.$bus.$on("show-identity-verification-result", handleVerificationResult);
      await getHostIdentityVerificationResult();
    });

    onBeforeUnmount(() => {
      ctx.root.$bus.$off("show-identity-verification-result", handleVerificationResult);
    });

    return {
      controls,
      tabs,
      activeTab,
      getDocumentFilters,
      getHostIdentityVerificationResult,
      verifyHostIdentity,
      changeTab,
      startCase
    };
  },
};
</script>

<template>
  <div class="absolute inset-0">
    <div class="relative">
      <div class="flex flex-col px-10 py-10 space-y-4">
        <div class="flex space-x-2 items-center">
          <tab-header v-model="activeTab" :tabs="tabs" class="w-full" @change="changeTab">
            <template #right-actions>
              <div class="flex items-center space-x-4">
                <a-button type="primary" icon="edit" @click.stop="$router.push('/dashboard/documents/templates')">
                  Templates
                </a-button>
                <a-tag class="identity-verification-tag">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span v-if="!$isAdmin()">
                        Identity verification process takes about 2-5 minutes: we'll
                        collect and verify your identity documents through this web
                        browser, a "Verified" badge will be assigned once the process
                        went through. Normally, with improved credibility, verified
                        hosts will have better exposure rate to marketplace tenants.
                      </span>
                      <span v-else>
                        Admin identity verification is required. To view all verification results submitted by
                        hosts/tenants, click "Hosts/Tenants Verifications"
                      </span>
                    </template>
                    <span v-if="!$isAdmin()" class="text-sm font-medium">Your identity</span>
                    <span v-else class="text-sm font-medium">Admin Identity</span>
                  </a-tooltip>
                  <a-tag v-if="controls.verifyResult.is_verified" color="green" class="verified-tag">
                    <span>Verified</span>
                    <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" :style="{ fontSize: '1rem' }" />
                  </a-tag>
                  <a-tag v-else color="gray">Unverified</a-tag>
                  <a-icon type="reload" :spin="controls.loading.fetchStatus" :style="{ fontSize: '12px', color: 'blue' }"
                    @click="getHostIdentityVerificationResult()" />
                  <!-- <span>|</span>
                <a-button v-if="controls.verifyResult.is_verified" type="primary" class="text-xs font-medium" size="small"
                  @click="
                    $openDocSlideCard(
                      'host',
                      controls.verifyResult
                    )
                    ">View</a-button> -->
                  <a-button v-if="!controls.verifyResult.is_verified" :loading="controls.loading.verifyResult"
                    size="small" class="text-xs font-medium" @click="verifyHostIdentity()">Verify</a-button>
                </a-tag>
              </div>
            </template>
          </tab-header>
        </div>

        <keep-alive>
          <admin-verification-results v-if="activeTab === 'admin'" />
          <div v-else class="flex flex-col bg-white border rounded-lg">
            <div class="w-full flex px-4 py-3 items-center justify-between">
              <span class="font-medium text-base text-black">Documents</span>
              <a-radio-group v-if="activeTab !== 'host'" default-value="all" button-style="solid" @change="e => {
                if (e.target.value === 'all') {
                  controls.typeFilter = {};
                } else {
                  controls.typeFilter = { type: e.target.value };
                }
              }">
                <a-radio-button value="all">
                  All
                </a-radio-button>
                <a-radio-button value="upload">
                  Upload
                </a-radio-button>
                <a-radio-button value="sign">
                  Sign
                </a-radio-button>
              </a-radio-group>
              <div class="flex items-center space-x-4">
                <a-dropdown-button>
                  {{ controls.statusFilter.status ? startCase(controls.statusFilter.status) : "All Status" }}
                  <a-menu slot="overlay" @click="e => {
                    if (e.key === 'all') {
                      controls.statusFilter = {};
                    } else {
                      controls.statusFilter = { status: e.key };
                    }
                  }">
                    <a-menu-item key="all">
                      <div class="flex items-center justify-start">
                        <div class="w-2 h-2 rounded-full bg-parqay-primary" />
                        <span class="text-sm px-4 py-1">All Status</span>
                      </div>
                    </a-menu-item>
                    <a-menu-item key="pending">
                      <div class="flex items-center justify-start">
                        <div class="w-2 h-2 rounded-full bg-orange-400" />
                        <span class="text-sm px-4 py-1">Pending</span>
                      </div>
                    </a-menu-item>
                    <a-menu-item key="complete">
                      <div class="flex items-center justify-start">
                        <div class="w-2 h-2 rounded-full bg-green-400" />
                        <span class="text-sm px-4 py-1">Complete</span>
                      </div>
                    </a-menu-item>
                  </a-menu>
                  <a-icon slot="icon" type="filter" />
                </a-dropdown-button>
              </div>
            </div>
            <document-table :filters="getDocumentFilters()" @change-tab="changeTab" />
          </div>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.ant-btn {
  @apply flex items-center space-x-2;
}

::v-deep {

  // Dropdown button 
  .ant-dropdown-trigger {
    @apply inline-flex items-center justify-center;
  }

  .identity-verification-tag {
    &:hover {
      @apply shadow-none border-gray-300 bg-white;
    }

    @apply flex rounded-full items-center space-x-1.5 px-4 py-1.5 text-black bg-white border-gray-200 shadow-inner cursor-pointer;
  }

  .verified-tag {
    @apply flex items-center justify-center space-x-1 py-0.5;
  }
}
</style>
