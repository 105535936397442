<script>
import { computed, watch, ref, reactive } from "@vue/composition-api";
import VehicleInfo from "src/views/listings/new/car-rentals/components/VehicleInfo";

import { API } from "src/views/non-host/reservations/api";

export default {
  name: "VehiclesForm",
  components: {
    VehicleInfo
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    controls: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    const updateReservationVehicle = async vehicle => {
      props.form.vehicle = vehicle;
      let resp;
      try {
        resp = await API.updateReservationVehicle(props.form.id, vehicle);
      } catch (error) {
        console.log("updateReservationVehicle >> error: ", error);
      }
      console.log("updateReservationVehicle >> success: ", resp);
    };
    const deleteReservationVehicle = async vehicleId => {
      let res;
      try {
        res = await API.deleteReservationVehicle(props.form.id, vehicleId);
      } catch (error) {
        console.log("deleteReservationVehicle => error", error);
      }
      console.log("deleteReservationVehicle", res);
    };
    return {
      updateReservationVehicle,
      deleteReservationVehicle
    };
  }
};
</script>

<template>
  <div v-if="$isDesktopView()"
    class="sm:grid sm:grid-cols-6 sm:gap-4 border-t sm:items-start sm:border-gray-200 sm:pt-5 px-5">
    <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      Vehicle
    </p>
    <div class="mt-1 sm:mt-0 sm:col-span-5">
      <div class="grid grid-cols-2 gap-4 pt-4">
        <template v-if="form.type != 'car-rental'">
          <div class="flex justify-between">
            <div class="flex flex-col">
              <span class="text-sm font-medium text-gray-500">Connect Vehicle with Smartcar Virtual Key</span>
              <span v-if="form.smartcar.prompt_tenant" class="text-sm font-light text-gray-500">Your host
                recommend you to connect your vehicle by Smartcar
                to access convenient services such as remote control and car
                trunk delivery. Click &quot;Connect Vehicle&quot;
                below</span>
              <div v-else class="flex space-x-2">
                <span class="text-sm font-light text-gray-500">This host does not ask for Smartcar permission or
                  this
                  service is not enabled. You can contact your host if you
                  have any questions.</span>
                <a-button @click="$openContactSlideCard(form)">
                  Contact Host
                </a-button>
              </div>
            </div>
          </div>
          <div v-if="form.smartcar.prompt_tenant" class="flex flex-col space-y-2">
            <span class="text-sm font-medium text-gray-500">Reason for requesting Smartcar virtual key:</span>
            <p class="rounded-lg border bg-gray-50 px-2 py-2">
              {{ $deepGet(form, "smartcar.prompt_reason", "N/A") }}
            </p>
          </div>
          <div v-if="form.smartcar.trunk_delivery" class="flex flex-col space-y-2 col-span-2">
            <span class="text-sm font-medium text-gray-500">Delivery Address of This Property</span>
            <span class="text-sm font-light">This host has grant you the permission to deliver your mail
              or package directly into your vehicle trunk, use the address
              below if you wish to use this service</span>
            <p class="rounded-lg border bg-gray-50 px-2 py-2">
              {{ $deepGet(form, "smartcar.delivery_address", "N/A") }}
            </p>
          </div>
          <div v-else class="flex flex-col space-y-2 col-span-2">
            <span class="text-sm font-medium text-gray-500">Vehicle Trunk Delivery</span>
            <div class="flex space-x-2">
              <span class="text-sm font-light text-gray-500">This host does not provide vehicle trunk delivery
                service
                or this service is not enabled. You can contact your host if
                you have any questions.</span>
              <a-button @click="$openContactSlideCard(form)">
                Contact Host
              </a-button>
            </div>
          </div>
        </template>
        <template>
          <div class="flex flex-col col-span-2">
            <span class="font-medium text-gray-500">Your host is requesting your vehicle information</span>
            <p class="text-sm font-light text-gray-500">
              Your vehicle information will be used for remote control,
              virtual keys and even car trunk delivery
            </p>
          </div>
          <vehicle-info class="col-span-2" title="Your vehicle infomation will autofill into right panel after connect"
            :model="form.vehicle" @added-vehicle="vehicle => updateReservationVehicle(vehicle)"
            @delete-vehicle="deleteReservationVehicle" />
          <div v-if="form.smartcar.prompt_tenant" class="flex flex-col col-span-2">
            <span class="text-sm font-medium text-gray-500">In case you have trouble connecting Smartcar...</span>
            <div class="flex space-x-4">
              <div class="flex justify-between items-center w-1/2">
                <span class="text-sm font-light text-gray-500">Smartcar is incompatible with my car</span>
                <a-switch v-model="form.smartcar.unavailable" />
              </div>
              <div class="flex flex-col space-y-2 w-1/2">
                <span class="text-sm font-light text-gray-500">Please provide host a reason below:</span>
                <a-textarea v-model="form.smartcar.unavailable_reason" :disabled="!form.smartcar.unavailable"
                  placeholder="For example, my 2020 Audi A6 cannot connect because it does not support fuel level monitoring in Smartcar"
                  :auto-size="{ minRows: 2, maxRows: 4 }" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col space-y-4 items-center">
    <span class="text-sm font-medium text-gray-700">
      Vehicle
    </span>
    <div class="flex flex-col w-full px-5 space-y-2">
      <span class="text-sm font-medium text-gray-500">Connect Vehicle with Smartcar Virtual Key</span>
      <span v-if="form.smartcar.prompt_tenant" class="text-sm font-light text-gray-500">Your host recommend
        you to connect your vehicle by Smartcar
        to access convenient services such as remote control and car
        trunk delivery. Click &quot;Connect Vehicle&quot;
        below</span>
      <template v-else>
        <span class="text-sm font-light text-gray-500">This host does not ask for Smartcar permission or
          this
          service is not enabled. You can contact your host if you
          have any questions.</span>
        <a-button @click="$openContactSlideCard(form)">
          Contact Host
        </a-button>
      </template>
      <template v-if="form.smartcar.prompt_tenant">
        <span class="text-sm font-medium text-gray-500">Reason for requesting Smartcar virtual key:</span>
        <p class="rounded-lg border bg-gray-50 px-2 py-2">
          {{ $deepGet(form, "smartcar.prompt_reason", "N/A") }}
        </p>
      </template>
      <template v-if="form.smartcar.trunk_delivery">
        <span class="text-sm font-medium text-gray-500">Delivery Address of This Property</span>
        <span class="text-sm font-light">This host has grant you the permission to deliver your mail
          or package directly into your vehicle trunk, use the address
          below if you wish to use this service</span>
        <p class="rounded-lg border bg-gray-50 px-2 py-2">
          {{ $deepGet(form, "smartcar.delivery_address", "N/A") }}
        </p>
      </template>
      <template v-else>
        <span class="text-sm font-medium text-gray-500">Vehicle Trunk Delivery</span>
        <span class="text-sm font-light text-gray-500">This host does not provide vehicle trunk delivery
          service
          or this service is not enabled. You can contact your host if
          you have any questions.</span>
        <a-button @click="$openContactSlideCard(form)">
          Contact Host
        </a-button>
      </template>
      <span class="font-medium text-gray-500">Your host is requesting your vehicle information</span>
      <p class="text-sm font-light text-gray-500">
        Your vehicle information will be used for remote control,
        virtual keys and even car trunk delivery
      </p>
      <vehicle-info title="Your vehicle infomation will autofill into right panel after connect"
        :model="form.vehicle" @added-vehicle="vehicle => updateReservationVehicle(vehicle)"
        @delete-vehicle="deleteReservationVehicle" />
      <div v-if="form.smartcar.prompt_tenant" class="flex flex-col col-span-2">
        <span class="text-sm font-medium text-gray-500">In case you have trouble connecting Smartcar...</span>
        <div class="flex justify-between items-center">
          <span class="text-sm font-light text-gray-500">Smartcar is incompatible with my car</span>
          <a-switch v-model="form.smartcar.unavailable" />
        </div>
        <div class="flex flex-col space-y-2">
          <span class="text-sm font-light text-gray-500">Please provide host a reason below:</span>
          <a-textarea v-model="form.smartcar.unavailable_reason" :disabled="!form.smartcar.unavailable"
            placeholder="For example, my 2020 Audi A6 cannot connect because it does not support fuel level monitoring in Smartcar"
            :auto-size="{ minRows: 2, maxRows: 4 }" />
        </div>
      </div>
    </div>
  </div>
</template>
