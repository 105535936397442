<script>
import { ref, onMounted } from "@vue/composition-api";
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "./carousel.css";

/* Intended for BOTH Stay and Coworking */
export default {
  name: "SpacesPropertyList",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  setup(props, { root }) {
    const { $nextTick } = root;
    const carouselSlides = ref(null);
    onMounted(() => {
      console.log("onMounted => " + carouselSlides.value[0].$el.clientWidth);
      // Force carousel slides to update width before initializing loop ends
      carouselSlides.value.map((el, i) => {
        el.$parent.update();
      });
      // $nextTick example
      $nextTick(() => {
        console.log("Dom loaded => " + carouselSlides.value[0].$el.clientWidth);
      });
    });
    return {
      carouselSlides,
    };
  },
};
</script>
<template>
  <div class="grid grid-cols-1 p-3">
    <div
      class="flex py-2 px-4 items-center py-4"
      style="height: 80px"
    >
      <router-link
        tag="button"
        :to="model.button.to"
        class="flex rounded-full border cursor-pointer border-parqay-primary hover:bg-parqay-primary h-full px-12 text-lg font-bold text-parqay-primary hover:text-white items-center justify-between"
      >
        <span class="text-center">{{ model.button.text }}</span>
        <svg-icon
          icon-class="arrow-circle-right"
          class="w-6 h-6"
        />
      </router-link>
    </div>
    <div
      v-for="(p, i) in model.properties"
      :key="`property-card-${i}`"
      class="relative flex px-4 py-2 cursor-pointer transition duration-200 ease-in-out transform hover:scale-105"
      style="height: 150px"
      @mouseenter="$emit('toggleMarker', i, true)"
      @mouseleave="$emit('toggleMarker', i, false)"
    >
      <div
        class="grid grid-cols-2 border shadow-sm rounded-lg overflow-hidden"
      >
        <hooper style="height: 132px">
          <slide
            v-for="(image, i) in p.images"
            ref="carouselSlides"
            :key="`${image}-${i}`"
          >
            <img
              :src="image"
              class="object-cover"
              style="height: 100%; width: 100%"
            >
          </slide>
          <hooper-navigation slot="hooper-addons" />
          <hooper-pagination slot="hooper-addons" />
        </hooper>

        <div class="border-l border-parqay-primary">
          <div class="flex flex-col p-1">
            <span
              class="px-2 py-1 text-parqay-primary text-center text-xs font-bold"
            >{{ p.title }}</span>
            <div class="m-2 rounded-md border-2 border-parqay-primary flex">
              <div
                class="w-1/2 border-r-2 border-parqay-primary text-parqay-primary border-dashed px-1 py-1 text-md font-bold text-center"
              >
                {{ p.date[0] }}
              </div>
              <div
                class="w-1/2 text-parqay-primary px-1 py-1 text-md font-bold text-center"
              >
                {{ p.date[1] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="absolute w-auto h-6 bg-white rounded-full px-2 border border-parqay-primary left-6 top-4"
      >
        <span class="text-parqay-primary text-center text-xs font-bold">{{
          p.price
        }}</span>
      </div>
      <div
        class="absolute w-auto bg-white rounded-lg px-6 py-2 bg-parqay-primary right-6 bottom-4 hover:bg-blue-500"
      >
        <span class="text-white text-center text-md font-medium">Reserve</span>
      </div>
    </div>
  </div>
</template>