<script>
import ParallexHouseIncomeDemoMobile from "src/views/landing-pages/components/ParallexHouseIncomeDemoMobile.vue";
import IconBulletList from "src/views/landing-pages/components/IconBulletList.vue";
import RealEstateHeroImage from "../assets/images/real-estate-hero.png";

export default {
    name: "landing-home-mobile",
    components: {
        ParallexHouseIncomeDemoMobile,
        IconBulletList
    },
    props: {
        controls: {
            type: Object,
            require: true
        },
        funcs: {
            type: Object,
            require: true
        }
    },
    setup(props, ctx) {
        return {
            RealEstateHeroImage,
            window
        }
    }
}
</script>
<template>
    <div class="px-2">
        <!-- Hero section -->

        <div class="relative bg-white overflow-hidden">
            <div class="flex flex-col items-center justify-center space-y-2">
                <img class="w-full object-cover h-64" :src="RealEstateHeroImage" />
                <div class="
            relative
            z-10
            pb-8
            bg-white
            sm:pb-16
            md:pb-20
            lg:max-w-2xl lg:w-1/2
            xl:w-2/3
            lg:pb-28
            xl:pb-32
          ">
                    <main class="
              mx-auto
              max-w-7xl
              px-4
              sm:mt-12 sm:px-6
              md:mt-16
              lg:mt-12 lg:px-8
              xl:mt-24
            ">
                        <div class="sm:text-center lg:text-left">
                            <h1 class="
                  text-center text-3xl
                  tracking-tight
                  font-bold
                  text-gray-900
                ">
                                <span class="block xl:inline">Build and scale your</span>
                                <span class="block text-blue-600 xl:inline">
                                    Leasing Business</span>
                            </h1>
                            <p class="
                  mt-3
                  text-base text-gray-500
                  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                  md:mt-5 md:text-xl
                  lg:mx-0
                ">
                                Companies use our platform & marketplace to manage, lease,
                                collect payments, verify tenants and more. Smartly automated so
                                you have time to run your business.
                            </p>
                            <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                <div class="rounded-md shadow">
                                    <a class="
                      w-full
                      flex
                      items-center
                      justify-center
                      px-8
                      py-3
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-white
                      bg-blue-600
                      hover:bg-blue-500
                      md:py-4 md:text-lg md:px-10
                    " @click="$router.push('/sign-up')">
                                        Get started
                                    </a>
                                </div>
                                <div class="mt-3 sm:mt-0 sm:ml-3">
                                    <a class="
                      w-full
                      flex
                      items-center
                      justify-center
                      px-8
                      py-3
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-blue-700
                      bg-blue-100
                      hover:bg-blue-200
                      md:py-4 md:text-lg md:px-10
                    " @click="$router.push({ path: 'pricing', hash: '#demo' })">
                                        Book a Demo
                                    </a>
                                </div>
                            </div>
                            <div class="
                  flex flex-col
                  justify-center
                  w-full
                  bg-white-800
                  pt-1
                  pb-2
                ">
                                <span class="mx-auto text-lg font-bold cursor-pointer py-5">
                                    Or, search for a
                                </span>
                                <div class="grid grid-cols-2 gap-4 rounded-full py-2 text-base"
                                    @click="funcs.navToMarketplace()">
                                    <span class="
                      cursor-pointer
                      transition
                      duration-200
                      ease-in-out
                      transform
                      hover:-translate-y-2 hover:scale-110
                      px-3
                      py-1
                      text-white text-sm
                      font-semibold
                      leading-5
                      uppercase
                      tracking-wide
                      bg-gradient-to-r
                      from-pink-500
                      to-yellow-200
                      rounded-full
                    ">
                                        Parking Space
                                    </span>
                                    <span class="
                      cursor-pointer
                      transition
                      duration-200
                      ease-in-out
                      transform
                      hover:-translate-y-2 hover:scale-110
                      mx-2
                      px-3
                      py-1
                      text-white text-sm
                      font-semibold
                      leading-5
                      uppercase
                      tracking-wide
                      bg-gradient-to-r
                      from-indigo-500
                      to-red-300
                      rounded-full
                    ">
                                        Stay
                                    </span>
                                    <span class="
                      cursor-pointer
                      transition
                      duration-200
                      ease-in-out
                      transform
                      hover:-translate-y-2 hover:scale-110
                      mx-2
                      px-3
                      py-1
                      text-white text-sm
                      font-semibold
                      leading-5
                      uppercase
                      tracking-wide
                      bg-gradient-to-r
                      from-pink-500
                      to-yellow-200
                      rounded-full
                    ">
                                        Office
                                    </span>
                                    <span class="
                      cursor-pointer
                      transition
                      duration-200
                      ease-in-out
                      transform
                      hover:-translate-y-2 hover:scale-110
                      mx-2
                      px-3
                      py-1
                      text-white text-sm
                      font-semibold
                      leading-5
                      uppercase
                      tracking-wide
                      bg-gradient-to-r
                      from-teal-500
                      to-blue-500
                      rounded-full
                    ">
                                        Car Rental
                                    </span>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>

        <!-- Popular Cities -->
        <div class="bg-white">
            <div class="max-w-7xl mx-auto py-2 md:py-16 px-4 sm:px-6 lg:px-8">
                <p class="
            text-center text-xl
            md:text-3xl
            font-bold
            md:font-semibold
            uppercase
            text-gray-500
            tracking-wide
          ">
                    Popular Cities
                </p>
                <div class="
            mt-6
            grid grid-cols-2
            gap-4
            md:gap-8 md:grid-cols-4
            lg:grid-cols-4
          ">
                    <div v-for="(city, i) in controls.popularCities" :key="`popular-city-${i}`" class="
              relative
              overflow-hidden
              h-32
              col-span-1
              flex
              justify-center
              items-center
              md:col-span-2
              lg:col-span-1
              rounded-lg
              md:rounded-xl
            " @click="() => window.open(city.url, '_blank')">
                        <img class="absolute inset-0 object-cover h-full w-full pointer-events-none" :src="city.image" />
                        <span class="text-xl font-bold text-white z-10 cursor-pointer pointer-events-none">{{
                        city.name
                        }}</span>
                        <div class="
                absolute
                inset-0
                cursor-pointer
                opacity-30
                hover:bg-gray-500
                pointer-events-none
              " />
                    </div>
                </div>
            </div>
        </div>

        <div id="features" class="relative bg-white py-16 sm:py-12 lg:py-10">
            <div class="
          mx-auto
          max-w-md
          px-4
          text-center
          sm:max-w-3xl sm:px-6
          lg:max-w-7xl lg:px-8
        ">
                <!-- <h2 class="text-lg font-semibold text-parqay-primary">All-In-One</h2> -->
                <p class="
            text-3xl
            font-bold
            tracking-tight
            text-gray-900
            sm:text-4xl
          ">
                    One Platform to Manage ALL Of Your Assets
                </p>
                <p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                    Leasing, Parking, Coworking,
                    Short-term rentals! We are your one stop shop!
                </p>
            </div>
        </div>

        <!-- House Income Demo Widget -->

        <div class="bg-white">
            <div class="mx-auto py-2 px-4">
                <div class="flex flex-col rounded-xl bg-white border shadow-sm py-4 px-2">
                    <div class="relative flex-auto bg-gray-50 shadow-sm rounded-xl" style="height: 300px">
                        <parallex-house-income-demo-mobile @arm-focus="funcs.handleArmFocus" />
                    </div>
                    <div class="flex flex-col pt-5 space-y-2">
                        <img class="w-auto object-contain px-4" src="/static/img/dashboard/dashboard-landing-ads.png"
                            alt="Workflow" />
                        <div class="">
                            <!-- If hover does not start -->
                            <div v-if="!controls.currentArm" class="flex flex-col justify-center space-y-2 px-4">
                                <icon-bullet-list v-slot:default="slotProps" class="pt-6 pb-4" :cols="1"
                                    icon-color="blue-400" :model="controls.incomeDemoContent.default"
                                    @update-arm="v => controls.currentArm = v">
                                    <div v-if="funcs.getTypesCount(slotProps.index) != 0"
                                        class="rounded-full bg-blue-500 px-4 py-1">
                                        <span class="text-center text-white text-xs">{{
                                        funcs.getTypesCount(slotProps.index)
                                        }} types
                                            included</span>
                                    </div>
                                </icon-bullet-list>
                            </div>
                            <!-- Parking Income Description -->
                            <div v-if="controls.currentArm == 'parking'"
                                class="flex flex-col justify-center space-y-2 px-4">
                                <h4 class="text-xl font-bold text-center text-blue-600">
                                    List Short & Long Term Parking
                                </h4>
                                <icon-bullet-list class="pt-6 pb-4" icon-color="blue-400"
                                    :model="controls.incomeDemoContent.parking" />
                                <button class="
                    flex
                    rounded-full
                    border
                    cursor-pointer
                    border-blue-600
                    bg-blue-600
                    hover:bg-white
                    py-2
                    px-12
                    text-lg
                    font-bold
                    hover:text-parqay-primary
                    text-white
                    items-center
                    justify-between
                  " @click="$loadUserData() ? $router.push('/listing/general-parking') : $router.push('/sign-up')">
                                    <span class="text-center">List A Space</span>
                                    <svg-icon icon-class="arrow-circle-right" class="w-6 h-6" />
                                </button>
                            </div>
                            <!-- Stay Income Description -->
                            <div v-if="controls.currentArm == 'house'"
                                class="flex flex-col justify-center space-y-2 px-4">
                                <h4 class="text-xl font-bold text-center text-blue-600">
                                    Short & Long Term Home Rentals
                                </h4>
                                <icon-bullet-list class="pt-6 pb-4" icon-color="blue-400"
                                    :model="controls.incomeDemoContent.house" />
                                <button class="
                    flex
                    rounded-full
                    border
                    cursor-pointer
                    border-blue-600
                    bg-blue-600
                    hover:bg-white
                    py-2
                    px-12
                    text-lg
                    font-bold
                    hover:text-parqay-primary
                    text-white
                    items-center
                    justify-between
                  ">
                                    <span class="text-center">List a Stay</span>
                                    <svg-icon icon-class="arrow-circle-right" class="w-6 h-6" />
                                </button>
                            </div>
                            <!-- Coworking Income Description -->
                            <div v-if="controls.currentArm == 'office'"
                                class="flex flex-col justify-center space-y-2 px-4">
                                <h4 class="text-xl font-bold text-center text-parqay-primary">
                                    List all of your available work and office spaces.
                                </h4>
                                <icon-bullet-list class="pt-6 pb-4" icon-color="blue-400"
                                    :model="controls.incomeDemoContent.office" />
                                <button class="
                    flex
                    rounded-full
                    border
                    cursor-pointer
                    border-blue-600
                    bg-blue-600
                    hover:bg-white
                    py-2
                    px-12
                    text-lg
                    font-bold
                    hover:text-parqay-primary
                    text-white
                    items-center
                    justify-between
                  " @click="$loadUserData() ? $router.push('/listing/stay-coworking#coworking') : $router.push('/sign-up')">
                                    <span class="text-center">List a Office</span>
                                    <svg-icon icon-class="arrow-circle-right" class="w-6 h-6" />
                                </button>
                            </div>
                            <!-- Car Rental Income Description -->
                            <div v-if="controls.currentArm == 'car'"
                                class="flex flex-col justify-center space-y-2 px-4">
                                <h4 class="text-xl font-bold text-center text-blue-600">
                                    Short & Longterm Car Rentals
                                </h4>
                                <span class="text-base text-gray-500 pb-4">
                                    Have an extra car that's not in use? List your car to be rented by
                                    a verified user.
                                </span>
                                <button class="
                    flex
                    rounded-full
                    border
                    cursor-pointer
                    border-blue-600
                    bg-blue-600
                    hover:bg-white
                    py-2
                    px-12
                    text-lg
                    font-bold
                    hover:text-parqay-primary
                    text-white
                    items-center
                    justify-between
                  " @click="$loadUserData() ? $router.push('/listing/car-rentals') : $router.push('/sign-up')">
                                    <span class="text-center">List a Car</span>
                                    <svg-icon icon-class="arrow-circle-right" class="w-6 h-6" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Features Section -->

        <div id="features" class="relative bg-white py-16 sm:py-24 lg:py-32">
            <div class="
          mx-auto
          max-w-md
          px-4
          text-center
          sm:max-w-3xl sm:px-6
          lg:max-w-7xl lg:px-8
        ">
                <!-- <h2 class="text-lg font-semibold text-parqay-primary">All-In-One</h2> -->
                <p class="
            mt-2
            text-3xl
            font-bold
            tracking-tight
            text-gray-900
            sm:text-4xl
          ">
                    Everything you need to Lease & Manage
                </p>
                <p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                    We provide all the necessary tools to make managing easy again
                </p>
                <div class="mt-12">
                    <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        <div class="pt-6">
                            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                <div class="-mt-6">
                                    <div>
                                        <span class="
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        bg-blue-500
                        p-3
                        shadow-lg
                      ">
                                            <!-- Heroicon name: outline/cloud-arrow-up -->
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <h3 class="
                      mt-6
                      text-lg
                      font-medium
                      tracking-tight
                      text-gray-900
                    ">
                                        24/7 Virtual Team
                                    </h3>
                                    <p class="mt-5 text-base text-gray-500">
                                        Never miss an opportunity because you were on another call or away from your
                                        desk. Let our sales
                                        team close for you!
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="pt-6">
                            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                <div class="-mt-6">
                                    <div>
                                        <span class="
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        bg-blue-500
                        p-3
                        shadow-lg
                      ">
                                            <!-- Heroicon name: outline/lock-closed -->
                                            <svg class="w-8 h-8 text-white" xmlns="http://www.w3.org/2000/svg"
                                                fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <h3 class="
                      mt-6
                      text-lg
                      font-medium
                      tracking-tight
                      text-gray-900
                    ">
                                        Marketplace
                                    </h3>
                                    <p class="mt-5 text-base text-gray-500">
                                        With our marketplace not only are we aiming to get more eyes on your listing,
                                        but fill it with
                                        verified tenants
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="pt-6">
                            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                <div class="-mt-6">
                                    <div>
                                        <span class="
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        bg-blue-500
                        p-3
                        shadow-lg
                      ">
                                            <!-- Heroicon name: outline/arrow-path -->
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <h3 class="
                      mt-6
                      text-lg
                      font-medium
                      tracking-tight
                      text-gray-900
                    ">
                                        SMS Messaging
                                    </h3>
                                    <p class="mt-5 text-base text-gray-500">
                                        Respond to opportunities faster and communicate directly with your tenants &
                                        prospective leads
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="pt-6">
                            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                <div class="-mt-6">
                                    <div>
                                        <span class="
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        bg-blue-500
                        p-3
                        shadow-lg
                      ">
                                            <!-- Heroicon name: outline/shield-check -->
                                            <svg class="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg"
                                                fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <h3 class="
                      mt-6
                      text-lg
                      font-medium
                      tracking-tight
                      text-gray-900
                    ">
                                        User Verification
                                    </h3>
                                    <p class="mt-5 text-base text-gray-500">
                                        Never worry about a background check, with Parqay you can now verify & validate
                                        your tenant in
                                        seconds!
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="pt-6">
                            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                <div class="-mt-6">
                                    <div>
                                        <span class="
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        bg-blue-500
                        p-3
                        shadow-lg
                      ">
                                            <!-- Heroicon name: outline/cog -->
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <h3 class="
                      mt-6
                      text-lg
                      font-medium
                      tracking-tight
                      text-gray-900
                    ">
                                        Service Management
                                    </h3>
                                    <p class="mt-5 text-base text-gray-500">
                                        Track and close all services like maintenance requests, towing requests and
                                        complaints from start
                                        to
                                        finish
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="pt-6">
                            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                <div class="-mt-6">
                                    <div>
                                        <span class="
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        bg-blue-500
                        p-3
                        shadow-lg
                      ">
                                            <!-- Heroicon name: outline/server -->
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <h3 class="
                      mt-6
                      text-lg
                      font-medium
                      tracking-tight
                      text-gray-900
                    ">
                                        Automation
                                    </h3>
                                    <p class="mt-5 text-base text-gray-500">
                                        Automate services like sales, payments, towing, maintenance
                                        requests and more!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Alternating Feature Sections -->
        <div class="relative pt-16 pb-32 overflow-hidden">
            <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48 bg-white" />
            <div class="relative">
                <div class="
            lg:mx-auto
            lg:max-w-7xl
            lg:px-8
            lg:grid
            lg:grid-cols-2
            lg:grid-flow-col-dense
            lg:gap-24
          ">
                    <div class="
              px-4
              max-w-xl
              mx-auto
              sm:px-6
              lg:py-16 lg:max-w-none lg:mx-0 lg:px-0
            ">
                        <div>
                            <div>
                                <span class="
                    h-12
                    w-12
                    rounded-md
                    flex
                    items-center
                    justify-center
                    bg-blue-500
                  ">
                                    <!-- Heroicon name: outline/inbox -->
                                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                                    </svg>
                                </span>
                            </div>
                            <div class="mt-6">
                                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                                    Stay on top of your listings
                                </h2>
                                <p class="mt-4 text-lg text-gray-500">
                                    Easily manage all of your properties and your offerings at the
                                    click of a button. With our built-in sales automation, we
                                    gurantee you'll never miss a close.
                                </p>
                                <div class="mt-6">
                                    <a class="
                      inline-flex
                      px-4
                      py-2
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      shadow-sm
                      text-white
                      bg-blue-500
                    " @click="$router.push('/sign-up')">
                                        Get started
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-8 border-t border-gray-200 pt-6">
                            <blockquote>
                                <div>
                                    <p class="text-base text-gray-500">
                                        &ldquo;Parqay has made leasing easy and simple. With their built
                                        in sales team, I no longer have to worry about missing a
                                        sale.&rdquo;
                                    </p>
                                </div>
                                <footer class="mt-3">
                                    <div class="flex items-center space-x-3">
                                        <div class="flex-shrink-0">
                                            <img class="h-6 w-6 rounded-full"
                                                src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                                alt="" />
                                        </div>
                                        <div class="text-base font-medium text-gray-700">
                                            Marcia Hill, Boston Property Manager
                                        </div>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                    <div class="mt-12 sm:mt-16 lg:mt-0">
                        <div class="
                pl-4
                -mr-48
                sm:pl-6
                md:-mr-16
                lg:px-0 lg:m-0 lg:relative lg:h-full
              ">
                            <img class="
                  w-full
                  rounded-xl
                  shadow-xl
                  ring-1 ring-black ring-opacity-5
                  lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none
                " src="static/img/dashboard/landing-hero-listing.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-24">
                <div class="
            lg:mx-auto
            lg:max-w-7xl
            lg:px-8
            lg:grid
            lg:grid-cols-2
            lg:grid-flow-col-dense
            lg:gap-24
          ">
                    <div class="
              px-4
              max-w-xl
              mx-auto
              sm:px-6
              lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2
            ">
                        <div>
                            <div>
                                <span class="
                    h-12
                    w-12
                    rounded-md
                    flex
                    items-center
                    justify-center
                    bg-blue-500
                  ">
                                    <!-- Heroicon name: outline/sparkles -->
                                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                                    </svg>
                                </span>
                            </div>
                            <div class="mt-6">
                                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                                    Better understand your Tenants and Business
                                </h2>
                                <p class="mt-4 text-lg text-gray-500">
                                    With our built in reservation calendar you can now keep track
                                    of your best days and your slow days. Accept requests from new
                                    tenants and see where you can place them!
                                </p>
                                <div class="mt-6">
                                    <a class="
                      inline-flex
                      px-4
                      py-2
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      shadow-sm
                      text-white
                      bg-blue-500
                    " @click="$router.push('/sign-up')">
                                        Get started
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div class="
                pr-4
                -ml-48
                sm:pr-6
                md:-ml-16
                lg:px-0 lg:m-0 lg:relative lg:h-full
              ">
                            <lazy-img class="
                  w-full
                  rounded-xl
                  shadow-xl
                  ring-1 ring-black ring-opacity-5
                  lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none
                " src="static/img/dashboard/support.png" alt="Customer profile user interface" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- CTA Section -->
        <div class="bg-white">
            <div class="
          max-w-4xl
          mx-auto
          py-16
          px-4
          sm:px-6 sm:py-24
          lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between
        ">
                <h2 class="
            text-4xl
            font-extrabold
            tracking-tight
            text-gray-900
            sm:text-4xl
          ">
                    <span class="block">Ready to get started?</span>
                    <span class="block text-blue-600">Get in touch or create an account.</span>
                </h2>
                <div class="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                    <a class="
              flex
              items-center
              justify-center
              px-4
              py-3
              border border-transparent
              text-base
              font-medium
              rounded-md
              shadow-sm
              text-white
              bg-blue-600
            " @click="$router.push({ path: 'pricing', hash: '#demo' })">
                        Book a Demo
                    </a>
                    <a class="
              flex
              items-center
              justify-center
              px-4
              py-3
              border border-transparent
              text-base
              font-medium
              rounded-md
              shadow-sm
              text-blue-800
              bg-blue-50
              hover:bg-blue-100
            " @click="$router.push('/sign-up')">
                        Get started
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>