<script>
import { ref, reactive } from "@vue/composition-api";
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "SpaceAddButton",
  setup(props, ctx) {

    const nameInput = ref(null);

    const model = reactive({
      spaceName: null,
      count: 1,
    });

    const handleAdd = () => {
      model.count++;
    };
    const handleMinus = () => {
      if (model.count > 1) {
        model.count--;
      }
    };

    const handleConfirm = () => {
      if (!isEmpty(model.spaceName)) {
        ctx.emit("add", cloneDeep(model));
        model.spaceName = null;
        model.count = 1;
      } else {
        if (nameInput.value) {
          nameInput.value.focus();
        }
      }
    };

    return {
      nameInput,
      model,
      handleAdd,
      handleMinus,
      handleConfirm,
    };
  },
};
</script>
<template>
  <div>
    <p class="text-xs text-gray-600 font-medium text-center h-10 leading-10">
      Enter Space Name
    </p>
    <div class="space-add-button rounded border shadow-sm bg-white flex text-parqay-primary">
      <div class="flex flex-col justify-center border-r w-3/4">
        <div class="border-b text-center px-3 py-2">
          <!-- <p class="mb-1 text-sm font-medium">Enter Space Name</p> -->
          <a-input ref="nameInput" v-model="model.spaceName" placeholder="e.g. EV Spaces" allow-clear />
        </div>
        <div class="flex items-center justify-center space-x-4 py-1">
          <button class="
          w-6
          h-6
          border border-parqay-primary
          hover:text-white hover:bg-parqay-primary
          rounded
          flex
          justify-center
          items-center
          focus:outline-none
        " @click="handleMinus">
            <svg-icon icon-class="minus" class="w-5 h-5" />
          </button>
          <span class="mx-4 text-2xl text-gray-500 font-semibold">{{ model.count }}</span>
          <button class="
          w-6
          h-6
          flex
          border border-parqay-primary
          hover:text-white hover:bg-parqay-primary
          rounded
          text-xl
          font-medium
          justify-center
          items-center
          focus:outline-none
        " @click="handleAdd">
            <svg-icon icon-class="plus" class="w-5 h-5" />
          </button>
        </div>
      </div>
      <div class="
          flex flex-col
          justify-center
          items-center
          hover:bg-parqay-primary hover:text-white
          cursor-pointer
          py-3
          px-1
          w-1/4
        " @click="handleConfirm">
        <span class="text-3xl">+</span>
        <span class="text-xs text-center">Add Space</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.space-add-button ::v-deep {
  .ant-input {
    font-size: 12px;
  }
}
</style>