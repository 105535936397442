<script>
import { ref, reactive, onMounted, nextTick } from "@vue/composition-api";
import PhoneNumberManageCard from "src/views/auth/components/Step3";
import EmailManageCard from "src/views/auth/components/Step2";
import NewPaymentMethodCard from "src/views/components/NewPaymentMethodCard";
import ChangePasswordModalView from "src/views/components/ChangePasswordModalView.vue"

import startCase from "lodash/startCase";
import { API } from "src/views/settings/api";
import { API as CommonAPI } from "src/views/components/api";

export default {
  components: {
    PhoneNumberManageCard,
    EmailManageCard,
    NewPaymentMethodCard,
    ChangePasswordModalView
  },
  setup(props, ctx) {
    const controls = reactive({
      loading: {
        accountDetails: false,
        paymentMethods: false,
        uploadLogo: false,
        uploadCover: false,
        saveWhiteLabel: false
      },
      editKeys: {
        basic: [],
        whiteLabel: []
      }, // Track edited account keys
      response: {
        whiteLabel: null
      },
      logoImages: [],
      coverImages: [],
      selectedPaymentMethod: null,
      key: {
        hostInfo: 0,
        phoneNumber: 0,
        updatePassword: 0,
        paymentMethods: 0
      }
    })
    const visible = reactive({
      changePhoneNumber: false,
      changeEmail: false,
      changePassword: false,
      accountUpdate: false
    });
    const changeVisible = ref(false);
    const tab = reactive({
      active: "Account Info",
      items: ["Account Info", "Vehicle", "Document", "Payment Methods"]
    });
    const account = reactive({
      first_name: null,
      last_name: null,
      phone_number: null,
      email: null,
      address: null
    });
    const paymentMethods = ref([]);
    const handleChange = k => {
      visible[k] = !visible[k];
    };

    const getAccountDetails = async () => {
      controls.loading.accountDetails = true;
      try {
        const { data } = await API.getAccountDetails();
        console.log("getAccountDetails", data);
        account.first_name = data.first_name;
        account.last_name = data.last_name;
        account.phone_number = data.phone_number;
        account.email = data.email;
        account.address = data.address;
      } catch (error) {
        console.log("getAccountDetails -> error: ", error);
      } finally {
        setTimeout(() => {
          controls.loading.accountDetails = false;
        }, 1500);
      }
    };

    const updateAccountDetails = async () => {
      let payload = {};
      controls.editKeys.basic.forEach(k => {
        const editValue = ctx.root.$deepGet(account, k);
        payload[k] = editValue
      })
      controls.loading.accountDetails = true;
      try {
        const { data } = await API.updateAccountDetails(payload);
        account.first_name = data.first_name;
        account.last_name = data.last_name;
        account.phone_number = data.phone_number;
        account.email = data.email;
        account.address = data.address;
      } catch (error) {
        ctx.root.$openNotification(
          "Update Account Info",
          error.message,
          10,
          "info"
        );
      } finally {
        controls.editKeys.basic = [];
        setTimeout(() => {
          controls.loading.accountDetails = false;
        }, 1500);
      }
    }

    const updateEditKeys = (editKey, section = "basic") => {
      if (!controls.editKeys[section].includes(editKey)) {
        controls.editKeys[section].push(editKey);
      }
    }

    const listAllPaymentMethods = async () => {
      controls.loading.paymentMethods = true;
      controls.key.paymentMethods += 1;
      try {
        const { data } = await API.listAllPaymentMethods();
        paymentMethods.value = data;
      } catch (error) {
        console.log("listAllPaymentMethods -> error: ", error);
      } finally {
        controls.selectedPaymentMethod = null;
        setTimeout(() => {
          controls.loading.paymentMethods = false;
        }, 500);
      }
    };


    const updateDefaultPaymentMethod = async paymentMethod => {
      controls.loading.paymentMethods = true;
      paymentMethods.value.forEach(p => {
        if (p.id === paymentMethod.id) {
          p.is_default = true;
        } else {
          p.is_default = false;
        }
      });
      try {
        await CommonAPI.updateDefaultPaymentMethod(paymentMethod.id);
      } catch (error) {
        console.log("updateDefaultPaymentMethod -> error: ", error);
        return;
      } finally {
        listAllPaymentMethods();
      }
    };

    const deletePaymentMethod = async paymentMethod => {
      controls.loading.paymentMethods = true;
      paymentMethods.value.forEach((v, i) => {
        if (v.id === paymentMethod.id) {
          paymentMethods.value.splice(i, 1);
        }
      });
      try {
        await CommonAPI.deletePaymentMethod(paymentMethod.id);
      } catch (error) {
        console.log("deletePaymentMethod -> error: ", error);
        return;
      } finally {
        listAllPaymentMethods();
      }
    };

    onMounted(() => {
      getAccountDetails();
      listAllPaymentMethods();
    });

    return {
      controls,
      visible,
      account,
      paymentMethods,
      changeVisible,
      tab,
      listAllPaymentMethods,
      updateDefaultPaymentMethod,
      deletePaymentMethod,
      handleChange,
      getAccountDetails,
      updateAccountDetails,
      updateEditKeys,
      startCase
    };
  }
};
</script>
<template>
  <div v-if="$isDesktopView()" class="px-10 py-6">
    <div class="flex justify-between py-2 border-b mb-6">
      <h3 class="font-medium text-2xl text-dark-purple my-auto">
        Account Settings
      </h3>
    </div>
    <div :key="`settings-host-info-${controls.key.hostInfo}`"
      class="flex flex-col bg-white rounded-lg shadow-sm py-4 border">
      <div class="flex items-center space-x-2 border-b pb-4 pl-4">
        <span class="font-medium text-base text-black">
          Tenant Information
        </span>
        <a-icon type="reload" :spin="controls.loading.accountDetails" class="cursor-pointer" style="color: blue;"
          @click="getAccountDetails()" />
      </div>
      <div class="py-6 grid gap-x-8 gap-y-4 grid-cols-2 px-6">
        <label-item label="First Name">
          <a-input v-model="account.first_name" placeholder="First Name" size="large"
            @change="updateEditKeys('first_name')">
            <svg-icon slot="prefix" icon-class="user-solid" class="h-4 w-4 text-gray-400" />
          </a-input>
        </label-item>
        <label-item label="Last Name">
          <a-input v-model="account.last_name" placeholder="Last Name" size="large"
            @change="updateEditKeys('last_name')">
            <svg-icon slot="prefix" icon-class="user-solid" class="h-4 w-4 text-gray-400" />
          </a-input>
        </label-item>
        <label-item label="Email">
          <a-input v-model="account.email" placeholder="Email" size="large" @change="updateEditKeys('email')" @click="e => {
    handleChange('changeEmail');
    e.target.blur();
  }
    ">
            <svg-icon slot="prefix" icon-class="mail" class="h-4 w-4 text-gray-400" />
            <a-icon slot="suffix" type="edit" style="color: blue;" @click="handleChange('changeEmail')" />
          </a-input>
        </label-item>
        <label-item label="Phone Number">
          <a-input v-model="account.phone_number" placeholder="Phone Number" size="large"
            @change="updateEditKeys('phone_number')" @click="e => {
    handleChange('changePhoneNumber');
    e.target.blur();
  }
    ">
            <svg-icon slot="prefix" icon-class="phone" class="h-4 w-4 text-gray-400" />
            <a-icon slot="suffix" type="edit" style="color: blue;" @click="handleChange('changePhoneNumber')" />
          </a-input>
        </label-item>
        <label-item label="Address">
          <a-input v-model="account.address" placeholder="Address" size="large">
            <svg-icon slot="prefix" icon-class="home" class="h-4 w-4 text-gray-400" />
          </a-input>
        </label-item>
      </div>
      <div class="bg-white flex justify-between py-4 px-6">
        <a-button @click="handleChange('changePassword')">
          Change Password
        </a-button>
        <a-button type="primary" :loading="controls.loading.accountDetails" :disabled="controls.loading.accountDetails"
          @click="updateAccountDetails(account)">
          {{ controls.loading.accountDetails ? "" : "Save" }}
        </a-button>
      </div>
    </div>

    <div class="bg-white rounded-lg py-4 mt-6 border shadow-sm">
      <div class="flex items-center space-x-2 border-b pb-4 pl-4 mb-0">
        <span class="font-medium text-base text-black">
          Payment
        </span>
        <a-icon type="reload" :spin="controls.loading.paymentMethods" class="cursor-pointer" style="color: blue;"
          @click="listAllPaymentMethods()" />
      </div>
      <div class="flex justify-between rounded border-b">
        <div class="payment-methods w-1/2 rounded shadow-sm bg-white px-4 pb-4">
          <a-divider>Saved Payment Methods</a-divider>
          <div v-if="paymentMethods && paymentMethods.length > 0 && !controls.loading.paymentMethods"
            class="flex flex-col space-y-4">
            <div v-for="(v, i) in paymentMethods" :key="`payment-method-${i}`" class="flex flex-col">
              <div class="rounded bg-parqay-primary hover:bg-blue-500 cursor-pointer py-4 px-4 flex justify-between"
                @click="() => (controls.selectedPaymentMethod = v)">
                <div class="flex items-center">
                  <span class="text-sm text-white font-semibold">{{ startCase(v.brand) }} {{ v.last4 }} - {{ v.name
                    }}</span>
                </div>
                <div class="flex items-center space-x-2">
                  <a-tag v-if="v.is_default" color="geekblue">
                    Default
                  </a-tag>
                  <a-tag color="orange" class="payment-method-tag">{{ startCase(v.funding) }}</a-tag>
                </div>
              </div>
              <div v-if="$deepGet(controls.selectedPaymentMethod, 'id') == v.id
    " class="flex justify-between pt-4 pb-2">
                <div v-if="v.is_default">
                  <p class="bg-blue-100 text-blue-600 text-xs px-4 py-2">Could not edit default payment method, try
                    switching default payment method before
                    editing</p>
                </div>
                <template v-else>
                  <div>
                    <a-button type="danger" @click="deletePaymentMethod(v)">
                      Delete
                    </a-button>
                  </div>
                  <div class="flex space-x-4 items-center text-parqay-primary">
                    <span class="text-sm">Make Default</span>
                    <a-switch class="text-white border border-white" @change="checked => {
    updateDefaultPaymentMethod(v);
  }
    " />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-else class="px-4 py-2">
            <p v-if="controls.loading.paymentMethods" class="bg-blue-100 text-blue-600 text-center space-y-2 py-8">
              <a-icon type="loading" />
            </p>
            <p v-else class="bg-blue-100 text-blue-600 text-center space-y-2 py-8">
              <span class="block text-sm font-medium">No payment method found</span>
              <span class="block text-xs">Use "+ Add Payment Method" on the right to create new payment method</span>
            </p>
          </div>
        </div>
        <new-payment-method-card :key="controls.key.paymentMethods" class="w-1/2 px-7 pb-7 pt-6"
          @refresh-list="listAllPaymentMethods()" />
      </div>
    </div>

    <a-modal v-model="visible.changePhoneNumber" :footer="false" class="bg-gray-400">
      <phone-number-manage-card :key="controls.key.phoneNumber" class="px-6 py-6" modal-mode @verified="({ phone_number }) => {
    account.phone_number = phone_number;
    updateEditKeys('phone_number');
    visible.changePhoneNumber = false;
    controls.key.phoneNumber += 1;
  }" />
    </a-modal>

    <a-modal v-model="visible.changeEmail" :footer="false" class="bg-gray-400">
      <email-manage-card class="px-6 py-6" modal-mode />
    </a-modal>

    <a-modal :key="controls.key.updatePassword" v-model="visible.changePassword" :footer="false" class="bg-gray-400">
      <change-password-modal-view @dismiss="() => {
    controls.key.updatePassword += 1;
    visible.changePassword = false;
  }" />
    </a-modal>

    <a-modal v-model="visible.accountUpdate" :footer="false">
      <div class="flex justify-center">
        <svg-icon icon-class="success" />
      </div>
      <h2 class="text-black text-3xl mt-4 text-center">
        Success
      </h2>
      <p class="w-full text-xl text-gray-800 text-center">
        Your Account Information has been updated!
      </p>
      <div class="flex justify-center">
        <button class="
                            bg-blue-600
                            text-xl
                            hover:bg-blue-500
                            text-white
                            font-bold
                            py-2
                            px-6
                            rounded
                          " @click="handleChange('accountUpdate')">
          OK
        </button>
      </div>
    </a-modal>
  </div>
  <div v-else class="absolute inset-0">
    <div class="flex flex-col h-screen bg-gray-200">
      <div class="flex-shrink-0 flex flex-col space-y-4 bg-white">
        <div class="relative flex justify-center items-center px-10 py-3 border-b">
          <div class="flex items-center space-x-2 text-black font-medium text-lg">
            <a-icon type="sliders" theme="twoTone" />
            <span>Settings</span>
          </div>
          <!-- <a-button type="link" :icon="controls.loading ? 'loading' : 'reload'" class="inset-y-0 pb-1"
            :style="{ position: 'absolute', right: '16px' }" @click="getDocuments()"></a-button> -->
        </div>
      </div>
      <div class="overflow-y-auto h-full">
        <div class="flex flex-col px-6 pt-6 pb-12 space-y-4">

          <div :key="`settings-host-info-${controls.key.hostInfo}`"
            class="flex flex-col bg-white rounded-lg shadow-sm py-4 border">
            <div class="flex items-center space-x-2 border-b px-5 pb-4">
              <span class="font-medium text-base text-black">
                Tenant Information
              </span>
              <a-icon type="reload" :spin="controls.loading.accountDetails" class="cursor-pointer" style="color: blue;"
                @click="getAccountDetails()" />
            </div>
            <div class="flex flex-col space-y-4 px-5 py-4">
              <label-item label="First Name">
                <a-input v-model="account.first_name" placeholder="First Name" size="large"
                  @change="updateEditKeys('first_name')">
                  <svg-icon slot="prefix" icon-class="user-solid" class="h-4 w-4 text-gray-400" />
                </a-input>
              </label-item>
              <label-item label="Last Name">
                <a-input v-model="account.last_name" placeholder="Last Name" size="large"
                  @change="updateEditKeys('last_name')">
                  <svg-icon slot="prefix" icon-class="user-solid" class="h-4 w-4 text-gray-400" />
                </a-input>
              </label-item>
              <label-item label="Email">
                <a-input v-model="account.email" placeholder="Email" size="large" @change="updateEditKeys('email')"
                  @click="e => {
    handleChange('changeEmail');
    e.target.blur();
  }
    ">
                  <svg-icon slot="prefix" icon-class="mail" class="h-4 w-4 text-gray-400" />
                  <a-icon slot="suffix" type="edit" style="color: blue;" @click="handleChange('changeEmail')" />
                </a-input>
              </label-item>
              <label-item label="Phone Number">
                <a-input v-model="account.phone_number" placeholder="Phone Number" size="large"
                  @change="updateEditKeys('phone_number')" @click="e => {
    handleChange('changePhoneNumber');
    e.target.blur();
  }
    ">
                  <svg-icon slot="prefix" icon-class="phone" class="h-4 w-4 text-gray-400" />
                  <a-icon slot="suffix" type="edit" style="color: blue;" @click="handleChange('changePhoneNumber')" />
                </a-input>
              </label-item>
              <label-item label="Address">
                <a-input v-model="account.address" placeholder="Address" size="large">
                  <svg-icon slot="prefix" icon-class="home" class="h-4 w-4 text-gray-400" />
                </a-input>
              </label-item>
            </div>
            <div class="bg-white flex justify-between py-4 px-6">
              <a-button @click="handleChange('changePassword')">
                Change Password
              </a-button>
              <a-button type="primary" :loading="controls.loading.accountDetails"
                :disabled="controls.loading.accountDetails" @click="updateAccountDetails(account)">
                {{ controls.loading.accountDetails ? "" : "Save" }}
              </a-button>
            </div>
          </div>

          <div class="bg-white rounded-lg pt-4 border shadow-sm">
            <div class="flex items-center space-x-2 border-b px-5 pb-4 mb-0">
              <span class="font-medium text-base text-black">
                Payment Method
              </span>
              <a-icon type="reload" :spin="controls.loading.paymentMethods" class="cursor-pointer" style="color: blue;"
                @click="listAllPaymentMethods()" />
            </div>
            <div class="flex flex-col">
              <div class="payment-methods rounded shadow-sm bg-white px-4 pb-4">
                <a-divider>Saved Payment Methods</a-divider>
                <div v-if="paymentMethods && paymentMethods.length > 0 && !controls.loading.paymentMethods"
                  class="flex flex-col space-y-4">
                  <div v-for="(v, i) in paymentMethods" :key="`payment-method-${i}`" class="flex flex-col">
                    <div
                      class="rounded bg-parqay-primary hover:bg-blue-500 cursor-pointer py-4 px-4 flex justify-between"
                      @click="() => (controls.selectedPaymentMethod = v)">
                      <div class="flex items-center">
                        <span class="text-sm text-white font-semibold">{{ startCase(v.brand) }} {{ v.last4 }} - {{
    v.name
  }}</span>
                      </div>
                      <div class="flex items-center space-x-2">
                        <a-tag v-if="v.is_default" color="geekblue">
                          Default
                        </a-tag>
                        <a-tag color="orange" class="payment-method-tag">{{ startCase(v.funding) }}</a-tag>
                      </div>
                    </div>
                    <div v-if="$deepGet(controls.selectedPaymentMethod, 'id') == v.id
    " class="flex justify-between pt-4 pb-2">
                      <div v-if="v.is_default">
                        <p class="bg-blue-100 text-blue-600 text-xs px-4 py-2">Could not edit default payment method,
                          try
                          switching default payment method before
                          editing</p>
                      </div>
                      <template v-else>
                        <div>
                          <a-button type="danger" @click="deletePaymentMethod(v)">
                            Delete
                          </a-button>
                        </div>
                        <div class="flex space-x-4 items-center text-parqay-primary">
                          <span class="text-sm">Make Default</span>
                          <a-switch class="text-white border border-white" @change="checked => {
    updateDefaultPaymentMethod(v);
  }
    " />
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div v-else class="px-4 py-2">
                  <p v-if="controls.loading.paymentMethods"
                    class="bg-blue-100 text-blue-600 text-center space-y-2 py-8">
                    <a-icon type="loading" />
                  </p>
                  <p v-else class="bg-blue-100 text-blue-600 text-center space-y-2 py-8">
                    <span class="block text-sm font-medium">No payment method found</span>
                    <span class="block text-xs">Use "+ Add Payment Method" on the right to create new payment
                      method</span>
                  </p>
                </div>
              </div>
              <new-payment-method-card :key="controls.key.paymentMethods" class="px-5 pt-4"
                @refresh-list="listAllPaymentMethods()" />
            </div>
          </div>

          <div class="h-12" />

        </div>
      </div>
    </div>

    <a-modal v-model="visible.changePhoneNumber" :footer="false" class="bg-gray-400">
      <phone-number-manage-card :key="controls.key.phoneNumber" modal-mode @verified="({ phone_number }) => {
    account.phone_number = phone_number;
    updateEditKeys('phone_number');
    visible.changePhoneNumber = false;
    controls.key.phoneNumber += 1;
  }" />
    </a-modal>

    <a-modal v-model="visible.changeEmail" :footer="false" class="bg-gray-400">
      <email-manage-card modal-mode />
    </a-modal>

    <a-modal :key="controls.key.updatePassword" v-model="visible.changePassword" :footer="false" class="bg-gray-400">
      <change-password-modal-view @dismiss="() => {
    controls.key.updatePassword += 1;
    visible.changePassword = false;
  }" />
    </a-modal>

    <a-modal v-model="visible.accountUpdate" :footer="false">
      <div class="flex justify-center">
        <svg-icon icon-class="success" />
      </div>
      <h2 class="text-black text-3xl mt-4 text-center">
        Success
      </h2>
      <p class="w-full text-xl text-gray-800 text-center">
        Your Account Information has been updated!
      </p>
      <div class="flex justify-center">
        <button class="
                            bg-blue-600
                            text-xl
                            hover:bg-blue-500
                            text-white
                            font-bold
                            py-2
                            px-6
                            rounded
                          " @click="handleChange('accountUpdate')">
          OK
        </button>
      </div>
    </a-modal>
  </div>
</template>
<style lang="scss" scoped>
.payment-methods::v-deep {
  .ant-divider-inner-text {
    @apply text-sm text-black;
  }

  .payment-method-tag {
    @apply mx-0;
  }
}
</style>