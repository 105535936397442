import request from "src/services/api/request";

export const API = {
  balanceSummary() {
    return request.get("payments/balance-summary/host");
  },
  listTransactions({ role }) {
    return request.get("payments/transactions/" + role);
  },
  getInvoiceLink({ source }) {
    return request.get("payments/get-invoice-link/" + source);
  },
  getReservation({ source }) {
    return request.get("payments/get-reservation/" + source);
  },
  initRefund({ source }) {
    return request.post("payments/refund/" + source);
  },
  getPendingPayouts({ filter = {} }) {
    return request.get("payments/payouts", { filter });
  },
  initPayout(payout, paidType) {
    let payload = {};
    if (payout.$amountUpdate) {
      payload['amount'] = payout.$amountUpdate;
    }
    if (paidType) {
      payload['status'] = payout.status;
      payload['amount'] = payout.amount;
      payload['notes'] = payout.notes;
      payload['payout_id'] = payout.payout_id;
    }
    if (payout.status === "automatic") {
      payload['status'] = "automatic";
      payload['amount'] = payout.amount;
    }
    return request.post(`payments/payouts/${payout.id}/init`, payload);
  }
};