<script>
import { onMounted, reactive, ref } from "@vue/composition-api";
import { API } from "../api";

export default {
    setup(props, ctx) {
        const controls = reactive({
            loading: false
        });
        const table = reactive({
            headers: [
                {
                    title: "Date",
                    align: "center",
                    // width: 150,
                    dataIndex: "created",
                    sorter: (a, b) => {
                        const result = a.dt.localeCompare(b.dt);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "date" },
                },
                {
                    title: "Description",
                    align: "center",
                    dataIndex: "description",
                    sorter: (a, b) => {
                        const result = a.reservation.title.localeCompare(b.reservation.title);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "description" },
                },
                {
                    title: "Status",
                    align: "center",
                    dataIndex: "status",
                    sorter: (a, b) => {
                        const result = a.status.localeCompare(b.status);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "status" },
                },
                {
                    title: "Type",
                    align: "center",
                    dataIndex: "type",
                    sorter: (a, b) => {
                        const result = a.type.localeCompare(b.type);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "type" },
                },
                {
                    title: "Amount",
                    align: "center",
                    dataIndex: "final_amount",
                    sorter: (a, b) => {
                        const result = a.final_amount.localeCompare(b.final_amount);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "amount" },
                },
                {
                    title: "Actions",
                    align: "center",
                    scopedSlots: { customRender: "actions" },
                }
            ],
            data: [],
        });

        const fetchData = async () => {
            controls.loading = true;
            const { data } = await API.listTransactions({ role: "host" });
            table.data = data;
            controls.loading = false;
        }

        const popupWindow = (url, win = window, w = 800, h = 600) => {
            const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
            const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
            return win.open(url, "Parqay Tenant Invoice", `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
        }

        const getInvoiceLink = async (payload) => {
            ctx.root.$set(payload, "$loading.invoice", true);
            const { data } = await API.getInvoiceLink({ source: payload.source.id });
            const { link } = data;
            popupWindow(link).focus();
            ctx.root.$set(payload, "$loading.invoice", false);
        }

        const getReservation = async (payload) => {
            ctx.root.$set(payload, "$loading.reservation", true);
            const { data } = await API.getReservation({ source: payload.source.id });
            console.log("getReservation: ", data);
            const { reservation_id } = data;
            ctx.root.$set(payload, "$loading.reservation", false);
            if (reservation_id) {
                ctx.root.$router.push("/dashboard/reservations/" + reservation_id);
            } else {
                ctx.root.$openNotification(
                    "Missing Reservation",
                    "Cannot find associated reservation of this transaction, please contact our customer service if you have questions",
                    10,
                    "info"
                );
            }
        }

        const initRefund = async (payload) => {
            ctx.root.$set(payload, "$loading.refund", true);
            const { data } = await API.initRefund({ source: payload.source.id });
            console.log("initRefund: ", data);
            const { status } = data;
            ctx.root.$set(payload, "$loading.refund", false);
            if (status === "succeeded") {
                ctx.root.$openNotification(
                    "Refund Initiated",
                    "Please note refund normally hit tenant account in a few business days",
                    10,
                    "success"
                );
                await fetchData();
            } else {
                ctx.root.$openNotification(
                    "Refund Error",
                    "Cannot refund this transaction, if you have further question, please contact our customer support",
                    10,
                    "info"
                );
            }
        }

        const getTagColor = (status) => {
            let color;
            switch (status) {
                case "available":
                    color = "geekblue"
                    break;
                case "paid":
                    color = "green"
                    break;
                case "withdrawn":
                    color = "gray"
                    break;
                case "pending":
                    color = "orange"
                    break;
                default:
                    color = "geekblue"
                    break;
            }
            return color
        }

        const getNestedTableItems = (record) => {
            const refunded = record.source.refunded;
            if (refunded === true && !["adjustment", "payment_refund"].includes(record.type)) {
                const nestedItems = table.data.filter(v => (v.type === "adjustment" && record.source.id === v.source.id) || (v.type === "payment_refund" && record.source.id === v.source.charge));
                console.log("getNestedTableItems -> ", nestedItems)
                ctx.root.$set(record, "expand", nestedItems);
            }
            if (["refund", "transfer_refund", "application_fee_refund"].includes(record.type) && ctx.root.$isAdmin()) {
                const nestedItems = table.data.filter(v => !v.type.includes("refund") && (record.source.id === v.source.id || record.source.originating_transaction === v.source.id || record.source.source_transaction === v.source.id || record.source.charge === v.source.id));
                console.log("getNestedTableItems -> ", nestedItems)
                ctx.root.$set(record, "expand", nestedItems);
            }
        }

        onMounted(() => {
            fetchData()
        })

        return {
            controls,
            table,
            fetchData,
            getInvoiceLink,
            getReservation,
            initRefund,
            getTagColor,
            getNestedTableItems
        }
    }
}
</script>
<template>
    <a-table :loading="controls.loading" :columns="table.headers" :expand-icon-as-cell="false"
        :expand-icon-column-index="-1" expand-row-by-click
        :data-source="table.data.filter(v => ['payment', 'payout'].includes(v.type) || $isAdmin())" row-key="id"
        :pagination="{ defaultPageSize: 10 }" :rowClassName="() => 'outer-table'" @expand="(expanded, record) => {
            $set(record, '$expanded', expanded);
            if (expanded && !record.expand) {
                getNestedTableItems(record)
            }
        }
            ">
        <template slot="date" slot-scope="date, record">
            <div class="flex items-center space-x-3">
                <template v-if="$isAdmin()">
                    <button v-if="['refund', 'transfer_refund', 'application_fee_refund'].includes(record.type)"
                        class="w-7 h-7 border border-blue-500 hover:bg-gray-100 flex items-center justify-center rounded-full">
                        <svg-icon icon-class="chevron-down" class="h-5 w-5 mt-0.5 text-blue-500 transform -rotate-90"
                            :class="{ 'rotate-0': record.$expanded }" />
                    </button>
                    <div v-else class="w-2 h-7"></div>
                    <span class="">
                        {{
                            $formatDate(date * 1000, "MMM Do YYYY").replace(new Date().getFullYear(), "")
                        }}
                    </span>
                </template>
                <template v-else>
                    <button v-if="$deepGet(record, 'source.refunded') === true"
                        class="w-7 h-7 border border-blue-500 hover:bg-gray-100 flex items-center justify-center rounded-full">
                        <svg-icon icon-class="chevron-down" class="h-5 w-5 mt-0.5 text-blue-500 transform -rotate-90"
                            :class="{ 'rotate-0': record.$expanded }" />
                    </button>
                    <div v-else class="w-2 h-7"></div>
                    <span class="">
                        {{
                            $formatDate(date * 1000, "MMM Do YYYY").replace(new Date().getFullYear(), "")
                        }}
                    </span>
                </template>
            </div>
        </template>
        <template slot="description" slot-scope="description, record">
            <div class="flex" style="max-width: 150px;">
                <span class="text-black font-medium text-sm">
                    {{
                        description
                    }}
                </span>
            </div>
        </template>
        <template slot="status" slot-scope="status, record">
            <div class="flex flex-col space-y-2 justify-center">
                <div>
                    <a-tag :color="getTagColor(status)" class="capitalize">
                        {{
                            status
                        }}
                    </a-tag>
                </div>
                <div class="flex justify-center items-center">
                    <a-tag v-if="$deepGet(record, 'source.refunded') === true" color="green"
                        class="flex items-center space-x-1 px-1.5">
                        <span>Refunded</span>
                        <svg-icon icon-class="check" class="h-4 w-4 text-green-500" />
                    </a-tag>
                </div>
            </div>
        </template>
        <template slot="type" slot-scope="type, record">
            <a-tag v-if="record.status !== 'paid'" color="geekblue" class="capitalize">
                {{
                    type.replace("adjustment", "Fee Refund").replace("payment_refund", "Refund")
                }}
            </a-tag>
            <a-tag v-else color="geekblue" class="capitalize">
                Payout
            </a-tag>
        </template>
        <template slot="expandedRowRender" slot-scope="record">
            <div v-if="record.expand" class="flex ml-24 border-l bg-white">
                <a-table row-key="id" :columns="table.headers.slice(0, -1)" :data-source="record.expand" :pagination="false"
                    class="w-full">
                    <template slot="date" slot-scope="date, record">
                        <span>
                            {{
                                $formatDate(date * 1000, "MM/DD/YYYY").replace(`/${new Date().getFullYear()}`, "")
                            }}
                        </span>
                    </template>
                    <template slot="description" slot-scope="description, record">
                        <span class="text-black font-medium">
                            {{
                                description
                            }}
                        </span>
                    </template>
                    <template slot="status" slot-scope="status, record">
                        <a-tag :color="getTagColor(status)" class="capitalize">
                            {{
                                status
                            }}
                        </a-tag>
                    </template>
                    <template slot="type" slot-scope="type, record">
                        <a-tag color="geekblue" class="capitalize">
                            {{
                                type.replace("adjustment", "Fee Refund").replace("payment_refund", "Refund")
                            }}
                        </a-tag>
                    </template>
                    <template slot="amount" slot-scope="amount, record">
                        <span v-if="record.status === 'available'" class="font-medium"
                            :class="amount > 0 ? 'text-green-400' : 'text-pink-500'">
                            {{ amount > 0 ? "+" : "" }}{{
                                $formatCurrency(amount)
                            }}
                        </span>
                        <span v-if="record.status === 'pending'" class="font-medium"
                            :class="amount > 0 ? 'text-orange-400' : 'text-pink-500'">
                            {{ amount > 0 ? "+" : "" }}{{
                                $formatCurrency(amount)
                            }}
                        </span>
                    </template>
                </a-table>
            </div>
        </template>
        <template slot="amount" slot-scope="amount, record">
            <span v-if="record.status === 'available'" class="font-medium"
                :class="amount > 0 ? 'text-green-400' : 'text-pink-500'">
                {{ amount > 0 ? "+" : "" }}{{
                    $formatCurrency(amount)
                }}
            </span>
            <span v-if="record.status === 'paid'" class="text-black font-medium">
                {{
                    $formatCurrency(amount)
                }}
            </span>
            <span v-if="record.status === 'withdrawn'" class="text-gray-500 font-medium">
                ({{
                    $formatCurrency(amount)
                }})
            </span>
            <span v-if="record.status === 'pending'" class="font-medium"
                :class="amount > 0 ? 'text-orange-400' : 'text-pink-500'">
                {{ amount > 0 ? "+" : "" }}{{
                    $formatCurrency(amount)
                }}
            </span>
        </template>
        <span slot="actions" slot-scope="_, record">
            <div v-if="$isAdmin()" class="flex items-center justify-center">
                <div class="grid grid-cols-2 gap-x-1.5 gap-y-2">
                    <a-button v-if="record.type === 'charge'" type="primary" size="small"
                        :loading="$deepGet(record, '$loading.invoice', false)" @click="getInvoiceLink(record)">
                        Invoice
                    </a-button>
                    <a-button v-if="record.type === 'charge'" size="small"
                        :loading="$deepGet(record, '$loading.reservation', false)" @click="getReservation(record)">
                        Reservation
                    </a-button>
                    <a-popconfirm v-if="$deepGet(record, 'source.source_transfer.source_transaction.invoice')"
                        title="This action will deactivate subscription bound to this payment" ok-text="Deactivate"
                        cancel-text="Cancel" @confirm="() => { }" @cancel="() => { }">
                        <a-button size="small" :loading="$deepGet(record, '$loading.cancelSubscription', false)">
                            Subscription
                        </a-button>
                    </a-popconfirm>
                    <a-popconfirm v-if="record.type === 'charge'"
                        title="This action will refund full amount captured in this transaction to tenant, including application fee"
                        ok-text="Confirm Refund" cancel-text="Cancel"
                        :disabled="$deepGet(record, 'source.refunded') === true" @confirm="
                            initRefund(record)
                            " @cancel="() => { }">
                        <a-button size="small" :loading="$deepGet(record, '$loading.refund', false)"
                            :disabled="$deepGet(record, 'source.refunded') === true">
                            Refund
                        </a-button>
                    </a-popconfirm>
                </div>
            </div>
            <div v-else class="flex items-center justify-center">
                <div v-if="record.status !== 'paid' && record.type === 'payment'"
                    class="grid grid-cols-2 gap-x-1.5 gap-y-2">
                    <a-button type="primary" size="small" :loading="$deepGet(record, '$loading.invoice', false)"
                        @click="getInvoiceLink(record)">
                        Invoice
                    </a-button>
                    <a-button size="small" :loading="$deepGet(record, '$loading.reservation', false)"
                        @click="getReservation(record)">
                        Reservation
                    </a-button>
                    <a-popconfirm v-if="$deepGet(record, 'source.source_transfer.source_transaction.invoice')"
                        title="This action will deactivate subscription bound to this payment" ok-text="Deactivate"
                        cancel-text="Cancel" @confirm="() => { }" @cancel="() => { }">
                        <a-button size="small" :loading="$deepGet(record, '$loading.cancelSubscription', false)">
                            Subscription
                        </a-button>
                    </a-popconfirm>
                    <a-popconfirm
                        title="This action will refund full amount captured in this transaction to tenant, including application fee"
                        ok-text="Confirm Refund" cancel-text="Cancel"
                        :disabled="$deepGet(record, 'source.refunded') === true" @confirm="
                            initRefund(record)
                            " @cancel="() => { }">
                        <a-button size="small" :loading="$deepGet(record, '$loading.refund', false)"
                            :disabled="$deepGet(record, 'source.refunded') === true">
                            Refund
                        </a-button>
                    </a-popconfirm>
                </div>
            </div>
        </span>
    </a-table>
</template>
<style lang="scss" scoped>
::v-deep {
    .ant-btn {
        @apply text-xs;
    }

    .ant-table-expanded-row {
        td:not(.ant-table-column-has-actions) {
            @apply p-0 border-0;
        }
    }
}
</style>