<template>
    <div class="flex flex-col border-r">
        <div class="flex items-center justify-center py-4 bg-gray-50 border-b">
            <span class="text-parqay-primary text-sm font-semibold">Pricing Calendar</span>
        </div>
        <div class="px-4 py-4">
            <vc-calendar ref="calendar" :key="key" is-multiple v-model="selectedDates" :attributes="calendarAttributes"
                :from-page="fromPage">
                <template #day-content="{ day, contentClass }">
                    <div :class="contentClass">
                        <div class="text-black hover:text-parqay-primary text-center font-semibold px-4 cursor-pointer">
                            {{
                day.day }}</div>
                        <div v-if="getPriceForDate(day.date)" class="price-text">
                            ${{ getPriceForDate(day.date) }}
                        </div>
                    </div>
                </template>
            </vc-calendar>
        </div>
    </div>
</template>

<script>
import { ref, reactive, toRefs, watch, onMounted } from '@vue/composition-api';
import dayjs from "dayjs";
import { convertToCumulativeConditionsIfNeeded } from './components/util';

export default {
    props: {
        events: {
            type: Array,
            default: () => null
        },
        simulatedSize: {
            type: String,
            default: null,
        },
        simulatedVolume: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        const { events } = toRefs(props);
        const calendar = ref("calendar");
        const key = ref(0);
        const selectedDates = ref([]);
        const calendarAttributes = reactive([]);
        const calendarPrices = reactive(new Map());
        const fromPage = ref({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });

        watch(() => events.value, val => {
            refresh();
        }, {
            deep: true
        })

        const generateRandomPricesForMonth = () => {
            let startDate = new Date();
            startDate.setDate(1);
            const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                const price = (Math.random() * 100).toFixed(2); // Random price between 0 and 100
                calendarPrices.set(d.toISOString().split('T')[0], price);
            }
        };

        const convertToLocalStartOfDay = (datetimeLocal) => {
            return dayjs(datetimeLocal).startOf('day').toDate().toISOString().split('T')[0];
        };

        // Utility function to evaluate string conditions
        const checkCondition = (condition, value) => {
            // Pattern to match the operators and the number from the condition string
            const pattern = /([<>]=?|==)(\d+)/;
            const matches = condition.match(pattern);

            if (matches) {
                const operator = matches[1];
                const number = parseFloat(matches[2]);
                switch (operator) {
                    case '<':
                        return value < number;
                    case '<=':
                        return value <= number;
                    case '>':
                        return value > number;
                    case '>=':
                        return value >= number;
                    case '==':
                        return value === number;
                    default:
                        throw new Error(`Unhandled operator: ${operator}`);
                }
            } else {
                throw new Error(`Invalid condition string: ${condition}`);
            }
        };

        const applySign = (adjustment) => {
            if (adjustment.sign !== '+' && adjustment.sign !== '-') {
                throw new Error("Sign string must be '+' or '-'");
            }
            return adjustment.sign === '+' ? Math.abs(adjustment.adjustment) : -Math.abs(adjustment.adjustment);
        };

        // Pricing Simulator function
        const calculateFinalPrice = (event, sizeType = props.simulatedSize, volumeRemaining = props.simulatedVolume) => {
            let finalPrice = event.price;

            const adjustments = convertToCumulativeConditionsIfNeeded(event.adjustments);

            // Apply volume adjustments
            const volumeAdjustment = adjustments.find(adj =>
                adj.type === "volume" && checkCondition(adj.tier, volumeRemaining)
            );

            if (volumeAdjustment) {
                finalPrice += applySign(volumeAdjustment);
            }

            // Apply size adjustments
            const sizeAdjustment = adjustments.find(adj =>
                adj.type === "size" && adj.tier === sizeType
            );

            if (sizeAdjustment) {
                finalPrice += applySign(sizeAdjustment);
            }

            // Return the final price
            return event.price;
        };

        const initializeCalendarAttributesFromEvents = () => {
            calendarPrices.clear();
            events.value.forEach(event => {
                calendarPrices.set(convertToLocalStartOfDay(event.datetime_local), calculateFinalPrice(event).toFixed(2));
            });
        };

        const getPriceForDate = (date) => {
            return calendarPrices.get(date.toISOString().split('T')[0]);
        };

        const focusMonthOfFirstEvent = () => {
            if (events.value && events.value.length > 0) {
                const firstEventDate = new Date(events.value[0].datetime_local);
                fromPage.value = { month: firstEventDate.getMonth() + 1, year: firstEventDate.getFullYear() };
            }
        };

        const refresh = () => {
            if (events && events.value && events.value.length > 0) {
                initializeCalendarAttributesFromEvents();
                focusMonthOfFirstEvent();
            } else {
                generateRandomPricesForMonth();
            }
            key.value += 1;
        }

        onMounted(() => {
            refresh();
        });

        return {
            calendar,
            key,
            selectedDates,
            calendarAttributes,
            calendarPrices,
            fromPage,
            refresh,
            getPriceForDate
        };
    },
};
</script>

<style lang="scss" scoped>
.price-text {
    font-size: 0.65rem;
    font-weight: 600;
    color: #1c55e4;
    margin-top: 0.25rem;
}
</style>