import { data as timekitContent } from "src/views/docs/data/timekit";

export const docsContent = {
    main: [
      {
        name: "Documentation",
        icon: "",
        anchor: "documentation",
        sourcecode: `
  POST /task?id=1 HTTP/1.1
  Host: example.org
  Content-Type: application/json; charset=utf-8
  Content-Length: 137
  
  {
    "status": "ok",
    "extended": true,
    "results": [
      {"value": 0, "type": "int64"},
      {"value": 1.0e+3, "type": "decimal"}
    ]
  }
          `,
      },
      {
        name: "Examples",
        icon: "",
        anchor: "examples",
      },
      {
        name: "Playground",
        icon: "",
        anchor: "playground",
      },
    ],
    secondary: [
      {
        name: "Getting Started",
        anchor: "getting-started",
        sub: [
          {
            name: "Installation",
            anchor: "installation",
          },
        ],
      },
      {
        name: "Reservations API",
        anchor: "reservations",
        sub: [
          {
            name: "Create A Reservation",
            anchor: "create-reservation",
          },
          {
            name: "Update A Reservation",
            anchor: "update-reservation",
          },
          {
            name: "Delete A Reservation",
            anchor: "delete-reservation",
          },
        ],
      },
      timekitContent,
      {
        name: "Vehicles API",
        anchor: "vehicles",
        sub: [
          {
            name: "Create A Vehicle",
            anchor: "create-vehicle",
          },
        ],
      },
    ],
  };
  