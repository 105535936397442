<script>
import { ref } from "@vue/composition-api";
import TabHeader from "src/views/components/TabHeader.vue";
import Chat from "./Chat.vue";
import Email from "./Email.vue";

export default {
  components: {
    TabHeader,
    Chat,
    Email
  },
  setup(props, ctx) {
    const activeTab = ref("reservations");
    const setupHashRoute = hashRoute => {
      ctx.root.$router.push({ name: ctx.root.$route.name, hash: `#${hashRoute}`, query: ctx.root.$route.query });
    };
    const changeTab = async name => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    return {
      activeTab,
      changeTab
    };
  }
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="pt-10 h-screen flex flex-col px-10 py-10 overflow-y-auto">
      <div class="mb-6">
        <tab-header v-model="activeTab" :tabs="[
          { name: 'chat', label: 'Chat' },
          { name: 'email', label: 'Email' }
        ]" @change="changeTab" />
      </div>
      <chat
        v-if="activeTab === 'chat'"
      />
      <email
        v-if="activeTab === 'email'"
      />
    </div>
  </div>
</template>
