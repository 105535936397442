<script>
import { ref, computed, onMounted, reactive } from "@vue/composition-api";

import { API } from "src/views/listings/api";
import { API as AccountAPI } from "src/views/components/api/account";

import TabHeader from "src/views/components/TabHeader.vue";
import ServiceTypeDropdown from "src/views/dropdowns/ServiceType";
import ListingAvatar from "src/views/components/ListingImageAvatar.vue";
import VueQr from 'vue-qr'

import uniq from "lodash/uniq";

import ParqayMarkerImage from "src/assets/icons/svg/PARQAY-marker.svg";

export default {
  components: {
    TabHeader,
    ServiceTypeDropdown,
    ListingAvatar,
    VueQr
  },
  setup(props, { root }) {
    const { $route, $router } = root;
    const controls = reactive({
      activateListings: false,
      selectedListings: [],
      loading: {
        table: false,
        status: false
      },
      visible: { qrCode: false },
      activeTab: root.$isAdmin() ? "tenants" : "host",
      landingPageState: null,
      whiteLabel: {},
      counts: {
        active: 0,
        inactive: 0,
        host: 0,
        tenant: 0,
      }
    });
    const pagination = reactive({
      currentPage: 1,
      perPage: 10,
      total: 0
    })
    const serviceTypeOptions = [
      "Parking",
      "Coworking",
      "Stays",
      "car-rental",
      "Services",
      "All",
    ];
    const activeServiceType = ref("All");

    const table = reactive({
      headers: {
        host: [
          {
            title: "Listing",
            align: "center",
            dataIndex: "name",
            sorter: (a, b) => {
              const result = a.name.localeCompare(b.name);
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "name" },
          },
          {
            title: "Location",
            align: "center",
            dataIndex: "location.address",
            sorter: (a, b) => {
              const result = a.location.address.localeCompare(b.location.address);
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "location" },
          },
          {
            title: "Listing type",
            align: "center",
            dataIndex: "type",
            sorter: (a, b) => {
              const result = a.type.localeCompare(b.type);
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "type" },
          },
          {
            title: "Traffic & Views",
            align: "center",
            dataIndex: "insights",
            sorter: (a, b) => {
              if (a.insights && b.insights) {
                return a.insights.total_views_count > b.insights.total_views_count;
              }
              return 0;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "traffic" },
          },
          {
            title: "Actions",
            align: "center",
            scopedSlots: { customRender: "options" },
          },
          {
            title: "Active",
            align: "center",
            dataIndex: "is_active",
            sorter: (a, b) => {
              const result = a.is_active - b.is_active;
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "active" },
          },
          {
            title: "Auto-Approve",
            align: "center",
            dataIndex: "is_auto_approve",
            sorter: (a, b) => {
              const result = a.is_auto_approve - b.is_auto_approve;
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "auto_approve" },
          },
        ],
        tenants: [
          {
            title: root.$isAdmin() ? "Host" : "Name",
            align: "center",
            dataIndex: "tenant.full_name",
            sorter: (a, b) => {
              const result = a.tenant.full_name.localeCompare(b.tenant.full_name);
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "tenant-name" },
          },
          {
            title: "Listing",
            align: "center",
            dataIndex: "name",
            sorter: (a, b) => {
              const result = a.name.localeCompare(b.name);
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "name" },
          },
          {
            title: "Location",
            align: "center",
            dataIndex: "location.address",
            sorter: (a, b) => {
              const result = a.location.address.localeCompare(b.location.address);
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "location" },
          },
          {
            title: "Listing type",
            align: "center",
            dataIndex: "type",
            sorter: (a, b) => {
              const result = a.type.localeCompare(b.type);
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "type" },
          },
          {
            title: "Traffic & Views",
            align: "center",
            dataIndex: "insights",
            sorter: (a, b) => {
              if (a.insights && b.insights) {
                return a.insights.total_views_count > b.insights.total_views_count;
              }
              return 0;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "traffic" },
          },
          root.$isAdmin() && {
            title: "Actions",
            align: "center",
            scopedSlots: { customRender: "options" },
          },
          {
            title: "Active",
            align: "center",
            dataIndex: "is_active",
            sorter: (a, b) => {
              const result = a.is_active - b.is_active;
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "active" },
          },
          {
            title: "Auto-Approve",
            align: "center",
            dataIndex: "is_auto_approve",
            sorter: (a, b) => {
              const result = a.is_auto_approve - b.is_auto_approve;
              console.log("sorting...", result);
              return result;
            },
            sortDirections: ["descend", "ascend"],
            scopedSlots: { customRender: "auto_approve" },
          },
        ]
      },
      data: [],
    });

    const listings = ref([]);

    const handleTableChange = (paging, _, sorter, table) => {
      console.log(paging);
      console.log(sorter);
      console.log(table);
    };

    const rowSelection = computed(() => {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          controls.selectedListings = selectedRows.map((v) => v.id);
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    });

    const handleActiveChange = async (id, is_active) => {
      controls.loading.status = true;
      await API.updateActiveState(id, is_active);
      getStatus();
    };

    const handleAutoApproveChange = async (id, is_auto_approve) => {
      await API.updateAutoApproveState(id, is_auto_approve);
    };

    const toggleListingsState = async (active) => {
      controls.loading.status = true;
      const ls = table.data.filter((v) => controls.selectedListings.includes(v.id));
      ls.forEach((v) => {
        v.is_active = active;
      });
      for (const l of ls) {
        await handleActiveChange(l.id, active);
      }
      getStatus();
    };

    const getTypeName = (type) => {
      switch (type) {
        case "parking":
          return "Parking";
        case "coworking":
          return "Coworking";
        case "stay":
          return "Stay";
        case "car-rental":
          return "Car Rental";
        default:
          return "Unknown";
      }
    };

    const getIconClass = (type) => {
      switch (type) {
        case "parking":
          return "listing-type-parking";
        case "coworking":
          return "listing-type-co-working";
        case "stay":
          return "listing-type-stay";
        default:
          return "listing-type-car-rental";
      }
    };

    const getWhiteLabelState = async () => {
      try {
        const { data } = await AccountAPI.getUser();
        if (data.white_label) {
          controls.landingPageState = "active";
          controls.whiteLabel = data.white_label;
        } else {
          controls.landingPageState = "inactive";
          controls.whiteLabel = {};
        }
      } catch (error) {
        console.log("getWhiteLabelState: error -----> ", error);
        controls.landingPageState = "inactive";
        controls.whiteLabel = {};
      }
    }

    const getLandingPageURL = () => {
      return process.env.VUE_APP_MARKETPLACE_FRONTEND_BASE_URL + "/hosts/" + controls.whiteLabel.url_slug;
    }

    const getStatus = async () => {
      try {
        controls.loading.status = true;
        const { data: { active_count, inactive_count, hosts_count = 0, tenants_count = 0 } } = await API.getStatus(root.$isAdmin() ? "host" : controls.activeTab);
        controls.counts.active = active_count;
        controls.counts.inactive = inactive_count;
        controls.counts.host = hosts_count;
        controls.counts.tenant = tenants_count;
        controls.loading.status = false;
      } catch (error) {
        controls.loading.status = false;
      }
    }

    const fetchData = async () => {
      table.data = [];
      listings.value = [];
      controls.loading.table = true;
      getStatus();
      const payload = {
        filter: {
          page: pagination.currentPage
        }
      };
      let resp = {};
      if (controls.activeTab === "host") {
        const { data, pagination: pag } = await API.list(payload);

        resp.data = data;

        pagination.currentPage = pag.page;
        pagination.perPage = pag.per_page;
        pagination.total = pag.total;
      }

      if (controls.activeTab === "tenants") {
        if (root.$isAdmin()) {
          const { data, pagination: pag } = await API.list(payload);
          resp.data = data;

          pagination.currentPage = pag.page;
          pagination.perPage = pag.per_page;
          pagination.total = pag.total;
        } else {
          const { data, pagination: pag } = await API.listTenantsListings(payload);
          resp.data = data;

          pagination.currentPage = pag.page;
          pagination.perPage = pag.per_page;
          pagination.total = pag.total;
        }
      }

      resp.data.forEach(async v => {
        const { data } = await API.getEventInsight(v.id, "traffic");
        root.$set(v, "insights", data);
      })
      listings.value = resp.data;
      table.data = listings.value;
      controls.loading.table = false;
    };

    const startAutoposter = async (listingId) => {
      try {
        const { data } = await API.startAutoposter(listingId);
        root.$openNotification(
          "Autoposter Request",
          `Request sent. ${JSON.stringify(data)}`
        )
      } catch (error) {
        root.$openNotification(
          "Autoposter Error",
          error.message
        )
      }
    }

    const deleteListing = async () => {
      table.data = table.data.filter(
        (v) => !controls.selectedListings.includes(v.id)
      );
      for (const listingId of controls.selectedListings) {
        await API.delete(listingId);
      }
      controls.selectedListings = [];
    };

    const setupHashRoute = hashRoute => {
      $router.push({ name: $route.name, hash: `#${hashRoute}` });
    };
    const changeTab = async name => {
      controls.activeTab = name;
      setupHashRoute(name);
      fetchData();
    };

    const handlePageChange = (page) => {
      pagination.currentPage = page;
      fetchData();
    }

    onMounted(() => {
      fetchData();
      getWhiteLabelState();
    });

    return {
      controls,
      pagination,
      serviceTypeOptions,
      activeServiceType,
      table,
      listings,
      rowSelection,
      getStatus,
      fetchData,
      startAutoposter,
      getLandingPageURL,
      handleTableChange,
      handleActiveChange,
      handleAutoApproveChange,
      toggleListingsState,
      getTypeName,
      getIconClass,
      deleteListing,
      changeTab,
      handlePageChange,
      uniq,
      ParqayMarkerImage,
      console,
    };
  },
};
</script>
<template>
  <div class="h-full px-10 py-10 flex flex-col space-y-4">

    <tab-header v-show="!$isAdmin()" v-model="controls.activeTab" :tabs="$isAdmin() ?
      [
        { name: 'tenants', label: 'Listings' }
      ]
      :
      [
        { name: 'host', label: 'Your Listings' }, { name: 'tenants', label: 'Tenants Listings' }
      ]" @change="changeTab">
      <template #right-actions>
        <a-button type="primary" @click="$router.push('/dashboard/account-settings#white-label')">White-label Settings
        </a-button>
      </template>
    </tab-header>

    <template v-if="controls.activeTab === 'host'">
      <div class="flex justify-between items-center mt-5">
        <div class="flex flex-col space-y-2">
          <div class="flex items-center space-x-4">
            <span class="text-black text-lg font-semibold">
              Listings
            </span>
            <a-icon type="reload" :spin="controls.loading.table" style="font-size: 0.95rem;"
              @click.stop="fetchData()" />
          </div>
          <span class="text-sm font-normal text-gray-500">Your own listings as a host that were
            published</span>
        </div>
        <div class="flex space-x-3 items-center">
          <a-button type="primary" :disabled="controls.landingPageState === 'inactive'" style="height: 38px;"
            @click="$openUrlInNewTab(getLandingPageURL())">
            Landing Page
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="ml-2 w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
            </svg>
          </a-button>
          <a-tooltip v-if="controls.landingPageState === 'inactive'" placement="top">
            <template slot="title">
              <span>Host listings landing page URL will be available after you created white-label profile in settings.
                Please click the "White-label Settings" button above to setup.</span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
          <a-button style="height: 38px;" @click.stop="() => controls.visible.qrCode = true">
            QR Code
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="ml-2 w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
            </svg>
          </a-button>
          <service-type-dropdown @selected="(m) => {
      console.log('selected type -> ', m);
      if (m.value) {
        table.data = listings.filter((v) => v.type === m.value);
      } else {
        table.data = listings;
      }
    }
      " />
        </div>
      </div>

      <div class="mt-6">
        <div class="rounded-lg bg-white shadow-sm border pb-6">
          <div class="flex justify-between items-center px-6 my-6">
            <div class="flex items-center space-x-2">
              <h4 class="font-medium text-base">
                You have
                <span class="text-parqay-primary">{{
      `${pagination.total} Listing${listings.length > 1 ? "s" : ""}`
    }}</span>
                available
              </h4>
              <a-tag status color="green">
                <a-icon v-if="controls.loading.status" type="loading" />
                <span>{{ controls.counts.active }} in marketplace</span>
              </a-tag>
              <a-tag status color="pink">
                <a-icon v-if="controls.loading.status" type="loading" />
                <span>{{ controls.counts.inactive }} inactive</span>
              </a-tag>
            </div>
            <div v-if="controls.selectedListings.length > 0" class="flex space-x-4 items-center">
              <a-switch v-model="controls.activateListings" checked-children="Active" un-checked-children="Inactive"
                @change="toggleListingsState(controls.activateListings)" />
              <a-button type="danger" icon="close" @click="deleteListing()">
                Delete {{ controls.selectedListings.length }} Listing{{
      controls.selectedListings.length > 1 ? "s" : ""
    }}
              </a-button>
            </div>
          </div>
          <a-table class="border-t" :loading="controls.loading.table" :columns="table.headers.host"
            :data-source="table.data" row-key="id" :pagination="false" :row-selection="rowSelection" :custom-row="(record, index) => {
      return {
        on: {
          click: (event) => {
            $router.push({
              name: 'ListingsDetail',
              params: { id: record.id },
            })
          }
        }
      }
    }" @change="handleTableChange">

            <template slot="name" slot-scope="name, record">
              <div class="flex items-center space-x-3">
                <listing-avatar width="50" height="50" rounded="sm" :listing-id="record.id" />
                <span class="font-medium text-gray-900 text-xs" style="min-width: 150px;">{{ name }}</span>
              </div>
            </template>

            <span slot="location" slot-scope="location" class="text-parqay-primary text-center font-medium text-xs">{{
      location
    }}</span>

            <template slot="type" slot-scope="type, record">
              <div class="flex items-center justify-center space-x-2">
                <svg-icon class="flex-shrink-0 w-6 h-6" :icon-class="getIconClass(type)" />
                <span v-if="record.subtype && record.subtype === 'event'"
                  class="text-left font-medium text-gray-900 text-xs">
                  Event Parking
                </span>
                <span v-else class="text-left font-medium text-gray-900 text-xs">
                  {{ getTypeName(type) }}
                </span>
              </div>
            </template>

            <template slot="traffic" slot-scope="insights, record">
              <div class="flex items-center justify-center space-x-2" style="min-width: 150px;">
                <template v-if="insights">
                  <a-tag style="display: flex; align-items: center; border-color: black;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 mr-1">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <span>{{ insights.total_views_count }} views</span>
                  </a-tag>
                  <a-tag style="display: flex; align-items: center; border-color: black;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 mr-1">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                    </svg>
                    <span>{{ insights.event_pages_count }} events</span>
                  </a-tag>
                </template>
                <a-icon v-else type="loading" style="" />
              </div>
            </template>
            <span slot="options" slot-scope="options, record">
              <div class="flex items-center space-x-2" @click.stop="() => { }">
                <a-popconfirm :title="`Promote listing: ${record.name}, would you like to proceed?`"
                  ok-text="Start Autoposter" stop-propagation @confirm="
      startAutoposter(record.id)
      ">
                  <a-button size="small" class="w-16 text-xs">
                    Promote
                  </a-button>
                </a-popconfirm>
              </div>
            </span>
            <template slot="active" slot-scope="is_active, record">
              <div @click.stop="() => { }">
                <a-switch v-model="record.is_active" @change="handleActiveChange(record.id, record.is_active)" />
              </div>
            </template>
            <template slot="auto_approve" slot-scope="is_auto_approve, record">
              <div @click.stop="() => { }">
                <a-switch v-model="record.is_auto_approve"
                  @change="handleAutoApproveChange(record.id, record.is_auto_approve)" />
              </div>
            </template>
          </a-table>
          <div class="flex items-center justify-center pt-4">
            <a-pagination :current="pagination.currentPage" :total="pagination.total" :page-size="pagination.perPage"
              @change="handlePageChange" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex justify-between items-center mt-5">
        <div class="flex flex-col space-y-2">
          <div class="flex items-center space-x-4">
            <span class="text-black text-lg font-semibold">
              {{ $isAdmin() ? "Platform Listings" : "Tenants Listings" }}
            </span>
            <a-icon type="reload" :spin="controls.loading.table" style="font-size: 0.95rem;"
              @click.stop="fetchData()" />
          </div>
          <p v-if="$isAdmin()" class="text-sm font-normal text-gray-500 max-w-3xl">
            Listings of all hosts are given below. Full control has been granted for these listings.
          </p>
          <p v-else class="text-sm font-normal text-gray-500 max-w-3xl">As a super-host, all listings created by the
            tenants
            you invited
            will show below. To change your company / org logo and display name to your tenants, please go to
            White-label
            Settings on the top-right
          </p>
        </div>
        <div class="flex space-x-2 items-center">
          <span class="font-medium text-base">Service Type</span>
          <service-type-dropdown @selected="(m) => {
      console.log('selected type -> ', m);
      if (m.value) {
        table.data = listings.filter((v) => v.type === m.value);
      } else {
        table.data = listings;
      }
    }
      " />
        </div>
      </div>

      <div class="mt-6">
        <div class="rounded-lg bg-white shadow-sm border pb-6">
          <div class="flex justify-between items-center px-6 my-6">
            <div class="flex items-center space-x-2">
              <h4 class="font-medium text-base">
                <a-tag color="geekblue">
                  {{ $isAdmin() ? controls.counts.host : controls.counts.tenant }} {{ $isAdmin() ? "host" : "tenant"
                  }}{{
      ($isAdmin() ? controls.counts.host : controls.counts.tenant) > 1 ?
        "s" : ""
    }}
                </a-tag>
                {{ uniq(listings.map((v) => v.user)).length > 1 ?
      "are" : "is"
                }} hosting
                <a-tag color="geekblue">
                  {{ pagination.total }} listing{{ listings.length > 1 ? "s" : "" }}
                </a-tag>
                Status:
              </h4>
              <a-tag status color="green">
                <a-icon v-if="controls.loading.status" type="loading" />
                <span>{{ controls.counts.active }} in marketplace</span>
              </a-tag>
              <a-tag status color="pink">
                <a-icon v-if="controls.loading.status" type="loading" />
                <span>{{ controls.counts.inactive }} inactive</span>
              </a-tag>
            </div>
            <div v-if="controls.selectedListings.length > 0" class="flex space-x-4 items-center">
              <a-switch v-model="controls.activateListings" checked-children="Active" un-checked-children="Inactive"
                @change="toggleListingsState(controls.activateListings)" />
              <a-button type="danger" icon="close" @click="deleteListing()">
                Delete {{ controls.selectedListings.length }} Listing{{
      controls.selectedListings.length > 1 ? "s" : ""
    }}
              </a-button>
            </div>
          </div>
          <a-table class="border-t" :loading="controls.loading.table" :columns="table.headers.tenants"
            :data-source="table.data" row-key="id" :pagination="false" :row-selection="rowSelection" :custom-row="(record, index) => {
      return {
        on: {
          click: (event) => {
            $router.push({
              name: 'ListingsDetail',
              params: { id: record.id },
            })
          }
        }
      }
    }" @change="handleTableChange">
            <template slot="tenant-name" slot-scope="tenant_name, record">
              <div class="flex items-center justify-between py-2 space-x-4">
                <avatar :user-id="$isAdmin() ? record.user : $deepGet(record, 'tenant.id')" popover
                  :relative-role="$isAdmin() ? 'host' : null" class="cursor-pointer"
                  @click="$router.push('/dashboard/tenants?focus=' + record.user)" />
                <a v-if="!$isAdmin()" class="font-medium text-xs text-blue-500 flex-grow text-center"
                  @click.stop="$router.push('/dashboard/tenants?focus=' + $deepGet(record, 'tenant.id'))">
                  {{ tenant_name }}
                </a>
              </div>
            </template>

            <template slot="name" slot-scope="name, record">
              <div class="flex items-center space-x-3">
                <listing-avatar width="50" height="50" rounded="sm" :listing-id="record.id" />
                <span class="font-medium text-gray-900 text-xs" style="min-width: 150px;">{{ name }}</span>
              </div>
            </template>

            <span slot="location" slot-scope="location" class="text-parqay-primary text-center font-medium text-xs">{{
      location
    }}</span>
            <div slot="type" slot-scope="type" class="flex flex-row items-center justify-center">
              <svg-icon class="w-6 h-6 mr-2" :icon-class="getIconClass(type)" />
              <span class="w-20 text-left font-medium text-gray-900 text-xs">{{
      getTypeName(type)
    }}</span>
            </div>
            <template slot="traffic" slot-scope="insights, record">
              <div class="flex flex-col items-center justify-center space-y-2" style="width: 90px;">
                <template v-if="insights">
                  <a-tag views>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <span>{{ insights.total_views_count }} views</span>
                  </a-tag>
                  <a-tag views>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                    </svg>

                    <span>{{ insights.event_pages_count }} events</span>
                  </a-tag>
                </template>
                <a-icon v-else type="loading" style="" />
              </div>
            </template>
            <span slot="options" slot-scope="options, record">
              <div class="flex items-center space-x-2" @click.stop="() => { }">
                <a-popconfirm :title="`Promote listing: ${record.name}, would you like to proceed?`"
                  ok-text="Start Autoposter" stop-propagation @confirm="
      startAutoposter(record.id)
      ">
                  <a-button size="small" class="w-16 text-xs">
                    Promote
                  </a-button>
                </a-popconfirm>
              </div>
            </span>
            <template slot="active" slot-scope="is_active, record">
              <div @click.stop="() => { }">
                <a-switch v-model="record.is_active" @change="handleActiveChange(record.id, record.is_active)" />
              </div>
            </template>
            <template slot="auto_approve" slot-scope="is_auto_approve, record">
              <div @click.stop="() => { }">
                <a-switch v-model="record.is_auto_approve"
                  @change="handleAutoApproveChange(record.id, record.is_auto_approve)" />
              </div>
            </template>
          </a-table>
          <div class="flex items-center justify-center pt-4">
            <a-pagination :current="pagination.currentPage" :total="pagination.total" :page-size="pagination.perPage"
              @change="handlePageChange" />
          </div>
        </div>
      </div>
    </template>
    <a-modal v-model="controls.visible.qrCode" title="Host Listings QR Code" :footer="null">
      <div class="flex flex-col items-center justify-center" style="max-width: 520px;">
        <p class="text-center font-medium mb-0">QR code for your host landing page URL</p>
        <a-divider style="margin: 16px 0;"></a-divider>
        <template v-if="controls.landingPageState === 'active'">
          <vue-qr color-dark="#1c55e4" background-color="#c5d4fa" logo-src="/static/img/dashboard/parqay-logo.png"
            :text="getLandingPageURL()" :dot-scale="0.8" :logo-scale="0.2" :size="500" />
          <a-divider style="margin: 12px 0;"> How to download / print?</a-divider>
          <span>Move the mouse onto the QR code, right click to call context menu:</span>
          <div class="px-6 py-2">
            <p class="py-1 text-sm"><a-tag color="geekblue">To Download</a-tag>Click "<b>Save Image As...</b>"</p>
            <p class="py-1 text-sm"><a-tag color="geekblue">To Print</a-tag>Open downloaded image in step 1, then
              click print in previewer</p>
          </div>
        </template>
        <div v-else class="flex items-center space-x-2">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-20 h-20">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
            </svg>
          </div>
          <span>
            Host listings landing page QR code will be available after you created white-label profile in
            settings.
            Please click the "White-label Settings" button above to setup.
          </span>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {

  .ant-btn {
    @apply flex items-center justify-center;
  }

  .ant-table-row {
    @apply cursor-pointer;
  }

  .ant-tag[status] {
    @apply flex items-center space-x-2;
  }

  .ant-tag[views] {
    display: flex;
    border-color: black;
    @apply w-full items-center justify-between h-6;
  }

  .ant-pagination {
    @apply px-4;
  }
}
</style>
