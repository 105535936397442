<script>
import VerifyLot from "src/views/listings/new/components/VerifyLot";
import ReceivePayment from "src/views/listings/new/components/ReceivePayment";
// import ListingDetails from "./ListingDetails";
import SchedulePrice from "src/views/listings/new/components/SchedulePrice";

import StaySpaceDetails from "src/views/listings/new/stay-coworking/StaySpaceDetails";
import CoworkingSpaceDetails from "src/views/listings/new/stay-coworking/CoworkingSpaceDetails";
// import HomeSchedulePrice from 'src/components/body/pages/hosting flow/new2/stay sharing flow/HomeSchedulePrice';

import { ref, onMounted, onBeforeUnmount } from "@vue/composition-api";

export default {
  name: "stay-coworking-listing-create",
  components: {
    VerifyLot,
    ReceivePayment,
    StaySpaceDetails,
    CoworkingSpaceDetails,
    SchedulePrice,
    // ListingDetails,
    // SchedulePrice,
  },
  setup(props, ctx) {
    const steps = ref([
      "Locate Me",
      "Listing details",
      "Schedule & Price",
      "Payout",
    ]);
    var stepComponents = ref([]);
    if (ctx.root.$route.hash == "#coworking") {
      stepComponents.value = [
        "verify-lot",
        "coworking-space-details",
        "schedule-price",
        "receive-payment",
      ];
    } else {
      stepComponents.value = [
        "verify-lot",
        "stay-space-details",
        "schedule-price",
        "receive-payment",
      ];
    }
    const stepIndex = ref(0);
    const skipToStep = (s) => {
      if (stepIndex.value > s) {
        stepIndex.value = s;
      }
    };
    const nextStep = () => {
      console.log("nextStep");
      let target = stepIndex.value + 1;
      if (target === 5) {
        ctx.root.$router.push("/listing/new");
        return;
      }
      stepIndex.value = target;
    };
    const previousStep = () => {
      let target = stepIndex.value - 1;
      if (target === -1) {
        ctx.root.$router.go(-1);
        return;
      }
      stepIndex.value = target;
    };
    const payloadUpdate = (pk, pd) => {
      ctx.root.$store.commit("updateListingForm", [pk, pd]);
    };
    const clearPayload = () => {
      ctx.root.$store.commit("resetLisitngForm");
    };

    onMounted(() => {
      payloadUpdate("type", ctx.root.$route.hash.replace("#", ""));
    });

    onBeforeUnmount(() => {
      clearPayload();
    });
    return {
      steps,
      stepComponents,
      stepIndex,
      skipToStep,
      nextStep,
      previousStep,
      payloadUpdate,
    };
  },
};
</script>

<template>
  <div class="w-full bg-white">
    <div class="flex justify-center border-b border-gray-300 relative">
      <div class="
          absolute
          left-4
          top-0
          bottom-0
          w-auto
          flex
          space-x-2
          items-center
          justify-center
          border-parqay-primary
          hover:border-indigo-800
          text-parqay-primary
          hover:text-blue-800
        ">
        <button class="
            border-2 border-parqay-primary
            rounded-full
            w-10
            h-10
            inline-flex
            items-center
            justify-center
            focus:outline-none
            focus:ring-indigo-500
            focus:border-indigo-500
          " @click="previousStep">
          <svg-icon icon-class="chevron-left" class="w-6 h-6 my-auto" />
        </button>
        <span class="text-lg font-medium cursor-pointer" @click="previousStep">Back</span>
      </div>
      <nav aria-label="Progress">
        <ol class="rounded-md divide-y divide-gray-300 mb-0 md:flex md:divide-y-0">
          <li v-for="(step, i) in steps" :key="`step-${i}`" class="relative md:flex-0 md:flex">
            <a class="group flex items-center w-full">
              <span class="px-6 py-4 flex items-center text-sm font-medium">
                <!-- Completed Step -->
                <span v-if="stepIndex > i" class="
                    flex-shrink-0
                    w-10
                    h-10
                    flex
                    items-center
                    justify-center
                    bg-parqay-primary
                    rounded-full
                    group-hover:bg-blue-500
                  " @click="skipToStep(i)">
                  <svg-icon icon-class="solid-check" class="w-6 h-6 text-white" />
                </span>
                <!-- Current Step -->
                <span v-else-if="stepIndex == i" class="
                    flex-shrink-0
                    w-10
                    h-10
                    flex
                    items-center
                    justify-center
                    border-2 border-parqay-primary
                    rounded-full
                  " aria-current="step" @click="skipToStep(i)">
                  <span class="text-parqay-primary">{{ i + 1 }}</span>
                </span>
                <!-- Upcoming Step -->
                <span v-else class="
                    flex-shrink-0
                    w-10
                    h-10
                    flex
                    items-center
                    justify-center
                    border-2 border-gray-300
                    rounded-full
                    group-hover:border-gray-400
                  " @click="skipToStep(i)">
                  <span class="text-gray-500 group-hover:text-gray-900">{{
                    i + 1
                  }}</span>
                </span>
                <span class="ml-4 text-sm font-medium text-gray-900" @click="skipToStep(i)">{{ step }}</span>
              </span>
            </a>

            <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
              <svg-icon icon-class="step-indicator-arrow" class="h-full w-full text-gray-300" />
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div class="body">
      <div class="listview bg-gray-50">
        <!-- Do Not cache last step since its triggering by on mounted hook! -->
        <keep-alive v-if="stepIndex < stepComponents.length - 1">
          <component :is="stepComponents[stepIndex]" class="flex justify-center" @next="nextStep" @back="previousStep"
            @payload-update="payloadUpdate" />
        </keep-alive>
        <component :is="stepComponents[stepIndex]" v-else class="flex justify-center" @next="nextStep"
          @back="previousStep" @payload-update="payloadUpdate" />
      </div>
    </div>
  </div>
</template>
