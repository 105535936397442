import cloneDeep from "lodash/cloneDeep";

/**
 * Converts an array of tier objects into an array of tier objects with cumulative conditions.
 * Each tier's value is transformed into a string representing a cumulative condition.
 * 
 * @param {Object[]} tiers - An array of objects with a 'tier' key representing the tier value.
 * @returns {Object[]} An array of objects with the 'tier' key containing the cumulative condition as a string.
 *
 * @example
 * // returns [{ "tier": "<=10" }, { "tier": "<=30" }]
 * convertToCumulativeConditionsIfNeeded([{ "tier": 10 }, { "tier": 20 }]);
 */
export const convertToCumulativeConditionsIfNeeded = (tiers) => {
    let cumulativeSum = 0;

    let _tiers = cloneDeep(tiers);

    return _tiers.map((tier) => {
        if (tier.type === "volume") {
            cumulativeSum += tier.tier; // Increase cumulative sum by the current tier value
            tier.tier = `<=${cumulativeSum}`; // Return a new object with the updated condition
        }
        return tier;
    });
};

/**
 * Reverses the process of convertToCumulativeConditionsIfNeeded by converting an array of tier objects
 * with cumulative condition strings back into an array of tier objects with the original numerical values.
 * 
 * @param {Object[]} tiers - An array of objects with a 'tier' key containing the cumulative condition as a string.
 * @returns {Object[]} An array of objects with the 'tier' key representing the original tier value.
 *
 * @example
 * // returns [{ "tier": 10 }, { "tier": 20 }]
 * convertFromCumulativeConditionsIfNeeded([{ "tier": "<=10" }, { "tier": "<=30" }]);
 */
export const convertFromCumulativeConditionsIfNeeded = (tiers) => {
    let previousSum = 0;

    return tiers.map((tier) => {
        if (tier.type === "volume" && typeof tier.tier === 'string') {
            // Extract the number from the condition string and convert it to an integer
            const currentTier = parseInt(tier.tier.replace('<=', ''), 10);
            // Calculate the original tier value by subtracting the previous sum
            const originalTier = currentTier - previousSum;
            // Update the previous sum to the current cumulative value for the next iteration
            previousSum = currentTier;
            tier.tier = originalTier;
        }
        return tier;
    });
};