<script>
import { ref, onMounted, watch } from "@vue/composition-api";

import LandingHeader from "src/views/landing-pages/Header.vue";
import LandingFooter from "src/views/landing-pages/Footer.vue";

export default {
  name: "LandingFrame",
  components: {
    LandingHeader,
    LandingFooter,
  },
  setup(props, ctx) {
    const windowHeight = ref(window.innerHeight);
    const transitionName = ref("fade");

    watch(
      () => ctx.root.$route,
      (to, from) => {
        console.log("route changed: from: " + from.path + " to: " + to.path);
        transitionName.value = "fade";
      }
    );
    onMounted(() => { });
    return {
      windowHeight,
      transitionName,
    };
  },
};
</script>
<template>
  <div class="flex flex-col h-screen bg-white overflow-y-auto md:overflow-hidden">
    <landing-header />
    <smooth-scroll v-if="$isDesktopView()">
      <transition :name="transitionName">
        <router-view />
      </transition>
      <landing-footer />
    </smooth-scroll>
    <div v-else class="flex flex-col mt-16">
      <transition :name="transitionName">
        <router-view />
      </transition>
      <landing-footer />
    </div>
  </div>
</template>
