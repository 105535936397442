<script>
import ImagesUploader from "src/views/components/ImagesUploader";
import ListingSettings from "src/views/settings/components/Listing.vue";
import Dropdown from "src/views/dropdowns/View";
import { ref, reactive, onMounted } from "@vue/composition-api";
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';

import { API } from "src/views/settings/api";

export default {
  name: "ListingDetails",
  components: {
    ImagesUploader,
    Dropdown,
    ListingSettings
  },
  props: {
    controls: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, ctx) {
    const openNotification = ctx.root.$openNotification;
    const isEvent = () => {
      return props.form.events && props.form.events.length > 0;
    }
    const loading = ref(false);
    const visible = ref(false);
    const buildingType = ref("residential");
    const spaceNumber = ref(isEvent() ? 20 : 1);
    const spaceType = ref("driveway");
    const parkingTitle = ref(null);
    if (isEvent()) {
      parkingTitle.value = `Event: ${ctx.root.$deepGet(props.form, "events[0].name")}`;
    }
    const settings = reactive({
      amenities: {
        Valet: false,
        Gated: false,
        "Mobile Pass": false,
        "Self-Park": false,
        "Covered": false,
        "Airport Shuttle": false,
        "Guarded": false,
        "Wheelchair Accessible": false,
        "Cameras": false,
        "On-site Staff": false
      },
      vehicle_size: {
        Motorcycle: false,
        Compact: false,
        "Mid-Sized Sedan": false,
        "Large Sedan": false,
        "Oversized(Truck or RV)": false
      }
    });
    const autofill = reactive({
      about_host: {
        is_active: false,
        content: null
      },
      things_your_guests_should_know: {
        is_active: false,
        content: null
      }
    })
    const assignTowing = ref("No");
    const receivePackages = ref(false);
    const aboutHost = ref("");
    const thingsShouldKnow = ref("");
    const nearbyLandmarks = ref("");
    const howDoGuestsEnterInstruction = ref("");
    const imagesUploader = ref(null);
    const imagesDataUrls = ref([]);
    const selectSpaceType = (i, m) => {
      const spaceTypeName = lowerCase(m.name);
      spaceType.value = spaceTypeName;
    };
    const getAllImages = () => {
      imagesDataUrls.value = imagesUploader.value.getAllAddedImagesDataUrls();
      console.log("imagesDataUrls", imagesDataUrls.value);
    };
    const next = () => {
      getAllImages();
      const verified = verifyFields();
      if (verified) {
        // Note: images storage requires indexedDB (big files)
        ctx.root.$setValueForIndexedDB(
          "listing-new-images",
          imagesDataUrls.value
        );
        // Note: normal payload storage uses Vuex Store
        ctx.emit("payload-update", "listing-details", assemblePayloads());
        ctx.emit("next");
      }
    };
    const assemblePayloads = () => {
      // Backend is using snake-case as its framework is Python
      return {
        title: parkingTitle.value,
        building_type: buildingType.value,
        space_type: spaceType.value,
        space_number: spaceNumber.value,
        // Convert Proxy back to Object, use Lodash method - cloneDeep
        amenities: cloneDeep(settings.amenities),
        what_can_you_fit: cloneDeep(settings.vehicle_size),
        assign_towing: assignTowing.value,
        receive_packages: receivePackages.value,
        how_do_guests_enter_instruction: howDoGuestsEnterInstruction.value,
        nearby_landmarks: nearbyLandmarks.value,
        things_your_guests_should_know: thingsShouldKnow.value,
        about_host: aboutHost.value
      };
    };
    const verifyAmenities = () => {
      var verified = true;
      forOwn(settings, (v, k) => {
        var checkedCount = 0;
        forOwn(v, (vv, kk) => {
          if (vv) {
            checkedCount += 1;
          }
        });
        if (checkedCount === 0) {
          openNotification(
            "Parking Space Amenities",
            `At least one item should be checked in ${startCase(k)} category`,
            10,
            "warning"
          );
          verified = false;
          return false;
        }
      });
      return verified;
    };
    const verifyFields = () => {
      // return true;
      if (!verifyAmenities()) {
        return false;
      }

      if (imagesDataUrls.value.length < 1) {
        openNotification(
          "Parking Space Images",
          `At least 1 image is required`,
          10,
          "warning"
        );
        return false;
      }

      if (!howDoGuestsEnterInstruction.value) {
        openNotification(
          "Parking Space Information",
          `"How do guests get to your space" is required field`,
          10,
          "warning"
        );
        return false;
      }

      if (!nearbyLandmarks.value) {
        openNotification(
          "Parking Space Information",
          `"Nearby Landmarks" is required field`,
          10,
          "warning"
        );
        return false;
      }

      if (!autofill.about_host && !aboutHost.value) {
        openNotification(
          "Parking Space Information",
          `"About Yourself" is required field`,
          10,
          "warning"
        );
        return false;
      }

      if (!autofill.things_your_guests_should_know.is_active && !thingsShouldKnow.value) {
        openNotification(
          "Parking Space Information",
          `"Things Guests Should Know" is required field`,
          10,
          "warning"
        );
        return false;
      }

      return true;
    };
    const parseListingSettings = ({ about_host, things_your_guests_should_know }) => {
      if (about_host) {
        autofill.about_host = about_host;
      } else {
        autofill.about_host.is_active = false;
        autofill.about_host.content = null;
      }

      if (things_your_guests_should_know) {
        autofill.things_your_guests_should_know = things_your_guests_should_know;
      } else {
        autofill.things_your_guests_should_know.is_active = false;
        autofill.things_your_guests_should_know.content = null;
      }
    }

    const getListingSettings = async () => {
      loading.value = true;
      try {
        const { data: { listing } } = await API.getListingSettings("host");

        parseListingSettings(listing);

        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getListingSettings();
    });
    return {
      loading,
      visible,
      imagesUploader,
      parkingTitle,
      buildingType,
      isMultiUnit: ref(false),
      spaceNumber,
      spaceType,
      settings,
      autofill,
      assignTowing,
      receivePackages,
      aboutHost,
      thingsShouldKnow,
      nearbyLandmarks,
      howDoGuestsEnterInstruction,
      disableContinue: ref(false),
      next,
      getAllImages,
      selectSpaceType,
      getListingSettings,
      lowerCase,
      startCase
    };
  }
};
</script>

<template>
  <div class="general-parking-listing-details flex flex-col">
    <div class="flex items-center justify-between px-4 py-2 border-b bg-white relative">
      <div />
      <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span class="text-black text-lg font-semibold text-center py-4">
          {{ $t("general.details") }}
        </span>
      </div>
      <button class="px-4 py-2 bg-parqay-primary text-white rounded" @click="next">
        {{ $t("dashboard.continue") }}
      </button>
    </div>
    <div class="flex">
      <div class="w-3/5 border-r py-4">
        <div class="grid grid-cols-3 gap-4 px-6 pt-4 pb-6">
          <div class="flex flex-col space-y-2 col-span-2">
            <span class="text-base font-medium">Listing Title</span>
            <a-input v-model="parkingTitle" allow-clear size="large" placeholder="Ex: Garage Near Main St." />
          </div>
          <div class="flex flex-col items-center">
            <label class="text-base font-medium">
              {{ $t("general.type") }}
            </label>
            <div class="my-3">
              <a-radio-group v-model="buildingType" button-style="solid">
                <a-radio-button v-for="(option, i) in $t('general.type1', {
                  returnObjects: true
                })" :key="`building-type-${i}`" :value="lowerCase(option)">
                  {{ option }}
                </a-radio-button>
              </a-radio-group>
            </div>
          </div>
          <div class="flex flex-col items-center space-y-2">
            <span class="text-base font-medium">
              {{ $t("general.information") }}
            </span>
            <span>{{ $t("general.kind") }}</span>
            <dropdown class="w-full" :model="$t('general.kind1', { returnObjects: true })"
              @dropdown-select="selectSpaceType" />
          </div>
          <div class="flex flex-col items-center space-y-2">
            <span class="text-base font-medium"> Number of Spaces </span>
            <span>{{ $t("general.many") }}</span>
            <a-input-number v-model="spaceNumber" size="large" />
          </div>
        </div>
        <div class="px-6 py-4 border-t">
          <h2 class="text-base font-medium text-center">
            {{ $t("general.space") }}
          </h2>
          <div class="flex flex-col">
            <span class="text-base font-normal text-center mt-0">Tell us more about your space</span>
            <div class="grid grid-cols-3 gap-4 mt-4">
              <div v-for="(amenityOptions, amenityName, i) in settings" :key="`amenity-group-${i}`"
                class="flex flex-col items-center space-y-2">
                <span class="text-base font-medium">{{
                  startCase(amenityName)
                }}</span>
                <div class="flex flex-col space-y-2">
                  <a-checkbox v-for="(v, k, j) in amenityOptions" :key="`amenity-option-${j}`"
                    v-model="amenityOptions[k]">
                    {{ k }}
                  </a-checkbox>
                </div>
              </div>
              <div class="flex flex-col space-y-2">
                <span>Connect your gates to our partners (Optional)</span>
                <a-dropdown>
                  <a-button class="flex justify-center items-center" @click="e => e.preventDefault()">
                    Connect
                    <a-icon type="login" />
                  </a-button>
                  Connect
                  <a-menu slot="overlay" @click="() => { }">
                    <a-menu-item key="1">
                      <a-icon type="link" />Ticketech
                    </a-menu-item>
                    <a-menu-item key="2">
                      <a-icon type="link" />Skidata
                    </a-menu-item>
                    <a-menu-item key="3">
                      <a-icon type="link" />SmartGarage
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
              <div class="flex flex-col space-y-2">
                <span>{{ $t("dashboard.towing") }}</span>
                <a-radio-group v-model="assignTowing" button-style="solid">
                  <a-radio-button value="Yes">
                    Yes
                  </a-radio-button>
                  <a-radio-button value="No">
                    No
                  </a-radio-button>
                  <a-radio-button value="Use My Own">
                    Use My Own
                  </a-radio-button>
                </a-radio-group>
              </div>
              <div class="flex flex-col space-y-2">
                <span>{{ $t("dashboard.packages") }}</span>
                <a-radio-group v-model="receivePackages" button-style="solid">
                  <a-radio-button :value="true">
                    Yes
                  </a-radio-button>
                  <a-radio-button :value="false">
                    No
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="px-6 py-4 border-t flex items-start justify-center space-x-4" style="min-height: 150px;">
          <a-icon v-if="loading" type="loading" style="font-size: 35px;" />
          <template v-else>
            <label-item label="About Yourself"
              desc="Inform your tenants about yourself as a host. This setting populates as &quot;About Host&quot; section in listing search result."
              class="w-1/2">
              <div class="flex items-center space-x-2 cursor-pointer" slot="actions" @click.stop="() => visible = true">
                <div class="w-2 h-2 rounded-full"
                  :class="autofill.about_host.is_active ? 'bg-green-400' : 'bg-pink-500'" />
                <span class="text-xs font-semibold"
                  :class="autofill.about_host.is_active ? 'text-green-400' : ''">Autofill</span>
                <a-button type="link" icon="edit" />
              </div>
              <div v-if="autofill.about_host.is_active"
                class="border border-gray-300 rounded px-4 py-2 text-sm font-medium">
                <span class="leading-6">
                  {{ autofill.about_host.content }}
                </span>
                <a-button type="link" icon="edit" size="small" @click.stop="() => visible = true">Edit</a-button>
              </div>
              <a-textarea v-else v-model="aboutHost" placeholder="Tell tenants more about yourself as a host."
                :auto-size="{ minRows: 4, maxRows: 4 }" />
            </label-item>
            <label-item label="Things your guests should know"
              desc="Enter any essential information for tenants. This setting shows in &quot;Things your guests should know&quot; section in listing search result."
              class="w-1/2">
              <div class="flex items-center space-x-2 cursor-pointer" slot="actions" @click.stop="() => visible = true">
                <div class="w-2 h-2 rounded-full"
                  :class="autofill.things_your_guests_should_know.is_active ? 'bg-green-400' : 'bg-pink-500'" />
                <span class="text-xs font-semibold"
                  :class="autofill.things_your_guests_should_know.is_active ? 'text-green-400' : ''">Autofill</span>
                <a-button type="link" icon="edit" />
              </div>
              <div v-if="autofill.things_your_guests_should_know.is_active"
                class="border border-gray-300 rounded px-4 py-2 text-sm font-medium">
                <span class="leading-6">
                  {{ autofill.things_your_guests_should_know.content }}
                </span>
                <a-button type="link" icon="edit" size="small" @click.stop="() => visible = true">Edit</a-button>
              </div>
              <a-textarea v-else v-model="thingsShouldKnow"
                placeholder="Enter any info your tenants need to pay special attention."
                :auto-size="{ minRows: 4, maxRows: 4 }" />
            </label-item>
          </template>
        </div>
        <a-modal v-model="visible" title="Autofill Settings" width="1000px" destroy-on-close :footer="null"
          @cancel="() => visible = false">
          <listing-settings no-title @refresh="() => {
            visible = false;
            getListingSettings();
          }" />
        </a-modal>
        <div class="px-6 py-4 border-t flex space-x-4">
          <div class="flex flex-col w-1/2 space-y-2">
            <span class="text-base font-medium">{{ $t("dashboard.how") }}</span>
            <a-textarea v-model="howDoGuestsEnterInstruction"
              placeholder="This will show up in “Getting There” section in parking search. Enter any specific guidance to assistant guests enter this place"
              :auto-size="{ minRows: 6, maxRows: 6 }" />
          </div>
          <div class="flex flex-col w-1/2 space-y-2">
            <span class="text-base font-medium">Nearby Landmarks</span>
            <a-textarea v-model="nearbyLandmarks" placeholder="Provide descriptions for nearby landmarks"
              :auto-size="{ minRows: 6, maxRows: 6 }" />
          </div>
        </div>
      </div>
      <div class="w-2/5 flex flex-col p-4 mx-4 my-2 shadow-sm border bg-white rounded-lg">
        <h2 class="mb-2 text-center text-base font-medium py-4">
          {{ $t("listing.photo") }}
        </h2>
        <p class="text-center">
          {{ $t("listing.photoInfo") }}
        </p>
        <div class="flex-grow-0 justify-center items-center">
          <keep-alive>
            <images-uploader ref="imagesUploader" />
          </keep-alive>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end px-4 py-2 border-t bg-white" style="margin-bottom: 100px;">
      <button class="px-4 py-2 bg-parqay-primary text-white rounded" @click="next">
        {{ $t("dashboard.continue") }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.general-parking-listing-details ::v-deep .ant-input-number {
  width: 200px;
}

.general-parking-listing-details ::v-deep .ant-input-number-input {
  text-align: center;
}

.general-parking-listing-details ::v-deep .ant-checkbox-wrapper {
  margin-left: 0;
}
</style>
