<script>
import { reactive, onMounted } from "@vue/composition-api";
import { API } from "./api";

export default {
  name: "contact-sales",
  setup(props, ctx) {
    const form = reactive({
      first_name: null,
      last_name: null,
      company: null,
      email: null,
      country: "US",
      phone_number: null,
      message: null,
    });
    const controls = reactive({
      loading: false,
      visible: {
        submitButton: true,
      },
      disabled: {
        previewButton: true,
      },
      responseStatus: "info",
      responseMessage: null,
      agreePolicy: false,
    });
    const contactSale = async () => {
      let resp;
      controls.loading = true;
      try {
        resp = await API.contactSales(form);
        controls.responseStatus = "info";
        controls.responseMessage = resp.message;
        controls.visible.submitButton = false;
        setTimeout(() => {
          // ctx.emit("refresh-form");
          controls.disabled.previewButton = false;
        }, 3000);
      } catch (error) {
        controls.responseStatus = "error";
        controls.responseMessage = error.message;
      } finally {
        controls.loading = false;
      }
    };
    onMounted(() => { });
    return {
      form,
      controls,
      contactSale,
      window
    };
  },
};
</script>
<template>
  <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div class="relative max-w-xl mx-auto">
      <svg class="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none"
        viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20"
            patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <svg class="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none"
        viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20"
            patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <div class="text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Contact sales
        </h2>
        <p class="mt-4 text-lg leading-6 text-gray-500">
          Please fill in the information below and select a time that works for you.
        </p>
        <a-divider>Live Preview</a-divider>
        <div class="flex flex-col space-y-2">
          <p class="text-base leading-6 text-gray-500">The contact form below is required before starting dashboard
            live preview, you may ask our sales for a redeem code to login to our demo system after submitting the form
          </p>
          <button v-if="!controls.disabled.previewButton"
            class="flex items-center justify-between px-3 py-2 border hover:border-2 hover:border-blue-500 rounded-lg"
            @click="window.open('/demo', '_blank')">
            <div class="h-20 w-36 rounded-lg bg-gray-100 border overflow-hidden">
              <img src="https://parqay-assets.s3.us-west-2.amazonaws.com/dashboard/dashboard-thumbnail.png"
                class="object-cover">
            </div>
            <div class="flex items-center justify-center space-x-2">
              <span class="text-base font-semibold text-blue-500">Start Preview</span>
              <svg-icon icon-class="arrow-circle-right" class="w-8 h-8 text-blue-500" />
            </div>
          </button>
          <button v-else class="flex items-center justify-between px-3 py-2 border rounded-lg focus:outline-none">
            <div class="h-20 w-36 rounded-lg bg-gray-100 opacity-75 border overflow-hidden">
              <img src="https://parqay-assets.s3.us-west-2.amazonaws.com/dashboard/dashboard-thumbnail.png"
                class="object-cover">
            </div>
            <div class="flex items-center justify-center space-x-2">
              <span class="text-base font-semibold text-gray-400">Start Preview</span>
              <svg-icon icon-class="arrow-circle-right" class="w-8 h-8 text-gray-400" />
            </div>
          </button>
        </div>
      </div>
      <div class="mt-12">
        <a-divider>Form</a-divider>
        <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div>
            <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>
            <div class="mt-1">
              <input id="first_name" type="text" name="first_name" autocomplete="given-name"
                class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                v-model="form.first_name" />
            </div>
          </div>
          <div>
            <label for="last_name" class="block text-sm font-medium text-gray-700">Last name</label>
            <div class="mt-1">
              <input id="last_name" type="text" name="last_name" autocomplete="family-name"
                class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                v-model="form.last_name" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="company" class="block text-sm font-medium text-gray-700">Company</label>
            <div class="mt-1">
              <input id="company" type="text" name="company" autocomplete="organization"
                class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                v-model="form.company" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <div class="mt-1">
              <input id="email" name="email" type="email" autocomplete="email"
                class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                v-model="form.email" />
            </div>
          </div>
          <div class="sm:col-span-2 space-y-1">
            <label for="phone_number" class="block text-sm font-medium text-gray-700">Phone Number</label>
            <div class="rounded-md shadow-sm flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-8">
              <country-select v-model="form.country" :country="form.country" topCountry="US"
                class-name="w-full md:w-56" />
              <input id="phone_number" type="text" name="phone_number" autocomplete="tel"
                class="py-3 px-4 block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                placeholder="e.g. (415) 688-2011" v-model="form.phone_number" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="message" class="block text-sm font-medium text-gray-700">Message</label>
            <div class="mt-1">
              <textarea id="message" name="message" rows="4"
                class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                v-model="form.message" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <a-switch v-model="controls.agreePolicy" />
              </div>
              <div class="ml-3">
                <p class="text-base text-gray-500">
                  By selecting this, you agree to the
                  <a href="https://parqyt.com/privacy-policy" class="font-medium text-gray-700 underline">Privacy
                    Policy</a>
                  and
                  <a href="#" class="font-medium text-gray-700 underline">Cookie Policy</a>.
                </p>
              </div>
            </div>
          </div>
          <div class="sm:col-span-2 space-y-4">
            <a-alert v-if="controls.responseMessage" :message="controls.responseMessage" :type="controls.responseStatus"
              closable @close="() => (controls.responseMessage = null)" />
            <a-button v-if="controls.visible.submitButton" type="primary" size="large" :loading="controls.loading"
              :disabled="!controls.agreePolicy"
              class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-parqay-primary hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="contactSale()">
              Let's talk
            </a-button>
            <a-button v-else type="primary" size="large" block @click="() => window.open('/demo', '_blank')">
              Start Preview
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
