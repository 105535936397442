<script>
import {
  reactive,
  onMounted,
  onBeforeUnmount,
} from "@vue/composition-api";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import StepFinal from "./components/StepFinal";

import isEmpty from 'lodash/isEmpty';
import { Modal } from 'ant-design-vue';



export default {
  name: "sign-up-host",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    StepFinal,
  },
  setup(props, ctx) {

    const controls = reactive({
      currentStep: -1, // This value must be -1, otherwise reverse anchor routing will not work
      stepModel: ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"]
    })

    const getAnchorName = (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return "choose-occupation";
        case 1:
          return "verify-email";
        case 2:
          return "verify-sms";
        case 3:
          return "wrap-up";
        case 4:
          return "choose-plan";
      }
    };

    const getAnchorIndex = () => {
      // const hash = location.hash;
      // console.log("hash >>> " + hash);
      // if (hash) {
      //   switch (hash.replace("#", "")) {
      //     case "choose-occupation":
      //       return 0;
      //     case "verify-email":
      //       return 1;
      //     case "verify-sms":
      //       return 2;
      //     case "wrap-up":
      //       return 3;
      //     case "choose-plan":
      //       return 4;
      //   }
      // }
      // return 0;
      return ctx.root.$store.state.signUpStepIndex;
    };

    const nextStep = () => {
      controls.currentStep += 1;
    };

    const prevStep = () => {
      controls.currentStep -= 1;
    };

    const payloadUpdate = (pk, pd, stepIndex) => {
      console.log("payloadUpdate: ", pk, pd, stepIndex);
      ctx.root.$store.commit("updateSignUpStep", stepIndex);
      ctx.root.$store.commit("updateSignUpForm", [pk, pd]);
    };

    const clearPayload = () => {
      ctx.root.$store.commit("resetSignUpForm");
    };

    const showInProgressDialog = () => {
      Modal.confirm({
        title: () => 'In-progress Sign Up Detected',
        icon: "info-circle",
        content: "We have found a sign up form in progress, do you wish to continue?",
        okText: "Continue",
        cancelText: "Start New",
        onOk() {
          console.log('continue sign-up');
          controls.currentStep = getAnchorIndex();
        },
        onCancel() {
          console.log('start new sign-up');
          clearPayload();
          controls.currentStep = 0;
        }
      });
    };

    onMounted(() => {
      controls.currentStep = 0;
      if (!isEmpty(ctx.root.$store.state.signUpForm)) {
        showInProgressDialog();
      }
    });

    onBeforeUnmount(() => {
      // clearPayload();
    });

    return {
      controls,
      getAnchorName,
      getAnchorIndex,
      nextStep,
      prevStep,
      payloadUpdate
    };
  },
};
</script>
<template>
  <div class="relative h-screen bg-white flex flex-col md:flex-row items-center overflow-y-auto">
    <div class="relative md:hidden w-full">
      <img class="object-cover h-52 w-full" src="/static/img/dashboard/dashboard-sign-up-bg.png" alt="" />
      <div class="absolute top-5 right-3">
        <div class="flex px-2 py-1 bg-white hover:shadow-md rounded-full border shadow-sm cursor-pointer">
          <router-link tag="a" to="/" class="flex justify-center items-center pointer-events-auto">
            <svg-icon icon-class="PARQAY-logo" class="h-8 w-36" />
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col py-6 md:py-8 xl:py-36
          px-5 md:px-6 justify-center items-center xl:justify-start border-r md:h-full md:w-1/3 md:max-w-xl no-scrollbar md:overflow-y-auto">
      <div class="pt-3 md:pt-6 mx-auto w-full max-w-sm overflow-auto max-h-screen no-scrollbar">
        <!-- Logo -->

        <div class="hidden md:flex flex-col py-4 pr-2">
          <div class="flex items-center justify-between space-x-2">
            <router-link tag="a" to="/" class="flex justify-center items-center w-2/3">
              <svg-icon icon-class="PARQAY-logo" class="inline-flex w-full h-16" />
            </router-link>
            <div class="inline-flex">
              <!--<div
                class="flex rounded-full bg-parqay-primary px-4 py-1 items-center justify-center my-auto"
              >
                <span class="text-white font-medium">Dashboard</span> 
              </div>-->
            </div>
          </div>
        </div>

        <!-- Titles -->

        <div class="flex flex-col space-y-1 pb-3 md:py-4 md:mt-3">
          <h4 class="text-2xl font-bold text-center">
            Sign Up
          </h4>
          <span class="text-base font-semibold text-blue-700 text-center max-w">
            Complete the steps below to become a host
          </span>
        </div>

        <div class="flex flex-col mt-4">
          <!-- Step Indicator -->
          <nav aria-label="Progress">
            <ol class="flex items-center justify-center">
              <li v-for="(step, i) in controls.stepModel" :key="`signup-step-${i}`" class="relative" :class="{
                'pr-12': i < controls.stepModel.length - 1,
              }">
                <template v-if="i < controls.currentStep">
                  <!-- Completed Step -->
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full bg-parqay-primary" />
                  </div>
                  <a href="#"
                    class="relative w-8 h-8 flex items-center justify-center bg-parqay-primary rounded-full hover:bg-blue-500">
                    <svg-icon icon-class="solid-check" class="w-5 h-5 text-white" />
                    <span class="sr-only">{{ step }}</span>
                  </a>
                </template>

                <template v-if="i == controls.currentStep">
                  <!-- Current Step -->
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full bg-gray-200" />
                  </div>
                  <a href="#"
                    class="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-parqay-primary rounded-full"
                    aria-current="step">
                    <span class="h-2.5 w-2.5 bg-parqay-primary rounded-full" aria-hidden="true" />
                    <span class="sr-only">{{ step }}</span>
                  </a>
                </template>

                <template v-if="i > controls.currentStep">
                  <!-- Upcoming Step -->
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full bg-gray-200" />
                  </div>
                  <a href="#"
                    class="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
                    <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" aria-hidden="true" />
                    <span class="sr-only">{{ step }}</span>
                  </a>
                </template>
              </li>
            </ol>
          </nav>

          <!-- Steps Card -->
          <step-1 v-if="controls.currentStep == 0" :hash-route="getAnchorName(0)"
            @payload-update="(pk, pd) => payloadUpdate(pk, pd,  1)" @next="nextStep" />
          <step-2 v-if="controls.currentStep == 1" :hash-route="getAnchorName(1)" @next="nextStep" @prev="prevStep"
            @payload-update="(pk, pd) => payloadUpdate(pk, pd,  2)" />
          <step-3 v-if="controls.currentStep == 2" :hash-route="getAnchorName(2)" @next="nextStep" @prev="prevStep"
            @payload-update="(pk, pd) => payloadUpdate(pk, pd,  3)" />
          <step-4 v-if="controls.currentStep == 3" :hash-route="getAnchorName(3)" @next="nextStep" @prev="prevStep"
            @payload-update="(pk, pd) => payloadUpdate(pk, pd,  4)" />
          <step-5 v-if="controls.currentStep == 4" :hash-route="getAnchorName(4)" @next="nextStep" @prev="prevStep"
            @payload-update="(pk, pd) => payloadUpdate(pk, pd,  5)" />
          <step-final v-if="controls.currentStep == 5" @prev="prevStep" />

          <div class="flex px-4 py-4 mb-20 md:mb-auto">
            <!-- Login Switch -->
            <span class="text-center text-base font-medium">Already have an account?<router-link tag="a" to="/login"
                class="mx-2 text-parqay-primary">Sign in here</router-link></span>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:block relative w-9/12 flex-auto bg-login-img-bg overflow-hidden">
      <div data-relative-input="true" class="h-screen w-full" data-limit-x="30" data-limit-y="30">
        <div class="layer absolute" style="left: -30px; top: -30px; height: 105%; width: 110%" data-depth="0.4">
          <img class="flex object-cover w-full h-full" src="/static/img/dashboard/dashboard-sign-up-bg.png" alt="">
        </div>
        <div class="layer w-full h-full" data-depth="0.3">
          <div class="absolute w-72 left-auto right-6 bottom-6 top-auto">
            <div class="flex px-4 py-2 bg-white rounded-xl border shadow-sm">
              <img class="h-16 w-auto object-contain" src="/static/img/dashboard/dashboard-landing-ads.png" alt="">
            </div>
          </div>
        </div>
        <div class="layer w-full h-full" data-depth="0.2">
          <div class="absolute top-6 right-6">
            <div class="flex px-2 py-1 bg-white hover:shadow-md rounded-full border shadow-sm cursor-pointer">
              <router-link tag="a" to="/" class="flex justify-center items-center pointer-events-auto">
                <svg-icon icon-class="PARQAY-logo" class="h-8 w-36" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.no-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
</style>