<script>
import { onMounted, watch } from "@vue/composition-api";
import ValidationSectionHeader from "src/views/reservations/components/ValidationSectionHeader";
import DocumentsForm from "./Documents.vue";
import TransactionForm from "./Transaction.vue";
import ListingForm from "./Listing.vue";
import TypeForm from "./Type.vue";
import IntervalsForm from "./Intervals.vue";
import HostForm from "./Host.vue";
import VehiclesForm from "./Vehicles.vue";
import { API } from "src/views/reservations/api";
import isEmpty from 'lodash/isEmpty';

export default {
  name: "BaseDetails",
  components: {
    ValidationSectionHeader,
    DocumentsForm,
    TransactionForm,
    ListingForm,
    TypeForm,
    IntervalsForm,
    HostForm,
    VehiclesForm
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    controls: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    watch(
      () => props.form.title,
      val => {
        console.log("props.form.title >> ", val);
        var updatedTitle;
        if (isEmpty(val)) {
          updatedTitle = "New Reservation";
        } else {
          updatedTitle = val;
        }
        store.commit("bulkUpdateScheduleToAddList", ["title", updatedTitle]);
      }
    );
    const resetReservationType = () => {
      props.form.type = null;
    };
    const scrollToSection = anchor => {
      document.getElementById(anchor).scrollIntoView({ behavior: "smooth" });
    };
    const getFirstLetter = (text, defaultLetter = "") => {
      if (!text || text.length <= 0) {
        return defaultLetter;
      }
      return text[0].toUpperCase();
    };
    const validateFields = () => {
      let _errors = [];
      // if (!ctx.root.$deepGet(props.form, "vehicle.id")) {
      //   _errors.push({
      //     type: "booking",
      //     message: "Vehicle details must be entered"
      //   });
      // }
      if (!ctx.root.$deepGet(props.form, "vehicle.smartcar")) {
        if (ctx.root.$deepGet(props.form, "smartcar.prompt_tenant")) {
          if (ctx.root.$deepGet(props.form, "smartcar.unavailable")) {
            if (!ctx.root.$deepGet(props.form, "smartcar.unavailable_reason")) {
              _errors.push({
                type: "booking",
                message: "Smartcar unavailable reason is required"
              });
            }
          } else {
            _errors.push({
              type: "booking",
              message: "Smartcar is not connected"
            });
          }
        }
      }
      if (_errors.length > 1) {
        const errorsCount = _errors.length;
        _errors.push({
          type: "header",
          message: `Multiple Errors (${errorsCount})`
        });
      } else if (_errors.length == 1) {
        _errors.push({
          type: "header",
          message: _errors[0].message
        });
      }
      props.controls.errors = _errors;
    };
    const completeReservation = async () => {
      validateFields();
      if (!isEmpty(props.controls.errors)) {
        return;
      }
      ctx.emit("charge");
    };
    const sendEmailReminder = async () => {
      props.controls.loading.pendingReminder = true;
      let resp;
      try {
        resp = await API.sendEmailReminder(props.form.id);
      } catch (error) {
        console.log("sendEmailReminder error: ", error);
        props.controls.loading.pendingReminder = false;
        return;
      }
      console.log("sendEmailReminder resp: ", resp.message);
      props.controls.loading.pendingReminder = false;
      ctx.root.$openNotification(
        `Email reminder has been sent to: ${ctx.root.$deepGet(
          props.form,
          "tenant.email"
        )}`,
        "",
        5,
        "info"
      );
    };
    onMounted(() => {
      if (ctx.root.$route.hash === '#pay') {
        scrollToSection('transaction-form');
        ctx.root.$openPaymentDrawer('tenant');
      }
    });
    return {
      scrollToSection,
      resetReservationType,
      getFirstLetter,
      completeReservation,
      sendEmailReminder,
      validateFields,
      console,
      isEmpty
    };
  }
};
</script>
<template>
  <div class="reservation-base-details bg-white shadow overflow-scroll sm:rounded-lg divide-y-2 divide-parqay-primary">
    <div class="px-4 pt-2 lg:pt-5 pb-2 sm:px-6 flex justify-between items-center">
      <div class="flex items-start space-x-4">
        <div>
          <button class="
              inline-flex
              items-center
              p-2
              border border-transparent
              rounded-full
              shadow-sm
              text-white
              bg-parqay-primary
              hover:bg-blue-500
              focus:outline-none
            " @click="$router.go(-1)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
        </div>
        <div v-if="$isDesktopView()">
          <h3 class="text-lg leading-6 font-medium text-gray-900 m-2">
            Reservation Details
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            View reservation and manage your space
          </p>
        </div>
      </div>
      <div class="reservation-actions flex space-x-4 items-center">
        <div>
          <validation-section-header :validation-errors="controls.errors.filter(e => e.type == 'header')" />
        </div>
        <a-tag v-if="form.status === 'pending' && $isDesktopView()" color="orange">
          <div class="flex items-center">
            <span class="text-black font-medium pr-2">Status: </span>
            <span class="px-2 border-r">Pending</span>
            <a-button type="primary" class="ml-2" :loading="controls.loading.pendingReminder" @click="() => {
              if (!$deepGet(form, 'transaction.payment_method')) {
                scrollToSection('transaction-form');
                $openPaymentDrawer('tenant');
                return;
              }
              completeReservation();
            }
              ">
              Complete Reservation
            </a-button>
          </div>
        </a-tag>
        <a-tag v-if="form.status === 'complete'" color="green">
          Complete
        </a-tag>
        <a-tag v-if="$deepGet(form, 'status') === 'canceled'" color="pink">
          Canceled
        </a-tag>
        <a-button v-if="form.status === 'pending' && !$isDesktopView()" type="primary" size="large"
          :loading="controls.loading.pendingReminder" @click="() => {
              if (!$deepGet(form, 'transaction.payment_method')) {
                scrollToSection('transaction-form');
                $openPaymentDrawer('tenant');
                return;
              }
              completeReservation();
            }
              ">
          Complete Reservation
        </a-button>
        <a-popover v-else title="QR Code" trigger="click">
          <template #content>
            <p class="text-center font-medium pb-2">{{ form.title }}</p>
            <qr-code :text="form.id"></qr-code>
          </template>
          <button
            class="px-4 py-2 border text-md font-medium rounded-md shadow-sm text-parqay-primary bg-white border-parqay-primary hover:bg-gray-100 focus:outline-none">
            QR Code
          </button>
        </a-popover>
      </div>
    </div>
    <div class="space-y-4 lg:space-y-16 overflow-y-auto" style="max-height: 95vh;">
      <div class="grid grid-cols-2 lg:grid-cols-4 mx-8 my-8 gap-4">
        <div class="flex space-x-2 items-center">
          <div class="h-12 w-12 rounded bg-parqay-primary cursor-pointer" @click="scrollToSection('listings')">
            <h1 class="text-white text-5xl h-full w-full text-center m-0">
              {{ getFirstLetter(form.title, "R") }}
            </h1>
          </div>

          <div v-if="controls.editMode" class="flex flex-col space-y2">
            <a-popover v-if="controls.editingTitle" :visible="!isEmpty(controls.errors.filter(e => e.type == 'title'))
              " placement="bottom">
              <template #content>
                <span>Please enter the reservation title</span>
              </template>
              <span>Reservation Title</span>
              <a-input v-model="form.title" type="large" placeholder="Ex. Evanston N Garage" class="text-sm">
                <template #suffix>
                  <button v-show="form.title"
                    class="text-xs px-2 py-1 rounded bg-parqay-primary text-white hover:bg-blue-500"
                    @click="() => (controls.editingTitle = false)">
                    Save
                  </button>
                </template>
              </a-input>
            </a-popover>
            <div v-else class="flex space-x-2">
              <span class="text-base font-medium text-parqay-primary text-center">{{
              form.title
            }}</span>
              <button class="text-xs px-2 py-1 rounded bg-parqay-primary text-white hover:bg-blue-500"
                @click="() => (controls.editingTitle = true)">
                Edit
              </button>
            </div>
          </div>
          <span v-else class="text-base font-medium text-center">{{
              $deepGet(form, "title", "Loading...")
            }}
          </span>
        </div>
        <div class="flex flex-1 space-x-2">
          <div class="h-12 w-12 rounded bg-parqay-primary my-auto cursor-pointer" @click="scrollToSection('host')">
            <img class="h-full w-full object-contain" src="/static/img/dashboard/db-r-parking-details-contact.svg"
              alt="" />
          </div>
          <div class="my-auto w-28">
            <a class="m-0 h-full text-base font-medium" @click="scrollToSection('host')">{{ $deepGet(form, "host.name",
              "N/A") }}</a>
          </div>
        </div>
        <!-- Undetermined Type State -->
        <div v-if="!form.type" class="flex space-x-2 rounded-xl border px-2 py-3 cursor-pointer">
          <svg-icon icon-class="circular-loader" class="h-8 w-8 rounded my-auto text-parqay-primary animate-spin" />
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium hover:text-parqay-primary">
              Loading...
            </p>
          </div>
        </div>
        <!-- Start - Parking Type Indicator -->
        <div v-if="form.type == 'parking'" class="flex space-x-2 relative">
          <svg-icon icon-class="parking-type" class="h-12 w-12 rounded my-auto" />
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium">
              Parking Reservation
            </p>
          </div>
        </div>
        <!-- End - Parking Type Indicator -->
        <!-- Start - Stays Type Indicator -->
        <div v-if="form.type == 'stay'" class="flex space-x-2 relative">
          <svg-icon icon-class="stay-type" class="h-12 w-12 rounded my-auto" />
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium">
              Stays Reservation
            </p>
          </div>
        </div>
        <!-- End - Stays Type Indicator -->
        <!-- Start - Coworking Type Indicator -->
        <div v-if="form.type == 'coworking'" class="flex space-x-2 relative">
          <div class="border-parqay-primary rounded-lg w-12 h-12 flex" style="border-width: 3px">
            <svg-icon icon-class="coworking-type" class="h-auto w-auto m-2 text-black" />
          </div>
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium">
              Coworking Reservation
            </p>
          </div>
        </div>
        <!-- End - Coworking Type Indicator -->
        <!-- Start - Car Rentals Type Indicator -->
        <div v-if="form.type == 'car-rental'" class="flex space-x-2 relative">
          <svg-icon icon-class="car-rental-type" class="h-12 w-12 rounded my-auto" />
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium">
              Car Rentals Reservation
            </p>
          </div>
        </div>
        <!-- End - Car Rentals Type Indicator -->
        <div v-if="$isDesktopView()" class="flex flex-col space-y-2 items-center my-auto">
          <div class="my-auto">
            <p class="m-0 font-bold">
              Confirmation #
            </p>
          </div>
          <div class="my-auto font-medium text-parqay-primary">
            <p class="m-0">
              {{ $deepGet(form, "id", "Loading...") }}
            </p>
          </div>
        </div>
        <type-form :form="form" :controls="controls" />
      </div>

      <div class="bg-white shadow overflow-hidden sm:rounded-lg mx-8 pb-4">
        <div class="flex justify-between items-center bg-white px-4 py-2 space-x-2 border-b border-gray-200 sm:px-6">
          <h3 class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap">
            Booking Details
          </h3>
          <validation-section-header :validation-errors="controls.errors.filter(e => e.type == 'booking')
              " />
        </div>
        <div>
          <intervals-form :form="form" :controls="controls" />
          <div class="space-y-4 sm:space-y-5">

            <!-- BOOKING - GUESTS SLOT -->
            <slot name="guests" />

            <!-- BOOKING - DRIVERS SLOT -->
            <slot name="drivers" />

            <!-- BOOKING - START OF TRANSACTION SECTION -->
            <transaction-form :form="form" :controls="controls"
              class="sm:grid sm:grid-cols-6 sm:gap-4 border-t sm:items-start sm:border-gray-200 sm:pt-5 px-5" />
            <!-- BOOKING - END OF TRANSACTION SECTION -->

            <!-- BOOKING - START OF Event SECTION -->
            <div v-if="form.event"
              class="sm:grid sm:grid-cols-6 sm:gap-4 border-t sm:items-start sm:border-gray-200 sm:pt-5 px-5 py-2">
              <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Associated Event
              </p>
              <div class="mt-1 sm:mt-0 sm:col-span-5">
                <a-tag class="py-2 text-xs font-medium" color="geekblue">
                  {{
              form.event
            }}
                </a-tag>
              </div>
            </div>
            <!-- BOOKING - END OF Event SECTION -->

            <!-- BOOKING - START OF VEHICLE SECTION -->
            <vehicles-form v-if="form.type == 'parking'" :form="form" :controls="controls" />
            <!-- BOOKING - END OF VEHICLE SECTION -->

            <!-- BOOKING - START OF CREATED AT SECTION -->
            <div class="flex items-center justify-between sm:justify-start space-x-4 border-t border-gray-200 px-5 pt-4">
              <span class="text-sm font-medium text-gray-700">
                Created At
              </span>
              <div class="">
                <a-tag class="py-1.5 text-xs font-medium">
                  {{
              $deepGet(form, "created_at.$date") ? $formatDate(form.created_at.$date, "YYYY-MM-DD h:mm a") : "N/A"
            }}
                </a-tag>
              </div>
            </div>
            <!-- BOOKING - END OF CREATED AT SECTION -->
          </div>
        </div>
      </div>

      <!-- START OF LISTING DETAILS SECTION -->
      <listing-form :form="form" :controls="controls" />
      <!-- END OF LISTING DETAILS SECTION -->

      <!-- START OF HOST DETAILS SECTION -->
      <host-form :form="form" :controls="controls" />
      <!-- END OF HOST DETAILS SECTION -->

      <!-- START OF VEHICLE DETAILS SECTION -->
      <slot name="vehicle-details" />
      <!-- END OF VEHICLE DETAILS SECTION -->

      <documents-form v-if="$isDesktopView()" :form="form" :controls="controls" />

      <div class="h-24" />
    </div>
    <payment-method-drawer :form="form" />
  </div>
</template>
<style lang="scss" scoped>
.reservation-base-details ::v-deep {
  .ant-input {
    @apply flex items-center;
  }

  .ant-calendar-range-picker-separator {
    // line-height: 1.5rem;
    // font-size: 1.5rem;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-mask-image: url("/static/img/Icons/arrow-circle-right.svg");
    mask-image: url("/static/img/Icons/arrow-circle-right.svg");
    @apply bg-parqay-primary;
  }
}
</style>

<style lang="scss" scoped>
.reservation-actions::v-deep {
  .ant-tag {
    @apply py-2 px-5 text-sm;
  }
}
</style>
