<script>
import {
  ref,
  onMounted,
} from "@vue/composition-api";
import EventCard from "src/views/components/components/EventCard.vue";
import PQSearchBar from "src/views/components/PQSearchBar.vue";
import { API } from "src/views/components/api";

export default {
  components: {
    EventCard,
    "pq-search-bar": PQSearchBar,
  },
  props: {
    controls: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const events = ref([]);
    const isSearchStalled = ref(false);
    const toggleEvent = (event) => {
      console.log("toggleEvent", event);
      var _selected = false;
      props.form.events.forEach((selectedEvent, i) => {
        if (event.id && selectedEvent.id) {
          if (event.id == selectedEvent.id) {
            _selected = true;
            props.form.events.splice(i, 1);
          }
        }
      });
      if (!_selected) {
        props.form.events.push(event);
      }
    };

    const updateKeyword = async (keyword, loading) => {
      try {
        isSearchStalled.value = true;
        const { data } = await API.searchEvents(keyword);
        events.value = data.map(event => ({
          ...event,
          price: 50.0,
          adjustments: []
        }));
        isSearchStalled.value = false;
        loading.value = false;
      } catch (error) {
        isSearchStalled.value = false;
        loading.value = false;
      }
    };
    onMounted(() => {
      isSearchStalled.value = true;
      setTimeout(() => {
        isSearchStalled.value = false;
      }, 1500);
    });
    return {
      events,
      isSearchStalled,
      updateKeyword,
      toggleEvent,
    };
  },
};
</script>
<template>
  <div class="flex justify-center pb-4">
    <div class="bg-white flex flex-col space-y-4 w-full">
      <pq-search-bar placeholder="Search event by name, type, or address" class="h-24 px-12 py-4 xl:w-1/2 xl:mx-auto"
        @keywords-update="updateKeyword" />
      <div class="flex relative">
        <smooth-scroll>
          <template v-if="isSearchStalled">
            <div class="absolute inset-0">
              <div class="flex h-full justify-center items-center">
                <svg-icon icon-class="PARQAY-watermark" class="animate-pulse w-auto h-auto" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex justify-center items-start mb-36">
              <div class="grid grid-cols-4 xl:grid-cols-4 gap-8 w-full xl:w-1/2 px-12 py-4">
                <event-card v-for="(event, i) in events" :key="`event-search-result-${i}`" :event="event"
                  :selected-events="form.events" @click="toggleEvent(event)" />
              </div>
            </div>
          </template>
        </smooth-scroll>
      </div>
    </div>
  </div>
</template>