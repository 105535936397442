<script>
import { watch } from "@vue/composition-api";

export default {
  props: {
    attachments: {
      type: Array,
      require: true
    }
  },
  setup(props, ctx) {

    const previewMedia = f => {
      ctx.root.$bus.$emit("preview-attachment-media", f);
    };

    const detectFileType = (f) => {
      return f.type;
    };

    const detectIsFileType = (f, target_type) => {
      return detectFileType(f) === target_type;
    }

    return {
      previewMedia,
      detectIsFileType
    };
  }
}
</script>
<template>
  <div class="grid grid-cols-2 gap-2">
    <template v-if="attachments.filter(f => detectIsFileType(f, 'image')).length > 0">
      <viewer :images="attachments.filter(f => detectIsFileType(f, 'image'))">
        <div class="grid grid-cols-4 gap-2 mb-3">
          <div v-for="(f, i) in attachments.filter(f =>
            detectIsFileType(f, 'image')
          )" :key="`${f.type}-${i}`">
            <img v-if="!f.$error" :src="f.url" alt=""
              class="w-full object-cover bg-gray-50 hover:opacity-50 cursor-pointer border rounded overflow-hidden"
              style="height: 80px" @error="event => {
                $set(f, '$error', true);
              }" />
            <a-tooltip v-else title="Image link might be broken or expired.">
              <div class="flex items-center justify-center bg-gray-200 cursor-pointer border border-gray-300 rounded"
                style="width: 80px; height: 80px;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                  <path fill-rule="evenodd"
                    d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd" />
                </svg>
              </div>
            </a-tooltip>
          </div>
        </div>
      </viewer>
    </template>

    <template>
      <div v-if="attachments.filter(f => detectIsFileType(f, 'video')).length > 0" class="flex flex-col space-y-2 pb-3">
        <div v-for="(f, i) in attachments.filter(f => detectIsFileType(f, 'video')
        )" :key="`${f.type}-${i}`">
          <video controls class="w-full object-cover hover:opacity-50 cursor-pointer border rounded overflow-hidden">
            <source :src="f.url" :type="f.mime_type">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </template>

    <div class="flex flex-col space-y-2 pb-3">
      <div v-for="(f, i) in attachments.filter(f =>
        detectIsFileType(f, 'audio')
      )" :key="`${f.type}-${i}`" class="flex relative">
        <audio controls>
          <source :src="f.url" :type="f.mime_type">
          Your browser does not support the audio element.
        </audio>
      </div>

      <div v-for="(f, i) in attachments.filter(f =>
        detectIsFileType(f, 'pdf')
      )" :key="`${f.type}-${i}`" class="flex relative">
        <a target="_blank"
          class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
          @click="previewMedia(f)">
          <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
            <span class="font-bold text-lg text-pink-500">PDF</span>
          </div>
          <span class="font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis">
            {{ f.name || `${f.type}-attachment-${i + 1}` }}
          </span>
        </a>
      </div>

      <div v-for="(f, i) in attachments.filter(f =>
        detectIsFileType(f, 'word')
      )" :key="`${f.type}-${i}`" class="flex relative">
        <a target="_blank"
          class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
          @click="previewMedia(f)">
          <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
            <span class="font-bold text-lg text-parqay-primary">Word</span>
          </div>
          <span class="font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis">
            {{ f.name || `${f.type}-attachment-${i + 1}` }}
          </span>
        </a>
      </div>

      <div v-for="(f, i) in attachments.filter(f =>
        detectIsFileType(f, 'excel')
      )" :key="`${f.type}-${i}`" class="flex relative">
        <a target="_blank"
          class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
          @click="previewMedia(f)">
          <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
            <span class="font-bold text-lg text-green-400">Excel</span>
          </div>
          <span class="font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis">
            {{ f.name || `${f.type}-attachment-${i + 1}` }}
          </span>
        </a>
      </div>

      <div v-for="(f, i) in attachments.filter(f =>
        detectIsFileType(f, 'ppt')
      )" :key="`${f.type}-${i}`" class="flex relative">
        <a target="_blank"
          class="flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full"
          @click="previewMedia(f)">
          <div class="flex items-center justify-center border rounded p-2 bg-gray-50">
            <span class="font-bold text-lg text-orange-400">PPT</span>
          </div>
          <span class="font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis">
            {{ f.name || `${f.type}-attachment-${i + 1}` }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>