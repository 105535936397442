<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["chartData", "options", "lineColor", "areaColor"],
  mounted() {
    this.renderChart(
      {
        labels: new Array(8),
        datasets: [
          {
            data: this.chartData,
            // line color
            borderColor: this.lineColor,
            borderWidth: 3,
            pointRadius: 0,
            // fill color
            backgroundColor: this.areaColor,
          },
        ],
      },
      {
        maintainAspectRatio: false,
        // elements: {
        //   fill: 'bottom',
        // },
        layout: {
          padding: {
            left: -10,
          },
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        gridLines: {
          drawTicks: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
                suggestedMin: 50,
                suggestedMax: 100,
                min: 0,
                // max: 65,
                // stepSize: 40,
                maxTicksLimit: 1,
              },
            },
          ],
          xAxes: [
            {
              display: false,
              maxTicksLimit: 1,
            },
          ],
        },
      }
    );
  },
};
</script>
