<script>
import { reactive } from "@vue/composition-api";
export default {
  name: "SpaceItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const controls = reactive({
      hover: false,
    });
    const handleAdd = () => {
      // props.item.availablity += 1;
      console.log("handleAdd");
      ctx.emit("add");
    };

    const handleMinus = () => {
      if (props.item.availablity < 1) return;
      // props.item.availablity -= 1;
      console.log("handleMinus");
      ctx.emit("minus");
    };
    return {
      controls,
      handleAdd,
      handleMinus,
    };
  },
};
</script>
<template>
  <div
    class="flex rounded border shadow-sm bg-white text-parqay-primary flex-col relative"
    @mouseover="() => (controls.hover = true)"
    @mouseleave="() => (controls.hover = false)"
  >
    <div class="text-center border-b">
      <div class="text-2xl font-bold">
        <span class="text-pink-500">{{ item.availability }}</span>
      </div>
      <span class="font-bold">Spaces</span>
    </div>
    <div class="flex px-0.5 py-2 justify-around items-center space-x-0.5">
      <button
        class="
          w-6
          h-6
          border border-parqay-primary
          hover:text-white hover:bg-parqay-primary
          rounded
          flex
          justify-center
          items-center
          focus:outline-none
        "
        @click="handleMinus"
      >
        <svg-icon
          icon-class="minus"
          class="w-5 h-5"
        />
      </button>
      <button
        class="
          w-6
          h-6
          flex
          border border-parqay-primary
          hover:text-white hover:bg-parqay-primary
          rounded
          text-xl
          font-medium
          justify-center
          items-center
          focus:outline-none
        "
        @click="handleAdd"
      >
        <svg-icon
          icon-class="plus"
          class="w-5 h-5"
        />
      </button>
      <button
        class="
          w-6
          h-6
          flex
          items-center
          justify-center
          border border-pink-500
          text-pink-500
          hover:text-white hover:bg-pink-500
          rounded-full
          font-bold
          focus:outline-none
        "
        @click="$emit('delete')"
      >
        <svg-icon
          icon-class="delete"
          class="w-5 h-5"
        />
      </button>
    </div>

    <div
      v-show="controls.hover"
      class="w-5 h-5 bg-gray-200 rounded absolute"
      :style="{ top: '-0.5rem', right: '-0.5rem' }"
    >
      <svg-icon
        icon-class="move"
        class="w-5 h-5"
      />
    </div>
  </div>
</template>