<script>
import { reactive, onMounted } from '@vue/composition-api'
import PhoneNumberManageCard from "src/views/auth/components/Step3.vue";
import startCase from "lodash/startCase";
import isEmpty from "lodash/isEmpty";

import { API } from "src/views/listings/api";

export default {
  components: {
    PhoneNumberManageCard
  },
  props: {
    form: {
      type: Object,
      default: () => ({
        name: 'John Michael',
        role: 'Operator',
        phone_number: '+1(734)272-6106',
        email: 'support@parqay.com'
      })
    }
  },
  setup(props, ctx) {

    const controls = reactive({
      loading: {
        updateForm: false
      },
      visible: {
        editModal: false,
        phoneNumber: false
      },
      roles: [
        'Property Manager',
        'Owner',
        'Operator',
        'Agent',
        'Representative',
        'Other'
      ],
      otherRole: null,
      form: Object.assign({}, props.form),
      updateForm: {}
    });

    const onInputChange = (namespace) => {
      if (namespace === "otherRole") {
        const role = "Other:" + controls.otherRole;
        controls.updateForm.role = role;
        return
      }
      controls.updateForm[namespace] = controls.form[namespace];
      controls.otherRole = null;
    }

    const updatePrimaryContact = async () => {
      controls.loading.updateForm = true;
      try {
        await API.updatePrimaryContact(ctx.root.$route.params.id, controls.updateForm)
      } catch (error) {
        ctx.root.$openNotification("Update Primary Contact", error.message);
      } finally {
        setTimeout(() => {
          controls.loading.updateForm = false;
          controls.visible.editModal = false;
          ctx.emit("refresh");
        }, 500);
      }
    }

    onMounted(() => {

    })

    return {
      controls,
      updatePrimaryContact,
      onInputChange,
      startCase,
      isEmpty
    }
  }
}
</script>
<template>
  <div>
    <div class="px-2 py-1 flex justify-between items-center">
      <h4 class="font-medium">
        Primary Contact
      </h4>
      <a-button v-if="!controls.visible.editModal" type="primary" @click="() => controls.visible.editModal = true">
        Edit
      </a-button>
    </div>
    <!-- preview -->
    <div class="grid grid-cols-2 gap-3 px-2 py-3">
      <div class="flex items-center space-x-2">
        <div class="text-center flex-none bg-parqay-primary w-12 h-12 rounded-full text-white mr-1">
          <span class="text-2xl leading-12">{{ startCase(Array.from(form.name)[0]) }}</span>
        </div>
        <div class="flex flex-col space-y-2">
          <!-- name -->
          <span>{{ form.name }}</span>
          <!-- role -->
          <a-tag color="geekblue">{{ form.role }}</a-tag>
        </div>
      </div>
      <div class="flex flex-col space-y-3">
        <div class="flex items-center mb-2">
          <svg-icon icon-class="contact-email" class="w-8 h-8 mr-2" />
          <span>{{ form.email }}</span>
        </div>
        <div class="flex items-center">
          <svg-icon icon-class="contact-phone" class="w-8 h-8 mr-2" />
          <span>{{ form.phone_number }}</span>
        </div>
      </div>
    </div>
    <!-- Update phone number -->
    <a-modal destroy-on-close v-model="controls.visible.phoneNumber" :footer="false" class="bg-gray-400">
      <phone-number-manage-card modal-mode class="px-6 py-6" @verified="
        payload => {
          controls.visible.phoneNumber = false;
          const { phone_number } = payload;
          controls.form.phone_number = phone_number;
          onInputChange('phone_number');
        }
      " />
    </a-modal>
    <!-- Edit form -->
    <a-modal :visible="controls.visible.editModal" :footer="null"
      @cancel="() => { controls.form = Object.assign({}, form); controls.updateForm = {}; controls.otherRole = null; controls.visible.editModal = false; }">
      <div class="flex flex-col space-y-2 px-2 py-3">
        <div class="flex items-center justify-center px-6 py-2">
          <span class="text-black font-medium text-base">Edit Primary Contact</span>
        </div>
        <div class="flex flex-col space-y-2">
          <label-item label="Contact name" desc="This name will override your default host name">
            <a-input v-model="controls.form.name" size="large" placeholder="e.g. John Michael"
              @change="onInputChange('name')" />
          </label-item>
          <label-item label="Contact role" desc="Choose (or enter) a role that best describes the primary contact">
            <div class="flex flex-wrap align-baseline text-sm text-gray-600 font-normal space-x-5 pt-3">
              <a v-for="(option, i) in controls.roles" :key="`option-${i}`"
                class="border-indigo-500 hover:text-gray-600 h-8 mb-2" :class="{
                  'pb-2 border-b-4': controls.form.role.split(':')[0] == option
                }" @click="() => { controls.form.role = option; onInputChange('role'); }">
                {{ option }}
              </a>
            </div>
            <div v-if="controls.form.role.split(':')[0] === 'Other'" class="flex items-center space-x-2">
              <span class="text-gray-600 text-sm whitespace-nowrap">Other role, please specify:
              </span>
              <a-input v-model="controls.otherRole" @change="onInputChange('otherRole')" />
            </div>
          </label-item>
        </div>
        <label-item label="Contact email" desc="This email will override your default host email">
          <div class="flex items-center">
            <svg-icon icon-class="contact-email" class="w-12 h-12 mr-2" />
            <a-input v-model="controls.form.email" size="large" @change="onInputChange('email')" />
          </div>
        </label-item>
        <label-item label="Contact phone number" desc="This phone number will override your default host phone number">
          <div class="flex items-center">
            <svg-icon icon-class="contact-phone" class="w-12 h-12 mr-2" />
            <a-input v-model="controls.form.phone_number" size="large" @click="
              e => {
                controls.visible.phoneNumber = true;
                e.target.blur();
              }
            ">
              <a-button slot="suffix" type="primary" size="small" @click="() => (controls.visible.phoneNumber = true)">
                {{ controls.form.phone_number ? "Change" : "Add" }}
              </a-button>
            </a-input>
          </div>
        </label-item>
        <div class="flex items-center justify-end w-full py-3">
          <a-button type="primary" :disabled="isEmpty(controls.updateForm)" :loading="controls.loading.updateForm"
            @click="updatePrimaryContact()">Update</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>