<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api';

import { API } from "./api";

export default {
    name: 'EmailInboxWelcomePage',
    setup(props, ctx) {
        const state = reactive({
            userEmail: "Loading...",
            visible: {
                copy: false
            }
        });

        const copyUniqueAddress = () => {
            state.visible.copy = true;
            ctx.root.$copyToClipboard(state.userEmail);
            setTimeout(() => {
                state.visible.copy = false;
            }, 800);
        }

        const getUniqueAddress = async () => {
            try {
                const { data } = await API.getUniqueEmailAddress();
                state.userEmail = data.email;
            } catch (error) {
                state.userEmail = "Loading...";
                console.log(error.message);
            }
        }

        onMounted(() => {
            getUniqueAddress();
        })

        return {
            ...toRefs(state),
            copyUniqueAddress
        };
    },
};
</script>
<template>
    <div class="">
        <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
            <div class="lg:text-center">
                <h2 class="text-lg text-parqay-primary font-semibold">Welcome to your masked mailbox!</h2>
                <div class="flex items-center justify-center space-x-2 mt-4">
                    <a-tooltip :visible="visible.copy" title="Copied!">
                        <span class="text-3xl leading-8 font-extrabold tracking-tight text-gray-800">{{ userEmail }}
                        </span>
                    </a-tooltip>
                    <button class="flex items-center justify-center focus:outline-none"
                        @click.stop="copyUniqueAddress(userEmail)">
                        <svg v-if="visible.copy" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pt-1 text-parqay-primary">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                        </svg>
                        <svg-icon v-else icon-class="clipboard"
                            class="w-6 h-6 pt-1 text-gray-500 hover:text-parqay-primary" />
                    </button>
                </div>
                <div class="mt-8 text-center px-6">
                    <p class="text-base text-gray-500 m-0 p-0">The email address is automatically assigned as a masked
                        email to
                        promote your listings on the web. This helps prevent spam and protects your privacy.
                        Masked emails
                        are secure and have full functionality like a normal email, including sending attachments, adding
                        images and videos.
                    </p>
                </div>
                <div class="mt-10 flex justify-center">
                    <button
                        class="flex items-center justify-center space-x-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-parqay-primary hover:bg-blue-500 outline-none"
                        @click.stop="$bus.$emit('toggle-email-editor', {})">
                        <svg-icon icon-class="plus" class="w-4 h-4 text-white" />
                        <span>Compose New Email</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>