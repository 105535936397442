<script>
import { ref, reactive, computed, watch, onMounted, nextTick } from "@vue/composition-api";
import { saveUserData, authCallback } from "src/plugins/prototype";
import TokenManager from "src/services/authentication/token-manager";
import jwt_decode from "jwt-decode";
import parsePhoneNumber from "libphonenumber-js";
import isEmpty from "lodash/isEmpty";

// APIs
import { API } from "src/views/auth/api";

import PhoneNumberVerifyView from "./PhoneNumberVerifyView.vue";

export default {
  components: { PhoneNumberVerifyView },
  props: {
    loading: Boolean
  },
  setup(props, ctx) {
    const openNotification = ctx.root.$openNotification;
    const email = ref(null);
    const password = ref(null);
    const automaticLogin = ref(false);
    const controls = reactive({
      step: 0,
      country: "US",
      isOutsideUS: false,
      accessType: "unknown",
      authMode: "code",
      phoneNumberComponents: null
    });
    const showPasswordInput = computed(() => {
      return controls.accessType === 'email' || controls.authMode === 'password';
    })
    const onPasswordInputMounted = () => {
      const input = document.querySelector("#password-input");
      console.log("onPasswordInputMounted -> ", input);
      input.focus();
    }
    const loadCachedPayloadFromStore = () => {
      automaticLogin.value = ctx.root.$store.state.automaticLogin;
    };
    watch(
      () => email.value,
      val => {
        controls.accessType = checkAccessType(val);
      }
    );
    watch(
      () => automaticLogin.value,
      val => {
        ctx.root.$store.commit("toggleAutomaticLogin", val);
      }
    );
    const normalLogin = async () => {
      if (controls.accessType === "phone_number") {
        if (controls.phoneNumberComponents && password.value) {
          const full_number = controls.phoneNumberComponents.number;
          phoneNumberLogin(full_number, null, password.value);
        }
      }
      if (controls.accessType === "email") {
        if (email.value && password.value) {
          ctx.emit("loading", true);
          var res;
          try {
            res = await API.login({
              email: email.value,
              password: password.value,
              automatic_login: automaticLogin.value,
              source: "dashboard"
            });
            console.log("login api result: " + JSON.stringify(res));
            saveUserData(res.data);
            authCallback();
            TokenManager().accessToken = res.data.access_token;
            TokenManager().refreshToken = res.data.refresh_token;
            var decoded = jwt_decode(res.data["access_token"]);
            console.log("decoded JWT ==> " + JSON.stringify(decoded));
            const previousRoute = ctx.root.$route.query.next;
            if (res.data.extra.length > 0) {
              ctx.root.$router.push({
                name: "fullfill-profile",
                params: previousRoute || res.data.next_route
              });
            } else {
              if (previousRoute) {
                ctx.root.$router.push(previousRoute);
              } else {
                ctx.root.$router.push("/next/" + res.data.next_route);
              }
            }
          } catch (error) {
            openNotification("Login", error.message);
            console.warn(error);
            ctx.emit("loading", false);
          } finally {
            setTimeout(() => {
              ctx.emit("loading", false);
            }, 1000);
          }
        }
      }
    };
    const phoneNumberLogin = async (full_number, bridging_token, password) => {
      ctx.emit("loading", true);
      var res;
      try {
        let payload = {
          full_number: full_number,
          automatic_login: automaticLogin.value,
          source: "dashboard",
          is_phone_number_login: true
        };
        if (bridging_token) {
          payload["bridging_token"] = bridging_token;
        }
        if (password) {
          payload["password"] = password;
        }
        res = await API.login(payload);
        console.log("login api result: " + JSON.stringify(res));
        saveUserData(res.data);
        authCallback();
        const previousRoute = ctx.root.$route.query.next;
        if (res.data.extra.length > 0) {
          ctx.root.$router.push({
            name: "fullfill-profile",
            params: previousRoute || res.data.next_route
          });
        } else {
          if (previousRoute) {
            ctx.root.$router.push(previousRoute);
          } else {
            ctx.root.$router.push("/next/" + res.data.next_route);
          }
        }
      } catch (error) {
        openNotification("Login", error.message);
        console.warn(error);
        ctx.emit("loading", false);
      } finally {
        setTimeout(() => {
          ctx.emit("loading", false);
        }, 1000);
      }
    };
    const strictValidatePhone = phone_number => {
      try {
        return parsePhoneNumber(phone_number, controls.country).isValid();
      } catch (error) {
        return false;
      }
    };
    const updatePhoneNumberComponents = () => {
      try {
        controls.phoneNumberComponents = parsePhoneNumber(
          email.value,
          controls.country
        );
      } catch (error) {
        controls.phoneNumberComponents = null;
      }
    };
    const validatePhone = phone_number => {
      var phoneRe = /^(\D*\d){5}/;
      return phoneRe.test(phone_number);
    };
    const validateEmail = email => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const checkAccessType = val => {
      if (validatePhone(val)) return "phone_number";
      if (validateEmail(val)) return "email";
      return "unknown";
    };
    const getTitle = () => {
      if (controls.step == 0) {
        if (controls.accessType === "phone_number") return "Login by Phone";
        if (controls.accessType === "email") return "Login by Email";
        return "Phone number / Email address";
      }
      if (controls.step == 1) {
        if (controls.accessType === "phone_number")
          return "Use one of following options";
        if (controls.accessType === "email") return "Enter Password";
      }
    };
    const getPlaceholder = () => {
      if (controls.step == 0) {
        if (controls.accessType === "phone_number") return "Enter phone number";
        if (controls.accessType === "email") return "Enter email address";
        return "Enter phone number or email";
      }
      if (controls.step == 1) {
        return "Enter your password"
      }
    };
    onMounted(() => {
      loadCachedPayloadFromStore();
      controls.accessType = checkAccessType(email.value);
    });
    return {
      email,
      password,
      automaticLogin,
      controls,
      showPasswordInput,
      onPasswordInputMounted,
      normalLogin,
      phoneNumberLogin,
      strictValidatePhone,
      updatePhoneNumberComponents,
      getTitle,
      getPlaceholder,
      isEmpty,
      console
    };
  }
};
</script>
<template>
  <div class="space-y-6">
    <template v-if="controls.step == 0">
      <div class="flex flex-col items-center space-y-4 pt-3">
        <span class="text-sm text-center font-medium text-gray-700">
          {{ getTitle() }}
        </span>
        <a-input size="large" allow-clear required v-model="email" :placeholder="getPlaceholder()" @pressEnter="(e) => {
          console.log(e);
          if (controls.accessType === 'email' || strictValidatePhone(email)) {
            updatePhoneNumberComponents();
            controls.step = 1;
          }
        }">
          <template #suffix>
            <a-button v-if="!controls.isOutsideUS && controls.accessType === 'phone_number'
              " type="link" @click.prevent="() => (controls.isOutsideUS = true)">Outside US</a-button>
            <a-button v-if="controls.isOutsideUS && controls.accessType === 'phone_number'
              " type="link" @click.prevent="() => {
    controls.country = 'US';
    controls.isOutsideUS = false;
  }
    ">In US</a-button>
          </template>
        </a-input>
      </div>

      <div class="flex justify-center">
        <country-select v-if="controls.isOutsideUS && controls.accessType === 'phone_number'" v-model="controls.country"
          :country="controls.country" topCountry="US" class="w-full" />
      </div>
    </template>

    <div v-if="controls.step == 1" class="flex flex-col items-center space-y-6 relative">
      <div class="absolute left-0 top-5">
        <a-button icon="left" @click="() => (controls.step = 0)"></a-button>
      </div>
      <span class="text-sm text-center font-medium text-gray-700">
        {{ getTitle() }}
      </span>
      <a-radio-group v-if="controls.accessType === 'phone_number'" v-model="controls.authMode">
        <a-radio-button value="code">
          SMS Code
        </a-radio-button>
        <a-radio-button value="password">
          Password
        </a-radio-button>
      </a-radio-group>
      <phone-number-verify-view
        v-if="controls.accessType === 'phone_number' && controls.phoneNumberComponents && controls.authMode === 'code'"
        :country-code="controls.phoneNumberComponents.countryCallingCode"
        :phone-number="controls.phoneNumberComponents.nationalNumber" class="w-full" @token-granted="(full_number, token) => {
          phoneNumberLogin(full_number, token, null);
        }
          " />
      <div v-if="showPasswordInput" class="space-y-2 w-full">
        <a-input-password id="password-input" size="large" required v-model="password" :placeholder="getPlaceholder()"
          @hook:mounted="onPasswordInputMounted" @pressEnter="(e) => {
            console.log(e);
            if (!isEmpty(password)) {
              normalLogin();
            }
          }" />
      </div>
    </div>

    <div v-if="controls.step == 1" class="flex items-center justify-between pt-3">
      <div class="flex items-center">
        <input id="remember_me" v-model="automaticLogin" name="remember_me" type="checkbox" class="
                      h-4
                      w-4
                      text-parqay-primary
                      focus:ring-indigo-500
                      border-gray-300
                      rounded
                    " />
        <label for="remember_me" class="ml-2 block text-sm text-gray-900">
          Automatic Login
        </label>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Automatic login will grant 2 months access after logged in whilst
              normal login grant way shorter access to improve security</span>
          </template>
          <svg-icon icon-class="info" class="ml-2 w-5 h-5 text-parqay-primary" />
        </a-tooltip>
      </div>

      <div class="text-sm">
        <a class="font-medium text-parqay-primary hover:text-blue-500" @click.stop="$bus.$emit('open-password-reset')">
          Forgot your password?
        </a>
      </div>
    </div>

    <div>
      <a-button v-if="controls.step == 0" type="primary" :disabled="!(controls.accessType === 'email' || strictValidatePhone(email))
        " size="large" class="w-full" @click="() => {
    updatePhoneNumberComponents();
    controls.step = 1;
  }
    ">
        Continue
      </a-button>
      <a-button v-if="controls.step == 1 &&
        !(
          controls.accessType === 'phone_number' &&
          controls.authMode === 'code'
        )
        " type="primary" :loading="loading" size="large" :disabled="isEmpty(password)" class="w-full"
        @click="normalLogin()">
        Sign in
      </a-button>
    </div>
  </div>
</template>
