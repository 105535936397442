<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import Parallax from "parallax-js";
export default {
  name: "ParallexHouseIncomeDemo",
  props: {
    // Car Arm: left: -30px; top: -30px; height: 105%; width: 110%
    carImageFramePosition: {
      type: Object,
      default: () => {
        return {
          left: "-30px",
          top: "-30px",
          height: "105%",
          width: "110%",
        };
      },
    },
    carImageClass: {
      type: String,
      default: "",
    },
    carImageClassSM: {
      type: String,
      default: "sm:w-36 sm:h-1/5 sm:right-1/5 sm:bottom-24",
    },
    carImageClassXL: {
      type: String,
      default: "xl:w-56 xl:h-1/5 xl:right-1/4 xl:bottom-1/6",
    },
    // Car SVG
    carFloatArmFramePosition: {
      type: Object,
      default: () => {
        return {
          left: "-30px",
          top: "-30px",
          height: "105%",
          width: "110%",
        };
      },
    },
    carFloatArmClass: {
      type: String,
      default: "w-48 h-56",
    },
    carFloatArmClassSM: {
      type: String,
      default: "sm:right-36 sm:bottom-2",
    },
    carFloatArmClassXL: {
      type: String,
      default: "xl:right-1/4 xl:bottom-24",
    },
    // Office Arm
    officeFloatArmFramePosition: {
      type: Object,
      default: () => {
        return {
          left: "-30px",
          top: "-30px",
          height: "105%",
          width: "110%",
        };
      },
    },
    officeFloatArmClass: {
      type: String,
      default: "w-56 h-64",
    },
    officeFloatArmClassSM: {
      type: String,
      default: "sm:right-24 sm:top-8",
    },
    officeFloatArmClassXL: {
      type: String,
      default: "xl:right-1/4 xl:top-1/6",
    },
    // House Arm
    houseFloatArmFramePosition: {
      type: Object,
      default: () => {
        return {
          left: "-30px",
          top: "-30px",
          height: "105%",
          width: "110%",
        };
      },
    },
    houseFloatArmClass: {
      type: String,
      default: "w-72 h-64",
    },
    houseFloatArmClassSM: {
      type: String,
      default: "sm:left-24 sm:top-8",
    },
    houseFloatArmClassXL: {
      type: String,
      default: "xl:left-1/4 xl:top-1/6",
    },
    // Parking Arm
    parkingFloatArmFramePosition: {
      type: Object,
      default: () => {
        return {
          left: "-30px",
          top: "-30px",
          height: "105%",
          width: "110%",
        };
      },
    },
    parkingFloatArmClass: {
      type: String,
      default: "w-56 h-64",
    },
    parkingFloatArmClassSM: {
      type: String,
      default: "sm:left-24 sm:bottom-12",
    },
    parkingFloatArmClassXL: {
      type: String,
      default: "xl:left-1/4 xl:bottom-36",
    },
  },
  setup(props, ctx) {
    const heroEffect = ref(null);
    var parallaxInstance;
    const armsAnimations = ref({
      parking: false,
      house: false,
      office: false,
      car: false,
    });
    const toggleArmsAnimations = (t, v) => {
      console.log("toggleArmsAnimations: " + v);
      armsAnimations.value[t] = v;
      ctx.emit("arm-focus", t);
    };
    onMounted(() => {
      parallaxInstance = new Parallax(heroEffect.value, {
        relativeInput: true,
      });
    });
    return {
      heroEffect,
      armsAnimations,
      toggleArmsAnimations,
    };
  },
};
</script>
<template>
  <div
    ref="heroEffect"
    data-relative-input="true"
    class="h-full w-full"
    data-limit-x="30"
    data-limit-y="30"
  >
    <div
      class="layer absolute"
      style="left: -30px; top: -30px; height: 105%; width: 110%"
      data-depth="0.4"
    >
      <img
        class="absolute inset-0 pr-12 h-full w-full object-contain"
        src="/static/img/dashboard/db-login-background.png"
        alt=""
      />
    </div>
    <div
      class="layer absolute"
      :style="{
        left: parkingFloatArmFramePosition.left,
        top: parkingFloatArmFramePosition.top,
        height: parkingFloatArmFramePosition.height,
        width: parkingFloatArmFramePosition.width,
      }"
      data-depth="0.5"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        :class="
          parkingFloatArmClass +
            ' ' +
            parkingFloatArmClassSM +
            ' ' +
            parkingFloatArmClassXL
        "
        @mouseenter="toggleArmsAnimations('parking', true)"
        @mouseleave="toggleArmsAnimations('parking', false)"
      >
        <svg-icon
          icon-class="rent-parking-float-arm"
          class="w-full h-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          :class="{
            'animate-pulse': !armsAnimations.parking,
            'animate-none': armsAnimations.parking,
          }"
        />
      </div>
    </div>

    <div
      class="layer absolute"
      :style="{
        left: houseFloatArmFramePosition.left,
        top: houseFloatArmFramePosition.top,
        height: houseFloatArmFramePosition.height,
        width: houseFloatArmFramePosition.width,
      }"
      data-depth="0.5"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        :class="
          houseFloatArmClass +
            ' ' +
            houseFloatArmClassSM +
            ' ' +
            houseFloatArmClassXL
        "
        @mouseenter="toggleArmsAnimations('house', true)"
        @mouseleave="toggleArmsAnimations('house', false)"
      >
        <svg-icon
          icon-class="rent-house-float-arm"
          class="w-full h-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          :class="{
            'animate-pulse': !armsAnimations.house,
            'animate-none': armsAnimations.house,
          }"
        />
      </div>
    </div>

    <div
      class="layer absolute"
      :style="{
        left: officeFloatArmFramePosition.left,
        top: officeFloatArmFramePosition.top,
        height: officeFloatArmFramePosition.height,
        width: officeFloatArmFramePosition.width,
      }"
      data-depth="0.5"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        :class="
          officeFloatArmClass +
            ' ' +
            officeFloatArmClassSM +
            ' ' +
            officeFloatArmClassXL
        "
        @mouseenter="toggleArmsAnimations('office', true)"
        @mouseleave="toggleArmsAnimations('office', false)"
      >
        <svg-icon
          icon-class="rent-office-float-arm"
          class="w-full h-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          :class="{
            'animate-pulse': !armsAnimations.office,
            'animate-none': armsAnimations.office,
          }"
        />
      </div>
    </div>

    <div
      class="layer absolute"
      :style="{
        left: carImageFramePosition.left,
        top: carImageFramePosition.top,
        height: carImageFramePosition.height,
        width: carImageFramePosition.width,
      }"
      data-depth="0.36"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        :class="carImageClass + ' ' + carImageClassSM + ' ' + carImageClassXL"
      >
        <img
          src="/static/img/dashboard/car-float-arm.png"
          class="w-full h-full object-contain"
        />
      </div>
    </div>

    <div
      class="layer absolute"
      :style="{
        left: carFloatArmFramePosition.left,
        top: carFloatArmFramePosition.top,
        height: carFloatArmFramePosition.height,
        width: carFloatArmFramePosition.width,
      }"
      data-depth="0.5"
    >
      <div
        class="absolute cursor-pointer pointer-events-auto"
        :class="
          carFloatArmClass + ' ' + carFloatArmClassSM + ' ' + carFloatArmClassXL
        "
        @mouseenter="toggleArmsAnimations('car', true)"
        @mouseleave="toggleArmsAnimations('car', false)"
      >
        <svg-icon
          icon-class="lease-car-float-arm"
          class="w-full h-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          :class="{
            'animate-pulse': !armsAnimations.car,
            'animate-none': armsAnimations.car,
          }"
        />
      </div>
    </div>
  </div>
</template>
