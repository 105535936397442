<script>
import { ref } from "@vue/composition-api";

import TabHeader from "src/views/components/TabHeader.vue";
import Reservations from "./components/Reservations.vue";
import Calendar from "./components/Calendar.vue";

export default {
  name: "ReservationsCalendar",
  components: {
    TabHeader,
    Reservations,
    "pq-calendar": Calendar
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const router = ctx.root.$router;
    const activeTab = ref("reservations");
    const setupHashRoute = hashRoute => {
      router.push({ name: route.name, hash: `#${hashRoute}` });
    };
    const changeTab = async name => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    return {
      activeTab,
      changeTab
    };
  }
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="pt-10 h-full flex flex-col px-10">
      <div class="mb-6">
        <tab-header v-model="activeTab" :tabs="[
          { name: 'reservations', label: 'Reservations' },
          { name: 'calendar', label: 'Calendar', action: () => $bus.$emit('calendar-refresh') }
        ]" @change="changeTab">
          <template #right-actions>
            <button class="px-4 py-2 rounded bg-green-400 hover:bg-green-500 focus:outline-none disabled:opacity-50 text-white font-medium"
              @click="$router.push('/dashboard/reservations/new')">
              + Create Reservation
            </button>
          </template>
        </tab-header>
      </div>
      <reservations v-if="activeTab === 'reservations'" />
      <pq-calendar v-if="activeTab === 'calendar'" />
    </div>
  </div>
</template>
