<script>
import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import EventBus from "src/services/util/event-bus";
import TabHeader from "src/views/components/TabHeader";
import ServiceTypeDropdown from "src/views/dropdowns/ServiceType";
import ListingSearchDropdown from "src/views/components/ListingSearchDropdown";
import ReservationTable from "src/views/reservations/components/Table";

import { API } from "src/views/reservations/api";

export default {
  name: "Reservations",
  components: {
    TabHeader,
    ServiceTypeDropdown,
    ListingSearchDropdown,
    ReservationTable,
  },
  props: {
    model: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {

    const reservations = ref([]);
    const controls = reactive({
      loading: false
    });
    const pagination = reactive({
      currentPage: 1,
      perPage: 10,
      total: 0
    })
    const activeTab = ref("All");
    const filter = reactive({
      tab: "all",
      type: null,
      listing: null,
    });
    const reloadListingSearch = ref(true);

    const filterReservations = ({ tab, type, listing }) => {
      if (tab) {
        filter.tab = activeTab.value;
        filter.type = null;
        filter.listing = null;
      }
      if (type) {
        filter.type = type;
        filter.tab = null;
        filter.listing = null;
        activeTab.value = "All";
      }
      if (listing) {
        filter.listing = listing;
        filter.tab = null;
        filter.type = null;
        activeTab.value = "All";
      }
      pagination.currentPage = 1;
      fetchData();
    };

    const reloadDropdowns = () => {
      reloadListingSearch.value = false;
      nextTick(() => {
        reloadListingSearch.value = true;
      });
    };

    const fetchData = async () => {
      controls.loading = true;
      try {
        const { data, pagination: pag } = await API.list({
          page: pagination.currentPage,
          tab: filter.tab,
          type: filter.type,
          listing: filter.listing
        });

        reservations.value = data

        pagination.currentPage = pag.page;
        pagination.perPage = pag.per_page;
        pagination.total = pag.total;

      } catch (error) {
        console.log("reservation error: ", error);
        return;
      } finally {
        controls.loading = false;
      }
    };

    const handlePageChange = (page) => {
      pagination.currentPage = page;
      fetchData();
    }

    onMounted(() => {
      fetchData();
      EventBus.$on("reservation-request-status-change", fetchData);
    });

    onBeforeUnmount(() => {
      EventBus.$off("reservation-request-status-change", fetchData);
    });

    return {
      reservations,
      controls,
      pagination,
      activeTab,
      filter,
      reloadListingSearch,
      fetchData,
      filterReservations,
      reloadDropdowns,
      handlePageChange
    };
  },
};
</script>
<template>
  <div class="reservation">
    <div class="mb-6">
      <tab-header v-model="activeTab" :tabs="[
        { name: 'All', label: 'All' },
        { name: 'Requesting', label: 'Requesting' },
        { name: 'Upcoming', label: 'Upcoming' },
        { name: 'Pending', label: 'Pending' },
        { name: 'Event', label: 'Event' },
        { name: 'Completed', label: 'Completed' },
        { name: 'Marketplace', label: 'Marketplace' },
        { name: 'Past', label: 'Past' },
        { name: 'Canceled', label: 'Canceled' },
        { name: 'Denied', label: 'Denied' },
      ]" :padding="50" show-toggles disableHashDetect @change="tab => filterReservations({ tab })" />
    </div>
    <div>
      <div class="flex flex-col shadow-sm border rounded-lg bg-white mb-12">
        <div class="flex items-center justify-between px-6 py-4 border-b">
          <div class="flex items-center space-x-3">
            <span class="font-medium text-base text-black">You are hosting</span>
            <a-icon type="reload" :spin="controls.loading" style="font-size: 0.95rem;" @click.stop="fetchData()" />
          </div>
          <div class="flex space-x-4 items-center">
            <p>Service Type</p>
            <service-type-dropdown @selected="({ value: type }) => {
              filterReservations({ type });
            }
              " />
            <p>Property</p>
            <listing-search-dropdown v-if="reloadListingSearch" :type-filter="filter.type"
              :auto-select-first-on-blur="false" class="" @selected="({ id: listing }) => {
                filterReservations({ listing });
              }
                " @reset="() => {
    filter.listing = null;
    filterReservations({ tab: 'All' });
    reloadDropdowns();
  }
    " />
          </div>
          <div class="flex space-x-2 items-center relative">
            <button :disabled="true"
              class="px-4 py-2 rounded bg-parqay-primary hover:bg-blue-500 disabled:opacity-50 focus:outline-none text-white font-medium">
              Import
            </button>
            <a-tag class="absolute" :style="{
              left: '-3rem',
              top: '-1rem',
            }" color="orange">
              Available Soon
            </a-tag>
          </div>
        </div>
        <reservation-table :controls="controls" :data="reservations" :pagination="pagination"
          @page-change="handlePageChange" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reservation::v-deep {
  .ant-table-row {
    @apply cursor-pointer;

    td {
      @apply py-2;
    }
  }

  .ant-table-fixed-right {
    td {
      @apply py-2 px-2 border-l;
    }
  }

  .ant-table-fixed {
    th[key="docs"] {
      // @apply px-2 border-l;
    }
  }
}
</style>
