<script>
import { reactive, computed, toRaw, onMounted } from "@vue/composition-api";
import { API } from "src/views/settings/api";

export default {
    name: "ListingSettings",
    props: {
        noTitle: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {

        const controls = reactive({
            loading: {
                listing: false
            }
        })

        const settings = reactive({
            about_host: {
                is_active: false,
                content: null
            },
            things_your_guests_should_know: {
                is_active: false,
                content: null
            }
        });

        const parseListingSettings = ({ about_host, things_your_guests_should_know }) => {
            if (about_host) {
                settings.about_host = about_host;
            } else {
                settings.about_host.is_active = false;
                settings.about_host.content = null;
            }

            if (things_your_guests_should_know) {
                settings.things_your_guests_should_know = things_your_guests_should_know;
            } else {
                settings.things_your_guests_should_know.is_active = false;
                settings.things_your_guests_should_know.content = null;
            }
        }

        const getListingSettings = async () => {
            controls.loading.listing = true;
            try {
                const { data: { listing } } = await API.getListingSettings("host");

                parseListingSettings(listing);

                controls.loading.listing = false;
            } catch (error) {
                controls.loading.listing = false;
            }
        };

        const updateHostListingAutofillSettings = async () => {
            try {
                controls.loading.listing = true;
                const { data: { listing } } = await API.updateHostListingAutofillSettings(toRaw(settings));
                parseListingSettings(listing);
                controls.loading.listing = false;
                ctx.emit("refresh");
            } catch (error) {
                ctx.root.$openNotification("Update Listing Settings", error.message, 10, "error");
                controls.loading.listing = false;
            }
        };

        const disableListingSave = computed(() => {
            if (settings.about_host.is_active) {
                if (!settings.about_host.content) {
                    return true;
                }
            }
            if (settings.things_your_guests_should_know.is_active) {
                if (!settings.things_your_guests_should_know.content) {
                    return true;
                }
            }
            return false;
        })

        onMounted(() => {
            getListingSettings();
        });

        return {
            controls,
            settings,
            getListingSettings,
            updateHostListingAutofillSettings,
            disableListingSave
        }
    }
}
</script>
<template>
    <div class="bg-white rounded-lg py-4 border shadow-sm">
        <div v-if="!noTitle" class="flex items-center space-x-2 border-b pb-4 pl-4 mb-0">
            <span class="font-medium text-base text-black">
                Listing
            </span>
            <a-icon type="reload" :spin="controls.loading.listing" class="cursor-pointer" style="color: blue;"
                @click.stop="getListingSettings()" />
            <a-tag color="geekblue" style="margin-left: 10px;">Global Overrides</a-tag>
        </div>
        <div class="grid gap-x-8 gap-y-4 grid-cols-2 px-6" :class="noTitle ? 'py-2' : 'py-6'">
            <label-item label="About Yourself"
                desc="Inform your tenants about yourself as a host. This setting (if set) auto-populates &quot;About Host&quot; section when you create a new listing.">
                <div class="flex items-center space-x-2" slot="actions">
                    <div class="w-2 h-2 rounded-full"
                        :class="settings.about_host.is_active ? 'bg-green-400' : 'bg-pink-500'" />
                    <span class="text-xs font-semibold"
                        :class="settings.about_host.is_active ? 'text-green-400' : ''">Autofill</span>
                    <a-switch v-model="settings.about_host.is_active" />
                </div>
                <a-textarea v-model="settings.about_host.content" placeholder="Tell tenants more about yourself as a host."
                    :auto-size="{ minRows: 3, maxRows: 4 }" :disabled="!settings.about_host.is_active" />
            </label-item>
            <label-item label="Things Tenants Should Know"
                desc="Enter any essential information for tenants. This setting (if set) autofills &quot;Things your guests should know&quot; section when you create a new listing.">
                <div class="flex items-center space-x-2" slot="actions">
                    <div class="w-2 h-2 rounded-full"
                        :class="settings.things_your_guests_should_know.is_active ? 'bg-green-400' : 'bg-pink-500'" />
                    <span class="text-xs font-semibold"
                        :class="settings.things_your_guests_should_know.is_active ? 'text-green-400' : ''">Autofill</span>
                    <a-switch v-model="settings.things_your_guests_should_know.is_active" />
                </div>
                <a-textarea v-model="settings.things_your_guests_should_know.content"
                    placeholder="Enter any info your tenants need to pay special attention."
                    :auto-size="{ minRows: 3, maxRows: 4 }"
                    :disabled="!settings.things_your_guests_should_know.is_active" />
            </label-item>
        </div>
        <div class="bg-white flex justify-end py-2 px-6">
            <a-button type="primary" :loading="controls.loading.listing"
                :disabled="controls.loading.listing || disableListingSave"
                @click.stop="updateHostListingAutofillSettings()">
                Save
            </a-button>
        </div>
    </div>
</template>