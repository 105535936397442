<script>
import { onMounted, reactive, ref } from "@vue/composition-api";
import { Tokens, Urls } from "services/constants";
import { API } from "src/views/settings/api";

export default {
  props: {
    title: {
      type: Object,
      default: () => {
        return {
          connect: "Connect With",
          check: "Check Earnings",
          update: "Update Payout Bank/Card"
        };
      }
    }
  },
  setup(props, ctx) {
    const loading = reactive({
      refresh: false
    });
    const stripeConnectUrl =
      "https://connect.stripe.com/express/oauth/authorize?client_id=" +
      Tokens.STRIPE_CONNECT_CLIENT_ID +
      "&redirect_uri=" +
      window.location.origin +
      "/dashboard/account-settings";
    const stripeConnectDashboardUrl = ref(null);
    const popUpBlockMessage = ref(null);
    const connectWithStripe = () => {
      var popUp = window.open(stripeConnectUrl, "_blank");
      if (popUp == null || typeof popUp == "undefined") {
        popUpBlockMessage.value =
          "Looks like your browser is blocking our navigation toward Stripe, \
          please allow the pop up so you will be able to set up payment methods to receive your earnings.";
      } else {
        popUpBlockMessage.value =
          "Please create your Stripe Connect account in another tab to receive our payouts.";
        popUp.focus();
      }
    };
    const getStripeConnectDashboardUrl = async () => {
      loading.refresh = true;
      popUpBlockMessage.value = null;
      var res;
      try {
        res = await API.getStripeConnectAccountDashboardUrl();
      } catch (error) {
        console.log("getStripeConnectDashboardUrl >> error", error);
      }
      console.log("getStripeConnectDashboardUrl", res);
      if (res.data) {
        stripeConnectDashboardUrl.value = res.data.login_link;
      }
      loading.refresh = false;
    };
    const refresh = async () => {
      await getStripeConnectDashboardUrl();
    };
    const navigateToStripeDashboard = (toAccount = false) => {
      var loginLink = stripeConnectDashboardUrl.value;
      if (loginLink) {
        if (toAccount) {
          loginLink = loginLink + "#/account";
        }
        var popUp = window.open(loginLink, "_blank");
        if (popUp == null || typeof popUp == "undefined") {
          popUpBlockMessage.value = `
          Looks like your browser is blocking our navigation toward Stripe,
          please allow the pop up so you will be able to see how much you have earned.
          `;
        } else {
          popUpBlockMessage.value =
            "Please view your payout details in another tab";
          popUp.focus();
        }
      }
    };
    onMounted(() => {
      getStripeConnectDashboardUrl();
    });
    return {
      loading,
      popUpBlockMessage,
      stripeConnectDashboardUrl,
      connectWithStripe,
      refresh,
      navigateToStripeDashboard
    };
  }
};
</script>
<template>
  <div>
    <template v-if="loading.refresh">
      <div
        class="
          flex
          items-center
          justify-center
          border border-purple-600
          py-2
          px-24
          rounded
        "
      >
        <svg-icon
          icon-class="circular-loader"
          class="animate-spin h-4 w-4 text-parqay-primary"
        />
      </div>
    </template>
    <template v-else>
      <template v-if="!stripeConnectDashboardUrl">
        <div
          v-if="popUpBlockMessage"
          class="
            border border-parqay-primary
            rounded-lg
            py-4
            px-4
            text-parqay-primary
            bg-white
          "
        >
          {{ popUpBlockMessage }} Click
          <button
            class="
              bg-parqay-primary
              hover:bg-blue-500
              rounded
              text-white
              px-2
              py-1
            "
            @click="refresh"
          >
            refresh
            <svg-icon
              v-if="loading.refresh"
              icon-class="circular-loader"
              class="animate-spin h-4 w-4 ml-2 my-auto text-white"
            />
          </button>
          if you have complete the steps
        </div>
        <div class="flex justify-center items-center">
          <button
            type="button"
            class="
              my-2
              px-4
              py-2
              font-medium
              rounded-md
              text-white
              bg-gradient-to-r
              from-green-400
              to-blue-500
              hover:from-pink-500
              hover:to-yellow-300
              focus:outline-none
              focus:border-indigo-800
              focus:shadow-outline-indigo
              active:bg-indigo-800
              transition
              ease-in-out
              duration-150
            "
            @click="connectWithStripe"
          >
            <div class="flex items-center space-x-2">
              <span>{{ title.connect }}</span>
              <svg-icon icon-class="stripe" class="h-6 w-12 text-white" />
            </div>
          </button>
        </div>
      </template>
      <template v-else>
        <div
          v-if="popUpBlockMessage"
          class="
            border border-parqay-primary
            rounded-lg
            py-4
            px-4
            text-parqay-primary
            bg-white
          "
        >
          {{ popUpBlockMessage }}
        </div>
        <div class="flex justify-center items-center flex-col space-y-2 mt-2">
          <button
            type="button"
            class="
              px-4
              py-2
              font-medium
              rounded-md
              text-white
              bg-gradient-to-r
              from-green-400
              to-blue-500
              hover:from-pink-500
              hover:to-yellow-300
              focus:outline-none
              focus:border-indigo-800
              focus:shadow-outline-indigo
              active:bg-indigo-800
              transition
              ease-in-out
              duration-150
            "
            @click="navigateToStripeDashboard()"
          >
            <div class="flex items-center space-x-2">
              <span>{{ title.check }}</span>
              <svg-icon icon-class="stripe" class="h-6 w-12 text-white" />
            </div>
          </button>
          <button
            type="button"
            class="
              px-4
              py-2
              font-medium
              rounded-md
              text-white
              bg-parqay-primary
              hover:bg-blue-500
              focus:outline-none
              focus:border-indigo-800
              focus:shadow-outline-indigo
              active:bg-indigo-800
              transition
              ease-in-out
              duration-150
            "
            @click="navigateToStripeDashboard(true)"
          >
            <span>{{ title.update }}</span>
          </button>
        </div>
      </template>
    </template>
  </div>
</template>
