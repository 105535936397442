<script>
import {
  onMounted,
  watch,
  reactive,
  computed
} from "@vue/composition-api";
import BaseForm from "src/views/reservations/components/forms/View";
import {
  getDefaultReservationDetailsForm,
  getDefaultReservationDetailsControls
} from "src/views/reservations/util";
import { API } from "src/views/reservations/api";
import VehiclesForm from "src/views/reservations/components/forms/Vehicles";
import DriversForm from "src/views/reservations/components/forms/Drivers";
import GuestsForm from "src/views/reservations/components/forms/Guests";

export default {
  name: "ReservationDetailsForm",
  components: {
    BaseForm,
    VehiclesForm,
    DriversForm,
    GuestsForm
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const routeType = computed(() => {
      return route.params.type;
    });
    console.log("routeType", routeType.value);
    // Note: Use FORM ONLY for communicating with BACKEND
    var form = reactive(getDefaultReservationDetailsForm(routeType.value));
    // Note: Use CONTROLS ONLY for controlling UI
    const controls = reactive(
      getDefaultReservationDetailsControls(routeType.value)
    );
    watch(
      () => form.type,
      () => {
        console.log("form.type >> updated", form.type);
        form.drivers = [];
        form.guests = [];
      }
    );
    const getReservationDetails = async () => {
      let resp;
      try {
        if (route.params.id === "null") {
          ctx.root.$openNotification(
            "Unknown Reservaton",
            "We could not find details associated with this reservation",
            10,
            "error"
          );
          ctx.root.$router.back();
          return
        }
        resp = await API.details(route.params.id);
        Object.assign(form, resp.data);
        console.log("reservation details api result: ", resp);
      } catch (error) {
        console.log("getReservationDetails error: ", error);
        return;
      }
      // _loadMockLocalFormData();
    };
    onMounted(() => {
      getReservationDetails();
    });
    return {
      form,
      controls,
      getReservationDetails
    };
  }
};
</script>
<template>
  <base-form
    :form="form"
    :controls="controls"
    @refresh="getReservationDetails()"
  >
    <template v-if="form.type">
      <template v-if="form.type == 'parking'" />
      <template v-if="['stay', 'coworking'].includes(form.type)">
        <template slot="guests">
          <guests-form :form="form" :controls="controls" />
        </template>
        <template slot="vehicle-details">
          <vehicles-form
            :embed-mode="false"
            :form="form"
            :controls="controls"
          />
        </template>
      </template>
      <template v-if="form.type == 'car-rental'">
        <template slot="drivers">
          <drivers-form :form="form" :controls="controls" />
        </template>
      </template>
    </template>
  </base-form>
</template>
