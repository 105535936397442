var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full overflow-hidden",staticStyle:{"min-height":"460px"}},[_c('div',{staticClass:"flex flex-col overflow-auto space-y-4"},[_vm._m(0),_c('div',{staticClass:"rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer",staticStyle:{"min-height":"321px"}},[_c('div',{staticClass:"grid grid-cols-2 gap-4 p-4"},[_vm._l((_vm.model),function(occupation,i){return [(occupation.name != 'Other')?_c('div',{key:("occupation-" + i),staticClass:"px-4 py-2 rounded-xl border hover:border-blue-300 hover:bg-blue-50",on:{"click":function($event){return _vm.toggleOccupation(i)}}},[_c('div',{staticClass:"flex space-x-2 justity-center items-center"},[(!occupation.checked)?_c('img',{staticClass:"w-12 h-12 object-fit rounded-full bg-gray-200",attrs:{"src":occupation.image ||
                    '/static/img/dashboard/occupation-placeholder.svg'}}):_c('div',{staticClass:"w-12 h-12 bg-parqay-primary rounded-full"},[_c('svg-icon',{staticClass:"w-12 h-12 text-white",attrs:{"icon-class":"solid-check"}})],1),_c('span',{staticClass:"text-md font-medium text-center",class:{
                  'text-pink-600': occupation.checked,
                  'text-parqay-primary': !occupation.checked
                }},[_vm._v(_vm._s(occupation.name))])])]):_vm._e()]}),_c('div',{staticClass:"grid grid-cols-2 col-span-2 gap-4"},[_c('div',{staticClass:"px-4 py-2 rounded-xl border hover:border-blue-300 hover:bg-blue-50",on:{"click":function($event){return _vm.toggleOccupation(-1)}}},[_c('div',{staticClass:"flex space-x-2 justity-center items-center"},[(!_vm.model.slice(-1)[0].checked)?_c('img',{staticClass:"w-12 h-12 object-fit rounded-full bg-gray-200",attrs:{"src":_vm.model.slice(-1)[0].image ||
                    '/static/img/dashboard/occupation-placeholder.svg'}}):_c('div',{staticClass:"w-12 h-12 bg-parqay-primary rounded-full"},[_c('svg-icon',{staticClass:"w-12 h-12 text-white",attrs:{"icon-class":"solid-check"}})],1),_c('span',{staticClass:"text-md font-medium text-center",class:{
                  'text-pink-600': _vm.model.slice(-1)[0].checked,
                  'text-parqay-primary': !_vm.model.slice(-1)[0].checked
                }},[_vm._v("Other")])])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.slice(-1)[0].value),expression:"model.slice(-1)[0].value"}],ref:"otherInput",staticClass:"text-md font-medium text-center bg-gray-200 hover:border-indigo-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full h-16 sm:text-sm border-gray-200 rounded-full",attrs:{"id":"other-occupation","type":"text","name":"other-occupation","placeholder":"Please specify","aria-describedby":"other-occupation"},domProps:{"value":(_vm.model.slice(-1)[0].value)},on:{"focus":function($event){return _vm.toggleOccupation(-1, true)},"blur":function($event){return _vm.toggleOccupation(-1, false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model.slice(-1)[0], "value", $event.target.value)}}})])],2)]),_c('button',{staticClass:"text-base bg-parqay-primary hover:bg-blue-500 rounded-lg text-white px-4 py-2",on:{"click":_vm.next}},[_vm._v("\n      Next\n    ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"text-base font-medium text-center"},[_vm._v("First, select the "),_c('span',{staticClass:"text-blue-700"},[_vm._v("best way")]),_vm._v(" to\n      describe your occupation")])}]

export { render, staticRenderFns }