<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["chartData", "options", "lineColor", "areaColor"],
  mounted() {
    this.renderChart(
      {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            data: this.chartData,
            // line color
            borderColor: this.lineColor,
            borderWidth: 3,
            pointRadius: 0,
            // fill color
            backgroundColor: this.areaColor,
          },
        ],
      },
      {
        maintainAspectRatio: false,
        layout: {
          padding: {
            // left: 10,
          },
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
        gridLines: {
          drawTicks: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                if (parseInt(value) >= 1000) {
                  return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '$' + value;
                }
              }
            }
          }]
        }
      }
    );
  },
};
</script>
