<script>
import LandingPage from "src/views/landing-pages/View";
import Marketplace from "src/views/landing-pages/Marketplace";
// import ContactSales from "src/views/landing-pages/ContactSales";
import Pricing from "src/views/landing-pages/Pricing.vue";
import Login from "src/views/auth/Login";
import SignUp from "src/views/auth/SignUp";
import Listing from "src/views/listings/new/View";
import DocsHome from "src/views/docs/View";

import { onMounted, reactive, ref } from "@vue/composition-api";
import { getOriginalPath, shouldPrerenderRedirect } from "src/views/seo/util";

export default {
  components: {
    LandingPage,
    Marketplace,
    // ContactSales,
    Pricing,
    Login,
    SignUp,
    Listing,
    DocsHome,
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const model = reactive({
      PrerenderedLandingPage: "LandingPage",
      PrerenderedMarketplace: "Marketplace",
      // PrerenderedContactSales: "ContactSales",
      PrerenderedPricing: "Pricing",
      PrerenderedLogin: "Login",
      PrerenderedSignUp: "SignUp",
      PrerenderedNewListing: "Listing",
      PrerenderedDocsHome: "DocsHome",
      PrerenderedDocsSections: "DocsHome",
    });
    const targetPage = ref(model[route.name]);
    const originalPath = getOriginalPath(ctx.root.$route.path);
    if (shouldPrerenderRedirect()) {
      window.location.href = originalPath + ctx.root.$route.hash;
      return;
    }
    onMounted(() => {});
    return {
      targetPage,
    };
  },
};
</script>
<template>
  <component :is="targetPage" />
</template>