<script>
import { reactive, ref, computed, onMounted, toRefs } from "@vue/composition-api";
import ListingAvatar from "src/views/components/ListingImageAvatar.vue";
import LeadOutreach from "./LeadOutreach.vue";
import DirectFullfill from "./DirectChatInfoFullfill.vue";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";

import { API } from "./api";
import { API as TenantAPI } from "src/views/tenants/api";

export default {
    components: {
        ListingAvatar,
        LeadOutreach,
        DirectFullfill
    },
    setup(props, ctx) {
        const contactLoading = ref(false);
        const searchLoading = ref(false);
        const conversationLoading = ref(false);
        const startDirectChatLoading = ref(false);
        const searchKeywords = ref(null);
        const contactsList = ref(null);
        const roomsList = ref(null);
        const contacts = ref([]);

        const conversationType = ref("reservation");

        const conversations = ref([]);

        const selectedContact = ref(null);

        const searchKeyword = ref('');

        const newLeadVisible = ref(false);

        const directChatFullfillVisible = ref(false);

        const filteredContacts = computed(() => {
            return contacts.value.filter((contact) => {
                return contact.name.toLowerCase().includes(searchKeyword.value.toLowerCase());
            });
        });

        const pagination = reactive({
            contact: {
                currentPage: 1,
                perPage: 10,
                total: 1
            },
            conversation: {
                currentPage: 1,
                perPage: 10,
                total: 1
            }
        })

        const toggleSearchLoading = debounce(() => {
            searchLoading.value = true;
            getConversationsList();
            filterContacts();
            setTimeout(() => {
                searchLoading.value = false;
            }, 800);
        }, 500, { trailing: true })

        const getTenantsList = async () => {
            try {
                contactLoading.value = true;
                const payload = {
                    page: pagination.contact.currentPage,
                }
                if (searchKeywords.value) {
                    payload.keyword = searchKeywords.value;
                }
                const { data, pagination: pag } = await TenantAPI.list(payload);
                contacts.value = data;
                pagination.contact.currentPage = pag.page;
                pagination.contact.perPage = pag.per_page;
                pagination.contact.total = pag.total;
                contactLoading.value = false;
            } catch (error) {
                console.log("tenants list api -> error: ", error.message);
                contactLoading.value = false;
            }
        };

        const listConversations = async (type) => {
            try {
                conversationLoading.value = true;
                let payload = {
                    type,
                    role: "host",
                    page: pagination.conversation.currentPage,
                }
                if (selectedContact.value && selectedContact.value.id) {
                    payload.filter = {
                        tenant_id: selectedContact.value.id
                    }
                }
                if (searchKeywords.value) {
                    if (payload.filter) {
                        payload.filter["keyword"] = searchKeywords.value;
                    } else {
                        payload.filter = {
                            keyword: searchKeywords.value
                        }
                    }
                }
                const { data: convs, pagination: pag } = await API.listConversations(payload);
                pagination.conversation.currentPage = pag.page;
                pagination.conversation.perPage = pag.per_page;
                pagination.conversation.total = pag.total;
                convs.forEach(async conv => {
                    const { data } = await API.getConversationLastMessage(conv.id);
                    ctx.root.$set(conv, "last_message", data.last_message);
                })
                conversationLoading.value = false;
                return convs;
            } catch (error) {
                console.log("getConversationsList -> error: ", error.message);
                conversationLoading.value = false;
            }
        }

        const getContactConversations = async () => {
            const directConvs = await listConversations("direct");
            const reservationConvs = await listConversations("reservation");
            conversations.value = [...directConvs, ...reservationConvs];
        }

        const getConversationsList = async () => {
            try {
                conversationLoading.value = true;
                let payload = {
                    type: conversationType.value,
                    role: "host",
                    page: pagination.conversation.currentPage,
                }
                if (selectedContact.value && selectedContact.value.id) {
                    payload.filter = {
                        tenant_id: selectedContact.value.id
                    }
                }
                if (searchKeywords.value) {
                    if (payload.filter) {
                        payload.filter["keyword"] = searchKeywords.value;
                    } else {
                        payload.filter = {
                            keyword: searchKeywords.value
                        }
                    }
                }
                const { data: convs, pagination: pag } = await API.listConversations(payload);
                conversations.value = convs;
                pagination.conversation.currentPage = pag.page;
                pagination.conversation.perPage = pag.per_page;
                pagination.conversation.total = pag.total;
                const promises = [];
                conversations.value.forEach(async conv => {
                    const { data } = await API.getConversationLastMessage(conv.id);
                    ctx.root.$set(conv, "last_message", data.last_message);
                    promises.push(data);
                })
                Promise.all(promises).then(() => {
                    console.log('All items processed.');
                }).catch((error) => {
                    console.error('Error processing items:', error);
                });
                conversationLoading.value = false;
            } catch (error) {
                console.log("getConversationsList -> error: ", error.message);
                conversationLoading.value = false;
            }
        };

        const messagesFilter = v => {
            let conditions = true;
            if (selectedContact.value) {
                conditions = conditions && (v.tenant === selectedContact.value.id);
            }
            // if (showEmptyConversations.value) {
            //     conditions = conditions && !v.last_message;
            // } else {
            //     conditions = conditions && v.last_message;
            // }
            return conditions;
        }

        /**
         * Filter the contacts based on a search keyword.
         */
        const filterContacts = () => {
            // const keyword = searchKeywords.value;

            // if (!keyword) {
            //     contacts.value = contactsCopy;
            //     return;
            // }

            getTenantsList();

            // const lowerKeyword = keyword.toLowerCase();
            // contacts.value = contactsCopy.filter((contact) => {
            //     return (
            //         (contact.email && contact.email.toLowerCase().includes(lowerKeyword)) ||
            //         (contact.id && contact.id.toLowerCase().includes(lowerKeyword)) ||
            //         (contact.name && contact.name.toLowerCase().includes(lowerKeyword)) ||
            //         (contact.phone_number && contact.phone_number.toLowerCase().includes(lowerKeyword)) ||
            //         (contact.status && contact.status.toLowerCase().includes(lowerKeyword))
            //     );
            // });
        };

        const handleConversationPageChange = (page) => {
            pagination.conversation.currentPage = page;
            getConversationsList();
        }

        const handleContactPageChange = (page) => {
            pagination.contact.currentPage = page;
            getTenantsList();
        }

        const checkContactPhoneNumber = (contact) => {
            if (contact && !contact.phone_number) {
                directChatFullfillVisible.value = true;
                startDirectChatLoading.value = false;
                return false;
            }
            return true;
        }

        const startDirectChat = async () => {
            try {
                startDirectChatLoading.value = true;
                if (!checkContactPhoneNumber(selectedContact.value)) {
                    return;
                }
                const { data } = await API.getDirectConversation(selectedContact.value.host, selectedContact.value.id, "host");
                ctx.root.$bus.$emit("open-contact-drawer", { room_id: data.id });
                startDirectChatLoading.value = false;
                getContactConversations();
            } catch (error) {
                ctx.root.$openNotification("Direct Chat Error", error.message);
                startDirectChatLoading.value = false;
            }
        }

        const oneNewLeadNext = async ({ contact, loading }) => {
            await getTenantsList();
            selectedContact.value = contact;
            await getContactConversations();
            loading.value = false;
            newLeadVisible.value = false;
        }

        const onDirectFullfillNext = async (contact) => {
            directChatFullfillVisible.value = false;
            selectedContact.value = contact;
            startDirectChat();
        }

        const getRandomInt = () => Math.floor(Math.random() * 50) + 1;


        const openLeadOutreachIfNeeded = () => {
            if (ctx.root.$route.query.lead && JSON.parse(ctx.root.$route.query.lead) === true) {
                newLeadVisible.value = true;
            }
        }

        onMounted(() => {

            openLeadOutreachIfNeeded();

            getTenantsList();

            getConversationsList();

            // Add scroll effect to contacts list
            if (contactsList.value) {
                contactsList.value.addEventListener('scroll', () => {
                    const scrollPercentage = (contactsList.value.scrollTop + contactsList.value.clientHeight) / contactsList.value.scrollHeight * 100;
                    contactsList.value.style.setProperty('--scroll', `${scrollPercentage}%`);
                });
            }

            // Add scroll effect to rooms list
            if (roomsList.value) {
                roomsList.value.addEventListener('scroll', () => {
                    const scrollPercentage = (roomsList.value.scrollTop + roomsList.value.clientHeight) / roomsList.value.scrollHeight * 100;
                    roomsList.value.style.setProperty('--scroll', `${scrollPercentage}%`);
                });
            }
        });

        return {
            contactLoading,
            conversationLoading,
            searchLoading,
            startDirectChatLoading,
            searchKeywords,
            contactsList,
            roomsList,
            contacts,
            conversationType,
            conversations,
            selectedContact,
            searchKeyword,
            newLeadVisible,
            directChatFullfillVisible,
            filteredContacts,
            pagination,
            toggleSearchLoading,
            filterContacts,
            getTenantsList,
            getContactConversations,
            getConversationsList,
            messagesFilter,
            handleContactPageChange,
            handleConversationPageChange,
            checkContactPhoneNumber,
            startDirectChat,
            oneNewLeadNext,
            onDirectFullfillNext,
            getRandomInt
        };
    },
}
</script>
<template>
    <div class="flex h-screen bg-gray-100 border rounded-md overflow-hidden">
        <div class="contactsList w-1/2 bg-white border-r border-gray-200 overflow-y-auto" ref="contactsList">
            <div class="flex items-center space-x-4 p-4 border-b border-gray-200">
                <a-input-search v-model="searchKeywords" :loading="searchLoading"
                    placeholder="Search contact / conversations / lead" allow-clear size="large"
                    @change="toggleSearchLoading()" />
                <a-icon type="reload" :spin="contactLoading" class="pr-1" style="font-size: 1rem;"
                    @click.stop="getTenantsList()" />
            </div>
            <div class="chat-lead h-full py-2 relative">
                <div class="px-4 py-2 cursor-pointer">
                    <div class="flex items-center space-x-6 px-4 py-2 hover:bg-blue-100 rounded-full border shadow-sm"
                        style="min-height: 60px;" @click.stop="() => {
                            newLeadVisible = true;
                        }">
                        <div class="flex items-center justify-center rounded-full bg-gray-100 w-8 h-8 border">
                            <a-icon type="plus" style="font-size: 20px;" />
                        </div>
                        <div class="flex-1">
                            <div class="font-medium">New Lead Outreach</div>
                        </div>
                    </div>
                </div>
                <div v-if="contactLoading" class="absolute inset-0 flex items-center justify-center h-4/5">
                    <a-icon type="loading" style="font-size: 30px;" />
                </div>
                <keep-alive>
                    <transition name="slide-left-right">
                        <ul v-if="!contactLoading">
                            <li v-for="(contact, i) in contacts" :key="`contact-${i}`" class="px-4 py-2 cursor-pointer">
                                <div class="flex items-center space-x-6 px-4 py-2 hover:bg-blue-100 rounded-full border shadow-sm"
                                    :class="{ 'ring-2 ring-gray-500 bg-gray-100': selectedContact && (contact.id === selectedContact.id) }"
                                    style="min-height: 60px;" @click.stop="() => {
                                        selectedContact = contact;
                                        searchKeywords = null;
                                        newLeadVisible = false;
                                        directChatFullfillVisible = false;
                                        getContactConversations();
                                    }">
                                    <avatar :user-id="contact.id" :admin-on-behalf-of="contact.host" />
                                    <div class="flex-1">
                                        <div class="font-medium">{{ contact.name }}</div>
                                    </div>
                                    <a-button
                                        v-if="conversations.filter(v => contact.id === v.tenant && v.last_message).length > 0"
                                        type="link" size="small" style="font-size: 0.75rem; font-weight: 500;">{{
                                            conversations.filter(v => contact.id === v.tenant && v.last_message).length
                                        }}
                                        conversations</a-button>
                                    <div class="flex items-center space-x-3">
                                        <a-tag v-if="contact.status === 'joined'" color="green">Tenant</a-tag>
                                        <a-tag v-if="contact.status === 'invited'" color="cyan">Lead</a-tag>
                                        <!-- <a-badge :count="getRandomInt()" /> -->
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </transition>
                </keep-alive>
                <div v-if="contacts.length > 0 && pagination.contact.total > 0"
                    class="flex items-center justify-center pt-2 pb-4">
                    <a-pagination :current="pagination.contact.currentPage" :total="pagination.contact.total"
                        :page-size="pagination.contact.perPage" @change="handleContactPageChange" />
                </div>
            </div>
        </div>
        <div class="roomsList w-1/2 bg-white overflow-y-auto" ref="roomsList">
            <div v-if="newLeadVisible"
                class="flex items-center justify-center space-x-4 px-4 py-6 border-b border-gray-200 relative">
                <span class="text-sm font-semibold py-0.5">New Lead Outreach</span>
                <a-icon type="close" class="absolute pr-1 my-auto right-4" style="font-size: 1rem;" @click.stop="() => {
                    newLeadVisible = false;
                }" />
            </div>
            <div v-else-if="directChatFullfillVisible"
                class="flex items-center justify-center space-x-4 px-4 py-6 border-b border-gray-200 relative">
                <span class="text-sm font-semibold py-0.5">Starting Direct Chat with <span class="text-parqay-primary">{{
                    selectedContact.name }}</span></span>
                <a-icon type="close" class="absolute pr-1 my-auto right-4" style="font-size: 1rem;" @click.stop="() => {
                    directChatFullfillVisible = false;
                }" />
            </div>
            <div v-else class="flex items-center justify-center space-x-4 px-4 py-6 border-b border-gray-200">
                <span v-if="selectedContact" class="text-sm font-semibold py-0.5">Conversations with {{ selectedContact.name
                }} ({{ conversationLoading ? "..." : conversations.filter(v => v.last_message).length }})</span>
                <span v-else class="text-sm font-semibold py-0.5">{{ searchKeywords ? "Search Results" : "All Conversations"
                }} ({{ conversationLoading ? "..." : pagination.conversation.total
}})</span>
                <a-icon v-if="selectedContact" type="reload" :spin="conversationLoading" class="pr-1"
                    style="font-size: 1rem;" @click.stop="getContactConversations()" />
                <a-icon v-else type="reload" :spin="conversationLoading" class="pr-1" style="font-size: 1rem;"
                    @click.stop="getConversationsList()" />
            </div>
            <div v-if="!selectedContact && !newLeadVisible" class="flex items-center justify-center px-4 pt-4">
                <a-radio-group v-model="conversationType" button-style="solid" @change="() => {
                    getConversationsList();
                }">
                    <a-radio-button value="reservation">
                        <div class="flex items-center space-x-2">
                            <span class="">Reservation Chat</span>
                            <!-- <div v-if="emails.inbox.filter(v => v.is_read === false).length > 0"
                                class="rounded flex items-center justify-center text-xs font-semibold text-blue-500 bg-white border border-blue-500 px-1"
                                style="padding-top: 1px; padding-bottom: 1px;">
                                {{ emails.inbox.filter(v => v.is_read === false).length }}
                            </div> -->
                        </div>
                    </a-radio-button>
                    <a-radio-button value="direct">
                        Direct Chat
                    </a-radio-button>
                </a-radio-group>
            </div>
            <div class="chat-lead h-full py-2 relative">
                <transition name="slide-left-right">
                    <lead-outreach v-if="newLeadVisible" @next="oneNewLeadNext" />
                    <direct-fullfill v-if="directChatFullfillVisible" :contact="selectedContact"
                        @next="onDirectFullfillNext" />
                </transition>
                <div v-if="conversationLoading" class="absolute inset-0 flex items-center justify-center h-4/5">
                    <a-icon type="loading" style="font-size: 30px;" />
                </div>
                <keep-alive>
                    <template v-if="!(newLeadVisible || directChatFullfillVisible)">
                        <transition name="slide-left-right">
                            <div v-if="!conversationLoading" class="flex flex-col">
                                <ul>
                                    <li v-if="selectedContact" class="flex items-center px-4 py-2 space-x-4 cursor-pointer">
                                        <div class="flex-shrink-0 flex w-1/2 items-center rounded-lg space-x-4 px-4 py-4 hover:bg-blue-100"
                                            @click.stop="() => {
                                                selectedContact = null;
                                                searchKeywords = null;
                                                getConversationsList();
                                                filterContacts();
                                            }">
                                            <a-icon type="left" />
                                            <span class="text-blue-500 font-medium">Show All Conversations</span>
                                        </div>
                                        <a-button
                                            v-if="!conversations.find(v => v.tenant === selectedContact.id && v.type === 'direct')"
                                            :loading="startDirectChatLoading" icon="plus" block size="large"
                                            style="display: flex; align-items: center; justify-content: center;"
                                            @click.stop="startDirectChat()">Start A
                                            Direct
                                            Chat</a-button>
                                    </li>
                                    <li v-for="(conversation, i) in conversations.filter(messagesFilter)"
                                        :key="`conversation-${i}`" class="px-4 pt-2 cursor-pointer" @click.stop="() => {
                                            if (conversation.type === 'direct') {
                                                if (!selectedContact) {
                                                    selectedContact = contacts.find(v => v.id === conversation.tenant);
                                                }
                                                if (!checkContactPhoneNumber(selectedContact)) {
                                                    return;
                                                }
                                            }
                                            $bus.$emit('open-contact-drawer', { room_id: conversation.id });
                                        }">
                                        <div class="flex items-center space-x-6 px-4 pt-3 pb-3 hover:bg-blue-100 rounded-lg border shadow-sm"
                                            style="min-height: 60px;">
                                            <listing-avatar v-if="conversation.listing_id"
                                                :listing-id="conversation.listing_id" :tooltip-item-size="30"
                                                tooltip-placement="bottom" />
                                            <avatar v-if="conversation.type === 'direct'" :user-id="conversation.tenant"
                                                :admin-on-behalf-of="conversation.host" />
                                            <div class="flex-1 space-y-2">
                                                <div v-if="conversation.type === 'direct'"
                                                    class="flex items-center space-x-2">
                                                    <a-tag color="green">Direct Chat</a-tag>
                                                    <span class="text-sm font-semibold">{{ conversation.tenant_name
                                                    }}</span>
                                                </div>
                                                <div v-if="conversation.name" class="flex items-center space-x-2">
                                                    <div class="font-semibold">{{ conversation.name }}</div>
                                                    <a-badge v-if="!$deepGet(conversation, 'last_message.is_read', true)"
                                                        count="New" />
                                                </div>
                                                <div v-if="conversation.reservation" class="flex items-center space-x-3">
                                                    <span>Tenant</span>
                                                    <a-tag>{{ conversation.reservation.tenant_name }}</a-tag>
                                                </div>
                                                <div v-if="conversation.last_message"
                                                    class="text-sm text-gray-500 truncate py-1"
                                                    :class="{ 'font-medium text-gray-800': !$deepGet(conversation, 'last_message.is_read', true) }"
                                                    style="max-width: 260px;"><span class="mr-1">{{
                                                        conversation.last_message.sender_name
                                                    }}:</span>{{
    conversation.last_message.message }}</div>
                                            </div>
                                            <div v-if="conversation.last_message"
                                                class="flex-shrink-0 text-sm text-gray-500">
                                                {{ $formatDate($deepGet(conversation,
                                                    "last_message.dt") || $deepGet(conversation,
                                                        "last_message.created_at.$date"), null, true)
                                                }}
                                            </div>
                                            <div v-else>
                                                <a-button icon="message" style="display: flex; align-items: center;">Start A
                                                    Chat</a-button>
                                            </div>
                                        </div>
                                        <a-divider v-if="conversation.type === 'reservation'"
                                            style="margin: 0px; margin-top: 0.5rem;">
                                            <div class="text-xs text-parqay-primary">
                                                <span>
                                                    Booked on {{ $formatDate($deepGet(conversation,
                                                        "created_at.$date", new Date()), null, true)
                                                    }}
                                                </span>
                                            </div>
                                        </a-divider>
                                        <div v-else class="h-4 w-full"></div>
                                    </li>
                                </ul>
                                <div v-if="conversations.length > 0 && pagination.conversation.total > 0"
                                    class="flex items-center justify-center pt-2 pb-4">
                                    <a-pagination :current="pagination.conversation.currentPage"
                                        :total="pagination.conversation.total" :page-size="pagination.conversation.perPage"
                                        @change="handleConversationPageChange" />
                                </div>
                            </div>
                        </transition>
                    </template>
                </keep-alive>
            </div>
        </div>
    </div>
</template>
<style scoped>
/* Add any custom styles here */
.contactsList::-webkit-scrollbar,
.roomsList::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.contactsList::-webkit-scrollbar-thumb,
.roomsList::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}

.contactsList::-webkit-scrollbar-track,
.roomsList::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
}

.contactsList::-webkit-scrollbar-thumb:hover,
.roomsList::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

.contactsList::-webkit-scrollbar-thumb:active,
.roomsList::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.6);
}

.contactsList::-webkit-scrollbar-corner,
.roomsList::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0.1);
}

.contactsList {
    --scroll: 0%;
}

.roomsList {
    --scroll: 0%;
}

.contactsList::before {
    content: '';
    position: absolute;
    top: var(--scroll);
    left: 0;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
}

.roomsList::before {
    content: '';
    position: absolute;
    top: var(--scroll);
    left: 0;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
}
</style>
<style lang="scss">
// Transitions
.chat-lead {
    .slide-left-right-enter-active {
        transition: transform 0.3s ease, opacity 0.3s ease;
        will-change: transform, opacity;
    }

    .slide-left-right-leave-active {
        transition: transform 0.3s ease, opacity 0.3s ease;
        will-change: transform, opacity;
    }

    .slide-left-right-enter,
    .slide-left-right-leave-to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        will-change: transform, opacity;
    }
}
</style>