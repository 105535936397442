<script>
import ImagesUploader from "src/views/components/ImagesUploader";
import ListingSettings from "src/views/settings/components/Listing.vue";
import Dropdown from "src/views/dropdowns/View";
import { reactive, ref, onMounted } from "@vue/composition-api";
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';

import { API } from "src/views/settings/api";

export default {
  name: "ListingDetails",
  components: {
    ImagesUploader,
    Dropdown,
    ListingSettings
  },
  setup(props, ctx) {
    const openNotification = ctx.root.$openNotification;
    const controls = reactive({
      loading: false,
      visible: false
    })
    const settings = reactive({
      autofill: {
        about_host: {
          is_active: false,
          content: null
        },
        things_your_guests_should_know: {
          is_active: false,
          content: null
        }
      }
    });
    const aboutHost = ref("");
    const thingsShouldKnow = ref("");
    const nearbyLandmarks = ref("");
    const howDoGuestsEnterInstruction = ref("");
    const imagesUploader = ref(null);
    const imagesDataUrls = ref([]);
    const homeTitle = ref("");
    const homeOrRoom = ref("Entire Place");
    const roomNumber = ref(1);
    const peopleNumberToHost = ref(1);
    const bathroomNumber = ref(1);
    const spaceInformation = ref("apartment");
    const spaceInformationOptions = ref([
      { id: 0, name: "Apartment" },
      { id: 1, name: "Condo" },
      { id: 2, name: "House" },
      { id: 3, name: "Event Space" }
    ]);
    const selectSpaceInformation = (i, m) => {
      const spaceInformationName = lowerCase(m.name);
      spaceInformation.value = spaceInformationName;
    };
    const amenities = reactive({
      space: {
        "A/C": false,
        Heat: false,
        Wifi: false,
        "Desk/workspace": false,
        "Washer and dryer": false
      },
      kitchen: {
        Fridge: false,
        "Gas stove": false,
        "Electric stove": false,
        "Pots and pans": false,
        "Dish washer": false
      },
      bathroom: {
        Shampoo: false,
        Towels: false,
        "Hair dryer": false,
        Iron: false
      },
      safety_items: {
        "Fire extinguisher": false,
        "CO & Smoke detector": false,
        "First aid kit": false
      }
    });
    const getAllImages = () => {
      imagesDataUrls.value = imagesUploader.value.getAllAddedImagesDataUrls();
      console.log("imagesDataUrls", imagesDataUrls.value);
    };
    const next = () => {
      getAllImages();
      const verified = verifyFields();
      if (verified) {
        // Note: images storage requires indexedDB (big files)
        ctx.root.$setValueForIndexedDB(
          "listing-new-images",
          imagesDataUrls.value
        );
        // Note: normal payload storage uses Vuex Store
        ctx.emit("payload-update", "listing-details", assemblePayloads());
        ctx.emit("next");
      }
    };
    const assemblePayloads = () => {
      // Backend is using snake-case as its framework is Python
      return {
        title: homeTitle.value,
        home_or_room: homeOrRoom.value,
        space_type: spaceInformation.value,
        room_number: roomNumber.value,
        people_number_to_host: peopleNumberToHost.value,
        bathroom_number: bathroomNumber.value,
        // Convert Proxy back to Object, use Lodash method - cloneDeep
        amenities: cloneDeep(amenities.space),
        kitchen: cloneDeep(amenities.kitchen),
        bathroom: cloneDeep(amenities.bathroom),
        safety_items: cloneDeep(amenities.safety_items),
        how_do_guests_enter_instruction: howDoGuestsEnterInstruction.value,
        nearby_landmarks: nearbyLandmarks.value,
        things_your_guests_should_know: thingsShouldKnow.value,
        about_host: aboutHost.value
      };
    };
    const verifyAmenities = () => {
      var verified = true;
      forOwn(amenities, (v, k) => {
        var checkedCount = 0;
        forOwn(v, (vv, kk) => {
          if (vv) {
            checkedCount += 1;
          }
        });
        if (checkedCount === 0) {
          openNotification(
            "Stay Listing Amenities",
            `At least one item should be checked in ${startCase(k)} category`,
            10,
            "warning"
          );
          verified = false;
          return false;
        }
      });
      return verified;
    };
    const verifyFields = () => {
      // return true;

      if (!homeTitle.value) {
        openNotification(
          "Stay Listing Title",
          `Listing title is required`,
          10,
          "warning"
        );
        return false;
      }

      if (!verifyAmenities()) {
        return false;
      }

      if (imagesDataUrls.value.length < 1) {
        openNotification(
          "Stay Listing Images",
          `At least 1 image is required`,
          10,
          "warning"
        );
        return false;
      }

      if (!howDoGuestsEnterInstruction.value) {
        openNotification(
          "Stay Listing Information",
          `"How do guests get to your stay" is required field`,
          10,
          "warning"
        );
        return false;
      }

      if (!nearbyLandmarks.value) {
        openNotification(
          "Stay Listing Information",
          `"Nearby Landmarks" is required field`,
          10,
          "warning"
        );
        return false;
      }

      if (!settings.autofill.about_host && !aboutHost.value) {
        openNotification(
          "Parking Space Information",
          `"About Yourself" is required field`,
          10,
          "warning"
        );
        return false;
      }

      if (!settings.autofill.things_your_guests_should_know.is_active && !thingsShouldKnow.value) {
        openNotification(
          "Parking Space Information",
          `"Things Guests Should Know" is required field`,
          10,
          "warning"
        );
        return false;
      }

      return true;
    };
    const parseListingSettings = ({ about_host, things_your_guests_should_know }) => {
      if (about_host) {
        settings.autofill.about_host = about_host;
      } else {
        settings.autofill.about_host.is_active = false;
        settings.autofill.about_host.content = null;
      }

      if (things_your_guests_should_know) {
        settings.autofill.things_your_guests_should_know = things_your_guests_should_know;
      } else {
        settings.autofill.things_your_guests_should_know.is_active = false;
        settings.autofill.things_your_guests_should_know.content = null;
      }
    }

    const getListingSettings = async () => {
      controls.loading = true;
      try {
        const { data: { listing } } = await API.getListingSettings("host");

        parseListingSettings(listing);

        controls.loading = false;
      } catch (error) {
        controls.loading = false;
      }
    };
    onMounted(() => {
      getListingSettings();
    });
    return {
      controls,
      settings,
      imagesUploader,
      homeTitle,
      homeOrRoom,
      roomNumber,
      peopleNumberToHost,
      bathroomNumber,
      spaceInformation,
      spaceInformationOptions,
      selectSpaceInformation,
      getListingSettings,
      amenities,
      aboutHost,
      thingsShouldKnow,
      nearbyLandmarks,
      howDoGuestsEnterInstruction,
      next,
      startCase
    };
  }
};
</script>
<template>
  <div class="stay-space-details flex flex-col px-4 py-6">
    <div class="flex items-center justify-between border-b px-4">
      <h2 class="my-3 text-center text-lg text-parqay-primary">
        Stay Listing Details
      </h2>
      <button class="px-4 py-2 bg-parqay-primary text-white rounded" @click="next">
        {{ $t("dashboard.continue") }}
      </button>
    </div>
    <div class="flex">
      <div class="w-3/5 border-r py-4">
        <div class="grid grid-cols-2 gap-4 px-6">
          <div class="flex flex-col space-y-2 col-span-2">
            <span class="text-base font-medium">{{
              $t("spaceListing.listing")
            }}</span>
            <a-input v-model="homeTitle" size="large" placeholder="Ex: Studio in the heart of Nice" />
          </div>
          <div class="flex flex-col space-y-2 col-span-2">
            <span class="text-base font-medium"> Rental Type </span>
            <a-radio-group v-model="homeOrRoom">
              <a-radio-button v-for="(guestOption, i) in $t(
                'default.spaceListing.guestOptions',
                {
                  returnObjects: true
                }
              ).filter(o => o !== 'Office/Desk')" :key="`trans-type-${i}`" :value="guestOption">
                {{ guestOption }}
              </a-radio-button>
            </a-radio-group>
          </div>
          <div class="flex space-x-2 items-center justify-between col-span-2">
            <div class="flex flex-col space-y-1">
              <span class="text-base font-medium">{{
                $t("spaceListing.info")
              }}</span>
              <span>Which of the following best describe your property</span>
            </div>
            <dropdown :model="spaceInformationOptions" :width="'64'" @dropdown-select="selectSpaceInformation" />
          </div>
        </div>

        <div class="
            grid grid-cols-3
            gap-2
            mt-6
            pb-4
            mx-6
            py-3
            border
            rounded-lg
            bg-white
          ">
          <div class="flex flex-col space-y-2 items-center">
            <span>{{ $t("spaceListing.rooms") }}</span>
            <a-input-number v-model="roomNumber" size="large" />
          </div>
          <div class="flex flex-col space-y-2 items-center">
            <span>{{ $t("spaceListing.people") }}</span>
            <a-input-number v-model="peopleNumberToHost" size="large" />
          </div>
          <div class="flex flex-col space-y-2 items-center">
            <span>{{ $t("spaceListing.bathroom") }}</span>
            <a-input-number v-model="bathroomNumber" size="large" />
          </div>
        </div>

        <div class="px-6 py-4 border-t mt-6">
          <h2 class="text-base font-medium text-center">
            {{ $t("spaceListing.what") }}
          </h2>
          <div class="grid grid-cols-2 gap-4 mt-4">
            <div v-for="(amenityOptions, amenityName, i) in amenities" :key="`amenity-group-${i}`"
              class="flex flex-col space-y-2 items-left">
              <span class="text-base font-medium">{{
                startCase(amenityName)
              }}</span>
              <a-checkbox v-for="(v, k, j) in amenityOptions" :key="`amenity-option-${j}`" v-model="amenityOptions[k]">
                {{ k }}
              </a-checkbox>
            </div>
          </div>
        </div>
        <div class="px-6 py-4 border-t flex items-start justify-center space-x-4" style="min-height: 150px;">
          <a-icon v-if="controls.loading" type="loading" style="font-size: 35px;" />
          <template v-else>
            <label-item label="About Yourself"
              desc="Inform your tenants about yourself as a host. This setting populates as &quot;About Host&quot; section in listing search result."
              class="w-1/2">
              <div class="flex items-center space-x-2 cursor-pointer" slot="actions"
                @click.stop="() => controls.visible = true">
                <div class="w-2 h-2 rounded-full"
                  :class="settings.autofill.about_host.is_active ? 'bg-green-400' : 'bg-pink-500'" />
                <span class="text-xs font-semibold"
                  :class="settings.autofill.about_host.is_active ? 'text-green-400' : ''">Autofill</span>
                <a-button type="link" icon="edit" />
              </div>
              <div v-if="settings.autofill.about_host.is_active"
                class="border border-gray-300 rounded px-4 py-2 text-sm font-medium">
                <span class="leading-6">
                  {{ settings.autofill.about_host.content }}
                </span>
                <a-button type="link" icon="edit" size="small" @click.stop="() => controls.visible = true">Edit</a-button>
              </div>
              <a-textarea v-else v-model="aboutHost" placeholder="Tell tenants more about yourself as a host."
                :auto-size="{ minRows: 4, maxRows: 4 }" />
            </label-item>
            <label-item label="Things your guests should know"
              desc="Enter any essential information for tenants. This setting shows in &quot;Things your guests should know&quot; section in listing search result."
              class="w-1/2">
              <div class="flex items-center space-x-2 cursor-pointer" slot="actions"
                @click.stop="() => controls.visible = true">
                <div class="w-2 h-2 rounded-full"
                  :class="settings.autofill.things_your_guests_should_know.is_active ? 'bg-green-400' : 'bg-pink-500'" />
                <span class="text-xs font-semibold"
                  :class="settings.autofill.things_your_guests_should_know.is_active ? 'text-green-400' : ''">Autofill</span>
                <a-button type="link" icon="edit" />
              </div>
              <div v-if="settings.autofill.things_your_guests_should_know.is_active"
                class="border border-gray-300 rounded px-4 py-2 text-sm font-medium">
                <span class="leading-6">
                  {{ settings.autofill.things_your_guests_should_know.content }}
                </span>
                <a-button type="link" icon="edit" size="small" @click.stop="() => controls.visible = true">Edit</a-button>
              </div>
              <a-textarea v-else v-model="thingsShouldKnow"
                placeholder="Enter any info your tenants need to pay special attention."
                :auto-size="{ minRows: 4, maxRows: 4 }" />
            </label-item>
          </template>
        </div>
        <a-modal v-model="controls.visible" title="Autofill Settings" width="1000px" destroy-on-close :footer="null"
          @cancel="() => controls.visible = false">
          <listing-settings no-title @refresh="() => {
            controls.visible = false;
            getListingSettings();
          }" />
        </a-modal>
        <div class="px-6 py-4 border-t flex space-x-4">
          <div class="flex flex-col w-1/2 space-y-2">
            <span class="text-base font-medium">{{ $t("dashboard.how") }}</span>
            <a-textarea v-model="howDoGuestsEnterInstruction"
              placeholder="This will show up in “Getting There” section in stay search. Enter any specific guidance to assistant guests enter this place"
              :auto-size="{ minRows: 6, maxRows: 6 }" />
          </div>
          <div class="flex flex-col w-1/2 space-y-2">
            <span class="text-base font-medium">Nearby Landmarks</span>
            <a-textarea v-model="nearbyLandmarks" placeholder="Provide descriptions for nearby landmarks"
              :auto-size="{ minRows: 6, maxRows: 6 }" />
          </div>
        </div>
      </div>
      <div class="
          w-2/5
          flex flex-col
          p-4
          mx-4
          my-2
          shadow-sm
          border
          bg-white
          rounded-lg
        ">
        <h2 class="mb-3 text-center">
          {{ $t("general.photos") }}
        </h2>
        <p class="text-muted add-photos-description">
          {{ $t("spaceListing.photoDetails") }}
        </p>
        <div class="flex-grow-0 justify-center items-center">
          <images-uploader ref="imagesUploader" />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end mb-5 py-2 px-4 border-t">
      <button class="px-4 py-2 bg-parqay-primary text-white rounded" @click="next">
        {{ $t("dashboard.continue") }}
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.stay-space-details ::v-deep .ant-checkbox-wrapper {
  margin-left: 0px;
}

.stay-space-details ::v-deep .ant-checkbox-wrapper {
  margin-left: 0px;
}

.stay-space-details ::v-deep .ant-input-number {
  width: 80%;
}

.stay-space-details ::v-deep .ant-input-number-input {
  text-align: center;
}
</style>
