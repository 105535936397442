<script>
import { reactive } from "@vue/composition-api";
import { getDefaultModel } from "src/views/listings/new/car-rentals/components/util";
import startCase from 'lodash/startCase';

export default {
  props: {
    model: {
      type: Array,
      default: () => {
        return getDefaultModel();
      },
    },
  },
  setup(props, ctx) {
    const table = reactive({
      headers: [
        {
          title: "Type",
          key: "type",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "Price",
          key: "price",
          dataIndex: "price",
          scopedSlots: { customRender: "price" },
        },
        {
          title: "Mileage",
          key: "mileage",
          dataIndex: "mileage",
          scopedSlots: { customRender: "mileage" },
        },
        {
          title: "Off/On",
          key: "enabled",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
      ],
      items: props.model,
    });
    return {
      table,
      startCase,
    };
  },
};
</script>
<template>
  <div
    class="flex flex-col rounded-lg bg-white shadow-sm border overflow-hidden"
  >
    <a-table
      :columns="table.headers"
      :data-source="table.items"
      :pagination="false"
      :row-key="(record, index) => `${index}`"
    >
      <span
        slot="type"
        slot-scope="type"
      >
        <a-tag color="geekblue">
          {{ startCase(type) }}
        </a-tag>
      </span>
      <span
        slot="price"
        slot-scope="price"
      >
        <a-input-number
          :default-value="price"
          :formatter="
            (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
          :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
        />
      </span>
      <span
        slot="mileage"
        slot-scope="mileage"
      >
        <a-input-number
          :default-value="mileage"
          :formatter="
            (value) => `${value}mi`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
          :parser="(value) => value.replace(/\mi\s?|(,*)/g, '')"
        />
      </span>
      <span
        slot="enabled"
        slot-scope="enabled, record"
      >
        <a-switch
          v-model="record.enabled"
          checked-children="On"
          un-checked-children="Off"
        />
      </span>
    </a-table>
    <slot />
  </div>
</template>