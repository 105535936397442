<script>
export default {
  props: {
    controls: {
      type: Object,
    },
    pricing: {
      type: Object,
    },
    timeFormat: {
      type: Object,
    },
    hideReview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const dismiss = () => (props.controls.showPricingDetails = false);
    const openIntercom = () => {
      // window.Intercom("show");
    };
    return {
      dismiss,
      openIntercom,
    };
  },
};
</script>
<template>
  <a-modal
    title="Pricing Details"
    :visible="controls.showPricingDetails"
    :body-style="{ background: 'rgba(244, 245, 247, 1)' }"
    @ok="
      () => {
        $emit('dismiss');
        dismiss();
      }
    "
    @cancel="
      () => {
        $emit('dismiss');
        dismiss();
      }
    "
  >
    <div class="flex flex-col space-y-8 pt-4 pb-8">
      <div
        v-for="(rd, i) in pricing.rates_details"
        :key="`pricing-details-${i}`"
        class="
          flex flex-col
          space-y-4
          px-6
          py-4
          text-xl
          font-bold
          text-parqay-primary
          shadow-lg
          hover:ring-2
          ring-parqay-primary
          cursor-pointer
          rounded-xl
          justify-between
          items-center
          bg-white
        "
      >
        <span class="whitespace-nowrap">{{ rd.title }}</span>
        <div
          v-for="(interval, j) in rd.intervals"
          :key="`pricing-intervals-${j}`"
          class="flex items-center space-x-4"
        >
          <div class="text-xs rounded-full bg-parqay-primary text-white font-light px-4 py-2 shadow-inner">
            {{ $parseBackendDate(interval.start, timeFormat.default, false) }}
          </div>
          <svg-icon
            icon-class="arrow-circle-right"
            class="text-parqay-primary w-6 h-6"
          />
          <div class="text-xs rounded-full bg-parqay-primary text-white font-light px-4 py-2 shadow-inner">
            {{ $parseBackendDate(interval.end, timeFormat.default, false) }}
          </div>
        </div>
      </div>
      <div class="flex flex-col divide-y border rounded-xl bg-white">
        <div class="flex w-full justify-between items-center py-4 px-4">
          <span class="text-lg text-black font-medium text-left">Price</span>
          <span class="text-lg text-gray-600 font-medium text-right">{{ $getPrice(pricing.price) }}</span>
        </div>
        <div class="flex w-full justify-between items-center py-4 px-4">
          <span class="text-lg text-black font-medium text-left">Fee</span>
          <span class="text-lg text-gray-600 font-medium text-right">{{ $getPrice(pricing.fee) }}</span>
        </div>
        <div class="flex justify-end text-xl text-right space-x-4 items-center py-6 px-4">
          <span class="text-black font-semibold">Total</span>
          <span class="text-parqay-primary font-bold">{{ $getPrice(pricing.total) }}</span>
        </div>
      </div>
    </div>
    <template #footer>
      <a-button @click="openIntercom">
        Ask a question
      </a-button>
      <a-button
        v-if="!hideReview"
        type="primary"
        @click="
          () => {
            $emit('review-order');
            dismiss();
          }
        "
      >
        Review Order
      </a-button>
      <a-button
        v-else
        type="primary"
        @click="
          () => {
            dismiss();
          }
        "
      >
        OK
      </a-button>
    </template>
  </a-modal>
</template>