<script>
import moment from "moment";
import { toRefs } from "@vue/composition-api";
import PricingRulesTable from "./components/EventPricingRulesTable";
import { API } from "src/views/listings/api";
import cloneDeep from "lodash/cloneDeep";
import { convertToCumulativeConditionsIfNeeded } from "./components/util";

export default {
    props: {
        controls: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        },
        embed: {
            type: Boolean,
            default: false
        },
        createMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PricingRulesTable
    },
    setup(props, ctx) {

        const { events } = toRefs(props.form);

        const applySign = (signStr, number) => {
            if (signStr !== '+' && signStr !== '-') {
                throw new Error("Sign string must be '+' or '-'");
            }
            return signStr === '+' ? Math.abs(number) : -Math.abs(number);
        };

        // Correct previous versions without adjustments param "sign".
        events.value.forEach(event => {
            event.adjustments.forEach(adjustment => {
                const { sign } = adjustment;
                if (!sign) {
                    ctx.root.$set(adjustment, "sign", "+");
                }
            })
        });

        const ruleUpdatesRaw = {
            event: {
                name: null,
                address: null
            },
            interval: {
                start: null,
                end: null
            },
            price: null,
            adjustments: null
        }

        const resetRuleUpdates = (event) => {
            ctx.root.$set(event, "$updates", cloneDeep(ruleUpdatesRaw));
        }

        if (!props.createMode) {
            events.value.forEach(event => {
                ctx.root.$set(event, "$loading", false);
                ctx.root.$set(event, "$editing", false);
                ctx.root.$set(event, "$updates", cloneDeep(ruleUpdatesRaw));
            })
        }

        const editEvents = () => {
            // Add timeout to prevent v-click-outside to stop expansion.
            setTimeout(() => {
                props.controls.expand.selectedEvent = true;
            }, 500);
        }

        /**
         * Transforms an array of adjustments into a dictionary grouped by type.
         * @param {Array} adjustments - The array of adjustment objects.
         * @returns {Object} - Dictionary with keys as unique types and values as arrays of adjustments.
         */
        const transformAdjustments = (adjustments) => {
            return adjustments.reduce((acc, adjustment) => {
                if (!acc[adjustment.type]) {
                    acc[adjustment.type] = [];
                }
                const found = acc[adjustment.type].find(a => a.tier === adjustment.tier);
                if (!found) {
                    acc[adjustment.type].push({
                        tier: adjustment.tier,
                        adjustment: adjustment.adjustment
                    });
                }
                return acc;
            }, {});
        };

        /**
         * Creates a pricing rule structure object.
         * @param {Object} event - The event data including name, price, datetime, and adjustments.
         * @param {number} priority - The priority level of the pricing structure.
         * @returns {Object} - The price structure object.
         */
        const createPriceStructure = (event, priority) => {
            // Transform event.adjustments array into a dictionary grouped by type
            const adjustments = convertToCumulativeConditionsIfNeeded(event.adjustments);
            const groupedAdjustments = transformAdjustments(adjustments);

            // Prepare the description based on unique types
            const uniqueTypes = Object.keys(groupedAdjustments);
            const description = uniqueTypes.map(t => `${t.charAt(0).toUpperCase()}${t.slice(1)}-Based`);

            const eventDate = moment(event.datetime_local).startOf('day');
            const start = eventDate.format('YYYY-MM-DDTHH:mm');

            // Calculate the start of the next day (00:00 next day)
            const end = eventDate.add(1, 'days').format('YYYY-MM-DDTHH:mm');

            // Create the intervals array with the calculated start and end times
            const intervals = [{
                start,
                end
            }];

            // Construct and return the structured data
            return {
                id: ctx.root.$shortUID(),
                coverage_title: "ALL Spaces",
                coverage: "all",
                title: ["Event Pricing", event.name],
                event: `${event.id}`,
                price: event.price,
                price_adjustments: groupedAdjustments,
                intervals: intervals,
                is_active: true,
                type: "daily",
                priority: priority,
                pricing_rule: {
                    type: "tiered.event",
                    description: description,
                    type_name: "Event Tiered"
                },
                usage_and_insights: {
                    used: 0,
                    sold: 0,
                    last_updated: new Date().toISOString().split('.')[0]
                }
            };
        };

        /**
         * Generates a humanized description of a pricing adjustment based on volume or size.
         * @param {Object} adjustment - A single adjustment rule.
         * @param {number} basePrice - The base price before adjustments.
         * @returns {string} A humanized description for the adjustment rule.
         */
        const humanizePricingAdjustment = (adjustment, basePrice) => {
            // Define a mapping of vehicle size types to emojis.
            const sizeEmojis = {
                "oversized": "🚚",
                "motorcycle": "🏍️",
                "compact": "🚗",
                "sedan": "🚘",
                // Add more mappings as needed...
            };

            const describeTier = tier => (
                tier.startsWith('<=') ? `less than ${tier.slice(2)}` :
                    tier.startsWith('<') ? `less than ${tier.slice(1)}` :
                        tier.startsWith('>=') ? `more than ${tier.slice(2)}` :
                            tier.startsWith('>') ? `more than ${tier.slice(1)}` :
                                tier
            );

            const calculateAdjustedPrice = (basePrice, adjustment) => basePrice + applySign(adjustment.sign, adjustment.adjustment);

            let description = '';
            let emoji = '';
            if (adjustment.type === "size") {
                emoji = sizeEmojis[adjustment.tier] || ""; // Use the emoji from the mapping if available, otherwise default to an empty string.
                description += `${emoji} When a ${adjustment.tier} vehicle is used, `;
            } else if (adjustment.type === "volume") {
                const maxEmojis = 5;
                const maxTierValue = 20; // Define the maximum tier value that will show 5 car emojis.
                let tierValue = parseInt(adjustment.tier.slice(2), 10);

                // Determine the number of car emojis to display based on the tier value.
                let emojiCount = 0;
                if (tierValue < maxTierValue) {
                    // Calculate the ratio of the current tier value to the maximum tier value and invert it.
                    emojiCount = maxEmojis - Math.floor((tierValue / maxTierValue) * maxEmojis);
                } else {
                    emojiCount = maxEmojis; // Display all 5 emojis if the tier value is above the maximum threshold.
                }

                emoji = '🚗'.repeat(Math.max(0, emojiCount)); // Ensure we don't repeat the emoji a negative number of times.
                description += `${emoji} When there are ${describeTier(adjustment.tier)} spaces remaining, `;
            }

            const priceDescription = `the price will be $${calculateAdjustedPrice(basePrice, adjustment)}`;
            return `${description}${priceDescription}.`;
        };

        const updateEventPricingRule = async (event) => {
            try {
                event.$loading = true;
                await API.updateEventPricingRule(props.form.id, event.id, event.$updates);
                resetRuleUpdates(event);
                event.$loading = false;
                event.$editing = false;
            } catch (error) {
                ctx.root.$openNotification("Pricing Rule", error.message);
                event.$loading = false;
            }
        }

        const assemblePayloads = () => {
            let pricingRules = [];
            events.value.forEach((event, i) => {
                const priceRule = createPriceStructure(event, i + 1);
                pricingRules.push(priceRule);
            })
            return pricingRules;
        };

        return {
            events,
            editEvents,
            resetRuleUpdates,
            humanizePricingAdjustment,
            applySign,
            updateEventPricingRule,
            assemblePayloads
        };
    }
};
</script>
<template>
    <div class="w-full">
        <div v-if="!embed" class="flex justify-between w-full">
            <div class="flex items-center space-x-4 justify-start">
                <span class="text-base font-medium">Pricing Rules</span>
                <a-button edit icon="edit" @click="editEvents()">
                    Edit Events
                </a-button>
            </div>
        </div>
        <div class="flex flex-col" :class="embed ? '' : 'mt-4 pb-24 space-y-2'">
            <div class="divide-y" style="min-height: 200px;">
                <div v-for="(event, i) in events" :key="i" class="flex flex-col pb-3 space-y-4 bg-white cursor-pointer"
                    :class="embed ? '' : 'rounded-md my-2'">
                    <div class="flex items-center justify-center py-4 border-b relative" :class="{
            'bg-gray-50': !(createMode || event.$editing)
        }">
                        <span class="text-center text-black font-semibold ">Event #{{ i + 1 }} Rule</span>
                        <div v-if="!createMode" class="absolute right-4 top-1/2 transform -translate-y-1/2">
                            <div class="flex items-center space-x-2">
                                <a-button v-if="!event.$editing" icon="edit" @click="() => event.$editing = true" />
                                <template v-else>
                                    <a-button edit type="primary" icon="check" :loading="event.$loading" @click="() => {
            updateEventPricingRule(event);
        }">
                                        Save Updates
                                    </a-button>
                                    <a-button edit icon="close" :loading="event.$loading" @click="() => {
            resetRuleUpdates(event);
            event.$editing = false;
        }" />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center px-5 py-2 space-x-4">
                        <div class="flex-shrink-0 flex flex-col space-y-2" :class="{
            'pt-6': createMode || event.$editing
        }">
                            <div class="bg-gray-200 rounded-md overflow-hidden" style="width: 150px; height: auto;">
                                <img class="object-cover" :src="event.image || event.img_url">
                            </div>
                        </div>
                        <div class="flex flex-col space-y-2 w-full">
                            <div v-if="createMode || event.$editing" class="flex space-x-4">
                                <label-item label="Event Name" class="flex-grow w-2/3">
                                    <a-textarea v-model="event.name" placeholder="Event Name"
                                        :auto-size="{ minRows: 2, maxRows: 4 }" @change="() => {
            if (!event.$updates) {
                return
            }
            event.$updates.event.name = event.name;
        }" />
                                </label-item>
                                <label-item label="Base Price" class="w-1/3">
                                    <a-input-number v-model="event.price" size="large" style="width: 100%;" @change="() => {
            if (!event.$updates) {
                return
            }
            event.$updates.price = event.price;
        }" />
                                </label-item>
                            </div>
                            <div v-else class="flex flex-col space-y-4">
                                <span class="text-lg font-semibold text-black">{{ event.name }}</span>
                                <div class="flex flex-wrap items-center px-4 py-4 gap-4 border rounded-md bg-white">
                                    <span class="text-sm font-medium">
                                        📅 {{ $formatDate(event.datetime_local, "MM/DD/YY") }}</span>
                                    <span class="text-sm font-medium">📍 {{ event.address }}</span>
                                </div>
                            </div>
                            <div v-if="createMode || event.$editing"
                                class="flex flex-wrap items-center px-4 py-4 gap-4 border hover:border-blue-400 rounded-md bg-white text-blue-500">
                                <span class="text-sm font-medium">📅 {{ $formatDate(event.datetime_local, "MM/DD/YY")
                                    }}</span>
                                <span class="text-sm font-medium">📍 {{ event.address }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex px-5">
                        <div class="flex flex-col py-1 space-y-4 w-full">
                            <div v-if="!createMode && !event.$editing"
                                class="flex items-center px-4 py-4 space-x-4 border rounded-md bg-white">
                                <span class="text-base font-semibold text-black">Base Price</span>
                                <span class="text-parqay-primary text-2xl font-bold">
                                    {{ $formatCurrency(event.price) }}
                                </span>
                            </div>
                            <pricing-rules-table :edit-mode="createMode || event.$editing" :event="event" :i="i" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="createMode"
                class="flex items-center justify-center rounded-lg border-2 border-dashed px-12 py-8">
                <span class="text-base font-medium text-gray-500">To add / delete selected events, click
                    Edit button above</span>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.ant-btn[edit] {
    @apply flex items-center justify-center space-x-2;
}
</style>