var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('smooth-scroll',{attrs:{"ops":{
    vuescroll: {},
    scrollPanel: {},
    rail: {},
    bar: {
      size: '6px',
      background: 'rgba(231, 70, 148, 1)',
      onlyShowBarOnScroll: false,
      opacity: 0.6,
    },
  }}},[_c('div',{staticClass:"flex py-2 px-4 space-x-2",staticStyle:{"max-width":"500px"}},_vm._l((_vm.validationErrors),function(error,i){return _c('div',{key:i,staticClass:"border rounded-full p-1 cursor-pointer"},[_c('div',{staticClass:"error-chip flex items-center space-x-1 rounded-full py-1 px-4 font-medium text-xs bg-pink-500 text-white hover:bg-pink-600 hover:shadow-inner"},[_c('svg-icon',{staticClass:"w-5 h-5",attrs:{"icon-class":"solid-exclamation-circle"}}),_c('span',{staticClass:"whitespace-nowrap"},[_vm._v(_vm._s(error.message))])],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }