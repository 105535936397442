<script>
export default {
  props: {
    model: {
      type: Array,
      require: true,
    },
  },
  setup(props, ctx) {
      const formatJSON = (code) => {
          var beautify = require("json-beautify");
          console.log("formatJSON >>> ", code)
          return beautify(code, null, 2, 40)
      }
    return {
        formatJSON
    };
  },
};
</script>
<template>
  <div class="flex flex-col justify-center items-center space-y-12">
    <div
      v-for="(m, i) in model"
      :key="i"
      class="bg-white py-4 px-6 space-y-4 border rounded-lg shadow-sm"
    >
      <span
        class="text-lg font-medium w-full text-left mb-2"
      >{{ m.title }}
      </span>
      <div
        class="grid gap-x-2 gap-y-6"
        :class="m.layout"
      >
        <template v-for="(row, i) in m.form">
          <template v-for="(col, j) in row">
            <div
              :key="`${i}*${j}`"
              class="google-light flex flex-col justify-start items-start space-y-2"
              :class="col.class"
            >
              <template v-for="(line, x) in col.items">
                <template v-if="line.text">
                  <span
                    :key="`${i}-${j}-${x}`"
                    class="w-full text-left"
                    :class="line.class"
                  >{{ line.text }}
                  </span>
                </template>
                <template v-else-if="line.tag">
                  <a-tag
                    :key="`${i}-${j}-${x}`"
                    :color="line.color"
                  >
                    {{ line.tag }}
                  </a-tag>
                </template>
                <template v-else>
                  <highlightjs
                    :key="`${i}-${j}-${x}`"
                    class="rounded text-xs overflow-scroll no-scrollbar"
                    :class="line.class"
                    :style="{ 'max-width': '100%', 'max-height': '300px' }"
                    autodetect
                    :code="formatJSON(line.code)"
                  />
                </template>
              </template>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>