<script>
import Vue from "vue";
import { reactive, ref } from "@vue/composition-api";
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";
import { STRIPE_KEY, STRIPE_ENV } from "src/services/constants";

export default {
  components: {
    StripeElements,
    StripeElement,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const loading = reactive({
      saving: false,
    });
    const paymentMethod = reactive({
      name: null,
    });
    const instanceOptions = reactive({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    });
    const elementsOptions = reactive({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    });
    const elms = ref(null);
    const cardElms = ref(null);
    const createCharge = (card) => {
      loading.saving = true;
      if (props.amount <= 0) {
        ctx.emit("error", "Amount must be equal or greater than $1");
        setTimeout(() => {
          loading.saving = false;
        }, 500);
        return;
      }
      // ref in template
      const groupComponent = elms.value;
      // Get stripe element
      const cardElement = card.stripeElement;
      // Access instance methods, e.g. createToken()
      groupComponent.instance
        .createToken(cardElement, { name: paymentMethod.name })
        .then((result) => {
          // Handle result.error or result.token
          console.log(result);
          if (result.token) {
            let payload = {
              amount: props.amount * 100,
              card_token: result.token.id,
            };
            if (STRIPE_ENV === "test")
              payload = { ...payload, test_mode: true };
            Vue.axios
              .post(process.env.VUE_APP_BACKEND_BASE_URL + "/api/charge", payload)
              .then((response) => {
                console.log(response.data);
                ctx.emit("success", {
                  message: `Stripe ID: ${response.data.stripe_resp.id}`,
                  stripeResp: response.data.stripe_resp,
                });
                loading.saving = false;
              })
              .catch((error) => {
                if (error.response) {
                  console.error(error.response.data.message); // => the response payload
                  ctx.emit("payment-error", error.response.data.message);
                }
                loading.saving = false;
              });
          } else {
            ctx.emit("error", result.error.message);
            loading.saving = false;
          }
        });
    };
    const baseStyle = {
      base: {
        backgroundColor: "white",
        iconColor: "rgba(63, 131, 248, 1)",
        color: "black",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "40px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "rgba(156, 163, 175, 1)",
        },
      },
    };
    return {
      elms,
      cardElms,
      loading,
      paymentMethod,
      STRIPE_KEY,
      baseStyle,
      instanceOptions,
      elementsOptions,
      createCharge,
    };
  },
};
</script>
<template>
  <div class="bg-white rounded">
    <stripe-elements #default="{ elements }" ref="elms" :stripe-key="STRIPE_KEY" :instance-options="instanceOptions"
      :elements-options="elementsOptions">
      <div class="mb-4">
        <div class="mt-4 relative rounded-md shadow-sm">
          <a-input v-model="paymentMethod.name" class="w-full"
            :style="{ fontSize: '16px', paddingLeft: '2.65rem', fontWeight: '400' }" placeholder="Name on card"
            size="large" />
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg-icon icon-class="user-solid" class="h-5 w-5 text-blue-500" />
          </div>
        </div>

        <div class="mt-4 relative rounded shadow-sm overflow-hidden">
          <stripe-element ref="cardElms" type="cardNumber"
            class="focus:ring-gray-400 focus:border-gray-400 block w-full pl-3 sm:text-base border border-gray-300"
            :elements="elements" :options="{
              placeholder: 'Card Number',
              showIcon: true,
              style: baseStyle,
            }" />
        </div>

        <div class="flex flex-row justify-between mt-4 space-x-4">
          <div class="rounded shadow-sm w-1/2 relative overflow-hidden">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg-icon icon-class="calendar" class="h-5 w-5 text-blue-500" />
            </div>
            <stripe-element type="cardExpiry"
              class="focus:ring-gray-400 focus:border-gray-400 block w-full pl-10 sm:text-sm border border-gray-300"
              :elements="elements" :options="{
                style: baseStyle,
              }" />
          </div>

          <div class="rounded shadow-sm w-1/2 relative overflow-hidden">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg-icon icon-class="lock" class="h-5 w-5 text-blue-500" />
            </div>
            <stripe-element type="cardCvc"
              class="focus:ring-gray-400 focus:border-gray-400 block w-full pl-10 sm:text-sm border border-gray-300"
              :elements="elements" :options="{
                style: baseStyle,
              }" />
          </div>
        </div>

        <div class="mt-4 flex rounded-md shadow-sm space-x-4">
          <a-button type="primary" size="large" :loading="loading.saving" @click="createCharge($refs['cardElms'])"
            class="w-1/2">
            Charge Card
          </a-button>
          <a-button size="large" class="w-1/2" @click="$emit('reset')">
            Reset
          </a-button>
        </div>
      </div>
    </stripe-elements>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-input {
    @apply placeholder-gray-400 font-medium text-base;
  }
}
</style>
