var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-2 py-1 flex justify-between items-center"},[_c('h4',{staticClass:"font-medium"},[_vm._v("\n      Primary Contact\n    ")]),(!_vm.controls.visible.editModal)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function () { return _vm.controls.visible.editModal = true; }}},[_vm._v("\n      Edit\n    ")]):_vm._e()],1),_c('div',{staticClass:"grid grid-cols-2 gap-3 px-2 py-3"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('div',{staticClass:"text-center flex-none bg-parqay-primary w-12 h-12 rounded-full text-white mr-1"},[_c('span',{staticClass:"text-2xl leading-12"},[_vm._v(_vm._s(_vm.startCase(Array.from(_vm.form.name)[0])))])]),_c('div',{staticClass:"flex flex-col space-y-2"},[_c('span',[_vm._v(_vm._s(_vm.form.name))]),_c('a-tag',{attrs:{"color":"geekblue"}},[_vm._v(_vm._s(_vm.form.role))])],1)]),_c('div',{staticClass:"flex flex-col space-y-3"},[_c('div',{staticClass:"flex items-center mb-2"},[_c('svg-icon',{staticClass:"w-8 h-8 mr-2",attrs:{"icon-class":"contact-email"}}),_c('span',[_vm._v(_vm._s(_vm.form.email))])],1),_c('div',{staticClass:"flex items-center"},[_c('svg-icon',{staticClass:"w-8 h-8 mr-2",attrs:{"icon-class":"contact-phone"}}),_c('span',[_vm._v(_vm._s(_vm.form.phone_number))])],1)])]),_c('a-modal',{staticClass:"bg-gray-400",attrs:{"destroy-on-close":"","footer":false},model:{value:(_vm.controls.visible.phoneNumber),callback:function ($$v) {_vm.$set(_vm.controls.visible, "phoneNumber", $$v)},expression:"controls.visible.phoneNumber"}},[_c('phone-number-manage-card',{staticClass:"px-6 py-6",attrs:{"modal-mode":""},on:{"verified":function (payload) {
        _vm.controls.visible.phoneNumber = false;
        var phone_number = payload.phone_number;
        _vm.controls.form.phone_number = phone_number;
        _vm.onInputChange('phone_number');
      }}})],1),_c('a-modal',{attrs:{"visible":_vm.controls.visible.editModal,"footer":null},on:{"cancel":function () { _vm.controls.form = Object.assign({}, _vm.form); _vm.controls.updateForm = {}; _vm.controls.otherRole = null; _vm.controls.visible.editModal = false; }}},[_c('div',{staticClass:"flex flex-col space-y-2 px-2 py-3"},[_c('div',{staticClass:"flex items-center justify-center px-6 py-2"},[_c('span',{staticClass:"text-black font-medium text-base"},[_vm._v("Edit Primary Contact")])]),_c('div',{staticClass:"flex flex-col space-y-2"},[_c('label-item',{attrs:{"label":"Contact name","desc":"This name will override your default host name"}},[_c('a-input',{attrs:{"size":"large","placeholder":"e.g. John Michael"},on:{"change":function($event){return _vm.onInputChange('name')}},model:{value:(_vm.controls.form.name),callback:function ($$v) {_vm.$set(_vm.controls.form, "name", $$v)},expression:"controls.form.name"}})],1),_c('label-item',{attrs:{"label":"Contact role","desc":"Choose (or enter) a role that best describes the primary contact"}},[_c('div',{staticClass:"flex flex-wrap align-baseline text-sm text-gray-600 font-normal space-x-5 pt-3"},_vm._l((_vm.controls.roles),function(option,i){return _c('a',{key:("option-" + i),staticClass:"border-indigo-500 hover:text-gray-600 h-8 mb-2",class:{
                'pb-2 border-b-4': _vm.controls.form.role.split(':')[0] == option
              },on:{"click":function () { _vm.controls.form.role = option; _vm.onInputChange('role'); }}},[_vm._v("\n              "+_vm._s(option)+"\n            ")])}),0),(_vm.controls.form.role.split(':')[0] === 'Other')?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',{staticClass:"text-gray-600 text-sm whitespace-nowrap"},[_vm._v("Other role, please specify:\n            ")]),_c('a-input',{on:{"change":function($event){return _vm.onInputChange('otherRole')}},model:{value:(_vm.controls.otherRole),callback:function ($$v) {_vm.$set(_vm.controls, "otherRole", $$v)},expression:"controls.otherRole"}})],1):_vm._e()])],1),_c('label-item',{attrs:{"label":"Contact email","desc":"This email will override your default host email"}},[_c('div',{staticClass:"flex items-center"},[_c('svg-icon',{staticClass:"w-12 h-12 mr-2",attrs:{"icon-class":"contact-email"}}),_c('a-input',{attrs:{"size":"large"},on:{"change":function($event){return _vm.onInputChange('email')}},model:{value:(_vm.controls.form.email),callback:function ($$v) {_vm.$set(_vm.controls.form, "email", $$v)},expression:"controls.form.email"}})],1)]),_c('label-item',{attrs:{"label":"Contact phone number","desc":"This phone number will override your default host phone number"}},[_c('div',{staticClass:"flex items-center"},[_c('svg-icon',{staticClass:"w-12 h-12 mr-2",attrs:{"icon-class":"contact-phone"}}),_c('a-input',{attrs:{"size":"large"},on:{"click":function (e) {
              _vm.controls.visible.phoneNumber = true;
              e.target.blur();
            }},model:{value:(_vm.controls.form.phone_number),callback:function ($$v) {_vm.$set(_vm.controls.form, "phone_number", $$v)},expression:"controls.form.phone_number"}},[_c('a-button',{attrs:{"slot":"suffix","type":"primary","size":"small"},on:{"click":function () { return (_vm.controls.visible.phoneNumber = true); }},slot:"suffix"},[_vm._v("\n              "+_vm._s(_vm.controls.form.phone_number ? "Change" : "Add")+"\n            ")])],1)],1)]),_c('div',{staticClass:"flex items-center justify-end w-full py-3"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.isEmpty(_vm.controls.updateForm),"loading":_vm.controls.loading.updateForm},on:{"click":function($event){return _vm.updatePrimaryContact()}}},[_vm._v("Update")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }