<script>
import { convertToCumulativeConditionsIfNeeded, convertFromCumulativeConditionsIfNeeded } from "./util";

import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';

export default {
    props: {
        editMode: {
            type: Boolean,
            required: true
        },
        event: {
            type: Object,
            required: true
        },
        i: {
            type: Number,
            required: true
        }
    },
    setup(props, ctx) {
        const applySign = (signStr, number) => {
            if (signStr !== '+' && signStr !== '-') {
                throw new Error("Sign string must be '+' or '-'");
            }
            return signStr === '+' ? Math.abs(number) : -Math.abs(number);
        };
        const generatePricingAdjustments = (event, type) => {
            const adjustments = {
                size: [
                    {
                        type: "size",
                        tier: "oversized",
                        sign: "+",
                        adjustment: 30
                    },
                    {
                        type: "size",
                        tier: "motorcycle",
                        sign: "-",
                        adjustment: 25
                    }
                ],
                volume: [
                    {
                        type: "volume",
                        tier: 10,
                        sign: "-",
                        adjustment: 1
                    },
                    {
                        type: "volume",
                        tier: 20,
                        sign: "+",
                        adjustment: 0
                    },
                    {
                        type: "volume",
                        tier: 30,
                        sign: "+",
                        adjustment: 2
                    }
                ],
                time: [
                    {
                        type: "time",
                        tier: dayjs(props.event.datetime_local).startOf("day").format("YYYY-MM-DDTHH:mm"),
                        sign: "-",
                        adjustment: 1
                    }
                ]
            };
            if (type === "volume") {
                if (event.adjustments.filter(adj => adj.type === "volume").length > 0) {
                    event.adjustments.push({
                        type,
                        tier: "",
                        sign: "-",
                        adjustment: 10
                    });
                } else {
                    event.adjustments.push(...adjustments[type]);
                }
            }
            if (type === "size") {
                if (event.adjustments.filter(adj => adj.type === "size").length > 0) {
                    event.adjustments.push({
                        type,
                        tier: "New Size",
                        sign: "-",
                        adjustment: 10
                    });
                } else {
                    event.adjustments.push(...adjustments[type]);
                }
            }
            if (type === "time") {
                // Only one early bird tier is allowed.
                if (!event.adjustments.find(adj => adj.type === "time")) {
                    event.adjustments.push(...adjustments[type]);
                }
            }
            if (event.$updates) {
                event.$updates.adjustments = convertToCumulativeConditionsIfNeeded(event.adjustments);
            }
        };
        const commitAdjustmentsUpdate = (event) => {
            if (!event.$updates) {
                return
            }
            event.$updates.adjustments = convertToCumulativeConditionsIfNeeded(event.adjustments);
        }
        const removeAdjustment = (event, adjustment) => {
            const i = event.adjustments.findIndex(adj => isEqual(adj, adjustment));
            event.adjustments.splice(i, 1);
            commitAdjustmentsUpdate(event);
        };
        return {
            applySign,
            generatePricingAdjustments,
            commitAdjustmentsUpdate,
            removeAdjustment,
            convertToCumulativeConditionsIfNeeded,
            convertFromCumulativeConditionsIfNeeded,
            dayjs,
            console
        }
    }
}
</script>
<template>
    <table v-if="editMode">
        <tr class="border-b text-gray-600">
            <th class="text-center" colspan="4">
                <div class="flex flex-col items-center px-4 py-4 space-y-2">
                    <span class="text-base font-semibold">Pricing by Spaces Availability</span>
                    <span class="text-sm font-light">Price decreases / increases based on remaining spaces</span>
                </div>
            </th>
        </tr>
        <tr class="border-b border-l border-r text-gray-600 bg-gray-50">
            <th class="text-center text-sm font-medium py-2">
                Tier
            </th>
            <th class="text-center text-sm font-medium">Discount</th>
            <th class="text-center text-sm font-medium" width="120">Price</th>
            <th />
        </tr>
        <tr height="18" />
        <tr v-for="(adjustment, j) in event.adjustments.filter(adjustment => adjustment.type === 'volume')"
            :key="`event-${i}-adjustment-${j}-volume`" class="text-sm text-gray-500">
            <td class="">
                <div class="flex items-center space-x-2">
                    <a-tag color="geekblue">
                        {{ j + 1 }}
                    </a-tag>
                    <span v-if="j == 0">
                        First group of
                    </span>
                    <span v-else>
                        Next group of
                    </span>
                    <a-input-number :default-value="adjustment.tier" :min="1" style="width: 50px;" @change="(e) => {
        console.log('pricing rule update -> ', e);
        commitAdjustmentsUpdate(event);
    }" />
                    <span>spaces</span>
                </div>
            </td>
            <td class="flex items-center space-x-2 p-2">
                <a-dropdown>
                    <a-button v-if="adjustment.sign === '-'" minus type="primary" icon="minus" />
                    <a-button v-if="adjustment.sign === '+'" plus type="primary" icon="plus" />
                    <a-menu slot="overlay">
                        <a-menu-item @click="() => {
        adjustment.sign = '-';
        commitAdjustmentsUpdate(event);
    }">
                            <span class="text-sm">
                                - Discount
                            </span>
                        </a-menu-item>
                        <a-menu-item @click="() => {
        adjustment.sign = '+';
        commitAdjustmentsUpdate(event);
    }">
                            <span class="text-sm">
                                + Surcharge
                            </span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <a-input-number v-model="adjustment.adjustment" :min="0" style="width: 80px;" @change="() => {
        commitAdjustmentsUpdate(event);
    }" />
            </td>
            <td class="text-center">
                <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
                <span v-if="adjustment.sign === '-'" class="text-orange-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
            </td>
            <td class="text-center py-2 px-1">
                <a-button @click="removeAdjustment(event, adjustment)">
                    <svg-icon icon-class="trash" class="w-4 h-4 text-parqay-primary" />
                </a-button>
            </td>
        </tr>
        <tr>
            <th class="text-center" colspan="4">
                <div class="flex flex-col pt-4 space-y-4">
                    <button
                        class="w-full px-4 py-2 rounded border hover:bg-blue-50 border-parqay-primary font-semibold text-parqay-primary transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                        type="button" @click="() => {
        generatePricingAdjustments(event, 'volume');
    }">
                        + New Tier
                    </button>
                </div>
            </th>
        </tr>

        <tr class="border-b text-gray-600">
            <th class="text-center" colspan="4">
                <div class="flex flex-col items-center px-4 py-4 space-y-2">
                    <span class="text-base font-semibold">Pricing by Space Size</span>
                    <span class="text-sm font-light">Price decreases / increases based on size of space / vehicle</span>
                </div>
            </th>
        </tr>
        <tr class="border-b border-l border-r text-gray-600 bg-gray-50">
            <th class="text-center text-sm font-medium py-2">
                Tier
            </th>
            <th class="text-center text-sm font-medium">Discount</th>
            <th class="text-center text-sm font-medium" width="120">Price</th>
            <th />
        </tr>
        <tr height="18" />
        <tr v-for="(adjustment, j) in event.adjustments.filter(adjustment => adjustment.type === 'size')"
            :key="`event-${i}-adjustment-${j}-size`" class="text-sm text-gray-500">
            <td class="">
                <div class="flex items-center space-x-2">
                    <a-tag color="geekblue">
                        {{ j + 1 }}
                    </a-tag>
                    <span>
                        Size:
                    </span>
                    <a-input v-model="adjustment.tier" allow-clear style="width: 150px;" @change="() => {
        commitAdjustmentsUpdate(event);
    }" />
                </div>
            </td>
            <td class="flex items-center space-x-2 p-2">
                <a-dropdown>
                    <a-button v-if="adjustment.sign === '-'" minus type="primary" icon="minus" />
                    <a-button v-if="adjustment.sign === '+'" plus type="primary" icon="plus" />
                    <a-menu slot="overlay">
                        <a-menu-item @click="() => {
        adjustment.sign = '-';
        commitAdjustmentsUpdate(event);
    }">
                            <span class="text-sm">
                                - Discount
                            </span>
                        </a-menu-item>
                        <a-menu-item @click="() => {
        adjustment.sign = '+';
        commitAdjustmentsUpdate(event);
    }">
                            <span class="text-sm">
                                + Surcharge
                            </span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <a-input-number v-model="adjustment.adjustment" :min="0" style="width: 80px;" @change="() => {
        commitAdjustmentsUpdate(event);
    }" />
            </td>
            <td class="text-center">
                <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
                <span v-if="adjustment.sign === '-'" class="text-orange-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
            </td>
            <td class="text-center py-2 px-1">
                <a-button @click="removeAdjustment(event, adjustment)">
                    <svg-icon icon-class="trash" class="w-4 h-4 text-parqay-primary" />
                </a-button>
            </td>
        </tr>
        <tr>
            <th class="text-center" colspan="4">
                <div class="flex flex-col pt-4 space-y-4">
                    <button
                        class="w-full px-4 py-2 rounded border hover:bg-blue-50 border-parqay-primary font-semibold text-parqay-primary transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                        type="button" @click="() => {
        generatePricingAdjustments(event, 'size');
    }">
                        + New Tier
                    </button>
                </div>
            </th>
        </tr>

        <tr class="border-b text-gray-600">
            <th class="text-center" colspan="4">
                <div class="flex flex-col items-center px-4 py-4 space-y-2">
                    <span class="text-base font-semibold">Early Bird Pricing</span>
                    <span class="text-sm font-light">Price discounted before a set date</span>
                </div>
            </th>
        </tr>
        <tr class="border-b border-l border-r text-gray-600 bg-gray-50">
            <th class="text-center text-sm font-medium py-2">
                Before
            </th>
            <th class="text-center text-sm font-medium">Discount</th>
            <th class="text-center text-sm font-medium" width="120">Price</th>
            <th />
        </tr>
        <tr height="18" />
        <tr v-for="(adjustment, j) in event.adjustments.filter(adjustment => adjustment.type === 'time')"
            :key="`event-${i}-adjustment-${j}-time`" class="text-sm text-gray-500">
            <td class="">
                <div class="flex items-center space-x-2">
                    <a-tag color="geekblue">
                        {{ j + 1 }}
                    </a-tag>
                    <a-date-picker early-bird :default-value="dayjs(adjustment.tier)" :show-time="{ format: 'HH:mm' }"
                        format="YYYY-MM-DD h:mm A" @change="(e) => {
        console.log(e);
        const earlyBirdDate = e.format('YYYY-MM-DDTHH:mm');
        console.log('earlyBirdDate -> ', earlyBirdDate);
        adjustment.tier = earlyBirdDate;
        commitAdjustmentsUpdate(event);
    }" />
                </div>
            </td>
            <td class="flex items-center space-x-2 p-2">
                <a-dropdown>
                    <a-button v-if="adjustment.sign === '-'" minus type="primary" icon="minus" />
                    <a-button v-if="adjustment.sign === '+'" plus type="primary" icon="plus" />
                    <a-menu slot="overlay">
                        <a-menu-item @click="() => {
        adjustment.sign = '-';
        commitAdjustmentsUpdate(event);
    }">
                            <span class="text-sm">
                                - Discount
                            </span>
                        </a-menu-item>
                        <a-menu-item @click="() => {
        adjustment.sign = '+';
        commitAdjustmentsUpdate(event);
    }">
                            <span class="text-sm">
                                + Surcharge
                            </span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <a-input-number v-model="adjustment.adjustment" :min="0" style="width: 80px;" @change="() => {
        commitAdjustmentsUpdate(event);
    }" />
            </td>
            <td class="text-center">
                <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
                <span v-if="adjustment.sign === '-'" class="text-orange-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
            </td>
            <td class="text-center py-2 px-1">
                <a-button @click="removeAdjustment(event, adjustment)">
                    <svg-icon icon-class="trash" class="w-4 h-4 text-parqay-primary" />
                </a-button>
            </td>
        </tr>
        <tr v-if="event.adjustments.filter(adjustment => adjustment.type === 'time') == 0">
            <th class="text-center" colspan="4">
                <div class="flex flex-col pt-4 space-y-4">
                    <button
                        class="w-full px-4 py-2 rounded border hover:bg-blue-50 border-parqay-primary font-semibold text-parqay-primary transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                        type="button" @click="() => {
        generatePricingAdjustments(event, 'time');
    }">
                        + New Tier
                    </button>
                </div>
            </th>
        </tr>
    </table>
    <table v-else>
        <tr v-if="event.adjustments && event.adjustments.length > 0" class="bg-gray-50 text-gray-600 border"
            height="60">
            <th class="text-sm font-medium px-4">
                Tier / Condition
            </th>
            <th class="text-center text-sm font-medium">Discount</th>
            <th class="text-center text-sm font-medium" width="120">Price</th>
        </tr>
        <tr v-for="(adjustment, j) in convertFromCumulativeConditionsIfNeeded(event.adjustments.filter(adj => adj.type === 'time'))"
            :key="`event-${i}-rule-${j}-time`" class="text-sm text-gray-500 bg-white hover:bg-blue-50 border"
            height="50" @click="() => event.$editing = true">
            <td class="text-center">
                <div class="flex items-center justify-start px-4 space-x-2">
                    <a-tag color="green">Early Bird</a-tag>
                    <span class="">Book before <span class="text-parqay-primary font-semibold underline pl-2">{{
        dayjs(adjustment.tier).format("MM-DD, h:mm A") }}</span></span>
                </div>
            </td>
            <td class="text-center">
                <a-tag v-if="adjustment.sign === '+'" color="green">+{{ $formatCurrency(adjustment.adjustment)
                    }}</a-tag>
                <a-tag v-if="adjustment.sign === '-'" color="orange">-{{ $formatCurrency(adjustment.adjustment)
                    }}</a-tag>
            </td>
            <td class="text-center">
                <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
                <span v-if="adjustment.sign === '-'" class="text-orange-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
            </td>
        </tr>
        <tr v-for="(adjustment, j) in convertFromCumulativeConditionsIfNeeded(event.adjustments.filter(adj => adj.type === 'volume'))"
            :key="`event-${i}-rule-${j}-volume`" class="text-sm text-gray-500 bg-white hover:bg-blue-50 border"
            height="50" @click="() => event.$editing = true">
            <td class="text-center">
                <div class="flex items-center justify-start px-4 space-x-2">
                    <span v-if="j == 0">
                        First group of
                    </span>
                    <span v-else>
                        Next group of
                    </span>
                    <a-tag color="geekblue">{{ adjustment.tier }}</a-tag>
                    <span>spaces</span>
                </div>
            </td>
            <td class="text-center">
                <a-tag v-if="adjustment.sign === '+'" color="green">+{{ $formatCurrency(adjustment.adjustment)
                    }}</a-tag>
                <a-tag v-if="adjustment.sign === '-'" color="orange">-{{ $formatCurrency(adjustment.adjustment)
                    }}</a-tag>
            </td>
            <td class="text-center">
                <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
                <span v-if="adjustment.sign === '-'" class="text-orange-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
            </td>
        </tr>
        <tr v-for="(adjustment, j) in convertFromCumulativeConditionsIfNeeded(event.adjustments.filter(adj => adj.type === 'size'))"
            :key="`event-${i}-rule-${j}-size`" class="text-sm text-gray-500 bg-white hover:bg-blue-50 border"
            height="50" @click="() => event.$editing = true">
            <td class="text-center">
                <div class="flex items-center justify-start px-4 space-x-2">
                    <span>Vehicle size of</span>
                    <a-tag color="geekblue">{{ adjustment.tier }}</a-tag>
                </div>
            </td>
            <td class="text-center">
                <a-tag v-if="adjustment.sign === '+'" color="green">+{{ $formatCurrency(adjustment.adjustment)
                    }}</a-tag>
                <a-tag v-if="adjustment.sign === '-'" color="orange">-{{ $formatCurrency(adjustment.adjustment)
                    }}</a-tag>
            </td>
            <td class="text-center">
                <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(event.price +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} /event</span>
                <span v-if="adjustment.sign === '-'" class="text-orange-400">{{ $formatCurrency(event.price +
                    applySign(adjustment.sign,
                    adjustment.adjustment)) }} /event</span>
            </td>
        </tr>
        <tr v-if="event.adjustments.length == 0">
            <th class="text-center" colspan="4">
                <button
                    class="w-full flex items-center justify-center px-4 py-2 space-x-2 rounded border hover:bg-green-50 border-green-400 font-semibold text-green-400 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                    type="button" @click="() => { event.$editing = true }">
                    <span>+ Activate Tier Pricing</span>
                </button>
            </th>
        </tr>
    </table>
</template>
<style lang="scss" scoped>
.ant-btn[minus] {
    @apply flex items-center justify-center bg-red-400 border-red-500;
}

.ant-btn[plus] {
    @apply flex items-center justify-center bg-green-400 border-green-400;
}

.ant-calendar-picker[early-bird] {
    min-width: 220px !important;
}
</style>