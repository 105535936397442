var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"heroEffect",staticClass:"h-full w-full",attrs:{"data-relative-input":"true","data-limit-x":"30","data-limit-y":"30"}},[_vm._m(0),_c('div',{staticClass:"layer absolute",style:({
      left: '50px',
      bottom: '60px',
      width: '3rem',
      height: '3rem',
    }),attrs:{"data-depth":"0.5"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",on:{"mouseenter":function($event){return _vm.toggleArmsAnimations('parking', true)},"mouseleave":function($event){return _vm.toggleArmsAnimations('parking', false)}}},[_c('svg-icon',{staticClass:"w-12 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",class:{
          'animate-pulse': !_vm.armsAnimations.parking,
          'animate-none': _vm.armsAnimations.parking,
        },attrs:{"icon-class":"demo-float-arm-dot"}})],1)]),_c('div',{staticClass:"layer absolute",style:({
      left: '50px',
      top: '40px',
      width: '3rem',
      height: '3rem',
    }),attrs:{"data-depth":"0.5"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",on:{"mouseenter":function($event){return _vm.toggleArmsAnimations('house', true)},"mouseleave":function($event){return _vm.toggleArmsAnimations('house', false)}}},[_c('svg-icon',{staticClass:"w-12 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",class:{
          'animate-pulse': !_vm.armsAnimations.house,
          'animate-none': _vm.armsAnimations.house,
        },attrs:{"icon-class":"demo-float-arm-dot"}})],1)]),_c('div',{staticClass:"layer absolute",style:({
      right: '60px',
      top: '40px',
      width: '3rem',
      height: '3rem',
    }),attrs:{"data-depth":"0.5"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",on:{"mouseenter":function($event){return _vm.toggleArmsAnimations('office', true)},"mouseleave":function($event){return _vm.toggleArmsAnimations('office', false)}}},[_c('svg-icon',{staticClass:"w-12 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",class:{
          'animate-pulse': !_vm.armsAnimations.office,
          'animate-none': _vm.armsAnimations.office,
        },attrs:{"icon-class":"demo-float-arm-dot"}})],1)]),_c('div',{staticClass:"layer absolute",style:({
      right: '35px',
      bottom: '30px',
      height: '5rem',
      width: '5rem',
    }),attrs:{"data-depth":"0.36"}},[_vm._m(1)]),_c('div',{staticClass:"layer absolute",style:({
      right: '35px',
      bottom: '30px',
      height: '5rem',
      width: '5rem',
    }),attrs:{"data-depth":"0.5"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",on:{"mouseenter":function($event){return _vm.toggleArmsAnimations('car', true)},"mouseleave":function($event){return _vm.toggleArmsAnimations('car', false)}}},[_c('svg-icon',{staticClass:"w-12 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",class:{
          'animate-pulse': !_vm.armsAnimations.car,
          'animate-none': _vm.armsAnimations.car,
        },attrs:{"icon-class":"demo-float-arm-dot"}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layer absolute",staticStyle:{"left":"-30px","top":"-30px","height":"115%","width":"110%"},attrs:{"data-depth":"0.4"}},[_c('img',{staticClass:"absolute inset-0 h-full w-full object-contain",attrs:{"src":"/static/img/dashboard/db-login-background.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute cursor-pointer pointer-events-auto"},[_c('img',{staticClass:"w-20 h-16 object-contain",attrs:{"src":"/static/img/dashboard/car-float-arm.png"}})])}]

export { render, staticRenderFns }