<script>
import { reactive, ref } from "@vue/composition-api";
import PricingDatePicker from "src/views/listings/detail/rates/DatePicker.vue";
import EventPricing from "src/views/listings/detail/rates/EventPricing.vue";
import Availability from "./components/AvailabilityView.vue";
import SimplePriceModeView from "src/views/listings/new/components/components/SimplePriceModeView.vue";
import { getDefaultModelForMode } from "src/views/listings/detail/availability/util.js";
import cloneDeep from "lodash/cloneDeep";
import lowerCase from "lodash/lowerCase";
import { convertToCumulativeConditionsIfNeeded } from "../../detail/rates/components/util";

export default {
  components: {
    PricingDatePicker,
    EventPricing,
    Availability,
    SimplePriceModeView
  },
  props: {
    controls: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, ctx) {
    const isEvent = () => {
      return props.form.events && props.form.events.length > 0;
    }

    const eventPricingRef = ref(null);
    const generalPricingRef = ref(null);
    const availabilityModel = reactive(getDefaultModelForMode("simple"));
    const pricePackage = ref(2);
    const simulator = reactive({
      size: null,
      volume: null
    })

    const next = () => {
      transferFormPayload();
      ctx.emit("next");
    };

    const transferFormPayload = () => {
      ctx.emit("payload-update", "schedule-price", assemblePayloads());
    };

    const assembleSchedules = () => {
      const schedulePayload = {
        mode: availabilityModel.mode,
        simple: {},
        manual: []
      };
      switch (availabilityModel.mode) {
        case "simple":
          schedulePayload.simple = availabilityModel.simple;
          // Change assemble rules, IF NEEDED.
          break;
        case "manual":
          availabilityModel.manual.forEach((v, i) => {
            if (v.day && v.from && v.to) {
              schedulePayload.manual.push({
                day: lowerCase(v.day),
                from: v.from,
                to: v.to,
                active: v.active
              });
            }
          });
          break;
        default:
          break;
      }
      return schedulePayload;
    };

    const assemblePayloads = () => {
      // Backend is using snake-case as its framework is Python
      let payloads = {
        price: isEvent() ? eventPricingRef.value.assemblePayloads() : generalPricingRef.value.assemblePayloads(),
        schedule: assembleSchedules(),
        package_price: pricePackage.value
      };
      if (isEvent()) {
        const events = cloneDeep(props.form.events);
        events.forEach(event => {
          event.adjustments = convertToCumulativeConditionsIfNeeded(event.adjustments);
        })
        payloads.events = events;
      }
      console.log("assemblePayloads -> ", payloads);
      return payloads;
    };
    return {
      isEvent,
      eventPricingRef,
      generalPricingRef,
      availabilityModel,
      pricePackage,
      simulator,
      next
    };
  }
};
</script>

<template>
  <div class="schedule-price flex flex-col relative">
    <span v-if="isEvent()" class="text-black text-lg font-semibold text-center border-b w-full py-4 bg-white">
      Event Pricing
    </span>
    <span v-else class="text-black text-lg font-semibold text-center border-b w-full py-4 bg-white">
      Schedule & Price
    </span>
    <div class="absolute top-2.5 right-4">
      <button
        class="text-parqay-primary font-medium border border-parqay-primary hover:bg-parqay-primary hover:text-white rounded-md py-2 px-4"
        @click="next">
        {{ $t("dashboard.continue") }}
      </button>
    </div>

    <div v-if="isEvent()" class="grid grid-cols-2 gap-8 py-12 max-w-7xl mx-auto">
      <event-pricing v-if="isEvent()" ref="eventPricingRef" :controls="controls" :form="form" create-mode />
      <div class="fixed bg-white border rounded-md shadow-md" style="
        top: calc(25vh + 30px);
        left: calc(50vw + 100px);
        width: 500px;
        min-height: 500px;
      ">
        <div class="flex flex-col overflow-y-auto"
          :style="$controls.windowWidth <= 2000 ? 'max-height: 460px;' : 'max-height: 50vh;'">
          <pricing-date-picker ref="pricingCalendar" :events="form.events" :simulated-size="simulator.size"
            :simulated-volume="simulator.volume" />
          <a-divider style="margin: 10px 0;">Simulator</a-divider>
          <div class="flex items-center justify-center px-4 py-2">
            <span class="text-sm text-gray-500">Simulate what price your tenants will see if they have a size X vehicle
              with Y of
              your free spaces remaining.</span>
          </div>
          <div class="flex items-end justify-between px-4 py-2 space-x-4">
            <label-item label="Size">
              <a-input v-model="simulator.size" placeholder="e.g. oversized" />
            </label-item>
            <label-item label="Spaces Remaining">
              <a-input-number v-model="simulator.volume" allow-clear placeholder="20" style="width: 150px;" />
            </label-item>
            <a-button type="primary" :disabled="!simulator.size && !simulator.volume"
              @click="$refs.pricingCalendar.refresh()">Simulate</a-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isEvent()" class="grid grid-cols-2 gap-8 py-12 max-w-7xl mx-auto">
      <availability :model="availabilityModel" />
      <div class="flex mt-2" style="width: 600px;">
        <simple-price-mode-view ref="generalPricingRef" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.ant-btn[edit] {
  @apply flex items-center justify-center space-x-2;
}

.schedule-price ::v-deep .ant-input-number {
  width: 200px;
}

.schedule-price ::v-deep .ant-input-number-input {
  text-align: center;
}
</style>
<style lang="scss" scoped>
::v-deep {
  .vc-container {
    @apply w-full;
  }
}
</style>