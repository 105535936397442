var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer"},[_c('div',{staticClass:"flex flex-col justify-center py-4"},[_c('div',{staticClass:"flex justify-center"},[(!_vm.isLoading)?_c('svg-icon',{staticClass:"w-1/5 h-16",attrs:{"icon-class":"sms-verify"}}):_c('div',{staticClass:"px-2 py-1 border-2 rounded-lg"},[_c('svg-icon',{staticClass:"animate-spin h-8 w-8",attrs:{"icon-class":"circular-loader"}})],1)],1),_vm._m(0),_c('div',{staticClass:"flex justify-center px-6"},[_c('div',{staticClass:"flex border rounded-lg border-parqay-primary bg-white w-full h-10"},[_c('div',{staticClass:"flex items-center",class:_vm.controls.step == 0 ? 'w-full' : 'w-3/4'},[_c('span',{staticClass:"text-center text-parqay-primary hover:text-blue-800 font-bold w-full"},[_vm._v(_vm._s(_vm.phoneNumber))])]),(_vm.controls.step == 1)?_c('div',{staticClass:"flex w-1/4 items-center"},[(_vm.controls.countDown == 0)?_c('button',{staticClass:"rounded bg-parqay-primary hover:bg-blue-500 text-white text-xs px-4 py-1",on:{"click":function($event){return _vm.sendVerifyCode()}}},[_vm._v("\n              Resend\n            ")]):_c('a-button',{attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.controls.countDown)+"s ")])],1):_vm._e()])]),(_vm.controls.step == 0)?_c('div',{staticClass:"flex flex-col justify-center mt-5 px-6 space-y-2"},[_c('span',{staticClass:"font-medium text-center py-2"},[_vm._v("You will receive SMS verification code shortly after clicking the\n          button below. By entering correct verification code, you will be\n          able to login directly")]),_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":function () {
            _vm.sendVerifyCode();
            _vm.controls.step = 1;
          }}},[_vm._v("Send code to me")])],1):_vm._e(),(_vm.controls.step == 1)?_c('div',{staticClass:"flex flex-col justify-center mt-2 px-6 space-y-2"},[_c('span',{staticClass:"font-medium text-center py-2"},[_vm._v("Enter the 4 digits you received below")]),_c('div',{staticClass:"grid grid-cols-4 gap-8 h-16"},[_c('div',{staticClass:"h-5/6 flex items-center justify-center rounded-lg",class:{
            'border-2 border-indigo-500': _vm.shouldHighlightBorderAtIndex(0),
            'border border-gray-500': !_vm.shouldHighlightBorderAtIndex(0),
          },on:{"click":function($event){return _vm.focusOnInput()}}},[_c('span',{staticClass:"text-black text-3xl font-bold"},[_vm._v(_vm._s(_vm.getVerifyCode(0)))])]),_c('div',{staticClass:"h-5/6 flex items-center justify-center rounded-lg",class:{
            'border-2 border-indigo-500': _vm.shouldHighlightBorderAtIndex(1),
            'border border-gray-500': !_vm.shouldHighlightBorderAtIndex(1)
          },on:{"click":function($event){return _vm.focusOnInput()}}},[_c('span',{staticClass:"text-black text-3xl font-bold"},[_vm._v(_vm._s(_vm.getVerifyCode(1)))])]),_c('div',{staticClass:"h-5/6 flex items-center justify-center rounded-lg",class:{
            'border-2 border-indigo-500': _vm.shouldHighlightBorderAtIndex(2),
            'border border-gray-500': !_vm.shouldHighlightBorderAtIndex(2)
          },on:{"click":function($event){return _vm.focusOnInput()}}},[_c('span',{staticClass:"text-black text-3xl font-bold"},[_vm._v(_vm._s(_vm.getVerifyCode(2)))])]),_c('div',{staticClass:"h-5/6 flex items-center justify-center rounded-lg",class:{
            'border-2 border-indigo-500': _vm.shouldHighlightBorderAtIndex(3),
            'border border-gray-500': !_vm.shouldHighlightBorderAtIndex(3)
          },on:{"click":function($event){return _vm.focusOnInput()}}},[_c('span',{staticClass:"text-black text-3xl font-bold"},[_vm._v(_vm._s(_vm.getVerifyCode(3)))])])]),(_vm.message)?_c('p',{staticClass:"text-sm text-center my-0",class:_vm.messageColorClass},[_vm._v("\n          "+_vm._s(_vm.message)+"\n        ")]):_vm._e(),_c('input',{staticClass:"first-input w-0 h-0 opacity-0",attrs:{"type":"text","pattern":"\\d*"}})]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex h-8 divide-x-2 divide-gray-400 divide-dashed"},[_c('div',{staticClass:"w-1/2"}),_c('div',{staticClass:"w-1/2"})])}]

export { render, staticRenderFns }