<script>
import { onMounted, ref } from "@vue/composition-api";
import { API } from "src/views/listings/api";

import Map from "src/views/map/View.vue";

export default {
  components: {
    "pq-map": Map
  },
  props: ["model", "locationModel", "listingType"],
  setup(props, ctx) {
    const activities = ref(props.model);

    const getAllActivities = (listing_id) => {
      API.getAllActivities(listing_id).then((res) => {
        console.log("getAllActivities: " + JSON.stringify(res.data));
        if (res.data) {
          activities.value = res.data;
        }
      });
    };

    onMounted(() => {
      getAllActivities(ctx.root.$route.params.id);
    });

    return {
      activities
    };
  },
};
</script>
<template>
  <div class="flex">
    <div class="flex flex-col border-r">
      <div v-if="listingType == 'car-rental'" class="px-2 flex justify-between items-center py-2">
        <span class="text-blue-500 font-bold mr-2">Status</span>
        <p class="font-bold mb-0">
          Your car is still in service
        </p>
      </div>
      <!-- map -->
      <div class="w-48 h-48 relative">
        <pq-map ref="PQMap" :center="locationModel" disable-control simple-focus />
      </div>
      <div v-if="listingType == 'car-rental'" class="px-2 flex justify-between items-center py-2">
        <span class="text-blue-500 font-bold mr-2">Progress</span>
        <a-progress :percent="80" :stroke-width="12" :show-info="false" />
      </div>
    </div>
    <div v-if="$store.state.notifications.filter(v => v.role === 'host' && v.listing_id === $route.params.id).length == 0"
      class="flex-grow flex items-center justify-center">
      <span class="text-base text-gray-400 py-2">
        No activities yet
      </span>
    </div>
    <div v-else class="flex-grow overflow-y-auto">
      <div class="flex flex-col justify-center items-center">
        <div
          v-for="(notification, i) in $store.state.notifications.filter(v => v.type === 'reservation' && v.role === 'host' && v.listing_id === $route.params.id)"
          :key="`listing-activity-${i}`"
          class="flex items-center justify-center w-full hover:bg-blue-50 border-b px-4 py-2 cursor-pointer"
          style="min-height: 60px;"
          @click.stop="$bus.$emit('open-reservation-drawer', null, notification.reservation_id)">
          <div class="flex-shrink-0 pr-2">
            <avatar :user-id="notification.tenant_id" />
          </div>
          <div class="flex flex-col space-y-1 p-2">
            <span class="text-xs">
              {{ notification.message }}
            </span>
            <div v-if="false">
              <span class="font-semibold mr-1">Income</span><span class="mr-6"
                :class="it.income.change == '+' ? 'text-green-400' : 'text-red-500'">{{ it.income.change }}{{
                  it.income.amount }}</span>
              <span class="font-semibold mr-1">Space</span><span
                :class="it.space.change == '+' ? 'text-green-400' : 'text-red-500'">{{ it.space.change }}{{
                  it.space.amount
                }}</span>
            </div>
          </div>
          <div class="pl-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-8 h-8 text-parqay-primary hover:text-blue-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>