<script>
import { computed, watch, onMounted, onBeforeUnmount } from "@vue/composition-api";
import StripeButton from "src/views/components/StripeButton";
import PricingDetailsModal from "src/views/non-host/common/PricingDetailsModal";
import IncomeDetailsModal from "src/views/non-host/common/IncomeDetailsModal";
import { API } from "src/views/reservations/api";
import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

export default {
  name: "TransactionForm",
  components: {
    StripeButton,
    PricingDetailsModal,
    IncomeDetailsModal
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    controls: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    const deepGet = ctx.root.$deepGet;
    const getDateTimeFormatBasedOnIntervalMode =
      ctx.root.$getDateTimeFormatBasedOnIntervalMode;
    const chargeDateFormat = computed(() => {
      var fmt;
      switch (props.form.transaction.charge_duration.unit) {
        case ("month", "months"):
          fmt = "YYYY-MM-DD";
          break;
        case ("day", "days"):
          fmt = "YYYY-MM-DD";
          break;
        case ("hour", "hours"):
          fmt = "YYYY-MM-DD HH:mm";
          break;
        default:
          break;
      }
      return fmt;
    });
    const chargeDateShowTime = computed(() => {
      var showTime;
      switch (props.form.transaction.charge_duration.unit) {
        case ("month", "months"):
          showTime = false;
          break;
        case ("day", "days"):
          showTime = false;
          break;
        case ("hour", "hours"):
          showTime = true;
          break;
        default:
          break;
      }
      return showTime;
    });
    watch(
      () => props.form.transaction.charge_type,
      val => {
        console.log("form.transaction.charge_type >> ", val);
        if (val === "subscription") {
          props.controls.transaction.showDuration = true;
          props.controls.transaction.disableFinalChargeDate = false;
          props.controls.transaction.finalChargeDate = cloneDeep(
            props.controls.transaction
          ).nextChargeDate.add(
            props.form.transaction.charge_duration.amount - 1,
            props.form.transaction.charge_duration.unit
          );
        }
        if (val === "deposit") {
          props.form.transaction.charge_duration.amount = 1;
          props.form.transaction.charge_duration.unit = "months";
          props.controls.transaction.showDuration = false;
          props.controls.transaction.disableFinalChargeDate = true;
          props.controls.transaction.finalChargeDate = cloneDeep(
            props.controls.transaction
          ).nextChargeDate;
        }
      }
    );
    watch(
      () => props.form.transaction.pricing,
      val => {
        console.log("props.form.transaction.pricing >> ", val);
        const guestMeta = val.guest_meta;
        props.form.transaction.invoice_items = [];
        for (const rd of guestMeta.rates_details) {
          props.form.transaction.invoice_items.push({
            description: rd.title,
            amount: rd.total,
            editable: false
          });
        }
        if (props.form.smartcar.trunk_delivery) {
          props.form.transaction.invoice_items.push({
            description: "Vehicle Trunk Delivery Fee",
            amount: props.form.smartcar.delivery_price,
            editable: false
          });
        }
        props.form.transaction.invoice_items.push({
          description: "Fee",
          amount: guestMeta.fee,
          editable: false
        });
        props.controls.transaction.invoiceItems = cloneDeep(
          props.form.transaction.invoice_items
        );
      }
    );
    watch(
      () => props.form.transaction.charge_duration,
      val => {
        console.log("form.transaction.charge_duration >> ", val);
        if (val.amount > 1 && ["months", "days"].includes(val.unit)) {
          props.form.transaction.charge_type = "subscription"
        } else {
          props.form.transaction.charge_type = "deposit"
        }
        if (props.form.transaction.charge_type === "subscription") {
          props.controls.transaction.finalChargeDate = cloneDeep(
            props.controls.transaction
          ).nextChargeDate.add(val.amount - 1, val.unit);
        }
      },
      { deep: true }
    );
    watch(
      () => props.controls.transaction.nextChargeDate,
      val => {
        console.log("props.controls.transaction.nextChargeDate >> ", val);
        if (props.form.transaction.charge_type === "subscription") {
          props.controls.transaction.finalChargeDate = cloneDeep(val).add(
            props.form.transaction.charge_duration.amount,
            props.form.transaction.charge_duration.unit
          );
        }
        if (props.form.transaction.charge_type === "deposit") {
          props.controls.transaction.finalChargeDate = cloneDeep(val);
        }
      }
    );
    const getDateFormat = () => {
      switch (props.form.transaction.interval_mode) {
        case "hourly":
          return "MMM D";
        case "daily":
          return "MMM D";
        case "monthly":
          return "MMM D";
        default:
          return;
      }
    };
    watch(
      () => props.form.transaction.interval_mode,
      async val => {
        console.log("interval_mode >> ", val);
        if (!props.form.listing) {
          return;
        }
        if (props.form.transaction.follow_listing_price) {
          const resp = await API.getReservationPriceAutomatic(
            val,
            props.form.intervals,
            props.form.listing.summary.location,
            props.form.listing.rates.rules
          );
          props.form.transaction.pricing = resp.data;
          props.form.transaction.final_charge = resp.data.guest_meta.total;
          props.form.transaction.host_income = resp.data.host_meta.income;
          props.form.review = getReviewForm(props.form);
        }
      }
    );
    watch(
      () => props.form.smartcar.trunk_delivery,
      async val => {
        console.log("trunk_delivery >> ", val);
        if (!props.form.listing && val) {
          return;
        }
        if (props.form.transaction.follow_listing_price) {
          const resp = await API.getReservationPriceAutomatic(
            props.form.transaction.interval_mode,
            props.form.intervals,
            props.form.listing.summary.location,
            props.form.listing.rates.rules
          );
          props.form.transaction.pricing = resp.data;
          props.form.transaction.final_charge = resp.data.guest_meta.total;
          props.form.transaction.host_income = resp.data.host_meta.income;
          props.form.review = getReviewForm(props.form);
        }
      }
    );
    watch(
      () => props.form.listing,
      async val => {
        console.log("form.transaction.listing >> ", val);
        if (!val) {
          return;
        }
        if (props.form.transaction.follow_listing_price) {
          const resp = await API.getReservationPriceAutomatic(
            props.form.transaction.interval_mode,
            props.form.intervals,
            props.form.listing.summary.location,
            val.rates.rules
          );
          props.form.transaction.pricing = resp.data;
          props.form.transaction.final_charge = resp.data.guest_meta.total;
          props.form.transaction.host_income = resp.data.host_meta.income;
          props.form.review = getReviewForm(props.form);
        }
      },
      { deep: true }
    );
    watch(
      () => props.form.transaction.follow_listing_price,
      async val => {
        console.log("follow_listing_price >> ", val);
        if (val) {
          if (props.form.listing) {
            const resp = await API.getReservationPriceAutomatic(
              props.form.transaction.interval_mode,
              props.form.intervals,
              props.form.listing.summary.location,
              props.form.listing.rates.rules
            );
            props.form.transaction.pricing = resp.data;
            props.form.transaction.final_charge = resp.data.guest_meta.total;
            props.form.transaction.host_income = resp.data.host_meta.income;
            props.form.review = getReviewForm(props.form);
          }
        } else {
          if (props.form.transaction.pricing["guest_meta"]) {
            props.form.transaction.list_price =
              props.form.transaction.pricing.guest_meta.price;
            props.form.review = getReviewForm(props.form);
          }
        }
      }
    );
    const getTimeFormat = () => {
      switch (props.form.transaction.interval_mode) {
        case "hourly":
          return "h:mm a";
        case "daily":
          return "h:mm a";
        case "monthly":
          return "YYYY";
        default:
          return;
      }
    };
    const getPricingModalTimeFormat = () => {
      return {
        default: `${getDateFormat()}, ${getTimeFormat()}`
      };
    };
    const calculateManualPrice = async () => {
      const resp = await API.getReservationPriceManual(
        props.form.transaction.list_price,
        props.form.listing.summary.location,
        props.form.smartcar.trunk_delivery
          ? { delivery_price: props.form.smartcar.delivery_price }
          : {}
      );
      props.form.transaction.pricing = resp.data;
      props.form.transaction.final_charge = resp.data.guest_meta.total;
      props.form.transaction.host_income = resp.data.host_meta.income;
    };
    // Generating review form for tenant future reference
    const getReviewForm = reservationForm => {
      const { intervals, listing, transaction } = reservationForm;
      const { interval_mode, pricing } = transaction;
      const fmts = getDateTimeFormatBasedOnIntervalMode(interval_mode);
      console.log(
        "getReviewForm: ",
        intervals,
        listing,
        pricing.guest_meta,
        fmts
      );
      return {
        intervals,
        title: deepGet(listing, "summary.title"),
        images: deepGet(listing, "summary.images", []),
        host: deepGet(listing, "summary.host"),
        about_host: deepGet(listing, "settings.about_host"),
        getting_there: deepGet(listing, "settings.getting_there"),
        amenities: deepGet(listing, "settings.amenities", []),
        location: deepGet(listing, "summary.location"),
        pricing: deepGet(pricing, "guest_meta"),
        time_format: fmts.default,
        created_at: deepGet(listing, "created_at")
      };
    };
    const onUpdateFormIntervals = async val => {
      console.log("intervals updated >> ", val);
      if (!props.form.listing) {
        return;
      }
      if (
        props.form.transaction.follow_listing_price &&
        deepGet(props.form.listing, "rates.rules")
      ) {
        const resp = await API.getReservationPriceAutomatic(
          props.form.transaction.interval_mode,
          val,
          props.form.listing.summary.location,
          deepGet(props.form.listing, "rates.rules")
        );
        props.form.transaction.pricing = resp.data;
        props.form.transaction.final_charge = resp.data.guest_meta.total;
        props.form.transaction.host_income = resp.data.host_meta.income;
        props.form.review = getReviewForm(props.form);
      }
    }
    onMounted(() => {
      ctx.root.$bus.$on("reservation-update-form-intervals", onUpdateFormIntervals);
    })
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("reservation-update-form-intervals", onUpdateFormIntervals);
    })
    return {
      chargeDateFormat,
      chargeDateShowTime,
      getPricingModalTimeFormat,
      calculateManualPrice,
      cloneDeep,
      isEmpty,
      startCase
    };
  }
};
</script>
<template>
  <div class="form-transaction">
    <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      Transaction
    </p>
    <div class="mt-1 sm:mt-0 sm:col-span-5">
      <div class="grid grid-cols-2 gap-4">
        <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1 col-span-2">
          <dt class="text-sm font-medium text-gray-500">
            Follow Listing Price
          </dt>
          <dd class="text-sm text-gray-900 font-bold sm:mt-0">
            <a-switch v-model="form.transaction.follow_listing_price" checked-children="Yes" un-checked-children="No"
              :disabled="!controls.createMode" />
          </dd>
        </div>
        <dl class="m-0 pt-2">
          <div v-if="!form.transaction.follow_listing_price" class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500">
              List Price
            </dt>
            <dd class="text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">
              <a-input-number v-if="controls.createMode" v-model="form.transaction.list_price" :formatter="value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                " :parser="value => value.replace(/\$\s?|(,*)/g, '')" :min="1" @blur="calculateManualPrice" />
              <div v-else>
                {{ $formatCurrency(form.transaction.list_price) }}
              </div>
            </dd>
          </div>
          <div v-if="controls.transaction.showDuration" class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500">
              Collect Duration
            </dt>
            <dd class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">
              <div v-if="controls.createMode" class="flex space-x-2">
                <a-input-number v-model="form.transaction.charge_duration.amount" :min="1" :max="12" />
                <a-select v-model="form.transaction.charge_duration.unit" default-value="months">
                  <a-select-option value="months">
                    months
                  </a-select-option>
                  <a-select-option value="days">
                    days
                  </a-select-option>
                  <a-select-option value="hours">
                    hours
                  </a-select-option>
                </a-select>
              </div>

              <div v-else>
                {{ $deepGet(form, "transaction.charge_duration.amount") }}
                {{ $deepGet(form, "transaction.charge_duration.unit") }}
              </div>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500">
              Tenant Total
            </dt>
            <dd class="flex items-center text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 space-x-2">
              <a-tag v-if="controls.createMode" color="geekblue">
                {{ $formatCurrency(form.transaction.final_charge) }}
              </a-tag>
              <div v-else>
                {{
                  $formatCurrency($deepGet(form, "transaction.final_charge"))
                }}
              </div>
              <button :disabled="!$deepGet(form, 'transaction.pricing.guest_meta.total')
                  " class="text-sm px-2 py-1 rounded bg-parqay-primary text-white" :class="$deepGet(form, 'transaction.pricing.guest_meta.total')
      ? 'hover:bg-blue-500'
      : 'opacity-50 cursor-not-allowed'
      " @click="() => (controls.showPricingDetails = true)">
                Price Details
              </button>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500">
              Income
            </dt>
            <dd class="flex items-center text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 space-x-2">
              <a-tag v-if="controls.createMode" color="green">
                {{ $formatCurrency(form.transaction.host_income) }}
              </a-tag>
              <div v-else class="text-green-500">
                +{{
                  $formatCurrency($deepGet(form, "transaction.host_income"))
                }}
              </div>
              <button :disabled="!$deepGet(form, 'transaction.pricing.host_meta.income')
                  " class="text-sm px-2 py-1 rounded bg-parqay-primary text-white" :class="$deepGet(form, 'transaction.pricing.host_meta.income')
      ? 'hover:bg-blue-500'
      : 'opacity-50 cursor-not-allowed'
      " @click="() => (controls.showIncomeDetails = true)">
                Income Details
              </button>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-x-1 gap-y-4">
            <p class="text-sm font-medium text-gray-500 my-auto">
              Actions
            </p>
            <div class="text-sm text-gray-900 sm:col-span-2 my-auto">
              <div v-if="controls.createMode" class="flex space-x-4 items-center justify-end">
                <span class="text-sm font-medium text-parqay-primary">Send Receipt?</span>
                <a-switch v-model="form.transaction.send_receipt" checked-children="Yes" un-checked-children="No" />
              </div>
              <button v-else class="
                  px-4
                  py-1
                  border border-transparent
                  text-md
                  font-base
                  rounded-md
                  shadow-sm
                  text-white
                  bg-parqay-primary
                  hover:bg-blue-500
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                ">
                Resend Receipt
              </button>
            </div>
            <p class="text-sm font-medium text-gray-500 my-auto">
              Pricing Mode
            </p>
            <a-select v-if="controls.createMode" v-model="form.transaction.interval_mode" default-value="hourly"
              class="sm:col-span-2 flex justify-end">
              <a-select-option value="monthly">
                monthly
              </a-select-option>
              <a-select-option value="daily">
                daily
              </a-select-option>
              <a-select-option value="hourly">
                hourly
              </a-select-option>
            </a-select>
            <span v-else class="sm:col-span-2 text-gray-900 font-bold">{{
              form.transaction.interval_mode
            }}</span>
            <p class="text-sm font-medium text-gray-500 my-auto">
              Invoice Items
            </p>
            <div v-if="controls.createMode" class="sm:col-span-2 flex justify-end">
              <button v-if="controls.transaction.editingInvoiceItems"
                class="bg-parqay-primary text-white text-xs px-4 py-1 rounded" @click="() => {
                  controls.transaction.editingInvoiceItems = false;
                  form.transaction.invoice_items = cloneDeep(
                    controls.transaction.invoiceItems.filter(
                      n => n.description && n.amount
                    )
                  );
                  controls.transaction.invoiceItems = [];
                }
                  ">
                Done
              </button>
              <button v-else class="bg-parqay-primary text-white text-xs px-4 py-1 rounded" @click="() => {
                controls.transaction.editingInvoiceItems = true;
                controls.transaction.invoiceItems = cloneDeep(
                  form.transaction.invoice_items.filter(
                    n => n.description && n.amount
                  )
                );
              }
                ">
                Edit
              </button>
            </div>
            <div v-if="controls.transaction.editingInvoiceItems"
              class="sm:col-span-3 rounded-lg bg-gray-50 border flex flex-col divide-y">
              <div v-for="(v, i) in controls.transaction.invoiceItems" :key="`transaction-invoice-item-${i}`"
                class="flex w-full items-center justify-between space-x-4 px-4 py-2">
                <a-input v-model="v.description" class="" placeholder="ex. Parking Fee" />
                <a-input-number v-model="v.amount" :default-value="0" :min="0" :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  " :parser="value => value.replace(/\$\s?|(,*)/g, '')" :disabled="!v.editable" />
                <button class="bg-gray-400 hover:bg-gray-600 disabled:opacity-50 rounded-full flex cursor-pointer"
                  :disabled="!v.editable" @click="() => {
                    if (controls.transaction.invoiceItems.length == 1) {
                      controls.transaction.invoiceItems[0].description = null;
                      controls.transaction.invoiceItems[0].amount = null;
                      return;
                    }
                    controls.transaction.invoiceItems.splice(i, 1);
                  }
                    ">
                  <svg-icon icon-class="close" class="w-3 h-3 m-1 text-white" />
                </button>
              </div>
              <div
                class="h-12 flex w-full items-center justify-center space-x-4 text-gray-500 hover:text-blue-500 cursor-pointer"
                @click="() => {
                  controls.transaction.invoiceItems.push({
                    description: null,
                    amount: null,
                    editable: true
                  });
                }
                  ">
                <svg-icon icon-class="add-outline" class="h-6 w-6" />
                <span class="leading-6 text-base font-semibold">Add New Item</span>
              </div>
            </div>
            <div v-else class="sm:col-span-3 rounded-lg bg-gray-50 border flex flex-col divide-y">
              <div v-for="(v, i) in form.transaction.invoice_items" :key="`transaction-invoice-item-${i}`"
                class="flex w-full items-center justify-between space-x-4 px-4 py-2">
                <div class="text-base font-medium">
                  {{ v.description }}
                </div>
                <div class="text-base font-medium">
                  {{ $formatCurrency(v.amount) }}
                </div>
              </div>
              <div v-if="isEmpty(form.transaction.invoice_items)"
                class="h-12 flex w-full items-center justify-center space-x-4 text-gray-500 cursor-pointer">
                <svg-icon icon-class="document" class="h-6 w-6" />
                <span class="leading-6 text-base font-semibold">No Invoice Item</span>
              </div>
            </div>
            <pricing-details-modal v-if="$deepGet(form, 'transaction.pricing.guest_meta.total')" :controls="controls"
              :time-format="getPricingModalTimeFormat()" :pricing="form.transaction.pricing.guest_meta" hide-review />
            <income-details-modal v-if="$deepGet(form, 'transaction.pricing.host_meta.income')" :controls="controls"
              :time-format="getPricingModalTimeFormat()" :pricing="form.transaction.pricing.host_meta" />
          </div>
        </dl>
        <div class="m-0 pt-2">
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <p class="text-sm font-medium text-gray-500">
              Charge Type
            </p>
            <div class="mt-1 text-sm text-gray-900 font-medium sm:-mt-1 sm:col-span-2">
              <a-radio-group v-if="controls.createMode" v-model="form.transaction.charge_type">
                <a-radio-button v-for="(t, i) in controls.transaction.chargeTypes" :key="`charge-type-${i}`" :value="t">
                  {{ startCase(t) }}
                </a-radio-button>
              </a-radio-group>
              <a-tag v-else color="geekblue">
                {{ startCase(form.transaction.charge_type) }}
              </a-tag>
            </div>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <p class="text-sm font-medium text-gray-500">
              Next Charge
            </p>
            <div class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">
              <a-date-picker v-if="controls.createMode" v-model="controls.transaction.nextChargeDate"
                :show-time="chargeDateShowTime" :default-value="controls.transaction.nextChargeDate"
                :format="chargeDateFormat" @change="(value, dateString) => {
                  console.log('next charge: ', value, dateString);
                  form.transaction.next_charge_date = $formatMomentForBackendUp(
                    value
                  );
                }
                  ">
                <template #suffixIcon>
                  <a-icon type="calendar" theme="twoTone" />
                </template>
              </a-date-picker>
              <div v-else class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                {{ $formatDate(form.transaction.next_charge_date) }}
              </div>
            </div>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <p class="text-sm font-medium text-gray-500">
              Final Charge
            </p>
            <div class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">
              <a-date-picker v-if="controls.createMode" v-model="controls.transaction.finalChargeDate"
                :show-time="chargeDateShowTime" :default-value="controls.transaction.finalChargeDate"
                :format="chargeDateFormat" :disabled="controls.transaction.disableFinalChargeDate" @change="(value, dateString) => {
                  console.log('final charge: ', value, dateString);
                  form.transaction.final_charge_date = $formatMomentForBackendUp(
                    value
                  );
                }
                  ">
                <template #suffixIcon>
                  <a-icon type="calendar" theme="twoTone" />
                </template>
              </a-date-picker>
              <div v-else class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                {{ $formatDate(form.transaction.final_charge_date) }}
              </div>
            </div>
          </div>
          <div v-if="form.smartcar.trunk_delivery" class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <p class="text-sm font-medium text-gray-500">
              Delivery Cost
            </p>
            <div class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">
              <a-input-number v-if="controls.createMode" v-model="form.smartcar.delivery_price" :formatter="value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                " :parser="value => value.replace(/\$\s?|(,*)/g, '')" :min="1" />
              <div v-else>
                {{ $formatCurrency(form.smartcar.delivery_price) }}
              </div>
            </div>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500 my-auto">
              Charge From
            </dt>
            <dd class="text-sm col-span-2 flex flex-col space-y-2 my-auto">
              <div v-if="controls.createMode" class="flex space-x-2 items-center">
                <div class="flex space-x-1 items-center">
                  <span class="text-xs">Enter by tenant?</span>
                  <a-switch v-model="form.transaction.request_user_enter_payment_method" size="small" />
                </div>
                <template v-if="!form.transaction.request_user_enter_payment_method">
                  <a-tooltip v-if="!$deepGet(form, 'tenant.id')" placement="top">
                    <template #title>
                      <span>Associate a tenant first. You can only help existed
                        tenant to setup payment method.</span>
                    </template>
                    <a class="text-gray-500 hover:text-gray-500 font-medium opacity-50 cursor-not-allowed">
                      Setup Payment
                    </a>
                  </a-tooltip>
                  <a v-else class="text-parqay-primary font-medium" @click="$openPaymentDrawer('host')">
                    Setup Payment
                  </a>
                </template>
              </div>
              <template v-else>
                <a v-if="form.transaction.payment_method" class="text-parqay-primary font-medium"
                  @click="$openPaymentDrawer('host')">
                  {{
                    `${startCase(
                      form.transaction.payment_method.brand
                    )} Card | End In ${form.transaction.payment_method.last4}`
                  }}
                </a>
                <a v-else class="text-parqay-primary font-medium disabled:opacity-50 disabled:cursor-not-allowed"
                  :disabled="!$deepGet(form, 'tenant.id')" @click="$openPaymentDrawer('host')">
                  Setup Payment for Tenant
                </a>
              </template>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500">
              Deposit To
            </dt>
            <dd class="flex col-span-2 justify-start">
              <stripe-button :title="{
                connect: 'Connect With',
                check: 'Payout Methods',
                update: 'Update Payout Methods'
              }" />
            </dd>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-transaction::v-deep {
  .ant-tag {
    @apply py-1 px-5 text-sm;
  }
}
</style>
