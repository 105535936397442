<script>
import { ref } from "@vue/composition-api";
import SlideBar from "src/views/components/components/SlideBar";
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "AdminAddSlideBar",
  components: {
    SlideBar,
  },
  props: {
    permissions: {
      type: Array,
      default: null,
    },
  },
  setup(props, ctx) {
    const model = ref({
        email: null,
        name: null,
        permissions: cloneDeep(props.permissions)
    });

    const visible = ref(false);

    return {
      model,
      visible,
      show() {
        visible.value = true;
      },
    };
  },
};
</script>
<template>
  <SlideBar
    v-model="visible"
    close-by-mask
    backdrop
  >
    <div class="divide-y">
      <div class="flex justify-between items-center px-6 py-6">
        <h3 class="font-bold text-lg text-gray-900 my-auto">
          Add Member
        </h3>
        <div @click="visible = false">
          <svg-icon
            class="w-6 h-6 cursor-pointer text-blue-900"
            icon-class="close"
          />
        </div>
      </div>
      <div>
        <div class="p-6 flex flex-col space-y-4">
          <div>
            <label
              for="email"
              class="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <div class="mt-1 flex rounded-md shadow-sm">
              <span
                class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
              >
                <svg-icon
                  icon-class="mail"
                  class="h-6 w-6"
                />
              </span>
              <input
                id="email"
                v-model="model.email"
                type="text"
                name="email"
                class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                placeholder="user@domain.com"
              >
            </div>
          </div>
          <div>
            <label
              for="name"
              class="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <div class="mt-1 flex rounded-md shadow-sm">
              <span
                class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
              >
                <svg-icon
                  icon-class="user"
                  class="h-6 w-6"
                />
              </span>
              <input
                id="name"
                v-model="model.name"
                type="text"
                name="name"
                class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                placeholder="Member name"
              >
            </div>
          </div>
          <div class="flex flex-col">
            <h4>Permissions</h4>
            <span>The member will have following access to your listing</span>
          </div>
          <div
            class="shadow-sm border rounded border-gray-300 bg-gray-50 divide-y"
          >
            <div
              v-for="permission in model.permissions"
              :key="permission.id"
              class="flex justify-between items-center px-2"
            >
              <span class="font-medium text-dark-purple py-2">{{
                permission.name
              }}</span>
              <a-checkbox v-model="permission.checked" />
            </div>
          </div>
        </div>
      </div>
      <div class="px-6 pt-4">
        <button
          class="rounded-lg bg-parqay-primary hover:bg-blue-500 font-medium w-full h-10 text-white"
        >
          Send Invitation
        </button>
      </div>
    </div>
  </SlideBar>
</template>