<script>
import {
  reactive,
  ref,
  computed,
  onMounted,
  nextTick,
  onBeforeUnmount
} from "@vue/composition-api";
import { Calendar } from "@toast-ui/vue-calendar";
import dayjs from "dayjs";
import ClickOutside from "vue-click-outside";
import startCase from "lodash/startCase";

import { API } from "src/views/reservations/api";

import Vue from 'vue'
import { MonthPicker } from 'vue-month-picker'

Vue.use(MonthPicker)

export default {
  name: "ParqayCalendar",
  components: {
    Calendar,
    MonthPicker
  },
  directives: {
    ClickOutside
  },
  props: {
    tips: {
      type: Object,
      default: () => {
        return {
          title: "To create new reservations",
          content: [
            "Click on any two dates to select a range",
            "Hold down shift, click on dates and release shift to setup reservation for multiple(descrete) dates"
          ]
        };
      }
    },
    focusDate: {
      type: dayjs,
      default: null
    },
    embedMode: {
      type: Boolean,
      default: false
    },
    disableControls: {
      type: Boolean,
      default: true
    },
    disableTooltip: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {

    const controls = reactive({
      loading: false,
      currentMonth: dayjs().month(),
      currentYear: dayjs().year(),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    });

    const reservations = ref([]);

    const tuiCalendar = ref(null);

    const monthPickerShow = ref(false);

    const scheduleList = ref([]);

    // const scheduleToAddList = computed(() => {
    //   return ctx.root.$store.getters.getScheduleToAddList();
    // });

    const view = ref("month");

    const taskView = ref(false);
    const scheduleView = ref(["time"]);
    const theme = ref({
      "month.dayname.height": "30px",
      "month.dayname.borderLeft": "1px solid #ff0000",
      "month.dayname.textAlign": "center",
      "week.daygridLeft.width": "100px",
      "week.timegridLeft.width": "100px",
      "month.schedule.height": "40px"
    });
    const week = ref({
      narrowWeekend: false,
      showTimezoneCollapseButton: true,
      timezonesCollapsed: false
    });
    const month = ref({
      visibleWeeksCount: 5,
      startDayOfWeek: 7
    });
    const timezones = ref([
      //{
      //  timezoneOffset: 540,
      //  displayLabel: "GMT+09:00",
      //  tooltip: "Seoul",
      //},
      {
        timezoneOffset: -420,
        displayLabel: "GMT-08:00",
        tooltip: "Los Angeles"
      }
    ]);
    const disableDblClick = ref(true);
    const isReadOnly = ref(false);
    const template = ref({
      milestone: function (schedule) {
        return `<span style="color:red;">${schedule.title}</span>`;
      },
      milestoneTitle: function () {
        return "MILESTONE";
      }
    });
    const useCreationPopup = ref(false);
    const useDetailPopup = ref(true);

    const calendarList = ref([
      {
        id: "0",
        name: "Reservations"
      },
      {
        id: "1",
        name: "New Reservation Intervals"
      }
    ]);

    const displayTip = ref(true);
    const hoveringTip = ref(false);

    const keyLogic = reactive({
      shiftState: false,
      range: {
        start: null,
        end: null
      },
      multiple: [],
      buildRangePreview: () => {
        const colorD = colorRandomizer();
        // Note: TZDate will change form when save to Vuex store, a conversion from TZDate to Date is required
        const startDt = keyLogic.range.start.date.toDate();
        const endDt = keyLogic.range.end.date.toDate();
        console.log("startDt >> ", startDt);
        console.log("endDt >> ", endDt);
        const scheduleToAdd = {
          id: ctx.root.$shortUID(),
          calendarId: "1",
          title: "+ New Schedule (drag to fine-tune the reservation schedule)",
          category: "time",
          dueDateClass: "",
          start: startDt,
          end: endDt,
          color: colorD.bgColor,
          bgColor: "white",
          borderColor: colorD.bgColor
        };
        // tuiCalendar.value.invoke("createSchedules", [scheduleToAdd]);
        // ctx.root.$store.commit("addScheduleToAddList", scheduleToAdd);
        keyLogic.reset("range");
      },
      buildMultiplePreview: () => {
        const colorD = colorRandomizer();
        keyLogic.multiple.forEach((v, i) => {
          // Note: TZDate will change form when save to Vuex store, a conversion from TZDate to Date is required
          const startDt = dayjs(v.date.toDate()).startOf("day");
          const endDt = dayjs(v.date.toDate()).endOf("day");
          const scheduleToAdd = {
            id: ctx.root.$shortUID(),
            calendarId: "1",
            title:
              "+ New Schedule (drag to fine-tune the reservation schedule)",
            category: "time",
            dueDateClass: "",
            start: startDt,
            end: endDt,
            color: colorD.bgColor,
            bgColor: "white",
            borderColor: colorD.bgColor
          };
          // tuiCalendar.value.invoke("createSchedules", [scheduleToAdd]);
          // ctx.root.$store.commit("addScheduleToAddList", scheduleToAdd);
        });
        keyLogic.reset("multiple");
      },
      reset: mode => {
        if (mode === "range") {
          if (keyLogic.range.start) {
            keyLogic.range.start.guide.clearGuideElement();
            keyLogic.range.start = null;
          }
          if (keyLogic.range.end) {
            keyLogic.range.end.guide.clearGuideElement();
            keyLogic.range.end = null;
          }
          return;
        }
        if (mode === "multiple") {
          keyLogic.multiple.forEach((v, i) => {
            v.guide.clearGuideElement();
          });
          keyLogic.multiple = [];
          return;
        }
      }
    });

    const scheduleRowHeight = computed(() => {
      if (reservations.value.length <= 1) {
        return 600;
      }
      if (reservations.value.length === 2) {
        return 800;
      }
      if (reservations.value.length >= 3) {
        return 1000;
      }
    })

    const setTargetMonthAndYear = mm => {
      controls.currentMonth = mm.month();
      controls.currentYear = mm.year();
      setMonthAndYear();
    };

    const setToday = () => {
      controls.currentMonth = dayjs().month();
      controls.currentYear = dayjs().year();
      setMonthAndYear();
    };

    const setFocusOnLatestReservation = reservation => {
      const durationsFrom = reservation.durations.map((duration) => dayjs(duration.from));
      const focusDate = dayjs.min(durationsFrom);
      controls.currentMonth = focusDate.month();
      controls.currentYear = focusDate.year();
      setMonthAndYear();
      if (!props.embedMode) {
        scrollToReservation(reservation.id);
      }
    }

    const setMonthAndYear = () => {
      const dt = dayjs().set('year', controls.currentYear).set('month', controls.currentMonth).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).toDate()
      console.log("setMonthAndYear: ", dt);
      tuiCalendar.value.invoke("setDate", dt);
    }

    const prev = () => {
      if (controls.currentMonth == 0) {
        controls.currentMonth = 11;
        controls.currentYear -= 1;
      } else {
        controls.currentMonth -= 1;
      }
      setMonthAndYear();
      fetchData();
    };

    const next = () => {
      if (controls.currentMonth == 11) {
        controls.currentMonth = 0;
        controls.currentYear += 1;
      } else {
        controls.currentMonth += 1;
      }
      setMonthAndYear();
      fetchData();
    };

    const handleKeyDown = event => {
      console.log(event.key + " > Down");
      if (event.key == "Shift") {
        keyLogic.shiftState = true;
      }
    };

    const handleKeyUp = event => {
      console.log(event.key + " > Up");
      if (event.key == "Shift") {
        keyLogic.shiftState = false;
        keyLogic.buildMultiplePreview();
      }
    };

    const colorRandomizer = () => {
      const hue = Math.floor(Math.random() * 360);
      const saturation = Math.floor(Math.random() * 26) + 85;
      const lightness = Math.floor(Math.random() * 16) + 55;
      const bgColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      let color = "white";
      if (lightness <= 58 || (lightness > 58 && (hue <= 40 || hue >= 200))) {
        // For lighter backgrounds, adjust the lightness to make sure the text color is white
        color = "white";
      } else {
        color = `hsl(${hue + 2}, ${Math.min(saturation + 10, 100)}%, ${Math.max(lightness - 50, 0)}%)`;
      }
      return {
        color,
        bgColor
      }
    };

    const convertReservationsToSchedule = m => {
      var sl = [];
      m.map((it, i) => {
        it.durations.forEach((dr, j) => {
          const colorD = colorRandomizer();
          sl.push({
            id: it.id,
            calendarId: "0",
            title: `${it.tenant_name} - ${it.listing.name} · ${startCase(it.listing.type)} - ${ctx.root.$formatCurrency(it.host_income)}`,
            category: "time",
            dueDateClass: "",
            start: dr.from,
            end: dr.to,
            color: colorD.color,
            bgColor: colorD.bgColor,
            borderColor: "white",
            dragBgColor: "rgba(129, 140, 248, 1)",
            raw: it
          });
        });
      });
      return sl;
    };

    const toggleViewMode = vm => {
      view.value = vm;
    };

    // Fix Calendar's Rendering Issue: color style is not rendered correctly if date range is smaller than one day
    // const fixDayScheduleStyle = () => {
    //   scheduleToAddList.value.forEach((sl, i) => {
    //     var targetDOMs = document.querySelectorAll(
    //       `[data-schedule-id="${sl.id}"]`
    //     );
    //     console.log("querySelectorAll", targetDOMs);
    //     if (targetDOMs.length > 0) {
    //       targetDOMs[0].style["background-color"] = sl.bgColor;
    //       targetDOMs[0].style["border-color"] = sl.borderColor;
    //       targetDOMs[0].style["color"] = sl.color;
    //     }
    //   });
    // };

    const scrollToReservation = (reservationId) => {
      nextTick(() => {
        const el = document.querySelector(
          `[data-schedule-id="${reservationId}"]`
        );
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      })
    }

    const onAfterRenderSchedule = () => {
      // fixDayScheduleStyle();
    };

    const onBeforeUpdateSchedule = event => {
      console.log("beforeUpdateSchedule");
      var schedule = event.schedule;
      var changes = event.changes;
      console.log("changes", changes);

      const payload = { ...changes };
      payload.id = schedule.id;
      payload.start = payload.start.toDate();
      payload.end = payload.end.toDate();

      // ctx.root.$store.commit("updateScheduleToAddList", payload);

      // tuiCalendar.value.invoke(
      //   "updateSchedule",
      //   schedule.id,
      //   schedule.calendarId,
      //   changes
      // );
    };
    const createNewReservation = event => {
      var guide = event.guide;
      if (props.disableControls) {
        console.log("createNewReservation >> prevented");
        guide.clearGuideElement();
        return;
      }
      console.log("createNewReservation");
      console.log("shift state", keyLogic.shiftState);
      var startTime = event.start;
      var endTime = event.end;
      var isAllDay = event.isAllDay;
      console.log(`startTime`, startTime);
      console.log(`endTime`, endTime);
      console.log(`isAllDay`, isAllDay);
      // Use guideEl$'s left, top to locate your schedule creation popup
      var guideEl$ = guide.guideElement
        ? guide.guideElement
        : guide.guideElements[Object.keys(guide.guideElements)[0]];
      console.log("guide.guideElements", guide.guideElements);

      if (!keyLogic.shiftState) {
        if (!keyLogic.range.start) {
          if (keyLogic.multiple.length > 0) {
            keyLogic.reset("multiple");
            console.log("multiple > reset");
          }
          keyLogic.range.start = {
            date: startTime,
            guide
          };
          console.log("selected range start");
        } else {
          keyLogic.range.end = {
            date: startTime,
            guide
          };
          console.log("selected range end");
          keyLogic.buildRangePreview();
        }
      } else {
        if (keyLogic.range.start || keyLogic.range.end) {
          keyLogic.reset("range");
          console.log("range > reset");
        }
        // keyLogic.range.start = {
        //     date: startTime,
        //     guide,
        //   };
        //   console.log("selected range start");
        keyLogic.multiple.push({
          date: startTime,
          guide
        });
      }
      // After that call this to hide the creation guide
      // guide.clearGuideElement();
    };

    const setupSchedulePopupDotColor = color => {
      const targetDOM = document.querySelectorAll(
        ".tui-full-calendar-calendar-dot"
      );
      if (targetDOM.length > 0) {
        console.log("targetDOM", targetDOM);
        targetDOM[0].style["background-color"] = color;
      }
    };

    const toggleDetailsPopup = (enable, schedule) => {
      const targetDOMs = document.querySelectorAll(
        `[class*="tui-full-calendar-floating-layer"]`
      );
      console.log("toggleDetailsPopup", targetDOMs);
      if (targetDOMs.length > 0) {
        targetDOMs.forEach((d, i) => {
          d.style["display"] = enable ? "block" : "none";
        });
      }
    };

    const onClickSchedule = event => {
      console.log("onClickSchedule", event.schedule);
      var schedule = event.schedule;
      if (schedule.raw) {
        toggleDetailsPopup(false, schedule);
        // Only existing schedule is able to open
        ctx.root.$bus.$emit('open-reservation-drawer', null, schedule.raw.id);
      } else {
        toggleDetailsPopup(true, schedule);
        setupSchedulePopupDotColor(schedule.borderColor);
      }
    };
    const deleteSchedule = id => {
      scheduleList.value.forEach((v, i) => {
        if (id === v.id) {
          scheduleList.value.splice(i, 1);
          ctx.root.$store.commit("deleteScheduleToAddFromList", { id });
        }
      });
    };

    const getCurrentMonthTimestamps = () => {
      // Get the current UTC date
      const currentDate = new Date();

      // Get the start of the current month
      const startOfMonth = new Date(Date.UTC(controls.currentYear, controls.currentMonth, 1));
      const startOfMonthTimestamp = Math.floor(startOfMonth.getTime() / 1000); // Convert to seconds

      // Get the end of the current month
      const endOfMonth = new Date(Date.UTC(controls.currentYear, controls.currentMonth + 1, 0));
      const endOfMonthTimestamp = Math.floor(endOfMonth.getTime() / 1000); // Convert to seconds

      // Return the timestamps as an object
      return {
        start: startOfMonthTimestamp,
        end: endOfMonthTimestamp
      };
    };

    const onBeforeDeleteSchedule = event => {
      var schedule = event.schedule;
      deleteSchedule(schedule.id);
    };

    const fetchData = async () => {
      try {
        controls.loading = true;
        let filter;
        if (props.embedMode) {
          filter = {
            tab: "requesting"
          }
        } else {
          const currentMonth = getCurrentMonthTimestamps();
          filter = {
            range: {
              start: currentMonth.start,
              end: currentMonth.end
            }
          }
        }
        const { data } = await API.list({
          ...filter,
          page: -1 // Means no pagination.
        });
        reservations.value = data;
        scheduleList.value = convertReservationsToSchedule(reservations.value);
        controls.loading = false;
        tuiCalendar.value.invoke("render");
      } catch (error) {
        controls.loading = false;
        console.log("reservation error: ", error);
        return;
      }
    };
    onMounted(async () => {
      ctx.root.$bus.$on("calendar-refresh", fetchData);
      // ctx.root.$store.commit('clearScheduleToAddList');
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
      await fetchData();
      if (reservations.value.length == 0) {
        setToday();
      } else {
        setFocusOnLatestReservation(reservations.value[0]);
      }
    });
    nextTick(() => {
      if (props.focusDate) {
        setTargetMonthAndYear(props.focusDate);
      }
    });
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("calendar-refresh", fetchData);
    })
    return {
      controls,
      scheduleRowHeight,
      tuiCalendar,
      monthPickerShow,
      calendarList,
      displayTip,
      hoveringTip,
      setToday,
      prev,
      next,
      scheduleList,
      view,
      toggleViewMode,
      taskView,
      scheduleView,
      theme,
      week,
      month,
      timezones,
      disableDblClick,
      isReadOnly,
      template,
      useCreationPopup,
      useDetailPopup,
      fetchData,
      setMonthAndYear,
      onBeforeUpdateSchedule,
      createNewReservation,
      onClickSchedule,
      onAfterRenderSchedule,
      deleteSchedule,
      onBeforeDeleteSchedule,
      dayjs,
      console
    };
  }
};
</script>
<template>
  <div class="pq-calendar flex flex-col" :class="embedMode ? 'h-full' : 'pb-4'">
    <div class="bg-white flex flex-col overflow-hidden relative"
      :class="embedMode ? 'pt-2' : 'border rounded-lg py-4 shadow-sm'">
      <div class="flex px-4 space-x-4 items-center justify-between" :class="embedMode ? 'pb-2' : 'pb-5'">
        <div v-if="$route.name === 'dashboard'" class="cursor-pointer" @click.stop="() => {
          $emit('toggle-size');
          $nextTick(() => {
            tuiCalendar.invoke('render');
          })
        }">
          <svg-icon v-if="embedMode" icon-class="expand" class="text-black hover:text-blue-500 w-6 h-6" />
          <svg-icon v-else icon-class="shrink" class="text-black hover:text-blue-500 w-6 h-6" />
        </div>
        <div v-if="!embedMode">
          <div class="flex rounded-full border bg-gray-50 cursor-pointer">
            <div class="px-4 py-2 border-r flex flex-initial justify-center hover:text-blue-700"
              :class="{ 'text-parqay-primary': view == 'month' }" @click="toggleViewMode('month')">
              <span class="text-center w-full my-auto">Month</span>
            </div>
            <div class="px-4 py-2 flex flex-initial justify-center hover:text-blue-700"
              :class="{ 'text-parqay-primary': view == 'day' }" @click="toggleViewMode('day')">
              <span class="text-center w-full my-auto">Day</span>
            </div>
          </div>
        </div>
        <div class="flex justify-between px-4 py-2" :class="embedMode ? 'space-x-2' : 'w-1/2'">
          <div class="flex flex-initial rounded-full border bg-gray-50 w-10 h-10 cursor-pointer">
            <div class="w-full h-full flex justify-center hover:text-parqay-primary" @click="prev">
              <svg-icon icon-class="chevron-left" class="h-5 w-5 hover:text-parqay-primary my-auto" />
            </div>
          </div>
          <div v-click-outside="() => monthPickerShow = false"
            class="flex flex-initial rounded-full border bg-gray-50 h-10 cursor-pointer relative">
            <div class="w-40 h-full flex border-r justify-center hover:text-blue-700 overflow-hidden"
              :class="{ 'text-parqay-primary': view == 'month' }" @click="() => monthPickerShow = true">
              <span class="text-center w-full my-auto">{{ controls.months[controls.currentMonth] }}, {{
                controls.currentYear }}</span>
            </div>
            <div v-if="monthPickerShow" class="absolute right-1/2 transform translate-x-1/2 top-10 z-30">
              <month-picker no-default @input="(e) => {
                console.log(e);
                const { monthIndex, year } = e;
                controls.currentMonth = monthIndex - 1;
                controls.currentYear = year;
                setMonthAndYear();
                fetchData();
                monthPickerShow = false;
              }">
              </month-picker>
            </div>
            <div class="w-20 h-full flex justify-center hover:text-parqay-primary" @click="setToday">
              <span class="text-center w-full my-auto">Today</span>
            </div>
          </div>
          <div class="flex flex-initial rounded-full border bg-gray-50 w-10 h-10 cursor-pointer">
            <div class="w-full h-full flex justify-center hover:text-parqay-primary" @click="next">
              <svg-icon icon-class="chevron-right" class="h-5 w-5 hover:text-parqay-primary my-auto" />
            </div>
          </div>
        </div>
        <div v-if="!embedMode" class="flex items-center space-x-4" :style="{ 'min-width': '200px' }">
          <div class="flex flex-col justify-center items-center relative">
            <!-- <button class="px-4 py-2 rounded bg-green-400 hover:bg-green-600 disabled:opacity-50 text-white font-medium"
              @click="$router.push('/dashboard/reservations/new')">
              + Create Reservation
            </button> -->
            <!-- <div class="flex justify-center items-center space-x-2 absolute"
              :style="{ bottom: '-1.25rem', left: '-1rem', right: '-1rem' }">
              <div class="flex items-center space-x-1">
                <span class="text-xs font-medium">Display Tip</span>
                <a-switch v-model="displayTip" default-checked size="small" />
              </div>
              <a class="text-xs text-green-500 font-medium text-center">
                ( {{ scheduleToAddList.length }} intervals )
              </a>
            </div> -->
          </div>
          <!-- <button v-if="scheduleToAddList.length > 0" class="text-pink-500 font-medium" @click="() => {
            scheduleToAddList.forEach((v, i) => {
              tuiCalendar.invoke('deleteSchedule', v.id, v.calendarId);
            });
            $store.commit('clearScheduleToAddList');
          }
            ">
            Reset
          </button> -->
        </div>
      </div>
      <!-- <a-tooltip placement="top" :default-visible="!disableTooltip"
        :visible="hoveringTip && displayTip && !disableTooltip">
        <template slot="title">
          <div class="relative">
            <div class="flex flex-col justify-center divide-y pt-6">
              <span class="text-center py-2 text-lg font-bold">{{
              tips.title
              }}</span>
              <span v-for="(t, i) in tips.content" :key="`tips-${i}`" class="text-center py-2">{{ t }}</span>
            </div>
            <div class="flex items-center space-x-4 absolute right-1 top-1">
              <span class="text-xs font-medium">Display Tip</span>
              <a-switch v-model="displayTip" default-checked size="small" />
            </div>
          </div>
        </template> -->
      <div v-if="controls.loading" class="absolute inset-0">
        <div class="flex items-center justify-center" style="height: 90vh;">
          <div class="bg-white flex flex-col items-center justify-center border rounded-md px-12 py-12 z-10">
            <div class="bg-white rounded-full cursor-pointer relative" style="width: 6.5rem; height: 6.5rem;">
              <div class="absolute inset-0 flex flex-col space-y-4 h-full items-center justify-center text-orange-500">
                <svg-icon icon-class="calendar" class="w-18 h-18 animate-bounce" />
                <span class="text-sm font-bold">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-click-outside="() => (hoveringTip = false)" @mouseover="() => {
        console.log('mouseover');
        hoveringTip = true;
      }
        ">
        <calendar ref="tuiCalendar" :style="{ height: embedMode ? '100%' : scheduleRowHeight + 'px' }" class="border-b"
          :class="embedMode ? 'embed' : ''" :calendars="calendarList" :schedules="scheduleList" :view="view"
          :task-view="taskView" :schedule-view="scheduleView" :theme="theme" :week="week" :month="month"
          :timezones="timezones" :disable-dbl-click="disableDblClick" :is-read-only="isReadOnly" :template="template"
          :use-creation-popup="useCreationPopup" :use-detail-popup="useDetailPopup"
          @beforeUpdateSchedule="onBeforeUpdateSchedule" @beforeCreateSchedule="createNewReservation"
          @afterRenderSchedule="onAfterRenderSchedule" @clickSchedule="onClickSchedule"
          @beforeDeleteSchedule="onBeforeDeleteSchedule" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pq-calendar ::v-deep {
  .month-picker__year {
    button {
      @apply bg-white border-none;
    }
  }

  .embed {
    .tui-full-calendar-weekday-grid-header {
      // @apply flex items-center;
    }

    .tui-full-calendar-weekday-grid-more-schedules {
      @apply p-0;
    }
  }

  .full-featured {
    .tui-full-calendar-weekday-grid-line {
      &:hover {

        background-color: rgba(88, 80, 236, 1) !important;

        .tui-full-calendar-weekday-grid-header {
          &::after {
            content: "Create Reservation";
            font-size: 0.8rem;
            line-height: 0.8rem;
            @apply absolute left-0 right-0 bottom-0 h-1/4 text-center text-white;
          }

          @apply h-full relative;
        }

        .tui-full-calendar-weekday-grid-date {
          color: white;

          &::after {
            content: "+";
            font-size: 5rem;
            line-height: 5rem;
            @apply absolute left-1/2 transform -translate-x-1/2 top-1/4 -translate-y-1/4 animate-pulse;
          }
        }

        @apply z-0;
      }

      .tui-full-calendar-weekday-grid-header {
        @apply h-full relative;
      }

      @apply cursor-pointer flex flex-col;
    }
  }

  .tui-full-calendar-section-calendar {
    width: 100%;
  }

  .tui-full-calendar-section-title {
    padding-right: 4px !important;
    width: calc(100% - 40px);
  }

  .tui-full-calendar-button.tui-full-calendar-section-private {
    padding: 8px;
  }

  .tui-full-calendar-section-start-date,
  .tui-full-calendar-section-end-date {
    width: unset;
  }

  .tui-full-calendar-section-state {
    width: unset;
  }

  .tui-full-calendar-icon.tui-full-calendar-calendar-dot {
    @apply bg-parqay-primary;
  }

  .tui-full-calendar-weekday-schedule {
    @apply border;
  }

  .tui-full-calendar-section-button {
    .tui-full-calendar-popup-edit {
      display: none;
    }

    .tui-full-calendar-popup-vertical-line {
      display: none;
    }

    .tui-full-calendar-popup-delete {
      width: unset;

      .tui-full-calendar-ic-delete {
        height: 20px;
        width: 20px;
        -webkit-mask-image: url("/static/img/Icons/trash.svg");
        mask-image: url("/static/img/Icons/trash.svg");
        background-image: unset;
        @apply bg-white my-auto;
      }

      .tui-full-calendar-content {
        @apply text-sm my-auto;
      }

      @apply bg-red-500 hover:bg-red-600 rounded-lg text-white focus:outline-none py-2 px-6;
    }

    @apply py-4 flex items-center justify-center;
  }

  .tui-full-calendar-weekday-schedule-title {
    &:hover {
      @apply scale-110 text-center;
    }

    @apply cursor-pointer text-left transform transition duration-500;
  }
}
</style>
