<script>
import { reactive, ref, onMounted } from '@vue/composition-api';
import VueQr from 'vue-qr'
import html2canvas from 'html2canvas';

import { API } from "./api";

export default {
  components: {
    VueQr
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {

    const loading = reactive({
      list: false
    })

    const visible = reactive({
      attendantCard: false
    })

    const table = reactive({
      data: [
        // {
        //   id: "2NKX3Htsu3MthZcE5Qpavr",
        //   role: 'attendant',
        //   name: 'Sam',
        //   email: 'dev@example.com',
        //   phone_number: '123-456-7890',
        //   token: '12345',
        //   invited_at: new Date().toISOString(),
        //   $read_only: true
        // }
      ],
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          scopedSlots: { customRender: 'email' }
        },
        {
          title: 'Phone Number',
          dataIndex: 'phone_number',
          key: 'phone_number',
          scopedSlots: { customRender: 'phone_number' }
        },
        {
          title: 'Token',
          dataIndex: 'token',
          key: 'token',
          scopedSlots: { customRender: 'token' }
        },
        {
          title: 'Invited At',
          dataIndex: 'invited_at',
          key: 'invited_at',
          width: 150,
          scopedSlots: { customRender: 'invited_at' }
        },
        {
          title: '',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' }
        },
      ],
    });

    const focusAttendant = ref({
      id: ctx.root.$shortUID(),
      name: null,
      email: null,
      phone_number: null,
      token: null,
      invited_at: null
    });

    const listAttendants = async () => {
      loading.list = true;
      const { data } = await API.listAttendants(props.form.id);
      table.data = data.map(it => ({
        ...it,
        $read_only: true,
        $show_token: false
      }));
      loading.list = false;
    }

    const assignAttendant = async (record) => {
      loading.list = true;
      await API.assignAttendant(props.form.id, record);
      listAttendants();
    }

    const getAttendantLoginURL = () => {
      return process.env.VUE_APP_FRONTEND_BASE_URL + `/attendant/reservations?id=${focusAttendant.value.id}&token=${focusAttendant.value.token}`;
    }

    const addNewAttendant = () => {
      table.data.push({
        id: ctx.root.$shortUID(),
        name: null,
        email: null,
        phone_number: null,
        token: null,
        invited_at: null,
        $read_only: false,
        $show_token: false
      })
    }

    const exportDivAsPNG = async (el, attendant) => {
      try {
        const canvas = await html2canvas(el);
        const image = canvas.toDataURL("image/png");
        const link = document.createElement('a');
        link.download = `${attendant.name}-Attendant-ID-Card.png`;
        link.href = image;
        document.body.appendChild(link); // Append the link to the body
        link.click();
        document.body.removeChild(link); // Remove the link after clicking
      } catch (error) {
        console.error('Error exporting div to PNG:', error);
      }
    };

    const update = (record) => {
      // Logic to update record
      console.log('Update', record);
    };

    const remove = (id) => {
      // Logic to remove record
      console.log('Remove', id);
    };

    onMounted(() => {
      listAttendants();
    })


    return {
      loading,
      visible,
      table,
      focusAttendant,
      listAttendants,
      assignAttendant,
      getAttendantLoginURL,
      update,
      remove,
      addNewAttendant,
      exportDivAsPNG
    };
  },
}
</script>
<template>
  <div class="bg-white flex justify-center mx-4 w-full">
    <div class="w-full max-w-6xl">
      <div class="flex justify-between items-center p-4">
        <div class="flex items-center space-x-4">
          <span class="text-base font-semibold text-black">Attendants Management</span>
          <a-icon type="reload" :spin="loading.list" @click="listAttendants()" />
        </div>
        <a-button type="primary" icon="plus" @click="addNewAttendant()" />
      </div>
      <a-table :columns="table.columns" :data-source="table.data" row-key="id" class="overflow-hidden">
        <template slot="name" slot-scope="name, record">
          <div v-if="record.$read_only" class="flex items-center space-x-4">
            <span>{{ name }}</span>
            <a-button id-card type="primary" icon="idcard" size="small" @click="() => {
              focusAttendant = record;
              visible.attendantCard = true;
            }">ID
              Card</a-button>
            <!-- <a-tag color="geekblue">{{ role }}</a-tag> -->
          </div>
          <a-input v-else v-model="record.name" allow-clear placeholder="John Parker" />
        </template>
        <template slot="email" slot-scope="email, record">
          <span v-if="record.$read_only">{{ email }}</span>
          <a-input v-else v-model="record.email" allow-clear placeholder="example@parqay.com" />
        </template>
        <template slot="phone_number" slot-scope="phone_number, record">
          <span v-if="record.$read_only">{{ phone_number }}</span>
          <a-input v-else v-model="record.phone_number" allow-clear placeholder="123-456-7890m" />
        </template>
        <template slot="token" slot-scope="token, record">
          <div class="flex items-center space-x-2">
            <template v-if="record.$read_only">
              <span>{{ record.$show_token ? token : '•'.repeat(4) }}</span>
              <a-icon v-if="record.$show_token" type="eye-invisible" @click="() => record.$show_token = false" />
              <a-icon v-else type="eye" @click="() => record.$show_token = true" />
            </template>
            <a-input-password v-else v-model="record.token" allow-clear placeholder="" />
          </div>
        </template>
        <template slot="invited_at" slot-scope="invited_at, record">
          <span v-if="invited_at">{{ $formatDate(invited_at, "MM/DD/YYYY") }}</span>
          <span v-else>{{ $formatDate(new Date().toISOString(), "MM/DD/YYYY") }}</span>
        </template>
        <template slot="actions" slot-scope="actions, record, i">
          <div class="flex items-center space-x-2">
            <a-button v-if="record.$read_only" icon="edit" @click="() => record.$read_only = false" />
            <a-button v-else type="primary" icon="check" @click="() => {
              assignAttendant(record);
              record.$read_only = true;
            }" />
            <!-- <a-button icon="stop" /> -->
            <!-- <a-button v-if="record.$read_only" type="danger" icon="close" @click="remove(record.id)" /> -->
            <a-button v-if="!record.$read_only" icon="close" @click="() => {
              if (record.invited_at) {
                record.$read_only = true
              } else {
                table.data.splice(i, 1);
              }
            }" />
          </div>
        </template>
      </a-table>
    </div>
    <a-modal v-model="visible.attendantCard" width="500px" :body-style="{ padding: 0 }" wrap-class-name="attendant-modal"
      :closable="false" :footer="null" @close="() => visible.attendantCard = false">
      <div ref="attendant-id-card" class="flex flex-col rounded-lg overflow-hidden">
        <div class="flex flex-col items-center pt-4 bg-parqay-primary">
          <div class="rounded-full bg-white h-4 w-20" />
          <span class="text-base font-semibold text-white text-center py-4">Attendant Identity Card</span>
        </div>
        <div class="bg-white flex flex-col space-y-6 items-center justify-center px-4 pt-16 pb-12">
          <div class="bg-gray-200" style="width: 200px; height: 200px;">
            <vue-qr color-dark="#1c55e4" background-color="#c5d4fa" logo-src="/static/img/dashboard/parqay-logo.png"
              :text="getAttendantLoginURL()" :dot-scale="0.8" :logo-scale="0.2" :size="300"
              class="cursor-pointer hover:ring-8 ring-parqay-primary" />
          </div>
          <div class="flex flex-col space-y-1">
            <span class="text-lg font-bold text-center text-black">{{ focusAttendant.name }}</span>
            <span class="text-base text-center text-parqay-primary">{{ focusAttendant.email }}</span>
            <span class="text-sm text-center">#{{ Math.floor(new Date(focusAttendant.invited_at).getTime() / 1000) }}-{{
              focusAttendant.token }}</span>
          </div>
          <div class="flex flex-col items-center bg-blue-100 rounded-lg mx-8 px-4 py-6 space-y-1">
            <span class="text-black text-sm font-semibold underline">
              HOW TO USE
            </span>
            <span class="text-sm text-center text-black">
              Scan the QR code above from any mobile browser to access.
            </span>
            <span class="text-sm font-light text-center pt-1 text-gray-500">
              Alternatively, visit <a-icon type=link />
              <a :href="getAttendantLoginURL()" target="_blank" class="text-parqay-primary font-medium">
                {{ getAttendantLoginURL() }}
              </a>
              and follow the provided instructions.
            </span>
            <span class="text-sm font-light text-center pt-1 text-gray-500 italic">
              This identity card is exclusively for authorized use. Property supervisors reserve the right to revoke
              authorization at any time.
            </span>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center border-t py-4">
        <a-button type="link" size="large" @click="exportDivAsPNG($refs['attendant-id-card'], focusAttendant)">Export As
          PNG</a-button>
      </div>
    </a-modal>
  </div>
</template>

<style lang="scss" scoped>
.ant-btn {
  @apply flex items-center justify-center space-x-2;
}

.ant-btn[id-card] {
  @apply text-xs font-medium;
}
</style>

<style lang="scss">
.attendant-modal {
  .ant-modal-content {
    @apply rounded-lg overflow-hidden;
  }
}
</style>