<script>
export default {
  props: {
    controls: {
      type: Object,
    },
    pricing: {
      type: Object,
    },
    timeFormat: {
      type: Object,
    },
  },
  setup(props, ctx) {
    const dismiss = () => (props.controls.showIncomeDetails = false);
    const openIntercom = () => {
      // window.Intercom("show");
    };
    return {
      dismiss,
      openIntercom,
    };
  },
};
</script>
<template>
  <a-modal
    title="Income Details"
    :visible="controls.showIncomeDetails"
    :body-style="{ background: 'rgba(244, 245, 247, 1)' }"
    @ok="
      () => {
        $emit('dismiss');
        dismiss();
      }
    "
    @cancel="
      () => {
        $emit('dismiss');
        dismiss();
      }
    "
  >
    <div class="flex flex-col space-y-8 pt-4 pb-8">
      <div class="flex flex-col divide-y border rounded-xl bg-white">
        <div class="flex w-full justify-between items-center py-4 px-4">
          <span class="text-lg text-black font-medium text-left">Price</span>
          <span class="text-lg text-gray-600 font-medium text-right">{{ $getPrice(pricing.price) }}</span>
        </div>
        <div class="flex w-full justify-between items-center py-4 px-4">
          <span class="text-lg text-black font-medium text-left">Fee</span>
          <span class="text-lg text-pink-500 font-medium text-right">-{{ $getPrice(pricing.fee) }}</span>
        </div>
        <div class="flex justify-end text-xl text-right space-x-4 items-center py-6 px-4">
          <span class="text-black font-semibold">Income</span>
          <span class="text-green-400 font-bold">{{ $getPrice(pricing.income) }}</span>
        </div>
      </div>
    </div>
    <template #footer>
      <a-button @click="openIntercom">
        Ask a question
      </a-button>
      <a-button
        type="primary"
        @click="
          () => {
            dismiss();
          }
        "
      >
        OK
      </a-button>
    </template>
  </a-modal>
</template>