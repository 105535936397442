import request from "src/services/api/request";

export const API = {
  listAttendants(listing_id) {
    return request.get("team/attendants", { filter: { listing_id } });
  },
  assignAttendant(listing_id, payload) {
    return request.put(`team/attendants/assign/${listing_id}`, payload);
  },
  listReservations(attendant_id, filter = {}) {
    return request.get(`team/attendants/${attendant_id}/reservations`, { filter });
  },
  login(id, token) {
    return request.post("team/attendant/login", { id, token });
  },
  manualLogin(email, token) {
    return request.post("team/attendant/login", { email, token });
  },
  checkIn(attendant_id, reservation_id) {
    return request.post(`team/attendants/${attendant_id}/reservations/${reservation_id}/check-in`);
  }
};
