<script>
export default {
  props: {
    menuItems: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    return {};
  },
};
</script>
<template>
  <div
    class="flex flex-col border-r bg-white"
    :style="{ height: 'calc(100%-4rem)', width: '28rem' }"
  >
    <smooth-scroll>
      <div class="flex flex-col space-y-2 items-start justify-center px-4 py-6">
        <div
          v-for="(it, i) in menuItems.main"
          :key="`main-menu-item-${i}`"
          class="
            flex
            space-x-4
            items-center
            px-4
            py-2
            w-full
            rounded-xl
            border
            hover:bg-parqay-primary
            hover:text-white
            cursor-pointer
          "
          @click="$emit('scroll-to-section', it.anchor)"
        >
          <div class="w-10 h-10 rounded-full bg-gray-200" />
          <a class="font-semibold">{{ it.name }}</a>
          <a-tag
            v-if="!it.ready"
            color="orange"
          >
            Available Soon
          </a-tag>
          <a-tag
            v-else
            color="geekblue"
          >
            Ready
          </a-tag>
        </div>
      </div>
      <div
        class="
          border-t
          flex flex-col
          space-y-2
          items-start
          justify-center
          px-4
          py-6
        "
      >
        <template v-for="(it, i) in menuItems.secondary">
          <div
            :key="`secondary-menu-item-${i}`"
            class="
              flex
              justify-between
              items-center
              px-4
              py-2
              w-full
              hover:text-parqay-primary
              cursor-pointer
            "
            @click="$emit('scroll-to-section', it.anchor)"
          >
            <a class="font-semibold">{{ it.name }}</a>
            <a-tag
              v-if="!it.ready"
              color="orange"
            >
              Available Soon
            </a-tag>
            <a-tag
              v-else
              color="geekblue"
            >
              Ready
            </a-tag>
          </div>
          <div
            v-for="(sub, j) in it.sub"
            :key="`sub-menu-item-${i}-${j}`"
            class="
              flex
              justify-between
              items-center
              px-4
              py-2
              w-full
              hover:text-parqay-primary
              cursor-pointer
            "
            @click="$emit('scroll-to-section', sub.anchor)"
          >
            <a class="font-light ml-4">{{ sub.name }}</a>
            <a-tag
              v-if="!sub.ready"
              color="orange"
            >
              Available Soon
            </a-tag>
            <a-tag
              v-else
              color="geekblue"
            >
              Ready
            </a-tag>
          </div>
        </template>
      </div>
    </smooth-scroll>
  </div>
</template>