<script>
import {
  computed,
  onMounted,
  reactive,
  ref
} from "@vue/composition-api";
import EventPricing from "src/views/listings/detail/rates/EventPricing.vue";
import ImagesUploader from "src/views/components/ImagesUploader";
import Dropdown from "src/views/dropdowns/View";
import startCase from 'lodash/startCase';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import lowerCase from 'lodash/lowerCase';

export default {
  components: {
    EventPricing,
    ImagesUploader,
    Dropdown,
  },
  props: {
    controls: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const shortUID = ctx.root.$shortUID;
    const openNotification = ctx.root.$openNotification;
    const tiers = ref([{ id: shortUID(), price: 30, inventory: 1 }]);
    const buildingType = ref("residential");
    const nearbyLandmarks = ref("");
    const howDoGuestsEnterInstruction = ref("");
    const spaceType = ref("driveway");
    const spaceTypeOptions = ref([
      { name: "Driveway" },
      { name: "Garage" },
      { name: "Blocked Driveway" },
      { name: "Parking Garage" },
      { name: "Lot" },
    ]);
    const spaceNumber = ref(20);
    const amenities = reactive({
      space: {
        covered_space: false,
        ev_charging: false,
        gated: false,
        guarded: false,
        lit: false,
      },
      vehicle_size: {
        compact: false,
        mid: false,
        large: false,
        oversized: false,
      },
      documents_to_collect: {
        registration: false,
        insurance: false,
        license: false,
      }
    });
    const assignTowing = ref("No");
    const receivePackages = ref(false);
    const imagesUploader = ref(null);
    const imagesDataUrls = ref([]);
    // watch(
    //   () => ctx.root.$store.state.listingForm["events-details"].events,
    //   (val) => {
    //     console.log("watch listingForm events", val);
    //     reloadEventsFromEventPicker();
    //   }
    // );
    const getAllImages = () => {
      imagesDataUrls.value = imagesUploader.value.getAllAddedImagesDataUrls();
      console.log("imagesDataUrls", imagesDataUrls.value);
    };
    const next = () => {
      getAllImages();
      const verified = verifyFields();
      if (verified) {
        // Note: images storage requires indexedDB (big files)
        ctx.root.$setValueForIndexedDB(
          "listing-new-images",
          imagesDataUrls.value
        );
        // Note: normal payload storage uses Vuex Store
        ctx.emit("payload-update", "events-details", assemblePayloads());
        ctx.emit("next");
      }
    };
    const prev = () => {
      ctx.emit("back");
    };
    const assemblePayloads = () => {
      // Backend is using snake-case as its framework is Python
      return {
        events: assembleEvents(props.form.events),
        building_type: buildingType.value,
        space_type: spaceType.value,
        space_number: spaceNumber.value,
        // Convert Proxy back to Object, use Lodash method - cloneDeep
        amenities: cloneDeep(amenities),
        assign_towing: assignTowing.value,
        receive_packages: receivePackages.value,
        how_do_guests_enter_instruction: howDoGuestsEnterInstruction.value,
        nearby_landmarks: nearbyLandmarks.value,
      };
    };
    const assembleEvents = (e) => {
      var assembledEvents = [];
      e.forEach((v, i) => {
        assembledEvents.push({
          address: v.address,
          coords: v.coordinate,
          image: v.img_url,
          id: v.id,
          name: v.name,
          date: v.date_rfc3339,
          tiers: v.tiers
        })
      })
      return assembledEvents;
    };
    const verifyAmenities = () => {
      var verified = true;
      forOwn(amenities, (v, k) => {
        var checkedCount = 0;
        forOwn(v, (vv, kk) => {
          if (vv) {
            checkedCount += 1;
          }
        });
        if (checkedCount === 0) {
          openNotification(
            "Parking Space Amenities",
            `At least one item should be checked in ${startCase(k)} category`,
            10,
            "warning"
          );
          verified = false;
          return false;
        }
      });
      return verified;
    };
    const verifyFields = () => {
      // return true;
      if (!verifyAmenities()) {
        return false;
      }

      if (imagesDataUrls.value.length < 1) {
        openNotification(
          "Parking Space Images",
          `At least 1 image is required`,
          10,
          "warning"
        );
        return false;
      }

      if (!howDoGuestsEnterInstruction.value) {
        openNotification(
          "Parking Space Information",
          `"How do guests get to your space" is required field`,
          10,
          "warning"
        );
        return false;
      }

      if (!nearbyLandmarks.value) {
        openNotification(
          "Parking Space Information",
          `"Nearby Landmarks" is required field`,
          10,
          "warning"
        );
        return false;
      }

      return true;
    };
    // const reloadEventsFromEventPicker = () => {
    //   const payload = loadFormPayload("events-details");
    //   const _events = payload.events;
    //   _events.forEach((event, i) => {
    //     // Note: $set has to be used to make event.tiers reactive because it does not exist in previous step
    //     ctx.root.$set(event, "tiers", cloneDeep(tiers.value));
    //   });
    //   events.value = _events;
    // };
    const loadFormPayload = (namespace) => {
      return ctx.root.$store.state.listingForm[namespace];
    };
    const addTier = (event) => {
      console.log("addTier", event);
      event.tiers.push({ id: shortUID(), price: 30, inventory: 1 });
    };
    const removeTier = (event, i) => {
      event.tiers.splice(i, 1);
    };
    const selectSpaceType = (i, m) => {
      const spaceTypeName = lowerCase(m.name);
      spaceType.value = spaceTypeName;
    };
    onMounted(() => {
      // reloadEventsFromEventPicker();
    });
    return {
      tiers,
      buildingType,
      spaceNumber,
      spaceType,
      spaceTypeOptions,
      next,
      prev,
      selectSpaceType,
      amenities,
      nearbyLandmarks,
      howDoGuestsEnterInstruction,
      assignTowing,
      receivePackages,
      imagesUploader,
      imagesDataUrls,
      addTier,
      removeTier,
      startCase,
      lowerCase,
    };
  }
};
</script>

<template>
  <div class="event-details-and-price flex flex-col px-24 py-6">
    <div class="flex items-center justify-between border-b">
      <div class="w-full py-4">
        <h4 class="text-lg text-parqay-primary font-medium">
          {{ $t("general.details") }}
        </h4>
      </div>
      <div>
        <button
          class="text-parqay-primary font-medium border border-parqay-primary hover:bg-parqay-primary hover:text-white rounded-md py-2 px-4"
          @click="next">
          {{ $t("dashboard.continue") }}
        </button>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="flex">
        <div class="w-3/5 border-r py-4">
          <div class="grid grid-cols-2 gap-4 px-6">
            <div class="flex flex-col pt-4">
              <label class="text-base font-medium">
                {{ $t("general.type") }}
              </label>
              <div class="my-3">
                <a-radio-group v-model="buildingType" button-style="solid">
                  <a-radio-button v-for="(option, i) in $t('general.type1', {
                    returnObjects: true,
                  })" :key="`building-type-${i}`" :value="lowerCase(option)">
                    {{ option }}
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>
            <div class="flex flex-col pt-4">
              <div class="text-base font-medium">
                {{ $t("general.information") }}
              </div>
              <div class="flex flex-col pt-4 pb-2 space-y-2">
                <span>{{ $t("general.kind") }}</span>
                <dropdown class="w-full" :model="$t('general.kind1', { returnObjects: true })"
                  @dropdown-select="selectSpaceType" />
              </div>
            </div>
          </div>
          <event-pricing :controls="controls" :form="form" class="border-t py-4 pr-4 mt-4" />
          <div class="border-t py-4 pr-4">
            <div class="text-base font-medium">
              {{ $t("general.space") }}
            </div>
            <div class="grid grid-cols-3 gap-4 mt-4">
              <div v-for="(amenityOptions, amenityName, i) in amenities" :key="`amenity-group-${i}`"
                class="flex flex-col items-center space-y-2">
                <span class="text-base font-medium">{{
                  startCase(amenityName)
                }}</span>
                <div class="flex flex-col space-y-2">
                  <a-checkbox v-for="(v, k, j) in amenityOptions" :key="`amenity-option-${j}`"
                    v-model="amenityOptions[k]">
                    {{ startCase(k) }}
                  </a-checkbox>
                </div>
              </div>
              <div class="col-span-2 flex flex-col space-y-2">
                <span>{{ $t("dashboard.towing") }}</span>
                <a-radio-group v-model="assignTowing" button-style="solid">
                  <a-radio-button value="Yes">
                    Yes
                  </a-radio-button>
                  <a-radio-button value="No">
                    No
                  </a-radio-button>
                  <a-radio-button value="Use My Own">
                    Use My Own
                  </a-radio-button>
                </a-radio-group>
              </div>
              <div class="col-span-2 flex flex-col space-y-2">
                <span>{{ $t("dashboard.packages") }}</span>
                <a-radio-group v-model="receivePackages" button-style="solid">
                  <a-radio-button :value="true">
                    Yes
                  </a-radio-button>
                  <a-radio-button :value="false">
                    No
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div class="flex flex-col pr-4">
            <div class="flex space-x-2 mt-4">
              <div class="flex flex-col w-1/2 space-y-2">
                <span class="text-base font-medium">{{
                  $t("dashboard.how")
                }}</span>
                <a-textarea v-model="howDoGuestsEnterInstruction"
                  placeholder="This will show up in “Getting There” section in parking search. Enter any specific guidance to assistant guests enter this place"
                  :auto-size="{ minRows: 6, maxRows: 6 }" />
              </div>
              <div class="flex flex-col w-1/2 space-y-2">
                <span class="text-base font-medium">Nearby Landmarks</span>
                <a-textarea v-model="nearbyLandmarks" placeholder="Provide descriptions for nearby landmarks"
                  :auto-size="{ minRows: 6, maxRows: 6 }" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/5 flex flex-col p-4 mx-4 my-2 shadow-sm border bg-white rounded-lg">
          <h2 class="mb-2 text-center text-base font-medium py-4">
            {{ $t("listing.photo") }}
          </h2>
          <p class="text-center">
            {{ $t("listing.photoInfo") }}
          </p>
          <div class="flex-grow-0 justify-center items-center">
            <images-uploader ref="imagesUploader" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between mb-5 border-t py-6 mt-6">
      <div />
      <div>
        <button
          class="text-parqay-primary font-medium border border-parqay-primary hover:bg-parqay-primary hover:text-white rounded-md py-2 px-4"
          @click="next">
          {{ $t("dashboard.continue") }}
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped>
.event-details-and-price /deep/ .ant-input-number {
  width: 70px;
}

.event-details-and-price /deep/ .ant-checkbox-wrapper {
  margin-left: 0;
}
</style>