<script>
import {
    onMounted,
    onBeforeUnmount
} from "@vue/composition-api";

export default {
    name: "dashboard-live-preview",
    setup(props, ctx) {

        const handleIframeReadyState = (event) => {
            ctx.root.$toggleLoadingIndicator(false)
        }

        onMounted(() => {
            ctx.root.$toggleLoadingIndicator(true, "Loading Preview")
            window.addEventListener(
                "parqay-demo-iframe-ready",
                handleIframeReadyState,
                false
            );
            setTimeout(() => {
                handleIframeReadyState()
            }, 5000);
        });

        onBeforeUnmount(() => {
            window.removeEventListener(
                "parqay-demo-iframe-ready",
                handleIframeReadyState,
                false
            )
        })

        return {};
    }
};
</script>
<template>
    <div class="h-screen w-full overflow-y-scroll">
        <nav class="fixed bg-blue-500 h-10 flex justify-between items-center w-full px-4">
            <div class="flex items-center space-x-2">
                <a-tag color="white" class="flex items-center space-x-2 px-2">
                    <div class="h-1.5 w-1.5 bg-green-400 rounded-full animate-pulse"></div>
                    <span class="text-blue-500 font-medium">
                        Live Preview
                    </span>
                </a-tag>
                <a class="text-sm text-white font-medium hover:text-yellow-300"
                    @click="$router.push('/')">Home</a>
            </div>
            <span class="text-sm text-white hover:text-yellow-300 font-medium cursor-pointer"
                @click="$router.push('/sign-up')">Sign up to start your
                14-days free trial</span>
            <div class="flex items-center space-x-4">
                <a class="text-sm text-white font-medium hover:text-yellow-300"
                    @click="$router.push('/pricing')">Pricing</a>
                <button class="px-4 py-1 rounded text-sm font-medium text-white bg-orange-400"
                    @click="$router.push('/sign-up')">
                    Sign Up
                </button>
            </div>
        </nav>
        <iframe title="Parqay Dashboard Demo" class="mt-10 h-screen w-full" src="https://demo.parqay.com/dashboard">
        </iframe>
    </div>
</template>
<style lang="scss" scoped>
::v-deep {
    .ant-tag {
        @apply flex;
    }
}
</style>