<script>
import { ref } from "@vue/composition-api";
import SlideBar from "src/views/components/components/SlideBar";
import includes from 'lodash/includes';

export default {
  name: "EvOptionSlide",
  components: {
    SlideBar,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  setup(props, ctx) {

    const visible = ref(false);

    const types = ref([
      {
        name: "Supercharger",
        image: null, //Example: "/static/img/dashboard/plug.png"
        checked: false,
      },
      {
        name: "CCS/SAE",
        image: null,
        checked: false,
      },
      {
        name: "CHAdeMO",
        image: null,
        checked: false,
      },
      {
        name: "J-1772",
        image: null,
        checked: false,
      },
      {
        name: "Tesla",
        image: null,
        checked: false,
      },
      {
        name: "Tesla (Roadster)",
        image: null,
        checked: false,
      },
      {
        name: "NEMA 14-50",
        image: null,
        checked: false,
      },
      {
        name: "Wall",
        image: null,
        checked: false,
      },
    ]);

    const chooseType = (i) => {
      types.value[i].checked = !types.value[i].checked;
    };

    const typeImagePlaceholder = ref("/static/img/dashboard/plug.png");

    const networks = ref([
      {
        name: "BC Hydro EV",
        image: null, //Example: "/static/img/dashboard/charge-station.png"
        checked: false,
      },
      {
        name: "Blink",
        image: null,
        checked: false,
      },
      {
        name: "ChargePoint",
        image: null,
        checked: false,
      },
      {
        name: "Circuit Electrique",
        image: null,
        checked: false,
      },
      {
        name: "Electrify America",
        image: null,
        checked: false,
      },
      {
        name: "Electrify Canada",
        image: null,
        checked: false,
      },
      {
        name: "EV Connect",
        image: null,
        checked: false,
      },
      {
        name: "EVgo",
        image: null,
        checked: false,
      },
      {
        name: "FLO",
        image: null,
        checked: false,
      },
      {
        name: "GE WattStation",
        image: null,
        checked: false,
      },
      {
        name: "Greenlots",
        image: null,
        checked: false,
      },
      {
        name: "IVY",
        image: null,
        checked: false,
      },
      {
        name: "myEVroute",
        image: null,
        checked: false,
      },
      {
        name: "OpConnect",
        image: null,
        checked: false,
      },
      {
        name: "Petro-Canada",
        image: null,
        checked: false,
      },
      {
        name: "SemaConnect",
        image: null,
        checked: false,
      },
      {
        name: "Sun Country",
        image: null,
        checked: false,
      },
      {
        name: "Volta",
        image: null,
        checked: false,
      },
      {
        name: "Webasto",
        image: null,
        checked: false,
      },
      {
        name: "Other *",
        image: null,
        checked: false,
      },
    ]);

    const chooseNetwork = (i) => {
      networks.value[i].checked = !networks.value[i].checked;
    };

    const networkImagePlaceholder = ref(
      "/static/img/dashboard/charge-station.png"
    );

    const saveOptions = () => {
      ctx.emit("ev-option-update", {
        networks: networks.value.filter((v) => v.checked).map((v) => v.name),
        plug_types: types.value.filter((v) => v.checked).map((v) => v.name)
      });
      visible.value = false;
    };

    return {
      visible,
      types,
      networks,
      typeImagePlaceholder,
      networkImagePlaceholder,
      show() {
        types.value.map((v, i) => {
          if (includes(props.options.plug_types, v.name)) {
            types.value[i].checked = true;
          }
        });
        networks.value.map((v, i) => {
          if (includes(props.options.networks, v.name)) {
            networks.value[i].checked = true;
          }
        });
        visible.value = true;
      },
      chooseType,
      chooseNetwork,
      saveOptions,
    };
  },
};
</script>
<template>
  <SlideBar v-model="visible" close-by-mask backdrop>
    <div class="divide-y">
      <div class="flex justify-between items-center px-6 py-6">
        <h3 class="font-bold text-lg text-gray-900 my-auto">
          EV Options
        </h3>
        <div @click="visible = false">
          <svg-icon class="w-6 h-6 cursor-pointer text-blue-900" icon-class="close" />
        </div>
      </div>
      <div class="px-6 pt-3 pb-6">
        <h4 class="text-dark-purple text-lg font-medium">
          EV Plug Types ({{ types.length }})
        </h4>
        <div>
          <!-- grid here -->
          <div class="grid grid-cols-3 gap-3">
            <div v-for="(it, i) in types" :key="`type-${i}`"
              class="h-28 bg-gray-100 hover:bg-gray-300 relative rounded cursor-pointer" @click="chooseType(i)">
              <img class="w-full object-contain p-6" :src="it.image || typeImagePlaceholder" alt="">
              <svg-icon v-if="it.checked" class="text-green-400 w-6 h-6 absolute right-1 top-1" icon-class="right-mark" />
              <span class="text-xs absolute left-0 right-0 bottom-1 text-center">
                {{ it.name }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- net work -->
      <div class="px-6 pt-3 pb-6">
        <h4 class="text-dark-purple text-lg font-medium">
          EV Networks ({{ networks.length }})
        </h4>
        <div>
          <!-- grid here -->
          <div class="grid grid-cols-3 gap-3">
            <div v-for="(it, i) in networks" :key="`network-${i}`"
              class="h-28 bg-gray-100 hover:bg-gray-300 relative rounded cursor-pointer" @click="chooseNetwork(i)">
              <img class="w-full object-contain p-6" :src="it.image || networkImagePlaceholder" alt="">
              <svg-icon v-if="it.checked" class="text-green-400 w-6 h-6 absolute right-1 top-1" icon-class="right-mark" />
              <span class="text-xs absolute left-0 right-0 bottom-1 text-center whitespace-nowrap">
                {{ it.name }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center mt-6 px-6 py-4 space-x-8">
        <a-button type="primary" block size="large" @click="saveOptions">
          Save
        </a-button>
      </div>
    </div>
  </SlideBar>
</template>