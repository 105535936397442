<script>
import { reactive, ref } from "@vue/composition-api";
import Dropdown from "src/views/dropdowns/View";
import startCase from 'lodash/startCase';

export default {
  components: {
    Dropdown,
  },
  setup(props, ctx) {
    const model = ref([
      {
        price: 5,
        every: {
          amount: 1,
          unit: "hour",
        },
        enabled: false,
      },
      {
        price: 30,
        every: {
          amount: 1,
          unit: "day",
        },
        enabled: false,
      },
      {
        price: 300,
        every: {
          amount: 1,
          unit: "month",
        },
        enabled: true,
      },
    ]);
    const generateFreqSlots = (t) => {
      var slots = [];
      for (var i = 0; i < t; i++) {
        slots.push({
          id: i,
          name: i + 1,
        });
      }
      return slots;
    };
    const freqModel = reactive({
      hour: generateFreqSlots(12),
      day: generateFreqSlots(15),
      month: generateFreqSlots(6),
    });
    const assemblePayloads = () => {
      // Backend is using snake-case as its framework is Python
      return model.value;
    };
    return {
      model,
      freqModel,
      assemblePayloads,
      startCase
    };
  },
};
</script>
<template>
  <div
    class="
      flex flex-col
      space-y-0
      rounded-lg
      bg-white
      shadow-sm
      border
      divide-y
    "
  >
    <div class="px-6 py-4 flex items-center justify-center">
      <span class="text-base font-medium">Pricing</span>
    </div>
    <div class="px-6 py-4">
      <div class="flex items-center justify-center pb-6 px-6">
        <span
          class="text-sm text-gray-500 text-center"
        >Note complex pricing rule will only be available after successfully
          uploaded your property</span>
      </div>
      <div
        v-for="(pm, i) in model"
        :key="`price-mode-${i}`"
        class="flex items-center mb-2 justify-center px-12"
      >
        <div class="flex flex-col w-full">
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="
                absolute
                inset-y-0
                left-0
                pl-3
                flex
                items-center
                pointer-events-none
              "
            >
              <span class="text-gray-500 sm:text-sm"> $ </span>
            </div>
            <input
              v-model="pm.price"
              type="number"
              name="price"
              class="
                focus:ring-indigo-500
                focus:border-indigo-500
                block
                w-full
                pl-7
                pr-8
                sm:text-sm
                border-gray-300
                rounded-md
              "
              placeholder="8.00"
            >
            <div class="absolute inset-y-0 right-0 flex items-center">
              <div class="pr-4 flex items-center cursor-pointer">
                <span class="text-gray-500 hover:text-parqay-primary sm:text-sm">
                  every
                </span>
              </div>

              <label
                for="freq"
                class="sr-only"
              >Intervals</label>
              <dropdown
                :model="freqModel[pm.every.unit]"
                small
                :width="10"
                custom-text-color="text-parqay-primary"
                namespace="freq"
                @dropdown-select="
                  (i) => {
                    pm.every.amount = freqModel[pm.every.unit][i].name;
                  }
                "
              />
              <label
                for="units"
                class="sr-only"
              >Units</label>
              <dropdown
                :model="[{ name: startCase(pm.every.unit) + '(s)' }]"
                small
                :width="20"
                custom-text-color="text-parqay-primary"
                namespace="unit"
              />
            </div>
          </div>
        </div>
        <div class="w-1/5 inline-flex items-center justify-center">
          <a-switch
            v-model="pm.enabled"
            checked-children="Yes"
            un-checked-children="No"
          />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>