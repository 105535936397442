<script>
import { ref, reactive } from "@vue/composition-api";
import { API } from "src/views/listings/api";
import { convertToCumulativeConditionsIfNeeded, convertFromCumulativeConditionsIfNeeded } from "./util";
import startCase from "lodash/startCase";
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';



export default {
    props: {
        tiers: {
            type: Array,
            required: true
        },
        form: {
            type: Object,
            required: true
        }
    },
    setup(props, ctx) {

        props.form.rates.rules.forEach(rule => {
            ctx.root.$set(rule, "priceEditorVisible", false);
        });

        const loading = reactive({
            table: false,
            tier: false
        });

        const editingTier = ref(null);

        const editingRule = ref(null);

        const updatedPrice = ref(0);

        const updatePricingRule = async (payload) => {
            loading.table = true;
            try {
                await API.updatePricingRule(props.form.id, payload);
                ctx.emit("refresh");
            } catch (error) {
                console.log("updatePricingRule > error: ", error);
            } finally {
                loading.table = false;
            }
        }

        const updateTierPricingRule = async (tier) => {
            console.log("tier updates: ", tier);
            try {
                loading.tier = true;
                await API.updateTierPricingRule(props.form.id, tier.id, tier.$updates);
                editingRule.value = null;
                editingTier.value = null;
                ctx.emit("refresh");
            } catch (error) {
                ctx.root.$openNotification("Pricing Rule", error.message);
            }
            loading.tier = false;
        }

        const savePrice = (i, price) => {
            props.form.rates.rules[i].price = price;
            props.form.rates.rules[i].priceEditorVisible = false;
            updatePricingRule({
                id: props.form.rates.rules[i].id,
                price
            })
        };

        const updateIsActive = (i, is_active) => {
            updatePricingRule({
                id: props.form.rates.rules[i].id,
                is_active
            })
        }

        const editTiers = (rule) => {
            const tier = props.tiers.find(tier => tier.id === rule.id);
            console.log('tier -> ', tier);
            if (!tier) { return }
            tier.adjustments = convertFromCumulativeConditionsIfNeeded(tier.adjustments);
            editingTier.value = tier;
            editingRule.value = rule;
        }

        const applySign = (signStr, number) => {
            if (signStr !== '+' && signStr !== '-') {
                throw new Error("Sign string must be '+' or '-'");
            }
            return signStr === '+' ? Math.abs(number) : -Math.abs(number);
        };

        const generatePricingAdjustments = (tier, type) => {
            const adjustments = {
                size: [
                    {
                        type: "size",
                        tier: "oversized",
                        sign: "+",
                        adjustment: 3
                    },
                    {
                        type: "size",
                        tier: "motorcycle",
                        sign: "-",
                        adjustment: 2
                    }
                ],
                volume: [
                    {
                        type: "volume",
                        tier: 10,
                        sign: "-",
                        adjustment: 1
                    },
                    {
                        type: "volume",
                        tier: 20,
                        sign: "+",
                        adjustment: 0
                    },
                    {
                        type: "volume",
                        tier: 30,
                        sign: "+",
                        adjustment: 2
                    }
                ],
                time: [
                    {
                        type: "time",
                        tier: dayjs().add(10, "days").startOf('day').format("YYYY-MM-DDTHH:mm"),
                        sign: "-",
                        adjustment: 1
                    }
                ]
            };
            if (type === "volume") {
                if (tier.adjustments.filter(adj => adj.type === "volume").length > 0) {
                    tier.adjustments.push({
                        type,
                        tier: 10,
                        sign: "-",
                        adjustment: 1
                    });
                } else {
                    tier.adjustments.push(...adjustments[type]);
                }
            }
            if (type === "size") {
                if (tier.adjustments.filter(adj => adj.type === "size").length > 0) {
                    tier.adjustments.push({
                        type,
                        tier: "New Size",
                        sign: "-",
                        adjustment: 1
                    });
                } else {
                    tier.adjustments.push(...adjustments[type]);
                }
            }
            if (type === "time") {
                // Only one early bird tier is allowed.
                if (!tier.adjustments.find(adj => adj.type === "time")) {
                    tier.adjustments.push(...adjustments[type]);
                }
            }
            tier.$updates = { adjustments: convertToCumulativeConditionsIfNeeded(tier.adjustments) };
            console.log("generatePricingAdjustments -> tier.$updates:", tier.$updates);
        };

        const commitAdjustmentsUpdate = () => {
            editingTier.value.$updates = { adjustments: convertToCumulativeConditionsIfNeeded(editingTier.value.adjustments) };
        }

        const removeAdjustment = (tier, adjustment, type) => {
            const i = tier.adjustments.findIndex(adj => isEqual(adj, adjustment));
            tier.adjustments.splice(i, 1);
            tier.$updates = { adjustments: convertToCumulativeConditionsIfNeeded(tier.adjustments) };
            console.log("removeAdjustment -> tier.$updates:", tier.$updates);
        };

        return {
            loading,
            editingTier,
            editingRule,
            updatedPrice,
            savePrice,
            updateIsActive,
            editTiers,
            applySign,
            generatePricingAdjustments,
            commitAdjustmentsUpdate,
            removeAdjustment,
            convertToCumulativeConditionsIfNeeded,
            convertFromCumulativeConditionsIfNeeded,
            updateTierPricingRule,
            startCase,
            dayjs,
            console
        }
    }
}
</script>

<template>
    <div v-if="editingRule" class="flex flex-col w-full">

        <div class="flex items-center space-x-2 px-4 py-3 border-b">
            <a-button action icon="left" @click="() => {
        if ($deepGet(editingTier, '$updates.adjustments')) {
            // If there is updates, refresh listing details.
            $emit('refresh');
        }
        editingTier = null;
        editingRule = null;
    }">
                Back
            </a-button>
            <div class="w-full flex items-center justify-center space-x-1.5">
                <span class="text-base font-medium">Tiered Pricing</span>
            </div>
            <a-button action type="primary" :loading="loading.tier" icon="check"
                :disabled="!$deepGet(editingTier, '$updates.adjustments')" @click="() => {
        updateTierPricingRule(editingTier);
    }">
                Save
            </a-button>
        </div>

        <div class="flex space-x-4 px-4 pb-8">
            <div class="w-1/3 flex flex-col pt-6 space-y-2">
                <div v-if="editingRule.intervals === 'all' && editingRule.pricing_rule.type === 'constant'"
                    class="border shadow-sm rounded-lg flex flex-col px-4 py-4 space-y-2 divide-y text-base font-medium">
                    <div class="flex flex-wrap gap-2">
                        <span>Rule of</span>
                        <span v-if="editingRule.coverage === 'all'" class="text-orange-400">All Spaces</span>
                        <span>with intervals</span>
                        <span v-if="editingRule.type === 'hourly'" class="text-green-400">shorter than a day</span>
                        <span v-if="editingRule.type === 'daily'" class="text-green-400">shorter than a month</span>
                        <span v-if="editingRule.type === 'monthly'" class="text-green-400">longer than a month</span>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <span>Base Price</span>
                        <div class="flex items-center space-x-2 text-xl">
                            <span class="text-parqay-primary font-semibold">
                                {{ $formatCurrency(editingRule.price) }}
                            </span>
                            <span class="text-parqay-primary">{{ startCase(editingRule.type) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <table class="w-2/3">
                <tr class="border-b text-gray-600">
                    <th class="text-center" colspan="4">
                        <div class="flex flex-col items-center px-4 py-4 space-y-2">
                            <span class="text-base font-semibold">Pricing by Spaces Availability</span>
                            <span class="text-sm font-light">Price decreases / increases based on remaining
                                spaces</span>
                        </div>
                    </th>
                </tr>
                <tr class="border-b border-l border-r text-gray-600 bg-gray-50">
                    <th class="text-center text-sm font-medium py-2">
                        Tier
                    </th>
                    <th class="text-center text-sm font-medium">Discount</th>
                    <th class="text-center text-sm font-medium" width="120">Price</th>
                    <th />
                </tr>
                <tr height="18" />
                <tr v-for="(adjustment, j) in editingTier.adjustments.filter(adjustment => adjustment.type === 'volume')"
                    :key="`tier-adjustment-${j}-volume`" class="text-sm text-gray-500">
                    <td class="">
                        <div class="flex items-center space-x-2">
                            <a-tag color="geekblue">
                                {{ j + 1 }}
                            </a-tag>
                            <span v-if="j == 0">
                                First group of
                            </span>
                            <span v-else>
                                Next group of
                            </span>
                            <a-input-number v-model="adjustment.tier" :min="1" style="width: 50px;" @change="(e) => {
        console.log('pricing rule update -> ', e);
        commitAdjustmentsUpdate();
    }" />
                            <span>spaces</span>
                        </div>
                    </td>
                    <td class="flex items-center space-x-2 p-2">
                        <a-dropdown>
                            <a-button v-if="adjustment.sign === '-'" minus type="primary" icon="minus" />
                            <a-button v-if="adjustment.sign === '+'" plus type="primary" icon="plus" />
                            <a-menu slot="overlay">
                                <a-menu-item @click="() => {
        adjustment.sign = '-';
        commitAdjustmentsUpdate();
    }">
                                    <span class="text-sm">
                                        - Discount
                                    </span>
                                </a-menu-item>
                                <a-menu-item @click="() => {
        adjustment.sign = '+';
        commitAdjustmentsUpdate();
    }">
                                    <span class="text-sm">
                                        + Surcharge
                                    </span>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <a-input-number v-model="adjustment.adjustment" :min="0" style="width: 80px;" @change="() => {
        commitAdjustmentsUpdate();
    }" />
                    </td>
                    <td class="text-center">
                        <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(editingRule.price
        +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} {{ editingRule.type }}</span>
                        <span v-if="adjustment.sign === '-'" class="text-orange-400">{{
        $formatCurrency(editingRule.price +
            applySign(adjustment.sign,
                adjustment.adjustment)) }} {{ editingRule.type }}</span>
                    </td>
                    <td class="text-center py-2 px-1">
                        <a-button @click="removeAdjustment(editingTier, adjustment, 'volume')">
                            <svg-icon icon-class="trash" class="w-4 h-4 text-parqay-primary" />
                        </a-button>
                    </td>
                </tr>
                <tr>
                    <th class="text-center" colspan="4">
                        <div class="flex flex-col pt-4 space-y-4">
                            <button
                                class="w-full px-4 py-2 rounded border hover:bg-blue-50 border-parqay-primary font-semibold text-parqay-primary transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                                type="button" @click="() => {
        generatePricingAdjustments(editingTier, 'volume');
    }">
                                + New Tier
                            </button>
                        </div>
                    </th>
                </tr>

                <tr class="border-b text-gray-600">
                    <th class="text-center" colspan="4">
                        <div class="flex flex-col items-center px-4 py-4 space-y-2">
                            <span class="text-base font-semibold">Pricing by Space Size</span>
                            <span class="text-sm font-light">Price decreases / increases based on size of space /
                                vehicle</span>
                        </div>
                    </th>
                </tr>
                <tr class="border-b border-l border-r text-gray-600 bg-gray-50">
                    <th class="text-center text-sm font-medium py-2">
                        Tier
                    </th>
                    <th class="text-center text-sm font-medium">Discount</th>
                    <th class="text-center text-sm font-medium" width="120">Price</th>
                    <th />
                </tr>
                <tr height="18" />
                <tr v-for="(adjustment, j) in editingTier.adjustments.filter(adjustment => adjustment.type === 'size')"
                    :key="`tier-adjustment-${j}-size`" class="text-sm text-gray-500">
                    <td class="">
                        <div class="flex items-center space-x-2">
                            <a-tag color="geekblue">
                                {{ j + 1 }}
                            </a-tag>
                            <span>
                                Size:
                            </span>
                            <a-input v-model="adjustment.tier" allow-clear style="width: 150px;" @change="() => {
        commitAdjustmentsUpdate();
    }" />
                        </div>
                    </td>
                    <td class="flex items-center space-x-2 p-2">
                        <a-dropdown>
                            <a-button v-if="adjustment.sign === '-'" minus type="primary" icon="minus" />
                            <a-button v-if="adjustment.sign === '+'" plus type="primary" icon="plus" />
                            <a-menu slot="overlay">
                                <a-menu-item @click="() => {
        adjustment.sign = '-';
        commitAdjustmentsUpdate();
    }">
                                    <span class="text-sm">
                                        - Discount
                                    </span>
                                </a-menu-item>
                                <a-menu-item @click="() => {
        adjustment.sign = '+';
        commitAdjustmentsUpdate();
    }">
                                    <span class="text-sm">
                                        + Surcharge
                                    </span>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <a-input-number v-model="adjustment.adjustment" :min="0" style="width: 80px;" @change="() => {
        commitAdjustmentsUpdate();
    }" />
                    </td>
                    <td class="text-center">
                        <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(editingRule.price
        +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} {{ editingRule.type }}</span>
                        <span v-if="adjustment.sign === '-'" class="text-orange-400">{{
        $formatCurrency(editingRule.price +
            applySign(adjustment.sign,
                adjustment.adjustment)) }} {{ editingRule.type }}</span>
                    </td>
                    <td class="text-center py-2 px-1">
                        <a-button @click="removeAdjustment(editingTier, adjustment, 'size')">
                            <svg-icon icon-class="trash" class="w-4 h-4 text-parqay-primary" />
                        </a-button>
                    </td>
                </tr>
                <tr>
                    <th class="text-center" colspan="4">
                        <div class="flex flex-col pt-4 space-y-4">
                            <button
                                class="w-full px-4 py-2 rounded border hover:bg-blue-50 border-parqay-primary font-semibold text-parqay-primary transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                                type="button" @click="() => {
        generatePricingAdjustments(editingTier, 'size');
    }">
                                + New Tier
                            </button>
                        </div>
                    </th>
                </tr>

                <tr class="border-b text-gray-600">
                    <th class="text-center" colspan="4">
                        <div class="flex flex-col items-center px-4 py-4 space-y-2">
                            <span class="text-base font-semibold">Early Bird Pricing</span>
                            <span class="text-sm font-light">Price discounted before a set date</span>
                        </div>
                    </th>
                </tr>
                <tr class="border-b border-l border-r text-gray-600 bg-gray-50">
                    <th class="text-center text-sm font-medium py-2">
                        Before
                    </th>
                    <th class="text-center text-sm font-medium">Discount</th>
                    <th class="text-center text-sm font-medium" width="120">Price</th>
                    <th />
                </tr>
                <tr height="18" />
                <tr v-for="(adjustment, j) in editingTier.adjustments.filter(adjustment => adjustment.type === 'time')"
                    :key="`tier-adjustment-${j}-time`" class="text-sm text-gray-500">
                    <td class="">
                        <div class="flex items-center space-x-2">
                            <a-tag color="geekblue">
                                {{ j + 1 }}
                            </a-tag>
                            <a-date-picker early-bird :default-value="dayjs(adjustment.tier)"
                                :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD h:mm A" @change="(e) => {
        console.log(e);
        const earlyBirdDate = e.format('YYYY-MM-DDTHH:mm');
        console.log('earlyBirdDate -> ', earlyBirdDate);
        adjustment.tier = earlyBirdDate;
        commitAdjustmentsUpdate();
    }" />
                        </div>
                    </td>
                    <td class="flex items-center space-x-2 p-2">
                        <a-dropdown>
                            <a-button v-if="adjustment.sign === '-'" minus type="primary" icon="minus" />
                            <a-button v-if="adjustment.sign === '+'" plus type="primary" icon="plus" />
                            <a-menu slot="overlay">
                                <a-menu-item @click="() => {
        adjustment.sign = '-';
        commitAdjustmentsUpdate()
    }">
                                    <span class="text-sm">
                                        - Discount
                                    </span>
                                </a-menu-item>
                                <a-menu-item @click="() => {
        adjustment.sign = '+';
        commitAdjustmentsUpdate();
    }">
                                    <span class="text-sm">
                                        + Surcharge
                                    </span>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <a-input-number v-model="adjustment.adjustment" :min="0" style="width: 80px;" @change="() => {
        commitAdjustmentsUpdate()
    }" />
                    </td>
                    <td class="text-center">
                        <span v-if="adjustment.sign === '+'" class="text-green-400">{{ $formatCurrency(editingRule.price
        +
        applySign(adjustment.sign,
            adjustment.adjustment)) }} {{ editingRule.type }}</span>
                        <span v-if="adjustment.sign === '-'" class="text-orange-400">{{
        $formatCurrency(editingRule.price +
            applySign(adjustment.sign,
                adjustment.adjustment)) }} {{ editingRule.type }}</span>
                    </td>
                    <td class="text-center py-2 px-1">
                        <a-button @click="removeAdjustment(editingTier, adjustment, 'time')">
                            <svg-icon icon-class="trash" class="w-4 h-4 text-parqay-primary" />
                        </a-button>
                    </td>
                </tr>
                <tr v-if="editingTier.adjustments.filter(adjustment => adjustment.type === 'time') == 0">
                    <th class="text-center" colspan="4">
                        <div class="flex flex-col pt-4 space-y-4">
                            <button
                                class="w-full px-4 py-2 rounded border hover:bg-blue-50 border-parqay-primary font-semibold text-parqay-primary transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                                type="button" @click="() => {
        generatePricingAdjustments(editingTier, 'time');
    }">
                                + New Tier
                            </button>
                        </div>
                    </th>
                </tr>
            </table>
        </div>

    </div>
    <a-table v-else :loading="loading.table" :data-source="form.rates.rules" row-key="id" :pagination="false"
        style="width: 100%;">
        <a-table-column title="Priority" data-index="priority">
            <template slot-scope="text, record, index">
                <div class="flex space-x-2 justify-center items-center">
                    <a-button icon="up" size="small" disabled></a-button>
                    <span class="font-bold" :class="{
        'text-red-400': index > 2,
        'text-gray-500': index <= 2,
    }">{{ index + 1 }}</span>
                    <a-button icon="down" size="small" disabled></a-button>
                </div>
            </template>
        </a-table-column>
        <!-- <a-table-column title="Coverage" data-index="coverage">
            <template slot-scope="text">
              <div class="flex">
                <span v-if="text.toLowerCase() == 'all'" class="font-medium text-blue-500">All Spaces</span>
                <span v-else>{{ text }}</span>
              </div>
            </template>
          </a-table-column> -->
        <a-table-column title="Rule" data-index="type">
            <template slot-scope="type, record">
                <div v-if="record.intervals === 'all' && record.pricing_rule.type === 'constant'"
                    class="flex flex-wrap items-center gap-2">
                    <a-tag color="geekblue">{{ startCase(type) }} Rate</a-tag>
                    <span>for</span>
                    <a-tag v-if="record.coverage === 'all'" color="orange">All Spaces</a-tag>
                    <span>with intervals</span>
                    <a-tag v-if="type === 'hourly'" color="green">shorter than a day</a-tag>
                    <a-tag v-if="type === 'daily'" color="green">shorter than a month</a-tag>
                    <a-tag v-if="type === 'monthly'" color="green">longer than a month</a-tag>
                </div>
            </template>
        </a-table-column>
        <!-- <a-table-column title="Dates" data-index="intervals">
            <template slot-scope="intervals, record">
              <div v-if="$deepGet(intervals, '[0].start')" class="flex flex-col space-y-2">
                <a-tag color="geekblue">{{ $formatDate(intervals[0].start) }}</a-tag>
                <a-tag color="geekblue">{{ $formatDate(intervals[0].end) }}</a-tag>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="Type" data-index="pricing_rule">
            <template slot-scope="pricing_rule">
              <div class="flex flex-col space-y-2">
                <a-tag color="geekblue">{{ pricing_rule.type_name }}</a-tag>
                <a-tag v-for="(t, i) in pricing_rule.description" :key="`rule-type-${i}`" color="geekblue">{{ t
                  }}</a-tag>
              </div>
            </template>
          </a-table-column> -->
        <!-- <a-table-column key="usageInsights" title="Usage & Insights" data-index="usage_and_insights">
            <template slot-scope="text">
              <div class="flex flex-col">
                <span class="text-xs whitespace-nowrap">Used: {{ text.used }} times</span>
                <span class="text-xs whitespace-nowrap">Sold: {{ text.sold }} spaces</span>
                <span class="text-xs font-bold text-dark-purple whitespace-nowrap">since
                  {{ text.last_updated }}
                </span>
              </div>
            </template>
          </a-table-column> -->
        <a-table-column key="price" title="Rate" data-index="price" align="center">
            <template slot-scope="price, it, index">
                <a-popover v-model="it.priceEditorVisible" trigger="click" @visibleChange="(v) => {
        if (v) {
            updatedPrice = it.price;
        }
    }">
                    <template slot="content">
                        <div class="flex items-center space-x-2">
                            <a-input-number v-model="updatedPrice" :min="0" :formatter="(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        " :parser="(value) => value.replace(/\$\s?|(,*)/g, '')" v-on:keyup="e => {
        if (e.keyCode === 13) {
            savePrice(index, updatedPrice)
        }
    }" />

                            <a-button type="primary" size="small" @click="savePrice(index, updatedPrice)">
                                Save
                            </a-button>
                            <a-button size="small" @click.stop="it.priceEditorVisible = !it.priceEditorVisible">
                                Cancel
                            </a-button>
                        </div>
                    </template>
                    <div class="border rounded px-3 py-0.5 hover:border-parqay-primary">
                        <span class="text-base text-parqay-primary">{{ $formatCurrency(it.price) }}</span>
                    </div>
                </a-popover>
            </template>
        </a-table-column>
        <a-table-column key="is_active" title="Active" align="center">
            <template slot-scope="text, record, index">
                <a-switch v-model="record.is_active" @change="updateIsActive(index, record.is_active)"></a-switch>
            </template>
        </a-table-column>
        <a-table-column key="action" title="Tier Pricing" align="center">
            <template slot-scope="text, record, index">
                <div v-if="tiers.find(tier => tier.id === record.id) && tiers.find(tier => tier.id === record.id).adjustments.length > 0"
                    class="flex flex-wrap items-center pl-4 gap-y-2" style="max-width: 230px;">
                    <a-tag v-if="tiers.find(tier => tier.id === record.id).adjustments.find(adj => adj.type === 'time')"
                        color="green">Early Bird</a-tag>
                    <a-tag
                        v-if="tiers.find(tier => tier.id === record.id).adjustments.find(adj => adj.type === 'volume')"
                        color="geekblue">Volume</a-tag>
                    <a-tag v-if="tiers.find(tier => tier.id === record.id).adjustments.find(adj => adj.type === 'size')"
                        color="geekblue">Size</a-tag>
                    <a class="w-full flex items-center space-x-2" @click.stop="editTiers(record)">
                        <div class="w-2 h-2 rounded-full bg-green-400" />
                        <span class="text-xs font-semibold text-green-400">{{ tiers.find(tier => tier.id ===
                            record.id).adjustments.length }} Tiers</span>
                        <a-icon type="edit" />
                    </a>
                </div>
                <a-button v-else action type="primary" icon="plus" @click.stop="editTiers(record)">Tier Rule</a-button>
            </template>
        </a-table-column>
    </a-table>
</template>
<style lang="scss" scoped>
.ant-btn[action] {
    @apply flex items-center justify-center space-x-2;
}

.ant-btn[minus] {
    @apply flex items-center justify-center bg-red-400 border-red-500;
}

.ant-btn[plus] {
    @apply flex items-center justify-center bg-green-400 border-green-400;
}

.ant-calendar-picker[early-bird] {
    min-width: 220px !important;
}
</style>