<script>
import { reactive } from "@vue/composition-api";
export default {
  props: {
    confirmationId: String
  },
  setup(props, ctx) {
    const controls = reactive({
      showFull: false
    });
    return {
      controls
    };
  }
};
</script>
<template>
  <a-tooltip>
    <template v-if="!controls.showFull" #title>
      <span>Click to copy</span>
    </template>
    <span
      id="confirmation-tag"
      class="text-parqay-primary text-xs px-2 py-1 bg-white border border-parqay-primary rounded-full cursor-pointer hover:shadow-lg"
      @click.stop="
        () => {
          if (!controls.showFull) {
            $copyToClipboard(confirmationId);
            $openNotification(
              'Confirmation number copied to clipboard',
              '',
              5,
              'info'
            );
          }
          controls.showFull = !controls.showFull;
        }
      "
      >{{
        controls.showFull ? confirmationId : confirmationId.substring(0, 6)
      }}</span
    >
  </a-tooltip>
</template>
