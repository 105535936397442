var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"heroEffect",staticClass:"h-full w-full",attrs:{"data-relative-input":"true","data-limit-x":"30","data-limit-y":"30"}},[_vm._m(0),_c('div',{staticClass:"layer absolute",style:({
      left: _vm.parkingFloatArmFramePosition.left,
      top: _vm.parkingFloatArmFramePosition.top,
      height: _vm.parkingFloatArmFramePosition.height,
      width: _vm.parkingFloatArmFramePosition.width,
    }),attrs:{"data-depth":"0.5"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",class:_vm.parkingFloatArmClass +
          ' ' +
          _vm.parkingFloatArmClassSM +
          ' ' +
          _vm.parkingFloatArmClassXL,on:{"mouseenter":function($event){return _vm.toggleArmsAnimations('parking', true)},"mouseleave":function($event){return _vm.toggleArmsAnimations('parking', false)}}},[_c('svg-icon',{staticClass:"w-full h-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",class:{
          'animate-pulse': !_vm.armsAnimations.parking,
          'animate-none': _vm.armsAnimations.parking,
        },attrs:{"icon-class":"rent-parking-float-arm"}})],1)]),_c('div',{staticClass:"layer absolute",style:({
      left: _vm.houseFloatArmFramePosition.left,
      top: _vm.houseFloatArmFramePosition.top,
      height: _vm.houseFloatArmFramePosition.height,
      width: _vm.houseFloatArmFramePosition.width,
    }),attrs:{"data-depth":"0.5"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",class:_vm.houseFloatArmClass +
          ' ' +
          _vm.houseFloatArmClassSM +
          ' ' +
          _vm.houseFloatArmClassXL,on:{"mouseenter":function($event){return _vm.toggleArmsAnimations('house', true)},"mouseleave":function($event){return _vm.toggleArmsAnimations('house', false)}}},[_c('svg-icon',{staticClass:"w-full h-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",class:{
          'animate-pulse': !_vm.armsAnimations.house,
          'animate-none': _vm.armsAnimations.house,
        },attrs:{"icon-class":"rent-house-float-arm"}})],1)]),_c('div',{staticClass:"layer absolute",style:({
      left: _vm.officeFloatArmFramePosition.left,
      top: _vm.officeFloatArmFramePosition.top,
      height: _vm.officeFloatArmFramePosition.height,
      width: _vm.officeFloatArmFramePosition.width,
    }),attrs:{"data-depth":"0.5"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",class:_vm.officeFloatArmClass +
          ' ' +
          _vm.officeFloatArmClassSM +
          ' ' +
          _vm.officeFloatArmClassXL,on:{"mouseenter":function($event){return _vm.toggleArmsAnimations('office', true)},"mouseleave":function($event){return _vm.toggleArmsAnimations('office', false)}}},[_c('svg-icon',{staticClass:"w-full h-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",class:{
          'animate-pulse': !_vm.armsAnimations.office,
          'animate-none': _vm.armsAnimations.office,
        },attrs:{"icon-class":"rent-office-float-arm"}})],1)]),_c('div',{staticClass:"layer absolute",style:({
      left: _vm.carImageFramePosition.left,
      top: _vm.carImageFramePosition.top,
      height: _vm.carImageFramePosition.height,
      width: _vm.carImageFramePosition.width,
    }),attrs:{"data-depth":"0.36"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",class:_vm.carImageClass + ' ' + _vm.carImageClassSM + ' ' + _vm.carImageClassXL},[_c('img',{staticClass:"w-full h-full object-contain",attrs:{"src":"/static/img/dashboard/car-float-arm.png"}})])]),_c('div',{staticClass:"layer absolute",style:({
      left: _vm.carFloatArmFramePosition.left,
      top: _vm.carFloatArmFramePosition.top,
      height: _vm.carFloatArmFramePosition.height,
      width: _vm.carFloatArmFramePosition.width,
    }),attrs:{"data-depth":"0.5"}},[_c('div',{staticClass:"absolute cursor-pointer pointer-events-auto",class:_vm.carFloatArmClass + ' ' + _vm.carFloatArmClassSM + ' ' + _vm.carFloatArmClassXL,on:{"mouseenter":function($event){return _vm.toggleArmsAnimations('car', true)},"mouseleave":function($event){return _vm.toggleArmsAnimations('car', false)}}},[_c('svg-icon',{staticClass:"w-full h-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",class:{
          'animate-pulse': !_vm.armsAnimations.car,
          'animate-none': _vm.armsAnimations.car,
        },attrs:{"icon-class":"lease-car-float-arm"}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layer absolute",staticStyle:{"left":"-30px","top":"-30px","height":"105%","width":"110%"},attrs:{"data-depth":"0.4"}},[_c('img',{staticClass:"absolute inset-0 pr-12 h-full w-full object-contain",attrs:{"src":"/static/img/dashboard/db-login-background.png","alt":""}})])}]

export { render, staticRenderFns }