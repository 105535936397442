<script>
import { ref } from "@vue/composition-api";
export default {
  props: {
    brand: {
      type: String,
      required: true,
    },
    last4: {
      type: String,
      required: true,
    },
  },
  setup() {
    const form = ref([
      {
        brand: "visa",
        icon: "card-brand-visa",
        class: "object-cover",
      },
      {
        brand: "amex",
        icon: "card-brand-amex",
        class: "object-cover",
      },
      {
        brand: "discover",
        icon: "card-brand-discover",
        class: "object-cover",
      },
      {
        brand: "mastercard",
        icon: "card-brand-mastercard",
        class: "object-cover",
      },
    ]);
    return {
      form,
    };
  },
};
</script>
<template>
  <a-tag class="items-center space-x-2" :style="{ display: 'flex' }">
    <svg-icon
      v-if="form.map((v) => v.brand).includes(brand)"
      :src="form.find((v) => v.brand === brand).icon"
      class="h-8 w-12"
      :class="form.find((v) => v.brand === brand).class"
    />
    <span v-else>
      {{ brand }}
    </span>
    <span class="text-base font-medium">
      ends in
      <a class="text-black">{{ last4 }}</a>
    </span>
  </a-tag>
</template>
