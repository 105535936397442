export const data = {
  name: "Timekit API",
  anchor: "timekit",
  template: "endpoint",
  ready: false,
  sub: [
    {
      name: "Search Availability",
      anchor: "search-availability",
      description:
        "Tell whether making a book on a time interval is possible with time constraints. (Super Fast)",
      template: "endpoint",
      ready: true,
      endpoint: "GET /api/timekit/search/:interval_mode",
      main: `
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc felis justo, accumsan sit amet turpis et, fermentum posuere lectus. Maecenas vitae ante in tellus auctor aliquet non id nisl. Donec at lacinia lorem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer scelerisque tellus sed finibus varius. In semper sed ipsum nec iaculis. Ut accumsan dolor at diam facilisis, eget imperdiet ex finibus. Aliquam eget orci nulla. In dignissim, velit sed mattis malesuada, urna quam molestie erat, at tristique lectus lacus ut dolor. Suspendisse potenti. Ut elementum cursus egestas. Morbi ultricies lobortis congue. Nullam pellentesque, dui vitae ullamcorper gravida, sem augue fermentum velit, id bibendum nisl arcu eu mauris. Proin rutrum, enim fermentum venenatis consequat, urna nunc dictum lorem, quis vehicula dui sapien at ipsum.

      Praesent interdum arcu a ipsum auctor gravida. Curabitur ullamcorper massa vitae dui elementum rutrum. Etiam egestas sapien at neque interdum, elementum condimentum leo ultrices. In efficitur at mauris bibendum congue. Vestibulum nunc sem, congue vel sollicitudin eget, vulputate et lacus. Interdum et malesuada fames ac ante ipsum primis in faucibus. In in purus a justo vestibulum pellentesque. Donec porttitor at tortor nec convallis. Vivamus id lacinia leo, id facilisis mi. Donec feugiat condimentum augue. In hac habitasse platea dictumst.
      
      Proin vel volutpat neque. Nunc lobortis, nisl et mollis interdum, dolor ante scelerisque purus, nec iaculis justo elit et ligula. Nam eget leo in orci rhoncus dictum. Integer pharetra, sapien in viverra condimentum, ex sapien vestibulum leo, eget ultricies magna metus id purus. Suspendisse faucibus, mauris vitae laoreet pellentesque, magna mauris vehicula risus, et lobortis orci odio at orci. Quisque porta nisl quis enim luctus dapibus. Suspendisse potenti. Ut id magna nec mi varius sagittis. Integer venenatis rutrum mi eget vulputate. Vivamus sit amet velit vestibulum, volutpat sapien non, sagittis justo. Vestibulum bibendum, augue a condimentum pellentesque, leo risus hendrerit enim, at ultrices mauris libero eu sapien. Fusce risus nibh, malesuada sit amet vestibulum sed, blandit ullamcorper velit. Vivamus non metus ipsum.
          `,
      parameters: [
        {
          title: "Request Params",
          layout: "grid-cols-4",
          form: [
            [
              {
                class: "",
                items: [
                  {
                    text: "start_time",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: "Start date in RFC3339 format",
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: `2021-01-08T10:00:00-07:00`,
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  {
                    text: "end_time",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: "End date in RFC3339 format",
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: `2021-02-11T05:00:00-07:00`,
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "booked_intervals",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "Array of Objects",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `The occupied time intervals from previous booking. The "meta" value can serve as a store for metadata`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: [
                      {
                        from: "2021-01-01T02:00:00-07:00",
                        to: "2021-01-05T04:00:00-07:00",
                        meta: null,
                      },
                    ],
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "interval_mode",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `One of "hourly", "daily", "monthly". This param defines the resolution of the search.`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: `hourly`,
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "availability_meta",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "Object",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `Represent the time constraints. 
                    "Simple" mode defines open and close time on all weekdays, weekends and holidys.
                    "Manual" mode defines open and close time for each individual weekdays, 
                    where muitiple intervals of open and close times on same weekday may apply.`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: {
                      mode: "simple",
                      manual: [
                        {
                          day: "sunday",
                          from: "00:00AM",
                          to: "01:00PM",
                          active: true,
                        },
                        {
                          day: "sunday",
                          from: "03:00PM",
                          to: "11:59PM",
                          active: true,
                        },
                        {
                          day: "monday",
                          from: "00:00AM",
                          to: "11:59PM",
                          active: false,
                        },
                        {
                          day: "tuesday",
                          from: "00:00AM",
                          to: "11:59PM",
                          active: false,
                        },
                        {
                          day: "thursday",
                          from: "00:00AM",
                          to: "11:59PM",
                          active: false,
                        },
                        {
                          day: "wednesday",
                          from: "00:00AM",
                          to: "11:59PM",
                          active: false,
                        },
                      ],
                      simple: {
                        weekdays: {
                          from: "00:00AM",
                          to: "11:59PM",
                        },
                        weekends: {
                          from: "00:00AM",
                          to: "11:59PM",
                        },
                        holidays: {
                          from: "10:00AM",
                          to: "11:59PM",
                          include: [
                            {
                              id: "example_holiday_id_1",
                              name: "New Year's Day",
                              date: {
                                formatted: "Wednesday, January 1",
                                original: "2021-01-08T00:00:00-07:00",
                              },
                              checked: true,
                            },
                            {
                              id: "example_holiday_id_2",
                              name: "Birthday of Martin Luther King, Jr",
                              date: {
                                formatted: "Monday, January 20",
                                original: "2020-01-20T00:00:00-07:00",
                              },
                              checked: false,
                            },
                          ],
                        },
                      },
                    },
                  },
                ],
              },
            ],
          ],
        },
        {
          title: "Response",
          layout: "grid-cols-4",
          form: [
            [
              {
                class: "",
                items: [
                  {
                    text: "data.available",
                    class: "font-medium text-sm",
                  },
                  {
                    tag: "Boolean",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: "Define whether querying time interval is available",
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: { data: { available: true } },
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "message",
                    class: "font-medium text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `Message from the API`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: {
                      message: "Availability info is returned",
                    },
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "status",
                    class: "font-medium text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `The result of the API request. Possible values are "success" or "error"`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: {
                      status: "success",
                    },
                  },
                ],
              },
            ],
          ],
        },
      ],
      sourcecode: [
        {
          code: `
POST /task?id=1 HTTP/1.1
Host: example.org
Content-Type: application/json; charset=utf-8
Content-Length: 137

{
  "status": "ok",
  "extended": true,
  "results": [
    {"value": 0, "type": "int64"},
    {"value": 1.0e+3, "type": "decimal"}
  ]
}
          `,
          lang: "http",
        },
        {
          code: `
import requests 
"""
Python requests package is required to install 
in the first place!
"""

resp = requests.get(
  "https://{{PARQAY_API_DOMAIN}}/api/timekit/search/hourly", 
  json={
    "start_time": "2021-01-08T10:00:00-07:00",
    "end_time": "2021-02-11T05:00:00-07:00",
    "booked_intervals": [
      {
          "from": "2021-01-01T02:00:00-07:00",
          "to": "2021-01-05T04:00:00-07:00",
          "meta": None
      }
    ],
    "availability_meta": {
      "mode": "simple",
      "manual": [
        {
          "day": "sunday",
          "from": "00:00AM",
          "to": "01:00PM",
          "active": True
        },
        {
          "day": "sunday",
          "from": "03:00PM",
          "to": "11:59PM",
          "active": True
        },
        {
          "day": "monday",
          "from": "00:00AM",
          "to": "11:59PM",
          "active": False
        },
        {
          "day": "tuesday",
          "from": "00:00AM",
          "to": "11:59PM",
          "active": False
        },
        {
          "day": "thursday",
          "from": "00:00AM",
          "to": "11:59PM",
          "active": False
        },
        {
          "day": "wednesday",
          "from": "00:00AM",
          "to": "11:59PM",
          "active": False
        }
      ],
      "simple": {
        "weekdays": {
          "from": "00:00AM",
          "to": "11:59PM"
        },
        "weekends": {
          "from": "00:00AM",
          "to": "11:59PM"
        },
        "holidays": {
          "from": "10:00AM",
          "to": "11:59PM",
          "include": [
            {
              "id": "example_holiday_id_1",
              "name": "New Year's Day",
              "date": {
                "formatted": "Wednesday, January 1",
                "original": "2021-01-08T00:00:00-07:00"
              },
              "checked": True
            },
            {
              "id": "example_holiday_id_2",
              "name": "Birthday of Martin Luther King, Jr",
              "date": {
                  "formatted": "Monday, January 20",
                  "original": "2020-01-20T00:00:00-07:00"
              },
              "checked": False
            }
          ]
        }
      }
    }
})

print(f'result: {resp.json()}')
          `,
          lang: "python",
        },
        {
          code: `
const Stripe = require('stripe');
const stripe = Stripe('sk_test_4eC39HqLyjWDarjtT1zdp7dc');
stripe.charges.create({
  amount: 2000,
  currency: "usd",
  source: "tok_amex", // obtained with Stripe.js
  metadata: {'order_id': '6735'}
});
          `,
          lang: "javascript",
        },
        {
          code: `
const Stripe = require('stripe');
const stripe = Stripe('sk_test_4eC39HqLyjWDarjtT1zdp7dc');
stripe.charges.create({
  amount: 2000,
  currency: "usd",
  source: "tok_amex", // obtained with Stripe.js
  metadata: {'order_id': '6735'}
});
          `,
          lang: "node.js",
        },
      ],
      response: {
        data: {
          available: true,
        },
        message: "Availability info is returned",
        status: "success",
      },
    },
    {
      name: "Suggest Availability",
      anchor: "suggest-availability",
      description: `Calculate the best fit available time intervals 
      by providing a querying interval with start and end times and time constraints. (Normal Fast)`,
      template: "endpoint",
      ready: true,
      endpoint: "GET /api/timekit/suggest/:interval_mode",
      main: `
      Donec vehicula dapibus lacinia. In eget elit in quam tempor lobortis. Fusce sit amet lectus sem. Aliquam sapien diam, porttitor ac diam nec, aliquam faucibus neque. Vivamus at congue metus. Vivamus porttitor feugiat eros ut tempus. Maecenas ullamcorper sed tellus in mollis. Cras sagittis nulla ante, vitae aliquam odio varius at. Curabitur pharetra felis vel nibh commodo semper. Phasellus risus arcu, rhoncus quis quam non, euismod pharetra quam. Nunc vehicula risus in dui hendrerit condimentum. Mauris eu lacinia orci. Nullam eu aliquam nunc, dignissim luctus ligula. Fusce dui enim, facilisis sagittis purus fringilla, porttitor faucibus quam. Sed sit amet sapien non tortor vehicula pellentesque nec vel augue. Morbi suscipit enim quis orci interdum laoreet.

      Ut laoreet dui nunc, sed hendrerit orci vehicula et. Nullam et neque diam. In porta condimentum nisi viverra mattis. Ut vel sapien vulputate, lobortis elit eget, dictum lectus. Etiam quis dignissim augue, et ullamcorper ante. Nam at lorem malesuada, pretium urna quis, lacinia enim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi suscipit orci a leo dictum, ac aliquet purus laoreet. Praesent imperdiet lectus dictum, mollis purus id, molestie felis.
          `,
      parameters: [
        {
          title: "Request Params",
          layout: "grid-cols-4",
          form: [
            [
              {
                class: "",
                items: [
                  {
                    text: "start_time",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: "Start date in RFC3339 format",
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: `2021-01-08T10:00:00-07:00`,
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  {
                    text: "end_time",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: "End date in RFC3339 format",
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: `2021-02-11T05:00:00-07:00`,
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "booked_intervals",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "Array of Objects",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `The occupied time intervals from previous booking. The "meta" value can serve as a store for metadata`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: [
                      {
                        from: "2021-01-01T02:00:00-07:00",
                        to: "2021-01-05T04:00:00-07:00",
                        meta: null,
                      },
                    ],
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "interval_mode",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `One of "hourly", "daily", "monthly". This param defines the resolution of the search.`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: `hourly`,
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "availability_meta",
                    class: "font-medium text-sm",
                  },
                  {
                    text: "Required",
                    class: "font-bold text-parqay-primary text-sm",
                  },
                  {
                    tag: "Object",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `Represent the time constraints. 
                        "Simple" mode defines open and close time on all weekdays, weekends and holidys.
                        "Manual" mode defines open and close time for each individual weekdays, 
                        where muitiple intervals of open and close times on same weekday may apply.`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: {
                      mode: "simple",
                      manual: [
                        {
                          day: "sunday",
                          from: "00:00AM",
                          to: "01:00PM",
                          active: true,
                        },
                        {
                          day: "sunday",
                          from: "03:00PM",
                          to: "11:59PM",
                          active: true,
                        },
                        {
                          day: "monday",
                          from: "00:00AM",
                          to: "11:59PM",
                          active: false,
                        },
                        {
                          day: "tuesday",
                          from: "00:00AM",
                          to: "11:59PM",
                          active: false,
                        },
                        {
                          day: "thursday",
                          from: "00:00AM",
                          to: "11:59PM",
                          active: false,
                        },
                        {
                          day: "wednesday",
                          from: "00:00AM",
                          to: "11:59PM",
                          active: false,
                        },
                      ],
                      simple: {
                        weekdays: {
                          from: "00:00AM",
                          to: "11:59PM",
                        },
                        weekends: {
                          from: "00:00AM",
                          to: "11:59PM",
                        },
                        holidays: {
                          from: "10:00AM",
                          to: "11:59PM",
                          include: [
                            {
                              id: "example_holiday_id_1",
                              name: "New Year's Day",
                              date: {
                                formatted: "Wednesday, January 1",
                                original: "2021-01-08T00:00:00-07:00",
                              },
                              checked: true,
                            },
                            {
                              id: "example_holiday_id_2",
                              name: "Birthday of Martin Luther King, Jr",
                              date: {
                                formatted: "Monday, January 20",
                                original: "2020-01-20T00:00:00-07:00",
                              },
                              checked: false,
                            },
                          ],
                        },
                      },
                    },
                  },
                ],
              },
            ],
          ],
        },
        {
          title: "Response",
          layout: "grid-cols-4",
          form: [
            [
              {
                class: "",
                items: [
                  {
                    text: "data",
                    class: "font-medium text-sm",
                  },
                  {
                    tag: "Array of Objects",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `
                        A list of available time intervals. The result can either be continuous or descrete.
                        Note these intervals must be within querying interval defined by a start and end time.
                        IMPORTANT: This result is returned slower than "Search Availability" API but will provide best
                        estimation to fit your schedule. Use this endpoint ONLY if you want to provide smooth and supportive
                        user experience when a user cannot find any perfectly matched results from "Search Availability" API.
                        `,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: {
                      data: [
                        {
                          end: "2021-01-01T01:59:00-07:00",
                          start: "2020-12-31T05:00:00-07:00",
                        },
                        {
                          end: "2021-01-07T23:59:00-07:00",
                          start: "2021-01-05T04:01:00-07:00",
                        },
                        {
                          end: "2022-05-05T10:00:00-07:00",
                          start: "2021-01-08T10:00:00-07:00",
                        },
                      ],
                      status: "success",
                    },
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "message",
                    class: "font-medium text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `Message from the API`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: {
                      message: "Availability info is returned",
                    },
                  },
                ],
              },
            ],
            [
              {
                class: "",
                items: [
                  // Each array in column represents vertically assigned flex-col
                  {
                    text: "status",
                    class: "font-medium text-sm",
                  },
                  {
                    tag: "String",
                    color: "geekblue",
                  },
                ],
              },
              {
                class: "",
                items: [
                  {
                    text: `The result of the API request. Possible values are "success" or "error"`,
                    class: "font-light text-sm text-gray-500",
                  },
                ],
              },
              {
                class: "col-span-2",
                items: [
                  {
                    code: {
                      status: "success",
                    },
                  },
                ],
              },
            ],
          ],
        },
      ],
      sourcecode: [
        {
          code: `
POST /task?id=1 HTTP/1.1
Host: example.org
Content-Type: application/json; charset=utf-8
Content-Length: 137

{
  "status": "ok",
  "extended": true,
  "results": [
    {"value": 0, "type": "int64"},
    {"value": 1.0e+3, "type": "decimal"}
  ]
}
          `,
          lang: "http",
        },
        {
          code: `
import requests 
"""
Python requests package is required to install 
in the first place!
"""

resp = requests.get(
  "https://{{PARQAY_API_DOMAIN}}/api/timekit/suggest/monthly", 
  json={
    "start_time": "2020-12-31T05:00:00-07:00",
    "end_time":"2022-05-05T10:00:00-07:00",
    "booked_intervals": [
      {
        "from": "2021-01-01T02:00:00-07:00",
        "to": "2021-01-05T04:00:00-07:00",
        "meta": null
      }
    ],
    "availability_meta": {
        "mode": "manual",
        "manual": [
          {
            "day": "sunday",
            "from": "00:00AM",
            "to": "01:00PM",
            "active": true
          },
          {
            "day": "sunday",
            "from": "03:00PM",
            "to": "11:59PM",
            "active": true
          },
          {
            "day": "monday",
            "from": "00:00AM",
            "to": "11:59PM",
            "active": false
          },
          {
            "day": "tuesday",
            "from": "00:00AM",
            "to": "11:59PM",
            "active": false
          },
          {
            "day": "thursday",
            "from": "00:00AM",
            "to": "11:59PM",
            "active": false
          },
          {
            "day": "wednesday",
            "from": "00:00AM",
            "to": "11:59PM",
            "active": false
          }
        ],
        "simple": {
          "weekdays": {
            "from": "00:00AM",
            "to": "11:59PM"
          },
          "weekends": {
            "from": "00:00AM",
            "to": "11:59PM"
          },
          "holidays": {
            "from": "10:00AM",
            "to": "11:59PM",
            "include": [
              {
                "id": "example_holiday_id_1",
                "name": "New Year's Day",
                "date": {
                  "formatted": "Wednesday, January 1",
                  "original": "2021-01-08T00:00:00-07:00"
                },
                "checked": true
              },
              {
                "id": "example_holiday_id_2",
                "name": "Birthday of Martin Luther King, Jr",
                "date": {
                  "formatted": "Monday, January 20",
                  "original": "2020-01-20T00:00:00-07:00"
                },
                "checked": false
              }
            ]
          }
        }
    }
})

print(f'result: {resp.json()}')
                    `,
          lang: "python",
        },
        {
          code: `
const Stripe = require('stripe');
const stripe = Stripe('sk_test_4eC39HqLyjWDarjtT1zdp7dc');
stripe.charges.create({
  amount: 2000,
  currency: "usd",
  source: "tok_amex", // obtained with Stripe.js
  metadata: {'order_id': '6735'}
});
          `,
          lang: "javascript",
        },
        {
          code: `
const Stripe = require('stripe');
const stripe = Stripe('sk_test_4eC39HqLyjWDarjtT1zdp7dc');
stripe.charges.create({
  amount: 2000,
  currency: "usd",
  source: "tok_amex", // obtained with Stripe.js
  metadata: {'order_id': '6735'}
});
          `,
          lang: "node.js",
        },
      ],
      response: {
        data: [
          {
            end: "2021-01-01T01:59:00-07:00",
            start: "2020-12-31T05:00:00-07:00",
          },
          {
            end: "2021-01-07T23:59:00-07:00",
            start: "2021-01-05T04:01:00-07:00",
          },
          {
            end: "2022-05-05T10:00:00-07:00",
            start: "2021-01-08T10:00:00-07:00",
          },
        ],
        status: "success",
      },
    },
  ],
};
