<script>
import { onMounted } from "@vue/composition-api";
import ValidationSectionHeader from "src/views/reservations/components/ValidationSectionHeader";
import InviteForm from "src/views/tenants/components/InviteForm.vue";
import VehiclesForm from "src/views/reservations/components/forms/Vehicles.vue";

export default {
  name: "TenantForm",
  components: {
    InviteForm,
    VehiclesForm,
    ValidationSectionHeader
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    controls: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const saveNewTenant = () => {
      const error = props.controls.newTenant.validate(props.controls.newTenant);
      if (error) {
        ctx.root.$openNotification(error, "", 5, "error");
        return;
      } else {
        props.form.tenant = props.controls.newTenant;
        props.controls.editModeTenant = false;
        props.controls.editMode = false;
      }
    };
    onMounted(() => {

    });
    return {
      saveNewTenant
    };
  },
};
</script>

<template>
  <div id="tenants" class="bg-white shadow sm:rounded-lg mx-8 pb-4">
    <div class="bg-white px-4 pt-3 pb-1 border-b border-gray-200 sm:px-6">
      <div class="flex justify-between items-center">
        <h3 class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap">
          Tenant Details
        </h3>
        <validation-section-header :validation-errors="controls.errors.filter((e) => e.type == 'tenant')" />
        <div>
          <button v-if="controls.editMode &&
            controls.showTenantInputSearch &&
            !controls.createMode
            "
            class="border border-parqay-primary bg-white text-parqay-primary hover:text-blue-800 px-2 py-1 text-xs rounded"
            @click="() => {
              controls.showTenantInputSearch = false;
              controls.editModeTenant = false;
            }
              ">
            Cancel
          </button>
          <button v-if="controls.editMode && !controls.showTenantInputSearch"
            class="bg-parqay-primary hover:bg-blue-500 px-4 py-1 text-xs text-white border border-parqay-primary rounded"
            @click="() => {
              controls.showTenantInputSearch = true;
              controls.showCreateNewTenant = true;
            }
              ">
            Edit
          </button>
        </div>
      </div>
    </div>
    <div v-if="controls.showTenantInputSearch"
      class="sm:grid sm:grid-cols-2 sm:gap-y-4 sm:items-start sm:border-gray-200 sm:pt-5 px-5">
      <tenant-search-input :key="`tenant-search-input-${controls.keys.tenantSearchInput}`" :default-tenant="form.tenant"
        @select="tenant => {
          controls.tenant = tenant;
        }" @select-confirmed="() => {
  controls.showTenantInputSearch = false;
  form.tenant = controls.tenant;
}" />
      <div class="flex items-center justify-center">
        <button v-if="controls.showCreateNewTenant"
          class="flex items-center justify-center space-x-2 border border-parqay-primary hover:border-indigo-800 px-4 py-2 rounded focus:outline-none"
          @click="() => {
            controls.editModeTenant = true;
            controls.showCreateNewTenant = false;
          }
            ">
          <span class="text-center font-semibold text-parqay-primary hover:text-blue-800">
            + Create New Tenant
          </span>
        </button>
      </div>
      <template v-if="controls.editModeTenant">
        <invite-form class="col-span-2" @refresh-tenants-list="() => (controls.keys.tenantSearchInput += 1)"
          @dismiss="() => (controls.editModeTenant = false)" />
      </template>
    </div>
    <div v-else class="sm:grid sm:grid-cols-2 sm:gap-2 sm:items-start sm:border-gray-200 sm:pt-5 px-5">
      <div class="grid grid-cols-3 gap-4">
        <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Full Name
        </p>
        <p class="mt-1 text-sm text-parqay-primary font-medium sm:mt-0 sm:col-span-2 pt-2">
          {{ $deepGet(form, "tenant.name") }}
        </p>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          City
        </p>
        <p class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
          {{
            $deepGet(form, "tenant.address")
            ? $parseAddress($deepGet(form, "tenant.address")).city
            : "N/A"
          }}
        </p>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Email
        </p>
        <p class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
          {{ $deepGet(form, "tenant.email", "N/A") }}
        </p>
      </div>
      <div>
        <a class="
            px-4
            py-1.5
            border border-transparent
            text-md
            font-base
            rounded-md
            shadow-sm
            text-white
            bg-parqay-primary
            hover:bg-blue-500
            focus:outline-none
          " @click.stop="$bus.$emit('toggle-email-editor', { tenant_id: form.tenant.id })">
          Send Email
        </a>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Phone Number
        </p>
        <p class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
          {{
            $deepGet(form, "tenant.phone_number")
            ? $formatPhoneNumber($deepGet(form, "tenant.phone_number"))
            : "N/A"
          }}
        </p>
      </div>
      <div class="flex space-x-2 items-center">
        <button class="
            px-4
            py-1
            border border-transparent
            text-md
            font-base
            rounded-md
            shadow-sm
            text-white
            bg-parqay-primary
            hover:bg-blue-500
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          " @click="$openContactSlideCard(form)">
          Call
        </button>
        <button class="
            px-4
            py-1
            border border-transparent
            text-md
            font-base
            rounded-md
            shadow-sm
            text-white
            bg-parqay-primary
            hover:bg-blue-500
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          " @click="$openContactSlideCard(form)">
          Text
        </button>
      </div>
    </div>
    <div class="pt-4">
      <vehicles-form v-if="form.type == 'parking' && controls.tenant && form.tenant" :controls="controls" :form="form" />
    </div>
  </div>
</template>
