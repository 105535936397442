<script>
import VehicleInfo from "src/views/listings/new/car-rentals/components/VehicleInfo";
import { API } from "src/views/listings/api";
import { getDefaultVehicleModel } from "../../new/car-rentals/components/util";
export default {
  name: "Cars",
  components: {
    VehicleInfo
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          vehicle: getDefaultVehicleModel()
        };
      }
    }
  },
  setup(props, ctx) {
    const updateReservationVehicle = async vehicle => {
      props.form.vehicle = vehicle;
      let resp;
      try {
        resp = await API.updateReservationVehicle(props.form.id, vehicle);
      } catch (error) {
        console.log("updateReservationVehicle >> error: ", error);
      }
      console.log("updateReservationVehicle >> success: ", resp);
    };
    return {
      updateReservationVehicle
    };
  }
};
</script>
<template>
  <div class="flex flex-col space-y-4 pb-4">
    <h3 class="font-bold text-base text-dark-purple px-6">
      Virtual Key
    </h3>
    <vehicle-info
      title="Your vehicle infomation will autofill into right panel after connect"
      :vehicle-id="$deepGet(form, 'summary.vehicle.id')"
      @added-vehicle="vehicle => updateReservationVehicle(vehicle)"
    />
  </div>
</template>
