<script>
import Dropdown from "src/views/dropdowns/View";
import { ref } from "@vue/composition-api";

export default {
  name: "ServiceTypeDropdown",
  components: {
    Dropdown,
  },
  setup() {
    const serviceTypeModel = ref([
      {
        id: 0,
        name: "Parking",
        value: "parking",
        icon: "/static/img/dashboard/parking-service.png",
      },
      {
        id: 1,
        name: "Stays",
        value: "stay",
        icon: "/static/img/dashboard/stays-service.png",
      },
      {
        id: 2,
        name: "Coworking",
        value: "coworking",
        icon: "/static/img/dashboard/coworking-service.png",
      },
      {
        id: 3,
        name: "Car Rentals",
        value: "car-rental",
        icon: "/static/img/dashboard/car-rentals-service.png",
      },
      {
        id: 4,
        name: "All",
        value: null,
        icon: null,
      },
    ]);

    return {
      serviceTypeModel,
    };
  },
};
</script>
<template>
  <dropdown
    :selected-index="4"
    :model="serviceTypeModel"
    :width="36"
    @dropdown-select="(i, m) => $emit('selected', m)"
  />
</template>
