<script>
import {
  ref,
  reactive,
  computed,
} from "@vue/composition-api";
export default {
  components: {},
  props: {
    custom: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: Boolean,
      default: true,
    },
    event: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedEvents: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mini: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const toggle = () => {
      const selectedIndex = props.selectedEvents.findIndex(selectedEvent => selectedEvent.id === props.event.id);
      if (selectedIndex >= 0) {
        props.selectedEvents.splice(selectedIndex, 1);
      } else {
        props.selectedEvents.push(props.event.id);
      }
      ctx.root.$bus.$emit("refresh-pricing-calendar", props.selectedEvents);
    }
    return {
      toggle
    };
  },
};
</script>
<template>
  <div class="bg-white rounded-lg shadow-lg flex flex-col overflow-hidden hover:shadow-2xl cursor-pointer"
    :style="{ minHeight: mini ? '200px' : '260px' }" @click="$emit('click')">
    <div class="bg-gray-100 relative overflow-hidden" :style="{ height: mini ? '130px' : '200px' }">
      <img class="absolute inset-0 object-cover h-full w-full" :src="event.image || event.img_url">
      <div v-if="selectMode" class="absolute w-8 right-2 h-8 top-2 rounded-full border border-parqay-primary">
        <div class="flex items-center justify-center h-full bg-white rounded-full">
          <svg-icon v-if="selectedEvents.find(selectedEvent => event.id === selectedEvent.id)" icon-class="solid-check"
            class="text-white h-8 w-8 bg-parqay-primary rounded-full" @click="toggle()" />
        </div>
      </div>
      <div class="absolute left-2 right-2 h-8 bottom-2 rounded-full bg-white">
        <div class="flex items-center justify-center h-full">
          <span v-if="!custom" class="font-medium text-parqay-primary" :class="mini ? 'text-sm' : 'text-base'">{{
            $formatDate(event.datetime_local, "MMMM Do YYYY")
          }}</span>
          <a-date-picker v-else class="w-full" format="MMMM Do YYYY" placeholder="Select Event Date" />
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center" :class="mini ? 'px-2 py-3 space-y-2' : 'p-4'">
      <span v-if="!custom" class="text-center font-medium" :class="mini ? 'text-sm' : 'text-base'">{{
        event.name
      }}</span>
      <span v-if="!custom" class="text-center font-normal text-gray-400" :class="mini ? 'text-xs' : 'text-sm'">{{
        event.address }}</span>
      <a-input v-else placeholder="Enter Event Name" />
    </div>
  </div>
</template>