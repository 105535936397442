<script>
import { onMounted } from "@vue/composition-api";

export default {
  name: "TenantForm",
  props: {
    form: {
      type: Object,
      required: true,
    },
    controls: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const saveNewTenant = () => {
      const error = props.controls.newTenant.validate(props.controls.newTenant);
      if (error) {
        ctx.root.$openNotification(error, "", 5, "error");
        return;
      } else {
        props.form.tenant = props.controls.newTenant;
        props.controls.editModeTenant = false;
        props.controls.editMode = false;
      }
    };
    onMounted(() => {
    });
    return {
      saveNewTenant,
    };
  },
};
</script>

<template>
  <div id="host" class="bg-white shadow overflow-hidden sm:rounded-lg mx-8 pb-4">
    <div class="bg-white px-4 pt-3 pb-1 border-b border-gray-200 sm:px-6">
      <div class="flex justify-between items-center">
        <h3 class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap">
          Host Details
        </h3>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-2 sm:gap-2 sm:items-start sm:border-gray-200 pt-5 px-5">
      <div class="flex items-center justify-between sm:justify-start py-2 space-x-4">
        <p class="text-sm font-medium text-gray-700">
          Full Name
        </p>
        <p class="text-sm text-parqay-primary font-medium">
          {{ $deepGet(form, "host.name") }}
        </p>
      </div>
      <div class="flex items-center justify-between sm:justify-start py-2 space-x-4">
        <p class="text-sm font-medium text-gray-700">
          Email
        </p>
        <p class="text-sm text-gray-900 font-bold">
          {{ $deepGet(form, "host.email", "N/A") }}
        </p>
      </div>
      <div class="flex items-center justify-between sm:justify-start py-2 space-x-4">
        <p class="text-sm font-medium text-gray-700">
          Phone Number
        </p>
        <p class="text-sm text-gray-900 font-bold">
          {{ $deepGet(form, "host.phone_number") ? $formatPhoneNumber($deepGet(form, "host.phone_number")) : "N/A" }}
        </p>
      </div>
      <div class="flex space-x-4 items-center">
        <button class="
            px-4
            py-1
            border border-transparent
            text-md
            font-base
            rounded-md
            shadow-sm
            text-white
            bg-parqay-primary
            hover:bg-blue-500
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          " @click="$openContactSlideCard(form)">
          Call
        </button>
        <button class="
            px-4
            py-1
            border border-transparent
            text-md
            font-base
            rounded-md
            shadow-sm
            text-white
            bg-parqay-primary
            hover:bg-blue-500
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          " @click="$openContactSlideCard(form)">
          Text
        </button>
      </div>
    </div>
  </div>
</template>