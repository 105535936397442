<script>
import { computed, watch, ref, reactive } from "@vue/composition-api";
import {
  getDefaultGuestForm,
  getDefaultGuestControls
} from "src/views/reservations/util";

export default {
  name: "GuestsForm",
  components: {},
  props: {
    form: {
      type: Object,
      required: true
    },
    controls: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    return {
      getDefaultGuestForm,
      getDefaultGuestControls
    };
  }
};
</script>

<template>
  <div
    class="
      sm:grid sm:grid-cols-6
      sm:gap-4
      border-t
      sm:items-start
      sm:border-gray-200
      sm:pt-5
      px-5
    "
  >
    <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      Guests
    </p>
    <div class="mt-1 sm:mt-0 sm:col-span-5">
      <div class="flex justify-between w-1/2 py-2 items-center">
        <div class="flex flex-col">
          <span class="text-sm font-medium text-gray-500"
            >Prompt guest to enter basic info</span
          >
          <span class="text-sm font-light text-gray-500"
            >If choose NO, you may help your guest to add info</span
          >
        </div>
        <a-switch
          :disabled="!controls.createMode"
          checked-children="Yes"
          un-checked-children="No"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <dl
          v-for="(guest, i) in form.guests"
          :key="`driver-info-${i}`"
          class="m-0 pt-2"
        >
          <div class="pb-4 flex flex-col space-y-2">
            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
              <dt class="text-sm font-bold my-auto">Guest {{ i + 1 }}</dt>
              <a-input
                v-if="controls.guests[i].editMode"
                v-model="guest.name"
                class="
                  my-auto
                  text-sm text-parqay-primary
                  font-medium
                  sm:col-span-2
                "
                size="large"
              />
              <dd
                v-else
                class="
                  mt-1
                  text-sm text-parqay-primary
                  font-medium
                  sm:mt-0
                  sm:col-span-2
                "
              >
                {{ guest.name }}
              </dd>
            </div>
            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
              <dt class="text-sm font-medium text-gray-500 my-auto">
                Number
              </dt>
              <a-input
                v-if="controls.guests[i].editMode"
                v-model="guest.phone_number"
                class="
                  my-auto
                  text-sm text-parqay-primary
                  font-medium
                  sm:col-span-2
                "
                size="large"
              />
              <dd
                v-else
                class="
                  mt-1
                  text-sm text-gray-900
                  font-bold
                  sm:mt-0
                  sm:col-span-2
                "
              >
                {{ guest.phone_number }}
              </dd>
            </div>
            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
              <dt class="text-sm font-medium text-gray-500 my-auto">
                Docs
              </dt>
              <dd class="my-auto col-span-2">
                <div
                  v-if="controls.guests[i].editMode"
                  class="flex items-center"
                >
                  <div>
                    <button
                      class="rounded bg-parqay-primary text-white px-4 py-2"
                      @click="$openDocSlideCard('host')"
                    >
                      Upload
                    </button>
                  </div>
                </div>
                <div v-else class="flex space-x-2 items-center">
                  <!-- <docs-dropdown
                    class="w-2/3"
                    :model="form.docs"
                  /> -->
                  <div>
                    <button
                      class="rounded bg-parqay-primary text-white px-4 py-2"
                      @click="$openDocSlideCard('host')"
                    >
                      View
                    </button>
                  </div>
                </div>
              </dd>
            </div>
            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
              <dt class="text-sm font-medium text-gray-500 my-auto">
                Doc Number
              </dt>
              <a-input
                v-if="controls.guests[i].editMode"
                v-model="guest.doc_number"
                class="
                  my-auto
                  text-sm text-parqay-primary
                  font-medium
                  sm:col-span-2
                "
                size="large"
              />
              <dd
                v-else
                class="
                  mt-1
                  text-sm text-gray-900
                  font-bold
                  sm:mt-0
                  sm:col-span-2
                "
              >
                {{ guest.doc_number }}
              </dd>
            </div>
            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
              <div class="flex justify-start space-x-4 col-span-3 py-4">
                <button
                  class="
                    px-2
                    py-1
                    bg-red-500
                    hover:bg-red-700
                    text-white
                    rounded
                  "
                  @click="
                    () => {
                      form.guests.splice(i, 1);
                      controls.guests.splice(i, 1);
                    }
                  "
                >
                  Delete
                </button>
                <button
                  v-if="!controls.guests[i].editMode"
                  class="
                    px-4
                    py-1
                    bg-parqay-primary
                    hover:bg-blue-500
                    text-white
                    rounded
                  "
                  @click="() => (controls.guests[i].editMode = true)"
                >
                  Edit
                </button>
                <button
                  v-if="controls.guests[i].editMode"
                  class="
                    px-4
                    py-1
                    bg-parqay-primary
                    hover:bg-blue-500
                    text-white
                    rounded
                  "
                  @click="() => (controls.guests[i].editMode = false)"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </dl>
        <div v-if="controls.editMode" class="mx-auto">
          <button
            class="
              border border-parqay-primary
              hover:border-indigo-800
              focus:outline-none
              text-parqay-primary
              hover:text-blue-800
              font-semibold
              rounded
              py-2
              px-12
            "
            @click="
              () => {
                form.guests.push(getDefaultGuestForm());
                controls.guests.push(getDefaultGuestControls(true));
              }
            "
          >
            + Add New Guest
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
