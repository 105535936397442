<script>
import SpaceItem from "./SpaceItem";
import SpaceAddButton from "./SpaceAddButton";
import { reactive, ref } from "@vue/composition-api";
import { Container, Draggable } from "vue-smooth-dnd";
import { API } from "src/views/listings/api";
import forOwn from 'lodash/forOwn';
import groupBy from 'lodash/groupBy';

export default {
  name: "Spaces",
  components: {
    SpaceItem,
    SpaceAddButton,
    Container,
    Draggable,
  },
  props: {
    model: {
      type: Object,
    },
    form: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    const shortUID = ctx.root.$shortUID;
    const drag = ref(false);
    const controls = reactive({
      drag: false,
      loading: {
        save: false,
      },
    });
    const getGroupPayloadByOrderIndex = (index) => {
      let result;
      forOwn(props.model.spaces.groups, (v, k) => {
        if (v.order === index) {
          result = v;
        }
      });
      return result;
    };
    const groupSpaces = (spaces) => {
      var meta = [];
      const groupedSpaces = groupBy(spaces.items, "group_id");
      forOwn(groupedSpaces, (v, k) => {
        meta.push({
          availability: v.length,
          id: k,
          name: spaces.groups[k].name,
          order: spaces.groups[k].order,
        });
      });
      meta.sort((a, b) => a.order - b.order);
      console.log("groupSpaces >> ", meta);
      return meta;
    };
    const handleAddSpace = (data) => {
      const { spaceName, count } = data;
      const order = Object.keys(props.model.spaces.groups).length;
      const groupId = shortUID();
      props.model.spaces.groups[groupId] = {
        name: spaceName,
        order,
      };
      for (const i of new Array(count)) {
        handleAddSpots(groupId, order);
      }
    };
    const handleAddSpots = (group_id, order) => {
      props.model.spaces.items.push({
        id: shortUID(),
        group_id,
        priority: Object.keys(props.model.spaces.groups).length - order,
        booked_intervals: [],
      });
    };
    const handleRemoveSpots = (group_id) => {
      const groupCount = props.model.spaces.items.filter((v) => v.group_id === group_id).length;
      if (groupCount == 0) {
        delete props.model.spaces.groups[group_id];
        return;
      }
      const spotToRemove = props.model.spaces.items.findIndex((v) => v.group_id === group_id);
      props.model.spaces.items.splice(spotToRemove, 1);
    };
    const handleRemoveGroup = (group_id) => {
      delete props.model.spaces.groups[group_id];
      props.model.spaces.items = props.model.spaces.items.filter((v) => v.group_id !== group_id);
      // const it = _.remove(props.model.spaces.items, (v) => v.group_id === group_id);
      // console.log("handleRemoveGroup >> ", it)
    };
    const updateSpaces = async () => {
      controls.loading.save = true;
      let resp;
      try {
        resp = await API.updateSpaces(props.form.id, { ...props.model.spaces });
      } catch (error) {
        console.log("updateSpaces >> error: ", error);
        setTimeout(() => {
          controls.loading.save = false;
        }, 500);
        return;
      }
      console.log("updateSpaces >> resp: ", resp);
      setTimeout(() => {
        controls.loading.save = false;
        ctx.emit("refresh");
      }, 500);
    };
    return {
      drag,
      controls,
      handleAddSpace,
      handleAddSpots,
      handleRemoveSpots,
      handleRemoveGroup,
      groupSpaces,
      getGroupPayloadByOrderIndex,
      updateSpaces,
      console,
    };
  },
};
</script>
<template>
  <div class="spaces pb-4 px-2">
    <div class="flex items-baseline justify-between space-x-4 pb-2">
      <span class="text-sm font-medium">
        Spaces
      </span>
      <div class="flex items-center space-x-2 text-xs font-semibold text-parqay-primary hover:text-blue-500 cursor-pointer" @click.stop="() => {
        $emit('change-tab', 'calendar')
      }">
        <a-icon type="calendar" />
        <span>Booking Calendar</span>
      </div>
    </div>
    <div class="flex items-center justify-center space-x-2 pt-4 pb-1 hover:animate-pulse cursor-pointer">
        <svg-icon icon-class="move" class="w-4 h-4" />
        <span class="font-light text-gray-600">Drag to change the priority of selling these spaces</span>
      </div>
    <div class="py-2">
      <container class="grid grid-cols-4 gap-4" orientation="horizontal" drag-class="z-20" :get-child-payload="(index) => {
        return groupSpaces(model.spaces)[index];
      }
        " @start="controls.drag = true" @end="controls.drag = false" @drop="(dropResult) => {
    const { removedIndex, addedIndex, payload, element } = dropResult;
    console.log(dropResult);
    //TODO: use real reordering instead of current switching position
    const removingPayload = getGroupPayloadByOrderIndex(addedIndex);
    if (removingPayload) {
      removingPayload.order = removedIndex;
      model.spaces.groups[payload.id].order = addedIndex;
    }
  }
    ">
        <draggable v-for="(it, i) in groupSpaces(model.spaces)" :key="it.id" class="flex flex-col z-0">
          <p class="text-xs text-gray-600 font-medium text-center h-10 leading-10">
            {{ it.name }}
          </p>
          <div class="pointer-events-auto">
            <space-item class="hover:shadow-xl cursor-pointer hover:border-2 hover:border-blue-500" :item="it"
              @add="() => handleAddSpots(it.id, it.order)" @minus="() => handleRemoveSpots(it.id)"
              @delete="() => handleRemoveGroup(it.id)" />
          </div>
        </draggable>
        <draggable class="col-span-2 flex">
          <space-add-button @add="handleAddSpace" />
        </draggable>
      </container>
    </div>
    <div class="flex space-x-4 mt-4">
      <a-button type="primary" block :loading="controls.loading.save" @click="() => updateSpaces()">
        Save Changes
      </a-button>
      <a-button block @click="$emit('refresh')">
        Reset
      </a-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spaces ::v-deep {
  .smooth-dnd-container.horizontal {
    display: grid;
  }
}
</style>
