export const PropertyImages = [
  "/static/img/host-dashboard/listing3.jpg",
  "/static/img/host-dashboard/listing4.jpg",
  "/static/img/host-dashboard/listing5.jpg",
  "/static/img/host-dashboard/listing1.jpg",
  "/static/img/host-dashboard/listing2.jpg",
];
export const PropertiesNearbyData = [
  {
    button: {
      text: "View All Parking Spaces",
      to: "/",
    },
    properties: [
      {
        id: "1",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.964, lng: -122.015 },
        images: PropertyImages,
      },
      {
        id: "2",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96440284626288, lng: -122.01395834377185 },
        images: PropertyImages,
      },
      {
        id: "3",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.963764196854974, lng: -122.01445187023059 },
        images: PropertyImages,
      },
      {
        id: "4",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96372990680235, lng: -122.01532090595141 },
        images: PropertyImages,
      },
      {
        id: "5",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96441932711484, lng: -122.01619327068329 },
        images: PropertyImages,
      },
      {
        id: "6",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96361970086733, lng: -122.01597973497648 },
        images: PropertyImages,
      },
    ],
  },
  {
    button: {
      text: "View All Stays",
      to: "/",
    },
    properties: [
      {
        id: "1",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.964, lng: -122.015 },
        images: PropertyImages,
      },
      {
        id: "2",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96440284626288, lng: -122.01395834377185 },
        images: PropertyImages,
      },
      {
        id: "3",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.963764196854974, lng: -122.01445187023059 },
        images: PropertyImages,
      },
      {
        id: "4",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96372990680235, lng: -122.01532090595141 },
        images: PropertyImages,
      },
      {
        id: "5",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96441932711484, lng: -122.01619327068329 },
        images: PropertyImages,
      },
      {
        id: "6",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96361970086733, lng: -122.01597973497648 },
        images: PropertyImages,
      },
    ],
  },
  {
    button: {
      text: "View All Offices",
      to: "/",
    },
    properties: [
      {
        id: "1",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.964, lng: -122.015 },
        images: PropertyImages,
      },
      {
        id: "2",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96440284626288, lng: -122.01395834377185 },
        images: PropertyImages,
      },
      {
        id: "3",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.963764196854974, lng: -122.01445187023059 },
        images: PropertyImages,
      },
      {
        id: "4",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96372990680235, lng: -122.01532090595141 },
        images: PropertyImages,
      },
      {
        id: "5",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96441932711484, lng: -122.01619327068329 },
        images: PropertyImages,
      },
      {
        id: "6",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96361970086733, lng: -122.01597973497648 },
        images: PropertyImages,
      },
    ],
  },
  {
    button: {
      text: "View All Vehicles",
      to: "/",
    },
    properties: [
      {
        id: "1",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.964, lng: -122.015 },
        images: PropertyImages,
      },
      {
        id: "2",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96440284626288, lng: -122.01395834377185 },
        images: PropertyImages,
      },
      {
        id: "3",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.963764196854974, lng: -122.01445187023059 },
        images: PropertyImages,
      },
      {
        id: "4",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96372990680235, lng: -122.01532090595141 },
        images: PropertyImages,
      },
      {
        id: "5",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96441932711484, lng: -122.01619327068329 },
        images: PropertyImages,
      },
      {
        id: "6",
        type: "parking",
        mode: "monthly",
        title: "Milbrae - Monthly / Airport Parking",
        price: "$200/month",
        date: ["MAR", "2021"],
        location: { lat: 36.96361970086733, lng: -122.01597973497648 },
        images: PropertyImages,
      },
    ],
  },
];
