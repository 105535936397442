var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white shadow sm:rounded-lg mx-8 pb-4",attrs:{"id":"tenants"}},[_c('div',{staticClass:"bg-white px-4 pt-3 pb-1 border-b border-gray-200 sm:px-6"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('h3',{staticClass:"text-base leading-6 font-medium text-gray-900 whitespace-nowrap"},[_vm._v("\n          Tenant Details\n        ")]),_c('validation-section-header',{attrs:{"validation-errors":_vm.controls.errors.filter(function (e) { return e.type == 'tenant'; })}}),_c('div',[(_vm.controls.editMode &&
            _vm.controls.showTenantInputSearch &&
            !_vm.controls.createMode
            )?_c('button',{staticClass:"border border-parqay-primary bg-white text-parqay-primary hover:text-blue-800 px-2 py-1 text-xs rounded",on:{"click":function () {
              _vm.controls.showTenantInputSearch = false;
              _vm.controls.editModeTenant = false;
            }}},[_vm._v("\n            Cancel\n          ")]):_vm._e(),(_vm.controls.editMode && !_vm.controls.showTenantInputSearch)?_c('button',{staticClass:"bg-parqay-primary hover:bg-blue-500 px-4 py-1 text-xs text-white border border-parqay-primary rounded",on:{"click":function () {
              _vm.controls.showTenantInputSearch = true;
              _vm.controls.showCreateNewTenant = true;
            }}},[_vm._v("\n            Edit\n          ")]):_vm._e()])],1)]),(_vm.controls.showTenantInputSearch)?_c('div',{staticClass:"sm:grid sm:grid-cols-2 sm:gap-y-4 sm:items-start sm:border-gray-200 sm:pt-5 px-5"},[_c('tenant-search-input',{key:("tenant-search-input-" + (_vm.controls.keys.tenantSearchInput)),attrs:{"default-tenant":_vm.form.tenant},on:{"select":function (tenant) {
          _vm.controls.tenant = tenant;
        },"select-confirmed":function () {
  _vm.controls.showTenantInputSearch = false;
  _vm.form.tenant = _vm.controls.tenant;
}}}),_c('div',{staticClass:"flex items-center justify-center"},[(_vm.controls.showCreateNewTenant)?_c('button',{staticClass:"flex items-center justify-center space-x-2 border border-parqay-primary hover:border-indigo-800 px-4 py-2 rounded focus:outline-none",on:{"click":function () {
            _vm.controls.editModeTenant = true;
            _vm.controls.showCreateNewTenant = false;
          }}},[_c('span',{staticClass:"text-center font-semibold text-parqay-primary hover:text-blue-800"},[_vm._v("\n            + Create New Tenant\n          ")])]):_vm._e()]),(_vm.controls.editModeTenant)?[_c('invite-form',{staticClass:"col-span-2",on:{"refresh-tenants-list":function () { return (_vm.controls.keys.tenantSearchInput += 1); },"dismiss":function () { return (_vm.controls.editModeTenant = false); }}})]:_vm._e()],2):_c('div',{staticClass:"sm:grid sm:grid-cols-2 sm:gap-2 sm:items-start sm:border-gray-200 sm:pt-5 px-5"},[_c('div',{staticClass:"grid grid-cols-3 gap-4"},[_c('p',{staticClass:"block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"},[_vm._v("\n          Full Name\n        ")]),_c('p',{staticClass:"mt-1 text-sm text-parqay-primary font-medium sm:mt-0 sm:col-span-2 pt-2"},[_vm._v("\n          "+_vm._s(_vm.$deepGet(_vm.form, "tenant.name"))+"\n        ")])]),_c('div',{staticClass:"grid grid-cols-3 gap-4"},[_c('p',{staticClass:"block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"},[_vm._v("\n          City\n        ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2"},[_vm._v("\n          "+_vm._s(_vm.$deepGet(_vm.form, "tenant.address")
            ? _vm.$parseAddress(_vm.$deepGet(_vm.form, "tenant.address")).city
            : "N/A")+"\n        ")])]),_c('div',{staticClass:"grid grid-cols-3 gap-4"},[_c('p',{staticClass:"block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"},[_vm._v("\n          Email\n        ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2"},[_vm._v("\n          "+_vm._s(_vm.$deepGet(_vm.form, "tenant.email", "N/A"))+"\n        ")])]),_c('div',[_c('a',{staticClass:"\n            px-4\n            py-1.5\n            border border-transparent\n            text-md\n            font-base\n            rounded-md\n            shadow-sm\n            text-white\n            bg-parqay-primary\n            hover:bg-blue-500\n            focus:outline-none\n          ",on:{"click":function($event){$event.stopPropagation();return _vm.$bus.$emit('toggle-email-editor', { tenant_id: _vm.form.tenant.id })}}},[_vm._v("\n          Send Email\n        ")])]),_c('div',{staticClass:"grid grid-cols-3 gap-4"},[_c('p',{staticClass:"block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"},[_vm._v("\n          Phone Number\n        ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2"},[_vm._v("\n          "+_vm._s(_vm.$deepGet(_vm.form, "tenant.phone_number")
            ? _vm.$formatPhoneNumber(_vm.$deepGet(_vm.form, "tenant.phone_number"))
            : "N/A")+"\n        ")])]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('button',{staticClass:"\n            px-4\n            py-1\n            border border-transparent\n            text-md\n            font-base\n            rounded-md\n            shadow-sm\n            text-white\n            bg-parqay-primary\n            hover:bg-blue-500\n            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500\n          ",on:{"click":function($event){return _vm.$openContactSlideCard(_vm.form)}}},[_vm._v("\n          Call\n        ")]),_c('button',{staticClass:"\n            px-4\n            py-1\n            border border-transparent\n            text-md\n            font-base\n            rounded-md\n            shadow-sm\n            text-white\n            bg-parqay-primary\n            hover:bg-blue-500\n            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500\n          ",on:{"click":function($event){return _vm.$openContactSlideCard(_vm.form)}}},[_vm._v("\n          Text\n        ")])])]),_c('div',{staticClass:"pt-4"},[(_vm.form.type == 'parking' && _vm.controls.tenant && _vm.form.tenant)?_c('vehicles-form',{attrs:{"controls":_vm.controls,"form":_vm.form}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }