<script>
import CreditCardTag from "./CreditCardTag.vue";

export default {
  props: {
    t: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  components: { CreditCardTag },
  setup() {
    const openReceipt = (r) => {
      window.open(r, "_blank");
    };
    return {
      openReceipt,
    };
  },
};
</script>
<template>
  <div
    class="flex rounded-xl border bg-white shadow-md hover:shadow-xl cursor-pointer px-3 xl:px-6 py-4 justify-between items-start"
  >
    <div class="grid gap-4 grid-cols-2 w-full">
      <span
        class="text-center text-xl font-semibold col-span-2 xl:col-span-1 xl:text-left"
      >
        {{ $deepGet(t, "billing_details.name") }} paid
        <a class="text-green-400">
          {{ $formatCurrency($deepGet(t, "amount_captured") / 100) }}
        </a>
      </span>
      <div
        class="flex items-center justify-center xl:justify-end space-x-2 col-span-2 xl:col-span-1"
      >
        <credit-card-tag
          :brand="$deepGet(t, 'payment_method_details.card.brand')"
          :last4="$deepGet(t, 'payment_method_details.card.last4')"
        />
      </div>
      <span class="text-sm font-medium text-center col-span-2 xl:text-left"
        >Stripe ID:
        <a class="text-green-400">
          {{ t.id }}
        </a>
      </span>
      <div
        class="flex items-center justify-center xl:justify-end space-x-2 col-span-2"
      >
        <a-button
          :disabled="!$deepGet(t, 'receipt_url')"
          @click="openReceipt($deepGet(t, 'receipt_url'))"
          >View Receipt</a-button
        >
      </div>
      <div
        v-if="expanded"
        class="flex flex-col items-center justify-center space-y-4 col-span-2 p-4 rounded border"
      >
        <span class="text-base font-medium pb-2 border-b w-full"
          >Card Details:
        </span>
        <div class="grid grid-cols-2 gap-x-2 gap-y-4 w-full pb-4 border-b">
          <div class="flex items-center space-x-2">
            <span>Expiry Date</span>
            <a-tag color="geekblue"
              >{{ $deepGet(t, "payment_method_details.card.exp_month") }}/{{
                $deepGet(t, "payment_method_details.card.exp_year")
              }}</a-tag
            >
          </div>
          <div v-if="false" class="flex items-center space-x-2">
            <span>Funding Source</span>
            <a-tag color="geekblue">{{
              $deepGet(t, "payment_method_details.card.funding")
            }}</a-tag>
          </div>
          <div class="flex items-center space-x-2">
            <span>Currency</span>
            <a-tag color="geekblue">{{ $deepGet(t, "currency") }}</a-tag>
          </div>
          <div v-if="false" class="flex items-center space-x-2">
            <span>Country</span>
            <a-tag color="geekblue">{{
              $deepGet(t, "payment_method_details.card.country")
            }}</a-tag>
          </div>
          <div class="flex items-center space-x-2">
            <span>CVC Check</span>
            <a-tag color="geekblue">{{
              $deepGet(t, "payment_method_details.card.checks.cvc_check")
            }}</a-tag>
          </div>
        </div>
        <div v-if="false" class="grid grid-cols-2 gap-x-2 gap-y-4 w-full">
          <div class="flex items-center space-x-2">
            <span>Captured?</span>
            <a-tag color="geekblue">{{
              $deepGet(t, "captured") ? "Yes" : "No"
            }}</a-tag>
          </div>
          <div class="flex items-center space-x-2 col-span-2">
            <span>Network Status</span>
            <a-tag color="geekblue">{{
              $deepGet(t, "outcome.network_status")
            }}</a-tag>
          </div>
          <div class="flex items-center space-x-2 col-span-2 md:col-span-1">
            <span>Outcome</span>
            <a-tag color="geekblue">{{ $deepGet(t, "outcome.type") }}</a-tag>
          </div>
          <div class="flex items-center space-x-2 col-span-2 md:col-span-1">
            <span>Risk Level</span>
            <a-tag color="geekblue">{{
              $deepGet(t, "outcome.risk_level")
            }}</a-tag>
          </div>
          <div class="flex items-center space-x-2 col-span-2">
            <span>Seller Message</span>
            <a-tag color="geekblue">{{
              $deepGet(t, "outcome.seller_message")
            }}</a-tag>
          </div>
        </div>
      </div>
      <span
        class="text-xs font-medium text-center text-gray-500 col-span-2 xl:text-right"
      >
        {{ new Date(t.created * 1000).toLocaleDateString("en-US") }}
        {{ new Date(t.created * 1000).toLocaleTimeString("en-US") }}
      </span>
    </div>
  </div>
</template>
