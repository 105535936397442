<script>
import { ref, onMounted } from "@vue/composition-api";
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual'

export default {
  name: "SignupStep5",
  props: {
    hashRoute: {
      type: String,
      default: "step-five",
    },
  },
  setup(props, ctx) {
    const planModel = ref([
      {
        id: "beta-trial",
        name: "Beta Trial",
        icon: "premium-diamond",
        details: ["All features are available in our beta trial period"],
        price: {
          amount: "Free",
          freq: "",
        },
      },
      {
        id: "hobby",
        name: "Hobby",
        icon: "leaf-plan",
        details: [
          "Marketplace Listings (up to 3)",
          "Payments: Credit Card & ACH",
          "Maintanence Tracking",
          "Tenant Communication Portal",
        ],
        price: {
          amount: "Free",
          freq: "",
        },
      },
      {
        id: "growth",
        name: "Growth",
        icon: "coin",
        details: [
          "Unlimited Listings",
          "Waived ACH Fees",
          "Custom Applications and Leases",
        ],
        price: {
          amount: "$150",
          freq: "/mo",
        },
      },
      {
        id: "scale",
        name: "Scale",
        icon: "crown",
        details: ["24/7 Virtual Leasing / Sales Office"],
        price: {
          amount: "$250",
          freq: "/mo",
        },
      },
    ]);

    const selectedPlan = ref(null);

    const selectPlan = (plan) => {
      selectedPlan.value = planModel.value[0];
      ctx.emit("payload-update", "plan", planModel.value[0].id);
    };

    const nextAction = () => {
      if (selectedPlan.value) {
        ctx.emit("next");
      }
    };

    const loadCachedPayloadFromStore = () => {
      const signUpForm = ctx.root.$store.state.signUpForm;
      if (signUpForm.plan) {
        const index = findIndex(planModel.value, (o) => {
          return o.id == signUpForm.plan;
        });
        selectedPlan.value = planModel.value[index];
      }
    };

    const route = ctx.root.$route;
    const router = ctx.root.$router;
    onMounted(() => {
      router.push({ name: route.name, hash: `#${props.hashRoute}` });
      loadCachedPayloadFromStore();
    });

    return {
      planModel,
      selectedPlan,
      selectPlan,
      nextAction,
      isEqual
    };
  },
};
</script>
<template>
  <div class="w-full overflow-hidden" style="min-height: 460px">
    <div class="flex flex-col overflow-auto space-y-4">
      <span class="text-lg font-medium text-center text-parqay-primary">Choose A Plan</span>
      <div class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer" style="min-height: 321px">
        <div class="flex flex-col px-2 md:px-6 py-2">
          <span class="text-center py-4">Choose a plan that works for you.</span>
          <div class="mt-1 border-b" />
          <div class="flex flex-col pt-4">
            <fieldset>
              <legend id="radiogroup-label" class="sr-only">
                Server size
              </legend>
              <ul class="space-y-4" role="radiogroup" aria-labelledby="radiogroup-label">
                <li v-for="(plan, i) in planModel" :key="`plan-${i}`" tabindex="0" role="radio" aria-checked="true"
                  class="group relative bg-white rounded-lg shadow-sm cursor-pointer"
                  :class="{'outline-none ring-4 ring-offset-2 ring-indigo-500': $deepGet(selectedPlan, 'id') === plan.id}"
                  @click="selectPlan(plan)">
                  <div
                    class="rounded-lg border border-gray-300 bg-white px-4 py-4 hover:border-gray-400 grid grid-cols-5 gap-x-2">
                    <div class="">
                      <svg-icon :icon-class="plan.icon" class="w-8 h-8" />
                    </div>
                    <div class="flex items-center col-span-3">
                      <div class="text-sm space-y-2">
                        <p class="font-medium text-gray-900">
                          {{ plan.name }}
                        </p>
                        <div class="text-xs text-gray-500">
                          <template v-for="(feature, i) in plan.details">
                            <p :key="`feature-${i}`" class="sm:inline">
                              {{ feature }}
                            </p>
                            <span v-if="i < plan.details.length - 1" :key="`feature-${i}-1`"
                              class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2 flex text-sm sm:mt-0 sm:block sm:text-right">
                      <div class="font-medium text-gray-900">
                        {{ plan.price.amount }}
                      </div>
                      <div class="ml-1 text-gray-500 sm:ml-0">
                        {{ plan.price.freq }}
                      </div>
                    </div>
                  </div>
                  <div class="absolute inset-0 rounded-lg border-2 pointer-events-none" :class="{
                    'border-indigo-500': selectedPlan === plan.id,
                    'border-transparent': selectedPlan !== plan.id,
                  }" aria-hidden="true" />
                </li>
              </ul>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="flex space-x-4">
        <button class="text-base bg-parqay-primary hover:bg-blue-500 rounded-lg text-white px-4 py-2 w-1/2"
          @click="$emit('prev')">
          Previous
        </button>
        <button class="text-base rounded-lg text-white px-4 py-2 w-1/2" :class="{
          'bg-parqay-primary hover:bg-blue-500': selectedPlan,
          'bg-gray-300': !selectedPlan,
        }" @click="nextAction">
          Complete
        </button>
      </div>
    </div>
  </div>
</template>