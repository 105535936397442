<script>
import { ref, reactive, computed, onMounted, watch } from "@vue/composition-api";
import { required, minLength, } from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default {
  name: "SignupStep4",
  props: {
    hashRoute: {
      type: String,
      default: "step-four",
    },
  },
  setup(props, ctx) {
    const agreed = ref(false);
    const highlightError = reactive({
      firstName: false,
      lastName: false,
      password: false,
    });
    var consentDt = null;
    watch(() => agreed.value, (val) => {
      if (val) {
        consentDt = now()
        console.log("agreement consent datetime: " + consentDt)
        ctx.emit("payload-update", "agreement_sign_at", consentDt);
      }
    })
    const firstName = ref(null);
    const lastName = ref(null);
    const password = ref(null);

    const now = () => {
      return dayjs().format("YYYY-MM-DDTHH:mm:ssZZ");
    };

    const shouldEnableNext = ($v) => {
      return agreed.value && !$v.firstName.$invalid && !$v.lastName.$invalid && !$v.password.$invalid
    }

    const nextAction = ($v) => {
      if (shouldEnableNext($v)) {
        ctx.emit("payload-update", "first_name", firstName.value);
        ctx.emit("payload-update", "last_name", lastName.value);
        ctx.emit("payload-update", "password", password.value);
        ctx.emit("next");
      }
    };

    const loadCachedPayloadFromStore = () => {
      const signUpForm = ctx.root.$store.state.signUpForm;
      if (signUpForm.first_name) {
        firstName.value = signUpForm.first_name;
      }
      if (signUpForm.last_name) {
        lastName.value = signUpForm.last_name;
      }
    };

    const checkShouldHighlightErrorState = ($v, target) => {
      highlightError[target] = $v[target].$invalid
    };

    const getInputState = (target) => {
      return { class: highlightError[target] ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500' : 'border-gray-300 text-gray-900 placeholder-gray-300 focus:border-indigo-500'}
    }

    const route = ctx.root.$route;
    const router = ctx.root.$router;
    onMounted(() => {
      router.push({ name: route.name, hash: `#${props.hashRoute}` });
      loadCachedPayloadFromStore();
    });

    return {
      firstName,
      lastName,
      password,
      agreed,
      highlightError,
      getInputState,
      checkShouldHighlightErrorState,
      nextAction,
      shouldEnableNext,
    };
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    password: {
      required,
      minLength: minLength(6),
      valid: (value) => {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        // const containsSpecial = /[#?!@$%^&*-]/.test(value)
        return containsUppercase && containsLowercase && containsNumber
      }
    },
  },
};
</script>
<template>
  <div
    class="w-full overflow-hidden"
    style="min-height: 460px"
  >
    <div class="flex flex-col overflow-auto space-y-4">
      <span
        class="text-lg font-medium text-center text-parqay-primary"
      >Wrap Up</span>
      <div
        class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer"
        style="min-height: 321px"
      >
        <div class="flex flex-col px-6 pt-2">
          <span
            class="text-center py-4"
          >Phew, finally. Let’s fill out the following information</span>
          <div class="mt-1 border-b" />
          <form
            class="grid grid-cols-2 gap-4 pt-4"
            autocomplete="off"
          >
            <div>
              <label
                for="first-name"
                class="block text-sm font-medium text-gray-700"
              >First Name</label>
              <div class="mt-1">
                <input
                  id="first-name"
                  v-model="firstName"
                  type="text"
                  name="first-name"
                  class="shadow-sm block w-full sm:text-sm rounded-md"
                  :class="getInputState('firstName').class"
                  placeholder="John"
                  @blur="checkShouldHighlightErrorState($v, 'firstName')"
                >
              </div>
            </div>
            <div>
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700"
              >Last Name</label>
              <div class="mt-1">
                <input
                  id="last-name"
                  v-model="lastName"
                  type="text"
                  name="last-name"
                  class="shadow-sm block w-full sm:text-sm rounded-md"
                  :class="getInputState('lastName').class"
                  placeholder="Michael"
                  @blur="checkShouldHighlightErrorState($v, 'lastName')"
                >
              </div>
            </div>
            <div class="col-span-2">
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >Password</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  name="password"
                  class="block w-full pr-10 focus:outline-none focus:ring-red-500 sm:text-sm rounded-md"
                  :class="getInputState('password').class"
                  placeholder=""
                  aria-invalid="true"
                  aria-describedby="email-error"
                  @blur="checkShouldHighlightErrorState($v, 'password')"
                >
                <div
                  v-if="highlightError.password"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <svg-icon
                    icon-class="solid-exclamation-circle"
                    class="h-5 w-5 text-red-500"
                  />
                </div>
              </div>
              <div
                v-if="highlightError.password"
                id="email-error"
                class="mt-2 text-sm text-red-600 text-center"
              >
                <span v-if="!$v.password.required">A valid password is required</span>
                <span v-if="!$v.password.minLength">A valid password needs at least 6 letters</span>
                <span v-if="!$v.password.valid && $v.password.required && $v.password.minLength">A valid password requires at least one uppercase, one lowercase letter and one number</span>
              </div>
            </div>
          </form>
          <div class="flex space-x-2 pt-2">
            <div class="inline-flex">
              <a-checkbox v-model="agreed" />
            </div>
            <div class="inline-flex">
              <span
                class="font-medium"
              >I agree to the
                <a class="text-parqay-primary">Terms Of Policy</a> and
                <a class="text-parqay-primary">Privacy Policy</a> of Parqay,
                Inc.</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex space-x-4">
        <button
          class="text-base bg-parqay-primary hover:bg-blue-500 rounded-lg text-white px-4 py-2 w-1/2"
          @click="$emit('prev')"
        >
          Previous
        </button>
        <button
          class="text-base rounded-lg text-white px-4 py-2 w-1/2"
          :class="{
            'bg-parqay-primary hover:bg-blue-500': shouldEnableNext($v),
            'bg-gray-300': !shouldEnableNext($v),
          }"
          @click="nextAction($v)"
        >
          Choose A Plan
        </button>
      </div>
    </div>
  </div>
</template>