<script>
import { reactive, ref, onMounted, nextTick } from "@vue/composition-api";
import { uploadFileToS3, getAvatarURL } from "src/views/components/util";
import { shortUID } from "src/plugins/prototype";

import PhoneNumberManageCard from "src/views/auth/components/Step3.vue";
import EmailManageCard from "src/views/auth/components/Step2.vue";
import NewPaymentMethodCard from "src/views/components/NewPaymentMethodCard.vue";
import ListingSettings from "./components/Listing.vue";
import ConnectWithStripeButton from "src/views/components/ConnectWithStripeButton.vue";
import CheckStripeEarningsButton from "src/views/components/CheckStripeEarningsButton.vue";
import ChangePasswordModalView from "src/views/components/ChangePasswordModalView.vue"

import startCase from "lodash/startCase";
import isEmpty from "lodash/isEmpty";

import { API } from "src/views/settings/api";
import { API as CommonAPI } from "src/views/components/api";


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = error => reject(error);
  });
}

export default {
  name: "AccountSettings",
  components: {
    PhoneNumberManageCard,
    EmailManageCard,
    NewPaymentMethodCard,
    ListingSettings,
    ConnectWithStripeButton,
    CheckStripeEarningsButton,
    ChangePasswordModalView
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const previewVisible = ref(false);
    const previewImage = ref('');
    const handleCancel = () => {
      previewVisible.value = false;
    };

    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };


    const controls = reactive({
      loading: {
        accountDetails: false,
        paymentMethods: false,
        uploadLogo: false,
        uploadCover: false,
        saveWhiteLabel: false
      },
      editKeys: {
        basic: [],
        whiteLabel: []
      }, // Track edited account keys
      response: {
        whiteLabel: null
      },
      logoImages: [],
      coverImages: [],
      selectedPaymentMethod: null,
      key: {
        hostInfo: 0,
        phoneNumber: 0,
        paymentMethods: 0
      }
    })

    const visible = reactive({
      changePhoneNumber: false,
      changeEmail: false,
      changePassword: false,
      accountUpdate: false
    });
    const changeVisible = ref(false);
    const tab = reactive({
      active: "Account Info",
      items: ["Account Info", "Vehicle", "Document", "Payment Methods"]
    });
    const account = reactive({
      first_name: null,
      last_name: null,
      phone_number: null,
      email: null,
      org_name: null,
      white_label: {
        id: null,
        org_name: null,
        url_slug: null,
        logo_url: null,
        cover_url: null
      }
    });
    const paymentMethods = ref([]);
    const handleChange = k => {
      visible[k] = !visible[k];
    };

    const stripeConnectDashboardUrl = ref(null);
    const payoutSchedule = ref({});

    const getStripeConnectDashboardUrl = () => {
      API.getStripeConnectAccountDashboardUrl()
        .then(res => {
          console.log(JSON.stringify(res.data));
          if (res.data.login_link) {
            stripeConnectDashboardUrl.value = res.data.login_link;
            payoutSchedule.value = res.data.payout_schedule;
          }
        })
        .catch(error => {
          console.log(JSON.stringify(error.message));
        });
    };

    const parseWhiteLabelData = async (data) => {
      if (data.white_label) {
        account.org_name = data.white_label.org_name;
        account.white_label.id = data.white_label.id;
        account.white_label.org_name = data.white_label.org_name;
        account.white_label.url_slug = data.white_label.url_slug;
        account.white_label.logo_url = data.white_label.logo_url;
        account.white_label.cover_url = data.white_label.cover_url;
        if (account.white_label.logo_url) {
          controls.logoImages = [
            {
              uid: ctx.root.$shortUID(),
              name: "logo-image",
              status: 'done',
              url: await getAvatarURL(account.white_label.logo_url)
            }
          ];
        }
        if (account.white_label.cover_url) {
          controls.coverImages = [
            {
              uid: ctx.root.$shortUID(),
              name: "cover-image",
              status: 'done',
              url: await getAvatarURL(account.white_label.cover_url)
            }
          ];
        }
      }
    }

    const getAccountDetails = async () => {
      controls.loading.accountDetails = true;
      try {
        const { data } = await API.getAccountDetails();
        console.log("getAccountDetails", data);
        controls.response.whiteLabel = data;
        account.first_name = data.first_name;
        account.last_name = data.last_name;
        account.phone_number = data.phone_number;
        account.email = data.email;
        await parseWhiteLabelData(data);
      } catch (error) {
        console.log("getAccountDetails -> error: ", error);
      } finally {
        setTimeout(() => {
          controls.loading.accountDetails = false;
        }, 1500);
      }
    };

    const updateAccountDetails = async () => {
      let payload = {};
      controls.editKeys.basic.forEach(k => {
        const editValue = ctx.root.$deepGet(account, k);
        payload[k] = editValue
      })
      controls.loading.accountDetails = true;
      try {
        const { data } = await API.updateAccountDetails(payload);
        account.first_name = data.first_name;
        account.last_name = data.last_name;
        account.phone_number = data.phone_number;
        account.email = data.email;
        await parseWhiteLabelData(data);
      } catch (error) {
        ctx.root.$openNotification(
          "Update Account Info",
          error.message,
          10,
          "info"
        );
      } finally {
        controls.editKeys.basic = [];
        setTimeout(() => {
          controls.loading.accountDetails = false;
        }, 1500);
      }
    }

    const updateEditKeys = (editKey, section = "basic") => {
      if (!controls.editKeys[section].includes(editKey)) {
        controls.editKeys[section].push(editKey);
      }
    }

    const listAllPaymentMethods = async () => {
      controls.loading.paymentMethods = true;
      controls.key.paymentMethods += 1;
      try {
        const { data } = await API.listAllPaymentMethods();
        paymentMethods.value = data;
      } catch (error) {
        console.log("listAllPaymentMethods -> error: ", error);
      } finally {
        controls.selectedPaymentMethod = null;
        setTimeout(() => {
          controls.loading.paymentMethods = false;
        }, 500);
      }
    };

    const scrollToSection = anchor => {
      document
        .getElementById(anchor)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const createWhiteLabelDetails = async () => {
      let payload = {
        org_name: account.white_label.org_name,
        url_slug: account.white_label.url_slug,
        logo_url: account.white_label.logo_url,
        cover_url: account.white_label.cover_url
      };
      if (isEmpty(payload)) {
        return
      }
      controls.loading.saveWhiteLabel = true;
      try {
        const { data } = await API.createWhiteLabelDetails(payload);
        await parseWhiteLabelData(data);
        controls.editKeys.whiteLabel = [];
        ctx.root.$openNotification(
          "Create White-label",
          "White-label settings have been saved",
          10,
          "success"
        );
      } catch (error) {
        ctx.root.$openNotification(
          "Create White-label Error",
          error.message,
          10,
          "info"
        );
      } finally {
        controls.loading.saveWhiteLabel = false;
      }
    }

    const updateWhiteLabelDetails = async () => {
      let payload = {};
      controls.editKeys.whiteLabel.forEach(k => {
        const editValue = ctx.root.$deepGet(account, `white_label.${k}`)
        payload[k] = editValue
      })
      if (isEmpty(payload)) {
        return
      }
      controls.loading.saveWhiteLabel = true;
      try {
        const { data } = await API.updateWhiteLabelDetails(payload);
        await parseWhiteLabelData(data);
        controls.editKeys.whiteLabel = [];
        ctx.root.$openNotification(
          "Update Profile",
          "White-label settings have been updated",
          10,
          "success"
        );
      } catch (error) {
        ctx.root.$openNotification(
          "Update Profile Error",
          error.message,
          10,
          "info"
        );
      } finally {
        controls.loading.saveWhiteLabel = false;
      }
    }

    const navToWhiteLabelSample = () => {
      window.open(process.env.VUE_APP_FRONTEND_BASE_URL + "/login/parqay", "_blank");
    }

    const getLoginPageURL = () => {
      return process.env.VUE_APP_FRONTEND_BASE_URL + (account.white_label.url_slug ?
        `/login/${account.white_label.url_slug}` : "/tenant")
    }

    const handleFileChange = async ({ file }, type) => {
      console.log("handleFileChange > file: ", file)
      if (file.status === "removed") {
        if (type === "logo") {
          controls.logoImages = [];
          account.white_label.logo_url = null;
          if (!controls.editKeys.whiteLabel.includes('logo_url')) {
            controls.editKeys.whiteLabel.push('logo_url')
          }
        }

        if (type === "cover") {
          controls.coverImages = [];
          account.white_label.cover_url = null;
          if (!controls.editKeys.whiteLabel.includes('cover_url')) {
            controls.editKeys.whiteLabel.push('cover_url')
          }
        }
        return
      }

      if (type === "logo") {
        controls.loading.uploadLogo = true;
      }

      if (type === "cover") {
        controls.loading.uploadCover = true;
      }

      const s3_path = "white-label/" + shortUID() + "/" + file.name;
      const file_url = await uploadFileToS3({ path: s3_path, fileBlob: file.originFileObj });
      console.log("uploadFileToS3 >> resp: ", file_url);

      if (type === "logo") {
        account.white_label.logo_url = s3_path;
        controls.logoImages = [
          {
            uid: ctx.root.$shortUID(),
            name: file.name,
            status: 'done',
            url: file_url
          }
        ];
        if (!controls.editKeys.whiteLabel.includes('logo_url')) {
          controls.editKeys.whiteLabel.push('logo_url')
        }
        controls.loading.uploadLogo = false;
      }

      if (type === "cover") {
        account.white_label.cover_url = s3_path;
        controls.coverImages = [
          {
            uid: ctx.root.$shortUID(),
            name: file.name,
            status: 'done',
            url: file_url
          }
        ];
        if (!controls.editKeys.whiteLabel.includes('cover_url')) {
          controls.editKeys.whiteLabel.push('cover_url')
        }
        controls.loading.uploadCover = false;
      }
    }

    const updateDefaultPaymentMethod = async paymentMethod => {
      controls.loading.paymentMethods = true;
      paymentMethods.value.forEach(p => {
        if (p.id === paymentMethod.id) {
          p.is_default = true;
        } else {
          p.is_default = false;
        }
      });
      try {
        await CommonAPI.updateDefaultPaymentMethod(paymentMethod.id);
      } catch (error) {
        console.log("updateDefaultPaymentMethod -> error: ", error);
        return;
      } finally {
        listAllPaymentMethods();
      }
    };

    const deletePaymentMethod = async paymentMethod => {
      controls.loading.paymentMethods = true;
      paymentMethods.value.forEach((v, i) => {
        if (v.id === paymentMethod.id) {
          paymentMethods.value.splice(i, 1);
        }
      });
      try {
        await CommonAPI.deletePaymentMethod(paymentMethod.id);
      } catch (error) {
        console.log("deletePaymentMethod -> error: ", error);
        return;
      } finally {
        listAllPaymentMethods();
      }
    };

    onMounted(() => {
      getAccountDetails();
      listAllPaymentMethods();
      getStripeConnectDashboardUrl();
    });

    nextTick(() => {
      const hashRoute = route.hash.replace("#", "");
      if (hashRoute === "check-earnings") {
        scrollToSection("check-earnings");
      }
      if (hashRoute === "white-label") {
        scrollToSection("white-label");
      }
      if (hashRoute === "listing-settings") {
        scrollToSection("listing-settings");
      }
    });

    return {
      controls,
      visible,
      account,
      paymentMethods,
      changeVisible,
      tab,
      previewVisible,
      previewImage,
      stripeConnectDashboardUrl,
      payoutSchedule,
      listAllPaymentMethods,
      updateDefaultPaymentMethod,
      deletePaymentMethod,
      getStripeConnectDashboardUrl,
      handleChange,
      handleFileChange,
      navToWhiteLabelSample,
      getAccountDetails,
      updateAccountDetails,
      updateEditKeys,
      createWhiteLabelDetails,
      updateWhiteLabelDetails,
      getLoginPageURL,
      handleCancel,
      handlePreview,
      startCase,
      window,
      console
    };
  }
};
</script>
<template>
  <div class="px-10 py-6">
    <div class="flex justify-between py-2 border-b mb-6">
      <h3 class="font-medium text-2xl text-dark-purple my-auto">
        Account Settings
      </h3>
    </div>
    <div :key="`settings-host-info-${controls.key.hostInfo}`"
      class="flex flex-col bg-white rounded-lg shadow-sm py-4 border">
      <div class="flex items-center space-x-2 border-b pb-4 pl-4">
        <span class="font-medium text-base text-black">
          Host Information
        </span>
        <a-icon type="reload" :spin="controls.loading.accountDetails" class="cursor-pointer" style="color: blue;"
          @click="getAccountDetails()" />
      </div>
      <div class="py-6 grid gap-x-8 gap-y-4 grid-cols-2 px-6">
        <label-item label="First Name">
          <a-input v-model="account.first_name" placeholder="First Name" size="large"
            @change="updateEditKeys('first_name')">
            <svg-icon slot="prefix" icon-class="user-solid" class="h-4 w-4 text-gray-400" />
          </a-input>
        </label-item>
        <label-item label="Last Name">
          <a-input v-model="account.last_name" placeholder="Last Name" size="large" @change="updateEditKeys('last_name')">
            <svg-icon slot="prefix" icon-class="user-solid" class="h-4 w-4 text-gray-400" />
          </a-input>
        </label-item>
        <label-item label="Email">
          <a-input v-model="account.email" placeholder="Email" size="large" @change="updateEditKeys('email')" @click="e => {
            handleChange('changeEmail');
            e.target.blur();
          }
            ">
            <svg-icon slot="prefix" icon-class="mail" class="h-4 w-4 text-gray-400" />
            <a-icon slot="suffix" type="edit" style="color: blue;" @click="handleChange('changeEmail')" />
          </a-input>
        </label-item>
        <label-item label="Phone Number">
          <a-input v-model="account.phone_number" placeholder="Phone Number" size="large"
            @change="updateEditKeys('phone_number')" @click="e => {
              handleChange('changePhoneNumber');
              e.target.blur();
            }
              ">
            <svg-icon slot="prefix" icon-class="phone" class="h-4 w-4 text-gray-400" />
            <a-icon slot="suffix" type="edit" style="color: blue;" @click="handleChange('changePhoneNumber')" />
          </a-input>
        </label-item>
      </div>
      <div class="bg-white flex justify-between py-4 px-6">
        <a-button @click="handleChange('changePassword')">
          Change Password
        </a-button>
        <a-button type="primary" :loading="controls.loading.accountDetails" :disabled="controls.loading.accountDetails"
          @click="updateAccountDetails(account)">
          {{ controls.loading.accountDetails ? "" : "Save" }}
        </a-button>
      </div>
      <a-divider id="white-label">White-label</a-divider>
      <div class="white-label-desc px-6">
        <p class="text-sm text-center text-gray-500 font-normal">Parqay Dashboard provides in-house white-label solution
          to allow our hosts integrate one of a kind management
          power into their own services. Customizable host listings landing page and login page help enterprise/start-ups
          build management
          portal / marketplace faster than ever. <a-button type="primary" size="small"
            @click="navToWhiteLabelSample()">View sample
          </a-button>
        </p>
      </div>
      <div class="py-6 grid gap-x-8 gap-y-4 grid-cols-2 px-6">
        <label-item label="Company / Organization Name">
          <a-input v-model="account.white_label.org_name" placeholder="e.g. Parqyt Inc." size="large" @change="(e) => {
            console.log(e);
            if (account.white_label.org_name !== $deepGet(controls, 'response.whiteLabel.white_label.org_name')) {
              if (!controls.editKeys.whiteLabel.includes('org_name')) {
                controls.editKeys.whiteLabel.push('org_name')
              }
            } else {
              if (controls.editKeys.whiteLabel.includes('org_name')) {
                const targetIndex = controls.editKeys.whiteLabel.findIndex(k => k === 'org_name');
                controls.editKeys.whiteLabel.splice(targetIndex, 1)
              }
            }
          }">
            <svg-icon slot="prefix" icon-class="briefcase" class="h-4 w-4 text-gray-400" />
          </a-input>
        </label-item>
        <label-item label="Login Page URL">
          <a-input v-model="account.white_label.url_slug" addon-before="dashboard.parqay.com/login/" placeholder="parqay"
            size="large" @change="(e) => {
              console.log(e);
              if (account.white_label.url_slug !== $deepGet(controls, 'response.whiteLabel.white_label.url_slug')) {
                if (!controls.editKeys.whiteLabel.includes('url_slug')) {
                  controls.editKeys.whiteLabel.push('url_slug')
                }
              } else {
                if (controls.editKeys.whiteLabel.includes('url_slug')) {
                  const targetIndex = controls.editKeys.whiteLabel.findIndex(k => k === 'url_slug');
                  controls.editKeys.whiteLabel.splice(targetIndex, 1)
                }
              }
            }">
            <svg-icon slot="prefix" icon-class="link" class="h-4 w-4 text-gray-400" />
          </a-input>
          <a slot="desc" class="text-sm font-medium"
            :class="(!$deepGet(account, 'white_label.id') || controls.editKeys.whiteLabel.length > 0) ? 'pointer-events-none text-gray-500' : 'text-blue-500'"
            @click="window.open(getLoginPageURL(), '_blank')">{{
              getLoginPageURL()
            }}</a>
        </label-item>
        <label-item label="Company / Organization Logo" class="white-label-logo">
          <a-upload :file-list="controls.logoImages" list-type="picture-card" :custom-request="() => { }"
            @preview="handlePreview" @change="(info) => handleFileChange(info, 'logo')">
            <div v-if="controls.logoImages.length == 0">
              <a-icon :type="controls.loading.uploadLogo ? 'loading' : 'plus-circle'"
                :theme="controls.loading.uploadLogo ? 'outlined' : 'twoTone'" />
              <div style="margin-top: 8px">Upload</div>
            </div>
            <div v-else>
              <a-icon :type="controls.loading.uploadLogo ? 'loading' : 'edit'"
                :theme="controls.loading.uploadLogo ? 'outlined' : 'twoTone'" />
              <div style="margin-top: 8px">Update</div>
            </div>
          </a-upload>
          <a-tag slot="desc" color="orange">SVG preferable</a-tag>
        </label-item>
        <label-item label="Login Cover Image" class="white-label-cover">
          <a-upload :file-list="controls.coverImages" list-type="picture-card" :custom-request="() => { }"
            @preview="handlePreview" @change="(info) => handleFileChange(info, 'cover')">
            <div v-if="controls.coverImages.length == 0">
              <a-icon :type="controls.loading.uploadCover ? 'loading' : 'plus-circle'"
                :theme="controls.loading.uploadCover ? 'outlined' : 'twoTone'" />
              <div style="margin-top: 8px">Upload</div>
            </div>
            <div v-else>
              <a-icon :type="controls.loading.uploadCover ? 'loading' : 'edit'"
                :theme="controls.loading.uploadCover ? 'outlined' : 'twoTone'" />
              <div style="margin-top: 8px">Update</div>
            </div>
          </a-upload>
          <a-tag slot="desc" color="orange">WebP / PNG preferable</a-tag>
        </label-item>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img style="width: 100%" :src="previewImage" />
        </a-modal>
      </div>
      <div class="bg-white flex justify-center space-x-2 py-4 px-8">
        <a-button v-if="$deepGet(account, 'white_label.id')" type="primary" size="large"
          :loading="controls.loading.saveWhiteLabel" :disabled="controls.editKeys.whiteLabel.length < 1"
          class="text-sm w-1/3 max-w-xs" @click="updateWhiteLabelDetails()">
          Update
        </a-button>
        <a-button v-else type="primary" size="large" :loading="controls.loading.saveWhiteLabel"
          :disabled="controls.editKeys.whiteLabel.length < 1" class="text-sm w-1/3 max-w-xs"
          @click="createWhiteLabelDetails()">
          Create
        </a-button>
        <a-button v-if="controls.editKeys.whiteLabel.length > 0" type="link" icon="reload"
          @click="() => { getAccountDetails(); controls.key.hostInfo += 1 }"></a-button>
      </div>
    </div>

    <div class="bg-white rounded-lg py-4 mt-6 border shadow-sm">
      <div class="flex items-center space-x-2 border-b pb-4 pl-4 mb-0">
        <span class="font-medium text-base text-black">
          Payment
        </span>
        <a-icon type="reload" :spin="controls.loading.paymentMethods" class="cursor-pointer" style="color: blue;"
          @click="listAllPaymentMethods()" />
      </div>
      <div class="flex justify-between rounded border-b">
        <div class="payment-methods w-1/2 rounded shadow-sm bg-white px-4 pb-4">
          <a-divider>Saved Payment Methods</a-divider>
          <div v-if="paymentMethods && paymentMethods.length > 0 && !controls.loading.paymentMethods"
            class="flex flex-col space-y-4">
            <div v-for="(v, i) in paymentMethods" :key="`payment-method-${i}`" class="flex flex-col">
              <div class="rounded bg-parqay-primary hover:bg-blue-500 cursor-pointer py-4 px-4 flex justify-between"
                @click="() => (controls.selectedPaymentMethod = v)">
                <div class="flex items-center">
                  <span class="text-sm text-white font-semibold">{{ startCase(v.brand) }} {{ v.last4 }} - {{ v.name
                  }}</span>
                </div>
                <div class="flex items-center space-x-2">
                  <a-tag v-if="v.is_default" color="geekblue">
                    Default
                  </a-tag>
                  <a-tag color="orange" class="payment-method-tag">{{ startCase(v.funding) }}</a-tag>
                </div>
              </div>
              <div v-if="$deepGet(controls.selectedPaymentMethod, 'id') == v.id
                " class="flex justify-between pt-4 pb-2">
                <div v-if="v.is_default">
                  <p class="bg-blue-100 text-blue-600 text-xs px-4 py-2">Could not edit default payment method, try
                    switching default payment method before
                    editing</p>
                </div>
                <template v-else>
                  <div>
                    <a-button type="danger" @click="deletePaymentMethod(v)">
                      Delete
                    </a-button>
                  </div>
                  <div class="flex space-x-4 items-center text-parqay-primary">
                    <span class="text-sm">Make Default</span>
                    <a-switch class="text-white border border-white" @change="checked => {
                      updateDefaultPaymentMethod(v);
                    }
                      " />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-else class="px-4 py-2">
            <p v-if="controls.loading.paymentMethods" class="bg-blue-100 text-blue-600 text-center space-y-2 py-8">
              <a-icon type="loading" />
            </p>
            <p v-else class="bg-blue-100 text-blue-600 text-center space-y-2 py-8">
              <span class="block text-sm font-medium">No payment method found</span>
              <span class="block text-xs">Use "+ Add Payment Method" on the right to create new payment method</span>
            </p>
          </div>
        </div>
        <new-payment-method-card :key="controls.key.paymentMethods" class="w-1/2 px-7 pb-7 pt-6"
          @refresh-list="listAllPaymentMethods()" />
      </div>
      <div class="payment-methods flex justify-between rounded">
        <div class="flex flex-col w-1/2 rounded shadow-sm bg-white px-4 pb-4">
          <a-divider>
            Payout Schedule
          </a-divider>
          <div v-if="stripeConnectDashboardUrl" class="flex justify-center items-center space-x-4 py-3">
            <div class="
              font-medium
              rounded-full
              px-3
              py-1
              bg-white
              border border-parqay-primary
              text-parqay-primary
            ">
              <span class="border-r border-parqay-primary pr-2">On Hold</span>
              <span class="pl-2"> {{ payoutSchedule.delay_days }} Days</span>
            </div>
            <div class="
              font-medium
              rounded-full
              px-3
              py-1
              border border-parqay-primary
              text-parqay-primary
            ">
              <span class="capitalize">{{ payoutSchedule.interval }} Transfer</span>
            </div>
          </div>
        </div>
        <div id="check-earnings" class="
          w-1/2
          rounded
          shadow-sm
          bg-white
          border-l
          flex
          flex-col
          items-center
          justify-center
          px-4
          pb-4
        ">
          <a-divider>
            Payout Settings
          </a-divider>
          <connect-with-stripe-button v-if="!stripeConnectDashboardUrl" @refresh="getStripeConnectDashboardUrl" />
          <check-stripe-earnings-button v-else :login-link="stripeConnectDashboardUrl" layout="h" />
        </div>
      </div>
    </div>

    <listing-settings id="listing-settings" class="mt-6" />

    <a-modal v-model="visible.changePhoneNumber" :footer="false" class="bg-gray-400">
      <phone-number-manage-card :key="controls.key.phoneNumber" class="px-6 py-6" modal-mode @verified="({ phone_number }) => {
        account.phone_number = phone_number;
        updateEditKeys('phone_number');
        visible.changePhoneNumber = false;
        controls.key.phoneNumber += 1;
      }" />
    </a-modal>

    <a-modal v-model="visible.changeEmail" :footer="false" class="bg-gray-400">
      <email-manage-card class="px-6 py-6" modal-mode />
    </a-modal>

    <a-modal :key="controls.key.updatePassword" v-model="visible.changePassword" :footer="false" class="bg-gray-400">
      <change-password-modal-view @dismiss="() => visible.changePassword = false" />
    </a-modal>

    <a-modal v-model="visible.accountUpdate" :footer="false">
      <div class="flex justify-center">
        <svg-icon icon-class="success" />
      </div>
      <h2 class="text-black text-3xl mt-4 text-center">
        Success
      </h2>
      <p class="w-full text-xl text-gray-800 text-center">
        Your Account Information has been updated!
      </p>
      <div class="flex justify-center">
        <button class="
            bg-blue-600
            text-xl
            hover:bg-blue-500
            text-white
            font-bold
            py-2
            px-6
            rounded
          " @click="handleChange('accountUpdate')">
          OK
        </button>
      </div>
    </a-modal>
  </div>
</template>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
<style lang="scss" scoped>
.white-label-desc {
  .ant-btn {
    @apply text-xs ml-1;
  }
}

.white-label-logo::v-deep {
  .ant-upload-list-picture-card-container {
    width: 300px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 300px;
  }

  .ant-upload-list-item-image {
    object-fit: contain !important;
  }
}

.white-label-cover::v-deep {

  .ant-upload-picture-card-wrapper {
    .ant-upload-list-picture-card-container {
      width: 100%;
      height: 200px;
    }

    .ant-upload-list-picture-card .ant-upload-list-item {
      width: 100%;
      height: 200px;
    }

    @apply flex space-x-2;
  }
}

.payment-methods::v-deep {
  .ant-divider-inner-text {
    @apply text-sm text-black;
  }

  .payment-method-tag {
    @apply mx-0;
  }
}
</style>