<script>
import VehicleInfo from "src/views/listings/new/car-rentals/components/VehicleInfo";
import ImagesUploader from "src/views/components/ImagesUploader";
import Dropdown from "src/views/dropdowns/View";
import { ref, reactive } from "@vue/composition-api";
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';

export default {
  name: "CarDetails",
  components: {
    VehicleInfo,
    ImagesUploader,
    Dropdown
  },
  setup(props, ctx) {
    const deepGet = ctx.root.$deepGet;
    const openNotification = ctx.root.$openNotification;
    const transmissionType = ref("automatic");
    const instantOrRequestRental = ref("instant");
    const carTitle = ref(null);
    const spaceType = ref("driveway");
    const vehicle = ref(null);
    const spaceTypeOptions = ref([
      { id: 0, name: "Driveway" },
      { id: 1, name: "Residential Garage" },
      { id: 2, name: "Parking Garage" },
      { id: 3, name: "Parking Lot" }
    ]);
    const amenities = reactive({
      features: {
        GPS: false,
        "EV Convertible": false,
        "USB Input": false,
        bluetooth: false,
        "Toll Pass": false,
        Carplay: false,
        "Android Auto": false,
        Sunroof: false
      },
      vehicle_size: {
        Compact: false,
        "Mid-Sized Sedan": false,
        SUV: false,
        Truck: false,
        Luxury: false,
        Oversized: false
      },
      power: {
        "Gas Powered": false,
        "Electric Vehicle": false,
        Hybrid: false
      }
    });
    const nearbyLandmarks = ref("");
    const howDoGuestsEnterInstruction = ref("");
    const imagesUploader = ref(null);
    const imagesUrls = ref([]);
    const selectLocationType = (i, m) => {
      const spaceTypeName = lowerCase(m.name);
      spaceType.value = spaceTypeName;
    };
    const addVehicle = v => {
      console.log("addVehicle", v);
      vehicle.value = v;
      carTitle.value = `${v.make} ${v.model} (${v.year})`;
    };
    const getAllImages = () => {
      imagesUrls.value = imagesUploader.value.getAllAddedImagesUrls();
      console.log("imagesUrls", imagesUrls.value);
    };
    const next = () => {
      getAllImages();
      const verified = verifyFields();
      if (verified) {
        // Note: normal payload storage uses Vuex Store
        ctx.emit("payload-update", "vehicle-details", assemblePayloads());
        ctx.emit("next");
      }
    };
    const assemblePayloads = () => {
      // Backend is using snake-case as its framework is Python
      return {
        transmission_type: transmissionType.value,
        instant_or_request: instantOrRequestRental.value,
        space_type: spaceType.value,
        title: carTitle.value,
        images_urls: imagesUrls.value,
        vehicle_id: vehicle.value.id,
        // Convert Proxy back to Object, use Lodash method - cloneDeep
        amenities: cloneDeep(amenities.features),
        vehicle_size: cloneDeep(amenities.vehicle_size),
        power: cloneDeep(amenities.power),
        how_do_guests_enter_instruction: howDoGuestsEnterInstruction.value,
        nearby_landmarks: nearbyLandmarks.value
      };
    };
    const verifyAmenities = () => {
      var verified = true;
      forOwn(amenities, (v, k) => {
        var checkedCount = 0;
        forOwn(v, (vv, kk) => {
          if (vv) {
            checkedCount += 1;
          }
        });
        if (checkedCount === 0) {
          openNotification(
            "Vehicle Amenities",
            `At least one item should be checked in ${startCase(k)} category`,
            10,
            "warning"
          );
          verified = false;
          return false;
        }
      });
      return verified;
    };
    const verifyFields = () => {
      // return true;
      if (!deepGet(vehicle.value, "id")) {
        openNotification(
          "Vehicle Verification",
          `You must verify the vehicle using Smartcar or by entering information manually`,
          10,
          "warning"
        );
        return false;
      }

      if (!carTitle.value) {
        openNotification(
          "Vehicle Listing Title",
          `Listing title is required`,
          10,
          "warning"
        );
        return false;
      }

      if (!verifyAmenities()) {
        return false;
      }

      if (imagesUrls.value.length < 1) {
        openNotification(
          "Vehicle Images",
          `At least 1 image is required`,
          10,
          "warning"
        );
        return false;
      }

      if (!howDoGuestsEnterInstruction.value) {
        openNotification(
          "Vehicle Information",
          `"How do guests get to your vehicle" is required field`,
          10,
          "warning"
        );
        return false;
      }

      if (!nearbyLandmarks.value) {
        openNotification(
          "Vehicle Information",
          `"Nearby Landmarks" is required field`,
          10,
          "warning"
        );
        return false;
      }

      return true;
    };
    return {
      vehicle,
      transmissionType,
      instantOrRequestRental,
      carTitle,
      spaceType,
      spaceTypeOptions,
      amenities,
      nearbyLandmarks,
      howDoGuestsEnterInstruction,
      imagesUploader,
      imagesUrls,
      next,
      selectLocationType,
      addVehicle,
      startCase,
      lowerCase
    };
  }
};
</script>
<template>
  <div class="car-details flex flex-col py-2">
    <div class="flex items-center justify-between border-b px-4">
      <div />
      <div class="">
        <h2 class="my-3 text-center text-base">
          {{ $t("carListing.tell") }}
        </h2>
      </div>
      <button class="px-4 py-2 bg-parqay-primary text-white rounded" @click="next">
        {{ $t("carListing.continue") }}
      </button>
    </div>
    <div class="flex">
      <div class="w-3/5 border-r">
        <vehicle-info @added-vehicle="addVehicle" />
        <div class="grid grid-cols-2 px-6 gap-4 border-t pt-4">
          <div class="">
            <label class="text-base font-medium">{{
              $t("carListing.title")
            }}</label>
            <a-input
              v-model="carTitle"
              size="large"
              placeholder="Ex: BMW - X5"
              class="my-3"
            />
          </div>
          <div class="flex flex-col space-y-2">
            <label class="text-base font-medium">{{
              $t("carListing.transTitle")
            }}</label>
            <a-radio-group v-model="transmissionType">
              <a-radio-button
                v-for="(transType, i) in $t('default.carListing.transType', {
                  returnObjects: true
                })"
                :key="`trans-type-${i}`"
                :value="lowerCase(transType)"
              >
                {{ transType }}
              </a-radio-button>
            </a-radio-group>
          </div>
          <div class="flex flex-col space-y-2">
            <label class="text-base font-medium">
              {{ $t("carListing.instant") }}
            </label>
            <a-radio-group v-model="instantOrRequestRental">
              <a-radio-button
                v-for="(leaseType, i) in $t('default.carListing.option', {
                  returnObjects: true
                })"
                :key="`lease-type-${i}`"
                :value="lowerCase(leaseType)"
              >
                {{ leaseType }}
              </a-radio-button>
            </a-radio-group>
          </div>
          <div class="flex flex-col space-y-2">
            <span class="text-base font-medium mb-2">
              {{ $t("carListing.spaceInfo") }}
            </span>
            <label>{{ $t("carListing.kind") }}</label>
            <dropdown
              :model="spaceTypeOptions"
              :width="'full'"
              @dropdown-select="selectLocationType"
            />
          </div>
        </div>
        <div class="border-t mt-4">
          <div class="grid grid-cols-4 gap-4 mt-4">
            <div
              v-for="(amenityOptions, amenityName, i) in amenities"
              :key="`amenity-group-${i}`"
              class="flex flex-col items-center space-y-2"
            >
              <span class="text-base font-medium">{{
                startCase(amenityName)
              }}</span>
              <div class="flex flex-col space-y-2">
                <a-checkbox
                  v-for="(v, k, j) in amenityOptions"
                  :key="`amenity-option-${j}`"
                  v-model="amenityOptions[k]"
                >
                  {{ k }}
                </a-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="flex py-4 px-6 space-x-4 border-t mt-4">
          <div class="flex flex-col w-1/2 space-y-2">
            <span class="text-base font-medium">Nearby Landmarks</span>
            <a-textarea
              v-model="nearbyLandmarks"
              placeholder="Provide descriptions for nearby landmarks"
              :auto-size="{ minRows: 6, maxRows: 6 }"
            />
          </div>
          <div class="flex flex-col w-1/2 space-y-2">
            <label class="text-base font-medium">{{
              $t("carListing.how")
            }}</label>
            <a-textarea
              v-model="howDoGuestsEnterInstruction"
              placeholder="This will show up in “Getting There” section in car search. Enter any specific guidance to assistant guests enter your vehicle"
              :auto-size="{ minRows: 6, maxRows: 6 }"
            />
          </div>
        </div>
      </div>
      <div
        class="
          w-2/5
          flex flex-col
          p-4
          mx-4
          my-2
          shadow-sm
          border
          bg-white
          rounded-lg
        "
      >
        <h2 class="mb-2 text-center">
          {{ $t("carListing.photo") }}
        </h2>
        <p class="text-center add-photos-description">
          Please upload at least 9 photos of your vehicle. Our AI detection
          system will check if they belong to the same vehicle make and model.
          We cannot list unmatched vehicle.
        </p>
        <div class="flex-grow-0 justify-center items-center">
          <images-uploader ref="imagesUploader" recognize="vehicle" />
        </div>
      </div>
    </div>
    <hr />
    <div class="flex items-center justify-end mb-5 py-2 px-4">
      <button class="px-4 py-2 bg-parqay-primary text-white rounded" @click="next">
        {{ $t("carListing.continue") }}
      </button>
    </div>
  </div>
</template>

<style lang="css" scoped>
.car-details /deep/ .ant-input-number {
  width: 70px;
}
.car-details /deep/ .ant-checkbox-wrapper {
  margin-left: 0;
}
</style>
