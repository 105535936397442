<script>
import { reactive } from "@vue/composition-api";
export default {
    props: {
        validationErrors: {
            type: Array,
            default: () => {
                return new Array(10)
            }
        }
    },
  setup() {
    const controls = reactive({});
    return {
      controls,
    };
  },
};
</script>
<template>
  <smooth-scroll
    :ops="{
      vuescroll: {},
      scrollPanel: {},
      rail: {},
      bar: {
        size: '6px',
        background: 'rgba(231, 70, 148, 1)',
        onlyShowBarOnScroll: false,
        opacity: 0.6,
      },
    }"
  >
    <div
      class="flex py-2 px-4 space-x-2"
      style="max-width: 500px"
    >
      <div
        v-for="(error, i) in validationErrors"
        :key="i"
        class="border rounded-full p-1 cursor-pointer"
      >
        <div class="error-chip flex items-center space-x-1 rounded-full py-1 px-4 font-medium text-xs bg-pink-500 text-white hover:bg-pink-600 hover:shadow-inner">
          <svg-icon
            icon-class="solid-exclamation-circle"
            class="w-5 h-5"
          />
          <span class="whitespace-nowrap">{{ error.message }}</span>
        </div>
      </div>
    </div>
  </smooth-scroll>
</template>
<style lang="scss" scoped>
.error-chip {
  outline: 3px solid white;
}
// Fix smooth scrollbar horizontal scroll
::v-deep .__panel {
  overflow-x: hidden !important;
}
</style>