<script>
import { reactive } from "@vue/composition-api";
import PricingDatePicker from "./DatePicker.vue";
import EventPricing from "src/views/listings/detail/rates/EventPricing.vue";
import GeneralRuleTable from "./components/GeneralPricingRulesTable.vue";

export default {
  components: {
    PricingDatePicker,
    EventPricing,
    GeneralRuleTable
  },
  props: {
    form: {
      type: Object,
      require: true
    }
  },
  setup(props, ctx) {

    const controls = reactive({
      expand: {
        selectedEvent: false
      }
    })

    return {
      controls
    };
  },
};
</script>
<template>
  <div class="flex justify-center w-full max-w-screen-md py-6">
    <div class="w-full rounded bg-white shadow-sm border">
      <div class="border-b px-4 py-3 flex justify-between">
        <div class="flex items-center space-x-2">
          <span class="text-black text-base font-medium my-auto">
            Saved Rules
          </span>
          <a-tag color="geekblue">{{ form.rates.rules.filter(rule => rule.is_active).length }} in use</a-tag>
        </div>
      </div>

      <div v-if="form.events" class="flex w-full">
        <pricing-date-picker :events="form.events" />
        <event-pricing :controls="controls" :form="form" embed />
      </div>
      <general-rule-table v-else :tiers="form.tiers" :form="form" @refresh="$emit('refresh')" />
    </div>
  </div>
</template>