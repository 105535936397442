<script>
import { ref, watch, onMounted, nextTick, onBeforeUnmount } from "@vue/composition-api";
import dayjs from "dayjs";
import ApproveDenyTag from "src/views/reservations/components/ApproveDenyTag";
import ValidationSectionHeader from "src/views/reservations/components/ValidationSectionHeader";
import ListingForm from "src/views/reservations/components/forms/Listing.vue";
import TypeForm from "src/views/reservations/components/forms/Type";
import IntervalsForm from "src/views/reservations/components/forms/Intervals";
import TenantForm from "src/views/reservations/components/forms/Tenant";
import DocumentsForm from "src/views/reservations/components/forms/Documents";
import { API } from "src/views/reservations/api";
import isEmpty from "lodash/isEmpty";

export default {
  name: "BaseDetails",
  components: {
    ApproveDenyTag,
    ValidationSectionHeader,
    ListingForm,
    TypeForm,
    IntervalsForm,
    TenantForm,
    DocumentsForm,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    controls: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    const cancelReason = ref(null);
    watch(
      () => props.form.title,
      (val) => {
        console.log("props.form.title >> ", val);
        var updatedTitle;
        if (isEmpty(val)) {
          updatedTitle = "New Reservation";
        } else {
          updatedTitle = val;
        }
        store.commit("bulkUpdateScheduleToAddList", ["title", updatedTitle]);
      }
    );
    const resetReservationType = () => {
      props.form.type = null;
    };
    const scrollToSection = (anchor) => {
      const el = document.getElementById(anchor);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    };
    const getFirstLetter = (text, defaultLetter = "") => {
      if (!text || text.length <= 0) {
        return defaultLetter;
      }
      return text[0].toUpperCase();
    };
    const validateFields = () => {
      let _errors = [];
      if (!props.form.title) {
        _errors.push({
          type: "title",
          message: "Please enter the reservation title",
        });
      }
      if (!props.form.type) {
        _errors.push({
          type: "type",
          message: "Please select reservation type",
        });
      }
      if (props.form.intervals.length === 0) {
        _errors.push({
          type: "booking",
          message: "At least one time interval should be chosen",
        });
      }
      if (props.form.type !== "car-rental") {
        if (props.form.smartcar.prompt_tenant) {
          if (!props.form.smartcar.prompt_reason) {
            _errors.push({
              type: "tenant",
              message:
                "A reason must be entered to request for SmartCar virtual key",
            });
          }
        } else {
          // if (!props.form.vehicle) {
          //   _errors.push({
          //     type: "tenant",
          //     message: "Vehicle details must be entered",
          //   });
          // }
        }
        if (props.form.smartcar.trunk_delivery) {
          if (!props.form.smartcar.delivery_address) {
            _errors.push({
              type: "tenant",
              message:
                "A reachable address must be provided so your tenant can enter it as their address prefix",
            });
          }
        }
      }
      if (!props.form.listing) {
        _errors.push({
          type: "listing",
          message: "A listing must be associated with this reservation",
        });
      }
      if (!props.form.tenant) {
        _errors.push({
          type: "tenant",
          message: "A tenant must be associated with this reservation",
        });
      }
      if (_errors.length > 1) {
        const errorsCount = _errors.length;
        _errors.push({
          type: "header",
          message: `Multiple Errors (${errorsCount})`,
        });
      } else if (_errors.length == 1) {
        _errors.push({
          type: "header",
          message: _errors[0].message,
        });
      }
      props.controls.errors = _errors;
    };
    const createReservation = async () => {
      validateFields();
      if (!isEmpty(props.controls.errors)) {
        return;
      }
      props.controls.loading.createReservation = true;
      try {
        const resp = await API.create(props.form);
        console.log("resp: ", resp.data);
        if (resp.data) {
          ctx.root.$router.push("/dashboard/reservations");
        }
        props.controls.loading.createReservation = false;
        ctx.root.$openNotification(
          "Create Reservation",
          "New reservation has been created manually",
          5,
          "info"
        );
      } catch (error) {
        ctx.root.$openNotification("Create Reservation", error.message, 10, "error");
      }
    };
    const sendEmailReminder = async () => {
      props.controls.loading.pendingReminder = true;
      let resp;
      try {
        resp = await API.sendEmailReminder(props.form.id);
      } catch (error) {
        console.log("sendEmailReminder error: ", error);
        props.controls.loading.pendingReminder = false;
        return;
      }
      console.log("sendEmailReminder resp: ", resp.message);
      props.controls.loading.pendingReminder = false;
      ctx.root.$openNotification(
        `Email reminder has been sent to: ${ctx.root.$deepGet(
          props.form,
          "tenant.email"
        )}`,
        "",
        5,
        "info"
      );
    };
    const releaseSpaces = async (listing_id, reservation_id) => {
      ctx.root.$toggleLoadingIndicator(true);
      try {
        await API.releaseReservationBookedIntervals({
          reservation_id,
          listing_id,
          reason: cancelReason.value
        });
      } catch (error) {
        console.log("releaseSpaces >> error:", error);
        return;
      } finally {
        ctx.root.$toggleLoadingIndicator(false);
      }
      ctx.root.$router.push("/dashboard/reservations");
    };

    const handleShowDocuments = () => {
      nextTick(() => {
        scrollToSection('documents');
        ctx.root.$openDocSlideCard(
          'tenant',
          props.controls.identity_verification
        );
      })
    }

    let createdAtTimerHandle = null;
    onMounted(() => {
      ctx.root.$bus.$on("scroll-and-show-reservation-documents", handleShowDocuments);
      if (props.controls.createMode && !props.form.type) {
        props.controls.visible.typeSelect = true;
      }
      if (props.controls.createMode || props.controls.editMode) {
        createdAtTimerHandle = setInterval(() => {
          props.form.created_at = dayjs();
        }, 1000);
      }
    });
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("scroll-and-show-reservation-documents", handleShowDocuments);
      if (createdAtTimerHandle) {
        clearInterval(createdAtTimerHandle);
      }
    });
    return {
      cancelReason,
      scrollToSection,
      resetReservationType,
      getFirstLetter,
      createReservation,
      sendEmailReminder,
      releaseSpaces,
      handleShowDocuments,
      isEmpty,
      console
    };
  },
};
</script>
<template>
  <div class="reservation-base-details bg-white shadow overflow-scroll sm:rounded-lg divide-y-2 divide-parqay-primary">
    <div class="px-4 pt-5 pb-2 sm:px-6 flex justify-between items-center">
      <div class="flex items-center pb-2 space-x-4">
        <div>
          <button class="
              inline-flex
              items-center
              p-2
              border border-transparent
              rounded-full
              shadow-sm
              text-white
              bg-parqay-primary
              hover:bg-blue-500
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
            " @click="
              $historyGoBack({
                $router,
                defaultPath: '/dashboard/reservations',
              })
              ">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
        </div>
        <div>
          <span v-if="controls.createMode" class="text-lg leading-6 font-medium text-gray-900 m-2">
            Create Manual Reservation
          </span>
          <span v-else class="text-lg leading-6 font-medium text-gray-900 m-2">
            Reservation Details
          </span>
        </div>
      </div>
      <div v-if="controls.createMode" class="reservation-actions flex space-x-4 items-center">
        <validation-section-header :validation-errors="controls.errors.filter((e) => e.type == 'header')" />
        <a-button type="primary" :loading="controls.loading.createReservation" size="large" @click="createReservation">
          Create Reservation
        </a-button>
      </div>
      <div v-else class="reservation-actions flex space-x-3 items-center">
        <a-tag v-if="$deepGet(form, 'status') === 'pending'" color="orange">
          <div class="flex items-center">
            <span class="text-black font-medium pr-2">Status: </span>
            <span class="px-2 border-r border-orange-400">Pending</span>
            <a-button size="small" type="primary" class="ml-2" :loading="controls.loading.pendingReminder"
              @click="sendEmailReminder">
              Remind Tenant
            </a-button>
          </div>
        </a-tag>
        <div v-if="$deepGet(form, 'status') === 'request'" class="flex space-x-6 items-center py-2">
          <span class="font-medium text-sm tracking-wide text-gray-900">Actions</span>
          <approve-deny-tag :form="form" expand @refresh="$emit('refresh')" />
        </div>
        <a-tag v-if="$deepGet(form, 'status') === 'complete'" color="green">
          Complete
        </a-tag>
        <a-tag v-if="$deepGet(form, 'status') === 'canceled'" color="pink">
          Canceled
        </a-tag>
        <a-tag v-if="$deepGet(form, 'status') === 'denied'" color="red">
          Denied
        </a-tag>
        <a-popconfirm v-if="!['request', 'canceled', 'denied'].includes($deepGet(form, 'status'))
          " ok-text="Confirm Release" cancel-text="Cancel" @confirm="() =>
    releaseSpaces(
      $deepGet(form, 'listing.id'),
      form.id
    )
    " @cancel="() => { }">
          <template #title>
            <div class="flex flex-col space-y-2">
              <span class="text-sm text-black">Are you sure you want to proceed?</span>
              <span class="text-sm text-red-500 font-medium">
                This action will release tenant spaces and cannot be undone.
              </span>
            </div>
            <div class="flex py-2">
              <a-textarea v-model="cancelReason" placeholder="Enter reason of cancellation."
                :auto-size="{ minRows: 3, maxRows: 3 }" />
            </div>
          </template>
          <a-button type="danger" size="large" class="release-space-btn">
            Release Space & Cancel
          </a-button>
        </a-popconfirm>
        <a-popconfirm v-if="!['request', 'canceled', 'denied'].includes($deepGet(form, 'status'))
          " title="This action will result in a full refund of the captured amount to the tenant."
          ok-text="Confirm Refund" cancel-text="Cancel" @confirm="() => {

          }
            " @cancel="() => { }">
          <a-button size="large" class="release-space-btn">
            Refund
          </a-button>
        </a-popconfirm>
        <!-- <button v-if="!controls.editMode"
          class="px-4 py-2 border text-md font-medium rounded-md shadow-sm text-white bg-parqay-primary hover:bg-blue-500 focus:outline-none"
          @click="() => (controls.editMode = true)">
          Edit
        </button>
        <button v-else class="
            px-4
            py-2
            border border-parqay-primary
            text-md
            font-medium
            rounded-md
            shadow-sm
            text-parqay-primary
            bg-white
            hover:text-blue-800
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white
          " @click="() => (controls.editMode = false)">
          Save
        </button> -->
      </div>
    </div>
    <div class="space-y-16 mb-16">
      <div class="grid grid-cols-4 mx-8 my-8 gap-2">
        <div class="flex space-x-2 items-center">
          <div class="flex flex-shrink-0 items-center justify-center h-12 w-12 rounded bg-parqay-primary cursor-pointer"
            @click="scrollToSection('listings')">
            <span class="text-white text-5xl text-center">
              {{ getFirstLetter(form.title, "R") }}
            </span>
          </div>

          <div v-if="controls.editMode" class="flex flex-col space-y-2">
            <a-popover v-if="controls.editingTitle" :visible="!isEmpty(controls.errors.filter((e) => e.type == 'title'))"
              placement="bottom">
              <template #content>
                <span>Please enter the reservation title</span>
              </template>
              <span>Reservation Title</span>
              <a-input v-model="form.title" type="large" placeholder="Ex. Evanston N Garage" class="text-sm">
                <template #suffix>
                  <button v-show="form.title"
                    class="text-xs px-2 py-1 rounded bg-parqay-primary text-white hover:bg-blue-500"
                    @click="() => (controls.editingTitle = false)">
                    Save
                  </button>
                </template>
              </a-input>
            </a-popover>
            <div v-else class="flex space-x-2">
              <span class="text-base font-medium text-parqay-primary text-center">{{
                form.title
              }}</span>
              <button class="text-xs px-2 py-1 rounded bg-parqay-primary text-white hover:bg-blue-500"
                @click="() => (controls.editingTitle = true)">
                Edit
              </button>
            </div>
          </div>
          <span v-else class="text-base font-medium text-center">{{ $deepGet(form, "title", "Loading...") }}
          </span>
        </div>
        <div class="flex items-center space-x-2">
          <div class="h-12 w-12 rounded bg-parqay-primary my-auto cursor-pointer" @click="scrollToSection('tenants')">
            <img class="h-full w-full object-contain" src="/static/img/dashboard/db-r-parking-details-contact.svg"
              alt="" />
          </div>
          <div class="flex flex-col space-y-2">
            <a v-if="controls.createMode" class="m-0 h-full text-base font-medium text-parqay-primary"
              @click="scrollToSection('tenants')">{{ form.tenant ? form.tenant.name : "Setup Tenant" }}</a>
            <a v-else class="m-0 h-full text-base font-medium" @click="scrollToSection('tenants')">{{ $deepGet(form,
              "tenant.name", "Loading...") }}</a>
            <div v-if="controls.identity_verification" class="flex items-center">
              <a-tag v-if="$deepGet(controls, 'identity_verification.status') === 'verified'
                " color="green">Verified</a-tag>
              <a-tag v-else-if="$deepGet(controls, 'identity_verification.status') === 'unverified'
                " color="pink">Unverified</a-tag>
              <a-tag v-else>{{
                $deepGet(controls, "identity_verification.status")
              }}</a-tag>
              <a-button type="primary" size="small" class="text-xs"
                :disabled="$deepGet(controls, 'identity_verification.status') === 'unverified'"
                @click="handleShowDocuments()">Show Documents</a-button>
            </div>
          </div>
        </div>
        <!-- Undetermined Type State -->
        <a-popover v-if="!form.type && controls.createMode"
          :visible="!isEmpty(controls.errors.filter((e) => e.type == 'type'))" placement="bottom">
          <template #content>
            <span>Please select reservation type</span>
          </template>
          <div class="flex space-x-2 rounded-xl border hover:border-indigo-500 px-2 py-2 cursor-pointer"
            @click="() => (controls.visible.typeSelect = true)">
            <svg-icon icon-class="add-outline"
              class="h-8 w-8 rounded my-auto text-parqay-primary transform hover:scale-125 ease-in-out duration-200" />
            <div class="my-auto w-28">
              <p class="m-0 h-full text-base font-medium hover:text-parqay-primary">
                Choose Type
              </p>
            </div>
          </div>
        </a-popover>
        <div v-else-if="!form.type" class="flex space-x-2 rounded-xl border px-2 py-3 cursor-pointer">
          <svg-icon icon-class="circular-loader" class="h-8 w-8 rounded my-auto text-parqay-primary animate-spin" />
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium hover:text-parqay-primary">
              Loading...
            </p>
          </div>
        </div>
        <!-- Start - Parking Type Indicator -->
        <div v-if="form.type == 'parking'" class="flex space-x-2 relative">
          <svg-icon icon-class="parking-type" class="h-12 w-12 rounded my-auto" />
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium">
              Parking Reservation
            </p>
          </div>
          <div v-if="controls.createMode" class="absolute w-8 h-full right-2" @click="resetReservationType">
            <div class="flex items-center h-full">
              <div class="bg-blue-500 hover:bg-blue-500 rounded-full flex cursor-pointer">
                <svg-icon icon-class="close" class="w-6 h-6 m-1 text-white" />
              </div>
            </div>
          </div>
        </div>
        <!-- End - Parking Type Indicator -->
        <!-- Start - Stays Type Indicator -->
        <div v-if="form.type == 'stay'" class="flex space-x-2 relative">
          <svg-icon icon-class="stay-type" class="h-12 w-12 rounded my-auto" />
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium">
              Stays Reservation
            </p>
          </div>
          <div v-if="controls.createMode" class="absolute w-8 h-full right-2" @click="resetReservationType">
            <div class="flex items-center h-full">
              <div class="bg-blue-500 hover:bg-blue-500 rounded-full flex cursor-pointer">
                <svg-icon icon-class="close" class="w-6 h-6 m-1 text-white" />
              </div>
            </div>
          </div>
        </div>
        <!-- End - Stays Type Indicator -->
        <!-- Start - Coworking Type Indicator -->
        <div v-if="form.type == 'coworking'" class="flex space-x-2 relative">
          <div class="border-parqay-primary rounded-lg w-12 h-12 flex" style="border-width: 3px">
            <svg-icon icon-class="coworking-type" class="h-auto w-auto m-2 text-black" />
          </div>
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium">
              Coworking Reservation
            </p>
          </div>
          <div v-if="controls.createMode" class="absolute w-8 h-full right-2" @click="resetReservationType">
            <div class="flex items-center h-full">
              <div class="bg-blue-500 hover:bg-blue-500 rounded-full flex cursor-pointer">
                <svg-icon icon-class="close" class="w-6 h-6 m-1 text-white" />
              </div>
            </div>
          </div>
        </div>
        <!-- End - Coworking Type Indicator -->
        <!-- Start - Car Rentals Type Indicator -->
        <div v-if="form.type == 'car-rental'" class="flex space-x-2 relative">
          <svg-icon icon-class="car-rental-type" class="h-12 w-12 rounded my-auto" />
          <div class="my-auto w-28">
            <p class="m-0 h-full text-base font-medium">
              Car Rentals Reservation
            </p>
          </div>
          <div v-if="controls.createMode" class="absolute w-8 h-full right-2" @click="resetReservationType">
            <div class="flex items-center h-full">
              <div class="bg-blue-500 hover:bg-blue-500 rounded-full flex cursor-pointer">
                <svg-icon icon-class="close" class="w-6 h-6 m-1 text-white" />
              </div>
            </div>
          </div>
        </div>
        <!-- End - Car Rentals Type Indicator -->
        <div class="flex flex-col space-y-2 items-center my-auto">
          <div class="my-auto">
            <p class="m-0 font-bold">
              Confirmation #
            </p>
          </div>
          <div class="my-auto font-medium text-parqay-primary">
            <p class="m-0">
              {{ $deepGet(form, "id", "Loading...") }}
            </p>
          </div>
        </div>
        <type-form :form="form" :controls="controls" />
      </div>
      <div class="bg-white shadow overflow-hidden sm:rounded-lg mx-8 pb-4">
        <div class="flex justify-between items-center bg-white px-4 py-2 space-x-2 border-b border-gray-200 sm:px-6">
          <h3 class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap">
            Booking Details
          </h3>
          <validation-section-header :validation-errors="controls.errors.filter((e) => e.type == 'booking')" />
        </div>
        <div>
          <intervals-form :form="form" :controls="controls" />
          <div class="space-y-4 sm:space-y-5">
            <!-- BOOKING - GUESTS SLOT -->
            <slot name="guests" />

            <!-- BOOKING - DRIVERS SLOT -->
            <slot name="drivers" />


            <!-- BOOKING - START OF Event SECTION -->
            <div v-if="form.event"
              class="sm:grid sm:grid-cols-6 sm:gap-4 border-t sm:items-start sm:border-gray-200 sm:pt-5 px-5 py-2">
              <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Associated Event
              </p>
              <div class="mt-1 sm:mt-0 sm:col-span-5">
                <a-tag class="py-2 text-xs font-medium" color="geekblue">
                  {{
                    form.event
                  }}
                </a-tag>
              </div>
            </div>
            <!-- BOOKING - END OF Event SECTION -->

            <!-- BOOKING - START OF CREATED AT SECTION -->
            <div v-if="$deepGet(form, 'created_at.$date')"
              class="sm:grid sm:grid-cols-6 sm:gap-4 border-t sm:items-start sm:border-gray-200 sm:pt-5 px-5 py-2">
              <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Created At
              </p>
              <div class="mt-1 sm:mt-0 sm:col-span-5">
                <div class="grid grid-cols-4 gap-4">
                  <a-tag class="py-2 text-xs font-medium">
                    {{
                      $deepGet(form, "created_at.$date") ? $formatDate(form.created_at.$date, "YYYY-MM-DD h:mm a") : "N/A"
                    }}
                  </a-tag>
                </div>
              </div>
            </div>
            <!-- BOOKING - END OF CREATED AT SECTION -->
          </div>
        </div>
      </div>

      <!-- START OF LISTING DETAILS SECTION -->
      <listing-form :form="form" :controls="controls" />
      <!-- END OF LISTING DETAILS SECTION -->

      <!-- START OF TENANT DETAILS SECTION -->
      <tenant-form :controls="controls" :form="form" />
      <!-- END OF TENANT DETAILS SECTION -->

      <documents-form :controls="controls" :form="form" />

      <!-- START OF VEHICLE DETAILS SECTION -->
      <slot name="vehicle-details" />
      <!-- END OF VEHICLE DETAILS SECTION -->
    </div>
    <payment-method-drawer-host :form="form" />
  </div>
</template>
<style lang="scss" scoped>
.reservation-base-details::v-deep {
  .ant-input {
    @apply flex items-center;
  }

  .ant-calendar-range-picker-separator {
    // line-height: 1.5rem;
    // font-size: 1.5rem;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-mask-image: url("/static/img/Icons/arrow-circle-right.svg");
    mask-image: url("/static/img/Icons/arrow-circle-right.svg");
    @apply bg-parqay-primary;
  }
}
</style>

<style lang="scss" scoped>
.reservation-actions::v-deep {
  .ant-tag {
    @apply py-2 px-5 text-sm;
  }
}
</style>

<style lang="scss" scoped>
::v-deep {
  .release-space-btn {
    @apply text-sm;
  }
}
</style>
