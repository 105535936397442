<script>
import { computed, watch, ref, reactive } from "@vue/composition-api";
import VehicleInfo from "src/views/listings/new/car-rentals/components/VehicleInfo";
import ValidationSectionHeader from "src/views/reservations/components/ValidationSectionHeader";

import { API } from "src/views/reservations/api";

export default {
  name: "VehiclesForm",
  components: {
    VehicleInfo,
    ValidationSectionHeader
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    controls: {
      type: Object,
      required: true
    },
    embedMode: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    const deepGet = ctx.root.$deepGet;
    const shouldShowVehicleInfo = computed(() => {
      if (props.form.smartcar.prompt_tenant) {
        if (!deepGet(props.form, "vehicle.id")) {
          return false;
        }
      }
      return true;
    });
    const updateReservationVehicle = async vehicle => {
      props.form.vehicle = vehicle;
      if (props.controls.createMode) {
        return;
      }
      let resp;
      try {
        resp = await API.updateReservationVehicle(props.form.id, vehicle);
      } catch (error) {
        console.log("updateReservationVehicle >> error: ", error);
      }
      console.log("updateReservationVehicle >> success: ", resp);
    };
    const deleteReservationVehicle = async vehicleId => {
      if (props.controls.createMode) {
        return;
      }
      let res;
      try {
        res = await API.deleteReservationVehicle(props.form.id, vehicleId);
      } catch (error) {
        console.log("deleteReservationVehicle => error", error);
      }
      console.log("deleteReservationVehicle", res);
    };
    return {
      shouldShowVehicleInfo,
      updateReservationVehicle,
      deleteReservationVehicle
    };
  }
};
</script>

<template>
  <div>
    <div
      v-if="form.type == 'parking'"
      class="sm:grid sm:grid-cols-6 sm:gap-4 border-t sm:items-start sm:border-gray-200 sm:pt-5 px-5"
    >
      <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Vehicle
      </p>
      <div class="mt-1 sm:mt-0 sm:col-span-5">
        <div class="grid grid-cols-2 gap-4 pt-4">
          <template v-if="form.type != 'car-rental'">
            <div class="flex justify-between">
              <div class="flex flex-col">
                <span class="text-sm font-medium text-gray-500"
                  >Prompt guest to connect Smartcar</span
                >
                <span class="text-sm font-light text-gray-500"
                  >If choose NO, you may help your guest to connect Smartcar by
                  panel below</span
                >
              </div>
              <a-switch
                v-model="form.smartcar.prompt_tenant"
                :disabled="!controls.editMode"
              />
            </div>
            <div class="flex flex-col space-y-2">
              <span class="text-sm font-medium text-gray-500"
                >Reason for requesting Smartcar virtual key:</span
              >
              <a-textarea
                v-if="controls.createMode"
                v-model="form.smartcar.prompt_reason"
                :disabled="!form.smartcar.prompt_tenant"
                placeholder="Your guest will see the reason when they open your invitation link"
                :auto-size="{ minRows: 2, maxRows: 4 }"
              />
              <p v-else class="rounded-lg border bg-gray-50 px-2 py-2">
                {{ $deepGet(form, "smartcar.prompt_reason", "N/A") }}
              </p>
            </div>
            <div class="flex justify-between">
              <div class="flex flex-col">
                <span class="text-sm font-medium text-gray-500"
                  >Allow tenant to use vehicle trunk delivery</span
                >
                <span class="text-sm font-light text-gray-500"
                  >You need to help tenant deliver their items into vehicle's
                  trunk if enabled</span
                >
              </div>
              <a-switch
                v-model="form.smartcar.trunk_delivery"
                :disabled="!controls.editMode"
              />
            </div>
            <div class="flex flex-col space-y-2">
              <span class="text-sm font-medium text-gray-500"
                >Reachable address of your property</span
              >
              <a-textarea
                v-if="controls.editMode"
                v-model="form.smartcar.delivery_address"
                :disabled="!form.smartcar.trunk_delivery"
                placeholder='Your tenant may enter their delivery address as: "your reachable address" + "their vehicle #"'
                :auto-size="{ minRows: 2, maxRows: 4 }"
              />
              <p v-else class="rounded-lg border bg-gray-50 px-2 py-2">
                {{ $deepGet(form, "smartcar.delivery_address", "N/A") }}
              </p>
            </div>
            <div class="flex justify-between">
              <div class="flex flex-col">
                <span class="text-sm font-medium text-gray-500"
                  >Provide services for vehicle</span
                >
                <span class="text-sm font-light text-gray-500"
                  >Your tenant will be prompt to choose a service for their
                  vehicle</span
                >
              </div>
              <a-switch disabled />
            </div>
            <div class="flex items-center">
              <div>
                <a-tag color="orange">
                  Available Soon
                </a-tag>
              </div>
            </div>
          </template>
          <vehicle-info
            v-if="shouldShowVehicleInfo"
            class="col-span-2"
            title="Or, you can help your guest to connect"
            :model="form.vehicle"
            :on-behalf-of="$deepGet(form, 'tenant.id')"
            @added-vehicle="vehicle => updateReservationVehicle(vehicle)"
            @delete-vehicle="deleteReservationVehicle"
          >
          </vehicle-info>
          <div
            v-else
            class="flex flex-col items-center justify-center col-span-2 border rounded-xl py-24 space-y-2"
          >
            <span class="text-xl text-gray-500"
              >No vehicle has been entered yet by tenant</span
            >
            <p class="text-gray-400">
              Vehicle will appear here when your tenant successfully connect
              their Smartcar
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="['stay', 'coworking'].includes(form.type)"
      class="bg-white shadow overflow-hidden sm:rounded-lg mx-8 pb-4"
    >
      <div
        class="flex justify-between items-center bg-white px-4 py-2 space-x-2 border-b border-gray-200 sm:px-6"
      >
        <h3
          class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap"
        >
          Combo Listing
        </h3>
        <validation-section-header
          :validation-errors="controls.errors.filter(e => e.type == 'booking')"
        />
      </div>
      <div
        class="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5 px-5"
      >
        <div class="flex flex-col space-y-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Guest Vehicle Rental
          </p>
          <div class="flex items-center justify-center space-x-2">
            <span>Enable?</span>
            <a-switch />
          </div>
        </div>
        <div class="mt-1 sm:mt-0 sm:col-span-5">
          <div class="grid grid-cols-2 gap-4 pt-4">
            <template v-if="form.type != 'car-rental'">
              <div class="flex justify-between">
                <div class="flex flex-col">
                  <span class="text-sm font-medium text-gray-500"
                    >Prompt guest to rent out car</span
                  >
                  <span class="text-sm font-light text-gray-500"
                    >If choose YES, you will need to setup a time range for
                    rental</span
                  >
                </div>
                <a-switch
                  v-model="form.smartcar.prompt_tenant"
                  :disabled="!controls.editMode"
                />
              </div>
              <div class="flex flex-col space-y-2">
                <span class="text-sm font-medium text-gray-500"
                  >Message for guest (if guest-rental is enabled):</span
                >
                <a-textarea
                  v-if="controls.createMode"
                  v-model="form.smartcar.prompt_reason"
                  :disabled="!form.smartcar.prompt_tenant"
                  placeholder="Your guest will see the reason when they open your invitation link"
                  :auto-size="{ minRows: 2, maxRows: 4 }"
                />
                <p v-else class="rounded-lg border bg-gray-50 px-2 py-2">
                  {{ $deepGet(form, "smartcar.prompt_reason", "N/A") }}
                </p>
              </div>
              <div class="flex justify-between items-center col-span-2">
                <div class="flex flex-col">
                  <span class="text-sm font-medium text-gray-500"
                    >Allow tenant to use vehicle trunk delivery</span
                  >
                  <span class="text-sm font-light text-gray-500"
                    >You need to help tenant deliver their items into vehicle's
                    trunk if enabled</span
                  >
                </div>
                <div class="flex items-center space-x-4">
                  <a-date-picker
                    placeholder="Start Date"
                    @change="(value, dateString) => {}"
                  >
                    <template #suffixIcon>
                      <a-icon type="calendar" theme="twoTone" />
                    </template>
                  </a-date-picker>
                  <a-date-picker
                    placeholder="End Date"
                    @change="(value, dateString) => {}"
                  >
                    <template #suffixIcon>
                      <a-icon type="calendar" theme="twoTone" />
                    </template>
                  </a-date-picker>
                </div>
              </div>
              <div class="flex justify-between items-center">
                <span class="text-sm font-medium text-gray-500"
                  >Max Mileage</span
                >
                <a-input-number
                  :formatter="value => `${value} mi`"
                  :parser="value => value.replace('mi', '')"
                  :min="1"
                  default-value="200"
                />
              </div>
              <div class="flex justify-between items-center">
                <span class="text-sm font-medium text-gray-500"
                  >Max Income for Guest</span
                >
                <a-input-number
                  :formatter="
                    value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                  :min="1"
                  default-value="20"
                />
              </div>
              <div class="flex justify-between items-center">
                <span class="text-sm font-medium text-gray-500"
                  >Pay Guest after Rental?</span
                >
                <a-switch />
              </div>
              <div class="flex justify-between items-center">
                <span class="text-sm font-medium text-gray-500"
                  >Host Overdue Penalty (per day)</span
                >
                <a-input-number
                  :formatter="
                    value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                  :min="1"
                  :default-value="5"
                />
              </div>
            </template>
            <vehicle-info
              v-if="shouldShowVehicleInfo"
              class="col-span-2"
              title="Or, you can help your guest to connect"
              :model="form.vehicle"
              :on-behalf-of="$deepGet(form, 'tenant.id')"
              @added-vehicle="vehicle => updateReservationVehicle(vehicle)"
              @delete-vehicle="deleteReservationVehicle"
            />
            <div
              v-else
              class="flex flex-col items-center justify-center col-span-2 border rounded-xl py-24 space-y-2"
            >
              <span class="text-xl text-gray-500"
                >No vehicle has been entered yet by tenant</span
              >
              <p class="text-gray-400">
                Vehicle will appear here when your tenant successfully connect
                their Smartcar
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
