<script>
import { onMounted, reactive, ref } from "@vue/composition-api";
import PaymentTable from "./components/Table.vue";
import CreditCardList from "./components/CreditCardList.vue";
import NewPaymentMethodCard from "./components/NewPaymentMethodCard.vue";
import TransactionCard from "./components/TransactionCard.vue";
import BalanceSummary from "./components/BalanceSummary.vue";

export default {
  components: {
    PaymentTable,
    CreditCardList,
    NewPaymentMethodCard,
    TransactionCard,
    BalanceSummary
  },
  setup() {
    const tableRef = ref(null);
    const controls = reactive({
      loading: {
        table: false,
        refresh: false,
        loadMore: false,
      },
      amount: 0,
      visible: {
        success: false,
        error: false,
      },
      resp: {
        success: {
          message: null,
          stripeResp: null,
        },
        error: {
          message: null,
        },
      },
      paymentKey: new Date().getTime(),
    });
    const form = ref({
      has_more: false,
      data: [],
    });
    const getTransactionsHistory = async () => {
      // ...
    };
    onMounted(() => {
    });
    return {
      tableRef,
      controls,
      form,
      getTransactionsHistory,
    };
  },
};
</script>
<template>
  <div class="flex flex-col items-center justify-center w-full px-10 pb-20">
    <div class="flex items-center space-x-4 w-full mt-6 pb-5">
      <span class="text-black text-lg font-semibold">
        Payments & Transactions
      </span>
      <a-icon v-if="tableRef" type="reload" :spin="tableRef.controls.loading" style="font-size: 0.95rem;"
        @click.stop="tableRef.fetchData()" />
    </div>
    <balance-summary class="mb-1 w-full" />
    <a-divider>Transactions History</a-divider>
    <div class="bg-white border shadow-sm rounded-lg overflow-hidden w-full">
      <div v-if="false" class="mx-auto flex flex-col space-y-4 w-4/5 xl:w-1/2">
        <div class="flex items-center justify-center space-x-6 border-b py-6">
          <div class="flex items-center space-x-3">
            <svg-icon icon-class="dollar" class="w-12 h-12 text-blue-500" />
            <span class="text-2xl font-semibold">Amount</span>
          </div>
          <a-input-number v-model="controls.amount" :step="100" :min="0" :formatter="(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            " :parser="(value) => value.replace(/\$\s?|(,*)/g, '')" size="large" @change="() => { }" />
        </div>
        <div class="flex items-center justify-between">
          <span class="text-xl font-medium">Pay with</span>
          <credit-card-list />
        </div>
        <div class="flex flex-col space-y-4">
          <new-payment-method-card :key="controls.paymentKey" :amount="controls.amount" @success="(payload) => {
            const { message, stripeResp } = payload;
            controls.visible.success = true;
            controls.resp.success.message = message;
            controls.resp.success.stripeResp = stripeResp;
            controls.amount = 0;
            controls.paymentKey = new Date().getTime() + 1;
          }
            " @payment-error="(message) => {
    controls.visible.error = true;
    controls.resp.error.message = message;
  }
    " @reset="() => {
    controls.amount = 0;
    controls.paymentKey = new Date().getTime() + 1;
  }
    " @error="(message) =>
    $openNotification({
      message: 'Error',
      description: message,
      duration: null,
      type: 'error',
    })
    " />
        </div>

        <div class="flex flex-col space-y-6 border-t py-6">
          <div class="flex space-x-4 items-center justify-center pb-2 cursor-pointer" @click="getTransactionsHistory()">
            <span class="text-center text-xl font-bold">
              Recent Transactions
            </span>
            <a-icon type="sync" :spin="controls.loading.refresh" />
          </div>

          <a v-if="form.data.length == 0"
            class="rounded-xl bg-gray-100 flex items-center justify-center text-lg px-8 py-6">
            {{
              controls.loading.refresh
              ? "Loading..."
              : "No more recent transactions"
            }}
          </a>

          <div class="flex flex-col space-y-6">
            <transaction-card v-for="(t, i) in form.data" :key="`recent-transaction-${i}`" :t="t" />
            <a-button v-if="form.has_more" size="large" :loading="controls.loading.loadMore" class="w-full" @click="
              getTransactionsHistory({
                ending_before: form.data[form.data.length - 1].id,
                has_more: true,
              })
              ">
              Load More
            </a-button>
          </div>
        </div>

        <a-modal v-model="controls.visible.success" :footer="null">
          <a-result status="success" title="Payment went through" :sub-title="controls.resp.success.message">
            <template #extra>
              <div class="flex flex-col space-y-6">
                <div class="flex flex-col space-y-2">
                  <span class="text-lg font-medium text-green-500">Captured Payment Method</span>
                  <span class="text-sm text-gray-500">Notice there are a few minutes delay before this transaction
                    could be shown in transaction history</span>
                </div>
                <transaction-card expanded :t="controls.resp.success.stripeResp" />
                <a-button type="primary" size="large" @click="() => (controls.visible.success = false)">
                  OK
                </a-button>
              </div>
            </template>
          </a-result>
        </a-modal>

        <a-modal v-model="controls.visible.error" :footer="null">
          <a-result status="error" title="Payment Error">
            <div class="desc">
              <p style="font-size: 16px">
                <strong>Error Body:</strong>
              </p>
              <p>
                <a-icon :style="{ color: 'red' }" type="close-circle" />
                <span class="pl-2">
                  {{ controls.resp.error.message }}
                </span>
              </p>
            </div>
            <template #extra>
              <a-button type="primary" size="large" @click="() => (controls.visible.error = false)">
                OK
              </a-button>
            </template>
          </a-result>
        </a-modal>
      </div>
      <payment-table ref="tableRef" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-input-number {
    font-size: 24px;
    height: 60px;
    width: 200px;
  }

  .ant-input-number-input-wrap {
    line-height: 60px;

    input {
      text-align: center;
    }
  }

  .ant-result {
    @apply sm:p-0;
  }
}
</style>
