<script>
import VehicleInfo from "src/views/listings/new/car-rentals/components/VehicleInfo.vue";
import { API } from "src/views/listings/api";
import startCase from 'lodash/startCase';

export default {
  name: "ListingForm",
  components: {
    VehicleInfo
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    controls: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const getListingDetails = async () => {
      console.log("getListingDetails: ", props.controls.listing.selected);
      var resp;
      resp = await API.details(props.controls.listing.selected.id);
      console.log("details >> ", resp);
      props.form.listing = resp.data;
    };
    return {
      getListingDetails,
      startCase,
      window
    };
  }
};
</script>

<template>
  <div v-if="form.type" id="listings" class="bg-white shadow overflow-hidden sm:rounded-lg mx-8 pb-4">
    <div class="bg-white px-4 pt-3 pb-1 border-b border-gray-200 sm:px-6">
      <div class="flex justify-between items-center">
        <h3 class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap">
          Listing Details
        </h3>
      </div>
    </div>
    <template v-if="form.type == 'parking'">
      <div class="grid grid-cols-2 gap-2 sm:items-start sm:border-gray-200 px-5 py-4">
        <div class="sm:grid sm:grid-cols-3 gap-4 items-center">
          <p class="block text-sm font-medium text-gray-700">
            Associate Listing
          </p>
          <a class="text-sm text-parqay-primary font-medium" @click="$openListingDetailsDrawer(form.review)">
            {{ $deepGet(form, "listing.summary.title") }}
          </a>
        </div>
        <div class="sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700">
            Price
          </p>
          <p class="text-sm text-gray-900 font-bold sm:col-span-2">
            {{ $formatCurrency($deepGet(form, "transaction.final_charge", 0)) }}
          </p>
        </div>
        <div class="col-span-2 sm:col-span-1 sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700">
            Address
          </p>
          <p class="mt-1 text-sm text-gray-900 font-bold sm:col-span-2">
            {{ $deepGet(form, "listing.summary.location.address") }}
          </p>
        </div>
        <div class="sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Interval
          </p>
          <div class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            <button type="button" class="
                px-4
                py-1
                border border-transparent
                text-sm
                font-base
                rounded-md
                shadow-sm
                text-white
                bg-parqay-primary
                hover:bg-blue-500
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              ">
              {{
    startCase($deepGet(form, "transaction.interval_mode", ""))
  }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="['stay', 'coworking'].includes(form.type)">
      <div class="grid grid-cols-2 gap-2 sm:items-start sm:border-gray-200 px-5 py-4">
        <div class="sm:grid sm:grid-cols-3 gap-4 items-center">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Associate Listing
          </p>
          <p class="mt-1 text-sm text-parqay-primary font-medium sm:mt-0 sm:col-span-2 pt-2">
            Nice Studio In Nice
          </p>
        </div>
        <div class="sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Price
          </p>
          <p class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            $100
          </p>
        </div>
        <div class="col-span-2 sm:col-span-1 sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Address
          </p>
          <p class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            3618 Evanston Ave N, Seattle, WA 98103, USA
          </p>
        </div>
        <div class="sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Interval
          </p>
          <div class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            <button type="button" class="
                inline-flex
                items-center
                px-2
                py-1
                border border-transparent
                text-sm
                font-base
                rounded-md
                shadow-sm
                text-white
                bg-parqay-primary
                hover:bg-blue-500
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              ">
              Per Night
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="form.type == 'car-rental'">
      <div class="grid grid-cols-2 gap-2 sm:items-start sm:border-gray-200 px-5 py-4">
        <div class="sm:grid sm:grid-cols-3 gap-4 items-center">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Associate Listing
          </p>
          <p class="mt-1 text-sm text-parqay-primary font-medium sm:mt-0 sm:col-span-2 pt-2">
            Lexus RX 2017
          </p>
        </div>
        <div class="sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Price
          </p>
          <p class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            $100
          </p>
        </div>
        <div class="col-span-2 sm:col-span-1 sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Vehicle Address
          </p>
          <p class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            3618 Evanston Ave N, Seattle, WA 98103, USA
          </p>
        </div>
        <div class="sm:grid sm:grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Interval
          </p>
          <div class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            <button type="button" class="
                inline-flex
                items-center
                px-2
                py-1
                border border-transparent
                text-sm
                font-base
                rounded-md
                shadow-sm
                text-white
                bg-parqay-primary
                hover:bg-blue-500
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              ">
              Per Day
            </button>
          </div>
        </div>
        <template>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500 my-auto">
              Mileage
            </dt>
            <dd class="my-auto col-span-2 grid grid-cols-2 gap-x-2">
              <span class="font-bold text-sm text-gray-900">5,600 Miles</span>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500 my-auto">
              Max Mileage
            </dt>
            <dd class="my-auto col-span-2 grid grid-cols-2 gap-x-2">
              <span class="font-bold text-sm text-gray-900">10,000 Miles</span>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <p class="text-sm font-medium text-gray-500">
              Collect Mileage Penalty
            </p>
            <div class="mt-1 text-sm text-gray-900 font-medium sm:-mt-1 sm:col-span-2">
              <div>
                <a-radio-group v-model="form.listing.mileage_penalty">
                  <a-radio-button v-for="(o, i) in controls.listing.mileagePenaltyOptions"
                    :key="`mileage-penalty-option-${i}`" :value="o.value">
                    {{ o.text }}
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500 my-auto">
              Extra Mileage
            </dt>
            <dd class="my-auto col-span-2 grid grid-cols-3 gap-x-2">
              <span class="font-bold text-sm text-red-500">0 Miles</span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Mileage penalty activated, this means if extra mileage is
                    used, driver’s payment method will be auto-charged and
                    deposit to your account</span>
                </template>
                <svg-icon icon-class="info" class="ml-2 w-5 h-5 text-parqay-primary" />
              </a-tooltip>
            </dd>
          </div>
        </template>
        <vehicle-info class="col-span-2" title="Connect Smartcar to grant your guest virtual key" :model="form.vehicle"
          @added-vehicle="vehicle => (form.vehicle = vehicle)">
          <template #info>
            <!-- <docs-dropdown
              class="w-full"
              :model="form.docs"
            /> -->
            <div>
              <button class="rounded bg-parqay-primary text-white px-2 py-2 text-md whitespace-nowrap"
                @click="$openDocSlideCard('tenant')">
                View Doc
              </button>
            </div>
          </template>
        </vehicle-info>
      </div>
    </template>
  </div>
</template>
