<script>
import { computed, watch, ref, reactive, onMounted } from "@vue/composition-api";

import Calendar from "src/views/reservations/components/Calendar";
import { API } from "src/views/reservations/api";
import dayjs from "dayjs";

export default {
  name: "IntervalsForm",
  components: {
    Calendar,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    controls: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    const shortUID = ctx.root.$shortUID;
    const formatInterval = ctx.root.$formatMomentForBackendUp;
    const intervalsCalendar = ref(null);
    const reservations = ref([]);
    const scheduleToAddList = ref([]);
    const getMaxIntervalMoment = () => {
      if (props.form.intervals.length > 0) {
        const mms = props.form.intervals.map((interval) => dayjs(interval.end));
        return dayjs.max(mms);
      }
      return dayjs();
    };
    const loadSchedulesToAddIfNeeded = () => {
      if (props.controls.createMode) {
        scheduleToAddList.value = store.getters.getScheduleToAddList();
        scheduleToAddList.value.forEach((v, i) => {
          props.form.intervals.push({
            id: v.id,
            start: formatInterval(dayjs(v.start)),
            end: formatInterval(dayjs(v.end)),
          });
        });
      }
    };
    const calendarFocusDate = computed(() => {
      if (props.form.intervals.length > 0) {
        const mms = props.form.intervals.map((interval) => dayjs(interval.start));
        return dayjs.min(mms);
      }
      return null;
    });
    const getRandomInt = (max) => {
      return Math.floor(Math.random() * max);
    };
    const colorRandomizer = () => {
      var pool = [
        {
          color: "white",
          bgColor: "#6B8BFD",
        },
        {
          color: "white",
          bgColor: "#FD9827",
        },
        {
          color: "white",
          bgColor: "#6740B4",
        },
        {
          color: "white",
          bgColor: "#50AE55",
        },
        {
          color: "white",
          bgColor: "#757575",
        },
        {
          color: "white",
          bgColor: "#4154B3",
        },
      ];

      return pool[getRandomInt(pool.length)];
    };
    const addNewInterval = () => {
      const startMM = getMaxIntervalMoment();
      const endMM = getMaxIntervalMoment().add(1, "days");
      console.log("addNewInterval >> ", startMM, endMM);
      const intervalId = shortUID();
      if (props.controls.createMode) {
        const colorD = colorRandomizer();
        const scheduleToAdd = {
          id: intervalId,
          calendarId: "1",
          title: "+ New Schedule (drag to fine-tune the reservation schedule)",
          category: "time",
          dueDateClass: "",
          start: startMM,
          end: endMM,
          color: colorD.bgColor,
          bgColor: "white",
          borderColor: colorD.bgColor,
        };
        // tuiCalendar.value.invoke("createSchedules", [scheduleToAdd]);
        // ctx.root.$store.commit("addScheduleToAddList", scheduleToAdd);
      }
      props.form.intervals.push({
        id: intervalId,
        start: formatInterval(startMM),
        end: formatInterval(endMM),
      });
      ctx.root.$bus.$emit("reservation-update-form-intervals", props.form.intervals);
    };
    const updateInterval = (value, interval) => {
      const [startMM, endMM] = value;
      console.log("updateInterval", formatInterval(startMM), formatInterval(endMM));
      const targetInterval = ctx.root.$deepGet(
        props.form.intervals.filter((v) => v.id === interval.id),
        "[0]"
      );
      if (targetInterval) {
        targetInterval.start = formatInterval(startMM);
        targetInterval.end = formatInterval(endMM);
      }
      ctx.root.$bus.$emit("reservation-update-form-intervals", props.form.intervals);
    };
    const deleteInterval = (i) => {
      console.log("delete -> ", props.form.intervals[i]);
      // ctx.root.$store.commit("deleteScheduleToAddFromList", {
      //   id: props.form.intervals[i].id,
      // });
      props.form.intervals.splice(i, 1);
      ctx.root.$bus.$emit("reservation-update-form-intervals", props.form.intervals);
    };
    const getRangeDefault = (interval) => {
      if (!interval.start && !interval.end) {
        return null;
      }
      return [dayjs(interval.start), dayjs(interval.end)];
    };
    onMounted(() => {
      API.list().then((res) => {
        console.log("reservation api lists:", res);
        if (res.data) {
          reservations.value = res.data;
        }
      });
      // loadSchedulesToAddIfNeeded();
    });
    return {
      intervalsCalendar,
      reservations,
      calendarFocusDate,
      addNewInterval,
      updateInterval,
      deleteInterval,
      getRangeDefault,
      window,
    };
  },
};
</script>

<template>
  <div class="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5 px-5 py-6">
    <div class="flex flex-col space-y-6 items-center justify-center">
      <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Intervals
      </p>
      <div class="flex space-x-2 items-center">
        <span class="text-xs font-medium text-parqay-primary">Show Calendar</span>
        <a-switch v-model="controls.showCalendar" checked-children="Yes" un-checked-children="No" />
      </div>
      <span v-if="controls.editMode" class="text-xs font-light">Parqay calendar will show both your existing
        reservations and to-add ones, showing such can help you determine the best time intervals for a
        reservation</span>
    </div>
    <div class="mt-1 sm:mt-0 sm:col-span-5">
      <calendar v-if="controls.showCalendar" ref="intervalsCalendar" :model="reservations" :focus-date="calendarFocusDate"
        embed-mode :tips="{
          title: 'To edit date/time range',
          content: ['Hold down mouse and drag the intervals you just defined, when released, the update will be saved'],
        }" class="border rounded-lg w-full overflow-hidden" />
      <div v-else class="grid grid-cols-2 gap-4">
        <div v-if="controls.editMode" class="flex col-span-2 space-x-2">
          <span class="text-base text-red-500 font-medium">*</span>
          <div>
            Notice: for <a-tag color="geekblue">
              manual
            </a-tag> reservation, you have to handle availabilities conflicts on your own. Use the calendar on the left
            to view existing
            reservations, system won't show warning if you select a time interval that conflicts with other
          </div>
        </div>
        <div v-if="controls.editMode"
          class="flex col-span-2 space-x-2 rounded-lg bg-gray-100 text-gray-500 hover:bg-blue-400 hover:text-white px-4 py-2 items-center cursor-pointer"
          @click="() => {
            // const r = $router.resolve({ name: 'tenant-home', query: { searchHeaderState: 'initial' } });
            // window.open(r.href, '_blank');
            window.open('https://parqay.com/search', '_blank');
          }
            ">
          <span class="font-medium">To automatically manage booking availabilities, block booking if unavailable, please
            let your tenant book your listing in marketplace search</span>
          <svg-icon icon-class="arrow-circle-right" class="w-6 h-6 text-parqay-primary" />
        </div>
        <template v-for="(interval, i) in form.intervals">
          <div v-if="controls.editMode" :key="`edit-interval-${i}`" class="flex space-x-2 items-center">
            <a-range-picker class="" :separator="''" :allow-clear="false" :default-value="getRangeDefault(interval)"
              :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" :placeholder="['Start Time', 'End Time']"
              size="large" :disabled="!controls.editMode"
              @change="(value, dateString) => updateInterval(value, interval)">
              <template #suffixIcon>
                <a-icon type="calendar" theme="twoTone" />
              </template>
            </a-range-picker>
            <div class="">
              <a-popconfirm title="Are you sure to delete this interval?" ok-text="Yes" cancel-text="No"
                @confirm="() => deleteInterval(i)" @cancel="() => { }">
                <div class="bg-gray-400 hover:bg-gray-600 rounded-full flex cursor-pointer">
                  <svg-icon icon-class="close" class="w-3 h-3 m-1 text-white" />
                </div>
              </a-popconfirm>
            </div>
          </div>
          <div v-else :key="`interval-${i}`"
            class="flex items-center space-x-2 justify-center text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
            <span>{{ $formatDate(interval.start.substr(0, 16)) }} </span>
            <svg-icon icon-class="arrow-circle-right" class="w-6 h-6 text-parqay-primary" />
            <span>{{ $formatDate(interval.end.substr(0, 16)) }} </span>
          </div>
        </template>
        <div v-if="controls.editMode">
          <button
            class="border border-parqay-primary hover:border-indigo-800 focus:outline-none text-parqay-primary hover:text-blue-800 font-semibold rounded py-2 px-12"
            @click="addNewInterval">
            + Add New Interval
          </button>
        </div>
      </div>
    </div>
  </div>
</template>