<script>
import { reactive } from "@vue/composition-api";
import { API } from "src/views/tenants/api";

export default {
  name: "TenantInviteForm",
  components: {},
  setup(props, ctx) {
    const form = reactive({
      first_name: null,
      last_name: null,
      address: null,
      email: null,
      invitation_message: null,
      invite_by: {
        email: true,
      },
    });

    const controls = reactive({
      step_index: 1,
      invite_mode_index: 0,
      result: null,
      loading: false,
    });

    const searchInviteTenant = async () => {
      controls.loading = true;
      let resp;
      try {
        resp = await API.inviteSearch({
          ...form,
        });
        console.log("searchInviteTenant, resp: ", resp);
        controls.result = resp.data.is_existed ? "existing-info" : "inviting-info";
        form.email = resp.data.email;
        form.first_name = resp.data.first_name;
        form.last_name = resp.data.last_name;
        controls.step_index += 1;
      } catch (error) {
        console.log("searchInviteTenant -> error: ", error);
      } finally {
        controls.loading = false;
      }
    };

    const confirmInviteTenant = async () => {
      controls.loading = true;
      let resp;
      try {
        resp = await API.inviteConfirm({
          ...form,
        });
        console.log("confirmInviteTenant, resp: ", resp);
        controls.result = "success";
      } catch (error) {
        console.log("confirmInviteTenant -> error: ", error);
      } finally {
        controls.loading = false;
        ctx.emit("refresh-tenants-list");
      }
    };

    const validateFields = () => {
      return (
        form.first_name && form.last_name && form.email && form.invitation_message
      );
    };

    return {
      form,
      controls,
      searchInviteTenant,
      confirmInviteTenant,
      validateFields,
    };
  },
};
</script>
<template>
  <div class="flex flex-col">
    <div class="flex flex-col py-10 px-10 space-y-6">
      <div
        v-if="controls.step_index === 1"
        class="flex flex-col bg-white shadow-sm border rounded-lg py-4 mb-1"
      >
        <h3 class="font-medium text-base leading-10 border-b pb-2 px-4">
          Tenant Invitation Form
        </h3>
        <div
          class="sm:grid sm:grid-cols-2 sm:gap-y-4 sm:items-start sm:border-gray-200 sm:py-6 px-8"
        >
          <div class="grid grid-cols-3 gap-4">
            <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              First Name
            </p>
            <a-input v-model="form.first_name" size="large" />
          </div>
          <div class="grid grid-cols-3 gap-4">
            <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Last Name
            </p>
            <a-input v-model="form.last_name" size="large" />
          </div>
          <div class="grid grid-cols-3 gap-4">
            <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Address (optional)
            </p>
            <a-input v-model="form.address" size="large" />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div class="flex items-center space-x-4">
              <span class="text-sm font-medium text-gray-700"> Email </span>
              <a-input v-model="form.email" size="large" />
            </div>
            <div class="flex items-center space-x-2">
              <span>Invite by email?</span>
              <a-checkbox checked />
            </div>
          </div>
          <div class="flex flex-col space-y-2 col-span-2">
            <span class="text-sm font-medium text-gray-500"
              >Leave invitation message for this tenant:</span
            >
            <a-textarea
              v-model="form.invitation_message"
              placeholder="Your guest will see the message when they open your invitation link"
              :auto-size="{ minRows: 2, maxRows: 4 }"
            />
          </div>
        </div>
      </div>

      <template v-if="controls.step_index === 2">
        <a-result
          v-if="controls.result === 'success'"
          status="success"
          title="Successfully Invited/Added Tenant!"
          sub-title="Your tenants list has been updated, please open the dropdown menu to select the new tenant"
        >
          <template #extra></template>
        </a-result>
        <a-result
          v-if="controls.result === 'existing-info'"
          title="We've found an existing account"
        >
          <template #extra>
            <div class="flex flex-col space-y-4 justify-center items-center">
              <span class="w-1/2 text-gray-500 text-base"
                >We're about to add an existing Parqay tenant, please confirm the
                information below. After clicking the Confirm, this tenant will be
                added directly into your tenants list</span
              >
              <a
                target="_blank"
                class="flex space-x-4 px-4 py-4 border rounded items-center hover:shadow-sm bg-white hover:bg-blue-100"
              >
                <div
                  class="flex items-center justify-center border rounded bg-gray-50 w-12 h-12"
                >
                  <span class="font-bold text-2xl text-blue-500">{{
                    form.first_name.substring(0, 1)
                  }}</span>
                </div>
                <span class="font-semibold text-base text-gray-600"
                  >{{ form.first_name }} {{ form.last_name }}</span
                >
                <span
                  class="font-semibold text-base text-white bg-parqay-primary rounded-full px-4 py-2"
                  >{{ form.email }}</span
                >
              </a>
              <div class="flex space-x-4 pt-2">
                <a-button size="large" @click="() => (controls.step_index -= 1)">
                  Go Back
                </a-button>
                <a-button
                  type="primary"
                  size="large"
                  :loading="controls.loading"
                  @click="confirmInviteTenant()"
                >
                  Add This Tenant
                </a-button>
              </div>
            </div>
          </template>
        </a-result>
        <a-result
          v-if="controls.result === 'inviting-info'"
          title="Inviting New Tenant"
        >
          <template #extra>
            <div class="flex flex-col space-y-4 justify-center items-center">
              <span class="w-1/2 text-gray-500 text-base"
                >We're about to add a new tenant into our system, please confirm the
                information below. After clicking the Confirm, a new tenant account
                will be created and a magic login link will send to the provided
                email.</span
              >
              <a
                target="_blank"
                class="flex space-x-4 px-4 py-4 border rounded items-center hover:shadow-sm bg-white hover:bg-blue-100"
              >
                <div
                  class="flex items-center justify-center border rounded bg-gray-50 w-12 h-12"
                >
                  <span class="font-bold text-2xl text-blue-500">{{
                    form.first_name.substring(0, 1)
                  }}</span>
                </div>
                <span class="font-semibold text-base text-gray-600"
                  >{{ form.first_name }} {{ form.last_name }}</span
                >
                <span
                  class="font-semibold text-base text-white bg-parqay-primary rounded-full px-4 py-2"
                  >{{ form.email }}</span
                >
              </a>
              <div class="flex space-x-4 pt-2">
                <a-button size="large" @click="() => (controls.step_index -= 1)">
                  Go Back
                </a-button>
                <a-button
                  type="primary"
                  size="large"
                  :loading="controls.loading"
                  @click="confirmInviteTenant()"
                >
                  Send Invitation
                </a-button>
              </div>
            </div>
          </template>
        </a-result>
      </template>

      <div
        v-if="controls.step_index < 2"
        class="bg-white border shadow-sm rounded-lg flex justify-between py-4 px-8"
      >
        <a-button
          v-if="controls.step_index > 0"
          type="primary"
          @click="
            () => {
              if (controls.step_index == 1) {
                $emit('dismiss');
                return;
              }
              controls.step_index -= 1;
            }
          "
        >
          Previous
        </a-button>
        <div v-else />
        <a-button
          v-if="controls.step_index < 1"
          type="primary"
          @click="
            () => {
              if (controls.invite_mode_index === 1) {
                $router.push('/dashboard/reservations#calendar');
              } else {
                controls.step_index += 1;
              }
            }
          "
        >
          Next
        </a-button>
        <a-button
          v-else
          type="primary"
          :loading="controls.loading"
          :disabled="!validateFields()"
          @click="searchInviteTenant()"
        >
          Invite
        </a-button>
      </div>
    </div>
  </div>
</template>
