<script>
import { onMounted, reactive, watch, onBeforeUnmount } from "@vue/composition-api";
import ValidationSectionHeader from "src/views/reservations/components/ValidationSectionHeader";
import { API } from "src/views/documents/api";
import { deepGet } from "src/plugins/prototype";

import {
  startSignatureRequest,
  startVerifyIdentity,
} from "src/views/documents/components/Templates.vue";

export default {
  components: {
    ValidationSectionHeader,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    controls: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const templates = reactive({
      upload_only: [],
      signature_request: [],
      identity_verification: null,
    });
    const documents = reactive({
      upload_only: [],
      signature_request: [],
      identity_verification: [],
    });
    watch(
      () => props.form.listing,
      async (val) => {
        if (deepGet(val, "id")) {
          await getDocumentTemplates(props.form);
        }
        if (deepGet(props.form, "tenant.id")) {
          await getDocumentsByReservation();
          await getIdentityVerificationResult();
        }
      },
      {
        deep: true,
      }
    );
    const getDocumentTemplates = async (form) => {
      const documentTemplates = deepGet(props.form, "document_templates", []);
      if (documentTemplates.length === 0) {
        let resp;
        resp = await API.getListingDocumentTemplates({
          listing_id: form.listing.id,
          on_behalf_of: form.host.id,
        });
        templates.upload_only = resp.data.filter((v) => v.type === "upload");
        templates.signature_request = resp.data.filter((v) => v.type === "sign");
        templates.identity_verification = resp.data.find(
          (v) => v.type === "identity"
        );
        props.form.document_templates = resp.data;
      } else {
        templates.upload_only = documentTemplates.filter((v) => v.type === "upload");
        templates.signature_request = documentTemplates.filter(
          (v) => v.type === "sign"
        );
        templates.identity_verification = documentTemplates.find(
          (v) => v.type === "identity"
        );
      }
      props.controls.loading.documentTemplates = false;
    };
    const signDocument = async (doc) => {
      // ctx.root.$toggleLoadingIndicator(true);
      try {
        await startSignatureRequest(doc, "tenant");
      } catch (error) {
        console.log("signDocument >> error: ", error);
        ctx.root.$openNotification("Signing Document", error.message, 10, "error");
        return;
      } finally {
        // ctx.root.$toggleLoadingIndicator(false);
      }
    };
    const startIdentityVerification = async () => {
      ctx.root.$toggleLoadingIndicator(true, "Starting Verification");
      try {
        await startVerifyIdentity();
      } catch (error) {
        console.log("startIdentityVerification error: ", error);
        ctx.root.$openNotification("Verify Identity", error.message, 10, "error");
        return;
      } finally {
        ctx.root.$toggleLoadingIndicator(false);
      }
    };
    const getDocumentsByReservation = async () => {
      let resp;
      resp = await API.getDocumentsByReservation({
        role: "tenant",
        reservation_id: props.form.id
      });
      documents.upload_only = resp.data.filter((v) => v.template.type === "upload");
      documents.signature_request = resp.data.filter(
        (v) => v.template.type === "sign"
      );
    };
    const getIdentityVerificationResult = async () => {
      let resp;
      try {
        resp = await API.getIdentityVerificationResult({
          on_behalf_of: deepGet(props.form, "tenant.id"),
        });
        documents.identity_verification = [resp.data];
        // documents.identity_verification = [];
      } catch (error) {
        console.log("getIdentityVerificationResult error: ", error);
        ctx.root.$openNotification(
          "Identity Verification",
          error.message,
          10,
          "error"
        );
      }
    };

    onMounted(async () => {
      props.controls.loading.documentTemplates = true;
      if (deepGet(props.form, "listing.id")) {
        await getDocumentTemplates(props.form);
      }
      if (deepGet(props.form, "tenant.id")) {
        await getDocumentsByReservation();
        await getIdentityVerificationResult();
      }
      ctx.root.$bus.$on("start-new-document-signature-request", signDocument);
      ctx.root.$bus.$on("document-sign-refresh", getDocumentsByReservation);
      ctx.root.$bus.$on("document-upload-refresh", getDocumentsByReservation);
      ctx.root.$bus.$on(
        "identity-verification-refresh",
        getIdentityVerificationResult
      );
    });
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("start-new-document-signature-request", signDocument);
      ctx.root.$bus.$off("document-sign-refresh", getDocumentsByReservation);
      ctx.root.$bus.$off("document-upload-refresh", getDocumentsByReservation);
      ctx.root.$bus.$off(
        "identity-verification-refresh",
        getIdentityVerificationResult
      );
    });
    return {
      templates,
      documents,
      signDocument,
      startIdentityVerification,
    };
  },
};
</script>
<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg mx-8">
    <div
      class="flex justify-between items-center bg-white px-4 py-2 space-x-2 border-b border-gray-200 sm:px-6"
    >
      <h3 class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap">
        Documents & Signatures
      </h3>
      <validation-section-header
        :validation-errors="controls.errors.filter((e) => e.type == 'document')"
      />
    </div>
    <div class="grid grid-cols-2">
      <div class="grid grid-cols-5 border-b border-r">
        <span class="px-4 py-4 text-sm font-medium text-gray-700">
          Documents
        </span>
        <div class="flex flex-col col-span-4 space-y-2 py-4 px-2">
          <span class="text-left text-sm font-light">
            Please upload following documents required by host:
          </span>
          <div
            class="flex flex-wrap rounded-lg border bg-gray-50 overflow-hidden px-1 py-1"
          >
            <div
              v-for="(doc, i) in documents.upload_only"
              :key="`document-upload-${i}`"
              class="flex items-center space-x-1 px-2 py-1.5 mx-1 my-1 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer"
              @click="
                $openDocSlideCard(
                  'tenant',
                  doc
                )
              "
            >
              <div class="w-8 h-8 rounded-lg border bg-gray-50 flex-shrink-0">
                <svg-icon
                  icon-class="document"
                  class="text-parqay-primary w-6 h-6 m-1"
                />
              </div>
              <div class="flex flex-col space-y-0.5 px-1">
                <div class="text-xs font-medium text-gray-600">
                  {{ doc.template.name }}
                </div>
                <div>
                  <a-tag v-if="doc.status === 'complete'" color="green"
                    >Submitted</a-tag
                  >
                  <a-tag v-else-if="doc.status === 'incomplete'" color="geekblue">
                    Action Required
                  </a-tag>
                  <a-tag v-else color="orange">
                    {{ doc.status }}
                  </a-tag>
                </div>
              </div>
              <a-button
                v-if="doc.status !== 'complete'"
                type="primary"
                size="small"
                class="text-xs"
              >
                Upload
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-5 border-b">
        <span class="px-4 py-4 text-sm font-medium text-gray-700">
          Signatures
        </span>
        <div class="flex flex-col col-span-4 space-y-2 py-4 px-2">
          <span class="text-left text-sm font-light"
            >Please sign following documents required by host:</span
          >
          <div
            class="flex flex-wrap rounded-lg border bg-gray-50 overflow-hidden px-1 py-1"
          >
            <div
              v-for="(doc, i) in documents.signature_request"
              :key="`template-signature-${i}`"
              class="flex items-center space-x-1 px-2 py-1.5 mx-1 my-1 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer"
              @click="
                $openDocSlideCard(
                  'tenant',
                  doc
                )
              "
            >
              <div class="w-8 h-8 rounded-lg border bg-gray-50 flex-shrink-0">
                <svg-icon
                  icon-class="document"
                  class="text-parqay-primary w-6 h-6 m-1"
                />
              </div>
              <div class="flex flex-col space-y-0.5 px-1">
                <div class="text-xs font-medium text-gray-600">
                  {{ doc.template.name }}
                </div>
                <div>
                  <a-tag
                    v-if="['complete', 'signed'].includes(doc.status)"
                    color="green"
                    >{{ doc.status }}</a-tag
                  >
                  <a-tag v-else-if="doc.status === 'incomplete'" color="geekblue">
                    Action Required
                  </a-tag>
                  <a-tag v-else color="orange">
                    {{ doc.status }}
                  </a-tag>
                </div>
              </div>
              <!-- <a-badge
                v-if="
                  documents.signature_request.filter(
                    (v) => v.template.id === template.id
                  ).length > 0
                "
                :count="
                  documents.signature_request.filter(
                    (v) => v.template.id === template.id
                  ).length
                "
                :number-style="{ backgroundColor: '#108ee9' }"
                @click="
                  openSignatureRequestDocumentPreviewSlide(
                    template,
                    documents.signature_request.filter(
                      (v) => v.template.id === template.id
                    )
                  )
                "
              /> -->
              <a-button
                v-if="!['complete', 'signed'].includes(doc.status)"
                type="primary"
                size="small"
                class="text-xs"
                @click.stop="signDocument(doc)"
              >
                Sign
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-10 col-span-2">
        <div class="flex flex-col space-y-2 px-4 py-4 items-start">
          <span class="text-sm font-medium text-gray-700">
            Identity Verification
          </span>
        </div>
        <div class="flex flex-col col-span-9 space-y-2 py-4 px-2">
          <span class="text-left text-sm font-light">
            Verify identity by your ID documents / selfie
          </span>
          <div
            class="flex flex-wrap rounded-lg border bg-gray-50 overflow-hidden px-1 py-1"
          >
            <template v-if="controls.loading.documentTemplates">
              <div
                class="flex items-center space-x-1 px-2 py-1.5 mx-1 my-1 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer"
              >
                <div class="w-8 h-8 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon
                    icon-class="circular-loader"
                    class="text-parqay-primary w-6 h-6 m-1 animate-spin"
                  />
                </div>
                <div class="flex flex-col space-y-1 px-1">
                  <div class="text-xs font-medium text-gray-600">
                    loading ...
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="!templates.identity_verification">
              <div
                class="flex items-center space-x-1 px-2 py-1.5 mx-1 my-1 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer"
              >
                <div class="w-8 h-8 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon
                    icon-class="document"
                    class="text-parqay-primary w-6 h-6 m-1"
                  />
                </div>
                <div class="flex flex-col space-y-1 px-1">
                  <div class="text-xs font-medium text-gray-600">
                    Not required by host
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="flex items-center space-x-1 px-2 py-1.5 mx-1 my-1 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer"
                @click="
                  () => {
                    if (
                      documents.identity_verification &&
                      documents.identity_verification.filter(
                        (v) => v.status !== 'unverified'
                      ).length > 0
                    ) {
                      $openDocSlideCard(
                        'tenant',
                        documents.identity_verification[0]
                      );
                    } else {
                      startIdentityVerification();
                    }
                  }
                "
              >
                <div class="w-8 h-8 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon
                    icon-class="document"
                    class="text-parqay-primary w-6 h-6 m-1"
                  />
                </div>
                <div class="flex flex-col space-y-1 px-1">
                  <div class="text-xs font-medium text-gray-600">
                    Verification Result
                  </div>
                  <!-- <a-tag color="geekblue">submitted</a-tag> -->
                  <div>
                    <a-tag
                      v-if="
                        documents.identity_verification.filter(
                          (v) => v.status === 'verified'
                        ).length > 0
                      "
                      color="green"
                      >Verified</a-tag
                    >
                    <a-tag v-else color="geekblue">
                      Action Required
                    </a-tag>
                  </div>
                </div>
                <a-button
                  v-if="
                    !(
                      documents.identity_verification &&
                      documents.identity_verification.length > 0
                    )
                  "
                  type="primary"
                  size="small"
                  class="text-xs"
                  @click.stop="startIdentityVerification()"
                  >Verify</a-button
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
