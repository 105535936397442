<script>
import ClickOutside from "vue-click-outside";

export default {
    name: "header-mobile",
    components: {},
    directives: {
        ClickOutside,
    },
    props: {
        controls: {
            type: Object,
            require: true
        },
        funcs: {
            type: Object,
            require: true
        }
    },
    setup(props, ctx) {
        const pushToRoute = (r) => {
            closeMenu();
            return ctx.root.$router.push(r);
        }
        const closeMenu = () => {
            props.controls.visible.mobileMenu = false;
        }
        return {
            pushToRoute,
            closeMenu,
            console,
            window
        }
    }
}
</script>
<template>
    <div>
        <div class="fixed inset-x-0 top-0 z-30">
            <div class="
        flex justify-between items-center bg-white px-4 py-2
        ">
                <a class="h-16" style="width: 200px" @click="funcs.navToLogo()">
                    <svg-icon icon-class="PARQAY-logo" class="h-full w-full" />
                </a>
                <button id="menu-button" type="button" class="
              h-10
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-gray-500
              hover:bg-gray-100
              focus:outline-none
              focus:ring-2 focus:ring-inset focus:ring-indigo-500
            " aria-expanded="false" @click="
                () => (controls.visible.mobileMenu = !controls.visible.mobileMenu)
            ">
                    <span class="sr-only">Open menu</span>
                    <svg-icon v-if="controls.visible.mobileMenu" icon-class="close"
                        class="h-6 w-6 text-gray-400 pointer-events-none" />
                    <!-- Heroicon name: outline/menu -->
                    <svg v-else class="h-6 w-6 pointer-events-none" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
        </div>

        <!--
            Mobile menu
        -->
        <div class="
          fixed
          z-40
          top-16
          inset-x-0
          p-2
          transition
          transform
          origin-top
          md:hidden
        " :class="controls.visible.mobileMenu ? 'pointer-events-auto duration-100 ease-in opacity-100 scale-100' : 'pointer-events-none duration-200 ease-out opacity-0 scale-95'"
            v-click-outside="(e) => {
                if (e.target.id !== 'menu-button') {
                    // Ignore menu button touch outside since it has its own touch event handler
                    console.log('click outside -> ', e.target.id);
                    controls.visible.mobileMenu = false;
                }
            }">
            <div class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-white
            divide-y-2 divide-gray-50
          ">
                <div class="py-6">
                    <div class="grid grid-cols-4 gap-x-2 gap-y-4 px-5">
                        <button type="button" class="
                text-gray-500
                group
                bg-white
                rounded-md
                inline-flex
                items-center
                justify-center
                text-base
                font-medium
                hover:text-gray-900
                focus:outline-none
              " aria-expanded="false" @click="funcs.navToFeatures()">
                            <span>Features</span>
                        </button>

                        <a class="text-base text-center font-medium text-gray-500 hover:text-gray-900" @click="
                            () => {
                                if ($route.path === '/pricing') {
                                    $scrollToHashSection('#pricing');
                                    closeMenu();
                                } else {
                                    pushToRoute('/pricing');
                                }
                            }
                        ">
                            Pricing
                        </a>
                        <a class="text-base text-center font-medium text-gray-500 hover:text-gray-900" @click="
                            () => {
                                if ($route.hash === '#demo') {
                                    $scrollToHashSection($route.hash);
                                } else {
                                    pushToRoute({ path: 'pricing', hash: '#demo' });
                                }
                            }
                        ">
                            Demo
                        </a>

                        <a class="text-base text-center font-medium text-gray-500 hover:text-gray-900"
                            @click="pushToRoute('/docs')">
                            API Doc
                        </a>

                    </div>
                    <template v-if="controls.userData">
                        <div class="flex flex-grow justify-center pt-6 pb-2 px-5">
                            <button type="button"
                                class="text-blue-500 group inline-flex items-center rounded-md bg-white text-lg font-medium hover:text-blue-700 focus:outline-none"
                                aria-expanded="false">
                                <span>Hello, {{ $deepGet(controls.userData, 'first_name') }}</span>
                            </button>
                        </div>
                        <div class="relative grid gap-6 bg-white px-5 pt-6 pb-3 sm:gap-8 sm:p-8">
                            <a class="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                                @click="() => { controls.visible.accountMenu = false; pushToRoute('/dashboard'); }">
                                <div class="flex items-center justify-between space-x-2">
                                    <span class="text-base font-medium text-gray-900">Dashboard</span>
                                    <a-tag color="orange">Host</a-tag>
                                </div>
                                <span class="mt-1 text-sm text-gray-500">Supercharged sharing economy property
                                    management
                                    dashboard</span>
                            </a>

                            <a class="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                                @click="() => { controls.visible.accountMenu = false; pushToRoute({ 'name': 'tenant-home' }); }">
                                <div class="flex items-center justify-between space-x-2">
                                    <span class="text-base font-medium text-gray-900">Access</span>
                                    <a-tag color="cyan">Tenant</a-tag>
                                </div>
                                <span class="mt-1 text-sm text-gray-500">
                                    Access portal for property tenant to manage reservations,
                                    documents, etc
                                </span>
                            </a>
                        </div>
                    </template>
                    <div v-else class="mt-6 px-5">
                        <button class="
                  w-full
                  flex
                  items-center
                  justify-center
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-base
                  font-medium
                  text-white
                  bg-gradient-to-r
                  from-purple-600
                  to-parqay-primary
                  hover:from-purple-700
                  hover:to-indigo-700
                " @click="() => controls.visible.accountMenu = !controls.visible.accountMenu">
                            {{ controls.visible.accountMenu ? "Choose an option" : "Sign in" }}
                            <a-icon :type="controls.visible.accountMenu ? 'down' : 'right'" class="ml-2" />
                        </button>
                        <div v-show="controls.visible.accountMenu"
                            class="mt-3 flex flex-col bg-white py-2 divide-y border rounded-lg overflow-auto"
                            :style="{ minWidth: '280px', maxHeight: '45vh' }">
                            <a class="rounded-md px-5 pt-2 pb-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                @click="() => { controls.visible.accountMenu = false; $router.push('/login'); }">
                                <div class="flex flex-col space-y-2">
                                    <div class="flex items-center justify-between space-x-2">
                                        <span class="text-sm font-medium text-blue-600">Dashboard Login</span>
                                        <a-tag color="orange">Host</a-tag>
                                    </div>
                                    <span class="text-sm text-gray-500">Supercharged sharing economy property management
                                        dashboard</span>
                                </div>
                            </a>

                            <a class="rounded-md px-5 pt-2 pb-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                @click="() => { controls.visible.accountMenu = false; $router.push('/login/tenant'); }">
                                <div class="flex flex-col space-y-2">
                                    <div class="flex items-center justify-between space-x-2">
                                        <span class="text-sm font-medium text-blue-600">Access Login</span>
                                        <a-tag color="cyan">Tenant</a-tag>
                                    </div>
                                    <span class="text-sm text-gray-500">
                                        Access portal for property tenant to manage reservations,
                                        documents, etc
                                    </span>
                                </div>
                            </a>

                            <a class="rounded-md px-5 pt-2 pb-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                @click="() => { controls.visible.accountMenu = false; $router.push('/org/login'); }">
                                <div class="flex flex-col space-y-2">
                                    <div class="flex items-center justify-between space-x-2">
                                        <span class="text-sm font-medium text-blue-600">Organization Login</span>
                                        <a-tag color="geekblue">White-label</a-tag>
                                    </div>
                                    <span class="text-sm text-gray-500">
                                        Access portal for organization who activated white-label features
                                    </span>
                                </div>
                            </a>

                            <a class="rounded-md px-5 pt-2 pb-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                @click="() => { controls.visible.accountMenu = false; $router.push('/towing'); }">
                                <div class="flex flex-col space-y-2">
                                    <div class="flex items-center justify-between space-x-2">
                                        <span class="text-sm font-medium text-blue-600">Towing Login</span>
                                        <a-tag color="white" class="towing-tag">Towing ORG</a-tag>
                                    </div>
                                    <span class="text-sm text-gray-500">
                                        Towing company access to earn income from our hosts
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <a-divider></a-divider>
                    <div class="flex items-center justify-center">
                        <a class="text-base text-center font-medium text-blue-500 hover:text-blue-600"
                            @click="() => window.open('https://parqay.com', '_blank')">
                            Marketplace
                        </a>
                    </div>
                    <a-divider></a-divider>
                    <div v-if="false" class="flex items-center justify-center">
                        <a class="text-base text-center font-medium text-blue-500 hover:text-blue-600"
                            @click="pushToRoute('/white-label')">
                            White-label My Organization
                        </a>
                    </div>
                    <template v-if="controls.userData">
                        <a-divider></a-divider>
                        <a class="-m-3 block rounded-md transition duration-150 ease-in-out hover:bg-gray-50"
                            @click="() => { controls.visible.accountMenu = false; $logout(); controls.userData = null; }">
                            <p align="center" class="m-0 p-3">
                                <span class="text-center text-base font-medium text-pink-500">Log Out</span>
                            </p>
                        </a>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.towing-tag {
    @apply border border-black text-black;
}
</style>