<script>
import ValidationSectionHeader from "src/views/reservations/components/ValidationSectionHeader";
import VehicleInfo from "src/views/listings/new/car-rentals/components/VehicleInfo";
import TransactionForm from "src/views/reservations/components/forms/Transaction.vue";
import { API } from "src/views/listings/api";
import startCase from 'lodash/startCase';

export default {
  name: "ListingForm",
  components: {
    VehicleInfo,
    TransactionForm,
    ValidationSectionHeader
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    controls: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const getListingDetails = async (listingId) => {
      if (!listingId) {
        props.form.listing = null;
        return
      }
      try {
        props.controls.loading.getReservationDetails = true;
        const { data } = await API.details(listingId);
        props.form.listing = data;
        props.controls.showListingInputSearch = false;
        props.controls.loading.getReservationDetails = false;
      } catch (error) {
        ctx.root.$openNotification("Listing Details", "Could not fetch listing details. Error: " + error.message);
        props.controls.showListingInputSearch = true;
        props.controls.loading.getReservationDetails = false;
      }
    };
    return {
      getListingDetails,
      window,
      startCase
    };
  }
};
</script>

<template>
  <div v-if="form.type" id="listings" class="bg-white shadow sm:rounded-lg mx-8 pb-4">
    <div class="bg-white px-4 pt-3 pb-1 border-b border-gray-200 sm:px-6">
      <div class="flex justify-between items-center">
        <h3 class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap">
          Listing Details
        </h3>
        <validation-section-header :validation-errors="controls.errors.filter(e => e.type == 'listing')" />
        <div>
          <button v-if="controls.editMode &&
            controls.showListingInputSearch &&
            !controls.createMode
            " class="border border-parqay-primary bg-white text-parqay-primary px-2 py-1 text-xs rounded"
            @click="() => (controls.showListingInputSearch = false)">
            Cancel
          </button>
          <button v-if="controls.editMode && !controls.showListingInputSearch"
            class="bg-parqay-primary px-4 py-1 text-xs text-white border border-parqay-primary rounded" @click="() => {
              controls.showListingInputSearch = true;
              form.listing = null;
            }">
            Edit
          </button>
        </div>
      </div>
    </div>
    <!-- PARKING, STAY & COWORKING -->
    <template v-if="['parking', 'stay', 'coworking'].includes(form.type)">
      <div v-if="controls.showListingInputSearch"
        class="sm:grid sm:grid-cols-2 sm:gap-2 sm:items-start sm:border-gray-200 sm:pt-5 px-5">
        <div class="flex items-center space-x-4">
          <listing-search-input :type="form.type" @select="getListingDetails" />
          <a-icon v-if="controls.loading.getReservationDetails" type="loading" />
        </div>
        <div class="flex items-center justify-center">
          <button
            class="flex items-center justify-center space-x-2 border border-parqay-primary px-4 py-2 rounded focus:outline-none"
            @click="window.open('/listing', '_blank')">
            <span class="text-center font-semibold text-parqay-primary">
              + Create New Listing
            </span>
            <svg-icon icon-class="external-link" class="w-5 h-5 text-parqay-primary" />
          </button>
        </div>
      </div>
      <div v-else class="sm:grid sm:grid-cols-2 sm:gap-2 sm:items-start sm:border-gray-200 sm:pt-5 px-5">
        <div class="grid grid-cols-3 gap-4 items-center">
          <p class="block text-sm font-medium text-gray-700">
            Associate Listing
          </p>
          <router-link tag="a" :to="{
            name: 'ListingsDetail',
            params: {
              id: $deepGet(form, 'listing.id')
            }
          }" class="text-sm text-parqay-primary font-medium">
            {{ $deepGet(form, "listing.summary.title") }}
          </router-link>
          <!-- <div>
            <button
              class="text-xs bg-parqay-primary text-white hover:bg-blue-500 py-1 px-2 rounded focus:outline-none"
              @click="$openListingDetailsDrawer(form.review)"
            >
              Preview as tenant
            </button>
          </div> -->
        </div>
        <div class="grid grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700">
            Price
          </p>
          <p class="text-sm text-gray-900 font-bold sm:col-span-2">
            {{ $formatCurrency($deepGet(form, "transaction.final_charge", 0)) }}
          </p>
        </div>
        <div class="grid grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700">
            Address
          </p>
          <p class="mt-1 text-sm text-gray-900 font-bold sm:col-span-2">
            {{ $deepGet(form, "listing.summary.location.address") }}
          </p>
        </div>
        <div class="grid grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Interval
          </p>
          <div class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            <button type="button" class="
                px-4
                py-1
                border border-transparent
                text-sm
                font-base
                rounded-md
                shadow-sm
                text-white
                bg-parqay-primary
                hover:bg-blue-500
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              ">
              {{
                startCase($deepGet(form, "transaction.interval_mode", ""))
              }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <!-- CAR RENTAL -->
    <template v-if="form.type == 'car-rental'">
      <div v-if="controls.showListingInputSearch"
        class="sm:grid sm:grid-cols-2 sm:gap-2 sm:items-start sm:border-gray-200 sm:pt-5 px-5">
        <div class="flex items-center space-x-4">
          <listing-search-input :type="form.type" @select="getListingDetails" />
          <a-icon v-if="controls.loading.getReservationDetails" type="loading" />
        </div>
        <div class="flex items-center justify-center">
          <button
            class="flex items-center justify-center space-x-2 border border-parqay-primary px-4 py-2 rounded focus:outline-none"
            @click="window.open('/listing', '_blank')">
            <span class="text-center font-semibold text-parqay-primary">
              + Create New Listing
            </span>
            <svg-icon icon-class="external-link" class="w-5 h-5 text-parqay-primary" />
          </button>
        </div>
      </div>
      <div v-else class="sm:grid sm:grid-cols-2 sm:gap-2 sm:items-start sm:border-gray-200 sm:pt-5 px-5">
        <div class="grid grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Associate Listing
          </p>
          <router-link tag="a" :to="{
            name: 'ListingsDetail',
            params: {
              id: $deepGet(form, 'listing.id')
            }
          }" class="mt-1 text-sm text-parqay-primary font-medium sm:mt-0 sm:col-span-2 pt-2">
            {{ $deepGet(form, "listing.summary.title") }}
          </router-link>
        </div>
        <div class="grid grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Vehicle Address
          </p>
          <p class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            {{ $deepGet(form, "listing.summary.location.address") }}
          </p>
        </div>
        <div class="grid grid-cols-3 gap-4">
          <p class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Interval
          </p>
          <div class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2 pt-2">
            <button type="button" class="
                inline-flex
                items-center
                px-2
                py-1
                border border-transparent
                text-sm
                font-base
                rounded-md
                shadow-sm
                text-white
                bg-parqay-primary
                hover:bg-blue-500
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              ">
              Per Day
            </button>
          </div>
        </div>
        <template>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500 my-auto">
              Mileage
            </dt>
            <dd class="my-auto col-span-2 grid grid-cols-2 gap-x-2">
              <span class="font-bold text-sm text-gray-900">5,600 Miles</span>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500 my-auto">
              Max Mileage
            </dt>
            <dd class="my-auto col-span-2 grid grid-cols-2 gap-x-2">
              <span class="font-bold text-sm text-gray-900">10,000 Miles</span>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <p class="text-sm font-medium text-gray-500">
              Collect Mileage Penalty
            </p>
            <div class="mt-1 text-sm text-gray-900 font-medium sm:-mt-1 sm:col-span-2">
              <div>
                <a-radio-group v-model="form.listing.mileage_penalty">
                  <a-radio-button v-for="(o, i) in controls.listing.mileagePenaltyOptions"
                    :key="`mileage-penalty-option-${i}`" :value="o.value">
                    {{ o.text }}
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-1">
            <dt class="text-sm font-medium text-gray-500 my-auto">
              Extra Mileage
            </dt>
            <dd class="my-auto col-span-2 grid grid-cols-3 gap-x-2">
              <span class="font-bold text-sm text-red-500">0 Miles</span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Mileage penalty activated, this means if extra mileage is
                    used, driver’s payment method will be auto-charged and
                    deposit to your account</span>
                </template>
                <svg-icon icon-class="info" class="ml-2 w-5 h-5 text-parqay-primary" />
              </a-tooltip>
            </dd>
          </div>
        </template>
        <vehicle-info class="col-span-2" title="Connect Smartcar to grant your guest virtual key" :model="form.vehicle"
          :vehicle-id="$deepGet(form, 'listing.summary.vehicle.id')"
          @added-vehicle="vehicle => (form.vehicle = vehicle)" />
      </div>
    </template>
    <div class="pt-4">
      <transaction-form v-if="form.listing" :controls="controls" :form="form"
        class="sm:grid sm:grid-cols-6 sm:gap-4 border-t sm:items-start sm:border-gray-200 sm:pt-5 px-5" />
    </div>
  </div>
</template>
