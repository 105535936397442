<script>
import { reactive, ref, computed } from '@vue/composition-api';
import parsePhoneNumber from "libphonenumber-js";

import { API } from "./api";

export default {
    props: {
        contact: {
            type: Object,
            required: true
        }
    },
    setup(props, ctx) {

        const loading = ref(false);

        const form = reactive({
            email: null,
            phone_number: null,
            phoneNumberFormatted: "",
            phoneComponents: {},
            country: "US",
            errorMessage: {
                email: null,
                phoneNumber: null
            },
            isPhoneNumberInsideUS: true
        })

        const isEmailValid = () => {
            if (!form.email) {
                form.errorMessage.email = null;
                return;
            }
            // Regex for validating email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValid = emailRegex.test(form.email);
            if (isValid) {
                form.errorMessage.email = null;
            } else {
                form.errorMessage.email = "Invalid email.";
            }
        };

        const isPhoneNumberValid = () => {
            // Regex for validating phone number format
            const phoneNumberRegex = /^\+?\d{1,3}[-.\s]?\(?\d{2,4}\)?[-.\s]?\d{3,5}[-.\s]?\d{4}$/;
            const isValid = phoneNumberRegex.test(form.phoneNumberFormatted);
            if (isValid) {
                form.errorMessage.phoneNumber = null;
            } else {
                form.errorMessage.phoneNumber = "Invalid phone number.";
            }
        };

        const formatPhoneNumber = () => {
            if (form.isPhoneNumberInsideUS) {
                // Format phone number to (XXX) XXX-XXXX
                form.phoneNumberFormatted = form.phoneNumberFormatted.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            } else {
                // Format phone number to only digits
                form.phoneNumberFormatted = form.phoneNumberFormatted.replace(/\D/g, "");
            }
            updatePhoneComponents();
        };

        const updatePhoneComponents = () => {
            try {
                form.phoneComponents = parsePhoneNumber(
                    form.phoneNumberFormatted,
                    form.country
                );
                form.phone_number = form.phoneComponents.number;
            } catch (error) {
                form.phoneComponents = {};
                form.phone_number = null;
            }
        };

        const shouldDisableNextButton = computed(() => {
            if (
                !form.phone_number ||
                Object.values(form.errorMessage).some(v => v !== null)
            ) {
                return true;
            }
            return false;
        })

        const getPayload = () => {
            const payload = {
                phone_number: form.phone_number
            }
            if (!props.contact.email) {
                payload["email"] = form.email;
            }
            if (ctx.root.$isAdmin()) {
                payload["on_behalf_of"] = props.contact.host;
            }
            return payload;
        }

        const fullfillInfo = async () => {
            console.log("fullfillInfo -> ", getPayload());
            try {
                loading.value = true;
                const { data } = await API.fullfillDirectConversationMissingInfo(props.contact.id, getPayload());
                loading.value = false;
                ctx.emit("next", data);
            } catch (error) {
                loading.value = false;
                ctx.root.$openNotification("Info Fullfillment Error", error.message);
            }
        };

        return {
            loading,
            form,
            shouldDisableNextButton,
            isEmailValid,
            isPhoneNumberValid,
            formatPhoneNumber,
            updatePhoneComponents,
            fullfillInfo
        };
    },
};
</script>

<template>
    <div class="flex flex-col justify-center items-center px-4 py-2">
        <div class="w-full bg-white rounded-lg border overflow-hidden">
            <div class="px-6 py-6 space-y-4">
                <h2 class="text-xl text-center font-semibold text-gray-900">
                    Fullfill Missing Contact Info
                </h2>
                <div class="text-sm text-gray-500">
                    A direct chat requires contact's phone number.
                    Every outgoing message will forward to this phone number using SMS/MMS so your contact will be notified
                    immediately.
                    If you don't wish to also forwarding SMS/MMS, please use reservation chat on an existing reservation.
                </div>
                <a-divider>Required Info</a-divider>
                <div class="mb-4 space-y-4">
                    <div class="flex flex-col mb-4 space-y-4">
                        <label-item label="Phone Number" :error-message="form.errorMessage.phoneNumber">
                            <template #desc>
                                <div class="flex-grow flex items-center justify-end">
                                    <a-button v-if="form.isPhoneNumberInsideUS" country-picker type="link"
                                        @click.stop="() => { form.isPhoneNumberInsideUS = false; formatPhoneNumber() }">Outside
                                        US</a-button>
                                    <a-button v-else country-picker type="link"
                                        @click.stop="() => { form.isPhoneNumberInsideUS = true; formatPhoneNumber(); form.errorMessage.country = null; }">Inside
                                        US</a-button>
                                </div>
                            </template>
                            <a-input v-model="form.phoneNumberFormatted" placeholder="Contact's phone number" size="large"
                                allow-clear @input="isPhoneNumberValid()" @blur="formatPhoneNumber()">
                                <svg-icon slot="prefix" icon-class="phone" class="h-4 w-4 text-gray-400" />
                            </a-input>
                        </label-item>
                        <label-item v-if="!form.isPhoneNumberInsideUS" label="Choose Phone Number Country"
                            :error-message="form.errorMessage.country">
                            <country-select v-model="form.country" :country="form.country" topCountry="US" class="w-full"
                                @input="selected => {
                                    if (form.country) {
                                        form.errorMessage.country = null
                                    } else {
                                        form.errorMessage.country = 'Country must be specified.'
                                    }
                                    updatePhoneComponents();
                                }" />
                        </label-item>
                        <label-item v-if="!contact.email" label="Email (Optional)" :error-message="form.errorMessage.email">
                            <a-input v-model.trim="form.email" placeholder="Contact's email address" allow-clear
                                size="large" @input="isEmailValid()" @blur="isEmailValid()" />
                        </label-item>
                    </div>
                    <a-alert message="SMS/MMS Reachability"
                        description="Please double check contact's phone number. The chat could not start if the phone number is not reachable. Click continue to start direct chat with the contact."
                        type="warning" />
                    <div class="flex items-center justify-center">
                        <a-button type="primary" :loading="loading" block :disabled="shouldDisableNextButton" size="large"
                            @click.stop="fullfillInfo()">
                            Continue
                        </a-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ant-btn[country-picker] {
    @apply flex items-center justify-end text-xs font-medium;
}
</style>