<script>
import { ref, watch, computed } from "@vue/composition-api";
import AdminAddSlideBar from "./AdminAddSlideBar";
import includes from "lodash/includes";
import cloneDeep from "lodash/cloneDeep";
import isEqual from 'lodash/isEqual';

export default {
  name: "ListingsAdmin",
  components: {
    AdminAddSlideBar,
  },
  props: {
    componentData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const model = ref([]);

    const columns = ref(props.componentData.access.columns);

    const permissions = computed(() => {
      var _permissions = []
      columns.value.map((v, i) => {
        if (v.id != 'name' && v.id != 'role') {
          v.checked = false
          _permissions.push(v)
        }
      })
      return _permissions
    })

    const shouldShowSaveButton = ref(false);

    props.componentData.access.data.map((access, i) => {
      var permission = {};
      var colIds = [];
      columns.value.map((col, i) => {
        colIds.push(col.id);
      });

      colIds.map((colId, i) => {
        if (includes(access.permissions, colId)) {
          permission[colId] = true;
        } else {
          permission[colId] = false;
        }
      });

      permission.id = access.id;
      permission.name = access.name;
      permission.role = access.role;

      model.value.push(permission);
    });

    const remoteModel = cloneDeep(model.value);

    watch(
      () => model.value,
      (updatedValue) => {
        console.log("change detected...");
        console.log("updatedValue: \n" + JSON.stringify(updatedValue));
        console.log(
          "is equal: \n" + JSON.stringify(isEqual(updatedValue, remoteModel))
        );
        if (!isEqual(updatedValue, remoteModel)) {
          shouldShowSaveButton.value = true;
        } else {
          shouldShowSaveButton.value = false;
        }
      },
      {
        deep: true,
      }
    );

    return {
      columns,
      permissions,
      model,
      shouldShowSaveButton,
    };
  },
};
</script>
<template>
  <div class="px-10">
    <div class="flex flex-row-reverse mt-4">
      <button
        class="px-4 py-2 bg-green-400 hover:bg-green-500 text-white rounded"
        @click="$refs['slide-bar'].show()"
      >
        + Add Member
      </button>
    </div>
    <div class="rounded bg-white shadow mt-4 pb-8 mb-24">
      <a-table
        :data-source="model"
        row-key="id"
        :pagination="false"
      >
        <a-table-column
          v-for="col in columns"
          :key="col.id"
          :title="col.name"
          data-index="id"
          align="center"
        >
          <template slot-scope="text, record">
            <span v-if="col.id == 'name'">{{ record[col.id] }}</span>
            <span
              v-else-if="col.id == 'role'"
              class="font-medium text-parqay-primary"
            >{{ record[col.id] }}</span>
            <a-checkbox
              v-else
              v-model="record[col.id]"
            />
          </template>
        </a-table-column>
        <a-table-column
          key="actionDelete"
          title=""
          data-index="id"
          align="center"
        >
          <template slot-scope="text, record, index">
            <button
              v-if="record.role != 'admin'"
              class="rounded bg-red-500 hover:bg-red-600 text-white px-3 py-1"
              @click="model.splice(index, 1);"
            >
              Remove
            </button>
          </template>
        </a-table-column>
      </a-table>
    </div>
    <admin-add-slide-bar
      ref="slide-bar"
      :permissions="permissions"
    />
    <!-- footer -->
    <div
      v-if="shouldShowSaveButton"
      class="fixed bottom-0 left-64 right-0 bg-parqay-primary border-t text-right py-2 px-10"
    >
      <button
        class="rounded bg-white border hover:bg-parqay-primary text-parqay-primary hover:text-white hover:border-white px-6 py-2 text-base font-medium"
      >
        Save Changes
      </button>
    </div>
  </div>
</template>
