import request from "src/services/api/request";
import dayjs from "dayjs";

export const API = {
  subscribeEmail(email) {
    const fmt = "YYYY-MM-DDTHH:mm:ssZZ";
    const dt = dayjs().format(fmt);
    return request.post("user/subscribe-email", { email, dt });
  },
  contactSales(payload) {
    return request.post("user/contact-sales", { ...payload });
  }
};
