<script>
import CalHeatMap from "cal-heatmap";
import "cal-heatmap/cal-heatmap.css";
import { nextTick, ref } from "@vue/composition-api";
import dayjs from "dayjs";
import { Urls } from "src/services/constants";

export default {
  name: "HeatMapCalendar",
  components: {},
  props: {},
  setup(props, ctx) {
    const getUserUID = ctx.root.$getUserUID;
    var cal = new CalHeatMap();
    const heatCalendar = ref(null);
    const getFocusDate = () => {
      let dt = new Date();
      dt.setDate(1);
      dt.setMonth(dt.getMonth() - 1);
      return dt;
    };
    nextTick(() => {
      cal.init({
        itemSelector: "#heatCalendar",
        itemName: ["reservation", "reservations"],
        domain: "month",
        subDomain: "x_day",
        cellSize: 18,
        subDomainTextFormat: "%d",
        // cellPadding: 5,
        domainGutter: 10,
        domainLabelFormat: (date) => {
          return dayjs(date).format("MMMM");
        },
        label: {
          position: "top",
        },
        range: 2,
        displayLegend: true,
        legend: [1, 15, 30],
        legendVerticalPosition: "top",
        legendHorizontalPosition: "center",
        legendMargin: [0, 0, 0, 0],
        legendColors: {
          min: "#c2f1ff",
          max: "#6366f1",
          empty: "#efefef",
          // Will use the CSS for the missing keys
        },
        start: getFocusDate(),
        data:
          Urls.API_BASE_URL() +
          "reservations/stats/heatmap" +
          "?user_uid=" +
          getUserUID(),
        // onClick: (date, nb) => {
        //   document.querySelector("#heatCalendar-placeholder").innerHTML = "You just clicked <br/>on <b>" +
        //     date + "</b> <br/>with <b>" +
        //     (nb === null ? "unknown" : nb) + "</b> items";
        // },
        tooltip: true,
      });
    });
    return {
      cal,
      heatCalendar,
    };
  },
};
</script>
<template>
  <div class="heat-map-calendar">
    <div class="
        rounded-lg
        bg-white
        shadow-sm
        hover:shadow-lg
        border
        hover:border-0
        cursor-pointer
        text-center
        flex flex-col
        items-center
        justify-center
      ">
      <div class="flex w-full justify-between transform translate-y-4 px-4">
        <button class="
            w-8
            h-8
            rounded
            border
            bg-white
            text-gray-500
            hover:bg-gray-100 hover:text-parqay-primary
            focus:outline-none
          " @click="cal.previous()">
          <svg-icon class="w-6 h-6 my-1" icon-class="arrow-sm" />
        </button>
        <button class="
            w-8
            h-8
            rounded
            border
            bg-white
            text-gray-600
            hover:bg-gray-100 hover:text-parqay-primary
            focus:outline-none
          " @click="cal.next()">
          <svg-icon class="w-6 h-6 transform rotate-180 my-1" icon-class="arrow-sm" />
        </button>
      </div>
      <div id="heatCalendar" ref="heatCalendar" class="my-4 relative"
        @click="$router.push('/dashboard/reservations')" />
      <div class="absolute top-2 h-8 left-2 right-2 z-20"></div>
      <div class="text-center mt-0 mb-3">
        <span class="text-sm text-gray-500">Reservations Heatmap</span>
      </div>
    </div>
  </div>
</template>
