<script>
import { reactive, ref, watch, onMounted, nextTick } from "@vue/composition-api";
import isInteger from "lodash/isInteger";
import toNumber from "lodash/toNumber";

// APIs

import { API } from "src/views/auth/api";
export default {
  name: "SignupStep3Next",
  props: {
    phoneNumber: {
      type: String,
      required: true
    },
    countryCode: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const isLoading = ref(false);
    const message = ref(null);
    const messageColorClass = ref("text-parqay-primary");
    const verifyCode = ref([]);
    const controls = reactive({
      step: 0,
      countDown: 60,
      countDownHandle: null
    });
    const _ = ctx.root.$_;
    const deepGet = ctx.root.$deepGet;
    const fullNumber = ref(props.countryCode + props.phoneNumber);
    const triggerCountDown = onOff => {
      if (onOff) {
        controls.countDown = 60;
        controls.countDownHandle = setInterval(() => {
          if (controls.countDown > 0) {
            controls.countDown -= 1;
          } else {
            if (controls.countDownHandle)
              clearInterval(controls.countDownHandle);
          }
        }, 1000);
      } else {
        if (controls.countDownHandle) clearInterval(controls.countDownHandle);
      }
    };

    const sendVerifyCode = () => {
      triggerCountDown(true);
      message.value = "Sending verification code...";
      isLoading.value = true;
      API.initVerifyPhoneNumber(props.phoneNumber, props.countryCode)
        .then(res => {
          isLoading.value = false;
          message.value = res.message;
          messageColorClass.value = "text-parqay-primary";
        })
        .catch(error => {
          isLoading.value = false;
          message.value = error.message;
          messageColorClass.value = "text-red-500";
        });
    };

    const validateVerifyCode = () => {
      isLoading.value = true;
      message.value = "Verifying...";
      messageColorClass.value = "text-parqay-primary";
      API.phoneNumberTokenExchange(
        props.phoneNumber,
        props.countryCode,
        verifyCode.value.join("")
      )
        .then(res => {
          isLoading.value = false;
          message.value = res.message;
          messageColorClass.value = "text-parqay-primary";
          ctx.emit("token-granted", `+${props.countryCode}${props.phoneNumber}`, res.data.token);
        })
        .catch(error => {
          isLoading.value = false;
          message.value = error.message;
          messageColorClass.value = "text-red-500";
        });
    };

    const handleVerifyCode = event => {
      console.log(event.key);
      if (isInteger(toNumber(event.key))) {
        console.log("number pressed");
        if (verifyCode.value.length < 4) {
          verifyCode.value.push(toNumber(event.key));
        }
      }
      if (event.key == "Backspace") {
        verifyCode.value.splice(-1, 1);
      }
    };

    const getVerifyCode = i => {
      return deepGet(verifyCode.value, i);
    };

    const shouldHighlightBorderAtIndex = i => {
      return (
        i == verifyCode.value.length - 1 ||
        (i == 0 && verifyCode.value.length == 0) ||
        verifyCode.value.length == 4
      );
    };

    watch(
      () => verifyCode.value,
      val => {
        console.log("verify code: " + val);
        if (verifyCode.value.length == 4) {
          validateVerifyCode();
        } else {
          isLoading.value = false;
        }
      }
    );

    const focusOnInput = () => {
      const keyboardTriggerInput = document.querySelector(".first-input");
      if (keyboardTriggerInput) {
        keyboardTriggerInput.focus();
      }
    }

    onMounted(() => {
      window.addEventListener("keyup", handleVerifyCode);
    });

    nextTick(() => {
      focusOnInput();
    });
    return {
      isLoading,
      message,
      messageColorClass,
      fullNumber,
      controls,
      getVerifyCode,
      sendVerifyCode,
      shouldHighlightBorderAtIndex,
      focusOnInput
    };
  }
};
</script>
<template>
  <div>
    <div class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer">
      <div class="flex flex-col justify-center py-4">
        <div class="flex justify-center">
          <svg-icon v-if="!isLoading" icon-class="sms-verify" class="w-1/5 h-16" />
          <div v-else class="px-2 py-1 border-2 rounded-lg">
            <svg-icon icon-class="circular-loader" class="animate-spin h-8 w-8" />
          </div>
        </div>
        <div class="flex h-8 divide-x-2 divide-gray-400 divide-dashed">
          <div class="w-1/2" />
          <div class="w-1/2" />
        </div>
        <div class="flex justify-center px-6">
          <div class="flex border rounded-lg border-parqay-primary bg-white w-full h-10">
            <div class="flex items-center" :class="controls.step == 0 ? 'w-full' : 'w-3/4'">
              <span class="text-center text-parqay-primary hover:text-blue-800 font-bold w-full">{{ phoneNumber }}</span>
            </div>
            <div v-if="controls.step == 1" class="flex w-1/4 items-center">
              <button v-if="controls.countDown == 0"
                class="rounded bg-parqay-primary hover:bg-blue-500 text-white text-xs px-4 py-1"
                @click="sendVerifyCode()">
                Resend
              </button>
              <a-button v-else disabled> {{ controls.countDown }}s </a-button>
            </div>
          </div>
        </div>

        <div v-if="controls.step == 0" class="flex flex-col justify-center mt-5 px-6 space-y-2">
          <span class="font-medium text-center py-2">You will receive SMS verification code shortly after clicking the
            button below. By entering correct verification code, you will be
            able to login directly</span>
          <a-button type="primary" size="large" @click="
            () => {
              sendVerifyCode();
              controls.step = 1;
            }
          ">Send code to me</a-button>
        </div>

        <div v-if="controls.step == 1" class="flex flex-col justify-center mt-2 px-6 space-y-2">
          <span class="font-medium text-center py-2">Enter the 4 digits you received below</span>
          <div class="grid grid-cols-4 gap-8 h-16">
            <div class="h-5/6 flex items-center justify-center rounded-lg" :class="{
              'border-2 border-indigo-500': shouldHighlightBorderAtIndex(0),
              'border border-gray-500': !shouldHighlightBorderAtIndex(0),
            }" @click="focusOnInput()">
              <span class="text-black text-3xl font-bold">{{
                  getVerifyCode(0)
              }}</span>
            </div>
            <div class="h-5/6 flex items-center justify-center rounded-lg" :class="{
              'border-2 border-indigo-500': shouldHighlightBorderAtIndex(1),
              'border border-gray-500': !shouldHighlightBorderAtIndex(1)
            }" @click="focusOnInput()">
              <span class="text-black text-3xl font-bold">{{
                  getVerifyCode(1)
              }}</span>
            </div>
            <div class="h-5/6 flex items-center justify-center rounded-lg" :class="{
              'border-2 border-indigo-500': shouldHighlightBorderAtIndex(2),
              'border border-gray-500': !shouldHighlightBorderAtIndex(2)
            }" @click="focusOnInput()">
              <span class="text-black text-3xl font-bold">{{
                  getVerifyCode(2)
              }}</span>
            </div>
            <div class="h-5/6 flex items-center justify-center rounded-lg" :class="{
              'border-2 border-indigo-500': shouldHighlightBorderAtIndex(3),
              'border border-gray-500': !shouldHighlightBorderAtIndex(3)
            }" @click="focusOnInput()">
              <span class="text-black text-3xl font-bold">{{
                  getVerifyCode(3)
              }}</span>
            </div>
          </div>
          <p v-if="message" class="text-sm text-center my-0" :class="messageColorClass">
            <!-- Wrong code, re-enter or resend the code -->
            {{ message }}
          </p>
          <input type="text" pattern="\d*" class="first-input w-0 h-0 opacity-0">
        </div>
      </div>
    </div>
  </div>
</template>