<script>
import { ref, watch, onMounted, reactive } from "@vue/composition-api";
import DownloadAppBadge from "src/views/components/DownloadAppBadge";
// APIs
import { API } from "src/views/landing-pages/api";
export default {
  name: "LandingPageFooter",
  components: {
    DownloadAppBadge,
  },
  setup() {
    const controls = reactive({
      loading: false,
    });
    const subscriberEmail = ref(null);
    const subscribeMessage = ref(null);
    const subscribeMessageClasses = ref("");
    const subscribeEmail = () => {
      controls.loading = true;
      if (!subscriberEmail.value) {
        subscribeMessage.value = "Email address is missing";
        subscribeMessageClasses.value = "text-red-500";
        controls.loading = false;
        return;
      }
      API.subscribeEmail(subscriberEmail.value)
        .then((res) => {
          console.log("res -> " + JSON.stringify(res));
          subscribeMessage.value = res.message;
          subscribeMessageClasses.value = "text-parqay-primary";
          setTimeout(() => {
            subscriberEmail.value = null;
            subscribeMessage.value = null;
            controls.loading = false;
          }, 3000);
        })
        .catch((error) => {
          console.log("error -> " + JSON.stringify(error));
          subscribeMessage.value = error.message;
          subscribeMessageClasses.value = "text-red-500";
          controls.loading = false;
        });
    };
    return {
      controls,
      subscriberEmail,
      subscribeMessage,
      subscribeMessageClasses,
      subscribeEmail,
    };
  },
};
</script>
<template>
  <footer class="bg-gray-50" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">
      Footer
    </h2>
    <div v-if="$isDesktopView()" class="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="grid grid-cols-2 gap-8 xl:col-span-3">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Solutions
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Marketing
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Analytics
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Commerce
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Insights
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Support
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a class="text-base text-gray-500 hover:text-gray-900" @click="$router.push('/pricing')">
                    Pricing
                  </a>
                </li>

                <li>
                  <a class="text-base text-gray-500 hover:text-gray-900" @click="$router.push('/pricing#demo')">
                    Demo
                  </a>
                </li>

                <li>
                  <a class="text-base text-gray-500 hover:text-gray-900" @click="$router.push('/faq')">
                    FAQs
                  </a>
                </li>

                <li>
                  <a class="text-base text-gray-500 hover:text-gray-900" @click="$router.push('/contact-us')">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Company
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    About
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Blog
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Jobs
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Press
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Partners
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Legal
              </h3>
              <ul class="mt-4 space-y-4">

                <li>
                  <a class="text-base text-gray-500 hover:text-gray-900" @click="$router.push('/privacy-policy')">
                    Privacy
                  </a>
                </li>

                <li>
                  <a class="text-base text-gray-500 hover:text-gray-900" @click="$router.push('/terms-of-use')">
                    Terms
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex mt-12 xl:mt-0 xl:col-span-3">
          <div class="w-1/2">
            <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p class="mt-4 text-base text-gray-500">
              The latest news, articles, and resources, sent to your inbox weekly.
            </p>
            <!-- Subscribe Message Box -->
            <p v-if="subscribeMessage" class="mt-4 text-base" :class="subscribeMessageClasses">
              {{ subscribeMessage }}
            </p>
            <form class="mt-4 sm:flex sm:max-w-md">
              <label for="emailAddress" class="sr-only">Email address</label>
              <a-input v-model="subscriberEmail" size="large"
                class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400"
                placeholder="Enter your email" />
              <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <a-button type="primary" :loading="controls.loading" size="large"
                  class="w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-parqay-primary hover:from-purple-700 hover:to-indigo-700"
                  @click="subscribeEmail()">
                  Subscribe
                </a-button>
              </div>
            </form>
          </div>
          <div class="w-1/2 flex justify-center">
            <download-app-badge />
          </div>
        </div>
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
        <div class="flex space-x-6 md:order-2">
          <a href="https://www.facebook.com/Parqay" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd" />
            </svg>
          </a>

          <a href="https://www.instagram.com/parqayservices/" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Instagram</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clip-rule="evenodd" />
            </svg>
          </a>

          <a href="https://twitter.com/parqayservices" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path
                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
          <a href="https://www.linkedin.com/company/parqay" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Linkedin</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>

          <a href="mailto:support@parqyt.com" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Email</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
            </svg>
          </a>

          <a href="sms:+1-415-688-2011" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">SMS</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.739 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm-4.704 12.474c-.416.33-.996.495-1.74.495-.637 0-1.201-.123-1.69-.367l.274-1.083c.494.249.993.375 1.501.375.293 0 .521-.056.686-.167.315-.214.334-.646.023-.892-.149-.117-.404-.236-.769-.357-1.097-.366-1.645-.937-1.645-1.716 0-.503.202-.917.604-1.243.404-.325.943-.488 1.614-.488.586 0 1.096.099 1.535.298l-.299 1.049c-.401-.187-.82-.28-1.254-.28-.267 0-.476.052-.627.153-.299.204-.293.57-.035.787.126.107.428.246.91.416.532.188.92.42 1.167.698.246.277.369.621.369 1.034 0 .528-.208.958-.624 1.288zm6.638.401c-.091-2.281-.136-3.829-.136-4.642h-.026c-.138.611-.605 2.128-1.399 4.547h-1.031c-.476-1.669-1.178-4.547-1.178-4.547h-.018l-.204 4.642h-1.229l.366-5.75h1.792l1.014 3.967 1.228-3.967h1.811l.309 5.75h-1.299zm5.565-.377c-.419.334-1.011.502-1.76.502-.606 0-1.179-.107-1.727-.381l.29-1.147c.48.246.973.39 1.522.39.286 0 .51-.054.667-.162.302-.204.313-.61.022-.841-.145-.114-.4-.231-.757-.353-1.107-.367-1.67-.954-1.67-1.745 0-.51.208-.938.618-1.267.407-.328.956-.494 1.631-.494.587 0 1.109.101 1.55.3l.023.012-.316 1.111c-.404-.19-.81-.295-1.274-.295-.258 0-.463.05-.609.148-.281.192-.275.533-.032.738.123.103.425.242.899.41.536.188.932.426 1.181.706.25.283.378.636.378 1.055-.001.537-.215.979-.636 1.313z" />
            </svg>
          </a>
        </div>
        <p class="mt-8 mr-8 text-base text-gray-400 md:mt-0 md:order-2">
          &copy; {{ new Date().getFullYear() }}  Parqyt, Inc. All rights reserved.
        </p>
      </div>
    </div>

    <div v-else class="mx-auto pt-5 pb-8 px-4">
      <div class="flex flex-col">
        <div class="flex flex-col justify-start divide-y">
          <div class="grid grid-cols-2 gap-8 px-4 pb-5">
            <div class="flex flex-col">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Solutions
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Marketing
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Analytics
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Commerce
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Insights
                  </a>
                </li>
              </ul>
            </div>
            <div class="flex flex-col">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Support
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Pricing
                  </a>
                </li>

                <!-- <li>
                  <a
                    href="#"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Documentation
                  </a>
                </li>-->

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Guides
                  </a>
                </li>

                <!-- <li>
                  <a
                    href="#"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    API Status
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-8 px-4 pt-5 pb-5">
            <div class="flex flex-col">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Company
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    About
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Blog
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Jobs
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Press
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Partners
                  </a>
                </li>
              </ul>
            </div>
            <div class="flex flex-col">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Legal
              </h3>
              <ul class="mt-4 space-y-4">
                <!-- <li>
                  <a
                    href="#"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Claim
                  </a>
                </li> -->

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Privacy
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Terms
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col px-4 pt-5 pb-5">
            <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p class="mt-4 text-base text-gray-500">
              The latest news, articles, and resources, sent to your inbox weekly.
            </p>
            <!-- Subscribe Message Box -->
            <p v-if="subscribeMessage" class="mt-4 text-base" :class="subscribeMessageClasses">
              {{ subscribeMessage }}
            </p>
            <form class="mt-4 sm:flex sm:max-w-md">
              <label for="emailAddress" class="sr-only">Email address</label>
              <a-input v-model="subscriberEmail" size="large"
                class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400"
                placeholder="Enter your email" />
              <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <a-button type="primary" :loading="controls.loading" size="large"
                  class="w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-parqay-primary hover:from-purple-700 hover:to-indigo-700"
                  @click="subscribeEmail()">
                  Subscribe
                </a-button>
              </div>
            </form>
          </div>
          <download-app-badge class="flex flex-col px-4 pt-5 pb-5" />
        </div>
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
        <div class="flex space-x-6 md:order-2">
          <a href="https://www.facebook.com/Parqay" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd" />
            </svg>
          </a>

          <a href="https://www.instagram.com/parqayservices/" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Instagram</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clip-rule="evenodd" />
            </svg>
          </a>

          <a href="https://twitter.com/parqayservices" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path
                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
          <a href="https://www.linkedin.com/company/parqay" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Linkedin</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>

          <a href="mailto:support@parqyt.com" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Email</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
            </svg>
          </a>

          <a href="sms:+1-415-688-2011" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">SMS</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.739 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm-4.704 12.474c-.416.33-.996.495-1.74.495-.637 0-1.201-.123-1.69-.367l.274-1.083c.494.249.993.375 1.501.375.293 0 .521-.056.686-.167.315-.214.334-.646.023-.892-.149-.117-.404-.236-.769-.357-1.097-.366-1.645-.937-1.645-1.716 0-.503.202-.917.604-1.243.404-.325.943-.488 1.614-.488.586 0 1.096.099 1.535.298l-.299 1.049c-.401-.187-.82-.28-1.254-.28-.267 0-.476.052-.627.153-.299.204-.293.57-.035.787.126.107.428.246.91.416.532.188.92.42 1.167.698.246.277.369.621.369 1.034 0 .528-.208.958-.624 1.288zm6.638.401c-.091-2.281-.136-3.829-.136-4.642h-.026c-.138.611-.605 2.128-1.399 4.547h-1.031c-.476-1.669-1.178-4.547-1.178-4.547h-.018l-.204 4.642h-1.229l.366-5.75h1.792l1.014 3.967 1.228-3.967h1.811l.309 5.75h-1.299zm5.565-.377c-.419.334-1.011.502-1.76.502-.606 0-1.179-.107-1.727-.381l.29-1.147c.48.246.973.39 1.522.39.286 0 .51-.054.667-.162.302-.204.313-.61.022-.841-.145-.114-.4-.231-.757-.353-1.107-.367-1.67-.954-1.67-1.745 0-.51.208-.938.618-1.267.407-.328.956-.494 1.631-.494.587 0 1.109.101 1.55.3l.023.012-.316 1.111c-.404-.19-.81-.295-1.274-.295-.258 0-.463.05-.609.148-.281.192-.275.533-.032.738.123.103.425.242.899.41.536.188.932.426 1.181.706.25.283.378.636.378 1.055-.001.537-.215.979-.636 1.313z" />
            </svg>
          </a>
        </div>
        <p class="mt-8 mr-8 text-base text-gray-400 md:mt-0 md:order-2">
          &copy; 2021 Parqyt, Inc. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>
