<script>
import { reactive } from "@vue/composition-api";

import LoginInputs from "./components/LoginInputs.vue";
import DownloadAppBadge from "src/views/components/DownloadAppBadge.vue";
import SocialLogins from "./components/SocialLogins.vue";

export default {
  name: "login-host",
  components: {
    LoginInputs,
    DownloadAppBadge,
    SocialLogins,
  },
  setup(props, ctx) {
    const loading = reactive({
      signin: false,
      facebook: true,
      google: true,
      apple: true,
    });
    return {
      loading,
    };
  },
};
</script>
<template>
  <div class="h-screen w-screen overflow-y-auto">
    <div class="bg-white flex flex-col md:flex-row items-center h-screen">
      <img class="md:hidden object-cover h-52 w-full" src="/static/img/dashboard/login-bg.png" alt="" />
      <div
        class="flex flex-col py-6 md:py-8 xl:py-36
          px-2 md:px-6 justify-center items-center xl:justify-start border-r md:h-full md:w-1/3 md:max-w-xl no-scrollbar md:overflow-y-auto">
        <div class="flex flex-col space-y-3">
          <router-link tag="a" to="/" class="flex justify-center items-center">
            <svg-icon icon-class="PARQAY-logo" class="h-20 w-auto pr-6" />
          </router-link>
          <h4 class="text-xl font-bold text-gray-900 text-center">
            Management Portal
          </h4>
        </div>

        <div class="flex flex-col w-full pt-3 px-6 xl:px-12">
          <div class="flex flex-col justify-center space-y-2">
            <h4 class="text-xl font-medium text-gray-800 text-center">
              Sign in
            </h4>

            <router-link tag="a" to="/sign-up" class="
                    pl-4
                    font-medium
                    text-parqay-primary
                    hover:text-blue-500
                    text-center
                  ">
              Sign up to start your 14-days free trial
            </router-link>
          </div>
          <social-logins :loading="loading" />

          <div class="mt-6 relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                Or continue with
              </span>
            </div>
          </div>

          <div class="mt-6">
            <login-inputs :loading="loading.signin" @loading="
              (l) => {
                loading.signin = l;
              }
            " />
          </div>

          <div class="mt-4 flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-2 text-sm">
            <a-button icon="right-circle" size="large"
              class="w-full md:w-1/2 font-medium text-parqay-primary hover:text-blue-500 border"
              @click="$router.push('/login/tenant')">Tenant Access</a-button>
            <a-button icon="right-circle" size="large"
              class="w-full md:w-1/2 font-medium text-parqay-primary hover:text-blue-500 border"
              @click="$router.push('/towing')">Towing Company</a-button>
          </div>
          <download-app-badge class="border-t mt-4 pt-4 mb-24 md:mb-auto" />
        </div>
      </div>
      <div class="hidden md:block relative w-auto h-full flex-auto">
        <img class="absolute inset-0 object-cover h-full w-full" src="/static/img/dashboard/login-bg.png" alt="" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-btn {
    @apply flex items-center justify-center;
  }
}
</style>
