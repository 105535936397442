<script>
import { ref, onMounted } from "@vue/composition-api";
import findIndex from "lodash/findIndex";
export default {
  name: "SignupStep1",
  props: {
    hashRoute: {
      type: String,
      default: "step-one"
    }
  },
  setup(props, ctx) {
    const model = ref([
      {
        name: "Property Manager",
        image: "/static/img/dashboard/occupation-property-manager.png",
        checked: false
      },
      {
        name: "Home Owner",
        image: "/static/img/dashboard/occupation-home-owner.png",
        checked: false
      },
      {
        name: "Parking Operator",
        image: null,
        checked: false
      },
      {
        name: "Coworking Space",
        image: "/static/img/dashboard/occupation-doctor.png",
        checked: false
      },
      {
        name: "Hotel",
        image: "/static/img/dashboard/occupation-student.png",
        checked: false
      },
      {
        name: "Service Provider",
        image: null,
        checked: false
      },
      // "Other" is always the last element
      {
        name: "Other",
        image: null,
        checked: false,
        value: null
      }
    ]);
    const otherInput = ref(null);

    const convertModelToPayload = () => {
      var payload_data = "";
      model.value.map((val, i) => {
        if (val.checked) {
          if (!payload_data) {
            payload_data += val.name;
          } else {
            payload_data += `, ${val.name}`;
          }
          if (val.name == "Other") {
            payload_data += `:${val.value}`;
          }
        }
      });
      return payload_data;
    };

    const toggleOccupation = (i, target = null) => {
      if (i == -1) {
        // "Other"'s index
        if (target == null) {
          model.value.slice(-1)[0].checked = !model.value.slice(-1)[0].checked;
          // Focus on input if click "Other" first
          otherInput.value.focus();
        } else {
          if (target == true) {
            model.value.slice(-1)[0].checked = true;
          } else {
            if (model.value.slice(-1)[0].value) {
              model.value.slice(-1)[0].checked = true;
            } else {
              model.value.slice(-1)[0].checked = false;
            }
          }
        }
      } else {
        model.value[i].checked = !model.value[i].checked;
        if (target) {
          model.value[i].checked = target;
        }
      }
    };
    const route = ctx.root.$route;
    const router = ctx.root.$router;

    const next = () => {
      ctx.emit("payload-update", "occupation", convertModelToPayload());
      ctx.emit("next");
    };

    const loadCachedPayloadFromStore = () => {
      const signUpForm = ctx.root.$store.state.signUpForm;
      if (signUpForm.occupation) {
        var occupations = signUpForm.occupation.split(", ");
        console.log("occupations: " + occupations);
        occupations.map((occupation, i) => {
          console.log("occupation: " + occupation + " i: " + i);
          var index;
          if (occupation.includes("Other")) {
            index = findIndex(model.value, o => {
              return o.name == "Other";
            });
          } else {
            index = findIndex(model.value, o => {
              return o.name == occupation;
            });
          }
          console.log("index: " + index);
          if (index >= 0) {
            if (occupation.includes("Other")) {
              const otherVal = occupation.split(":")[1];
              model.value[index].value = otherVal;
            }
            model.value[index].checked = true;
          }
        });
      }
    };

    onMounted(() => {
      router.push({ name: route.name, hash: `#${props.hashRoute}` });
      loadCachedPayloadFromStore();
    });
    return {
      model,
      otherInput,
      toggleOccupation,
      next
    };
  }
};
</script>
<template>
  <div class="w-full overflow-hidden" style="min-height: 460px">
    <div class="flex flex-col overflow-auto space-y-4">
      <span class="text-base font-medium text-center"
        >First, select the <span class="text-blue-700">best way</span> to
        describe your occupation</span
      >
      <div
        class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer"
        style="min-height: 321px"
      >
        <div class="grid grid-cols-2 gap-4 p-4">
          <template v-for="(occupation, i) in model">
            <div
              v-if="occupation.name != 'Other'"
              :key="`occupation-${i}`"
              class="px-4 py-2 rounded-xl border hover:border-blue-300 hover:bg-blue-50"
              @click="toggleOccupation(i)"
            >
              <div class="flex space-x-2 justity-center items-center">
                <img
                  v-if="!occupation.checked"
                  :src="
                    occupation.image ||
                      '/static/img/dashboard/occupation-placeholder.svg'
                  "
                  class="w-12 h-12 object-fit rounded-full bg-gray-200"
                />
                <div v-else class="w-12 h-12 bg-parqay-primary rounded-full">
                  <svg-icon
                    icon-class="solid-check"
                    class="w-12 h-12 text-white"
                  />
                </div>
                <span
                  class="text-md font-medium text-center"
                  :class="{
                    'text-pink-600': occupation.checked,
                    'text-parqay-primary': !occupation.checked
                  }"
                  >{{ occupation.name }}</span
                >
              </div>
            </div>
          </template>

          <div class="grid grid-cols-2 col-span-2 gap-4">
            <div
              class="px-4 py-2 rounded-xl border hover:border-blue-300 hover:bg-blue-50"
              @click="toggleOccupation(-1)"
            >
              <div class="flex space-x-2 justity-center items-center">
                <img
                  v-if="!model.slice(-1)[0].checked"
                  :src="
                    model.slice(-1)[0].image ||
                      '/static/img/dashboard/occupation-placeholder.svg'
                  "
                  class="w-12 h-12 object-fit rounded-full bg-gray-200"
                />
                <div v-else class="w-12 h-12 bg-parqay-primary rounded-full">
                  <svg-icon
                    icon-class="solid-check"
                    class="w-12 h-12 text-white"
                  />
                </div>
                <span
                  class="text-md font-medium text-center"
                  :class="{
                    'text-pink-600': model.slice(-1)[0].checked,
                    'text-parqay-primary': !model.slice(-1)[0].checked
                  }"
                  >Other</span
                >
              </div>
            </div>
            <input
              id="other-occupation"
              ref="otherInput"
              v-model="model.slice(-1)[0].value"
              type="text"
              name="other-occupation"
              class="text-md font-medium text-center bg-gray-200 hover:border-indigo-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full h-16 sm:text-sm border-gray-200 rounded-full"
              placeholder="Please specify"
              aria-describedby="other-occupation"
              @focus="toggleOccupation(-1, true)"
              @blur="toggleOccupation(-1, false)"
            />
          </div>
        </div>
      </div>
      <button
        class="text-base bg-parqay-primary hover:bg-blue-500 rounded-lg text-white px-4 py-2"
        @click="next"
      >
        Next
      </button>
    </div>
  </div>
</template>
