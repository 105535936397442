<script>
import { reactive, ref, onMounted, onBeforeUnmount } from '@vue/composition-api';
import EmailWelcome from "./EmailWelcome.vue";
import EmailAttachmentList from "./EmailAttachmentList.vue";
import { getS3DownloadUrl } from "src/views/components/util";

import { API } from "./api";

export default {
    components: { EmailWelcome, EmailAttachmentList },
    setup(props, ctx) {

        const loading = ref(false);

        const isAutoLoading = ref(false);

        const searchLoading = ref(false);

        const activeMode = ref("inbox");

        const activeEmail = ref(null);

        const emails = reactive({
            inbox: [],
            sent: []
        });

        const searchKeywords = ref(null);

        const updateEmailReadStatus = async (email_id) => {
            try {
                await API.updateEmailReadStatus(email_id);
            } catch (error) {
                console.log(error.message)
            }
        }

        const setActiveEmail = (email) => {
            activeEmail.value = email;
            if (activeEmail.value.is_read) {
                return;
            }
            activeEmail.value.is_read = true;
            updateEmailReadStatus(email.id);
        };

        /**
         * Strips an email address off a string and returns the remaining text.
         * @param {string} str - The input string that contains an email address in the format of "Name <email>".
         * @returns {string} - The input string with the email address removed.
         * @description This function uses a regular expression to match an email address within angle brackets (<...>) and remove it from the input string. The regex pattern /<([^@\s]+@[^@\s]+\.[^@\s]+)>/ matches an email address that contains an @ symbol and a domain name with at least one dot, and does not contain any whitespace characters. The captured email address is then replaced with an empty string.
         */
        const stripContactEmail = (str) => str.replace(/<([^@\s]+@[^@\s]+\.[^@\s]+)>/, '');

        const getFirstLetter = str => str.charAt(0);

        const getEmails = async (mode, isAuto = true) => {
            isAutoLoading.value = isAuto;
            try {
                loading.value = true;
                const { data } = await API.listEmails(mode);
                emails[mode] = data;
                emails[mode].forEach(v => {
                    ctx.root.$set(v, "type", mode);
                    v.attachments.forEach(async attachment => {
                        const url = await getS3DownloadUrl({ path: attachment.s3_path });
                        ctx.root.$set(attachment, "url", url);
                    });
                })
                loading.value = false;
            } catch (error) {
                console.log(error.message);
                loading.value = false;
            }
        }

        const getMainHtmlText = (htmlContent) => {
            return htmlContent.replace(/(<([^>]+)>)/gi, "");
        }

        const toggleSearchLoading = () => {
            searchLoading.value = true;
            setTimeout(() => {
                searchLoading.value = false;
            }, 800);
        }

        /**
         * Filters the emails based on a search keyword.
         *
         * @param {string} keyword - The search keyword.
         * @returns {Array} - The filtered list of emails.
         */
        const filterEmails = (keyword) => {
            if (!keyword) {
                return emails[activeMode.value];
            }

            const lowerKeyword = keyword.toLowerCase();
            return emails[activeMode.value].filter((email) => {
                return (
                    (email.subject && email.subject.toLowerCase().includes(lowerKeyword)) ||
                    (email.content && email.content.toLowerCase().includes(lowerKeyword)) ||
                    (email.text && email.text.toLowerCase().includes(lowerKeyword)) ||
                    (email.html && email.html.toLowerCase().includes(lowerKeyword)) ||
                    (email.to && email.to.toLowerCase().includes(lowerKeyword)) ||
                    (email.cc && email.cc.toLowerCase().includes(lowerKeyword)) ||
                    (email.bcc && email.bcc.toLowerCase().includes(lowerKeyword)) ||
                    (email.recipients && email.recipients.some((recipient) => recipient.toLowerCase().includes(lowerKeyword)))
                );
            });
        };

        onMounted(() => {
            getEmails("inbox", false); // Manually trigger to show big loading icon.
            getEmails("sent", false);
            // Call the function every 5 minutes (300000 milliseconds)
            const intervalId = setInterval(() => {
                getEmails("inbox"); // Auto trigger to silently update.
                getEmails("sent");
            }, 5 * 60 * 1000);
            onBeforeUnmount(() => {
                clearInterval(intervalId);
            })
        })

        return {
            loading,
            isAutoLoading,
            searchLoading,
            activeEmail,
            activeMode,
            emails,
            searchKeywords,
            setActiveEmail,
            stripContactEmail,
            getFirstLetter,
            getEmails,
            getMainHtmlText,
            toggleSearchLoading,
            filterEmails
        };
    },
};
</script>
<template>
    <div class="flex h-screen bg-white border rounded-md overflow-hidden">
        <div class="flex-shrink-0 flex flex-col w-1/3 border-r">
            <div class="flex items-center justify-between space-x-3 pl-3 py-3 pr-3 border-b border-gray-200">
                <a-input-search v-model="searchKeywords" :loading="searchLoading" placeholder="Search mailbox" allow-clear
                    size="large" @change="toggleSearchLoading()" />
            </div>
            <div class="flex items-center justify-between p-3 border-b">
                <a-icon type="plus" class="ml-1" style="font-size: 1.25rem;"
                    @click.stop="$bus.$emit('toggle-email-editor', {})" />
                <a-radio-group v-model="activeMode" button-style="solid" @change="() => {
                    getEmails(activeMode);
                }">
                    <a-radio-button value="inbox">
                        <div class="flex items-center space-x-2">
                            <span class="">Inbox</span>
                            <div v-if="emails.inbox.filter(v => v.is_read === false).length > 0"
                                class="rounded flex items-center justify-center text-xs font-semibold text-blue-500 bg-white border border-blue-500 px-1"
                                style="padding-top: 1px; padding-bottom: 1px;">
                                {{ emails.inbox.filter(v => v.is_read === false).length }}
                            </div>
                        </div>
                    </a-radio-button>
                    <a-radio-button value="sent">
                        Sent
                    </a-radio-button>
                </a-radio-group>
                <a-icon type="reload" :spin="loading" class="pr-1" style="font-size: 1rem;"
                    @click.stop="getEmails(activeMode, false)" />
            </div>
            <div class="flex-grow overflow-y-auto">
                <div v-if="loading && !isAutoLoading" class="flex items-center justify-center h-4/5">
                    <a-icon type="loading" style="font-size: 30px;" />
                </div>
                <ul v-else>
                    <template v-if="activeMode === 'inbox'">
                        <li v-for="email in filterEmails(searchKeywords)" :key="email.id"
                            class="relative px-6 py-4 cursor-pointer border-b hover:bg-gray-100 space-y-1"
                            :class="{ 'bg-gray-100': activeEmail === email }" @click="setActiveEmail(email)">
                            <div :class="{ 'font-bold': !email.is_read }">{{ stripContactEmail(email.from) }}</div>
                            <div :class="{ 'font-bold': !email.is_read }">{{ email.subject }}</div>
                            <div v-if="email.text" class="truncate font-light" style="max-width: 100%;">{{ email.text }}
                            </div>
                            <div v-if="$deepGet(email, 'sent_at.$date')" class="absolute right-4 top-4 text-xs"
                                :class="{ 'font-bold': !email.is_read }">{{ $formatDate(email.sent_at.$date, null, true) }}
                            </div>
                        </li>
                    </template>
                    <template v-else>
                        <li v-for="email in filterEmails(searchKeywords)" :key="email.id"
                            class="relative px-6 py-4 cursor-pointer border-b hover:bg-gray-100 space-y-1"
                            :class="{ 'bg-gray-100': activeEmail === email }" @click="setActiveEmail(email)">
                            <div class="truncate">To: {{ email.recipients.join(", ") }}</div>
                            <div>{{ email.subject }}</div>
                            <div class="truncate font-light" style="max-width: 100%;">{{ getMainHtmlText(email.content) }}
                            </div>
                            <div v-if="$deepGet(email, 'created_at.$date')" class="absolute right-4 top-4 text-xs"
                                :class="{ 'font-bold': !email.created_at }">{{ $formatDate(email.created_at.$date, null,
                                    true) }}
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="flex-grow relative">
            <div class="flex flex-col items-center justify-center h-full bg-white">
                <email-welcome />
            </div>
            <!-- Active Email Viewer -->
            <div v-if="activeEmail" class="absolute inset-0 bg-white">

                <!-- Inbox Viewer -->
                <div v-if="activeEmail.type === 'inbox'" class="flex flex-col h-full">
                    <div class="flex items-center justify-between bg-gray-100 p-4">
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <div class="flex-none items-center justify-center h-10 w-10 rounded-full bg-blue-500 hover:bg-blue-600 flex-shrink-0 cursor-pointer relative"
                                    @click.stop="() => { }">
                                    <span
                                        class="text-lg text-center uppercase text-white absolute left-0 right-0 top-0 bottom-0 m-auto h-1/2"
                                        style="line-height: initial">
                                        {{ getFirstLetter(activeEmail.from) }}
                                    </span>
                                </div>
                                <!-- <img src="https://via.placeholder.com/48x48" alt="Avatar" class="rounded-full w-12 h-12" /> -->
                            </div>
                            <div class="ml-4">
                                <div class="font-bold">{{ activeEmail.from }}</div>
                                <div>{{ activeEmail.subject }}</div>
                            </div>
                        </div>
                        <div class="flex items-center space-x-4">
                            <div class="rounded p-1.5 text-gray-400 hover:text-gray-600 hover:bg-gray-200 cursor-pointer"
                                @click.stop="$bus.$emit('toggle-email-editor', { to_emails: [activeEmail.from] })">
                                <img src="/static/img/dashboard/reply-arrow.png" class="object-contain w-5 h-5">
                            </div>
                            <a-dropdown :trigger="['click']">
                                <div class="rounded p-1 hover:bg-gray-200 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                        class="w-6 h-6">
                                        <path fill-rule="evenodd"
                                            d="M10.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <a-menu slot="overlay">
                                    <a-menu-item reply>
                                        <a class="">Reply</a>
                                    </a-menu-item>
                                    <a-menu-item forward>
                                        <a class="">Forward</a>
                                    </a-menu-item>
                                    <a-menu-item delete>
                                        <svg-icon icon-class="trash" class="w-4 h-4 text-red-500" />
                                        <a class="">Delete</a>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                            <div class="text-gray-400 hover:text-gray-600 cursor-pointer"
                                @click.stop="() => activeEmail = null">
                                <svg-icon icon-class="close" class="w-5 h-5" />
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow overflow-y-auto p-4">
                        <div class="bg-white p-4 shadow-md rounded-lg">
                            <div v-if="activeEmail.html" class="text-gray-700 mb-4" v-html="activeEmail.html"></div>
                            <div v-else class="text-gray-700 mb-4">{{ activeEmail.text }}</div>
                            <a-divider v-if="activeEmail.attachments.length > 0">
                                <span class="text-xs text-center">
                                    Attachments ({{ activeEmail.attachments.length }})
                                </span>
                            </a-divider>
                            <email-attachment-list :attachments="activeEmail.attachments" />
                            <a-divider></a-divider>
                            <div class="text-gray-500">
                                <div>From: {{ activeEmail.from }}</div>
                                <div v-if="activeEmail.target === 'bcc'">To: {{ activeEmail.bcc }}</div>
                                <div v-else>To: {{ activeEmail.recipients.join(", ") }}</div>
                                <div v-if="activeEmail.cc">Cc: {{ activeEmail.cc }}</div>
                                <div v-if="activeEmail.bcc">Bcc: {{ activeEmail.bcc }}</div>
                                <div v-if="activeEmail.sent_at">Date: {{ $formatDate(activeEmail.sent_at.$date) }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Sent Viewer -->
                <div v-else-if="activeEmail.type === 'sent'" class="flex flex-col h-full">
                    <div class="flex items-center justify-between bg-gray-100 p-4">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <div class="font-bold">To: {{ activeEmail.recipients.join(", ") }}</div>
                                <div>{{ activeEmail.subject }}</div>
                            </div>
                        </div>
                        <div class="flex items-center space-x-4">
                            <div class="text-gray-400 hover:text-gray-600 cursor-pointer"
                                @click.stop="$bus.$emit('toggle-email-editor', { to_emails: activeEmail.recipients })">
                                <img src="/static/img/dashboard/reply-arrow.png" class="object-contain w-5 h-5">
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                <path fill-rule="evenodd"
                                    d="M10.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                                    clip-rule="evenodd" />
                            </svg>

                            <div class="text-gray-400 hover:text-gray-600 cursor-pointer"
                                @click.stop="() => activeEmail = null">
                                <svg-icon icon-class="close" class="w-5 h-5" />
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow overflow-y-auto p-4">
                        <div class="bg-white p-4 shadow-md rounded-lg">
                            <div class="text-gray-700 mb-4" v-html="activeEmail.content"></div>
                            <a-divider v-if="activeEmail.attachments.length > 0">
                                <span class="text-xs text-center">
                                    Attachments ({{ activeEmail.attachments.length }})
                                </span>
                            </a-divider>
                            <email-attachment-list :attachments="activeEmail.attachments" />
                            <a-divider></a-divider>
                            <div class="text-gray-500">
                                <div>From: {{ activeEmail.from }}</div>
                                <div>To: {{ activeEmail.recipients.join(", ") }}</div>
                                <div v-if="activeEmail.cc">Cc: {{ activeEmail.cc }}</div>
                                <div v-if="activeEmail.bcc">Bcc: {{ activeEmail.bcc }}</div>
                                <div>Date: {{ $formatDate(activeEmail.created_at.$date) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.ant-dropdown-menu-item[reply] {
    @apply flex items-center space-x-2 pt-1.5 pb-2;
}

.ant-dropdown-menu-item[forward] {
    @apply flex items-center space-x-2 py-2 border-t;
}

.ant-dropdown-menu-item[delete] {
    @apply flex items-center justify-center space-x-2 pt-1.5 pb-1.5 border-t;

    a {
        @apply pl-0 text-red-500 font-semibold;
    }
}
</style>