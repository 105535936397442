<script>
import { reactive, nextTick, watch } from "@vue/composition-api";
import ContactSales from "src/views/landing-pages/ContactSales";

import { Modal } from 'ant-design-vue';

export default {
  components: {
    ContactSales,
  },
  // metaInfo() {
  //   return this.$metaInfoGenerator();
  // },
  setup(props, ctx) {
    const controls = reactive({
      key: 0,
      selectedMode: "monthly"
    });
    const scrollToSection = (anchor) => {
      document
        .querySelector(anchor)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    };
    watch(
      () => ctx.root.$route,
      (val) => {
        const hash = val.hash;
        console.log("route change detected -> hash: ", hash);
        if (hash) {
          nextTick(() => {
            scrollToSection(hash);
          });
        } else {
          setTimeout(() => {
            scrollToSection("#pricing");
          }, 200);
        }
      },
      { deep: true }
    );

    const getSegmentControlClasses = (isToggled) => {
      if (isToggled) {
        return "bg-white py-2 px-6 border-blue-700 rounded-md shadow-sm text-sm font-medium text-blue-700 whitespace-nowrap hover:bg-blue-50 focus:outline-none focus:z-10"
      } else {
        return "bg-blue-700 py-2 px-6 border border-transparent rounded-md text-sm font-medium text-white whitespace-nowrap hover:bg-blue-500 focus:outline-none focus:z-10"
      }
    }

    const navToSignUp = () => {
      Modal.info({
        title: () => 'Beta Trial Program',
        icon: "info-circle",
        content: "All new users will automatically enroll in our Beta Trial program for free until our promotion period ends",
        okText: "Got it",
        onOk() {
          ctx.root.$router.push('/sign-up')
        }
      });
    };

    nextTick(() => {
      const hash = ctx.root.$route.hash;
      if (hash) {
        console.log("hash: ", hash);
        setTimeout(() => {
          scrollToSection(hash);
        }, 500);
      }
    });

    return {
      controls,
      navToSignUp,
      getSegmentControlClasses
    };
  },
};
</script>
<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-gray-50 pt-0">
    <div class="relative bg-white">
      <!-- Overlapping background -->
      <div aria-hidden="true" class="hidden absolute bg-gray-50 w-full h-6 bottom-0 lg:block"></div>

      <div id="pricing" class="relative max-w-2xl mx-auto pt-16 px-4 text-center sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 class="text-4xl font-extrabold tracking-tight text-black sm:text-6xl">
          <span class="block lg:inline">Simple pricing,</span>
          <span class="block lg:inline">to help you GROW.</span>
        </h1>
        <p class="mt-4 text-xl text-black">
          Everything you need. Pick a plan that best suits your business.
        </p>
      </div>

      <h2 class="sr-only">Plans</h2>

      <!-- Toggle -->
      <div class="relative mt-12 flex justify-center sm:mt-16">
        <div class="bg-blue-700 p-0.5 rounded-lg flex">
          <button type="button" class="relative" :class="getSegmentControlClasses(controls.selectedMode === 'monthly')"
            @click="() => controls.selectedMode = 'monthly'">
            Monthly billing
          </button>
          <button type="button" class="ml-0.5 relative"
            :class="getSegmentControlClasses(controls.selectedMode === 'yearly')"
            @click="() => controls.selectedMode = 'yearly'">
            Yearly billing
          </button>
        </div>
      </div>

      <!-- Cards -->
      <div class="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
        <!-- Decorative background -->
        <div aria-hidden="true"
          class="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-blue-700 rounded-tl-lg rounded-tr-lg lg:block">
        </div>

        <div class="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
          <div class="bg-blue-700 lg:bg-transparent pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
            <div>
              <h3 class="text-white text-sm font-semibold uppercase tracking-wide">
                Hobby
              </h3>
              <div
                class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                <div class="mt-3 flex items-center">
                  <p class="text-white text-4xl font-extrabold tracking-tight">
                    Free
                  </p>
                  <div v-if="controls.selectedMode == 'monthly'" class="ml-4">
                    <p class="text-white text-sm">USD / mo</p>
                    <p class="text-blue-200 text-sm">Billed yearly ($0)</p>
                  </div>
                  <div v-if="controls.selectedMode == 'yearly'" class="ml-4">
                    <p class="text-white text-sm">USD / year</p>
                    <p class="text-blue-200 text-sm">Billed monthly ($0)</p>
                  </div>
                </div>
                <a class="bg-white text-parqay-primary hover:bg-blue-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                  @click="navToSignUp()">Buy
                  Hobby</a>
              </div>
            </div>
            <h4 class="sr-only">Features</h4>
            <ul role="list"
              class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0">
              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-white ml-3 text-sm font-medium">Basic invoicing</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-white ml-3 text-sm font-medium">Host Dashboard + CRM</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-white ml-3 text-sm font-medium">Mutli-accounts</span>
              </li>
            </ul>
          </div>

          <div class="bg-white ring-2 ring-indigo-700 shadow-md pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
            <div>
              <h3 class="text-parqay-primary text-sm font-semibold uppercase tracking-wide">
                Scale
              </h3>
              <div
                class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                <div class="mt-3 flex items-center">
                  <p v-if="controls.selectedMode == 'monthly'"
                    class="text-parqay-primary text-4xl font-extrabold tracking-tight">
                    $50
                  </p>
                  <p v-if="controls.selectedMode == 'yearly'"
                    class="text-parqay-primary text-4xl font-extrabold tracking-tight">
                    $550
                  </p>
                  <div v-if="controls.selectedMode == 'monthly'" class="ml-4">
                    <p class="text-gray-700 text-sm">USD / mo</p>
                    <p class="text-gray-500 text-sm">Billed yearly ($550)</p>
                  </div>
                  <div v-if="controls.selectedMode == 'yearly'" class="ml-4">
                    <p class="text-gray-700 text-sm">USD / year</p>
                    <p class="text-gray-500 text-sm">Billed monthly ($50)</p>
                  </div>
                </div>
                <a class="bg-parqay-primary text-white hover:bg-blue-700 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                  @click="navToSignUp()">Buy
                  Scale</a>
              </div>
            </div>
            <h4 class="sr-only">Features</h4>
            <ul role="list" class="border-gray-200 divide-gray-200 mt-7 border-t divide-y lg:border-t-0">
              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-gray-600 ml-3 text-sm font-medium">Advanced invoicing</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-gray-600 ml-3 text-sm font-medium">Host Dashboard + CRM</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-gray-600 ml-3 text-sm font-medium">Mutli-accounts</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-gray-600 ml-3 text-sm font-medium">Tax planning toolkit</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-gray-600 ml-3 text-sm font-medium">VAT &amp; VATMOSS filing</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-gray-600 ml-3 text-sm font-medium">Free bank transfers</span>
              </li>
            </ul>
          </div>

          <div class="bg-blue-700 lg:bg-transparent pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
            <div>
              <h3 class="text-white text-sm font-semibold uppercase tracking-wide">
                Growth
              </h3>
              <div
                class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                <div class="mt-3 flex items-center">
                  <p v-if="controls.selectedMode == 'monthly'"
                    class="text-white text-4xl font-extrabold tracking-tight">
                    $12
                  </p>
                  <p v-if="controls.selectedMode == 'yearly'" class="text-white text-4xl font-extrabold tracking-tight">
                    $140
                  </p>
                  <div v-if="controls.selectedMode == 'monthly'" class="ml-4">
                    <p class="text-white text-sm">USD / mo</p>
                    <p class="text-blue-200 text-sm">Billed yearly ($140)</p>
                  </div>
                  <div v-if="controls.selectedMode == 'yearly'" class="ml-4">
                    <p class="text-white text-sm">USD / year</p>
                    <p class="text-blue-200 text-sm">Billed monthly ($12)</p>
                  </div>
                </div>
                <a class="bg-white text-parqay-primary hover:bg-blue-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                  @click="navToSignUp()">Buy
                  Growth</a>
              </div>
            </div>
            <h4 class="sr-only">Features</h4>
            <ul role="list"
              class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0">
              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-white ml-3 text-sm font-medium">Basic invoicing</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-white ml-3 text-sm font-medium">Host Dashboard + CRM</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-white ml-3 text-sm font-medium">Mutli-accounts</span>
              </li>

              <li class="py-3 flex items-center">
                <!-- Heroicon name: solid/check -->
                <svg class="text-blue-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span class="text-white ml-3 text-sm font-medium">Tax planning toolkit</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Feature comparison (lg+) -->
    <section aria-labelledby="comparison-heading" class="hidden lg:block">
      <h2 id="comparison-heading" class="sr-only">Feature comparison</h2>

      <div class="max-w-7xl mx-auto py-24 px-8">
        <div class="w-full border-t border-gray-200 flex items-stretch">
          <div class="-mt-px w-1/4 py-6 pr-4 flex items-end">
            <h3 class="mt-auto text-sm font-bold text-gray-900">
              Catered for business
            </h3>
          </div>

          <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/4">
            <div class="border-transparent py-6 border-t-2">
              <p class="text-gray-900 text-sm font-bold">Hobby</p>
              <p class="mt-2 text-sm text-gray-500">
                Designed for 1 or 2 properties
              </p>
            </div>
          </div>

          <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/4">
            <div class="border-parqay-primary py-6 border-t-2">
              <p class="text-parqay-primary text-sm font-bold">Scale</p>
              <p class="mt-2 text-sm text-gray-500">
                Designed for portfolios with 1000+ units
              </p>
            </div>
          </div>

          <div aria-hidden="true" class="-mt-px pl-4 w-1/4">
            <div class="border-transparent py-6 border-t-2">
              <p class="text-gray-900 text-sm font-bold">Growth</p>
              <p class="mt-2 text-sm text-gray-500">
                Designed for portfolios with 500+ units
              </p>
            </div>
          </div>
        </div>

        <div class="relative">
          <!-- Fake card backgrounds -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
          </div>

          <table class="relative w-full">
            <caption class="sr-only">
              Business feature comparison
            </caption>
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Feature</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Hobby plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Scale plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Growth plan</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Accounting & Financials
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Host Dashboard + CRM
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Tenant Screening
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <span class="text-gray-900 text-sm font-medium">3 accounts</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <span class="text-parqay-primary text-sm font-medium">Unlimited accounts</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <span class="text-gray-900 text-sm font-medium">7 accounts</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Invoicing
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <span class="text-gray-900 text-sm font-medium">3 invoices</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <span class="text-parqay-primary text-sm font-medium">Unlimited invoices</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <span class="text-gray-900 text-sm font-medium">10 invoices</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Exclusive offers
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  6 months free advisor
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Mobile and web access
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Fake card borders -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full rounded-lg ring-2 ring-parqay-primary ring-opacity-100"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
            </div>
          </div>
        </div>

        <h3 class="mt-10 text-sm font-bold text-gray-900">Other perks</h3>

        <div class="mt-6 relative">
          <!-- Fake card backgrounds -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
          </div>

          <table class="relative w-full">
            <caption class="sr-only">
              Perk comparison
            </caption>
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Perk</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Hobby plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Scale plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Growth plan</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  24/7 customer support
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Instant notifications
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Budgeting tools
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Digital receipts
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Pots to separate money
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Free bank transfers
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Business debit card
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Fake card borders -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full rounded-lg ring-2 ring-parqay-primary ring-opacity-100"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
            </div>
          </div>
        </div>

        <h3 class="mt-10 text-sm font-bold text-gray-900">Other perks</h3>

        <div class="mt-6 relative">
          <!-- Fake card backgrounds -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
          </div>

          <table class="relative w-full">
            <caption class="sr-only">
              Perk comparison
            </caption>
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Perk</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Hobby plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Scale plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Growth plan</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  24/7 customer support
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Instant notifications
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Budgeting tools
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Digital receipts
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Pots to separate money
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Free bank transfers
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                  Business debit card
                </th>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>

                <td class="px-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/check -->
                    <svg class="mx-auto h-5 w-5 text-parqay-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Yes</span>
                  </span>
                </td>

                <td class="pl-4 relative w-1/4 py-0 text-center">
                  <span class="relative w-full h-full py-3">
                    <!-- Heroicon name: solid/x -->
                    <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">No</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Fake card borders -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full rounded-lg ring-2 ring-parqay-primary ring-opacity-100"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <contact-sales id="demo" :key="`contact-sales-form-${controls.key}`" />
  </div>
</template>
