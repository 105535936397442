<script>
import { computed, watch, ref, reactive, nextTick } from "@vue/composition-api";

export default {
  name: "TypeModalForm",
  components: {},
  props: {
    form: {
      type: Object,
      required: true,
    },
    controls: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    const modalData = ref([
      {
        title: "Parking Reservation",
        value: "parking",
        icon: "parking-type",
        alt: true,
        selected: false,
      },
      {
        title: "Stays Reservation",
        value: "stay",
        icon: "stay-type",
        alt: true,
        selected: false,
      },
      {
        title: "Coworking Reservation",
        value: "coworking",
        icon: "coworking-type",
        alt: false,
        selected: false,
      },
      {
        title: "Car Rentals Reservation",
        value: "car-rental",
        icon: "car-rental-type",
        alt: true,
        selected: false,
      },
    ]);
    const selectReservationType = (i) => {
      modalData.value[i].selected = true;
      modalData.value.forEach((v, j) => {
        if (i !== j) {
          v.selected = false;
        }
      });
      nextTick(() => {
        props.form.type = modalData.value[i].value;
        // ctx.emit("change-type", reservationType.value);
        modalData.value[i].selected = false;
        props.controls.visible.typeSelect = false;
      })
    };
    return {
      modalData,
      selectReservationType,
    };
  },
};
</script>

<template>
  <a-modal
    v-model="controls.visible.typeSelect"
    title="Choose A Reservation Type"
    width="30vw"
    :footer="null"
    :mask-style="{
      opacity: 0.25,
    }"
    @ok="() => (controls.visible.typeSelect = false)"
  >
    <div class="grid grid-cols-2 gap-4 mb-6">
      <div
        v-for="(v, i) in modalData"
        :key="`reservation-type-${i}`"
        class="
          flex
          space-x-2
          cursor-pointer
          border
          shadow-xs
          hover:shadow-lg
          rounded-lg
          px-2
          py-1
        "
        :class="v.selected ? 'text-white bg-parqay-primary' : ''"
        @click="selectReservationType(i)"
      >
        <div
          v-if="!v.alt"
          class="border-parqay-primary rounded-lg w-12 h-12 flex"
          style="border-width: 3px"
        >
          <svg-icon
            :icon-class="v.icon"
            class="h-auto w-auto m-2 text-black"
          />
        </div>
        <svg-icon
          v-else
          :icon-class="v.icon"
          class="h-12 w-12 rounded my-auto"
        />
        <div class="my-auto w-28">
          <p class="m-0 h-full text-base font-medium">
            {{ v.title }}
          </p>
        </div>
      </div>
    </div>
  </a-modal>
</template>