<script>
import { ref } from "@vue/composition-api";
export default {
  name: "ListingNew",
  setup(props, ctx) {
    const model = ref([
      {
        title: "Parking Space",
        desc: "Hourly, Daily and, Monthly Parking",
        route: "/listing/parking",
        what_we_support: [
          "Homes, Apartments, Condos",
          "Parking Lots & Parking Garages",
          "Airport & Commercial Parking"
        ]
      },
      {
        title: "Event Parking",
        desc: "Dedicated Parking for Your Events",
        route: "/listing/event-parking",
        what_we_support: [
          "Sports Event Parking",
          "Festival Parking Management",
          "Crowded Event Parking Accommodations"
        ]
      },
      {
        title: "A Place to Stay",
        desc: "Rooms or an entire place.",
        route: "/listing/stay-coworking#stay",
        what_we_support: [
          "Hotels",
          "Homes, Apartments, Condos",
          "Bed & Breakfast"
        ]
      },
      {
        title: "Coworking",
        desc: "Desk or an entire office space.",
        route: "/listing/stay-coworking#coworking",
        what_we_support: ["Office", "Desk", "Cafe", "Restaurant"]
      },
      {
        title: "Car Rentals",
        desc: "Lease out your car with Smart Car virtual key",
        route: "/listing/car-rentals",
        what_we_support: [
          "Monitor Mileage",
          "Remote Lock/Unlock",
          "Set Milegage/Time Threshold for Each Rental"
        ]
      }
    ]);
    const todo = ref([
      {
        title: "Combo: Stay + Parking",
        desc: "Lease out your property in 2 marketplaces",
        route: "/listing/car-rentals",
        disabled: true,
        what_we_support: [
          "Searchable in 2 marketplaces",
          "2x listing exposure rate",
          "Better booking rate"
        ]
      },
      {
        title: "Combo: Stay + Parking + Car",
        desc: "Lease out your property in 3 marketplaces",
        route: "/listing/car-rentals",
        disabled: true,
        what_we_support: [
          "Searchable in 3 marketplaces",
          "3x listing exposure rate",
          "Better booking rate"
        ]
      },
      {
        title: "Combo: Coworking + Parking",
        desc: "Lease out your property in 2 marketplaces",
        route: "/listing/car-rentals",
        disabled: true,
        what_we_support: [
          "Searchable in 2 marketplaces",
          "2x listing exposure rate",
          "Better booking rate"
        ]
      },
      {
        title: "Combo: Coworking + Parking + Car",
        desc: "Lease out your property in 3 marketplaces",
        route: "/listing/car-rentals",
        disabled: true,
        what_we_support: [
          "Searchable in 3 marketplaces",
          "3x listing exposure rate",
          "Better booking rate"
        ]
      },
      {
        title: "Combo: Parking + Car",
        desc: "Lease out your property in 2 marketplaces",
        route: "/listing/car-rentals",
        disabled: true,
        what_we_support: [
          "Searchable in 2 marketplaces",
          "2x listing exposure rate",
          "Better booking rate"
        ]
      },
      {
        title: "Event Parking",
        desc: "Private & Commercial Spaces",
        route: "/listing/event-parking",
        disabled: true,
        what_we_support: ["Sports", "Concerts", "Festivals"]
      },
      {
        title: "Services",
        desc: "Vehicle, Recreation, Rentals",
        route: "/listing/services",
        disabled: true,
        what_we_support: [
          "Car Services - Oil Change, Gas, Car Wash",
          "Home Services - Cleaning, Maintenance, etc."
        ]
      }
    ])
    return {
      model
    };
  }
};
</script>
<template>
  <div class="bg-white">
    <div class="mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col sm:items-center">
        <h1 class="text-5xl leading-none font-extrabold text-parqay-primary sm:text-center">
          What would you like to list?
        </h1>
        <p class="mt-5 text-xl leading-7 text-gray-500 sm:text-center">
          Please select one of the {{ model.length }} options below.
        </p>
      </div>
      <div
        class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-7xl lg:grid-cols-4  mx-auto">
        <div v-for="(m, i) in model" :key="`service-type-option-${i}`"
          class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">
              {{ m.title }}
            </h2>
            <p class="mt-4 text-sm leading-5 text-gray-500">
              {{ m.desc }}
            </p>
            <div v-if="m.disabled"
              class="mt-8 px-4 py-2 border border-red-500 text-red-500 font-semibold leading-5 text-center rounded-md cursor-pointer">
              Available Soon
            </div>
            <button v-else type="button"
              class="mt-8 w-full bg-parqay-primary border border-text-parqay-primary rounded-md py-2 text-sm leading-5 font-semibold text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline-gray transition duration-150 ease-in-out"
              @click="$router.push(m.route)">
              Get Started
            </button>
          </div>
          <div class="pt-3 pb-8 px-6">
            <h3 class="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">
              What we support
            </h3>
            <ul class="mt-6 space-y-4">
              <li v-for="(w, j) in m.what_we_support" :key="`what-we-support-${i}-${j}`" class="flex space-x-3">
                <svg-icon icon-class="check-var-1" class="flex-shrink-0 h-5 w-5 text-blue-500" />
                <span class="text-sm leading-5 text-gray-500">{{ w }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
