<script>
import { reactive, ref, watch, onMounted, onBeforeUnmount } from "@vue/composition-api";
import { startVerifyIdentity } from "src/views/documents/components/Templates.vue";
import { API } from "../api";
import startCase from "lodash/startCase";

export default {
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
      tab: "all",
      verifyResult: {}
    });

    const documents = ref([]);

    watch(() => controls.tab, tab => {
      getDocuments();
    })

    const getDocuments = async () => {
      controls.loading = true;
      try {
        const { data } = await API.list({
          type: controls.tab
        });
        documents.value = data;
      } catch (error) {
        console.log("getDocuments > error: ", error);
      }
      controls.loading = false;
    };

    const fetchData = async () => {
      getDocuments();
      getTenantIdentityVerificationResult();
    }

    const verifyTenantIdentity = async () => {
      ctx.root.$bus.$emit("toggle-loading-indicator", true, "Loading");
      await startVerifyIdentity();
      ctx.root.$bus.$emit("toggle-loading-indicator", false);
    }

    const getTenantIdentityVerificationResult = async () => {
      try {
        const { data } = await API.getIdentityVerificationResult();
        controls.verifyResult = data;
      } catch (error) {
        console.log("getHostIdentityVerificationResult, error: ", error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    onBeforeUnmount(() => {
    })

    return {
      controls,
      documents,
      fetchData,
      verifyTenantIdentity,
      startCase,
      window,
      console
    };
  },
};
</script>
<template>
  <div class="tenant-documents absolute inset-0 bg-gray-200">
    <!-- Note: h-full is important to activate flex grow! -->
    <div class="flex flex-col h-full">
      <div class="py-3 flex-shrink-0 flex flex-col bg-white border-b">
        <div class="relative flex justify-center items-center px-10 pb-3 border-b">
          <a-button v-if="controls.verifyResult.is_verified" type="link" class="inset-y-0 pb-1" :style="{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            left: '20px',
            paddingLeft: 0
          }" @click="$openDocSlideCard(
            'tenant',
            controls.verifyResult
          )">
            <a-icon type="idcard" style="font-size: 1.25rem;" />
            <span class="text-green-400 text-sm font-medium">Verified</span>
          </a-button>
          <div class="flex items-center space-x-2 text-black font-medium text-lg">
            <a-icon type="file-text" theme="twoTone" />
            <span>Documents</span>
          </div>
          <a-button type="link" :icon="controls.loading ? 'loading' : 'reload'" class="inset-y-0 pb-1"
            :style="{ position: 'absolute', right: '16px' }" @click="fetchData()"></a-button>
        </div>
        <a-button v-if="!controls.verifyResult.is_verified && !controls.loading" type="primary" size="large"
          style="display:flex; align-items:center; justify-content:center; height:45px; border-radius:0px;"
          @click="verifyTenantIdentity()">
          <span class="text-white text-base font-semibold transform scale-95 py-2 mr-1.5">Start Identity Verification
            <span class="text-yellow-200 underline font-extrabold">NOW</span>
          </span>
          <a-icon type="idcard" theme="filled" style="font-size: 1.5rem; color: white;" />
        </a-button>
        <div class="flex justify-center text-base text-gray-600 font-normal pt-4 space-x-6">
          <a class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.tab == 'all' }"
            @click="() => (controls.tab = 'all')">
            All
          </a>
          <a class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.tab == 'sign' }"
            @click="() => (controls.tab = 'sign')">
            Sign
          </a>
          <a class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.tab == 'upload' }"
            @click="() => (controls.tab = 'upload')">
            Upload
          </a>
        </div>
      </div>
      <div class="flex-grow overflow-y-auto w-screen mx-auto">
        <div v-if="controls.loading" class="flex items-center justify-center"
          :style="{ height: `${window.innerHeight / 2}px` }">
          <a-button size="large" loading></a-button>
        </div>
        <template v-else>
          <div v-if="documents.length == 0" class="flex flex-col items-center justify-center"
            :style="{ height: `${window.innerHeight / 2}px` }">
            <span class="text-lg text-gray-500">No document</span>
          </div>
          <div v-else class="px-6 pt-5 space-y-5">
            <template v-for="(document, i) in documents">
              <div :key="`document-item-${i}`" class="shadow-md overflow-hidden rounded-xl bg-white">
                <div class="flex items-center justify-between px-4 py-2 border-b">
                  <span class="text-base text-black font-medium py-2">{{ $deepGet(document, "template.name")
                    }}</span>
                  <div class="flex items-center space-x-2">
                    <a-tag type v-if="$deepGet(document, 'template.doc_sign_required')" color="green">Sign</a-tag>
                    <a-tag type v-else color="geekblue">Upload</a-tag>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center space-y-2">
                  <div class="flex items-center space-x-3 pt-3 pb-2">
                    <avatar :user-id="document.host" :popover="false" />
                    <span>Requested by host</span>
                    <span class="text-black font-medium">{{ $deepGet(document, "reservation.host_name") }}</span>
                  </div>
                  <a-divider></a-divider>
                  <div class="flex flex-col items-center space-y-2 w-full pt-1 pb-1">
                    <div class="flex justify-between w-full px-5">
                      <div class="flex space-x-2 items-center">
                        <div class="flex items-center space-x-2">
                          <a-tag status v-if="document.status == 'incomplete'" color="pink">
                            {{ startCase(document.status) }}
                          </a-tag>
                          <a-tag status v-if="document.status == 'signing'" color="orange" class="signing-tag">
                            <span>{{ startCase(document.status) }}</span>
                            <div v-if="document.signed_by"
                              class="flex flex-col items-center justify-center space-y-1 pt-1">
                              <a-tooltip v-for="(v, k) in document.signed_by" :key="`signed-by-${k}`">
                                <template #title>
                                  Signed by: {{ v.performed_by_email }}, waiting for other signers to sign
                                </template>
                                <div
                                  class="flex items-center space-x-1 text-xs px-1.5 py-1 rounded border border-orange-300 bg-orange-100 text-orange-500">
                                  <span>{{ v.performed_by_name }}</span><a-icon type="check-circle" />
                                </div>
                              </a-tooltip>
                            </div>
                          </a-tag>
                          <a-tag status v-if="document.status == 'signed'" color="cyan" class="justify-center">
                            {{ startCase(document.status) }}
                          </a-tag>
                          <a-tag status v-if="document.status == 'verifying'" color="orange" class="justify-center">
                            {{ startCase(document.status) }}
                          </a-tag>
                          <a-tag status v-if="document.status == 'requires_input'" color="pink" class="justify-center">
                            {{ startCase(document.status) }}
                          </a-tag>
                          <a-tag status v-if="document.status == 'complete'" color="green" class="justify-center">
                            {{ startCase(document.status) }}
                          </a-tag>
                        </div>
                      </div>
                      <a-button reservation v-if="$deepGet(document, 'reservation.id')" icon="calendar" size="small"
                        style="font-size:12px;"
                        @click="$router.push(`/tenant/reservations/${document.reservation.id}`)">{{ $deepGet(document,
            'reservation.title')
                        }}</a-button>
                    </div>
                    <template v-if="$deepGet(document, 'listing.$address')">
                      <a-divider>Address</a-divider>
                      <div class="rounded bg-white px-4 pb-1">
                        <p class="text-sm text-gray-700 font-medium">{{ $deepGet(document, "listing.$address") }}</p>
                      </div>
                    </template>
                  </div>
                  <a-divider></a-divider>
                  <div class="flex space-x-3 w-full px-5 pt-1 pb-4">
                    <a-button v-if="document.status === 'complete'" size="large" block
                      @click="$openDocSlideCard('tenant', document)">View
                      {{ startCase(document.type) }}ed Doc</a-button>
                    <a-button v-else-if="document.type === 'sign'" type="primary" size="large" block
                      @click="$openDocSlideCard('tenant', document)">Start Signing</a-button>
                    <a-button v-else-if="document.type === 'upload'" type="primary" size="large" block
                      @click="$openDocSlideCard('tenant', document)">Upload Document</a-button>
                  </div>
                </div>
              </div>
              <a-divider>Document requested on: {{ $parseBackendDate(document.created_at, "MMM DD, h:mm a") }}
              </a-divider>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!$isLoggedIn()" class="bg-white border-t fixed left-0 bottom-0 right-0 px-4 pt-3 pb-5">
      <a-button type="primary" size="large" block @click="$router.push(`/login/tenant?next=${$route.fullPath}`)">Login
        to
        View More</a-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ant-btn[reservation] {
  @apply flex items-center justify-center space-x-1.5;
}

.tenant-documents::v-deep {
  .ant-btn {
    @apply justify-center;
  }

  .ant-table-row {
    @apply cursor-pointer;
  }

  td {
    @apply py-2;
  }

  .ant-divider-inner-text {
    @apply text-xs text-gray-600 font-light;
  }
}
</style>
