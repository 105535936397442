var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-tooltip',{scopedSlots:_vm._u([(!_vm.controls.showFull)?{key:"title",fn:function(){return [_c('span',[_vm._v("Click to copy")])]},proxy:true}:null],null,true)},[_c('span',{staticClass:"text-parqay-primary text-xs px-2 py-1 bg-white border border-parqay-primary rounded-full cursor-pointer hover:shadow-lg",attrs:{"id":"confirmation-tag"},on:{"click":function($event){$event.stopPropagation();return (function () {
        if (!_vm.controls.showFull) {
          _vm.$copyToClipboard(_vm.confirmationId);
          _vm.$openNotification(
            'Confirmation number copied to clipboard',
            '',
            5,
            'info'
          );
        }
        _vm.controls.showFull = !_vm.controls.showFull;
      })($event)}}},[_vm._v(_vm._s(_vm.controls.showFull ? _vm.confirmationId : _vm.confirmationId.substring(0, 6)))])])}
var staticRenderFns = []

export { render, staticRenderFns }