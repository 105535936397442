<script>
import { onMounted, ref } from "@vue/composition-api";
import { API } from "src/views/dashboard/api";

export default {
  setup(props, ctx) {
    const form = ref([]);
    const calculatePercStatus = (from, to) => {
      let perc = Math.round(((to - from) / (from == 0 ? 1 : from)) * 100);
      let sign;
      if (perc > 0) sign = "+";
      if (perc < 0) sign = "-";
      perc = Math.abs(perc);
      return {
        perc,
        sign,
      };
    };
    onMounted(async () => {
      const resp = await API.getSummaryStats();
      form.value = resp.data;
    });
    return {
      form,
      calculatePercStatus,
    };
  },
};
</script>
<template>
  <div class="pt-2 pb-6 flex flex-col space-y-4">
    <div class="
            rounded-full
            bg-parqay-primary
            hover:bg-parqay-primary
            shadow-inner
            cursor-pointer
            h-8
            w-56
          ">
      <div class="relative">
        <div class="absolute inset-0 w-1/5">
          <div class="flex items-center justify-center h-8">
            <div class="w-3 h-3 bg-white rounded-full shadow-sm" />
          </div>
        </div>
        <h4 class="
                absolute
                left-0
                right-0
                top-1
                text-base
                leading-6
                font-medium
                text-white text-center
              ">
          {{ $isAdmin() ?"Platform Resources": "You are managing" }}
        </h4>
      </div>
    </div>
    <div class="flex border rounded-lg overflow-hidden">
      <div class="pq-grid grid grid-cols-3 divide-x divide-y flex-grow" style="margin:-1px;">
        <div v-for="(m, i) in form.filter((v) => v.type !== 'team')"
          class="flex justify-between bg-white hover:bg-gray-50 px-4 py-5 sm:p-6 cursor-pointer"
          :key="`property-summary-${i}`" @click="$router.push(m.route)">
          <div class="flex flex-col space-y-2">
            <span class="text-base font-normal text-gray-900">{{ m.name }}</span>
            <div class="flex space-x-2 items-baseline">
              <a class="
                      flex
                      items-baseline
                      text-2xl
                      font-semibold
                      text-parqay-primary
                    ">{{ m.to }}</a>
              <span class="text-sm font-medium text-gray-500">from {{ m.from }}</span>
            </div>
          </div>
          <div class="flex flex-col justify-end">
            <div v-if="calculatePercStatus(m.from, m.to).sign === '+'" class="
                    flex
                    items-center
                    px-2.5
                    py-0.5
                    rounded-full
                    text-sm
                    font-medium
                    bg-green-100
                    text-green-800
                    ">
              <svg-icon class="
                        -ml-1
                        mr-0.5
                        flex-shrink-0
                        self-center
                        h-5
                        w-5
                        text-green-500
                      " icon-class="increase" />
              <span class="sr-only"> Increased by </span>
              {{ calculatePercStatus(m.from, m.to).perc }}%
            </div>
            <div v-if="calculatePercStatus(m.from, m.to).sign === '-'" class="
                      flex
                      items-center
                      px-2.5
                      py-0.5
                      rounded-full
                      text-sm
                      font-medium
                      bg-red-100
                      text-red-800
                    ">
              <svg-icon class="
                        -ml-1
                        mr-0.5
                        flex-shrink-0
                        self-center
                        h-5
                        w-5
                        text-red-500
                      " icon-class="decrease" />
              <span class="sr-only"> Decreased by </span>
              {{ calculatePercStatus(m.from, m.to).perc }}%
            </div>
          </div>
        </div>
        <div v-for="(m, i) in form.filter((v) => v.type === 'team')"
          class="flex justify-between bg-white hover:bg-gray-50 px-4 py-5 sm:p-6 cursor-pointer"
          :key="`property-summary-team-${i}`" @click="$openPlanSelectSlideCard()">
          <div class="flex flex-col space-y-2">
            <span class="text-base font-normal text-gray-900">{{ m.name }}</span>
            <div class="flex space-x-2 items-baseline">
              <a v-if="!$isAdmin()" class="
                      flex
                      items-baseline
                      text-2xl
                      font-semibold
                      text-parqay-primary
                    ">
                {{ m.size }}
              </a>
              <a v-else class="
                      flex
                      items-baseline
                      text-3xl
                      font-semibold
                      text-black
                    ">
                ∞
              </a>
            </div>
          </div>
          <div v-if="!$isAdmin()" class="flex flex-col justify-end text-lg font-medium">
            <a-tag color="gold" class="flex items-center px-2 py-1 font-medium cursor-pointer">
              <svg-icon icon-class="premium-diamond" class="mr-2 h-5 w-5" />
              Beta Trial
            </a-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-tag {
    @apply flex px-1.5 py-1;
  }
}
</style>
