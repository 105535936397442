<script>
import { ref, reactive, onMounted, onBeforeUnmount } from '@vue/composition-api';
import ClickOutside from "vue-click-outside";
import EventCard from "src/views/components/components/EventCard.vue";
import EventPicker from "src/views/components/components/EventPicker.vue";
import VerifyLot from "src/views/listings/new/components/VerifyLot.vue";
import PickEvent from "src/views/listings/new/event-parking/PickEvent.vue";
import ListingDetails from "src/views/listings/new/general-parking/ListingDetails.vue";
import SchedulePrice from "src/views/listings/new/components/SchedulePrice.vue";
import DetailsAndPrice from "src/views/listings/new/event-parking/Details&Price.vue";
import ReceivePayment from "src/views/listings/new/components/ReceivePayment.vue";

export default {
  directives: {
    ClickOutside,
  },
  components: {
    EventCard,
    EventPicker,
    VerifyLot,
    PickEvent,
    SchedulePrice,
    DetailsAndPrice,
    ListingDetails,
    ReceivePayment,
  },
  setup(props, ctx) {
    const controls = reactive({
      visible: {
        editEvents: false
      },
      expand: {
        selectedEvent: false
      },
      key: 0
    })
    const form = reactive({
      events: []
    })
    const steps = ref(["Pick Events", "Locate Property", "Listing Details", "Pricing", "Payout"]);
    const stepComponents = ref([
      "pick-event",
      "verify-lot",
      "listing-details",
      "schedule-price",
      "receive-payment",
    ]);
    const stepIndex = ref(0);
    const skipToStep = (s) => {
      if (stepIndex.value > s) {
        stepIndex.value = s;
      }
    };
    const nextStep = () => {
      controls.expand.selectedEvent = false;
      let target = stepIndex.value + 1;
      // if (target == 1) {
      //   target = 3;
      // }
      if (target == 5) {
        ctx.root.$router.push("/listing/new");
        return;
      }
      stepIndex.value = target;
    };
    const previousStep = () => {
      let target = stepIndex.value - 1;
      if (target == -1) {
        ctx.root.$router.go(-1);
        return;
      }
      stepIndex.value = target;
    };
    const payloadUpdate = (pk, pd) => {
      ctx.root.$store.commit("updateListingForm", [pk, pd]);
    };

    const clearPayload = () => {
      ctx.root.$store.commit("resetLisitngForm");
    };

    onMounted(() => {
      payloadUpdate("type", "event-parking");
    })

    onBeforeUnmount(() => {
      clearPayload();
    });
    return {
      controls,
      form,
      steps,
      stepComponents,
      stepIndex,
      skipToStep,
      nextStep,
      previousStep,
      payloadUpdate
    };
  },
};
</script>

<template>
  <div class="w-full bg-white">
    <div class="flex justify-center border-b border-gray-300 relative">
      <div
        class="absolute left-4 top-0 bottom-0 w-auto flex space-x-2 items-center justify-center border-parqay-primary hover:border-indigo-800 text-parqay-primary hover:text-blue-800">
        <button
          class="border-2 border-parqay-primary rounded-full w-10 h-10 inline-flex items-center justify-center focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          @click="previousStep">
          <svg-icon icon-class="chevron-left" class="w-6 h-6 my-auto" />
        </button>
        <span class="text-lg font-medium cursor-pointer" @click="previousStep">Back</span>
      </div>
      <nav aria-label="Progress">
        <ol class="rounded-md divide-y divide-gray-300 mb-0 md:flex md:divide-y-0">
          <li v-for="(step, i) in steps" :key="`step-${i}`" class="relative md:flex-0 md:flex">
            <a class="group flex items-center w-full">
              <span class="px-6 py-4 flex items-center text-sm font-medium">
                <!-- Completed Step -->
                <span v-if="stepIndex > i"
                  class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-parqay-primary rounded-full group-hover:bg-blue-500"
                  @click="skipToStep(i)">
                  <svg-icon icon-class="solid-check" class="w-6 h-6 text-white" />
                </span>
                <!-- Current Step -->
                <span v-else-if="stepIndex == i"
                  class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-parqay-primary rounded-full"
                  aria-current="step" @click="skipToStep(i)">
                  <span class="text-parqay-primary">{{ i + 1 }}</span>
                </span>
                <!-- Upcoming Step -->
                <span v-else
                  class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400"
                  @click="skipToStep(i)">
                  <span class="text-gray-500 group-hover:text-gray-900">{{
                    i + 1
                  }}</span>
                </span>
                <span class="ml-4 text-sm font-medium text-gray-900" @click="skipToStep(i)">{{ step }}</span>
              </span>
            </a>

            <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
              <svg-icon icon-class="step-indicator-arrow" class="h-full w-full text-gray-300" />
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div>
      <div class="listview bg-gray-50">
        <!-- DO NOT cache last step since its triggering by on mounted hook! -->
        <keep-alive v-if="stepIndex < stepComponents.length - 1">
          <component :is="stepComponents[stepIndex]" :controls="controls" :form="form" @next="nextStep"
            @back="previousStep" @payload-update="payloadUpdate" />
        </keep-alive>
        <component v-else :is="stepComponents[stepIndex]" :controls="controls" :form="form" @next="nextStep"
          @back="previousStep" @payload-update="payloadUpdate" />
      </div>
    </div>
    <footer v-if="![1, 4].includes(stepIndex)" :class="controls.expand.selectedEvent ? 'h-expanded' : 'h-24'"
      class="bg-parqay-primary fixed left-0 right-0 bottom-0" style="z-index: 1001;" v-click-outside="() => {
        controls.expand.selectedEvent = false;
      }">
      <div class="flex flex-col h-full">
        <div class="flex justify-between items-center px-6 py-4">
          <div class="flex items-center">
            <span class="text-white text-lg font-medium"
              @click="controls.expand.selectedEvent = !controls.expand.selectedEvent">
              You have added
              <span class="text-orange-300 text-bold">{{
                form.events.length
              }}</span>
              events
            </span>
            <button
              class="cursor-pointer text-white border border-white rounded-full ml-4 text-sm font-medium px-4 py-1.5"
              @click="controls.expand.selectedEvent = !controls.expand.selectedEvent">
              {{ controls.expand.selectedEvent ? "Collapse" : "View all" }}
            </button>
            <button v-if="stepIndex > 0"
              class="cursor-pointer text-white border border-white rounded-full ml-4 text-sm font-medium px-4 py-1.5"
              @click="() => controls.visible.editEvents = true">
              + Add Events
            </button>
          </div>
          <div v-if="[0].includes(stepIndex)" class="flex-0">
            <button :disabled="form.events.length == 0"
              class="px-4 py-2 text-white text-lg font-medium bg-orange-400 hover:bg-orange-300 focus:outline-none rounded-lg disabled:opacity-50"
              @click="nextStep">
              Continue
            </button>
          </div>
        </div>
        <div v-if="controls.expand.selectedEvent" class="flex h-full w-full">
          <div v-if="form.events.length == 0"
            class="flex items-center justify-center border border-dashed border-white rounded-xl mx-6 my-4 w-full">
            <span class="text-center text-lg font-medium text-white" style="line-height: 200px;">
              Events you have selected will be displyed here.
            </span>
          </div>
          <div v-else
            class="flex justify-center items-center border border-dashed border-white rounded-xl mx-6 my-4 w-full">
            <smooth-scroll>
              <div class="grid grid-cols-6 xl:grid-cols-6 gap-4 xl:gap-6 w-full p-4">
                <event-card v-for="(event, i) in form.events" :key="`selected-event-${i}`" :event="event"
                  :selected-events="form.events" mini @click="() => {
                    form.events.splice(i, 1);
                  }
                    " />
              </div>
            </smooth-scroll>
          </div>
        </div>
      </div>
    </footer>
    <a-modal v-model="controls.visible.editEvents" width="90vw" :footer="null"
      @close="() => controls.visible.editEvents = false">
      <template #title>
        <span class="text-black text-lg font-semibold text-center w-full py-4">
          Choose An Event / Events
        </span>
      </template>
      <div class="flex justify-center mt-2 overflow-y-auto" style="height:80vh;">
        <event-picker :controls="controls" :form="form" class="w-full" />
      </div>
    </a-modal>
  </div>
</template>
<style lang="scss" scoped>
.h-expanded {
  min-height: 400px;
}
</style>