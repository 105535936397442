<script>
import { ref, onMounted, nextTick } from "@vue/composition-api";
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "./carousel.css";
import useGoogleMap from 'src/util/useGoogleMap';

// Components
import ParkingPropertyList from "./ParkingPropertyList";
import SpacesPropertyList from "./SpacesPropertyList";
import CarRentalPropertyList from "./CarRentalPropertyList";

// Data
import { PropertiesNearbyData } from "./mockData";

export default {
  name: "PropertiesNearby",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    ParkingPropertyList,
    SpacesPropertyList,
    CarRentalPropertyList,
  },
  setup() {
    const { getMap } = useGoogleMap();
    // Carousel Section (Hooper)
    const carouselControl = ref(null);
    const carouselContent = ref(null);
    const carouselControlIndex = ref(0);
    const carouselControlModel = ref([
      {
        title: "Parking Nearby",
        desc: "Hourly, daily and monthly parking.",
      },
      {
        title: "Stay Nearby",
        desc: "Places to stay or rest",
      },
      {
        title: "Workspaces Nearby",
        desc: "Offices, Workspaces and Cafes",
      },
      {
        title: "Car Rental Nearby",
        desc: "Find and book cars nearby",
      },
    ]);
    const carouselContentModel = ref(PropertiesNearbyData);
    const slideCarouselTo = (i) => {
      console.log("slideCarouselTo");
      if (carouselControl.value) {
        carouselControl.value.slideTo(i);
      }
      if (carouselControl.value) {
        carouselControl.value.slideTo(i);
      }
    };
    const handleCarouselControlUpdate = (payload) => {
      console.log(
        "from: " + payload.slideFrom + " to: " + payload.currentSlide
      );
      carouselControlIndex.value = payload.currentSlide;
    };

    // Map Section

    const mapLayer = ref(null);
    var mapInstance;

    const markerLocations = ref([
      { lat: 36.964, lng: -122.015 },
      { lat: 36.96440284626288, lng: -122.01395834377185 },
      { lat: 36.963764196854974, lng: -122.01445187023059 },
      { lat: 36.96372990680235, lng: -122.01532090595141 },
      { lat: 36.96441932711484, lng: -122.01619327068329 },
      { lat: 36.96361970086733, lng: -122.01597973497648 },
    ]);

    const markers = ref([]);

    var svgMarker, svgMarkerEnlarged;

    var googleService;

    const toggleMarker = (i, forceValue) => {
      if (googleService) {
        if (forceValue != null) {
          const marker = markers.value[i];
          if (forceValue == true) {
            marker.setIcon(svgMarkerEnlarged);
            marker.setAnimation(googleService.maps.Animation.BOUNCE);
            markers.value.map((v, i) => {
              if (v != marker) {
                v.setIcon(svgMarker);
                v.setAnimation(null);
              }
            });
          } else {
            marker.setIcon(svgMarker);
            marker.setAnimation(null);
          }
          return;
        }
      }
    };
    // Lifecycle Hooks

    onMounted(async () => {
      const maps = await getMap();
      nextTick(() => {
        googleService = window.google;
        mapInstance = new maps.Map(mapLayer.value, {
          center: { lat: 36.964, lng: -122.015 },
          zoom: 16,
        });
        svgMarker = {
          url: "/static/img/dashboard/PARQAY-marker.svg",
          // anchor: new google.maps.Point(25,50),
          scaledSize: new maps.Size(60, 60),
          labelOrigin: new maps.Point(30, 70),
        };
        svgMarkerEnlarged = {
          url: "/static/img/dashboard/PARQAY-marker.svg",
          scaledSize: new maps.Size(80, 80),
          labelOrigin: new maps.Point(40, 90),
        };
        markerLocations.value.map((v, i) => {
          const marker = new maps.Marker({
            position: v,
            icon: svgMarker,
            label: {
              text: "$200",
              color: "#524de2",
              fontWeight: "500",
            },
            map: mapInstance,
          });
          marker.addListener("click", () => {
            toggleMarker(i, true);
          });
          markers.value.push(marker);
        });

        maps.event.addListener(mapInstance, "click", (event) => {
          console.log(
            "{lat: " + event.latLng.lat(),
            ", lng: " + event.latLng.lng() + "}"
          );
        });
      });
    });

    return {
      mapLayer,
      markers,
      carouselControl,
      carouselContent,
      carouselControlIndex,
      carouselControlModel,
      carouselContentModel,
      markerLocations,
      toggleMarker,
      slideCarouselTo,
      handleCarouselControlUpdate,
    };
  },
};
</script>
<template>
  <div>
    <hooper
      id="nearbyControl"
      ref="carouselControl"
      group="group1"
      :items-to-show="3"
      :center-mode="true"
      style="height: 120px"
      :infinite-scroll="true"
      :wheel-control="false"
      @slide="handleCarouselControlUpdate"
    >
      <slide
        v-for="(c, i) in carouselControlModel"
        :key="`${c.title}-${i}`"
        style="padding: 0 0.5rem"
      >
        <div
          class="flex flex-col justify-center py-4 cursor-pointer pointer-events-auto bg-white hover:bg-gray-200"
          @click="slideCarouselTo(i)"
        >
          <h4 class="block text-2xl text-parqay-primary text-center font-bold">
            {{ c.title }}
          </h4>
          <span class="text-base font-medium text-center">{{ c.desc }}</span>
        </div>
      </slide>
      <hooper-navigation slot="hooper-addons" />
      <hooper-pagination slot="hooper-addons" />
    </hooper>

    <div
      class="mt-2 relative bg-white shadow-sm border sm:rounded-2xl sm:overflow-hidden"
      style="height: 500px"
    >
      <div class="grid grid-cols-2 h-full">
        <!-- Nearby Property Section -->
        <div class="h-full overflow-hidden">
          <smooth-scroll>
            <transition
              name="fade"
              :duration="{ enter: 200, leave: 200 }"
            >
              <parking-property-list
                v-if="carouselControlIndex == 0"
                :model="carouselContentModel[0]"
                @toggleMarker="toggleMarker"
              />
              <spaces-property-list
                v-if="carouselControlIndex == 1"
                :type="'stay'"
                :model="carouselContentModel[1]"
                @toggleMarker="toggleMarker"
              />
              <spaces-property-list
                v-if="carouselControlIndex == 2"
                :type="'coworking'"
                :model="carouselContentModel[2]"
                @toggleMarker="toggleMarker"
              />
              <car-rental-property-list
                v-if="carouselControlIndex == 3"
                :model="carouselContentModel[3]"
                @toggleMarker="toggleMarker"
              />
            </transition>
          </smooth-scroll>
        </div>
        <!-- Map Layer -->
        <div
          ref="mapLayer"
          class="col-span-1"
        />
      </div>
    </div>
  </div>
</template>