<script>
import { ref, onMounted } from "@vue/composition-api";
import HostHeader from "src/views/dashboard/components/HostHeader";

export default {
  name: "ListingFrame",
  components: {
    HostHeader
  },
  setup(props, ctx) {
    const windowHeight = ref(window.innerHeight);
    onMounted(() => { });
    return {
      windowHeight
    };
  }
};
</script>
<template>
  <div id="listing-flow" class="w-screen bg-yellow-300">
    <div class="h-screen flex overflow-hidden bg-white">
      <main class="flex flex-col w-full">
        <host-header>
          <template slot="left">
            <div class="flex items-center cursor-pointer hover:bg-gray-50 rounded-lg"
              @click="$router.push('/dashboard')">
              <svg-icon icon-class="PARQAY-logo" class="w-auto" :style="{ height: '3.75rem' }" />
            </div>
          </template>
          <template slot="right">
            <router-link tag="button" to="/dashboard"
              class="bg-parqay-primary hover:bg-blue-500 text-white font-normal py-2 px-4 rounded">
              Dashboard
            </router-link>
          </template>
        </host-header>
        <div class="flex-grow overflow-hidden" :style="`height: ${windowHeight - 100}px; background-color: #f9f9f9`">
          <div class="h-full overflow-y-auto ">
            <smooth-scroll>
              <router-view />
            </smooth-scroll>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
