var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex h-screen bg-gray-100 border rounded-md overflow-hidden"},[_c('div',{ref:"contactsList",staticClass:"contactsList w-1/2 bg-white border-r border-gray-200 overflow-y-auto"},[_c('div',{staticClass:"flex items-center space-x-4 p-4 border-b border-gray-200"},[_c('a-input-search',{attrs:{"loading":_vm.searchLoading,"placeholder":"Search contact / conversations / lead","allow-clear":"","size":"large"},on:{"change":function($event){return _vm.toggleSearchLoading()}},model:{value:(_vm.searchKeywords),callback:function ($$v) {_vm.searchKeywords=$$v},expression:"searchKeywords"}}),_c('a-icon',{staticClass:"pr-1",staticStyle:{"font-size":"1rem"},attrs:{"type":"reload","spin":_vm.contactLoading},on:{"click":function($event){$event.stopPropagation();return _vm.getTenantsList()}}})],1),_c('div',{staticClass:"chat-lead h-full py-2 relative"},[_c('div',{staticClass:"px-4 py-2 cursor-pointer"},[_c('div',{staticClass:"flex items-center space-x-6 px-4 py-2 hover:bg-blue-100 rounded-full border shadow-sm",staticStyle:{"min-height":"60px"},on:{"click":function($event){$event.stopPropagation();return (function () {
                            _vm.newLeadVisible = true;
                        })($event)}}},[_c('div',{staticClass:"flex items-center justify-center rounded-full bg-gray-100 w-8 h-8 border"},[_c('a-icon',{staticStyle:{"font-size":"20px"},attrs:{"type":"plus"}})],1),_vm._m(0)])]),(_vm.contactLoading)?_c('div',{staticClass:"absolute inset-0 flex items-center justify-center h-4/5"},[_c('a-icon',{staticStyle:{"font-size":"30px"},attrs:{"type":"loading"}})],1):_vm._e(),_c('keep-alive',[_c('transition',{attrs:{"name":"slide-left-right"}},[(!_vm.contactLoading)?_c('ul',_vm._l((_vm.contacts),function(contact,i){return _c('li',{key:("contact-" + i),staticClass:"px-4 py-2 cursor-pointer"},[_c('div',{staticClass:"flex items-center space-x-6 px-4 py-2 hover:bg-blue-100 rounded-full border shadow-sm",class:{ 'ring-2 ring-gray-500 bg-gray-100': _vm.selectedContact && (contact.id === _vm.selectedContact.id) },staticStyle:{"min-height":"60px"},on:{"click":function($event){$event.stopPropagation();return (function () {
                                        _vm.selectedContact = contact;
                                        _vm.searchKeywords = null;
                                        _vm.newLeadVisible = false;
                                        _vm.directChatFullfillVisible = false;
                                        _vm.getContactConversations();
                                    })($event)}}},[_c('avatar',{attrs:{"user-id":contact.id,"admin-on-behalf-of":contact.host}}),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"font-medium"},[_vm._v(_vm._s(contact.name))])]),(_vm.conversations.filter(function (v) { return contact.id === v.tenant && v.last_message; }).length > 0)?_c('a-button',{staticStyle:{"font-size":"0.75rem","font-weight":"500"},attrs:{"type":"link","size":"small"}},[_vm._v(_vm._s(_vm.conversations.filter(function (v) { return contact.id === v.tenant && v.last_message; }).length)+"\n                                        conversations")]):_vm._e(),_c('div',{staticClass:"flex items-center space-x-3"},[(contact.status === 'joined')?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("Tenant")]):_vm._e(),(contact.status === 'invited')?_c('a-tag',{attrs:{"color":"cyan"}},[_vm._v("Lead")]):_vm._e()],1)],1)])}),0):_vm._e()])],1),(_vm.contacts.length > 0 && _vm.pagination.contact.total > 0)?_c('div',{staticClass:"flex items-center justify-center pt-2 pb-4"},[_c('a-pagination',{attrs:{"current":_vm.pagination.contact.currentPage,"total":_vm.pagination.contact.total,"page-size":_vm.pagination.contact.perPage},on:{"change":_vm.handleContactPageChange}})],1):_vm._e()],1)]),_c('div',{ref:"roomsList",staticClass:"roomsList w-1/2 bg-white overflow-y-auto"},[(_vm.newLeadVisible)?_c('div',{staticClass:"flex items-center justify-center space-x-4 px-4 py-6 border-b border-gray-200 relative"},[_c('span',{staticClass:"text-sm font-semibold py-0.5"},[_vm._v("New Lead Outreach")]),_c('a-icon',{staticClass:"absolute pr-1 my-auto right-4",staticStyle:{"font-size":"1rem"},attrs:{"type":"close"},on:{"click":function($event){$event.stopPropagation();return (function () {
                    _vm.newLeadVisible = false;
                })($event)}}})],1):(_vm.directChatFullfillVisible)?_c('div',{staticClass:"flex items-center justify-center space-x-4 px-4 py-6 border-b border-gray-200 relative"},[_c('span',{staticClass:"text-sm font-semibold py-0.5"},[_vm._v("Starting Direct Chat with "),_c('span',{staticClass:"text-parqay-primary"},[_vm._v(_vm._s(_vm.selectedContact.name))])]),_c('a-icon',{staticClass:"absolute pr-1 my-auto right-4",staticStyle:{"font-size":"1rem"},attrs:{"type":"close"},on:{"click":function($event){$event.stopPropagation();return (function () {
                    _vm.directChatFullfillVisible = false;
                })($event)}}})],1):_c('div',{staticClass:"flex items-center justify-center space-x-4 px-4 py-6 border-b border-gray-200"},[(_vm.selectedContact)?_c('span',{staticClass:"text-sm font-semibold py-0.5"},[_vm._v("Conversations with "+_vm._s(_vm.selectedContact.name)+" ("+_vm._s(_vm.conversationLoading ? "..." : _vm.conversations.filter(function (v) { return v.last_message; }).length)+")")]):_c('span',{staticClass:"text-sm font-semibold py-0.5"},[_vm._v(_vm._s(_vm.searchKeywords ? "Search Results" : "All Conversations")+" ("+_vm._s(_vm.conversationLoading ? "..." : _vm.pagination.conversation.total)+")")]),(_vm.selectedContact)?_c('a-icon',{staticClass:"pr-1",staticStyle:{"font-size":"1rem"},attrs:{"type":"reload","spin":_vm.conversationLoading},on:{"click":function($event){$event.stopPropagation();return _vm.getContactConversations()}}}):_c('a-icon',{staticClass:"pr-1",staticStyle:{"font-size":"1rem"},attrs:{"type":"reload","spin":_vm.conversationLoading},on:{"click":function($event){$event.stopPropagation();return _vm.getConversationsList()}}})],1),(!_vm.selectedContact && !_vm.newLeadVisible)?_c('div',{staticClass:"flex items-center justify-center px-4 pt-4"},[_c('a-radio-group',{attrs:{"button-style":"solid"},on:{"change":function () {
                    _vm.getConversationsList();
                }},model:{value:(_vm.conversationType),callback:function ($$v) {_vm.conversationType=$$v},expression:"conversationType"}},[_c('a-radio-button',{attrs:{"value":"reservation"}},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',{},[_vm._v("Reservation Chat")])])]),_c('a-radio-button',{attrs:{"value":"direct"}},[_vm._v("\n                        Direct Chat\n                    ")])],1)],1):_vm._e(),_c('div',{staticClass:"chat-lead h-full py-2 relative"},[_c('transition',{attrs:{"name":"slide-left-right"}},[(_vm.newLeadVisible)?_c('lead-outreach',{on:{"next":_vm.oneNewLeadNext}}):_vm._e(),(_vm.directChatFullfillVisible)?_c('direct-fullfill',{attrs:{"contact":_vm.selectedContact},on:{"next":_vm.onDirectFullfillNext}}):_vm._e()],1),(_vm.conversationLoading)?_c('div',{staticClass:"absolute inset-0 flex items-center justify-center h-4/5"},[_c('a-icon',{staticStyle:{"font-size":"30px"},attrs:{"type":"loading"}})],1):_vm._e(),_c('keep-alive',[(!(_vm.newLeadVisible || _vm.directChatFullfillVisible))?[_c('transition',{attrs:{"name":"slide-left-right"}},[(!_vm.conversationLoading)?_c('div',{staticClass:"flex flex-col"},[_c('ul',[(_vm.selectedContact)?_c('li',{staticClass:"flex items-center px-4 py-2 space-x-4 cursor-pointer"},[_c('div',{staticClass:"flex-shrink-0 flex w-1/2 items-center rounded-lg space-x-4 px-4 py-4 hover:bg-blue-100",on:{"click":function($event){$event.stopPropagation();return (function () {
                                                _vm.selectedContact = null;
                                                _vm.searchKeywords = null;
                                                _vm.getConversationsList();
                                                _vm.filterContacts();
                                            })($event)}}},[_c('a-icon',{attrs:{"type":"left"}}),_c('span',{staticClass:"text-blue-500 font-medium"},[_vm._v("Show All Conversations")])],1),(!_vm.conversations.find(function (v) { return v.tenant === _vm.selectedContact.id && v.type === 'direct'; }))?_c('a-button',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"loading":_vm.startDirectChatLoading,"icon":"plus","block":"","size":"large"},on:{"click":function($event){$event.stopPropagation();return _vm.startDirectChat()}}},[_vm._v("Start A\n                                            Direct\n                                            Chat")]):_vm._e()],1):_vm._e(),_vm._l((_vm.conversations.filter(_vm.messagesFilter)),function(conversation,i){return _c('li',{key:("conversation-" + i),staticClass:"px-4 pt-2 cursor-pointer",on:{"click":function($event){$event.stopPropagation();return (function () {
                                            if (conversation.type === 'direct') {
                                                if (!_vm.selectedContact) {
                                                    _vm.selectedContact = _vm.contacts.find(function (v) { return v.id === conversation.tenant; });
                                                }
                                                if (!_vm.checkContactPhoneNumber(_vm.selectedContact)) {
                                                    return;
                                                }
                                            }
                                            _vm.$bus.$emit('open-contact-drawer', { room_id: conversation.id });
                                        })($event)}}},[_c('div',{staticClass:"flex items-center space-x-6 px-4 pt-3 pb-3 hover:bg-blue-100 rounded-lg border shadow-sm",staticStyle:{"min-height":"60px"}},[(conversation.listing_id)?_c('listing-avatar',{attrs:{"listing-id":conversation.listing_id,"tooltip-item-size":30,"tooltip-placement":"bottom"}}):_vm._e(),(conversation.type === 'direct')?_c('avatar',{attrs:{"user-id":conversation.tenant,"admin-on-behalf-of":conversation.host}}):_vm._e(),_c('div',{staticClass:"flex-1 space-y-2"},[(conversation.type === 'direct')?_c('div',{staticClass:"flex items-center space-x-2"},[_c('a-tag',{attrs:{"color":"green"}},[_vm._v("Direct Chat")]),_c('span',{staticClass:"text-sm font-semibold"},[_vm._v(_vm._s(conversation.tenant_name))])],1):_vm._e(),(conversation.name)?_c('div',{staticClass:"flex items-center space-x-2"},[_c('div',{staticClass:"font-semibold"},[_vm._v(_vm._s(conversation.name))]),(!_vm.$deepGet(conversation, 'last_message.is_read', true))?_c('a-badge',{attrs:{"count":"New"}}):_vm._e()],1):_vm._e(),(conversation.reservation)?_c('div',{staticClass:"flex items-center space-x-3"},[_c('span',[_vm._v("Tenant")]),_c('a-tag',[_vm._v(_vm._s(conversation.reservation.tenant_name))])],1):_vm._e(),(conversation.last_message)?_c('div',{staticClass:"text-sm text-gray-500 truncate py-1",class:{ 'font-medium text-gray-800': !_vm.$deepGet(conversation, 'last_message.is_read', true) },staticStyle:{"max-width":"260px"}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(conversation.last_message.sender_name)+":")]),_vm._v(_vm._s(conversation.last_message.message))]):_vm._e()]),(conversation.last_message)?_c('div',{staticClass:"flex-shrink-0 text-sm text-gray-500"},[_vm._v("\n                                                "+_vm._s(_vm.$formatDate(_vm.$deepGet(conversation,
                                                    "last_message.dt") || _vm.$deepGet(conversation,
                                                        "last_message.created_at.$date"), null, true))+"\n                                            ")]):_c('div',[_c('a-button',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"icon":"message"}},[_vm._v("Start A\n                                                    Chat")])],1)],1),(conversation.type === 'reservation')?_c('a-divider',{staticStyle:{"margin":"0px","margin-top":"0.5rem"}},[_c('div',{staticClass:"text-xs text-parqay-primary"},[_c('span',[_vm._v("\n                                                    Booked on "+_vm._s(_vm.$formatDate(_vm.$deepGet(conversation,
                                                        "created_at.$date", new Date()), null, true))+"\n                                                ")])])]):_c('div',{staticClass:"h-4 w-full"})],1)})],2),(_vm.conversations.length > 0 && _vm.pagination.conversation.total > 0)?_c('div',{staticClass:"flex items-center justify-center pt-2 pb-4"},[_c('a-pagination',{attrs:{"current":_vm.pagination.conversation.currentPage,"total":_vm.pagination.conversation.total,"page-size":_vm.pagination.conversation.perPage},on:{"change":_vm.handleConversationPageChange}})],1):_vm._e()]):_vm._e()])]:_vm._e()],2)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"font-medium"},[_vm._v("New Lead Outreach")])])}]

export { render, staticRenderFns }