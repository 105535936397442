<script>
import {
  reactive,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from "@vue/composition-api";
import ValidationSectionHeader from "src/views/reservations/components/ValidationSectionHeader";
import { API } from "src/views/documents/api";
import { deepGet } from "src/plugins/prototype";
import { startSignatureRequest } from "src/views/documents/components/Templates.vue";

export default {
  components: {
    ValidationSectionHeader,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    controls: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const templates = reactive({
      upload_only: [],
      signature_request: [],
      identity_verification: null,
    });
    const documents = reactive({
      upload_only: [],
      signature_request: [],
      identity_verification: [],
    });
    watch(
      () => props.form.tenant,
      async (val) => {
        if (deepGet(val, "id")) {
          await getTenantDocuments();
          await getIdentityVerificationResult(props.form);
        }
      },
      {
        deep: true,
      }
    );
    watch(
      () => props.form.listing,
      async (val) => {
        if (deepGet(val, "id")) {
          await getDocumentTemplates(props.form);
        }
      },
      {
        deep: true,
      }
    );
    const getDocumentTemplates = async (form) => {
      props.controls.loading.documentTemplates = true;
      const documentTemplates = deepGet(props.form, "document_templates", []);
      if (props.controls.createMode || documentTemplates.length === 0) {
        let resp;
        resp = await API.getListingDocumentTemplates({
          listing_id: form.listing.id,
        });
        templates.upload_only = resp.data.filter((v) => v.type === "upload");
        templates.signature_request = resp.data.filter((v) => v.type === "sign");
        templates.identity_verification = resp.data.find(
          (v) => v.type === "identity"
        );
        props.form.document_templates = resp.data;
      } else {
        templates.upload_only = documentTemplates.filter((v) => v.type === "upload");
        templates.signature_request = documentTemplates.filter(
          (v) => v.type === "sign"
        );
        templates.identity_verification = documentTemplates.find(
          (v) => v.type === "identity"
        );
      }
      props.controls.loading.documentTemplates = false;
    };
    const setupTemplates = () => {
      window.open("/dashboard/documents/templates", "_blank").focus();
    };
    const getTenantDocuments = async () => {
      let resp;
      resp = await API.getDocumentsByReservation({
        role: "host",
        reservation_id: props.form.id
      });
      documents.upload_only = resp.data.filter((v) => v.template.type === "upload");
      documents.signature_request = resp.data.filter(
        (v) => v.template.type === "sign"
      );
    };
    const getIdentityVerificationResult = async () => {
      let resp;
      resp = await API.getIdentityVerificationResult({
        on_behalf_of: deepGet(props.form, "tenant.id"),
      });
      props.controls.identity_verification = resp.data;
      documents.identity_verification = [resp.data];
    };
    const signDocument = async (doc) => {
      // ctx.root.$toggleLoadingIndicator(true);
      try {
        await startSignatureRequest(doc, "host");
      } catch (error) {
        console.log("signDocument >> error: ", error);
        ctx.root.$openNotification("Signing Document", error.message, 10, "error");
        return;
      } finally {
        // ctx.root.$toggleLoadingIndicator(false);
      }
    };
    onMounted(() => {
      ctx.root.$bus.$on("start-new-document-signature-request", signDocument);
      ctx.root.$bus.$on("document-sign-refresh", getTenantDocuments);
    });
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("start-new-document-signature-request", signDocument);
      ctx.root.$bus.$off("document-sign-refresh", getTenantDocuments);
    });
    return {
      templates,
      documents,
      setupTemplates,
    };
  },
};
</script>
<template>
  <div id="documents" class="bg-white shadow overflow-hidden sm:rounded-lg mx-8">
    <div class="flex justify-between items-center bg-white px-4 py-2 space-x-2 border-b border-gray-200 sm:px-6">
      <h3 class="text-base leading-6 font-medium text-gray-900 whitespace-nowrap">
        Documents & Signatures
      </h3>
      <validation-section-header :validation-errors="controls.errors.filter((e) => e.type == 'document')" />
    </div>
    <div class="grid grid-cols-2">
      <div v-if="$deepGet(form, 'listing.id')" class="grid grid-cols-5 border-b border-r">
        <span class="px-4 py-4 text-sm font-medium text-gray-700">
          Documents
        </span>
        <div class="flex flex-col col-span-4 space-y-2 py-4 px-4">
          <span class="text-left text-sm font-light">
            Document status will change to "submitted" once your tenant finished
            uploading
          </span>
          <div class="flex flex-wrap items-center space-x-2 px-4 py-1 rounded-lg border shadow-sm bg-white">
            <div class="flex items-center space-x-2 py-1">
              <span class="text-sm text-gray-700">Templates</span>
              <a-button size="small" class="text-xs" @click="setupTemplates()">Setup</a-button>
            </div>
            <div class="py-1">
              <a-tag v-for="(template, i) in templates.upload_only" :key="`template-upload-${i}`" color="geekblue">
                {{ template.name }}
              </a-tag>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 rounded-lg border bg-gray-50 overflow-hidden px-2 py-2">
            <template v-if="controls.loading.documentTemplates">
              <div
                class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer">
                <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon icon-class="circular-loader" class="text-parqay-primary w-6 h-6 m-2 animate-spin" />
                </div>
                <div class="flex flex-col space-y-1">
                  <div class="text-xs font-medium text-gray-600">
                    loading ...
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="documents.upload_only.length === 0">
              <div
                class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer">
                <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon icon-class="document" class="text-parqay-primary w-6 h-6 m-2" />
                </div>
                <div class="flex flex-col space-y-1">
                  <div class="text-xs font-medium text-gray-600">
                    No Document Yet
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="(doc, i) in documents.upload_only" :key="`doc-upload-${i}`"
                class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer"
                @click="
                  $openDocSlideCard(
                    'host',
                    doc
                  )
                  ">
                <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon icon-class="document" class="text-parqay-primary w-6 h-6 m-2" />
                </div>
                <div class="flex flex-col space-y-1">
                  <div class="text-xs font-medium text-gray-600">
                    {{ doc.template.name }}
                  </div>
                  <a-tag v-if="doc.status === 'complete'" color="green">
                    Uploaded
                  </a-tag>
                  <a-tag v-if="doc.status === 'incomplete'" color="orange">
                    Pending
                  </a-tag>
                  <a-tag v-if="doc.status === 'requires_input'" color="pink">
                    Requires Input
                  </a-tag>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="$deepGet(form, 'listing.id')" class="grid grid-cols-5 border-b">
        <span class="px-4 py-4 text-sm font-medium text-gray-700">
          Signatures
        </span>
        <div class="flex flex-col col-span-4 space-y-2 py-4 px-4">
          <span class="text-left text-sm font-light">Document status will change to "signed" once your tenant finished
            signing</span>
          <div class="flex flex-wrap items-center space-x-2 px-4 py-1 rounded-lg border shadow-sm bg-white">
            <div class="flex items-center space-x-2 py-1">
              <span class="text-sm text-gray-700">Templates</span>
              <a-button size="small" class="text-xs" @click="setupTemplates()">Setup</a-button>
            </div>
            <div class="py-1">
              <a-tag v-for="(template, i) in templates.signature_request" :key="`template-signature-${i}`"
                color="geekblue">
                {{ template.name }}
              </a-tag>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 rounded-lg border bg-gray-50 overflow-hidden px-2 py-2">
            <template v-if="controls.loading.documentTemplates">
              <div
                class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer">
                <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon icon-class="circular-loader" class="text-parqay-primary w-6 h-6 m-2 animate-spin" />
                </div>
                <div class="flex flex-col space-y-1">
                  <div class="text-xs font-medium text-gray-600">
                    loading ...
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="documents.signature_request.length === 0">
              <div
                class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer">
                <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon icon-class="document" class="text-parqay-primary w-6 h-6 m-2" />
                </div>
                <div class="flex flex-col space-y-1">
                  <div class="text-xs font-medium text-gray-600">
                    No Document Yet
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="(doc, i) in documents.signature_request" :key="`doc-signature-${i}`"
                class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer"
                @click="
                  $openDocSlideCard(
                    'host',
                    doc
                  )
                  ">
                <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon icon-class="document" class="text-parqay-primary w-6 h-6 m-2" />
                </div>
                <div class="flex flex-col space-y-1">
                  <div class="text-xs font-medium text-gray-600">
                    {{ doc.template.name }}
                  </div>
                  <a-tag v-if="doc.status === 'complete'" color="green">
                    Complete
                  </a-tag>
                  <a-tag v-if="doc.status === 'signing'" color="orange">
                    Signing
                  </a-tag>
                  <a-tag v-if="doc.status === 'incomplete'" color="orange">
                    Pending
                  </a-tag>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="$deepGet(form, 'tenant.id')" class="grid grid-cols-10 col-span-2">
        <div class="flex flex-col space-y-2 px-4 py-4 items-start">
          <span class="text-sm font-medium text-gray-700">
            Identity Verification
          </span>
          <div class="flex justify-start items-center">
            <a-tag v-if="$deepGet(controls, 'identity_verification.status') === 'verified'
              " color="green">Verified</a-tag>
            <a-tag v-else-if="$deepGet(controls, 'identity_verification.status') === 'unverified'
              " color="pink">Unverified</a-tag>
            <a-tag v-else>{{
              $deepGet(controls, "identity_verification.status")
            }}</a-tag>
          </div>
        </div>
        <div class="flex flex-col col-span-9 space-y-2 py-4 px-4">
          <span class="text-left text-sm font-light">
            You can access tenant's identity documents once they pass identity
            verification
          </span>
          <div class="grid grid-cols-4 gap-2 rounded-lg border bg-gray-50 overflow-hidden px-2 py-2">
            <template v-if="controls.loading.documentTemplates">
              <div
                class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer">
                <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                  <svg-icon icon-class="circular-loader" class="text-parqay-primary w-6 h-6 m-2 animate-spin" />
                </div>
                <div class="flex flex-col space-y-1">
                  <div class="text-xs font-medium text-gray-600">
                    loading ...
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="documents.identity_verification.filter(
                (v) => v.status !== 'unverified'
              ).length == 0
                ">
                <div
                  class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer">
                  <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                    <svg-icon icon-class="document" class="text-parqay-primary w-6 h-6 m-2" />
                  </div>
                  <div class="flex flex-col space-y-1">
                    <div class="text-xs font-medium text-gray-600">
                      No Result Yet
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-for="(doc, i) in documents.identity_verification" :key="`doc-upload-${i}`"
                  class="flex items-center space-x-4 px-4 py-2 rounded-lg border shadow-sm bg-white hover:bg-blue-100 cursor-pointer"
                  @click="
                    $openDocSlideCard(
                      'host',
                      doc
                    )
                    ">
                  <div class="w-10 h-10 rounded-lg border bg-gray-50 flex-shrink-0">
                    <svg-icon icon-class="document" class="text-parqay-primary w-6 h-6 m-2" />
                  </div>
                  <div class="flex flex-col space-y-1">
                    <div class="text-xs font-medium text-gray-600">
                      Verification Result
                    </div>
                    <!-- <a-tag color="geekblue">submitted</a-tag> -->
                    <a-tag v-if="doc.status === 'verified'" color="green">
                      Verified
                    </a-tag>
                    <a-tag v-if="doc.status === 'incomplete'" color="orange">{{
                      doc.status
                    }}</a-tag>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
