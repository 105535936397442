<template>
    <div class="booking-calendar pb-4 px-6">
        <template v-for="(groupId, i) in Object.keys(form.summary.spaces.groups)">
            <a-divider :key="`divider-${i}`">
                <div class="flex items-center space-x-2">
                    <span>
                        {{ $deepGet(form, `summary.spaces.groups.${groupId}.name`, `Space Group #${i}`) }}
                    </span>
                    <a-icon type="edit" style="color: gray;" @click.stop="$emit('change-tab', 'overview')" />
                </div>
            </a-divider>
            <div :key="`group-${i}`" class="flex flex-wrap gap-4 items-center justify-center pb-4">
                <div v-for="(item, j) in form.summary.spaces.items.filter(v => v.group_id === groupId)"
                    :key="`calendar-${item.id}`" class="flex flex-col items-center justify-center space-y-2">
                    <span class="text-base text-parqay-primary font-medium pb-2">Space #{{ j + 1 }}</span>
                    <vc-calendar :from-page="getFocusPage(item)" :color="'blue'" :attributes="getAttrs(item)"
                        @dayclick="day => console.log('dayclick -> ', day)">
                        <template #day-popover="{ dayTitle, attributes }">
                            <ul class="flex flex-col">
                                <li v-for="{ key, customData } in attributes" :key="key" class="flex flex-col">
                                    <div class="flex items-center justify-between py-1 space-x-1">
                                        <a-tag color="orange">From</a-tag>
                                        <span class="text-black text-xs font-semibold">{{ $formatDate(customData.start,
                                            'MM-DD-YYYY, h:mmA') }}</span>
                                    </div>
                                    <div class="flex items-center justify-between py-1 space-x-1">
                                        <a-tag color="orange">To</a-tag>
                                        <span class="text-black text-xs font-semibold">{{ $formatDate(customData.end,
                                            'MM-DD-YYYY, h:mmA') }}</span>
                                    </div>
                                    <div class="flex items-center justify-center space-x-2 text-xs text-gray-700 hover:bg-blue-100 px-2 py-2 cursor-pointer"
                                        @click.stop="$bus.$emit('open-reservation-drawer', null, customData.reservation_id)">
                                        <div class="flex-shrink-0 w-2 h-2 rounded-full bg-green-400"></div>
                                        <span>Reservation <a class="text-parqay-primary font-semibold">#{{
                                            customData.reservation_id.substring(0, 6) }}</a></span>
                                    </div>
                                </li>
                            </ul>
                        </template>
                    </vc-calendar>
                </div>
            </div>
        </template>
    </div>
</template>
  
<script>
import { ref } from "@vue/composition-api";

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    setup(props, ctx) {
        const getDateWithoutOffset = (datetimeString) => new Date(datetimeString.substring(0, 19));
        const getAttrs = (item) => {
            let attrs = [];
            item.booked_intervals.forEach((interval, i) => {
                const start = getDateWithoutOffset(interval.from);
                console.log("interval - from -> ", start)
                const end = getDateWithoutOffset(interval.to);
                console.log("interval - to -> ", end)
                attrs.push({
                    key: `${item.id}-${i}`,
                    highlight: true,
                    dates: [
                        {
                            start,
                            end
                        }
                    ],
                    popover: {
                        visibility: "hover"
                    },
                    customData: {
                        start,
                        end,
                        ...interval
                    }
                })
            })
            return attrs;
        }
        const getFocusPage = (item) => {
            const current = new Date();
            let focus = { month: current.getMonth() + 1, year: current.getFullYear() };
            if (item.booked_intervals.length > 0) {
                const interval = item.booked_intervals[item.booked_intervals.length - 1];
                const start = getDateWithoutOffset(interval.from);
                console.log("start month -> ", start.getMonth());
                focus = { month: start.getMonth() + 1, year: start.getFullYear() };
            }
            return focus;
        }

        return {
            getAttrs,
            getFocusPage,
            console
        };
    },
};
</script>
<style lang="scss">
.booking-calendar {
    .vc-day-popover-container {
        @apply bg-white;
    }

    @apply w-full flex flex-col items-center justify-center;
}
</style>