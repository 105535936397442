<script>
export default {
  props: {},
  setup(props, ctx) {
    return {
      window,
    };
  },
};
</script>
<template>
  <div class="h-screen block px-6 py-6">
    <div
      id="welcome"
      class="h-full flex flex-col"
    >
      <span
        class="text-3xl font-bold text-black leading-12"
      >Getting Started with Parqay Dashboard API</span>
      <span
        class="text-2xl text-gray-500 leading-12"
      >Manage ALL services at once</span>
      <div
        class="grid grid-cols-3 gap-4 py-6 mt-4"
        :style="{ height: `${window.innerHeight / 1.3}px` }"
      >
        <div class="flex flex-col bg-blue-400 rounded-3xl px-6 py-6">
          <span
            class="text-white text-2xl font-bold leading-12"
          >Read the Docs</span>
          <span
            class="text-white text-xl font-light h-24"
          >Learn how to get Parqay set up in your project.</span>
          <button
            class="
              mx-6
              py-2
              bg-white
              rounded-full
              text-parqay-primary
              hover:text-blue-800
              text-xl
              font-semibold
              mt-8
            "
            @click="$emit('scroll-to-section', 'documentation')"
          >
            Start Integration
          </button>
        </div>
        <div class="flex flex-col bg-white border shadow rounded-3xl px-6 py-6">
          <span
            class="text-parqay-primary text-2xl font-bold leading-12"
          >Check Examples</span>
          <span
            class="text-black text-xl font-light h-24"
          >Know the best practice.</span>
          <button
            class="
              mx-6
              py-2
              bg-parqay-primary
              hover:bg-blue-500
              rounded-full
              text-white text-xl
              font-semibold
              mt-8
            "
            @click="$emit('scroll-to-section', 'examples')"
          >
            Start Practice
          </button>
        </div>
        <div class="flex flex-col bg-pink-500 rounded-3xl px-6 py-6">
          <span
            class="text-white text-2xl font-bold leading-12"
          >Play Around</span>
          <span
            class="text-white text-xl font-light h-24"
          >Learning through play</span>
          <button
            class="
              mx-6
              py-2
              bg-white
              rounded-full
              text-pink-500
              hover:text-pink-700
              text-xl
              font-semibold
              mt-8
            "
            @click="$emit('scroll-to-section', 'playground')"
          >
            Enjoy!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>