<script>
import EventPicker from "src/views/components/components/EventPicker.vue";

export default {
  components: {
    EventPicker
  },
  props: {
    controls: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    return {};
  },
};
</script>
<template>
  <div class="flex flex-col space-y-4 bg-white">
    <span class="text-black text-lg font-semibold text-center border-b w-full py-4">
      Choose An Event / Events
    </span>
    <event-picker :controls="controls" :form="form" class="h-screen" />
  </div>
</template>