var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spaces pb-4 px-2"},[_c('div',{staticClass:"flex items-baseline justify-between space-x-4 pb-2"},[_c('span',{staticClass:"text-sm font-medium"},[_vm._v("\n      Spaces\n    ")]),_c('div',{staticClass:"flex items-center space-x-2 text-xs font-semibold text-parqay-primary hover:text-blue-500 cursor-pointer",on:{"click":function($event){$event.stopPropagation();return (function () {
      _vm.$emit('change-tab', 'calendar')
    })($event)}}},[_c('a-icon',{attrs:{"type":"calendar"}}),_c('span',[_vm._v("Booking Calendar")])],1)]),_c('div',{staticClass:"flex items-center justify-center space-x-2 pt-4 pb-1 hover:animate-pulse cursor-pointer"},[_c('svg-icon',{staticClass:"w-4 h-4",attrs:{"icon-class":"move"}}),_c('span',{staticClass:"font-light text-gray-600"},[_vm._v("Drag to change the priority of selling these spaces")])],1),_c('div',{staticClass:"py-2"},[_c('container',{staticClass:"grid grid-cols-4 gap-4",attrs:{"orientation":"horizontal","drag-class":"z-20","get-child-payload":function (index) {
      return _vm.groupSpaces(_vm.model.spaces)[index];
    }},on:{"start":function($event){_vm.controls.drag = true},"end":function($event){_vm.controls.drag = false},"drop":function (dropResult) {
  var removedIndex = dropResult.removedIndex;
  var addedIndex = dropResult.addedIndex;
  var payload = dropResult.payload;
  var element = dropResult.element;
  _vm.console.log(dropResult);
  //TODO: use real reordering instead of current switching position
  var removingPayload = _vm.getGroupPayloadByOrderIndex(addedIndex);
  if (removingPayload) {
    removingPayload.order = removedIndex;
    _vm.model.spaces.groups[payload.id].order = addedIndex;
  }
}}},[_vm._l((_vm.groupSpaces(_vm.model.spaces)),function(it,i){return _c('draggable',{key:it.id,staticClass:"flex flex-col z-0"},[_c('p',{staticClass:"text-xs text-gray-600 font-medium text-center h-10 leading-10"},[_vm._v("\n          "+_vm._s(it.name)+"\n        ")]),_c('div',{staticClass:"pointer-events-auto"},[_c('space-item',{staticClass:"hover:shadow-xl cursor-pointer hover:border-2 hover:border-blue-500",attrs:{"item":it},on:{"add":function () { return _vm.handleAddSpots(it.id, it.order); },"minus":function () { return _vm.handleRemoveSpots(it.id); },"delete":function () { return _vm.handleRemoveGroup(it.id); }}})],1)])}),_c('draggable',{staticClass:"col-span-2 flex"},[_c('space-add-button',{on:{"add":_vm.handleAddSpace}})],1)],2)],1),_c('div',{staticClass:"flex space-x-4 mt-4"},[_c('a-button',{attrs:{"type":"primary","block":"","loading":_vm.controls.loading.save},on:{"click":function () { return _vm.updateSpaces(); }}},[_vm._v("\n      Save Changes\n    ")]),_c('a-button',{attrs:{"block":""},on:{"click":function($event){return _vm.$emit('refresh')}}},[_vm._v("\n      Reset\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }